import { Flex } from "@mantine/core"

export const ParralaxPreview = (props: {
    width: number,
    height: string | number
    elementWidth: number
    margin: number
    zIndex: number
}) => {

    return (
        <Flex
            wrap={'wrap'}
            style={{
                zIndex: props.zIndex - 1,
                position: 'absolute',
                left: 0,
                top: 0,
                width: props.width,
                height: props.height,

                overflow: 'hidden',
                // cursor: 'pointer',
                // zoom: 1 / Math.max(props.zoom, 1),
            }}
        >
            {
                Array.from({ length: props.width / (props.elementWidth + props.margin) + 1 }).map((_, index) => {
                    if (index === 0) {
                        return (
                            <div
                                key={index}
                                style={{ width: props.elementWidth, height: '100%', background: 'transparent', marginRight: props.margin }}>
                            </div>
                        )
                    }
                    return (
                        <div
                            key={index}
                            style={{ border: '1px solid #236cfa', width: props.elementWidth, height: '100%', background: '#286ffe9c', marginRight: props.margin }}>
                        </div>
                    )
                }
                )
            }
        </Flex>
    )
}
