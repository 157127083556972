
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/use-store';
import { UIButton } from '../../../components/ui-kit/button/UIButton';
import GroupElement from '../../../stores/data/space-store/groupElement';

const Element = () => {
    const {
        dataStore: { spaceStore },
    } = useStore();

    if (spaceStore.selectedWithShift.length > 1) {
        return (
            <div style={{
                position: 'fixed', top: '26px', left: '0',
                right: '0',
                width: 'max-content',
                margin: '0 auto', zIndex: '1111',
                textAlign: 'center'
            }}>
                <UIButton
                    onClick={() => {
                        spaceStore.groupElements();
                    }}
                    color={'blue'}
                    loading={false}
                    fluid={false}
                >
                    Group Selected
                </UIButton>
            </div>)
    } else if (spaceStore.selected instanceof GroupElement) {
        return (
            <div style={{
                position: 'absolute', top: '26px', left: '0',
                right: '0',
                width: 'max-content',
                margin: '0 auto', zIndex: '1111',
                textAlign: 'center'
            }}>
                <UIButton
                    onClick={() => {
                        console.log('UNGROUP', spaceStore.selected!.id)
                        spaceStore.ungroupElements(spaceStore.selected!.id);
                    }}
                    color={'blue'}
                    loading={false}
                    fluid={false}
                >
                    Ungroup elements
                </UIButton>
            </div>)
    } else {
        return null
    }
}

export const GroupingElementsButton = observer(Element);