import { ColorInput } from "@mantine/core"

interface IUIColorInput {
    value: string
    onChange: (string: string) => void
}

const UIColorInput = (props: IUIColorInput) => {
    return (
        <ColorInput
            size="xs"
            radius={2}
            value={props.value}
            withPicker
            styles={{
                root: {
                },
                input: {
                    backgroundColor: '#303030',
                    color: 'white',
                    borderColor: '#464646',
                }
            }}
            onChangeEnd={(e: any) => {
                console.log(e)
                props.onChange(e)
            }}
            // label={'Page background color'}
            format="hexa"
          />
    )
}

export { UIColorInput }