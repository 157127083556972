import { useState } from "react";
import {
  createStyles,
  UnstyledButton,
  Menu,
  Group,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { observer } from 'mobx-react';
import { LanguageSwitcherElementProps } from "../../../stores/data/space-store/languageSwitcherElement";

// const data = [
//   { label: "English", value: "en" },
//   { label: "German", value: "de" },
//   { label: "French", value: "fr" },
//   { label: "Portuguese", value: "pt" },
//   { label: "Spanish", value: "es" },
//   { label: "Chinese", value: "zh" },
//   { label: "Japanese", value: "ja" },
// ];

const useStyles = createStyles((theme, { opened, attributes }: { opened: boolean, attributes: LanguageSwitcherElementProps }) => ({
  control: {
    width: '100%',
    height: '100%',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0.4rem ${theme.spacing.xs}`,
    borderRadius: attributes.borderRadius,
    border: `${attributes.borderWidth}px solid ${attributes.borderColor}`,
    transition: "background-color 150ms ease",
    fontFamily: `'${attributes.typefaceId}'`,
    backgroundColor: attributes.bgColor!
  },

  label: {
    color: attributes.textColor,
    fontSize: attributes.fontSize,
    fontWeight: attributes.fontWeight
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

// const useStyles = createStyles((theme, attributes: FormElementProps) => ({
//   input: {
//     fontFamily: `'${attributes.fieldTypefaceId}'`,
//     backgroundColor: attributes.fieldBackgroundColor,
//     color: attributes.fieldTextColor,
//     padding: `${attributes.fieldPaddingTop}px ${attributes.fieldPaddingLeft}px`,
//     textAlign: attributes.fieldTextAlign,
//     fontSize: attributes.fieldFontSize,
//     fontWeight: attributes.fieldFontWeight,
//     borderRadius: attributes.fieldBorderRadius,
//     border: `${attributes.fieldBorderWidth}px solid ${attributes.fieldBorderColor}`,
//     '::placeholder': {
//       color: attributes.fieldTextColor,
//       opacity: 0.8,
//     },
//     '::-webkit-input-placeholder': {
//       color: attributes.fieldTextColor,
//       opacity: 0.8,
//     },

//     '::-ms-input-placeholder': {
//       color: attributes.fieldTextColor,
//       opacity: 0.8,
//     },
//   },
// }));

const LanguageSwitcherContent = (props: { onSelect: (withShift: boolean) => void, attributes: LanguageSwitcherElementProps, selectedClassName: string }) => {

  const [opened, setOpened] = useState(false);

  const { classes } = useStyles({ attributes: props.attributes, opened });

  const {
    id,
    x,
    y,
    width,
    height,
    // src,
    // alt,
    position,
    // objectFit,
    bgColor,
    zIndex,
    opacity,
    borderRadius,
    rotate,

    // fields,
    // buttonText,
    // formSubmitText,


    // // TODO!!!!!
    // // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // backgroundColor,

    // labelPaddingLeft,
    // labelPaddingTop,
    // labelFontSize,
    // labelFontWeight,
    // // labelLetterSpacing,
    // // labelLineHeight,
    // // labelTextDecoration,
    // labelTextColor,
    // labelTextAlign,
    // labelTypefaceId,

    // buttonPaddingLeft,
    // buttonPaddingTop,
    // buttonFontSize,
    // buttonFontWeight,
    // // buttonLetterSpacing,
    // // buttonLineHeight,
    // // buttonTextDecoration,
    // buttonTextColor,
    // buttonBackgroundColor,
    // buttonBorderRadius,
    // buttonBorderWidth,
    // buttonBorderColor,
    // buttonTypefaceId,
    // // buttonTextAlign,
    // // buttonTypefaceId,
    // // buttonFluid,
    // // buttonWidth,

    // formSubmitTextFontSize,
    // formSubmitTextFontWeight,
    // // formSubmitTextLetterSpacing,
    // // formSubmitTextLineHeight,
    // // formSubmitTextTextDecoration,
    // formSubmitTextTextColor,
    // formSubmitTextTextAlign,
    // formSubmitTextTypefaceId,

    // displaySavedState,
  } = props.attributes

  // const [selected, setSelected] = useState(data[0]);

  // const changeLanguage = (lang: string) => { }

  // const items = data.map((item) => (
  //   <Menu.Item
  //     onClick={() => changeLanguage(item.value)}
  //     key={item.label}
  //   >
  //     {item.label}
  //   </Menu.Item>
  // ));

  // return <div>awdawd</div>
  return (
    <div
      onClick={(e) => {
        props.onSelect(e.shiftKey)
      }}
      className={`${id}${props.selectedClassName ? ` ${props.selectedClassName}` : ''}`}
      style={{
        position,
        backgroundColor: bgColor || undefined,
        zIndex,
        opacity,
        borderRadius,
        top: 0,
        left: 0,
        width,
        height,
        transform: `translate(${x}px, ${y}px) rotate(${rotate || 0}deg)`,
      }}>
      <Menu
        onOpen={() => setOpened(true)}
        onClose={() => setOpened(false)}
        radius={props.attributes.borderRadius}
        width="target"
        withinPortal
      >
        <Menu.Target>
          <UnstyledButton className={classes.control}>
            <Group spacing="xs">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width={props.attributes.iconSize} height={props.attributes.iconSize} fill={props.attributes.iconColor}><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm87.63,96H175.8c-1.41-28.46-10.27-55.47-25.12-77A88.2,88.2,0,0,1,215.63,120ZM128,215.89c-18.73-20.27-30.09-49-31.77-79.89h63.54C158.09,166.87,146.73,195.62,128,215.89ZM96.23,120c1.68-30.87,13-59.62,31.77-79.89,18.73,20.27,30.09,49,31.77,79.89Zm9.09-77C90.47,64.53,81.61,91.54,80.2,120H40.37A88.2,88.2,0,0,1,105.32,43ZM40.37,136H80.2c1.41,28.46,10.27,55.47,25.12,77A88.2,88.2,0,0,1,40.37,136Zm110.31,77c14.85-21.56,23.71-48.57,25.12-77h39.83A88.2,88.2,0,0,1,150.68,213Z"></path></svg>
              <span className={classes.label}>
                {'English'}
              </span>
            </Group>
            <IconChevronDown size={props.attributes.caretSize} className={classes.icon} stroke={1.5} color={props.attributes.caretColor} />
          </UnstyledButton>
        </Menu.Target>
        {/* <Menu.Dropdown></Menu.Dropdown> */}
      </Menu>
    </div>
  );
}

export default observer(LanguageSwitcherContent);