import React, { useState } from "react";

import {
  Anchor,
  Paper,
  Text,
  Container,
  Group,
  Button,
  Title,
  Flex,
  TextInput,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useStore } from "../../../hooks/use-store";
import styles from "./register.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../../components/Logo";
import OtpEnterCodeScreen from "./OtpEnterCode";
import LanguageSwitcher from "../profile/LanguageSwitcher";
import { useDisclosure } from "@mantine/hooks";

interface FormProps {
  email: string;
}

const OtpLogin = () => {
  const {
    dataStore: { appStore },
  } = useStore();

  const [visible, { toggle }] = useDisclosure(false);
  
  const [enterCodeState, setEnterCodeState] = useState<{ id: null | string, email: null | string }>({ id: null, email: null });
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const register = async (data: FormProps) => {
    const res = await appStore.sendCode({
      email: data.email,
    });
    if (res.id) {
      setEnterCodeState({ id: res.id, email: data.email });
    } else {
      console.log(res)
    }
  };

  if (enterCodeState.id) {
    return <OtpEnterCodeScreen id={enterCodeState.id} email={enterCodeState.email!} />
  }

  return (
    <Flex className={styles.container} justify={'center'}>

      <Flex w={'420px'} mih={'100vh'} direction={'column'}>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ background: '#303030' }}>
          <Container size={500} mb={20} >
            <Flex justify={'center'} mb={30}>
              <Logo size={40} />
            </Flex>
            <Title
              mt={20}
              mb={20}
              color="white"
              align="center"
              size={24}
            >
              {t("welcome")}
            </Title>
            <Flex justify={'center'} mb={10}>
              <Title
                color="rgba(190, 190, 190, 1)"
                align="center"
                size={12}
              >
                {t("enter.email")}
              </Title>
            </Flex>
          </Container>
          <form onSubmit={form.onSubmit(async (values) => {
            toggle()
            await register(values)
            toggle()
          })}>

            <TextInput
              required
              type="email"
              placeholder="e@klykn.com"
              label={t("registerpage.email", { defaultValue: "Email" })}
              name={"email"}
              {...form.getInputProps('email')}
            />
            <Button
              fullWidth
              mt="xl"
              loading={visible}
              type="submit"
            // onClick={}
            >
              {t("continue")}
            </Button>
          </form>
          <Group position="apart" mt="lg">
            <Text size={'12px'}>
              {t("registerpage.agree")}{" "}
              <a
                href={"https://klykn.com/terms"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                {t("registerpage.terms")}
              </a>{" "}
              {t("registerpage.and")}{" "}
              <a
                target={"_blank"}
                rel={"noreferrer"}
                href={"https://klykn.com/privacy"}
              >
                {t("registerpage.conditions")}
              </a>
            </Text>
          </Group>
        </Paper>

        <Text color="white" size={'12px'} align="center" mt={15} mb={15}>
          {t("have.account")}&nbsp;
          <Link to={"/login-password"}>
            <Anchor color="white" size={'12px'} component="button">
              {t("login.password")}
            </Anchor>
          </Link>
        </Text>
        {/* <Flex ></Flex> */}
        <div style={{ flexGrow: 3 }}></div>
        <Flex justify={'center'} align={'flex-end'} mb={20}>
          <LanguageSwitcher />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OtpLogin;
