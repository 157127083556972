import { Accordion } from "@mantine/core"

export const SitePropertyAccordion = (props: {
    label: string | JSX.Element
    defaultChecked: boolean
    children: any
}) => {

    return (
        <Accordion
            defaultValue={props.defaultChecked ? 'elements' : 'none'}
            styles={{
                label: {
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: 'white',
                },
                chevron: {
                    marginRight: '-4px',
                },
                control: {
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    backgroundColor: 'transparent',
                    borderBottom: '1px solid #373A40',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                    '&:active': {
                        backgroundColor: 'transparent',
                    }
                },
                panel: {
                    paddingLeft: '0',
                    paddingRight: '0',
                },
                content: {
                    paddingLeft: '0',
                    paddingRight: '0',
                    paddingBottom: '0',
                    paddingTop: '0',
                }

            }}
        >
            <Accordion.Item key={'elements'} value={'elements'}>
                <Accordion.Control>{props.label}</Accordion.Control>
                <Accordion.Panel>
                    {props.children}
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    )
}