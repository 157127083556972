import {
    Modal, Text, Flex, Button,
    // Paper, 
    Divider
} from '@mantine/core';

import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { SitePropertyDivider } from './SitePropertyDivider';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';
// import { ProBadge } from './ProBadge';
import { useTranslation } from 'react-i18next';

export const Element = (props: {
    opened: boolean
    close: () => void
}) => {

    const {
        dataStore: { spaceStore },
        dataStore: { appStore },
    } = useStore();

    const { t } = useTranslation();

    const planType = spaceStore.planType || 'FREE'

    // const checks = spaceStore.checkBeforePublish()
    return (
        <Modal opened={props.opened} onClose={props.close} title={t("website.plan")} size={'lg'} padding={32}>

            <Flex gap={32}
                mb={32}
                mt={32}
            >
                <div style={{ width: '50%' }}>
                    <Flex align={'center'} gap={12} mb={12}>
                        <Text
                            style={{
                                color: 'white',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >

                            {t("free.plan")}
                        </Text>
                        {planType === 'FREE' ? (
                            <Flex align={'center'} gap={8} style={{ background: 'rgba(70, 70, 70, 1)', borderRadius: '12px', padding: '0px 12px', width: 'max-content' }}>
                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.99977 5.115C4.16785 5.115 5.11477 4.16808 5.11477 3C5.11477 1.83192 4.16785 0.884998 2.99977 0.884998C1.83168 0.884998 0.884766 1.83192 0.884766 3C0.884766 4.16808 1.83168 5.115 2.99977 5.115Z" fill="#36E678" />
                                </svg>
                                <Text
                                    style={{
                                        color: '#ffffff',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t("active")}

                                </Text>
                            </Flex>
                        ) : null}
                    </Flex>

                    <Text
                        style={{
                            color: 'rgba(190, 190, 190, 1)',
                            fontSize: '12px',
                            fontWeight: '400',
                        }}
                        mb={12}
                    >
                        {t("free.plan.description")}

                    </Text>

                </div>
                <div style={{ width: '50%' }}>
                    <Flex direction={'column'} style={{
                        borderRadius: '4px',
                        border: '1px solid rgba(115, 115, 115, 1)',
                    }}>
                        <Flex
                            style={{
                                borderRadius: '4px',
                                padding: '16px',
                            }}
                            gap={6}
                            direction={'column'}

                        >
                            {[
                                t("plan.features.free.subdomain"),
                                t("plan.features.free.limited.veiws"),
                                t("plan.features.free.badge"),
                                t("plan.features.free.preview"),
                            ].map(
                                (item) => {
                                    return (
                                        <Flex align={'center'} gap={8}>
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.99977 5.115C4.16785 5.115 5.11477 4.16808 5.11477 3C5.11477 1.83192 4.16785 0.884998 2.99977 0.884998C1.83168 0.884998 0.884766 1.83192 0.884766 3C0.884766 4.16808 1.83168 5.115 2.99977 5.115Z" fill="#36E678" />
                                            </svg>
                                            <Text
                                                style={{
                                                    color: '#BEBEBE',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {item}
                                            </Text>
                                        </Flex>
                                    )
                                }
                            )}
                        </Flex>
                    </Flex>
                </div>

            </Flex>

            {/* {
                checks.length > 0 ? (
                    <Paper shadow="xs" pos={'relative'} bg={'rgba(230, 120, 54, 0.15)'} pt={12} pb={12} pl={48} pr={32}>
                        <div style={{ position: 'absolute', top: '14px', left: '17px' }}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5067 10.6133L10.24 2.93334C9.66665 1.9 8.87332 1.33334 7.99998 1.33334C7.12665 1.33334 6.33332 1.9 5.75998 2.93334L1.49332 10.6133C0.953318 11.5933 0.893318 12.5333 1.32665 13.2733C1.75999 14.0133 2.61332 14.42 3.73332 14.42H12.2667C13.3867 14.42 14.24 14.0133 14.6733 13.2733C15.1067 12.5333 15.0467 11.5867 14.5067 10.6133ZM7.49998 6C7.49998 5.72667 7.72665 5.5 7.99998 5.5C8.27332 5.5 8.49998 5.72667 8.49998 6V9.33334C8.49998 9.60667 8.27332 9.83334 7.99998 9.83334C7.72665 9.83334 7.49998 9.60667 7.49998 9.33334V6ZM8.47332 11.8067C8.43998 11.8333 8.40665 11.86 8.37332 11.8867C8.33332 11.9133 8.29332 11.9333 8.25332 11.9467C8.21332 11.9667 8.17332 11.98 8.12665 11.9867C8.08665 11.9933 8.03998 12 7.99998 12C7.95998 12 7.91332 11.9933 7.86665 11.9867C7.82665 11.98 7.78665 11.9667 7.74665 11.9467C7.70665 11.9333 7.66665 11.9133 7.62665 11.8867C7.59332 11.86 7.55998 11.8333 7.52665 11.8067C7.40665 11.68 7.33332 11.5067 7.33332 11.3333C7.33332 11.16 7.40665 10.9867 7.52665 10.86C7.55998 10.8333 7.59332 10.8067 7.62665 10.78C7.66665 10.7533 7.70665 10.7333 7.74665 10.72C7.78665 10.7 7.82665 10.6867 7.86665 10.68C7.95332 10.66 8.04665 10.66 8.12665 10.68C8.17332 10.6867 8.21332 10.7 8.25332 10.72C8.29332 10.7333 8.33332 10.7533 8.37332 10.78C8.40665 10.8067 8.43998 10.8333 8.47332 10.86C8.59332 10.9867 8.66665 11.16 8.66665 11.3333C8.66665 11.5067 8.59332 11.68 8.47332 11.8067Z" fill="#E67836" />
                            </svg>

                        </div>
                        <Text fw={'bold'} size={14} color='white'>
                            {t("pro.disabled.features")}
                        </Text>
                        <Text mt={6} size={14} color='white'>
                            {t("pro.disabled.features.preview")}

                        </Text>
                        <Text mt={6} size={14} color='white'>
                            {t("pro.disabled.used.features")}
                        </Text>
                        {
                            checks.map((item) => (
                                <Flex ml={12} align={'center'} gap={8} mt={2} >
                                    <Text size={14} color='white'>
                                        - {item}
                                    </Text>
                                    <ProBadge />
                                </Flex>
                            ))
                        }


                    </Paper>
                ) : null
            } */}


            <SitePropertyDivider></SitePropertyDivider>

            <Flex gap={32}
                mb={32}
                mt={32}
            >
                <div style={{ width: '50%' }}>
                    <Flex align={'center'} gap={12} mb={12}>
                        <Text
                            style={{
                                color: 'white',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {t("pro.plan")}


                        </Text>
                        {planType !== 'FREE' ? (
                            <Flex align={'center'} gap={8} style={{ background: 'rgba(70, 70, 70, 1)', borderRadius: '12px', padding: '0px 12px', width: 'max-content' }}>
                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.99977 5.115C4.16785 5.115 5.11477 4.16808 5.11477 3C5.11477 1.83192 4.16785 0.884998 2.99977 0.884998C1.83168 0.884998 0.884766 1.83192 0.884766 3C0.884766 4.16808 1.83168 5.115 2.99977 5.115Z" fill="#36E678" />
                                </svg>
                                <Text
                                    style={{
                                        color: '#ffffff',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t("active")}
                                </Text>
                            </Flex>
                        ) : null}
                    </Flex>

                    <Text
                        style={{
                            color: 'rgba(190, 190, 190, 1)',
                            fontSize: '12px',
                            fontWeight: '400',
                        }}
                        mb={12}
                    >
                        {t("pro.plan.desc")}
                    </Text>
                </div>
                <div style={{ width: '50%' }}>
                    <Flex direction={'column'} style={{
                        borderRadius: '4px',
                        border: '1px solid rgba(115, 115, 115, 1)',
                    }}>
                        <Flex
                            style={{
                                borderRadius: '4px',
                                padding: '16px',
                            }}
                            gap={6}
                            direction={'column'}

                        >
                            {[
                                t("pro.plan.features.buy"),
                                t("pro.plan.features.animations"),
                                t("pro.plan.features.forms"),
                                t("pro.plan.features.custom.code"),
                                t("pro.plan.features.ai.image"),
                                t("pro.plan.features.sticky"),
                                t("pro.plan.features.no.branding"),
                                t("pro.plan.features.limited.views"),
                            ].map(
                                (item) => {
                                    return (
                                        <Flex align={'center'} gap={8}>
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.99977 5.115C4.16785 5.115 5.11477 4.16808 5.11477 3C5.11477 1.83192 4.16785 0.884998 2.99977 0.884998C1.83168 0.884998 0.884766 1.83192 0.884766 3C0.884766 4.16808 1.83168 5.115 2.99977 5.115Z" fill="#36E678" />
                                            </svg>
                                            <Text
                                                style={{
                                                    color: '#BEBEBE',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {item}
                                            </Text>
                                        </Flex>
                                    )
                                }
                            )}
                        </Flex>
                    </Flex>
                </div>

            </Flex>

            {planType !== 'FREE' ? (
                <Button
                    fullWidth={true}
                    loading={false}
                    styles={{
                        root: {
                            color: 'background: rgba(230, 230, 230, 1)',
                            height: '42px',
                            fontSize: '16px',
                            background: 'rgba(35, 108, 250, 1)',
                            border: 'none',
                            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                        }
                    }}
                    variant="default"
                    onClick={async () => {
                        const res = await appStore.getPortalLink({ siteId: spaceStore.siteId })

                        console.log(res)
                        if (res.url) {

                            let a = document.createElement('a');
                            a.target = '_blank';
                            a.href = res.url;
                            a.click();


                        } else {
                            NOTIFICATION_SERVICE.errorNotification('Something went wrong')
                        }
                    }}
                >
                    {t("pro.plan.manage.subscription")}
                </Button>) : (
                <>

                    <Flex
                        w={'100%'}
                        direction={'column'} gap={20} mt={20}>
                        <Flex


                            style={{
                                borderRadius: '4px',
                                border: '1px solid rgba(115, 115, 115, 1)',
                                padding: '20px'
                            }}
                            direction={'column'}
                            // gap={0}
                            pos={'relative'}
                        >
                            <Text color="#FFFFFF" fz="42px" fw={'900'} lh={'42px'} mb="2px">PRO</Text>
                            <Text color="#FFFFFF" fz="10px" fw={'500'} lh={'20px'} style={{
                                position: 'absolute', right: '282px', top: '30px',
                                background: '#403E4B',
                                borderRadius: '5px',
                                padding: '2px 6px',

                            }}>⚡️ LIMITED TIME: SAVE 66%</Text>
                            <Text color="#FFFFFF" fz="42px" fw={'900'} lh={'42px'} mb="2px" style={{ textDecoration: 'line-through' }}>$30 USD</Text>
                            <Flex align={'flex-end'} gap={4}>
                                <Text color="#09FF6B" fz="42px" fw={'900'} lh={'42px'} mb="2px">
                                    $10 USD
                                </Text>
                                <Text
                                    fw={'500'}
                                    style={{
                                        fontSize: '16pxpx',
                                        color: 'white',

                                    }}>/mo.</Text>
                            </Flex>
                            <Button
                                mt={20}
                                fullWidth={true}
                                loading={false}
                                styles={{
                                    root: {
                                        color: 'background: rgba(230, 230, 230, 1)',
                                        height: '42px',
                                        fontSize: '16px',
                                        background: 'rgba(35, 108, 250, 1)',
                                        border: 'none',
                                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                    }
                                }}
                                variant="default"
                                onClick={async () => {
                                    const res = await appStore.getCheckoutLink({ siteId: spaceStore.siteId })

                                    console.log(res)
                                    if (res.url) {

                                        let a = document.createElement('a');
                                        a.target = '_blank';
                                        a.href = res.url;
                                        a.click();


                                    } else {
                                        NOTIFICATION_SERVICE.errorNotification('Something went wrong')
                                    }
                                }}
                            >
                                {t("Start 7-Days Free Trial")}
                            </Button>
                        </Flex>
                    </Flex>

                </>

            )}

            <Divider my="xl" label={t("need.more")} labelPosition="center" />

            <Flex gap={32}
                mb={32}
                mt={32}
            >
                <div style={{ width: '50%' }}>
                    <Flex align={'center'} gap={12} mb={12}>
                        <Text
                            style={{
                                color: 'white',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            {t("enterprise")}
                        </Text>
                    </Flex>

                    <Text
                        style={{
                            color: 'rgba(190, 190, 190, 1)',
                            fontSize: '12px',
                            fontWeight: '400',
                        }}
                        mb={12}
                    >
                        {t("enterprise.desc")}
                        &nbsp;
                        <a href="mailto:lado@klykn.com?subject=Inquiry%20about%20Enterprise%20License&body=Dear%20[Recipient's%20Name],%0A%0AI%20am%20interested%20in%20learning%20more%20about%20your%20Enterprise%20License%20and%20would%20like%20to%20receive%20a%20quote%20for%20our%20organization.%20Could%20you%20please%20provide%20details%20on%20the%20features%20included,%20pricing%20options,%20and%20any%20special%20offers%20available?%20Additionally,%20I%20would%20appreciate%20information%20on%20the%20level%20of%20support%20and%20assistance%20offered%20to%20Enterprise%20License%20holders.%0A%0AThank%20you%20for%20your%20assistance.%0A%0AKind%20regards,%0A%0A[Your%20Name]">Request Enterprise License Quote</a>
                    </Text>

                </div>
                <div style={{ width: '50%' }}>
                    <Flex direction={'column'} style={{
                        borderRadius: '4px',
                        border: '1px solid rgba(115, 115, 115, 1)',
                    }}>
                        <Flex
                            style={{
                                borderRadius: '4px',
                                padding: '16px',
                            }}
                            gap={6}
                            direction={'column'}

                        >
                            {[
                                t("enterprise.unlimited.views"),
                                t("enterprise.unlimited.websites"),
                                t("enterprise.unlimited.ai"),
                            ].map(
                                (item) => {
                                    return (
                                        <Flex align={'center'} gap={8}>
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.99977 5.115C4.16785 5.115 5.11477 4.16808 5.11477 3C5.11477 1.83192 4.16785 0.884998 2.99977 0.884998C1.83168 0.884998 0.884766 1.83192 0.884766 3C0.884766 4.16808 1.83168 5.115 2.99977 5.115Z" fill="#36E678" />
                                            </svg>
                                            <Text
                                                style={{
                                                    color: '#BEBEBE',
                                                    fontSize: '14px',
                                                    fontWeight: '500',
                                                }}
                                            >
                                                {item}
                                            </Text>
                                        </Flex>
                                    )
                                }
                            )}
                        </Flex>
                    </Flex>
                </div>

            </Flex>

        </Modal >
    )
}

export const BillingModal = observer(Element);