import React, { useEffect, useState } from "react";
import { useStore } from "../../hooks/use-store";

import {
  createStyles,
  Button,
  Group,
  Card,
  // TextInput,
  // Modal,
  LoadingOverlay,
  Text,
  Popover,
  ActionIcon,
  Chip,
  MediaQuery,
  Center,
  Flex,
  Tooltip,
} from "@mantine/core";
import { modals } from '@mantine/modals';
// import { useTranslation } from "react-i18next";
import { useRequest } from "ahooks";
import { Link, useHistory } from "react-router-dom";
// import { useDisclosure } from "@mantine/hooks";
// import { useForm } from "@mantine/form";
// import { PLAN_TYPE } from "../../types";
import { IconCopy, IconPencil, IconTrash } from "@tabler/icons-react";
import { usePostHog } from "posthog-js/react";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../hooks/isMobile";

const useStyles = createStyles((theme) => ({

  card: {
    // backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    border: '1px solid rgba(70, 70, 70, 1)'
  },

  cardTitle: {
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1.2,
  },

  body: {
    padding: theme.spacing.md,
    background: '#303030',
  },

  wrapper: {
    height: "100%",
    maxWidth: "100%",
    width: "max-content",
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // background: '#303030',
  },

  title: {
    marginBottom: theme.spacing.sm,
    paddingLeft: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  center: {
    display: "flex",
    justifyContent: "center",
  },
  video: {
    margin: "0 auto",

    "> div": {
      position: "absolute!important" as any,
      transformOrigin: "left top",
      borderRadius: "8px",
      overflow: "hidden",
    },
  },
}));

export const SitesList = () => {
  const history = useHistory();
  const posthog = usePostHog()
  const isMobile = useIsMobile()
  const { classes } = useStyles();

  const [data, setData] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const { t } = useTranslation();

  const {
    dataStore: { appStore },
  } = useStore();

  const { loading, run } = useRequest(
    async () => appStore.getFunnelsListByUser(),
    {
      onSuccess: (model: any) => {
        setData(model);
        setInitialized(true)
      },
    }
  );

  const openModal = (id: string) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure? This action cannot be undone.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => {
      deleteFunnel(id)
    },
  });

  // const { loading: createFunnelLoading, runAsync: createFunnel } = useRequest(
  //   async (name: string) => appStore.createFunnel(name),
  //   {
  //     manual: true,
  //     onSuccess: (model: any) => {
  //       return model;
  //     },
  //   }
  // );
  const { runAsync: deleteFunnel } = useRequest(
    async (id: string) => appStore.deleteFunnel(id),
    {
      manual: true,
      onSuccess: (model: any) => {
        run();
        return model;
      },
    }
  );


  const { loading: createWebsiteLoading, runAsync: createWebsite } = useRequest(
    async (model: { name: string, freeDomain: string }) => appStore.createWebsite(model),
    {
      manual: true,
      onSuccess: (model: any) => {
        return model;
      },
    }
  );

  useEffect(() => {
    if (initialized && data.length === 0) {
      history.push('/new/site')
    }
  }, [data, history, initialized])

  const rows = data.map((element) => (
    <Card
      key={element.id}
      onClick={() => {
        posthog?.capture('Click on edit site')
        history.push(`/sites/${element.id}`)
      }}
      withBorder
      radius="md"
      p={0}
      className={classes.card}
      mt={10}
      mb={10}
      w={252}
      maw="100%"
    >
      <Flex direction={'column'}>
        <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
          <div style={{ background: '#f46a34', height: '150px', width: '100%' }}></div>
          {/* <Image src={element.data.background || 'https://ucarecdn.com/ed5028db-3ebc-442c-bcc6-fc1ab2e51f38/'} height={130} width={'100%'} /> */}
        </MediaQuery>
        <Flex className={classes.body} w={'100%'} direction={'column'} >
          <Flex style={{ position: 'absolute', top: '8px', left: '8px' }} align={'center'} >
            {element.lastPublished ? (
              <Chip color="teal" size="xs" radius="md" styles={{
                root: {
                  borderRadius: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  background: '#2e3a50'
                },
                label: {
                  background: '#2e3a50',
                  borderRadius: '2px',
                }
              }}>
                <Flex gap={6}
                  align={'center'}
                  h={'100%'}
                  style={{
                    background: '#2e3a50'
                  }}>
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.93668 1H4.74667C2.92667 1 1.84167 2.085 1.84167 3.905V8.09C1.84167 9.915 2.92667 11 4.74667 11H8.93167C10.7517 11 11.8367 9.915 11.8367 8.095V3.905C11.8417 2.085 10.7567 1 8.93668 1ZM9.51667 4.525L8.34667 8.295C8.06667 9.19 6.81168 9.205 6.51667 8.315L6.16667 7.28C6.07167 6.995 5.84667 6.765 5.56167 6.675L4.52167 6.325C3.64167 6.03 3.65167 4.765 4.54667 4.495L8.31667 3.32C9.05667 3.095 9.75168 3.79 9.51667 4.525Z" fill="#236CFA" />
                  </svg>

                  <Text color={'#236CFA'}>
                    {
                      t("launched")
                    }
                  </Text>
                </Flex>
              </Chip>
            ) : (
              <Chip color="teal" size="xs" radius="md" styles={{
                root: {
                  borderRadius: '2px',
                  display: 'flex',
                  alignItems: 'center'
                },
                label: {

                  borderRadius: '2px',
                }
              }}>
                <Flex gap={6} align={'center'} h={'100%'}>
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.93668 1H4.74667C2.92667 1 1.84167 2.085 1.84167 3.905V8.09C1.84167 9.915 2.92667 11 4.74667 11H8.93167C10.7517 11 11.8367 9.915 11.8367 8.095V3.905C11.8417 2.085 10.7567 1 8.93668 1ZM6.31667 8.755C6.17167 8.9 5.89667 9.04 5.69667 9.07L4.46667 9.245C4.42167 9.25 4.37667 9.255 4.33167 9.255C4.12667 9.255 3.93667 9.185 3.80167 9.05C3.63667 8.885 3.56667 8.645 3.60667 8.38L3.78167 7.15C3.81167 6.945 3.94667 6.675 4.09667 6.53L6.32667 4.3C6.36667 4.405 6.40667 4.51 6.46167 4.63C6.51167 4.735 6.56667 4.845 6.62667 4.945C6.67667 5.03 6.73167 5.11 6.77667 5.17C6.83167 5.255 6.89667 5.335 6.93667 5.38C6.96167 5.415 6.98167 5.44 6.99167 5.45C7.11667 5.6 7.26167 5.74 7.38667 5.845C7.42167 5.88 7.44167 5.9 7.45167 5.905C7.52667 5.965 7.60167 6.025 7.66667 6.07C7.74667 6.13 7.82667 6.185 7.91167 6.23C8.01167 6.29 8.12167 6.345 8.23167 6.4C8.34667 6.45 8.45167 6.495 8.55667 6.53L6.31667 8.755ZM9.52668 5.545L9.06668 6.01C9.03667 6.04 8.99667 6.055 8.95667 6.055C8.94167 6.055 8.92167 6.055 8.91167 6.05C7.89667 5.76 7.08667 4.95 6.79667 3.935C6.78167 3.88 6.79667 3.82 6.83667 3.785L7.30167 3.32C8.06168 2.56 8.78667 2.575 9.53167 3.32C9.91167 3.7 10.0967 4.065 10.0967 4.445C10.0917 4.805 9.90668 5.165 9.52668 5.545Z" fill="#989898" />
                  </svg>
                  <Text>
                    {
                      t("draft", {
                        defaultValue: "DRAFT",
                      })
                    }
                  </Text>
                </Flex>
              </Chip>


            )}
          </Flex>


          <Group spacing="lg" style={{ position: 'absolute', top: '8px', right: '8px' }}>
            <Center>
              <div onClick={(e) => {
                e.stopPropagation()
              }}>
                <Group spacing={0} position="right">
                  <Tooltip label={t("Edit website")} bg={'black'} color='white'>
                    <ActionIcon onClick={() => {
                      posthog?.capture('Click on edit site')
                    }} component={Link} to={`/sites/${element.id}`}>
                      <IconPencil size="1.4rem" stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label={t("Duplicate website")} bg={'black'} color='white'>
                    <ActionIcon
                      loading={createWebsiteLoading}
                      title="Copy site"
                      onClick={async () => {
                        posthog?.capture('Click on dublicate site')

                        const website = await createWebsite({
                          name: '',
                          freeDomain: element.freeDomain,
                        });
                        if (website) {
                          history.push(`/sites/${website.id}`);
                        }

                      }}
                    >
                      <IconCopy size="1.4rem" stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>


        
                    <ActionIcon onClick={() => openModal(element.id)}>
                      <IconTrash color="red" size="1.4rem" stroke={1.5} />
                    </ActionIcon>


                  {/* <Menu
                    transitionProps={{ transition: 'pop' }}
                    withArrow
                    position="bottom-end"
                    withinPortal
                  >
                    <Menu.Target>
                      <ActionIcon  >
                        <IconDots size="1rem" stroke={1.5} />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item icon={<IconMessages size="1rem" stroke={1.5} />}>Assign custom domain</Menu.Item>
                      <Menu.Item icon={<IconReportAnalytics size="1rem" stroke={1.5} />}>
                        Analytics (soon)
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu> */}
                </Group>
              </div>
            </Center>
          </Group>
          <Text className={classes.cardTitle} mt="xs" mb="md">
            {element.domain ? `https://${element.domain}` : `https://${element.freeDomain}.klykn.com`}
          </Text>
          <Group position="apart" spacing="xs">
            <Text size="xs" color="dimmed">
              Edited {new Date(element.updated_at).toLocaleString()}
            </Text>
          </Group>
        </Flex>
      </Flex>
    </Card>
  ));



  if (isMobile) {
    return (
      <Flex h="100%" w={'100%'} align={'center'} justify={'center'} p={24}>
        <Text align="center">For an enhanced experience, please enter Klykn on your desktop.</Text>
      </Flex>
    )
  }
  return (
    <div>
      <Flex justify={'space-between'} align={'center'}>

        <Text size={'24px'} fw='bold' color='white'>
          {t("my.websites", {
            defaultValue: "My Websites",
          })}

        </Text>

        {!loading && (
          <Group position="center" mt="xl">
            {rows.length < 50 ? (
              <Button
                component="a"
                href="/new"
                // loading={createFunnelLoading}
                // onClick={async () => {
                //   const funnel = await createFunnel('');
                //   if (funnel) {
                //     history.push(`/sites/${funnel.id}`);
                //   }
                // }}
                color="blue"
              >
                {t("add.new", {
                  defaultValue: "Add new",
                })}
              </Button>
            ) : (
              <Popover width={200} position="bottom" withArrow shadow="md">
                <Popover.Target>
                  <Button
                    color="blue"
                  >
                    {t("add.new", {
                      defaultValue: "Add new",
                    })}
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <Text size="sm">
                    {t("limit.reached", {
                      defaultValue: "Free limit reached. Upgrade to create unlimited websites.",
                    })}
                  </Text>
                </Popover.Dropdown>
              </Popover>
            )}
          </Group>
        )}
      </Flex>
      <div className={classes.wrapper}>
        <div>
          <LoadingOverlay visible={loading} overlayBlur={2} mih={200} />
          <Flex gap={32} wrap={'wrap'}>
            {rows}
          </Flex>
        </div>
      </div>
    </div>
  );
};
