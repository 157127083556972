import { Modal, Paper, SimpleGrid, Text, Flex } from '@mantine/core';
import {
    IconArrowUpRight,
    IconArrowDownRight,
} from '@tabler/icons-react';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid,  XAxis, YAxis } from 'recharts'

export const Element = () => {

    const {
        dataStore: { spaceStore },
        dataStore: { appStore },
    } = useStore();

    const [stat, setStat] = useState<any>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loading, runAsync } = useRequest(async () => appStore.getStatisticsByWebsiteId(spaceStore.siteId), {
        manual: true,
        onSuccess: (model: any) => {
            console.log(model)

            try {
                const facts = model.find((itm: any) => itm.data.domain.facts)

                const views = model.find((itm: any) => itm.data.domain.statistics?.views)
                const durations = model.find((itm: any) => itm.data.domain.statistics?.durations)
    
                setStat({
                    facts: facts.data.domain.facts,
                    views: views.data.domain.statistics.views,
                    durations: durations.data.domain.statistics.durations,
                })
            } catch (error) {
                console.error(error)
            }
        },
    });

    useEffect(() => {
        if (spaceStore.statisticsModalOpen) {
            runAsync();
        }
    }, [spaceStore.statisticsModalOpen, runAsync]
    )

    if (!stat.facts) {
        return null
    }
    const stats = [
        {
            title: 'Active visitors',
            value: stat.facts.activeVisitors,
            diff: 0,
            type: 'visitors'
        },
        {
            title: 'Average views',
            value: stat.facts.averageViews?.count,
            diff: stat.facts.averageViews?.change,
            type: 'per day'
        },
        {
            title: 'Average duration',
            value: (stat.facts.averageDuration?.count / 1000).toFixed(0),
            diff: stat.facts.averageDuration?.change,
            type: 'seconds'
        },
        {
            title: 'Views today',
            value: stat.facts.viewsToday,
            diff: 0,
            type: 'views'
        }, {
            title: 'Views this month',
            value: stat.facts.viewsMonth,
            diff: 0,
            type: 'views'
        }, {
            title: 'Views this year',
            value: stat.facts.viewsYear,
            diff: 0,
            type: 'views'
        },
    ].map((st) => {
        const DiffIcon = st.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

        return (
            <Paper withBorder p="md" radius="md" key={st.title}>
                <Flex justify="space-between">
                    <Text size="xs" c="dimmed">
                        {st.title}
                    </Text>
                </Flex>

                <Flex align="flex-end" gap="xs" mt={25}>
                    <Text fw={600} fz={'34px'} lh={'30px'}>{st.value}</Text>
                    {st.type ? <Text fz={'12px'} lh={'12px'}>{st.type}</Text> : ''}
                    {
                        st.diff !== 0 ? <Text c={st.diff > 0 ? 'teal' : 'red'} fz="14px" fw={500} lh={'14px'}>
                            <span>{st.diff}%</span>
                            <DiffIcon size="1rem" stroke={1.5} />
                        </Text> : null
                    }

                </Flex>
            </Paper>
        );
    });

    const views = stat.views.map((itm: any, index: number) => ({
        name: `${index + 1} days ago`,
        count: itm.count,
    })).reverse()
    const durations = stat.durations.map((itm: any, index: number) => ({
        name: `${index + 1} days ago`,
        count: itm.count,
    })).reverse()
    return (
        <Modal padding={'30px 40px'} opened={spaceStore.statisticsModalOpen} onClose={() => spaceStore.toggleStatisticModal()} title="Statistics" size={'auto'} styles={{
            content: {
                minWidth: '800px'
            }
        }}>
            <SimpleGrid cols={3}>{stats}</SimpleGrid>
            <Paper withBorder p="md" radius="md" mt={20}>
                <Text fw={600} fz={20} mb={16}>Unique Views</Text>
                <BarChart width={770} height={340} data={views}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" fontSize={10} />
                    <YAxis fontSize={10} />
                    <Bar dataKey="count" fill="rgb(35, 108, 250)" />
                </BarChart>
            </Paper>
            <Paper withBorder p="md" radius="md" mt={20}>
                <Text fw={600} fz={20} mb={16}>Session duration</Text>
                <BarChart width={770} height={340} data={durations}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" fontSize={10} />
                    <YAxis fontSize={10} />
                    <Bar dataKey="count" fill="rgb(35, 108, 250)" />
                </BarChart>
            </Paper>
        </Modal >
    )
}

export const SiteStatisticsModal = observer(Element);