import React, { useEffect, useState } from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

type PropType = {
  slides: string[]
  options?: EmblaOptionsType,
  objectFit?: string
  borderRadius: number
  delay?: number
}

export const EmblaCarousel = (props: PropType) => {
  const waitBeforeShow = 10
  console.log("rerender");
  const [isShown, setIsShown] = useState(false);
  const [prevProps, setPrevProps] = useState<any>({});

  useEffect(() => {
    if (prevProps.delay !== props.delay || prevProps.options?.duration !== props.options?.duration) {
      setIsShown(false);
      setPrevProps(props)
      const timer = setTimeout(() => {

        setIsShown(true);
      }, waitBeforeShow);
      return () => clearTimeout(timer);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.delay, props.options]);

  return isShown ? (
    <Element
      {...props}
    />
  ) : null;
};

export const Element: React.FC<PropType> = (props) => {
  const { slides, options } = props
  const [emblaRef] = useEmblaCarousel(options, [Autoplay({
    delay: props.delay
  })])

  console.log(slides)
  return (
    <div className="embla" style={{
      borderRadius: props.borderRadius,

    }}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((value, index) => (
            <div className="embla__slide" key={index}>
              <img
                className="embla__slide__img"
                src={value}
                style={{
                  objectFit: props.objectFit as any,
                  width: '100%',
                  height: '100%',
                }}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

