import { Button, Flex, Text, rem } from "@mantine/core";
import LanguageSwitcher from "../profile/LanguageSwitcher";
import { useHistory } from "react-router-dom";
import useIsMobile from "../../../hooks/isMobile";
import Placeholder from "../gen/Placeholder";

export const NewWebsiteScreen = () => {
    const isMobile = useIsMobile()

    const history = useHistory();


    return (
        <Flex w={'100%'} h='100%' wrap={'wrap'}>
            <Flex w={isMobile ? '100%' : '35%'} direction={'column'} bg={'rgba(48, 48, 48, 1)'}>
                <Flex p="30px" gap={6} justify={'space-between'} align={'center'}>
                    {/* <div>Back</div> */}
                    <LanguageSwitcher />
                </Flex>
                <div style={{
                    padding: '30px 60px'
                }}>
                    <Text color="white" size={28} weight={'700'} mt={10} mb={10} align="left">
                        Let's get started.
                    </Text>
                    <Text color="rgba(230, 230, 230, 1)" size={18} weight={'400'} mt={20} mb={10}>
                        Let our AI craft your initial draft, or select from our ready-made templates to get started.
                    </Text>

                    <Flex direction={'column'} mt={20}>
                        <Button

                            mb={20}
                            styles={{
                                root: {
                                    color: 'background: rgba(230, 230, 230, 1)',
                                    height: rem(42),
                                    // width: '50%',
                                    fontSize: '18px',
                                    background: 'rgba(35, 108, 250, 1)',
                                    border: 'none',
                                    position: 'relative',
                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                }
                            }}
                            variant="default"
                            loading={false}
                            fullWidth={true}
                            onClick={async () => {

                                history.push(`/new/site`);
                            }}
                        >
                            Generate with AI
                        </Button>
                        <Button
                            mb={20}
                            fullWidth={true}
                            styles={{
                                root: {
                                    color: 'background: rgba(230, 230, 230, 1)',
                                    height: rem(42),
                                    // width: '50%',
                                    fontSize: '18px',
                                    background: 'rgba(35, 108, 250, 1)',
                                    border: 'none',
                                    position: 'relative',
                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                }
                            }}
                            variant="default"
                            loading={false}
                            onClick={async () => {

                                history.push(`/templates`);
                            }}
                        >
                            Select a Template
                        </Button>
                    </Flex>
                </div>
            </Flex>
            <Flex w={isMobile ? '100%' : '65%'}>
                <Flex align={'ceter'} justify={'center'} w={'100%'} pos='relative' bg="#464646" >
                <Placeholder />
                </Flex>
            </Flex>
        </Flex >
    );
}