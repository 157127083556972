// import Moveable from "react-moveable";
import InfiniteViewer from "react-infinite-viewer";
// import Guides from "@scena/react-guides";
import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { useStore } from "../../../hooks/use-store";
import * as WebFont from 'webfontloader'
import Site from "./SiteContent";
import { Flex, Text, Popover, Tooltip, createStyles } from "@mantine/core";
import { CONTENT_TYPE, TypefaceConfig } from "./helper";
import { GroupingElementsButton } from "./GroupingElementsButton";
// import { ProBadge } from "../ProBadge";
import { useTranslation } from "react-i18next";
import KeyboardController from "./KeyboardController";

const useStyles = createStyles((theme) => ({
  element: {
    position: 'relative',
    borderBottom: '1px solid rgba(70, 70, 70, 1)',
    background: '#303030',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(18, 18, 18, 1)'
    }
  },
}));

const SitePreview = () => {
  const viewerRef = useRef<InfiniteViewer>(null);

  // const [zoom, setZoom] = useState(1);

  const { t } = useTranslation();

  const { classes } = useStyles();
  const {
    dataStore: { spaceStore },
  } = useStore();

  useEffect(() => {
    setTimeout(() => {
      let zoom = 0.5
      if (spaceStore.currentViewport === 320) {
        zoom = 1
      }
      if (spaceStore.currentViewport === 810) {
        zoom = 0.7
      }

      if (viewerRef.current) {
        viewerRef.current.setZoom(zoom)
        spaceStore.updateZoom(zoom)
        viewerRef.current.scrollTo(0, 0)
        viewerRef.current.scrollCenter({
          horizontal: true,
          vertical: false,
        })
      }
    }, 0);
  }, [spaceStore, spaceStore.currentViewport]);

  useEffect(() => {
    console.log('Fonts loading',)
    const fonts = Object.keys(spaceStore.websiteData.typeface).map(key => spaceStore.websiteData.typeface[key])

    const googleFonts = fonts.filter(itm => itm.provider === 'google')
    const typekitFonts = fonts.filter(itm => itm.provider === 'typekit')
    const fontdeskFonts = fonts.filter(itm => itm.provider === 'fontdesk')
    const fontscomFonts = fonts.filter(itm => itm.provider === 'fontscom')

    if (googleFonts.length > 0) {
      WebFont.load({
        google: {
          families: googleFonts.map(itm => `${itm.name}:100,200,300,400,500,600,700,800,900`)
        }
      });
    }

    if (typekitFonts.length > 0) {
      for (const font of typekitFonts) {
        let abobe = font as TypefaceConfig.Typekit
        WebFont.load({
          typekit: {
            id: abobe.id,
          }
        });
      }
    }


    if (fontdeskFonts.length > 0) {
      for (const font of fontdeskFonts) {
        let fontdesk = font as TypefaceConfig.Fontdesk
        WebFont.load({
          fontdeck: {
            id: fontdesk.id,
          }
        });
      }
    }

    if (fontscomFonts.length > 0) {
      for (const font of fontscomFonts) {
        let fontscom = font as TypefaceConfig.FontsCom
        WebFont.load({
          monotype: {
            projectId: fontscom.projectId,
          }
        });
      }
    }

  }, [spaceStore.websiteData.typeface, spaceStore.allFonts])


  // const scrollOptions = {
  //   container: () => viewerRef.current!.getContainer(),
  //   threshold: 20,
  //   getScrollPosition: () => {
  //     return [
  //       viewerRef.current!.getScrollLeft({ absolute: true }),
  //       viewerRef.current!.getScrollTop({ absolute: true }),
  //     ];
  //   },
  // };

  //  let unit = 50;

  // if (zoom < 0.8) {
  //   unit = Math.floor(1 / zoom) * 50;
  // }
  return (<div className="container">
    {/* <button className="button" onClick={() => {
      viewerRef.current!.scrollCenter();
    }} style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      zIndex: 1,
    }}>Scroll Center</button> */}

    {/* <Guides
      type="horizontal"
      snapThreshold={5}
      snaps={[0, 600, 1440]}
      displayDragPos={true}
      dragPosFormat={(v) => `${v}px`}
      zoom={spaceStore.zoom}
      unit={50}
    ></Guides>
    <Guides
      type="vertical"
      snapThreshold={5}
      snaps={[0, 600, 1440]}
      displayDragPos={true}
      dragPosFormat={(v) => `${v}px`}
      zoom={spaceStore.zoom}
      unit={50}
    ></Guides> */}
    <KeyboardController />
    <GroupingElementsButton />
    {
      spaceStore.selected || spaceStore.selectedWithShift.length > 0 ? (
        <div style={{ position: 'absolute', top: '24px', right: '12px', zIndex: '1' }}>
          <div style={{
            background: 'rgba(35, 108, 250, 1)',
            width: 32,
            height: 32,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
            onClick={() => spaceStore.selectElement(null, false)}
          >
            <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.17163 10.8284L10.8285 5.17152" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10.8285 10.8285L5.17163 5.17163" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

          </div>
        </div>
      ) : (
        <div style={{ position: 'absolute', top: '24px', right: '12px', zIndex: '99' }}>
          <Popover position="bottom" withArrow shadow="md"
            styles={
              {
                dropdown: {
                  padding: 0,
                  borderRadius: 0,
                }
              }
            }>
            <Popover.Target>
              <div style={{
                background: 'rgba(35, 108, 250, 1)',
                width: 32,
                height: 32,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 8H12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8 12V4" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </Popover.Target>
            <Popover.Dropdown >
              <div>
                <Tooltip label={t("editor.text")} color="gray" position="left" bg='black'
                >
                  <div
                    className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.TEXT)
                    }}
                  >
                    {/* text */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.77979 4.77998V3.56665C1.77979 2.79998 2.39978 2.18665 3.15978 2.18665H12.8398C13.6065 2.18665 14.2198 2.80665 14.2198 3.56665V4.77998" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8 13.8133V2.73999" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.37354 13.8134H10.6269" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>
                <Tooltip label={t("editor.button")} color="gray" position="left" bg='black'
                >
                  <div
                    className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.BUTTON)
                    }}
                  >
                    {/* button */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.563477 7.91058C0.563477 5.82559 2.25369 4.13538 4.33868 4.13538H11.5819C13.6669 4.13538 15.3571 5.82559 15.3571 7.91058C15.3571 9.99557 13.6669 11.6858 11.5819 11.6858H4.33868C2.2537 11.6858 0.563477 9.99557 0.563477 7.91058Z" stroke="#BEBEBE" />
                      <path d="M4.93164 7.9834L11.0679 7.98336" stroke="#BEBEBE" strokeLinecap="round" />
                    </svg>

                  </div>
                </Tooltip>


                <Tooltip label={t("editor.shape")} color="gray" position="left" bg='black'>

                  <div className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.SHAPE, {
                        type: 'SHAPE',
                      })
                    }}
                  >
                    {/* shape */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.3135 8.16663V11.1666C11.3135 13.6666 10.3135 14.6666 7.81348 14.6666H4.81348C2.31348 14.6666 1.31348 13.6666 1.31348 11.1666V8.16663C1.31348 5.66663 2.31348 4.66663 4.81348 4.66663H7.81348C10.3135 4.66663 11.3135 5.66663 11.3135 8.16663Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14.6468 5.00004C14.6468 6.91337 13.1868 8.48004 11.3135 8.64671V8.16671C11.3135 5.66671 10.3135 4.66671 7.8135 4.66671H7.3335C7.50016 2.79337 9.06683 1.33337 10.9802 1.33337C12.8402 1.33337 14.3735 2.71337 14.6068 4.51337C14.6335 4.66671 14.6468 4.83337 14.6468 5.00004Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>

                <Tooltip label={t("editor.image")} color="gray" position="left" bg='black'>
                  <div className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.IMAGE, {
                        type: 'IMAGE',
                      })
                    }}>
                    {/* image */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.00016 14.6667H10.0002C13.3335 14.6667 14.6668 13.3334 14.6668 10V6.00004C14.6668 2.66671 13.3335 1.33337 10.0002 1.33337H6.00016C2.66683 1.33337 1.3335 2.66671 1.3335 6.00004V10C1.3335 13.3334 2.66683 14.6667 6.00016 14.6667Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.99984 6.66667C6.73622 6.66667 7.33317 6.06971 7.33317 5.33333C7.33317 4.59695 6.73622 4 5.99984 4C5.26346 4 4.6665 4.59695 4.6665 5.33333C4.6665 6.06971 5.26346 6.66667 5.99984 6.66667Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.77979 12.6333L5.06645 10.4266C5.59312 10.0733 6.35312 10.1133 6.82645 10.52L7.04645 10.7133C7.56645 11.16 8.40645 11.16 8.92645 10.7133L11.6998 8.33329C12.2198 7.88662 13.0598 7.88662 13.5798 8.33329L14.6665 9.26662" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>

                <Tooltip label={t("editor.video")} color="gray" position="left" bg='black'
                >
                  <div className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.IMAGE, {
                        type: 'VIDEO',
                      })
                    }}>
                    {/* video */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.6668 10V6.00004C14.6668 2.66671 13.3335 1.33337 10.0002 1.33337H6.00016C2.66683 1.33337 1.3335 2.66671 1.3335 6.00004V10C1.3335 13.3334 2.66683 14.6667 6.00016 14.6667H10.0002C13.3335 14.6667 14.6668 13.3334 14.6668 10Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.68018 4.73999H14.3202" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.68018 1.40662V4.64662" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.3198 1.40662V4.34662" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.5 9.63333V8.83333C6.5 7.80666 7.22667 7.38666 8.11333 7.9L8.80667 8.3L9.5 8.7C10.3867 9.21333 10.3867 10.0533 9.5 10.5667L8.80667 10.9667L8.11333 11.3667C7.22667 11.88 6.5 11.46 6.5 10.4333V9.63333V9.63333Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>

                <Tooltip
                  label={
                    <Flex align={'center'} gap={8}>
                      <Text>
                        {t("editor.code.element")}
                      </Text>
                      {/* <ProBadge /> */}
                    </Flex>
                  }
                  color="gray"
                  position="left"
                  bg='black'>
                  <div className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.SHAPE, {
                        type: 'CODE',
                      })
                    }}
                  >
                    {/* code */}

                    <div style={{ position: 'absolute', top: '-7px', right: '7px' }}>
                      <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="6" height="6" rx="3" fill="#236CFA" />
                      </svg>
                    </div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.00018 8.66663C5.56018 8.88663 5.19351 9.21329 4.92018 9.61996C4.76684 9.85329 4.76684 10.1466 4.92018 10.38C5.19351 10.7866 5.56018 11.1133 6.00018 11.3333" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M10.1401 8.66663C10.5801 8.88663 10.9468 9.21329 11.2201 9.61996C11.3735 9.85329 11.3735 10.1466 11.2201 10.38C10.9468 10.7866 10.5801 11.1133 10.1401 11.3333" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6.00016 14.6667H10.0002C13.3335 14.6667 14.6668 13.3334 14.6668 10V6.00004C14.6668 2.66671 13.3335 1.33337 10.0002 1.33337H6.00016C2.66683 1.33337 1.3335 2.66671 1.3335 6.00004V10C1.3335 13.3334 2.66683 14.6667 6.00016 14.6667Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.48682 5.34004L14.3002 5.33337" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>

                <Tooltip label={t("editor.slideshow")} color="gray" position="left" bg='black'

                >
                  <div
                    className={classes.element}

                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.IMAGE, {
                        type: 'SLIDER',
                      })
                    }}
                  >
                    {/* slideshow */}
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4.66671V11.3334C12 11.7467 11.9867 12.1134 11.94 12.44C11.7467 14.1934 10.92 14.6667 8.66667 14.6667H7.33333C5.08 14.6667 4.25333 14.1934 4.06 12.44C4.01333 12.1134 4 11.7467 4 11.3334V4.66671C4 4.25337 4.01333 3.88671 4.06 3.56004C4.25333 1.80671 5.08 1.33337 7.33333 1.33337H8.66667C10.92 1.33337 11.7467 1.80671 11.94 3.56004C11.9867 3.88671 12 4.25337 12 4.66671Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.00016 11.3333C4.00016 11.7467 4.0135 12.1133 4.06016 12.44C3.96683 12.4467 3.88016 12.4467 3.78016 12.4467H3.5535C1.78016 12.4467 1.3335 12 1.3335 10.22V5.78001C1.3335 4.00001 1.78016 3.55334 3.5535 3.55334H3.78016C3.88016 3.55334 3.96683 3.55334 4.06016 3.56001C4.0135 3.88668 4.00016 4.25334 4.00016 4.66668V11.3333Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M14.6666 5.78001V10.22C14.6666 12 14.2199 12.4467 12.4466 12.4467H12.2199C12.1199 12.4467 12.0333 12.4467 11.9399 12.44C11.9866 12.1133 11.9999 11.7467 11.9999 11.3333V4.66668C11.9999 4.25334 11.9866 3.88668 11.9399 3.56001C12.0333 3.55334 12.1199 3.55334 12.2199 3.55334H12.4466C14.2199 3.55334 14.6666 4.00001 14.6666 5.78001Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>

                <Tooltip
                  label={
                    <Flex align={'center'} gap={8}>
                      <Text>
                        {t("editor.form.element")}

                      </Text>
                      {/* <ProBadge /> */}
                    </Flex>
                  }
                  color="gray"
                  position="left"
                  bg='black'
                >
                  <div className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.FORM, {
                      })
                    }}>
                    {/* form */}
                    <div style={{ position: 'absolute', top: '-7px', right: '7px' }}>
                      <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="6" height="6" rx="3" fill="#236CFA" />
                      </svg>
                    </div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 4.66671V11.3334C14 13.3334 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3334 2 11.3334V4.66671C2 2.66671 3 1.33337 5.33333 1.33337H10.6667C13 1.33337 14 2.66671 14 4.66671Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.3335 8.66663H8.00016" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.3335 11.3334H10.6668" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>
                <Tooltip
                  label={
                    <Flex align={'center'} gap={8}>
                      <Text>
                        {t("editor.languageSwitcher.element")}

                      </Text>
                      {/* <ProBadge /> */}
                    </Flex>
                  }
                  color="gray"
                  position="left"
                  bg='black'
                >
                  <div className={classes.element}
                    onClick={() => {
                      spaceStore.addElement(CONTENT_TYPE.LANGUAGESWITCHER, {
                      })
                    }}>
                    {/* form */}
                    <div style={{ position: 'absolute', top: '-7px', right: '7px' }}>
                      <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="6" height="6" rx="3" fill="#236CFA" />
                      </svg>
                    </div>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14 4.66671V11.3334C14 13.3334 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3334 2 11.3334V4.66671C2 2.66671 3 1.33337 5.33333 1.33337H10.6667C13 1.33337 14 2.66671 14 4.66671Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.3335 8.66663H8.00016" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.3335 11.3334H10.6668" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </Tooltip>

              </div>
            </Popover.Dropdown>
          </Popover>
        </div>
      )
    }


    <div style={{ position: 'absolute', bottom: '12px', right: '12px', zIndex: '99999' }}>
      <div style={{
        borderRadius: '4px',
        background: ' #303030',
        color: '#BEBEBE',
        padding: '4px',
        fontSize: '10px',
      }}>{((spaceStore.zoom) * 100).toFixed(1)}%</div>
    </div>
    <InfiniteViewer className="infinite-viewer"
      zoom={spaceStore.zoom}
      useMouseDrag={true}
      useWheelScroll={true}
      zoomRange={spaceStore.currentViewport === 320 ? [0.1, 8] : [0.1, 4]}
      maxPinchWheel={5}

      onPinch={e => {
        spaceStore.updateZoom(e.zoom)
      }}
      onDragStart={e => {
        if (e.inputEvent.target.className === 'infinite-viewer-scroll-area'){
          spaceStore.selectElement(null, false)
        }
        console.log(e.inputEvent.target.className, 'e.inputEvent.target.className')
        if (e.inputEvent.target.className !== 'cvs') {
          e.stop();
        }
      }}
      // useMouseDrag: true,
      // useWheelScroll: true,
      // useAutoZoom: true,
      // zoomRange={[0.1, 10]}
      // maxPinchWheel: 10,
      rangeX={[-1400, 1400]}
      rangeY={[-400, 99999]}
      onScroll={e => {
        spaceStore.scrollOffset = e.scrollTop
        // @ts-ignore
        spaceStore.viewportScrollHeight = e.currentTarget.viewportScrollHeight
      }}
      ref={viewerRef}
    >
      <div
        className="viewport"
        style={{
          width: spaceStore.currentViewport,
          height: spaceStore.getActivePageHeight,
          border: "1px solid #252525",
          background: spaceStore.page?.backgroundColor!,
        }}>

        <Site />
        {/* <PreviewElement
          id='target11212122'
          zoom={zoom}
        /> */}
        {/* <div className="target" style={{ zIndex: 111 }}>Target</div>
        <Moveable
          zoom={1 / zoom}
          scrollable={true}
          // scrollOptions={scrollOptions}
          target={".target"}
          draggable={true}
          resizable={true}
          rotatable={true}
          snappable={false}
          pinchable={false}
          onDrag={e => {
            console.log('ondrag', e);
            // e.target.style.cssText += e.transform;
          }}
          onRender={e => {
            console.log(e);
            e.target.style.cssText += e.cssText;
          }}

        ></Moveable> */}
      </div>
    </InfiniteViewer>
  </div>);
}

export default observer(SitePreview);


