import { useStore } from '../../../hooks/use-store';
import Moveable from 'react-moveable';
import { useEffect, useRef } from 'react';

export function ShiftGroupElementComponent(props: { zoom: number, idsMap: string[], selectedWithShift: string[] }) {

  const {
    dataStore: { spaceStore },
  } = useStore();

  const moveableRef = useRef<Moveable>(null);

  const {
    zoom,
  } = props
  useEffect(() => {
    try {
      moveableRef.current?.setState({
        target: [`.selectedShift${props.selectedWithShift.length}`]
      }, () => {
        try {
          moveableRef.current?.forceUpdate()
          moveableRef.current?.updateRect();
        } catch (error) {
          console.log('error', error)
        }
      });
    } catch (error) {
      console.log('error', error)
    }

  }, [props.selectedWithShift.length])

  const snapHorizontalThreshold = zoom < 1 ? 5 : 1

  return (
    <Moveable
      ref={moveableRef}
      zoom={1 / zoom}
      target={props.selectedWithShift.length > 0 ? `.selectedShift${props.selectedWithShift.length}`: null}
      draggable={true}
      resizable={true}
      persistData={{
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      }}
      pinchable={false}

      snappable={true}
      isDisplaySnapDigit={true}
      isDisplayInnerSnapDigit={false}
      hideChildMoveableDefaultLines={false}
      snapGap={true}
      snapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
      elementSnapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
      snapThreshold={0}
      maxSnapElementGuidelineDistance={300}
      useAccuratePosition={true}
      elementGuidelines={props.idsMap}
      snapHorizontalThreshold={snapHorizontalThreshold}
      snapVerticalThreshold={snapHorizontalThreshold}
      verticalGuidelines={
        [0, 20, 720, 1420, 1440,]
      }
      horizontalGuidelines={
        [0, 1440, 600,]
      }
      rotatable={true}
      onDragGroup={({ events }) => {
        events.forEach(ev => {
          ev.target.style.transform = ev.transform;
        });
      }}
      onResizeGroup={({ events }) => {
        events.forEach(ev => {
          ev.target.style.width = `${ev.width}px`;
          ev.target.style.height = `${ev.height}px`;
          ev.target.style.transform = ev.drag.transform;
        });
      }}
      onRotateGroup={({ events }) => {
        events.forEach(ev => {
          ev.target.style.transform = ev.drag.transform;
        });
      }}
      onRenderGroupEnd={({ events }) => {
        events.forEach(e => {
          const cssText = e.target.style.cssText
          let width = undefined
          if (cssText.includes('width: ')) {
            width = +cssText.split('width: ')[1].split('px')[0]
          }
          let height = undefined
          if (cssText.includes('height: ')) {
            height = +cssText.split('height: ')[1].split('px')[0]
          }

          let rotate = undefined
          if (e.transform.includes('rotate(')) {
            rotate = +e.transform.split('rotate(')[1].split('deg')[0]
          }

          spaceStore.updateElementProps({
            id: e.target.className.split(' ')[0],
            x: e.transformObject.translate[0],
            y: e.transformObject.translate[1],
            width,
            height,
            rotate,
          })
        });
      }}
    />
  );
}
