import React from "react";

import {
  Container,
  Flex,
  PinInput,
  Title,
} from "@mantine/core";

import { useStore } from "../../../../hooks/use-store";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_SERVICE } from "../../../../components/notifications/NotificationService";

interface FormProps {
  code: string;
}

const OtpEnterCodeGenerator = (props: { id: string, onSuccess: () => void, email: string | null }) => {
  const {
    uiStore: { authStore },
  } = useStore();

  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();

  const register = async (data: FormProps) => {
    setLoading(true)
    const res = await authStore.verify({
      id: props.id,
      code: data.code
    });
    console.log(res)
    setLoading(false)
    if (res.ok) {
      return props.onSuccess()
    } else {
      NOTIFICATION_SERVICE.errorNotification('Wrong code');
    }
  };
  return (
    <div>
      <Container size={500} mb={20}>
        <Flex justify={'center'} mt={20} mb={10} >
          <Title
            color="white"
            align="center"
            size={24}
          >
            {t("check.inbox")}
          </Title>
        </Flex>
        <Flex justify={'center'} mb={10}>
          <Title
            color="rgba(190, 190, 190, 1)"
            align="center"
            size={14}
          >
            {t("sent.email.to")}
            &nbsp;
            {props.email}
            &nbsp;
            {t("with.code")}
          </Title>
        </Flex>
      </Container>
      <Flex justify={'center'}>
        <PinInput
          disabled={loading}
          autoFocus
          size="xl"
          // loading={loading}
          onComplete={async (value: string) => {
            await register({ code: value });
          }}
          required
          type={'number'}
        // onChange={field.onChange}
        // value={field.value}
        />
      </Flex>
    </div>
  );
};

export default OtpEnterCodeGenerator;
