import React, { useState } from "react";
import { observer } from "mobx-react";
import { useStore } from "./hooks/use-store";
import LoggedIn from "./pages/routers/logged-in";
import LoggedOut from "./pages/routers/logged-out";
import { AuthState } from "./stores/ui/auth-store/auth-store";
// import Header from "./components/header/header";

import "./App.scss";
import { ToastContainer } from "react-toastify";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import Invite from "./features/invite";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { PostHogProvider } from "posthog-js/react";
import OtpLogin from "./pages/public/register/OtpLogin";
import { WebsiteGenScreen } from "./pages/public/gen/website/WebsiteGenScreen";
import { TermsGenScreen } from "./pages/public/gen/terms/TermsGenScreen";
import { TemplateScreen } from "./pages/public/templates/TemplateScreen";
import { TemplatesScreen } from "./pages/public/templates/TemplatesScreen";
import { NewWebsiteScreen } from "./pages/public/templates/NewWebsiteScreen";
import { ModalsProvider } from "@mantine/modals";
import Survey from "./pages/public/survey/Survey";

function App() {
  const rootStore = useStore();
  const {
    uiStore: { authStore },
  } = rootStore;

  const [colorScheme, setColorScheme] = useState<ColorScheme>("dark");
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{
          other: {
            // background: '#303030'
          },
          colorScheme,
          fontFamily: "Mulish",
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <ModalsProvider>
          <PostHogProvider
            apiKey={'phc_WaKf3NXZeQxEo4RsgTE5t7DwoiZPliPT89VBrsFhP6P'}
            options={{
              session_recording: {
                // WARNING: Only enable this if you understand the security implications
                recordCrossOriginIframes: true,
              },
              api_host: 'https://eu.posthog.com',
            }}
          >
            <Router>
              <Switch>
                <Route path="/login">
                  <OtpLogin />
                </Route>

                <Route path="/new/site">
                  <WebsiteGenScreen />
                </Route>
                <Route path="/new">
                  <NewWebsiteScreen />
                </Route>      
                <Route path="/survey">
                  <Survey />
                </Route>      
              

                <Route path="/templates/:replaceId/:name">
                  <TemplateScreen />
                </Route>
                <Route path="/templates/:replaceId">
                  <TemplatesScreen />
                </Route>

                <Route path="/templates/default/:name">
                  <TemplateScreen />
                </Route>
                <Route path="/templates">
                  <TemplatesScreen />
                </Route>



                <Route path="/new/legal">
                  <TermsGenScreen />
                </Route>
                {authStore.authState === AuthState.LoggedIn ? (
                  <LoggedIn />
                ) : (
                  <LoggedOut />
                )}


              </Switch>
            </Router>
          </PostHogProvider>
        </ModalsProvider>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={"dark"}
        />
      </MantineProvider >
    </ColorSchemeProvider >
  );
}

export default observer(App);
