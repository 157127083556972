import { Button, rem, Tooltip } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { IconCopy, IconCheck } from "@tabler/icons-react";
import { usePostHog } from "posthog-js/react";

interface IProps {
  text: string;
  link: string;
}
const CopyToClipboard = ({ text, link }: IProps) => {
  const posthog = usePostHog()
  
  const clipboard = useClipboard();
  return (
    <Tooltip
      label="Link copied!"
      offset={10}
      position="bottom"
      radius="md"
      zIndex={100000}
      transitionProps={{ duration: 100, transition: "slide-down"}}
      opened={clipboard.copied}
    >
      <Button
        variant="light"
        rightIcon={
          clipboard.copied ? (
            <IconCheck size="1.2rem" stroke={1.5} />
          ) : (
            <IconCopy size="1.2rem" stroke={1.5} />
          )
        }
        radius="sm"
        size="xs"
        styles={{
          root: { paddingRight: rem(14), height: rem(28) },
          rightIcon: { marginLeft: rem(8) },
        }}
        onClick={() => {
          posthog?.capture('Copy website link')
          clipboard.copy(link)
        }}
      >
        {text}
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboard;
