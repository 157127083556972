import { useState } from "react";
import {
  createStyles,
  UnstyledButton,
  Menu,
  Image,
  Group,
  rem,
} from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
// import images from './images';
import english from "../profile/images/english.png";
import german from "../profile/images/german.png";
import french from "../profile/images/french.png";
import { Languages } from "../../../stores/ui/ui-store";
import { useStore } from "../../../hooks/use-store";

const data = [
  { label: "English", image: english, value: "en" },
  { label: "German", image: german, value: "de" },
  { label: "French", image: french, value: "fr" },
];

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    width: '100%',
    minWidth: rem(130),
    display: "flex",
    height: '2.625rem',
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0.4rem ${theme.spacing.xs}`,
    borderRadius: theme.radius.sm,
    border: `none`,
    transition: "background-color 150ms ease",
    color: 'black',
    fontWeight: 'bold',
    backgroundColor:
      opened
        ? theme.colors.gray[0]
        : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },

  icon: {
    transition: "transform 150ms ease",
    transform: opened ? "rotate(180deg)" : "rotate(0deg)",
  },
}));

const LanguageSwitcher2 = () => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles({ opened });
  // const [selected, setSelected] = useState(data[0]);

  const { uiStore } = useStore();

  const changeLanguage = (lang: string) =>
    uiStore.updateLanguage(lang as Languages);

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image src={item.image} width={18} height={18} />}
      onClick={() => changeLanguage(item.value)}
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          <Group spacing="xs">
            {/* <Image
              src={
                data.find((itm) => itm.value === uiStore.currentLanguage)?.image
              }
              width={22}
              height={22}
            /> */}
            <span className={classes.label}>
              {data.find((itm) => itm.value === uiStore.currentLanguage)?.label}
            </span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
};

export default LanguageSwitcher2;
