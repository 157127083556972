import { Flex, Text } from "@mantine/core";

export const SelectItem = (model: { selected: boolean, name: string, setItem: () => void }) => (
    <Flex
        mb={12}
        gap={12}
        p={12}
        onClick={() => model.setItem()}
        align={'center'}
        style={{
            cursor: 'pointer',
            background: model.selected ? 'rgba(46, 58, 80, 1)' : 'rgba(70, 70, 70, 1)',
            border: model.selected ? '1px solid rgba(35, 108, 250, 1)' : '1px solid rgba(115, 115, 115, 1)',
            borderRadius: '4px',
        }}>
        {model.selected ? <Flex ml={12} mr={12}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="2" fill="#236CFA" />
                <path d="M12 5L6.5 10.5L4 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Flex>
            : <Flex ml={12} mr={12}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#737373" />
                </svg>
            </Flex>
        }

        <Text color="white" size={'14px'}>{model.name}</Text>
    </Flex>
)