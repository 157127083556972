import React, { useState } from "react";

import {
    Text,
    Group,
    Button,
    TextInput,
} from "@mantine/core";

// import { useForm } from "react-hook-form";
import { useStore } from "../../../../hooks/use-store";
import { useTranslation } from "react-i18next";
import OtpEnterCodeScreen from "./OtpEnterCodeGen";
import { useForm } from '@mantine/form';

interface FormProps {
    email: string;
}

const OtpLoginGenerator = (porps: { onSuccess: () => void }) => {
    const {
        dataStore: { appStore },
    } = useStore();

    const [enterCodeState, setEnterCodeState] = useState<{ id: null | string, email: null | string }>({ id: null, email: null });
    const { t } = useTranslation();

    const register = async (data: FormProps) => {
        const res = await appStore.sendCode({
            email: data.email,
        });
        if (res.id) {
            setEnterCodeState({ id: res.id, email: data.email });
        } else {

        }
    };



    const form = useForm({
        initialValues: {
            email: '',
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    if (enterCodeState.id) {
        return <OtpEnterCodeScreen id={enterCodeState.id} onSuccess={porps.onSuccess} email={enterCodeState.email} />
    }

    return (
        <div>
            <Text color="white" size={24} weight={'700'} mt={10} mb={10} align="center">
                {t("login.or.create")}
            </Text>
            <form onSubmit={form.onSubmit(async (values) => {
                await register(values)
            })}>

                <TextInput
                    required
                    type="email"
                    placeholder="e@klykn.com"
                    label={t("registerpage.email", { defaultValue: "Email" })}
                    name={"email"}
                    {...form.getInputProps('email')}
                />
                <Button
                    fullWidth
                    mt="xl"
                    loading={false}
                    type="submit"
                // onClick={}
                >
                    {t("continue")}
                </Button>
            </form>
            <Group position="apart" mt="lg">
                <Text size={'sm'}>
                    {t("registerpage.agree")}{" "}
                    <a
                        href={"https://klykn.com/terms"}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        {t("registerpage.terms")}
                    </a>{" "}
                    {t("registerpage.and")}{" "}
                    <a
                        target={"_blank"}
                        rel={"noreferrer"}
                        href={"https://klykn.com/privacy"}
                    >
                        {t("registerpage.conditions")}
                    </a>
                </Text>
            </Group>
        </div>
    );
};

export default OtpLoginGenerator;
