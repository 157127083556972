// generate screnenshot https://www.site-shot.com/ 1440 3000
export const templates =
    [
        {
            name: 'One Agency',
            url: 'one-agency',
            preview: '/static/oneagency.png',
            iframeUrl: 'https://one-agency.klykn.com/',
        }, 
           {
            name: 'Clean Consult',
            url: 'cleanconsult',
            preview: '/static/cleanconsult.png',
            iframeUrl: 'https://cleanconsult.klykn.com/',
        },
        {
            name: 'Rock Stars',
            url: 'rockstars',
            preview: '/static/rockstars.png',
            iframeUrl: 'https://rockstars.klykn.com/',
        },
        {
            name: 'Blue Startup',
            url: 'bluestartup',
            preview: '/static/bluestartup.png',
            iframeUrl: 'https://bluestartup.klykn.com/',
        },
        {
            name: 'Sync Life',
            url: 'synclife',
            preview: '/static/synclife.png',
            iframeUrl: 'https://synclife.klykn.com/',
        },
        {
            name: 'Eco Hub',
            url: 'ecohub',
            preview: '/static/ecohub.png',
            iframeUrl: 'https://ecohub.klykn.com/',
        },
        {
            name: 'Stone Speak',
            url: 'stonespeak',
            preview: '/static/stonespeak.png',
            iframeUrl: 'https://stonespeak.klykn.com/',
        },
        {
            name: 'Mindful Coaching',
            url: 'mindfulcoaching',
            preview: '/static/mindfulcoaching.png',
            iframeUrl: 'https://mindfulcoaching.klykn.com/',
        },
        // {
        //     name: 'Essay',
        //     url: 'essay',
        //     preview: '/static/essay.png',
        //     iframeUrl: 'https://essay.klykn.com/',
        // },
        {
            name: 'Retro Revive',
            url: 'retrorevive',
            preview: '/static/retrorevive.png',
            iframeUrl: 'https://retrorevive.klykn.com/',
        },
        {
            name: 'Enchantment Fest',
            url: 'enchantmentfest',
            preview: '/static/enchantmentfest.png',
            iframeUrl: 'https://enchantmentfest.klykn.com/',
        },
        {
            name: 'James Morgam',
            url: 'jamesmorgam',
            preview: '/static/jamesmorgam.png',
            iframeUrl: 'https://jamesmorgam.klykn.com/',
        },
        // {
        //     name: 'Radiant Beauty',
        //     url: 'radiantbeauty',
        //     preview: '/static/radiantbeauty.png',
        //     iframeUrl: 'https://radiantbeauty.klykn.com/',
        // },
        {
            name: 'Horizon Coaching',
            url: 'horizoncoaching',
            preview: '/static/horizoncoaching.png',
            iframeUrl: 'https://horizoncoaching.klykn.com/',
        }, {
            name: 'Odyssey Expeditions',
            url: 'odysseyexpeditions',
            preview: '/static/odysseyexpeditions.png',
            iframeUrl: 'https://odysseyexpeditions.klykn.com/',
        },
        {
            name: 'Spirit Fusuon',
            url: 'spiritfusion',
            preview: '/static/spiritfusion.png',
            iframeUrl: 'https://spiritfusion.klykn.com/',
        },
        {
            name: 'Playground',
            url: 'playground',
            preview: '/static/3dplayground.png',
            iframeUrl: 'https://3dplayground.klykn.com/',
        },
        {
            name: 'Donut',
            url: 'donut',
            preview: '/static/donut.png',
            iframeUrl: 'https://donut.klykn.com/',
        },
        {
            name: 'Paws',
            url: 'paws',
            preview: '/static/paws.png',
            iframeUrl: 'https://paws.klykn.com/',
        },
        {
            name: 'Echoes Of Eternity',
            url: 'echoesofeternity',
            preview: '/static/echos.png',
            iframeUrl: 'https://echoesofeternity.klykn.com/',
        },
    ]