// const fonts = [
//   {
//     "family": "Roboto",
//     "variants": [
//       "100",
//       "100italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "700",
//       "700italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf",
//       "300": "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
//       "500": "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
//       "700": "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
//       "900": "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf",
//       "300italic": "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf",
//       "regular": "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
//       "italic": "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
//       "500italic": "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf",
//       "700italic": "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf",
//       "900italic": "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5GxP.ttf"
//   },
//   {
//     "family": "Open Sans",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v40",
//     "lastModified": "2023-12-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf",
//       "500": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf",
//       "600": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf",
//       "700": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
//       "800": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf",
//       "regular": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
//       "300italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVcUwaERZjA.ttf",
//       "italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVcUwaERZjA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVcUwaERZjA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVcUwaERZjA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVcUwaERZjA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVcUwaERZjA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4maVc.ttf"
//   },
//   {
//     "family": "Noto Sans JP",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v52",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFBEi75vY0rw-oME.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFJEj75vY0rw-oME.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFE8j75vY0rw-oME.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFCMj75vY0rw-oME.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFM8k75vY0rw-oME.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFPYk75vY0rw-oME.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFJEk75vY0rw-oME.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFLgk75vY0rw-oME.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFBEj75vY0rw-oME.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansjp/v52/-F6jfjtqLzI2JPCgQBnw7HFyzSD-AsregP8VFBEj35rS1g.ttf"
//   },
//   {
//     "family": "Montserrat",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-Y3tcoqK5.ttf",
//       "200": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf",
//       "300": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-Y3tcoqK5.ttf",
//       "500": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf",
//       "600": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
//       "700": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf",
//       "800": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-Y3tcoqK5.ttf",
//       "900": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-Y3tcoqK5.ttf",
//       "regular": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
//       "100italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX9-p7K5ILg.ttf",
//       "200italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX9-p7K5ILg.ttf",
//       "300italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX9-p7K5ILg.ttf",
//       "italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX9-p7K5ILg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX9-p7K5ILg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX9-p7K5ILg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX9-p7K5ILg.ttf",
//       "800italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX9-p7K5ILg.ttf",
//       "900italic": "https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX9-p7K5ILg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Hw_aX8.ttf"
//   },
//   {
//     "family": "Lato",
//     "variants": [
//       "100",
//       "100italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2024-03-12",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf",
//       "300": "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf",
//       "700": "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf",
//       "900": "https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf",
//       "300italic": "https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf",
//       "regular": "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk6XweuBCY.ttf",
//       "italic": "https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf",
//       "700italic": "https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf",
//       "900italic": "https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxgwWw.ttf"
//   },
//   {
//     "family": "Poppins",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2024-03-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf",
//       "200": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf",
//       "300": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
//       "500": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
//       "600": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
//       "700": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
//       "800": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf",
//       "900": "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/poppins/v21/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf",
//       "200italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
//       "regular": "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
//       "italic": "https://fonts.gstatic.com/s/poppins/v21/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf",
//       "900italic": "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrJJHedw.ttf"
//   },
//   {
//     "family": "Inter",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf",
//       "200": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf",
//       "300": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf",
//       "500": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf",
//       "600": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf",
//       "700": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf",
//       "800": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
//       "900": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf",
//       "regular": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZlhjQ.ttf"
//   },
//   {
//     "family": "Roboto Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-10-19",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWyo_BJ731BKMSK.ttf",
//       "200": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyUyovBJ731BKMSK.ttf",
//       "300": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyXsovBJ731BKMSK.ttf",
//       "500": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWAovBJ731BKMSK.ttf",
//       "600": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyVspfBJ731BKMSK.ttf",
//       "700": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyVVpfBJ731BKMSK.ttf",
//       "800": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyUypfBJ731BKMSK.ttf",
//       "900": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyUbpfBJ731BKMSK.ttf",
//       "regular": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWyovBJ731BKMSK.ttf",
//       "100italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64RgL5XljLdSK37o.ttf",
//       "200italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64ZgK5XljLdSK37o.ttf",
//       "300italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64UYK5XljLdSK37o.ttf",
//       "italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64RgK5XljLdSK37o.ttf",
//       "500italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64SoK5XljLdSK37o.ttf",
//       "600italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64cYN5XljLdSK37o.ttf",
//       "700italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64f8N5XljLdSK37o.ttf",
//       "800italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64ZgN5XljLdSK37o.ttf",
//       "900italic": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVW2ZhZI2eCN5jzbjEETS9weq8-19ehAyvMum7nfDB64bEN5XljLdSK37o.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/robotocondensed/v27/ieVo2ZhZI2eCN5jzbjEETS9weq8-_d6T_POl0fRJeyWyosBI5Xk.ttf"
//   },
//   {
//     "family": "Material Icons",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v142",
//     "lastModified": "2024-04-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDsNZIhI8tIHh.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialicons/v142/flUhRq6tzZclQEJ-Vdg-IuiaDvNYKBY.ttf"
//   },
//   {
//     "family": "Roboto Mono",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vuPQ--5Ip2sSQ.ttf",
//       "200": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_XvqPQ--5Ip2sSQ.ttf",
//       "300": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_gPqPQ--5Ip2sSQ.ttf",
//       "500": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_7PqPQ--5Ip2sSQ.ttf",
//       "600": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_AP2PQ--5Ip2sSQ.ttf",
//       "700": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_Of2PQ--5Ip2sSQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/robotomono/v23/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAeW9AJi8SZwt.ttf",
//       "200italic": "https://fonts.gstatic.com/s/robotomono/v23/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrnnAOW9AJi8SZwt.ttf",
//       "300italic": "https://fonts.gstatic.com/s/robotomono/v23/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrk5AOW9AJi8SZwt.ttf",
//       "italic": "https://fonts.gstatic.com/s/robotomono/v23/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlnAOW9AJi8SZwt.ttf",
//       "500italic": "https://fonts.gstatic.com/s/robotomono/v23/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrlVAOW9AJi8SZwt.ttf",
//       "600italic": "https://fonts.gstatic.com/s/robotomono/v23/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrm5B-W9AJi8SZwt.ttf",
//       "700italic": "https://fonts.gstatic.com/s/robotomono/v23/L0xoDF4xlVMF-BfR8bXMIjhOsXG-q2oeuFoqFrmAB-W9AJi8SZwt.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_QuW9.ttf"
//   },
//   {
//     "family": "Oswald",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v53",
//     "lastModified": "2023-08-17",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf",
//       "300": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf",
//       "500": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf",
//       "600": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf",
//       "700": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf",
//       "regular": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oswald/v53/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUBiYA.ttf"
//   },
//   {
//     "family": "Noto Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "devanagari",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v36",
//     "lastModified": "2024-02-16",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9At9d41P6zHtY.ttf",
//       "200": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyB9A99d41P6zHtY.ttf",
//       "300": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyCjA99d41P6zHtY.ttf",
//       "500": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyDPA99d41P6zHtY.ttf",
//       "600": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyAjBN9d41P6zHtY.ttf",
//       "700": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyAaBN9d41P6zHtY.ttf",
//       "800": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyB9BN9d41P6zHtY.ttf",
//       "900": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyBUBN9d41P6zHtY.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A99d41P6zHtY.ttf",
//       "100italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QDcf6VfYyWtY1rI.ttf",
//       "200italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QLce6VfYyWtY1rI.ttf",
//       "300italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QGke6VfYyWtY1rI.ttf",
//       "italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QDce6VfYyWtY1rI.ttf",
//       "500italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QAUe6VfYyWtY1rI.ttf",
//       "600italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QOkZ6VfYyWtY1rI.ttf",
//       "700italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QNAZ6VfYyWtY1rI.ttf",
//       "800italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QLcZ6VfYyWtY1rI.ttf",
//       "900italic": "https://fonts.gstatic.com/s/notosans/v36/o-0kIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevHtVtX57DGjDU1QJ4Z6VfYyWtY1rI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosans/v36/o-0mIpQlx3QUlC5A4PNB6Ryti20_6n1iPHjcz6L1SoM-jCpoiyD9A-9c6Vc.ttf"
//   },
//   {
//     "family": "Raleway",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v29",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CPNLA3JC9c.ttf",
//       "200": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCPNLA3JC9c.ttf",
//       "300": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCPNLA3JC9c.ttf",
//       "500": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCPNLA3JC9c.ttf",
//       "600": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf",
//       "700": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCPNLA3JC9c.ttf",
//       "800": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCPNLA3JC9c.ttf",
//       "900": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCPNLA3JC9c.ttf",
//       "regular": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf",
//       "100italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNPrQVIT9c2c8.ttf",
//       "200italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMPrQVIT9c2c8.ttf",
//       "300italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf",
//       "italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf",
//       "500italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf",
//       "600italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf",
//       "700italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf",
//       "800italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLPrQVIT9c2c8.ttf",
//       "900italic": "https://fonts.gstatic.com/s/raleway/v29/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLPrQVIT9c2c8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/raleway/v29/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCOPrQ.ttf"
//   },
//   {
//     "family": "Nunito Sans",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-04-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4GVilntF8kA_Ykqw.ttf",
//       "300": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4GiClntF8kA_Ykqw.ttf",
//       "500": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4G5ClntF8kA_Ykqw.ttf",
//       "600": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4GCC5ntF8kA_Ykqw.ttf",
//       "700": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4GMS5ntF8kA_Ykqw.ttf",
//       "800": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4GVi5ntF8kA_Ykqw.ttf",
//       "900": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4Gfy5ntF8kA_Ykqw.ttf",
//       "regular": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4G1ilntF8kA_Ykqw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmoP91UgIfM0qxVd.ttf",
//       "300italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmrR91UgIfM0qxVd.ttf",
//       "italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmqP91UgIfM0qxVd.ttf",
//       "500italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmq991UgIfM0qxVd.ttf",
//       "600italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmpR8FUgIfM0qxVd.ttf",
//       "700italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmpo8FUgIfM0qxVd.ttf",
//       "800italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmoP8FUgIfM0qxVd.ttf",
//       "900italic": "https://fonts.gstatic.com/s/nunitosans/v15/pe1kMImSLYBIv1o4X1M8cce4OdVisMz5nZRqy6cmmmU3t2FQWEAEOvV9wNvrwlNstMKW3Y6K5WMwXeVy3GboJ0kTHmom8FUgIfM0qxVd.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nunitosans/v15/pe1mMImSLYBIv1o4X1M8ce2xCx3yop4tQpF_MeTm0lfGWVpNn64CL7U8upHZIbMV51Q42ptCp5F5bxqqtQ1yiU4G1ilXtVUg.ttf"
//   },
//   {
//     "family": "Nunito",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDDshRTM9jo7eTWk.ttf",
//       "300": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDOUhRTM9jo7eTWk.ttf",
//       "500": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDIkhRTM9jo7eTWk.ttf",
//       "600": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDGUmRTM9jo7eTWk.ttf",
//       "700": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDFwmRTM9jo7eTWk.ttf",
//       "800": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDDsmRTM9jo7eTWk.ttf",
//       "900": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDBImRTM9jo7eTWk.ttf",
//       "regular": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM9jo7eTWk.ttf",
//       "200italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXA3iqzbXWnoeg.ttf",
//       "300italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNi83A3iqzbXWnoeg.ttf",
//       "italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNirXA3iqzbXWnoeg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNin3A3iqzbXWnoeg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNic3c3iqzbXWnoeg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiSnc3iqzbXWnoeg.ttf",
//       "800italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiLXc3iqzbXWnoeg.ttf",
//       "900italic": "https://fonts.gstatic.com/s/nunito/v26/XRXK3I6Li01BKofIMPyPbj8d7IEAGXNiBHc3iqzbXWnoeg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nunito/v26/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTI3ig.ttf"
//   },
//   {
//     "family": "Ubuntu",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzTt2aMH4V_gg.ttf",
//       "500": "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3Tt2aMH4V_gg.ttf",
//       "700": "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvTt2aMH4V_gg.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZftWyIPYBvgpUI.ttf",
//       "regular": "https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf",
//       "italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCu6KVjbNBYlgoKeg7znUiAFpxm.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejYHtGyIPYBvgpUI.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ubuntu/v20/4iCp6KVjbNBYlgoKejZPsmyIPYBvgpUI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKeQ7z.ttf"
//   },
//   {
//     "family": "Rubik",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "arabic",
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-06-30",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UE80V4bVkA.ttf",
//       "500": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UE80V4bVkA.ttf",
//       "600": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UE80V4bVkA.ttf",
//       "700": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UE80V4bVkA.ttf",
//       "800": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-h4-1UE80V4bVkA.ttf",
//       "900": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1UE80V4bVkA.ttf",
//       "regular": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0UwdYPFkJ1O.ttf",
//       "italic": "https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0UwdYPFkJ1O.ttf",
//       "500italic": "https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0UwdYPFkJ1O.ttf",
//       "600italic": "https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUwdYPFkJ1O.ttf",
//       "700italic": "https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf",
//       "800italic": "https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8vdFEUwdYPFkJ1O.ttf",
//       "900italic": "https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8v0FEUwdYPFkJ1O.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4iFUUUw.ttf"
//   },
//   {
//     "family": "Roboto Slab",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v34",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaG5iddG-1A.ttf",
//       "200": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaG5iddG-1A.ttf",
//       "300": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaG5iddG-1A.ttf",
//       "500": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaG5iddG-1A.ttf",
//       "600": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaG5iddG-1A.ttf",
//       "700": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaG5iddG-1A.ttf",
//       "800": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaG5iddG-1A.ttf",
//       "900": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaG5iddG-1A.ttf",
//       "regular": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/robotoslab/v34/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISmaWRm.ttf"
//   },
//   {
//     "family": "Playfair Display",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v37",
//     "lastModified": "2024-02-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQZNLo_U2r.ttf",
//       "600": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQZNLo_U2r.ttf",
//       "700": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiukDQZNLo_U2r.ttf",
//       "800": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFukDQZNLo_U2r.ttf",
//       "900": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsukDQZNLo_U2r.ttf",
//       "regular": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQZNLo_U2r.ttf",
//       "italic": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTbtbK-F2rA0s.ttf",
//       "500italic": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTbtbK-F2rA0s.ttf",
//       "600italic": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUbtbK-F2rA0s.ttf",
//       "700italic": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UbtbK-F2rA0s.ttf",
//       "800italic": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUbtbK-F2rA0s.ttf",
//       "900italic": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUbtbK-F2rA0s.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDRbtY.ttf"
//   },
//   {
//     "family": "Merriweather",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf",
//       "700": "https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNpX837pvjxPA.ttf",
//       "900": "https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52_wFpX837pvjxPA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR7lXcf_hP3hPGWH.ttf",
//       "regular": "https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf",
//       "italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4m0qyriQwlOrhSvowK_l5-eSZJdeP3r-Ho.ttf",
//       "700italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR71Wsf_hP3hPGWH.ttf",
//       "900italic": "https://fonts.gstatic.com/s/merriweather/v30/u-4l0qyriQwlOrhSvowK_l5-eR7NWMf_hP3hPGWH.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/merriweather/v30/u-440qyriQwlOrhSvowK_l5-eiZJ.ttf"
//   },
//   {
//     "family": "Noto Sans KR",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "korean",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v36",
//     "lastModified": "2023-08-17",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzuozeLTq8H4hfeE.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzmoyeLTq8H4hfeE.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzrQyeLTq8H4hfeE.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzztgyeLTq8H4hfeE.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzjQ1eLTq8H4hfeE.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzg01eLTq8H4hfeE.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzmo1eLTq8H4hfeE.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzkM1eLTq8H4hfeE.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzuoyeLTq8H4hfeE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskr/v36/PbyxFmXiEBPT4ITbgNA5Cgms3VYcOA-vvnIzzuoySLXg9A.ttf"
//   },
//   {
//     "family": "PT Sans",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/ptsans/v17/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf",
//       "regular": "https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79P0WOxOGMMDQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/ptsans/v17/jizYRExUiTo99u79D0eEwMOJIDQA-g.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ptsans/v17/jizdRExUiTo99u79D0e8fOytKB8c8zMrig.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0SEwA.ttf"
//   },
//   {
//     "family": "Noto Sans TC",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "chinese-traditional",
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v35",
//     "lastModified": "2023-08-17",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz76Cz_CpOtma3uNQ.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz7yCy_CpOtma3uNQ.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz7_6y_CpOtma3uNQ.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz75Ky_CpOtma3uNQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz7361_CpOtma3uNQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz70e1_CpOtma3uNQ.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz7yC1_CpOtma3uNQ.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz7wm1_CpOtma3uNQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz76Cy_CpOtma3uNQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstc/v35/-nFuOG829Oofr2wohFbTp9ifNAn722rq0MXz76CyzCtEsg.ttf"
//   },
//   {
//     "family": "Kanit",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-07-24",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/kanit/v15/nKKX-Go6G5tXcr72GwWKcaxALFs.ttf",
//       "200": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr5aOiWgX6BJNUJy.ttf",
//       "300": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4-OSWgX6BJNUJy.ttf",
//       "500": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr5mOCWgX6BJNUJy.ttf",
//       "600": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr5KPyWgX6BJNUJy.ttf",
//       "700": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4uPiWgX6BJNUJy.ttf",
//       "800": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4yPSWgX6BJNUJy.ttf",
//       "900": "https://fonts.gstatic.com/s/kanit/v15/nKKU-Go6G5tXcr4WPCWgX6BJNUJy.ttf",
//       "100italic": "https://fonts.gstatic.com/s/kanit/v15/nKKV-Go6G5tXcraQI2GAdY5FPFtrGw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI82hVaRrMFJyAu4.ttf",
//       "300italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI6miVaRrMFJyAu4.ttf",
//       "regular": "https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcoaSEQGodLxA.ttf",
//       "italic": "https://fonts.gstatic.com/s/kanit/v15/nKKX-Go6G5tXcraQGwWKcaxALFs.ttf",
//       "500italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI_GjVaRrMFJyAu4.ttf",
//       "600italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI92kVaRrMFJyAu4.ttf",
//       "700italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI7mlVaRrMFJyAu4.ttf",
//       "800italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI6WmVaRrMFJyAu4.ttf",
//       "900italic": "https://fonts.gstatic.com/s/kanit/v15/nKKS-Go6G5tXcraQI4GnVaRrMFJyAu4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcraTGwU.ttf"
//   },
//   {
//     "family": "Work Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNigDp6_cOyA.ttf",
//       "200": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNigDp6_cOyA.ttf",
//       "300": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNigDp6_cOyA.ttf",
//       "500": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf",
//       "600": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf",
//       "700": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNigDp6_cOyA.ttf",
//       "800": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNigDp6_cOyA.ttf",
//       "900": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNigDp6_cOyA.ttf",
//       "regular": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3moJo43ZKyDSQQ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmsJo43ZKyDSQQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUgGsJo43ZKyDSQQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3msJo43ZKyDSQQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJo43ZKyDSQQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUAGwJo43ZKyDSQQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUOWwJo43ZKyDSQQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJo43ZKyDSQQ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/worksans/v19/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUd2wJo43ZKyDSQQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/worksans/v19/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXBikJow.ttf"
//   },
//   {
//     "family": "Lora",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v35",
//     "lastModified": "2024-02-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/lora/v35/0QI6MX1D_JOuGQbT0gvTJPa787wsuyJGmKxemMeZ.ttf",
//       "600": "https://fonts.gstatic.com/s/lora/v35/0QI6MX1D_JOuGQbT0gvTJPa787zAvCJGmKxemMeZ.ttf",
//       "700": "https://fonts.gstatic.com/s/lora/v35/0QI6MX1D_JOuGQbT0gvTJPa787z5vCJGmKxemMeZ.ttf",
//       "regular": "https://fonts.gstatic.com/s/lora/v35/0QI6MX1D_JOuGQbT0gvTJPa787weuyJGmKxemMeZ.ttf",
//       "italic": "https://fonts.gstatic.com/s/lora/v35/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-MoFkqh8ndeZzZ0.ttf",
//       "500italic": "https://fonts.gstatic.com/s/lora/v35/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-PgFkqh8ndeZzZ0.ttf",
//       "600italic": "https://fonts.gstatic.com/s/lora/v35/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-BQCkqh8ndeZzZ0.ttf",
//       "700italic": "https://fonts.gstatic.com/s/lora/v35/0QI8MX1D_JOuMw_hLdO6T2wV9KnW-C0Ckqh8ndeZzZ0.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lora/v35/0QI6MX1D_JOuGQbT0gvTJPa787weuxJHkqg.ttf"
//   },
//   {
//     "family": "Fira Sans",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5Vn9IjOazP3dUTP.ttf",
//       "200": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnWKnuQR37fF3Wlg.ttf",
//       "300": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnPKruQR37fF3Wlg.ttf",
//       "500": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnZKvuQR37fF3Wlg.ttf",
//       "600": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnSKzuQR37fF3Wlg.ttf",
//       "700": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnLK3uQR37fF3Wlg.ttf",
//       "800": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnMK7uQR37fF3Wlg.ttf",
//       "900": "https://fonts.gstatic.com/s/firasans/v17/va9B4kDNxMZdWfMOD5VnFK_uQR37fF3Wlg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/firasans/v17/va9A4kDNxMZdWfMOD5VvkrCqYTfVcFTPj0s.ttf",
//       "200italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAGQBf_XljGllLX.ttf",
//       "300italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBiQxf_XljGllLX.ttf",
//       "regular": "https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VfkILKSTbndQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/firasans/v17/va9C4kDNxMZdWfMOD5VvkojOazP3dUTP.ttf",
//       "500italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrA6Qhf_XljGllLX.ttf",
//       "600italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrAWRRf_XljGllLX.ttf",
//       "700italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrByRBf_XljGllLX.ttf",
//       "800italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBuRxf_XljGllLX.ttf",
//       "900italic": "https://fonts.gstatic.com/s/firasans/v17/va9f4kDNxMZdWfMOD5VvkrBKRhf_XljGllLX.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/firasans/v17/va9E4kDNxMZdWfMOD5VvkYjO.ttf"
//   },
//   {
//     "family": "Mulish",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNRwaClGrw-PTY.ttf",
//       "300": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexc1RwaClGrw-PTY.ttf",
//       "500": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexaFRwaClGrw-PTY.ttf",
//       "600": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexU1WwaClGrw-PTY.ttf",
//       "700": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexXRWwaClGrw-PTY.ttf",
//       "800": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexRNWwaClGrw-PTY.ttf",
//       "900": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexTpWwaClGrw-PTY.ttf",
//       "regular": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNRwaClGrw-PTY.ttf",
//       "200italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeOvHp47LTZFwA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSd-OvHp47LTZFwA.ttf",
//       "italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSKeOvHp47LTZFwA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSG-OvHp47LTZFwA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsS9-SvHp47LTZFwA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSzuSvHp47LTZFwA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSqeSvHp47LTZFwA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/mulish/v13/1Ptwg83HX_SGhgqk2hAjQlW_mEuZ0FsSgOSvHp47LTZFwA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mulish/v13/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNR8aGvHg.ttf"
//   },
//   {
//     "family": "DM Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2024-03-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAop1hTmf3ZGMZpg.ttf",
//       "200": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAIpxhTmf3ZGMZpg.ttf",
//       "300": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwA_JxhTmf3ZGMZpg.ttf",
//       "500": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf",
//       "600": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAfJthTmf3ZGMZpg.ttf",
//       "700": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwARZthTmf3ZGMZpg.ttf",
//       "800": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAIpthTmf3ZGMZpg.ttf",
//       "900": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAC5thTmf3ZGMZpg.ttf",
//       "regular": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhTmf3ZGMZpg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat-JDG3zRmYJpso5.ttf",
//       "200italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat8JDW3zRmYJpso5.ttf",
//       "300italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat_XDW3zRmYJpso5.ttf",
//       "italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat-JDW3zRmYJpso5.ttf",
//       "500italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat-7DW3zRmYJpso5.ttf",
//       "600italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat9XCm3zRmYJpso5.ttf",
//       "700italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat9uCm3zRmYJpso5.ttf",
//       "800italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat8JCm3zRmYJpso5.ttf",
//       "900italic": "https://fonts.gstatic.com/s/dmsans/v15/rP2rp2ywxg089UriCZaSExd86J3t9jz86Mvy4qCRAL19DksVat8gCm3zRmYJpso5.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxRT23z.ttf"
//   },
//   {
//     "family": "Barlow",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E3b8s8yn4hnCci.ttf",
//       "200": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3w-oc4FAtlT47dw.ttf",
//       "300": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3p-kc4FAtlT47dw.ttf",
//       "500": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3_-gc4FAtlT47dw.ttf",
//       "600": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E30-8c4FAtlT47dw.ttf",
//       "700": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3t-4c4FAtlT47dw.ttf",
//       "800": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3q-0c4FAtlT47dw.ttf",
//       "900": "https://fonts.gstatic.com/s/barlow/v12/7cHqv4kjgoGqM7E3j-wc4FAtlT47dw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/barlow/v12/7cHtv4kjgoGqM7E_CfNYwHoDmTcibrA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfP04Voptzsrd6m9.ttf",
//       "300italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOQ4loptzsrd6m9.ttf",
//       "regular": "https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7EPC8E46HsxnA.ttf",
//       "italic": "https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Ccs8yn4hnCci.ttf",
//       "500italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPI41optzsrd6m9.ttf",
//       "600italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfPk5Foptzsrd6m9.ttf",
//       "700italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOA5Voptzsrd6m9.ttf",
//       "800italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOc5loptzsrd6m9.ttf",
//       "900italic": "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfO451optzsrd6m9.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/barlow/v12/7cHpv4kjgoGqM7E_Css8.ttf"
//   },
//   {
//     "family": "Quicksand",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v31",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo18G0wx40QDw.ttf",
//       "500": "https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18G0wx40QDw.ttf",
//       "600": "https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv18G0wx40QDw.ttf",
//       "700": "https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18G0wx40QDw.ttf",
//       "regular": "https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18G0wx40QDw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/quicksand/v31/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o58C-xw.ttf"
//   },
//   {
//     "family": "Inconsolata",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v32",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU8aRr8lleY2co.ttf",
//       "300": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp9s8aRr8lleY2co.ttf",
//       "500": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp7c8aRr8lleY2co.ttf",
//       "600": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp1s7aRr8lleY2co.ttf",
//       "700": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp2I7aRr8lleY2co.ttf",
//       "800": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7LppwU7aRr8lleY2co.ttf",
//       "900": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lppyw7aRr8lleY2co.ttf",
//       "regular": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8aRr8lleY2co.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inconsolata/v32/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8WRv2kg.ttf"
//   },
//   {
//     "family": "Titillium Web",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffAzHKIx5YrSYqWM.ttf",
//       "300": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffGjEKIx5YrSYqWM.ttf",
//       "600": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffBzCKIx5YrSYqWM.ttf",
//       "700": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffHjDKIx5YrSYqWM.ttf",
//       "900": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPDcZTIAOhVxoMyOr9n_E7ffEDBKIx5YrSYqWM.ttf",
//       "200italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbewI1zZpaduWMmxA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbepI5zZpaduWMmxA.ttf",
//       "regular": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf",
//       "italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPAcZTIAOhVxoMyOr9n_E7fdMbmCKZXbr2BsA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbe0IhzZpaduWMmxA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPFcZTIAOhVxoMyOr9n_E7fdMbetIlzZpaduWMmxA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/titilliumweb/v17/NaPecZTIAOhVxoMyOr9n_E7fdMXmCA.ttf"
//   },
//   {
//     "family": "IBM Plex Sans",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX-KVElMYYaJe8bpLHnCwDKjbLeEKxIedbzDw.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjR7_MIZmdd_qFmo.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjXr8MIZmdd_qFmo.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIZmdd_qFmo.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIZmdd_qFmo.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjWr7MIZmdd_qFmo.ttf",
//       "100italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX8KVElMYYaJe8bpLHnCwDKhdTmdKZMW9PjD3N8.ttf",
//       "200italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTm2Idscf3vBmpl8A.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRscf3vBmpl8A.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf",
//       "italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX-KVElMYYaJe8bpLHnCwDKhdTeEKxIedbzDw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTm5IVscf3vBmpl8A.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJscf3vBmpl8A.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINscf3vBmpl8A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKhdfeEA.ttf"
//   },
//   {
//     "family": "PT Serif",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/ptserif/v18/EJRSQgYoZZY2vCFuvAnt65qVXSr3pNNB.ttf",
//       "regular": "https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvDFRxL6ddjb-.ttf",
//       "italic": "https://fonts.gstatic.com/s/ptserif/v18/EJRTQgYoZZY2vCFuvAFTzrq_cyb-vco.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ptserif/v18/EJRQQgYoZZY2vCFuvAFT9gaQVy7VocNB6Iw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ptserif/v18/EJRVQgYoZZY2vCFuvAFQzro.ttf"
//   },
//   {
//     "family": "Heebo",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v26",
//     "lastModified": "2024-02-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiS2cckOnz02SXQ.ttf",
//       "200": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1ECSycckOnz02SXQ.ttf",
//       "300": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1E1yycckOnz02SXQ.ttf",
//       "500": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EuyycckOnz02SXQ.ttf",
//       "600": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EVyucckOnz02SXQ.ttf",
//       "700": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EbiucckOnz02SXQ.ttf",
//       "800": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1ECSucckOnz02SXQ.ttf",
//       "900": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EICucckOnz02SXQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSycckOnz02SXQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/heebo/v26/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysc0mj.ttf"
//   },
//   {
//     "family": "Nanum Gothic",
//     "variants": [
//       "regular",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_oRfi-oW3hYwmKDpxS7F_LQv37zlEn14YEUQ.ttf",
//       "800": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_oRfi-oW3hYwmKDpxS7F_LXv77zlEn14YEUQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nanumgothic/v23/PN_3Rfi-oW3hYwmKDpxS7F_D_9jb.ttf"
//   },
//   {
//     "family": "Manrope",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59FO_F87jxeN7B.ttf",
//       "300": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk6jFO_F87jxeN7B.ttf",
//       "500": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F87jxeN7B.ttf",
//       "600": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F87jxeN7B.ttf",
//       "700": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4aE-_F87jxeN7B.ttf",
//       "800": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf",
//       "regular": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FN_E-bw.ttf"
//   },
//   {
//     "family": "Libre Franklin",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsSUB9rIb-JH1g.ttf",
//       "200": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhrsWUB9rIb-JH1g.ttf",
//       "300": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhcMWUB9rIb-JH1g.ttf",
//       "500": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhHMWUB9rIb-JH1g.ttf",
//       "600": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduh8MKUB9rIb-JH1g.ttf",
//       "700": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhycKUB9rIb-JH1g.ttf",
//       "800": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhrsKUB9rIb-JH1g.ttf",
//       "900": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhh8KUB9rIb-JH1g.ttf",
//       "regular": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWUB9rIb-JH1g.ttf",
//       "100italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZ8RdDMTedX1sGE.ttf",
//       "200italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05ob8RNDMTedX1sGE.ttf",
//       "300italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oYiRNDMTedX1sGE.ttf",
//       "italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZ8RNDMTedX1sGE.ttf",
//       "500italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oZORNDMTedX1sGE.ttf",
//       "600italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oaiQ9DMTedX1sGE.ttf",
//       "700italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05oabQ9DMTedX1sGE.ttf",
//       "800italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05ob8Q9DMTedX1sGE.ttf",
//       "900italic": "https://fonts.gstatic.com/s/librefranklin/v14/jizMREVItHgc8qDIbSTKq4XkRiUawTk7f45UM9y05obVQ9DMTedX1sGE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librefranklin/v14/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWkBtDM.ttf"
//   },
//   {
//     "family": "Noto Serif",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZqFGjwM0Lhq_Szw.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZKFCjwM0Lhq_Szw.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZ9lCjwM0Lhq_Szw.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZmlCjwM0Lhq_Szw.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZdlejwM0Lhq_Szw.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZT1ejwM0Lhq_Szw.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZKFejwM0Lhq_Szw.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZAVejwM0Lhq_Szw.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZqFCjwM0Lhq_Szw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBNLgscPpKrCzyUi.ttf",
//       "200italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBPLg8cPpKrCzyUi.ttf",
//       "300italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBMVg8cPpKrCzyUi.ttf",
//       "italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBNLg8cPpKrCzyUi.ttf",
//       "500italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBN5g8cPpKrCzyUi.ttf",
//       "600italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBOVhMcPpKrCzyUi.ttf",
//       "700italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBOshMcPpKrCzyUi.ttf",
//       "800italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBPLhMcPpKrCzyUi.ttf",
//       "900italic": "https://fonts.gstatic.com/s/notoserif/v23/ga6saw1J5X9T9RW6j9bNfFIMZhhWnFTyNZIQD1-_FXP0RgnaOg9MYBPihMcPpKrCzyUi.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserif/v23/ga6iaw1J5X9T9RW6j9bNVls-hfgvz8JcMofYTa32J4wsL2JAlAhZqFCTwccP.ttf"
//   },
//   {
//     "family": "Karla",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v31",
//     "lastModified": "2023-09-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqqFENLR7fHGw.ttf",
//       "300": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDppqqFENLR7fHGw.ttf",
//       "500": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDypqqFENLR7fHGw.ttf",
//       "600": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDJp2qFENLR7fHGw.ttf",
//       "700": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDH52qFENLR7fHGw.ttf",
//       "800": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJ2qFENLR7fHGw.ttf",
//       "regular": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqqFENLR7fHGw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/karla/v31/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNnCV0lPZbLXGxGR.ttf",
//       "300italic": "https://fonts.gstatic.com/s/karla/v31/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNkcV0lPZbLXGxGR.ttf",
//       "italic": "https://fonts.gstatic.com/s/karla/v31/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNlCV0lPZbLXGxGR.ttf",
//       "500italic": "https://fonts.gstatic.com/s/karla/v31/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNlwV0lPZbLXGxGR.ttf",
//       "600italic": "https://fonts.gstatic.com/s/karla/v31/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNmcUElPZbLXGxGR.ttf",
//       "700italic": "https://fonts.gstatic.com/s/karla/v31/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNmlUElPZbLXGxGR.ttf",
//       "800italic": "https://fonts.gstatic.com/s/karla/v31/qkBKXvYC6trAT7RQNNK2EG7SIwPWMNnCUElPZbLXGxGR.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqaFUlP.ttf"
//   },
//   {
//     "family": "Material Icons Outlined",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v109",
//     "lastModified": "2023-03-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcdl5GuI2Ze.otf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialiconsoutlined/v109/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhXcanZM.otf"
//   },
//   {
//     "family": "Material Symbols Outlined",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v175",
//     "lastModified": "2024-04-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHeembd5zrTgt.ttf",
//       "200": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDAvHOembd5zrTgt.ttf",
//       "300": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDDxHOembd5zrTgt.ttf",
//       "500": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCdHOembd5zrTgt.ttf",
//       "600": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDBxG-embd5zrTgt.ttf",
//       "700": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDBIG-embd5zrTgt.ttf",
//       "regular": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOembd5zrTgt.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialsymbolsoutlined/v175/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHNenZ9o.ttf"
//   },
//   {
//     "family": "Noto Sans SC",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v36",
//     "lastModified": "2023-08-17",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG9_EnYxNbPzS5HE.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG1_FnYxNbPzS5HE.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG4HFnYxNbPzS5HE.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG-3FnYxNbPzS5HE.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaGwHCnYxNbPzS5HE.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaGzjCnYxNbPzS5HE.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG1_CnYxNbPzS5HE.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG3bCnYxNbPzS5HE.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG9_FnYxNbPzS5HE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssc/v36/k3kCo84MPvpLmixcA63oeAL7Iqp5IZJF9bmaG9_FrY1HaA.ttf"
//   },
//   {
//     "family": "Josefin Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v32",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjRXMFrLgTsQV0.ttf",
//       "200": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_LjQXMFrLgTsQV0.ttf",
//       "300": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_GbQXMFrLgTsQV0.ttf",
//       "500": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ArQXMFrLgTsQV0.ttf",
//       "600": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_ObXXMFrLgTsQV0.ttf",
//       "700": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_N_XXMFrLgTsQV0.ttf",
//       "regular": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf",
//       "100italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtINhKibpUV3MEQ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTNIJhKibpUV3MEQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCT6oJhKibpUV3MEQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTtIJhKibpUV3MEQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCThoJhKibpUV3MEQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTaoVhKibpUV3MEQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3JZQNVED7rKGKxtqIqX5EUCGZ2dIn0FyA96fCTU4VhKibpUV3MEQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/josefinsans/v32/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQbMBhKg.ttf"
//   },
//   {
//     "family": "Noto Color Emoji",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "emoji"
//     ],
//     "version": "v30",
//     "lastModified": "2023-12-07",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notocoloremoji/v30/Yq6P-KqIXTD0t4D9z1ESnKM3-HpFab5s79iz64w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notocoloremoji/v30/Yq6P-KqIXTD0t4D9z1ESnKM3-HpFWb9m6w.ttf"
//   },
//   {
//     "family": "Mukta",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-05-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbEOjFma-2HW7ZB_.ttf",
//       "300": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbFqj1ma-2HW7ZB_.ttf",
//       "500": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbEyjlma-2HW7ZB_.ttf",
//       "600": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbEeiVma-2HW7ZB_.ttf",
//       "700": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbF6iFma-2HW7ZB_.ttf",
//       "800": "https://fonts.gstatic.com/s/mukta/v14/iJWHBXyXfDDVXbFmi1ma-2HW7ZB_.ttf",
//       "regular": "https://fonts.gstatic.com/s/mukta/v14/iJWKBXyXfDDVXYnGp32S0H3f.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mukta/v14/iJWKBXyXfDDVXbnHrXk.ttf"
//   },
//   {
//     "family": "Arimo",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v29",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk338xsBxDAVQI4aA.ttf",
//       "600": "https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk3M8tsBxDAVQI4aA.ttf",
//       "700": "https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk3CstsBxDAVQI4aA.ttf",
//       "regular": "https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxsBxDAVQI4aA.ttf",
//       "italic": "https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-ERBrEdwcoaKww.ttf",
//       "500italic": "https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY-2RBrEdwcoaKww.ttf",
//       "600italic": "https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9aQxrEdwcoaKww.ttf",
//       "700italic": "https://fonts.gstatic.com/s/arimo/v29/P5sdzZCDf9_T_10c3i9MeUcyat4iJY9jQxrEdwcoaKww.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arimo/v29/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxcBhrE.ttf"
//   },
//   {
//     "family": "Libre Baskerville",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKiZrc3Hgbbcjq75U4uslyuy4kn0qviTjYwI8Gcw6Oi.ttf",
//       "regular": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNeYRI4CN2V.ttf",
//       "italic": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKhZrc3Hgbbcjq75U4uslyuy4kn0qNcaxYaDc2V2ro.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNfaxY.ttf"
//   },
//   {
//     "family": "Bebas Neue",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXoo9W9hzg.ttf"
//   },
//   {
//     "family": "Dosis",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v32",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7MV3BkFTq4EPw.ttf",
//       "300": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJabMV3BkFTq4EPw.ttf",
//       "500": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJBbMV3BkFTq4EPw.ttf",
//       "600": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ6bQV3BkFTq4EPw.ttf",
//       "700": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJ0LQV3BkFTq4EPw.ttf",
//       "800": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJt7QV3BkFTq4EPw.ttf",
//       "regular": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7MV3BkFTq4EPw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dosis/v32/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7Ml3RMB.ttf"
//   },
//   {
//     "family": "Hind Siliguri",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRDf44uEfKiGvxts.ttf",
//       "500": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRG_54uEfKiGvxts.ttf",
//       "600": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoREP-4uEfKiGvxts.ttf",
//       "700": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwOs5juQtsyLLR5jN4cxBEoRCf_4uEfKiGvxts.ttf",
//       "regular": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwTs5juQtsyLLR5jN4cxBEofJvQxuk0Nig.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hindsiliguri/v12/ijwTs5juQtsyLLR5jN4cxBEoTJrawg.ttf"
//   },
//   {
//     "family": "Abel",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE6VhLPJp6qGI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/abel/v18/MwQ5bhbm2POE2VlBOA.ttf"
//   },
//   {
//     "family": "Barlow Condensed",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B43LT31vytKgbaw.ttf",
//       "200": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B497y_3HcuKECcrs.ttf",
//       "300": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B47rx_3HcuKECcrs.ttf",
//       "500": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B4-Lw_3HcuKECcrs.ttf",
//       "600": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B4873_3HcuKECcrs.ttf",
//       "700": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B46r2_3HcuKECcrs.ttf",
//       "800": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B47b1_3HcuKECcrs.ttf",
//       "900": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxwL3I-JCGChYJ8VI-L6OO_au7B45L0_3HcuKECcrs.ttf",
//       "100italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxzL3I-JCGChYJ8VI-L6OO_au7B6xTru1H2lq0La6JN.ttf",
//       "200italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrF3DWvIMHYrtUxg.ttf",
//       "300italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrc3PWvIMHYrtUxg.ttf",
//       "regular": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B2xbZ23n3pKg.ttf",
//       "italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxxL3I-JCGChYJ8VI-L6OO_au7B6xTT31vytKgbaw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrK3LWvIMHYrtUxg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrB3XWvIMHYrtUxg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrY3TWvIMHYrtUxg.ttf",
//       "800italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrf3fWvIMHYrtUxg.ttf",
//       "900italic": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTxyL3I-JCGChYJ8VI-L6OO_au7B6xTrW3bWvIMHYrtUxg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/barlowcondensed/v12/HTx3L3I-JCGChYJ8VI-L6OO_au7B6xfT3w.ttf"
//   },
//   {
//     "family": "Archivo",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDJp8B1oJ0vyVQ.ttf",
//       "200": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTtDNp8B1oJ0vyVQ.ttf",
//       "300": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTajNp8B1oJ0vyVQ.ttf",
//       "500": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTBjNp8B1oJ0vyVQ.ttf",
//       "600": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT6jRp8B1oJ0vyVQ.ttf",
//       "700": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTT0zRp8B1oJ0vyVQ.ttf",
//       "800": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTtDRp8B1oJ0vyVQ.ttf",
//       "900": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTnTRp8B1oJ0vyVQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNp8B1oJ0vyVQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCBshdsBU7iVdxQ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HABsxdsBU7iVdxQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HDfsxdsBU7iVdxQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCBsxdsBU7iVdxQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HCzsxdsBU7iVdxQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HBftBdsBU7iVdxQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HBmtBdsBU7iVdxQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HABtBdsBU7iVdxQ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/archivo/v19/k3k8o8UDI-1M0wlSfdzyIEkpwTM29hr-8mTYIRyOSVz60_PG_HAotBdsBU7iVdxQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTNDNZ8Rds.ttf"
//   },
//   {
//     "family": "Cabin",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkW-EL7Gvxm7rE_s.ttf",
//       "600": "https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkYODL7Gvxm7rE_s.ttf",
//       "700": "https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkbqDL7Gvxm7rE_s.ttf",
//       "regular": "https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7Gvxm7rE_s.ttf",
//       "italic": "https://fonts.gstatic.com/s/cabin/v27/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHx_KlwkzuA_u1Bg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/cabin/v27/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXH9fKlwkzuA_u1Bg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/cabin/v27/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHGfWlwkzuA_u1Bg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cabin/v27/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHIPWlwkzuA_u1Bg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cabin/v27/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EH7Clwg.ttf"
//   },
//   {
//     "family": "Material Symbols Rounded",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v173",
//     "lastModified": "2024-04-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rIekXxKJKJBjAa8.ttf",
//       "200": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rAelXxKJKJBjAa8.ttf",
//       "300": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rNmlXxKJKJBjAa8.ttf",
//       "500": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rLWlXxKJKJBjAa8.ttf",
//       "600": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rFmiXxKJKJBjAa8.ttf",
//       "700": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rGCiXxKJKJBjAa8.ttf",
//       "regular": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rIelXxKJKJBjAa8.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialsymbolsrounded/v173/syl0-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjpZIvDmUSVOK7BDB_Qb9vUSzq3wzLK-P0J-V_Zs-QtQth3-jOcbTCVpeRL2w5rwZu2rIelbxODLA.ttf"
//   },
//   {
//     "family": "Jost",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7myjJAVGPokMmuHL.ttf",
//       "200": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7mwjJQVGPokMmuHL.ttf",
//       "300": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7mz9JQVGPokMmuHL.ttf",
//       "500": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7myRJQVGPokMmuHL.ttf",
//       "600": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7mx9IgVGPokMmuHL.ttf",
//       "700": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7mxEIgVGPokMmuHL.ttf",
//       "800": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7mwjIgVGPokMmuHL.ttf",
//       "900": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7mwKIgVGPokMmuHL.ttf",
//       "regular": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7myjJQVGPokMmuHL.ttf",
//       "100italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0ENI0un_HLMEo.ttf",
//       "200italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0FNI0un_HLMEo.ttf",
//       "300italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZrMFNI0un_HLMEo.ttf",
//       "italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZu0FNI0un_HLMEo.ttf",
//       "500italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZt8FNI0un_HLMEo.ttf",
//       "600italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZjMCNI0un_HLMEo.ttf",
//       "700italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZgoCNI0un_HLMEo.ttf",
//       "800italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZm0CNI0un_HLMEo.ttf",
//       "900italic": "https://fonts.gstatic.com/s/jost/v15/92zJtBhPNqw73oHH7BbQp4-B6XlrZkQCNI0un_HLMEo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jost/v15/92zPtBhPNqw79Ij1E865zBUv7myjJTVHNI0.ttf"
//   },
//   {
//     "family": "Bitter",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v33",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbeCL_EXFh2reU.ttf",
//       "200": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8XbfCL_EXFh2reU.ttf",
//       "300": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8ajfCL_EXFh2reU.ttf",
//       "500": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8cTfCL_EXFh2reU.ttf",
//       "600": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8SjYCL_EXFh2reU.ttf",
//       "700": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8RHYCL_EXFh2reU.ttf",
//       "800": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8XbYCL_EXFh2reU.ttf",
//       "900": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8V_YCL_EXFh2reU.ttf",
//       "regular": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfCL_EXFh2reU.ttf",
//       "100italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c4P3OWHpzveWxBw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cYPzOWHpzveWxBw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cvvzOWHpzveWxBw.ttf",
//       "italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c4PzOWHpzveWxBw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6c0vzOWHpzveWxBw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cPvvOWHpzveWxBw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cB_vOWHpzveWxBw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cYPvOWHpzveWxBw.ttf",
//       "900italic": "https://fonts.gstatic.com/s/bitter/v33/raxjHiqOu8IVPmn7epZnDMyKBvHf5D6cSfvOWHpzveWxBw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfOL7OWA.ttf"
//   },
//   {
//     "family": "PT Sans Narrow",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/ptsansnarrow/v18/BngSUXNadjH0qYEzV7ab-oWlsbg95DiCUfzgRd-3.ttf",
//       "regular": "https://fonts.gstatic.com/s/ptsansnarrow/v18/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ptsansnarrow/v18/BngRUXNadjH0qYEzV7ab-oWlsbCAwRg.ttf"
//   },
//   {
//     "family": "Source Code Pro",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DEyQhM5hTXUcdJg.ttf",
//       "300": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DJKQhM5hTXUcdJg.ttf",
//       "500": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DP6QhM5hTXUcdJg.ttf",
//       "600": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DBKXhM5hTXUcdJg.ttf",
//       "700": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DCuXhM5hTXUcdJg.ttf",
//       "800": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DEyXhM5hTXUcdJg.ttf",
//       "900": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DGWXhM5hTXUcdJg.ttf",
//       "regular": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQhM5hTXUcdJg.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTT7I1rSVcZZJiGpw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTMo1rSVcZZJiGpw.ttf",
//       "italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTbI1rSVcZZJiGpw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTXo1rSVcZZJiGpw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTsoprSVcZZJiGpw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTi4prSVcZZJiGpw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTT7IprSVcZZJiGpw.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_jiYsKILxRpg3hIP6sJ7fM7PqlOPHYvDP_W9O7GQTTxYprSVcZZJiGpw.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sourcecodepro/v23/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtM9rSQ.ttf"
//   },
//   {
//     "family": "Dancing Script",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BAyoHTeB9ptDqpw.ttf",
//       "600": "https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B7y0HTeB9ptDqpw.ttf",
//       "700": "https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7B1i0HTeB9ptDqpw.ttf",
//       "regular": "https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTeB9ptDqpw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3TOp5.ttf"
//   },
//   {
//     "family": "EB Garamond",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-2fRUA4V-e6yHgQ.ttf",
//       "600": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-NfNUA4V-e6yHgQ.ttf",
//       "700": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-DPNUA4V-e6yHgQ.ttf",
//       "800": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-a_NUA4V-e6yHgQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RUA4V-e6yHgQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7e8QI96WamXgXFI.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7eOQI96WamXgXFI.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7diR496WamXgXFI.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7dbR496WamXgXFI.ttf",
//       "800italic": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGFmQSNjdsmc35JDF1K5GRwUjcdlttVFm-rI7c8R496WamXgXFI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ebgaramond/v27/SlGDmQSNjdsmc35JDF1K5E55YMjF_7DPuGi-6_RkAo96.ttf"
//   },
//   {
//     "family": "Oxygen",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCJW8Db2-4C7wFZQ.ttf",
//       "700": "https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCNWgDb2-4C7wFZQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4Lcnbu6iUcnZ0SkAg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4LcnbuKiE0j.ttf"
//   },
//   {
//     "family": "Anton",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm0K08i4gS7lu.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anton/v25/1Ptgg87LROyAm3K1-Co.ttf"
//   },
//   {
//     "family": "Outfit",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC0C4G-EiAou6Y.ttf",
//       "200": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4bC1C4G-EiAou6Y.ttf",
//       "300": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4W61C4G-EiAou6Y.ttf",
//       "500": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4QK1C4G-EiAou6Y.ttf",
//       "600": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4e6yC4G-EiAou6Y.ttf",
//       "700": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4deyC4G-EiAou6Y.ttf",
//       "800": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4bCyC4G-EiAou6Y.ttf",
//       "900": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4ZmyC4G-EiAou6Y.ttf",
//       "regular": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4G-EiAou6Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/outfit/v11/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1O4C0Fg.ttf"
//   },
//   {
//     "family": "Noto Serif JP",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "japanese",
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZBaPRkgfU8fEwb0.otf",
//       "300": "https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZHKMRkgfU8fEwb0.otf",
//       "500": "https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZCqNRkgfU8fEwb0.otf",
//       "600": "https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZAaKRkgfU8fEwb0.otf",
//       "700": "https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZGKLRkgfU8fEwb0.otf",
//       "900": "https://fonts.gstatic.com/s/notoserifjp/v21/xn77YHs72GKoTvER4Gn3b5eMZFqJRkgfU8fEwb0.otf",
//       "regular": "https://fonts.gstatic.com/s/notoserifjp/v21/xn7mYHs72GKoTvER4Gn3b5eMXNikYkY0T84.otf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifjp/v21/xn7mYHs72GKoTvER4Gn3b5eMbN-uYA.otf"
//   },
//   {
//     "family": "Noto Sans HK",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "chinese-hongkong",
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-17",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qHB_-oWTiYjNvVA.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qPB--oWTiYjNvVA.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qC5--oWTiYjNvVA.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qEJ--oWTiYjNvVA.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qK55-oWTiYjNvVA.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qJd5-oWTiYjNvVA.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qPB5-oWTiYjNvVA.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qNl5-oWTiYjNvVA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qHB--oWTiYjNvVA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanshk/v31/nKKF-GM_FYFRJvXzVXaAPe97P1KHynJFP716qHB-yoSZjQ.ttf"
//   },
//   {
//     "family": "Hind",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfMJaIRuYjDpf5Vw.ttf",
//       "500": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfJpbIRuYjDpf5Vw.ttf",
//       "600": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfLZcIRuYjDpf5Vw.ttf",
//       "700": "https://fonts.gstatic.com/s/hind/v16/5aU19_a8oxmIfNJdIRuYjDpf5Vw.ttf",
//       "regular": "https://fonts.gstatic.com/s/hind/v16/5aU69_a8oxmIRG5yBROzkDM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hind/v16/5aU69_a8oxmIdG94AQ.ttf"
//   },
//   {
//     "family": "Exo 2",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvOcPtq-rpvLpQ.ttf",
//       "200": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvKcPtq-rpvLpQ.ttf",
//       "300": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKcPtq-rpvLpQ.ttf",
//       "500": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jjPKcPtq-rpvLpQ.ttf",
//       "600": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jYPWcPtq-rpvLpQ.ttf",
//       "700": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcPtq-rpvLpQ.ttf",
//       "800": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jPvWcPtq-rpvLpQ.ttf",
//       "900": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jF_WcPtq-rpvLpQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPtq-rpvLpQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fNC6jJ7bpQBL.ttf",
//       "200italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0fdC6jJ7bpQBL.ttf",
//       "300italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drEqfdC6jJ7bpQBL.ttf",
//       "italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drF0fdC6jJ7bpQBL.ttf",
//       "500italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drFGfdC6jJ7bpQBL.ttf",
//       "600italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGqetC6jJ7bpQBL.ttf",
//       "700italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drGTetC6jJ7bpQBL.ttf",
//       "800italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drH0etC6jJ7bpQBL.ttf",
//       "900italic": "https://fonts.gstatic.com/s/exo2/v21/7cH3v4okm5zmbtYtMeA0FKq0Jjg2drHdetC6jJ7bpQBL.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKsP9C6.ttf"
//   },
//   {
//     "family": "Material Icons Round",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v108",
//     "lastModified": "2023-03-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/materialiconsround/v108/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmMq_fTTvg-.otf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialiconsround/v108/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flzmLofU.otf"
//   },
//   {
//     "family": "Cairo",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-05-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hGA-W1ToLQ-HmkA.ttf",
//       "300": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hL4-W1ToLQ-HmkA.ttf",
//       "500": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hNI-W1ToLQ-HmkA.ttf",
//       "600": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hD45W1ToLQ-HmkA.ttf",
//       "700": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hAc5W1ToLQ-HmkA.ttf",
//       "800": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hGA5W1ToLQ-HmkA.ttf",
//       "900": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hEk5W1ToLQ-HmkA.ttf",
//       "regular": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hOA-W1ToLQ-HmkA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cairo/v28/SLXgc1nY6HkvangtZmpQdkhzfH5lkSs2SgRjCAGMQ1z0hOA-a1XiKQ.ttf"
//   },
//   {
//     "family": "Source Sans 3",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-10-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kw461EN_io6npfB.ttf",
//       "300": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kzm61EN_io6npfB.ttf",
//       "500": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8KyK61EN_io6npfB.ttf",
//       "600": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxm7FEN_io6npfB.ttf",
//       "700": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kxf7FEN_io6npfB.ttf",
//       "800": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Kw47FEN_io6npfB.ttf",
//       "900": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8KwR7FEN_io6npfB.ttf",
//       "regular": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky461EN_io6npfB.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqDlO9C4Ym4fB3Ts.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqOdO9C4Ym4fB3Ts.ttf",
//       "italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqLlO9C4Ym4fB3Ts.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqItO9C4Ym4fB3Ts.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqGdJ9C4Ym4fB3Ts.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqF5J9C4Ym4fB3Ts.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqDlJ9C4Ym4fB3Ts.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpDtKy2OAdR1K-IwhWudF-R3woAa8opPOrG97lwqBBJ9C4Ym4fB3Ts.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sourcesans3/v15/nwpBtKy2OAdR1K-IwhWudF-R9QMylBJAV3Bo8Ky462EM9C4.ttf"
//   },
//   {
//     "family": "Teko",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN9JG7Sy3TKEvkCF.ttf",
//       "500": "https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN8lG7Sy3TKEvkCF.ttf",
//       "600": "https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN_JHLSy3TKEvkCF.ttf",
//       "700": "https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN_wHLSy3TKEvkCF.ttf",
//       "regular": "https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN8XG7Sy3TKEvkCF.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/teko/v20/LYjYdG7kmE0gV69VVPPdFl06VN8XG4Sz1zY.ttf"
//   },
//   {
//     "family": "Pacifico",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ-6B6Mw.ttf"
//   },
//   {
//     "family": "Comfortaa",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v45",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/comfortaa/v45/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf",
//       "500": "https://fonts.gstatic.com/s/comfortaa/v45/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf",
//       "600": "https://fonts.gstatic.com/s/comfortaa/v45/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf",
//       "700": "https://fonts.gstatic.com/s/comfortaa/v45/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf",
//       "regular": "https://fonts.gstatic.com/s/comfortaa/v45/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/comfortaa/v45/1Pt_g8LJRfWJmhDAuUsSQamb1W0lwk4S4WjMDrUfJQ.ttf"
//   },
//   {
//     "family": "Crimson Text",
//     "variants": [
//       "regular",
//       "italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "600": "https://fonts.gstatic.com/s/crimsontext/v19/wlppgwHKFkZgtmSR3NB0oRJXsCx2C9lR1LFffg.ttf",
//       "700": "https://fonts.gstatic.com/s/crimsontext/v19/wlppgwHKFkZgtmSR3NB0oRJX1C12C9lR1LFffg.ttf",
//       "regular": "https://fonts.gstatic.com/s/crimsontext/v19/wlp2gwHKFkZgtmSR3NB0oRJvaAJSA_JN3Q.ttf",
//       "italic": "https://fonts.gstatic.com/s/crimsontext/v19/wlpogwHKFkZgtmSR3NB0oRJfaghWIfdd3ahG.ttf",
//       "600italic": "https://fonts.gstatic.com/s/crimsontext/v19/wlprgwHKFkZgtmSR3NB0oRJfajCOD9NV9rRPfrKu.ttf",
//       "700italic": "https://fonts.gstatic.com/s/crimsontext/v19/wlprgwHKFkZgtmSR3NB0oRJfajDqDtNV9rRPfrKu.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/crimsontext/v19/wlp2gwHKFkZgtmSR3NB0oRJfaQhW.ttf"
//   },
//   {
//     "family": "Public Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpi5ww0pX189fg.ttf",
//       "200": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulpm5ww0pX189fg.ttf",
//       "300": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuSJm5ww0pX189fg.ttf",
//       "500": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuJJm5ww0pX189fg.ttf",
//       "600": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuyJ65ww0pX189fg.ttf",
//       "700": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymu8Z65ww0pX189fg.ttf",
//       "800": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymulp65ww0pX189fg.ttf",
//       "900": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuv565ww0pX189fg.ttf",
//       "regular": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpm5ww0pX189fg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgQctfVotfj7j.ttf",
//       "200italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRgActfVotfj7j.ttf",
//       "300italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673toPgActfVotfj7j.ttf",
//       "italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpRgActfVotfj7j.ttf",
//       "500italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tpjgActfVotfj7j.ttf",
//       "600italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tqPhwctfVotfj7j.ttf",
//       "700italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tq2hwctfVotfj7j.ttf",
//       "800italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673trRhwctfVotfj7j.ttf",
//       "900italic": "https://fonts.gstatic.com/s/publicsans/v15/ijwAs572Xtc6ZYQws9YVwnNDZpDyNjGolS673tr4hwctfVotfj7j.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/publicsans/v15/ijwGs572Xtc6ZYQws9YVwllKVG8qX1oyOymuFpmJwgct.ttf"
//   },
//   {
//     "family": "Nanum Gothic Coding",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/nanumgothiccoding/v21/8QIYdjzHisX_8vv59_xMxtPFW4IXROws8xgecsV88t5V9r4.ttf",
//       "regular": "https://fonts.gstatic.com/s/nanumgothiccoding/v21/8QIVdjzHisX_8vv59_xMxtPFW4IXROwsy6QxVs1X7tc.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nanumgothiccoding/v21/8QIVdjzHisX_8vv59_xMxtPFW4IXROws-6U7Ug.ttf"
//   },
//   {
//     "family": "Assistant",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZnEGGf3qGuvM4.ttf",
//       "300": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtrhnEGGf3qGuvM4.ttf",
//       "500": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQttRnEGGf3qGuvM4.ttf",
//       "600": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtjhgEGGf3qGuvM4.ttf",
//       "700": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtgFgEGGf3qGuvM4.ttf",
//       "800": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtmZgEGGf3qGuvM4.ttf",
//       "regular": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnEGGf3qGuvM4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/assistant/v19/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGCV2g.ttf"
//   },
//   {
//     "family": "Lobster",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lobster/v30/neILzCirqoswsqX9_oWsMqEzSJQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lobster/v30/neILzCirqoswsqX9zoSmNg.ttf"
//   },
//   {
//     "family": "Figtree",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-07-24",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_chQF5ewkEU4HTy.ttf",
//       "500": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_dNQF5ewkEU4HTy.ttf",
//       "600": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_ehR15ewkEU4HTy.ttf",
//       "700": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_eYR15ewkEU4HTy.ttf",
//       "800": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_f_R15ewkEU4HTy.ttf",
//       "900": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_fWR15ewkEU4HTy.ttf",
//       "regular": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_d_QF5ewkEU4HTy.ttf",
//       "300italic": "https://fonts.gstatic.com/s/figtree/v5/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A-gdyEU25WTybO8.ttf",
//       "italic": "https://fonts.gstatic.com/s/figtree/v5/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A7YdyEU25WTybO8.ttf",
//       "500italic": "https://fonts.gstatic.com/s/figtree/v5/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A4QdyEU25WTybO8.ttf",
//       "600italic": "https://fonts.gstatic.com/s/figtree/v5/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A2gayEU25WTybO8.ttf",
//       "700italic": "https://fonts.gstatic.com/s/figtree/v5/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3A1EayEU25WTybO8.ttf",
//       "800italic": "https://fonts.gstatic.com/s/figtree/v5/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3AzYayEU25WTybO8.ttf",
//       "900italic": "https://fonts.gstatic.com/s/figtree/v5/_Xm9-HUzqDCFdgfMm4GnA4aZFrUvtOK3Ax8ayEU25WTybO8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/figtree/v5/_Xmz-HUzqDCFdgfMsYiV_F7wfS-Bs_d_QG5fyEU.ttf"
//   },
//   {
//     "family": "Prompt",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2CA9oYREcjeo0k.ttf",
//       "200": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cr_s4bmkvc5Q9dw.ttf",
//       "300": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cy_g4bmkvc5Q9dw.ttf",
//       "500": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Ck_k4bmkvc5Q9dw.ttf",
//       "600": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cv_44bmkvc5Q9dw.ttf",
//       "700": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C2_84bmkvc5Q9dw.ttf",
//       "800": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2Cx_w4bmkvc5Q9dw.ttf",
//       "900": "https://fonts.gstatic.com/s/prompt/v10/-W_8XJnvUD7dzB2C4_04bmkvc5Q9dw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/prompt/v10/-W_7XJnvUD7dzB2KZeJ8TkMBf50kbiM.ttf",
//       "200italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLQb2MrUZEtdzow.ttf",
//       "300italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeK0bGMrUZEtdzow.ttf",
//       "regular": "https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB26Z9AcZkIzeg.ttf",
//       "italic": "https://fonts.gstatic.com/s/prompt/v10/-W_9XJnvUD7dzB2KZdoYREcjeo0k.ttf",
//       "500italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLsbWMrUZEtdzow.ttf",
//       "600italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeLAamMrUZEtdzow.ttf",
//       "700italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKka2MrUZEtdzow.ttf",
//       "800italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeK4aGMrUZEtdzow.ttf",
//       "900italic": "https://fonts.gstatic.com/s/prompt/v10/-W_6XJnvUD7dzB2KZeKcaWMrUZEtdzow.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/prompt/v10/-W__XJnvUD7dzB2KZtoY.ttf"
//   },
//   {
//     "family": "Fjalla One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-04-04",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fjallaone/v15/Yq6R-LCAWCX3-6Ky7FAFnOZwkxgtUb8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fjallaone/v15/Yq6R-LCAWCX3-6Ky7FAFrOd6lw.ttf"
//   },
//   {
//     "family": "Signika Negative",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/signikanegative/v21/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAr5S73st9hiuEq8.ttf",
//       "500": "https://fonts.gstatic.com/s/signikanegative/v21/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqVS73st9hiuEq8.ttf",
//       "600": "https://fonts.gstatic.com/s/signikanegative/v21/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAp5TL3st9hiuEq8.ttf",
//       "700": "https://fonts.gstatic.com/s/signikanegative/v21/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RApATL3st9hiuEq8.ttf",
//       "regular": "https://fonts.gstatic.com/s/signikanegative/v21/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqnS73st9hiuEq8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/signikanegative/v21/E21x_cfngu7HiRpPX3ZpNE4kY5zKSPmJXkF0VDD2RAqnS43tvdw.ttf"
//   },
//   {
//     "family": "M PLUS Rounded 1c",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "500",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "hebrew",
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGCAYIAV6gnpUpoWwNkYvrugw9RuM3ixLsg6-av1x0.ttf",
//       "300": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0q5psKxeqmzgRK.ttf",
//       "500": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM1y55sKxeqmzgRK.ttf",
//       "700": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM064ZsKxeqmzgRK.ttf",
//       "800": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0m4psKxeqmzgRK.ttf",
//       "900": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGBAYIAV6gnpUpoWwNkYvrugw9RuM0C45sKxeqmzgRK.ttf",
//       "regular": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGEAYIAV6gnpUpoWwNkYvrugw9RuPWGzr8C7vav.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mplusrounded1c/v15/VdGEAYIAV6gnpUpoWwNkYvrugw9RuMWHxLs.ttf"
//   },
//   {
//     "family": "Zilla Slab",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYpEY2HSjWlhzbaw.ttf",
//       "500": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYskZ2HSjWlhzbaw.ttf",
//       "600": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYuUe2HSjWlhzbaw.ttf",
//       "700": "https://fonts.gstatic.com/s/zillaslab/v11/dFa5ZfeM_74wlPZtksIFYoEf2HSjWlhzbaw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CVHapXnp2fazkfg.ttf",
//       "regular": "https://fonts.gstatic.com/s/zillaslab/v11/dFa6ZfeM_74wlPZtksIFWj0w_HyIRlE.ttf",
//       "italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFa4ZfeM_74wlPZtksIFaj86-F6NVlFqdA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CDHepXnp2fazkfg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CIHCpXnp2fazkfg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/zillaslab/v11/dFanZfeM_74wlPZtksIFaj8CRHGpXnp2fazkfg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zillaslab/v11/dFa6ZfeM_74wlPZtksIFajw6-A.ttf"
//   },
//   {
//     "family": "Rajdhani",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pasEcOsc-bGkqIw.ttf",
//       "500": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pb0EMOsc-bGkqIw.ttf",
//       "600": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pbYF8Osc-bGkqIw.ttf",
//       "700": "https://fonts.gstatic.com/s/rajdhani/v15/LDI2apCSOBg7S-QT7pa8FsOsc-bGkqIw.ttf",
//       "regular": "https://fonts.gstatic.com/s/rajdhani/v15/LDIxapCSOBg7S-QT7q4AOeekWPrP.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rajdhani/v15/LDIxapCSOBg7S-QT7p4BM-M.ttf"
//   },
//   {
//     "family": "Maven Pro",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v33",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/mavenpro/v33/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Rf25nCpozp5GvU.ttf",
//       "600": "https://fonts.gstatic.com/s/mavenpro/v33/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8fvx5nCpozp5GvU.ttf",
//       "700": "https://fonts.gstatic.com/s/mavenpro/v33/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8cLx5nCpozp5GvU.ttf",
//       "800": "https://fonts.gstatic.com/s/mavenpro/v33/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8aXx5nCpozp5GvU.ttf",
//       "900": "https://fonts.gstatic.com/s/mavenpro/v33/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8Yzx5nCpozp5GvU.ttf",
//       "regular": "https://fonts.gstatic.com/s/mavenpro/v33/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX25nCpozp5GvU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mavenpro/v33/7Auup_AqnyWWAxW2Wk3swUz56MS91Eww8SX21nGjpw.ttf"
//   },
//   {
//     "family": "Overpass",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6_PLrOZCLtce-og.ttf",
//       "200": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6fPPrOZCLtce-og.ttf",
//       "300": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6ovPrOZCLtce-og.ttf",
//       "500": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6zvPrOZCLtce-og.ttf",
//       "600": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6IvTrOZCLtce-og.ttf",
//       "700": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6G_TrOZCLtce-og.ttf",
//       "800": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6fPTrOZCLtce-og.ttf",
//       "900": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6VfTrOZCLtce-og.ttf",
//       "regular": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6_PPrOZCLtce-og.ttf",
//       "100italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLADe5qPl8Kuosgz.ttf",
//       "200italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLCDepqPl8Kuosgz.ttf",
//       "300italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLBdepqPl8Kuosgz.ttf",
//       "italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLADepqPl8Kuosgz.ttf",
//       "500italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLAxepqPl8Kuosgz.ttf",
//       "600italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLDdfZqPl8Kuosgz.ttf",
//       "700italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLDkfZqPl8Kuosgz.ttf",
//       "800italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLCDfZqPl8Kuosgz.ttf",
//       "900italic": "https://fonts.gstatic.com/s/overpass/v13/qFdU35WCmI96Ajtm81GgSdXCNs-VMF0vNLCqfZqPl8Kuosgz.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/overpass/v13/qFda35WCmI96Ajtm83upeyoaX6QPnlo6_PPbOJqP.ttf"
//   },
//   {
//     "family": "Play",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/play/v19/6ae84K2oVqwItm4TOpc423nTJTM.ttf",
//       "regular": "https://fonts.gstatic.com/s/play/v19/6aez4K2oVqwIjtI8Hp8Tx3A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/play/v19/6aez4K2oVqwIvtM2Gg.ttf"
//   },
//   {
//     "family": "Arvo",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/arvo/v22/tDbM2oWUg0MKoZw1yLTA8vL7lAE.ttf",
//       "regular": "https://fonts.gstatic.com/s/arvo/v22/tDbD2oWUg0MKmSAa7Lzr7vs.ttf",
//       "italic": "https://fonts.gstatic.com/s/arvo/v22/tDbN2oWUg0MKqSIQ6J7u_vvijQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/arvo/v22/tDbO2oWUg0MKqSIoVLHK9tD-hAHkGg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arvo/v22/tDbD2oWUg0MKqSEQ6A.ttf"
//   },
//   {
//     "family": "Red Hat Display",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbjKWckg5-Xecg3w.ttf",
//       "500": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbl6Wckg5-Xecg3w.ttf",
//       "600": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbrKRckg5-Xecg3w.ttf",
//       "700": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbouRckg5-Xecg3w.ttf",
//       "800": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbuyRckg5-Xecg3w.ttf",
//       "900": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbsWRckg5-Xecg3w.ttf",
//       "regular": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbmyWckg5-Xecg3w.ttf",
//       "300italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVxAsz_VWZk3zJGg.ttf",
//       "italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVmgsz_VWZk3zJGg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVqAsz_VWZk3zJGg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVRAwz_VWZk3zJGg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVfQwz_VWZk3zJGg.ttf",
//       "800italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVGgwz_VWZk3zJGg.ttf",
//       "900italic": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIh7wUr0m80wwYf0QCXZzYzUoTg-CSvZX4Vlf1fe6TVMwwz_VWZk3zJGg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redhatdisplay/v19/8vIf7wUr0m80wwYf0QCXZzYzUoTK8RZQvRd-D1NYbmyWQkkz_Q.ttf"
//   },
//   {
//     "family": "Material Icons Sharp",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v109",
//     "lastModified": "2023-03-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/materialiconssharp/v109/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvSImEyc0vd.otf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialiconssharp/v109/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvlvVKGM.otf"
//   },
//   {
//     "family": "Lexend",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsX_LBte6KuGEo.ttf",
//       "200": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC7sW_LBte6KuGEo.ttf",
//       "300": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC2UW_LBte6KuGEo.ttf",
//       "500": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WCwkW_LBte6KuGEo.ttf",
//       "600": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC-UR_LBte6KuGEo.ttf",
//       "700": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC9wR_LBte6KuGEo.ttf",
//       "800": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC7sR_LBte6KuGEo.ttf",
//       "900": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WC5IR_LBte6KuGEo.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsW_LBte6KuGEo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexend/v19/wlptgwvFAVdoq2_F94zlCfv0bz1WCzsWzLFnfw.ttf"
//   },
//   {
//     "family": "Saira Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRMQgErUN8XuHNEtX81i9TmEkrnwetA2omSrzS8.ttf",
//       "200": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnbcpg8Keepi2lHw.ttf",
//       "300": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnCclg8Keepi2lHw.ttf",
//       "500": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnUchg8Keepi2lHw.ttf",
//       "600": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnfc9g8Keepi2lHw.ttf",
//       "700": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnGc5g8Keepi2lHw.ttf",
//       "800": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnBc1g8Keepi2lHw.ttf",
//       "900": "https://fonts.gstatic.com/s/sairacondensed/v11/EJRLQgErUN8XuHNEtX81i9TmEkrnIcxg8Keepi2lHw.ttf",
//       "regular": "https://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrfpeFE-IyCrw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sairacondensed/v11/EJROQgErUN8XuHNEtX81i9TmEkrvpOtA.ttf"
//   },
//   {
//     "family": "Varela Round",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-02-16",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIvoMGOD9gxZw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/varelaround/v20/w8gdH283Tvk__Lua32TysjIfocuK.ttf"
//   },
//   {
//     "family": "Caveat",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/caveat/v18/WnznHAc5bAfYB2QRah7pcpNvOx-pjcB9SIKjYBxPigs.ttf",
//       "600": "https://fonts.gstatic.com/s/caveat/v18/WnznHAc5bAfYB2QRah7pcpNvOx-pjSx6SIKjYBxPigs.ttf",
//       "700": "https://fonts.gstatic.com/s/caveat/v18/WnznHAc5bAfYB2QRah7pcpNvOx-pjRV6SIKjYBxPigs.ttf",
//       "regular": "https://fonts.gstatic.com/s/caveat/v18/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9SIKjYBxPigs.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/caveat/v18/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIOpZA.ttf"
//   },
//   {
//     "family": "IBM Plex Mono",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6pfjptAgt5VM-kVkqdyU8n3kwq0n1hj-sNFQ.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3uAL8ldPg-IUDNg.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3oQI8ldPg-IUDNg.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3twJ8ldPg-IUDNg.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3vAO8ldPg-IUDNg.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3pQP8ldPg-IUDNg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6rfjptAgt5VM-kVkqdyU8n1ioStndlre4dFcFh.ttf",
//       "200italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSGlZFh8ARHNh4zg.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSflVFh8ARHNh4zg.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F63fjptAgt5VM-kVkqdyU8n5igg1l9kn-s.ttf",
//       "italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6pfjptAgt5VM-kVkqdyU8n1ioq0n1hj-sNFQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSJlRFh8ARHNh4zg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSClNFh8ARHNh4zg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSblJFh8ARHNh4zg.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexmono/v19/-F63fjptAgt5VM-kVkqdyU8n1ikq0g.ttf"
//   },
//   {
//     "family": "Chakra Petch",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkeNIhFQJXE3AY00g.ttf",
//       "500": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkebIlFQJXE3AY00g.ttf",
//       "600": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkeQI5FQJXE3AY00g.ttf",
//       "700": "https://fonts.gstatic.com/s/chakrapetch/v11/cIflMapbsEk7TDLdtEz1BwkeJI9FQJXE3AY00g.ttf",
//       "300italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpLJQp_A_gMk0izH.ttf",
//       "regular": "https://fonts.gstatic.com/s/chakrapetch/v11/cIf6MapbsEk7TDLdtEz1BwkmmKBhSL7Y1Q.ttf",
//       "italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfkMapbsEk7TDLdtEz1BwkWmqplarvI1R8t.ttf",
//       "500italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpKRQ5_A_gMk0izH.ttf",
//       "600italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpK9RJ_A_gMk0izH.ttf",
//       "700italic": "https://fonts.gstatic.com/s/chakrapetch/v11/cIfnMapbsEk7TDLdtEz1BwkWmpLZRZ_A_gMk0izH.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chakrapetch/v11/cIf6MapbsEk7TDLdtEz1BwkWmapl.ttf"
//   },
//   {
//     "family": "Cormorant Garamond",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQAllvuQWJ5heb_w.ttf",
//       "500": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQWlhvuQWJ5heb_w.ttf",
//       "600": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQdl9vuQWJ5heb_w.ttf",
//       "700": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3YmX5slCNuHLi8bLeY9MK7whWMhyjQEl5vuQWJ5heb_w.ttf",
//       "300italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPjuw-NxBKL_y94.ttf",
//       "regular": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf",
//       "italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3ZmX5slCNuHLi8bLeY9MK7whWMhyjYrHtPkyuF7w6C.ttf",
//       "500italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEO7ug-NxBKL_y94.ttf",
//       "600italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEOXvQ-NxBKL_y94.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3WmX5slCNuHLi8bLeY9MK7whWMhyjYrEPzvA-NxBKL_y94.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjYr3tP.ttf"
//   },
//   {
//     "family": "Archivo Black",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/archivoblack/v21/HTxqL289NzCGg4MzN6KJ7eW6OYuP_x7yx3A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/archivoblack/v21/HTxqL289NzCGg4MzN6KJ7eW6CYqF-w.ttf"
//   },
//   {
//     "family": "Fira Sans Condensed",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarWZXtqOlQfx9CjA.ttf",
//       "200": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWTnMiMN-cxZblY4.ttf",
//       "300": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWV3PiMN-cxZblY4.ttf",
//       "500": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWQXOiMN-cxZblY4.ttf",
//       "600": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWSnJiMN-cxZblY4.ttf",
//       "700": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWU3IiMN-cxZblY4.ttf",
//       "800": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWVHLiMN-cxZblY4.ttf",
//       "900": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOsEADFm8hSaQTFG18FErVhsC9x-tarWXXKiMN-cxZblY4.ttf",
//       "100italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOtEADFm8hSaQTFG18FErVhsC9x-tarUfPVzONUXRpSjJcu.ttf",
//       "200italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVYMJ0dzRehY43EA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVBMF0dzRehY43EA.ttf",
//       "regular": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarYfHnrMtVbx8.ttf",
//       "italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOjEADFm8hSaQTFG18FErVhsC9x-tarUfPtqOlQfx9CjA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVXMB0dzRehY43EA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVcMd0dzRehY43EA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVFMZ0dzRehY43EA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVCMV0dzRehY43EA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOuEADFm8hSaQTFG18FErVhsC9x-tarUfPVLMR0dzRehY43EA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/firasanscondensed/v10/wEOhEADFm8hSaQTFG18FErVhsC9x-tarUfDtqA.ttf"
//   },
//   {
//     "family": "Asap",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2023-06-07",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqQsLmOXoA7Glw.ttf",
//       "200": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYEqUsLmOXoA7Glw.ttf",
//       "300": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYzKUsLmOXoA7Glw.ttf",
//       "500": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYoKUsLmOXoA7Glw.ttf",
//       "600": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYTKIsLmOXoA7Glw.ttf",
//       "700": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYdaIsLmOXoA7Glw.ttf",
//       "800": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYEqIsLmOXoA7Glw.ttf",
//       "900": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYO6IsLmOXoA7Glw.ttf",
//       "regular": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqUsLmOXoA7Glw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWubEbGmTggvWl0Qn.ttf",
//       "200italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuZEbWmTggvWl0Qn.ttf",
//       "300italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuaabWmTggvWl0Qn.ttf",
//       "italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWubEbWmTggvWl0Qn.ttf",
//       "500italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWub2bWmTggvWl0Qn.ttf",
//       "600italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuYaammTggvWl0Qn.ttf",
//       "700italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuYjammTggvWl0Qn.ttf",
//       "800italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuZEammTggvWl0Qn.ttf",
//       "900italic": "https://fonts.gstatic.com/s/asap/v30/KFOMCniXp96ayz4E7kSn66aGLdTylUAMQXC89YmC2DPNWuZtammTggvWl0Qn.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqUcL2mT.ttf"
//   },
//   {
//     "family": "Space Grotesk",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj62UUsjNsFjTDJK.ttf",
//       "500": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7aUUsjNsFjTDJK.ttf",
//       "600": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj42VksjNsFjTDJK.ttf",
//       "700": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj4PVksjNsFjTDJK.ttf",
//       "regular": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUUsjNsFjTDJK.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/spacegrotesk/v16/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj7oUXsiPMU.ttf"
//   },
//   {
//     "family": "Slabo 27px",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/slabo27px/v14/mFT0WbgBwKPR_Z4hGN2qsxgJ1EJ7i90.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/slabo27px/v14/mFT0WbgBwKPR_Z4hGN2qgxkD0A.ttf"
//   },
//   {
//     "family": "Shadows Into Light",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shadowsintolight/v19/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQDcsr4xzSMYA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shadowsintolight/v19/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQzc8D8.ttf"
//   },
//   {
//     "family": "Barlow Semi Condensed",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfG4qvKk8ogoSP.ttf",
//       "200": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRft6uPAGEki52WfA.ttf",
//       "300": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf06iPAGEki52WfA.ttf",
//       "500": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfi6mPAGEki52WfA.ttf",
//       "600": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfp66PAGEki52WfA.ttf",
//       "700": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRfw6-PAGEki52WfA.ttf",
//       "800": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf36yPAGEki52WfA.ttf",
//       "900": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpigxjLBV1hqnzfr-F8sEYMB0Yybp0mudRf-62PAGEki52WfA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpjgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbLLIEsKh5SPZWs.ttf",
//       "200italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJnAWsgqZiGfHK5.ttf",
//       "300italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIDAmsgqZiGfHK5.ttf",
//       "regular": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRnf4CrCEo4gg.ttf",
//       "italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlphgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfYqvKk8ogoSP.ttf",
//       "500italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJbA2sgqZiGfHK5.ttf",
//       "600italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbJ3BGsgqZiGfHK5.ttf",
//       "700italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbITBWsgqZiGfHK5.ttf",
//       "800italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIPBmsgqZiGfHK5.ttf",
//       "900italic": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpkgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfbIrB2sgqZiGfHK5.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/barlowsemicondensed/v15/wlpvgxjLBV1hqnzfr-F8sEYMB0Yybp0mudRXfoqv.ttf"
//   },
//   {
//     "family": "Permanent Marker",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004HaqIfrT5nlk.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/permanentmarker/v16/Fh4uPib9Iyv2ucM6pGQMWimMp004LauCeg.ttf"
//   },
//   {
//     "family": "Plus Jakarta Sans",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-06-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KU7NShXUEKi4Rw.ttf",
//       "300": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_907NShXUEKi4Rw.ttf",
//       "500": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_m07NShXUEKi4Rw.ttf",
//       "600": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_d0nNShXUEKi4Rw.ttf",
//       "700": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_TknNShXUEKi4Rw.ttf",
//       "800": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_KUnNShXUEKi4Rw.ttf",
//       "regular": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU7NShXUEKi4Rw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ2lCR_QMq2oR82k.ttf",
//       "300italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ17CR_QMq2oR82k.ttf",
//       "italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ0lCR_QMq2oR82k.ttf",
//       "500italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ0XCR_QMq2oR82k.ttf",
//       "600italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ37Dh_QMq2oR82k.ttf",
//       "700italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ3CDh_QMq2oR82k.ttf",
//       "800italic": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIZaomQNQcsA88c7O9yZ4KMCoOg4KozySKCdSNG9OcqYQ2lDh_QMq2oR82k.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/plusjakartasans/v8/LDIbaomQNQcsA88c7O9yZ4KMCoOg4IA6-91aHEjcWuA_qU79Sx_Q.ttf"
//   },
//   {
//     "family": "Abril Fatface",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/abrilfatface/v23/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/abrilfatface/v23/zOL64pLDlL1D99S8g8PtiKchq-FmiA.ttf"
//   },
//   {
//     "family": "Satisfy",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/satisfy/v21/rP2Hp2yn6lkG50LoOZSCHBeHFl0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/satisfy/v21/rP2Hp2yn6lkG50LoCZWIGA.ttf"
//   },
//   {
//     "family": "Lilita One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lilitaone/v15/i7dPIFZ9Zz-WBtRtedDbUEZ2RFq7AwU.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lilitaone/v15/i7dPIFZ9Zz-WBtRtedDbYEd8QA.ttf"
//   },
//   {
//     "family": "Material Icons Two Tone",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v112",
//     "lastModified": "2023-03-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/materialiconstwotone/v112/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu3NjDrH_77.otf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialiconstwotone/v112/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNBuwPDI.otf"
//   },
//   {
//     "family": "DM Serif Display",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dmserifdisplay/v15/-nFnOHM81r4j6k0gjAW3mujVU2B2K_d709jy92k.ttf",
//       "italic": "https://fonts.gstatic.com/s/dmserifdisplay/v15/-nFhOHM81r4j6k0gjAW3mujVU2B2G_Vx1_r352np3Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dmserifdisplay/v15/-nFnOHM81r4j6k0gjAW3mujVU2B2G_Zx1w.ttf"
//   },
//   {
//     "family": "Merriweather Sans",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-04-27",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZ_O4ljuEG7xFHnQ.ttf",
//       "500": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZkO4ljuEG7xFHnQ.ttf",
//       "600": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZfOkljuEG7xFHnQ.ttf",
//       "700": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZRekljuEG7xFHnQ.ttf",
//       "800": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZIukljuEG7xFHnQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4ljuEG7xFHnQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq2TzesCzRRXnaur.ttf",
//       "italic": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq3NzesCzRRXnaur.ttf",
//       "500italic": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq3_zesCzRRXnaur.ttf",
//       "600italic": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq0TyusCzRRXnaur.ttf",
//       "700italic": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq0qyusCzRRXnaur.ttf",
//       "800italic": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cM9IRs1JiJN1FRAMjTN5zd9vgsFHXwWDvLBsPDdpWMaq1NyusCzRRXnaur.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/merriweathersans/v26/2-cO9IRs1JiJN1FRAMjTN5zd9vgsFF_5asQTb6hZ2JKZou4Vj-sC.ttf"
//   },
//   {
//     "family": "IBM Plex Serif",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizBREVNn1dOx-zrZ2X3pZvkTi182zIZj1bIkNo.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi3Q-hIzoVrBicOg.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi20-RIzoVrBicOg.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi3s-BIzoVrBicOg.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi3A_xIzoVrBicOg.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizAREVNn1dOx-zrZ2X3pZvkTi2k_hIzoVrBicOg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizHREVNn1dOx-zrZ2X3pZvkTiUa41YTi3TNgNq55w.ttf",
//       "200italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa4_oyq17jjNOg_oc.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa454xq17jjNOg_oc.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizDREVNn1dOx-zrZ2X3pZvkThUY0TY7ikbI.ttf",
//       "italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizBREVNn1dOx-zrZ2X3pZvkTiUa2zIZj1bIkNo.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa48Ywq17jjNOg_oc.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa4-o3q17jjNOg_oc.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizGREVNn1dOx-zrZ2X3pZvkTiUa4442q17jjNOg_oc.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexserif/v19/jizDREVNn1dOx-zrZ2X3pZvkTiUZ2zI.ttf"
//   },
//   {
//     "family": "Domine",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/domine/v20/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X0DAI10VErGuW8Q.ttf",
//       "600": "https://fonts.gstatic.com/s/domine/v20/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X6zHI10VErGuW8Q.ttf",
//       "700": "https://fonts.gstatic.com/s/domine/v20/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X5XHI10VErGuW8Q.ttf",
//       "regular": "https://fonts.gstatic.com/s/domine/v20/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X3LAI10VErGuW8Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/domine/v20/L0xhDFMnlVwD4h3Lt9JWnbX3jG-2X3LAE1wfFg.ttf"
//   },
//   {
//     "family": "Questrial",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/questrial/v18/QdVUSTchPBm7nuUeVf70uCFg.ttf"
//   },
//   {
//     "family": "Hind Madurai",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfXaUnecsoMJ0b_g.ttf",
//       "500": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfBaQnecsoMJ0b_g.ttf",
//       "600": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfKaMnecsoMJ0b_g.ttf",
//       "700": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xu0e2p98ZvDXdZQIOcpqjfTaInecsoMJ0b_g.ttf",
//       "regular": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xx0e2p98ZvDXdZQIOcpqjn8Y0DceA0OQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hindmadurai/v11/f0Xx0e2p98ZvDXdZQIOcpqjX8IcH.ttf"
//   },
//   {
//     "family": "Indie Flower",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/indieflower/v21/m8JVjfNVeKWVnh3QMuKkFcZlbkGG1dKEDw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/indieflower/v21/m8JVjfNVeKWVnh3QMuKkFcZVb0uC.ttf"
//   },
//   {
//     "family": "Rowdies",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/rowdies/v17/ptRMTieMYPNBAK219hth5O7yKQNute8.ttf",
//       "700": "https://fonts.gstatic.com/s/rowdies/v17/ptRMTieMYPNBAK219gtm5O7yKQNute8.ttf",
//       "regular": "https://fonts.gstatic.com/s/rowdies/v17/ptRJTieMYPNBAK21zrdJwObZNQo.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rowdies/v17/ptRJTieMYPNBAK21_rZDxA.ttf"
//   },
//   {
//     "family": "M PLUS 1p",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "500",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "hebrew",
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/mplus1p/v28/e3tleuShHdiFyPFzBRrQnDQAUW3aq-5N.ttf",
//       "300": "https://fonts.gstatic.com/s/mplus1p/v28/e3tmeuShHdiFyPFzBRrQVBYge0PWovdU4w.ttf",
//       "500": "https://fonts.gstatic.com/s/mplus1p/v28/e3tmeuShHdiFyPFzBRrQDBcge0PWovdU4w.ttf",
//       "700": "https://fonts.gstatic.com/s/mplus1p/v28/e3tmeuShHdiFyPFzBRrQRBEge0PWovdU4w.ttf",
//       "800": "https://fonts.gstatic.com/s/mplus1p/v28/e3tmeuShHdiFyPFzBRrQWBIge0PWovdU4w.ttf",
//       "900": "https://fonts.gstatic.com/s/mplus1p/v28/e3tmeuShHdiFyPFzBRrQfBMge0PWovdU4w.ttf",
//       "regular": "https://fonts.gstatic.com/s/mplus1p/v28/e3tjeuShHdiFyPFzBRro-D4Ec2jKqw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mplus1p/v28/e3tjeuShHdiFyPFzBRrY-TQA.ttf"
//   },
//   {
//     "family": "Nanum Myeongjo",
//     "variants": [
//       "regular",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Bty3DZF0dXLMZlywRbVRNhxy2pXV1A0pfCs5Kos.ttf",
//       "800": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Bty3DZF0dXLMZlywRbVRNhxy2pLVFA0pfCs5Kos.ttf",
//       "regular": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Btx3DZF0dXLMZlywRbVRNhxy1LreHQ8juyl.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nanummyeongjo/v22/9Btx3DZF0dXLMZlywRbVRNhxy2LqcnA.ttf"
//   },
//   {
//     "family": "Catamaran",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjc1anXuluiLyw.ttf",
//       "200": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPPjd1anXuluiLyw.ttf",
//       "300": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPCbd1anXuluiLyw.ttf",
//       "500": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPErd1anXuluiLyw.ttf",
//       "600": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPKba1anXuluiLyw.ttf",
//       "700": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPJ_a1anXuluiLyw.ttf",
//       "800": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPPja1anXuluiLyw.ttf",
//       "900": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPNHa1anXuluiLyw.ttf",
//       "regular": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd1anXuluiLyw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/catamaran/v19/o-0bIpQoyXQa2RxT7-5B6Ryxs2E_6n1iPHjd5ajdvg.ttf"
//   },
//   {
//     "family": "Sora",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSn3-KIwNhBti0.ttf",
//       "200": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSfSnn-KIwNhBti0.ttf",
//       "300": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmScMnn-KIwNhBti0.ttf",
//       "500": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdgnn-KIwNhBti0.ttf",
//       "600": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSeMmX-KIwNhBti0.ttf",
//       "700": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSe1mX-KIwNhBti0.ttf",
//       "800": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSfSmX-KIwNhBti0.ttf",
//       "regular": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnn-KIwNhBti0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnk-LKQc.ttf"
//   },
//   {
//     "family": "Tajawal",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "latin"
//     ],
//     "version": "v9",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l_6gLrZjiLlJ-G0.ttf",
//       "300": "https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l5qjLrZjiLlJ-G0.ttf",
//       "500": "https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l8KiLrZjiLlJ-G0.ttf",
//       "700": "https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l4qkLrZjiLlJ-G0.ttf",
//       "800": "https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l5anLrZjiLlJ-G0.ttf",
//       "900": "https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l7KmLrZjiLlJ-G0.ttf",
//       "regular": "https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1rzaLCr5IlLA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1nzeBDg.ttf"
//   },
//   {
//     "family": "Source Serif 4",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjipdqrhxXD-wGvjU.ttf",
//       "300": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjiklqrhxXD-wGvjU.ttf",
//       "500": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjiiVqrhxXD-wGvjU.ttf",
//       "600": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjisltrhxXD-wGvjU.ttf",
//       "700": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjivBtrhxXD-wGvjU.ttf",
//       "800": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjipdtrhxXD-wGvjU.ttf",
//       "900": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjir5trhxXD-wGvjU.ttf",
//       "regular": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjihdqrhxXD-wGvjU.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pxl9dC84DrjXEXw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pGF9dC84DrjXEXw.ttf",
//       "italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pRl9dC84DrjXEXw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pdF9dC84DrjXEXw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pmFhdC84DrjXEXw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98poVhdC84DrjXEXw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98pxlhdC84DrjXEXw.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sourceserif4/v8/vEF02_tTDB4M7-auWDN0ahZJW1ge6NmXpVAHV83Bfb_US2D2QYxoUKIkn98p71hdC84DrjXEXw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sourceserif4/v8/vEFy2_tTDB4M7-auWDN0ahZJW3IX2ih5nk3AucvUHf6OAVIJmeUDygwjihdqnh1dCw.ttf"
//   },
//   {
//     "family": "Yanone Kaffeesatz",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2024-02-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftodtWpcGuLCnXkVA.ttf",
//       "300": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoqNWpcGuLCnXkVA.ttf",
//       "500": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoxNWpcGuLCnXkVA.ttf",
//       "600": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoKNKpcGuLCnXkVA.ttf",
//       "700": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIftoEdKpcGuLCnXkVA.ttf",
//       "regular": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWpcGuLCnXkVA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yanonekaffeesatz/v30/3y9I6aknfjLm_3lMKjiMgmUUYBs04aUXNxt9gW2LIfto9tWZcWGP.ttf"
//   },
//   {
//     "family": "Signika",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2023-07-24",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/signika/v25/vEF72_JTCgwQ5ejvMV0Ox_Kg1UwJ0tKfX4zNpD8E4ASzH1r93zuYzTMngt4xjw.ttf",
//       "500": "https://fonts.gstatic.com/s/signika/v25/vEF72_JTCgwQ5ejvMV0Ox_Kg1UwJ0tKfX4zNpD8E4ASzH1r9szuYzTMngt4xjw.ttf",
//       "600": "https://fonts.gstatic.com/s/signika/v25/vEF72_JTCgwQ5ejvMV0Ox_Kg1UwJ0tKfX4zNpD8E4ASzH1r9XzyYzTMngt4xjw.ttf",
//       "700": "https://fonts.gstatic.com/s/signika/v25/vEF72_JTCgwQ5ejvMV0Ox_Kg1UwJ0tKfX4zNpD8E4ASzH1r9ZjyYzTMngt4xjw.ttf",
//       "regular": "https://fonts.gstatic.com/s/signika/v25/vEF72_JTCgwQ5ejvMV0Ox_Kg1UwJ0tKfX4zNpD8E4ASzH1r9gTuYzTMngt4xjw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/signika/v25/vEF72_JTCgwQ5ejvMV0Ox_Kg1UwJ0tKfX4zNpD8E4ASzH1r9gTuozDkj.ttf"
//   },
//   {
//     "family": "Didact Gothic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/didactgothic/v20/ahcfv8qz1zt6hCC5G4F_P4ASpUySp0LlcyQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/didactgothic/v20/ahcfv8qz1zt6hCC5G4F_P4ASlU2Yow.ttf"
//   },
//   {
//     "family": "Roboto Flex",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2024-02-29",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/robotoflex/v26/NaNnepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGbt8jZktqc2V3Zs0KvDLdBP8SBZtOs2IifRuUZQMsPJtUsR4DEK6cULNeUx9XgTnH37Ha_FIAp4Fm0PP1hw45DntW2x0wZGzhPmr1YNMYKYn9_1IQXGwJAiUJVUMdN5YUW4O8HtSoXjC1z3QSabshNFVe3e0O5j3ZjrZCu23Qd4G0EBysQNK-QKavMl1cKq3tHXtXi8mzLjaAQbGunCNCKMY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/robotoflex/v26/NaNnepOXO_NexZs0b5QrzlOHb8wCikXpYqmZsWI-__OGbt8jZktqc2V3Zs0KvDLdBP8SBZtOs2IifRuUZQMsPJtUsR4DEK6cULNeUx9XgTnH37Ha_FIAp4Fm0PP1hw45DntW2x0wZGzhPmr1YNMYKYn9_1IQXGwJAiUJVUMdN5YUW4O8HtSoXjC1z3QSabshNFVe3e0O5j3ZjrZCu23Qd4G0EBysQNK-QKavMl1cKq3tHXtXi8mzLjaAcbCkmA.ttf"
//   },
//   {
//     "family": "Sarabun",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sarabun/v15/DtVhJx26TKEr37c9YHZJmnYI5gnOpg.ttf",
//       "200": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YNpoulwm6gDXvwE.ttf",
//       "300": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YL5rulwm6gDXvwE.ttf",
//       "500": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YOZqulwm6gDXvwE.ttf",
//       "600": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YMptulwm6gDXvwE.ttf",
//       "700": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YK5sulwm6gDXvwE.ttf",
//       "800": "https://fonts.gstatic.com/s/sarabun/v15/DtVmJx26TKEr37c9YLJvulwm6gDXvwE.ttf",
//       "100italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVnJx26TKEr37c9aBBx_nwMxAzephhN.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxUl0s7iLSrwFUlw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxNl4s7iLSrwFUlw.ttf",
//       "regular": "https://fonts.gstatic.com/s/sarabun/v15/DtVjJx26TKEr37c9WBJDnlQN9gk.ttf",
//       "italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVhJx26TKEr37c9aBBJmnYI5gnOpg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxbl8s7iLSrwFUlw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxQlgs7iLSrwFUlw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxJlks7iLSrwFUlw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sarabun/v15/DtVkJx26TKEr37c9aBBxOlos7iLSrwFUlw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sarabun/v15/DtVjJx26TKEr37c9aBNJmg.ttf"
//   },
//   {
//     "family": "Noto Sans Arabic",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic"
//     ],
//     "version": "v18",
//     "lastModified": "2022-06-01",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyG2vu3CBFQLaig.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfSGyvu3CBFQLaig.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCflmyvu3CBFQLaig.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCf-myvu3CBFQLaig.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfFmuvu3CBFQLaig.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfL2uvu3CBFQLaig.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfSGuvu3CBFQLaig.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfYWuvu3CBFQLaig.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyvu3CBFQLaig.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansarabic/v18/nwpxtLGrOAZMl5nJ_wfgRg3DrWFZWsnVBJ_sS6tlqHHFlhQ5l3sQWIHPqzCfyGyfunqF.ttf"
//   },
//   {
//     "family": "Archivo Narrow",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/archivonarrow/v30/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvHlGKpHOtFCQ76Q.ttf",
//       "600": "https://fonts.gstatic.com/s/archivonarrow/v30/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhv8laKpHOtFCQ76Q.ttf",
//       "700": "https://fonts.gstatic.com/s/archivonarrow/v30/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvy1aKpHOtFCQ76Q.ttf",
//       "regular": "https://fonts.gstatic.com/s/archivonarrow/v30/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvLFGKpHOtFCQ76Q.ttf",
//       "italic": "https://fonts.gstatic.com/s/archivonarrow/v30/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BJi53mpNiEr6T6Y.ttf",
//       "500italic": "https://fonts.gstatic.com/s/archivonarrow/v30/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BJQ53mpNiEr6T6Y.ttf",
//       "600italic": "https://fonts.gstatic.com/s/archivonarrow/v30/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BK84HmpNiEr6T6Y.ttf",
//       "700italic": "https://fonts.gstatic.com/s/archivonarrow/v30/tss7ApVBdCYD5Q7hcxTE1ArZ0bb1k3JSLwe1hB965BKF4HmpNiEr6T6Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/archivonarrow/v30/tss5ApVBdCYD5Q7hcxTE1ArZ0Zz8oY2KRmwvKhhvLFG6pXmp.ttf"
//   },
//   {
//     "family": "Urbanist",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx8fFpOrS8SlKw.ttf",
//       "200": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDSx4fFpOrS8SlKw.ttf",
//       "300": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDlR4fFpOrS8SlKw.ttf",
//       "500": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqD-R4fFpOrS8SlKw.ttf",
//       "600": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDFRkfFpOrS8SlKw.ttf",
//       "700": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDLBkfFpOrS8SlKw.ttf",
//       "800": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDSxkfFpOrS8SlKw.ttf",
//       "900": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDYhkfFpOrS8SlKw.ttf",
//       "regular": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4fFpOrS8SlKw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA133VJmvacG1K4S1.ttf",
//       "200italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA113VZmvacG1K4S1.ttf",
//       "300italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA12pVZmvacG1K4S1.ttf",
//       "italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA133VZmvacG1K4S1.ttf",
//       "500italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA13FVZmvacG1K4S1.ttf",
//       "600italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA10pUpmvacG1K4S1.ttf",
//       "700italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA10QUpmvacG1K4S1.ttf",
//       "800italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA113UpmvacG1K4S1.ttf",
//       "900italic": "https://fonts.gstatic.com/s/urbanist/v15/L0xtDF02iFML4hGCyMqgdyNEf6or5L2WA11eUpmvacG1K4S1.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/urbanist/v15/L0xjDF02iFML4hGCyOCpRdycFsGxSrqDyx4vF5mv.ttf"
//   },
//   {
//     "family": "Concert One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/concertone/v21/VEM1Ro9xs5PjtzCu-srDqRTlhv-CuVAQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/concertone/v21/VEM1Ro9xs5PjtzCu-srDqSTkjPs.ttf"
//   },
//   {
//     "family": "Vollkorn",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/vollkorn/v23/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2AnGuGWOdEbD63w.ttf",
//       "600": "https://fonts.gstatic.com/s/vollkorn/v23/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df27nauGWOdEbD63w.ttf",
//       "700": "https://fonts.gstatic.com/s/vollkorn/v23/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df213auGWOdEbD63w.ttf",
//       "800": "https://fonts.gstatic.com/s/vollkorn/v23/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2sHauGWOdEbD63w.ttf",
//       "900": "https://fonts.gstatic.com/s/vollkorn/v23/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2mXauGWOdEbD63w.ttf",
//       "regular": "https://fonts.gstatic.com/s/vollkorn/v23/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2MHGuGWOdEbD63w.ttf",
//       "italic": "https://fonts.gstatic.com/s/vollkorn/v23/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DJGWmmZM7Xq34g9.ttf",
//       "500italic": "https://fonts.gstatic.com/s/vollkorn/v23/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DJ0WmmZM7Xq34g9.ttf",
//       "600italic": "https://fonts.gstatic.com/s/vollkorn/v23/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DKYXWmZM7Xq34g9.ttf",
//       "700italic": "https://fonts.gstatic.com/s/vollkorn/v23/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DKhXWmZM7Xq34g9.ttf",
//       "800italic": "https://fonts.gstatic.com/s/vollkorn/v23/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DLGXWmZM7Xq34g9.ttf",
//       "900italic": "https://fonts.gstatic.com/s/vollkorn/v23/0ybuGDoxxrvAnPhYGxksckM2WMCpRjDj-DLvXWmZM7Xq34g9.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vollkorn/v23/0ybgGDoxxrvAnPhYGzMlQLzuMasz6Df2MHGeGGmZ.ttf"
//   },
//   {
//     "family": "Acme",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/acme/v25/RrQfboBx-C5_bx3Lb23lzLk.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/acme/v25/RrQfboBx-C5_XxzBaw.ttf"
//   },
//   {
//     "family": "Orbitron",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyKS6xpmIyXjU1pg.ttf",
//       "600": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyxSmxpmIyXjU1pg.ttf",
//       "700": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1ny_CmxpmIyXjU1pg.ttf",
//       "800": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nymymxpmIyXjU1pg.ttf",
//       "900": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimxpmIyXjU1pg.ttf",
//       "regular": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6xpmIyXjU1pg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/orbitron/v31/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nyGy6Bp2g2.ttf"
//   },
//   {
//     "family": "Russo One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/russoone/v16/Z9XUDmZRWg6M1LvRYsH-yMOInrib9Q.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/russoone/v16/Z9XUDmZRWg6M1LvRYsHOycmM.ttf"
//   },
//   {
//     "family": "Exo",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM2CwNsOl4p5Is.ttf",
//       "200": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4tM3CwNsOl4p5Is.ttf",
//       "300": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4g03CwNsOl4p5Is.ttf",
//       "500": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4mE3CwNsOl4p5Is.ttf",
//       "600": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4o0wCwNsOl4p5Is.ttf",
//       "700": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwCwNsOl4p5Is.ttf",
//       "800": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4tMwCwNsOl4p5Is.ttf",
//       "900": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4vowCwNsOl4p5Is.ttf",
//       "regular": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3CwNsOl4p5Is.ttf",
//       "100italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t040FmPnws9Iu-uA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t0Y0BmPnws9Iu-uA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t0vUBmPnws9Iu-uA.ttf",
//       "italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t040BmPnws9Iu-uA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t00UBmPnws9Iu-uA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t0PUdmPnws9Iu-uA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t0BEdmPnws9Iu-uA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t0Y0dmPnws9Iu-uA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/exo/v21/4UafrEtFpBISdmSt-MY2ehbO95t0SkdmPnws9Iu-uA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3OwJmPg.ttf"
//   },
//   {
//     "family": "Asap Condensed",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-05-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO9DSWlEgGqgp-pO.ttf",
//       "300": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO8nSmlEgGqgp-pO.ttf",
//       "500": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO9_S2lEgGqgp-pO.ttf",
//       "600": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO9TTGlEgGqgp-pO.ttf",
//       "700": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO83TWlEgGqgp-pO.ttf",
//       "800": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO8rTmlEgGqgp-pO.ttf",
//       "900": "https://fonts.gstatic.com/s/asapcondensed/v17/pxieypY1o9NHyXh3WvSbGSggdO8PT2lEgGqgp-pO.ttf",
//       "200italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUIFFim6CovpOkXA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUOVGim6CovpOkXA.ttf",
//       "regular": "https://fonts.gstatic.com/s/asapcondensed/v17/pxidypY1o9NHyXh3WvSbGSggdNeLYk1Mq3ap.ttf",
//       "italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxifypY1o9NHyXh3WvSbGSggdOeJaElurmapvvM.ttf",
//       "500italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUL1Him6CovpOkXA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUJFAim6CovpOkXA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUPVBim6CovpOkXA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUOlCim6CovpOkXA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/asapcondensed/v17/pxiYypY1o9NHyXh3WvSbGSggdOeJUM1Dim6CovpOkXA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/asapcondensed/v17/pxidypY1o9NHyXh3WvSbGSggdOeKaEk.ttf"
//   },
//   {
//     "family": "Frank Ruhl Libre",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-06-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw6bYVqQPxR2EUR_.ttf",
//       "500": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw73YVqQPxR2EUR_.ttf",
//       "600": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw4bZlqQPxR2EUR_.ttf",
//       "700": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw4iZlqQPxR2EUR_.ttf",
//       "800": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw5FZlqQPxR2EUR_.ttf",
//       "900": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw5sZlqQPxR2EUR_.ttf",
//       "regular": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw7FYVqQPxR2EUR_.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/frankruhllibre/v20/j8_96_fAw7jrcalD7oKYNX0QfAnPcbzNEEB7OoicBw7FYWqRNRA.ttf"
//   },
//   {
//     "family": "Alegreya",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v35",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/alegreya/v35/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGxBUI_KCisSGVrw.ttf",
//       "600": "https://fonts.gstatic.com/s/alegreya/v35/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGKBII_KCisSGVrw.ttf",
//       "700": "https://fonts.gstatic.com/s/alegreya/v35/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGERII_KCisSGVrw.ttf",
//       "800": "https://fonts.gstatic.com/s/alegreya/v35/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGdhII_KCisSGVrw.ttf",
//       "900": "https://fonts.gstatic.com/s/alegreya/v35/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNGXxII_KCisSGVrw.ttf",
//       "regular": "https://fonts.gstatic.com/s/alegreya/v35/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hUI_KCisSGVrw.ttf",
//       "italic": "https://fonts.gstatic.com/s/alegreya/v35/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlbgv6qmkySFr9V9.ttf",
//       "500italic": "https://fonts.gstatic.com/s/alegreya/v35/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlbSv6qmkySFr9V9.ttf",
//       "600italic": "https://fonts.gstatic.com/s/alegreya/v35/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlY-uKqmkySFr9V9.ttf",
//       "700italic": "https://fonts.gstatic.com/s/alegreya/v35/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlYHuKqmkySFr9V9.ttf",
//       "800italic": "https://fonts.gstatic.com/s/alegreya/v35/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlZguKqmkySFr9V9.ttf",
//       "900italic": "https://fonts.gstatic.com/s/alegreya/v35/4UaSrEBBsBhlBjvfkSLk3abBFkvpkARTPlZJuKqmkySFr9V9.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alegreya/v35/4UacrEBBsBhlBjvfkQjt71kZfyBzPgNG9hU4_aqm.ttf"
//   },
//   {
//     "family": "Cinzel",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-uTnTYrvDE5ZdqU.ttf",
//       "600": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-gjgTYrvDE5ZdqU.ttf",
//       "700": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-jHgTYrvDE5ZdqU.ttf",
//       "800": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-lbgTYrvDE5ZdqU.ttf",
//       "900": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-n_gTYrvDE5ZdqU.ttf",
//       "regular": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnTYrvDE5ZdqU.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cinzel/v23/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnfYvlCA.ttf"
//   },
//   {
//     "family": "Mate",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mate/v17/m8JdjftRd7WZ2z28WoXSaLU.ttf",
//       "italic": "https://fonts.gstatic.com/s/mate/v17/m8JTjftRd7WZ6z-2XqfXeLVdbw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mate/v17/m8JdjftRd7WZ6zy2Xg.ttf"
//   },
//   {
//     "family": "Crimson Pro",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTm18OJE_VNWoyQ.ttf",
//       "300": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZkG18OJE_VNWoyQ.ttf",
//       "500": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZ_G18OJE_VNWoyQ.ttf",
//       "600": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZEGp8OJE_VNWoyQ.ttf",
//       "700": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZKWp8OJE_VNWoyQ.ttf",
//       "800": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZTmp8OJE_VNWoyQ.ttf",
//       "900": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZZ2p8OJE_VNWoyQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm18OJE_VNWoyQ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4Ue5s7dtC4yZNE.ttf",
//       "300italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi7Ke5s7dtC4yZNE.ttf",
//       "italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6Ue5s7dtC4yZNE.ttf",
//       "500italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi6me5s7dtC4yZNE.ttf",
//       "600italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5KfJs7dtC4yZNE.ttf",
//       "700italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi5zfJs7dtC4yZNE.ttf",
//       "800italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi4UfJs7dtC4yZNE.ttf",
//       "900italic": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uSsoa5M_tv7IihmnkabAReu49Y_Bo-HVKMBi49fJs7dtC4yZNE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/crimsonpro/v24/q5uUsoa5M_tv7IihmnkabC5XiXCAlXGks1WZzm1MOZs7.ttf"
//   },
//   {
//     "family": "Bree Serif",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/breeserif/v17/4UaHrEJCrhhnVA3DgluAx63j5pN1MwI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/breeserif/v17/4UaHrEJCrhhnVA3DgluA96zp4g.ttf"
//   },
//   {
//     "family": "Almarai",
//     "variants": [
//       "300",
//       "regular",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "arabic"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_anhnicoq72sXg.ttf",
//       "700": "https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-aghnicoq72sXg.ttf",
//       "800": "https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_qjhnicoq72sXg.ttf",
//       "regular": "https://fonts.gstatic.com/s/almarai/v12/tsstApxBaigK_hnnc1qPonC3vqc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/almarai/v12/tsstApxBaigK_hnnQ1uFpg.ttf"
//   },
//   {
//     "family": "Marcellus",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/marcellus/v13/wEO_EBrOk8hQLDvIAF8FUfAL3EsHiA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/marcellus/v13/wEO_EBrOk8hQLDvIAF81UPoP.ttf"
//   },
//   {
//     "family": "Chivo",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2022-11-18",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_vB7ul2DSFXjQiQ.ttf",
//       "200": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_PB_ul2DSFXjQiQ.ttf",
//       "300": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_4h_ul2DSFXjQiQ.ttf",
//       "500": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_jh_ul2DSFXjQiQ.ttf",
//       "600": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_Yhjul2DSFXjQiQ.ttf",
//       "700": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_Wxjul2DSFXjQiQ.ttf",
//       "800": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_PBjul2DSFXjQiQ.ttf",
//       "900": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_FRjul2DSFXjQiQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_vB_ul2DSFXjQiQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFwG1WrWN33AiasJ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFyG1GrWN33AiasJ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFxY1GrWN33AiasJ.ttf",
//       "italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFwG1GrWN33AiasJ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFw01GrWN33AiasJ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFzY02rWN33AiasJ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFzh02rWN33AiasJ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFyG02rWN33AiasJ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/chivo/v18/va9Z4kzIxd1KFrBtW-13ZHhT-jDqdFyv02rWN33AiasJ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chivo/v18/va9b4kzIxd1KFppkaRKvDRPJVDf_vB_elmrW.ttf"
//   },
//   {
//     "family": "Kalam",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/kalam/v16/YA9Qr0Wd4kDdMtD6GgLLmCUItqGt.ttf",
//       "700": "https://fonts.gstatic.com/s/kalam/v16/YA9Qr0Wd4kDdMtDqHQLLmCUItqGt.ttf",
//       "regular": "https://fonts.gstatic.com/s/kalam/v16/YA9dr0Wd4kDdMuhWMibDszkB.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kalam/v16/YA9dr0Wd4kDdMthXOCI.ttf"
//   },
//   {
//     "family": "Amatic SC",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/amaticsc/v26/TUZ3zwprpvBS1izr_vOMscG6eb8D3WTy-A.ttf",
//       "regular": "https://fonts.gstatic.com/s/amaticsc/v26/TUZyzwprpvBS1izr_vO0De6ecZQf1A.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amaticsc/v26/TUZyzwprpvBS1izr_vOEDOSa.ttf"
//   },
//   {
//     "family": "Montserrat Alternates",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/montserratalternates/v17/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU0xiKfVKphL03l4.ttf",
//       "200": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xJIb1ALZH2mBhkw.ttf",
//       "300": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xQIX1ALZH2mBhkw.ttf",
//       "500": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xGIT1ALZH2mBhkw.ttf",
//       "600": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xNIP1ALZH2mBhkw.ttf",
//       "700": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xUIL1ALZH2mBhkw.ttf",
//       "800": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xTIH1ALZH2mBhkw.ttf",
//       "900": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTiWacfw6zH4dthXcyms1lPpC8I_b0juU0xaID1ALZH2mBhkw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTjWacfw6zH4dthXcyms1lPpC8I_b0juU057p-xIJxp1ml4imo.ttf",
//       "200italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8dAbxD-GVxk3Nd.ttf",
//       "300italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p95ArxD-GVxk3Nd.ttf",
//       "regular": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0J7K3RCJ1b0w.ttf",
//       "italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFThWacfw6zH4dthXcyms1lPpC8I_b0juU057qfVKphL03l4.ttf",
//       "500italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8hA7xD-GVxk3Nd.ttf",
//       "600italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p8NBLxD-GVxk3Nd.ttf",
//       "700italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9pBbxD-GVxk3Nd.ttf",
//       "800italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p91BrxD-GVxk3Nd.ttf",
//       "900italic": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTkWacfw6zH4dthXcyms1lPpC8I_b0juU057p9RB7xD-GVxk3Nd.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/montserratalternates/v17/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU057afV.ttf"
//   },
//   {
//     "family": "Noticia Text",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/noticiatext/v15/VuJpdNDF2Yv9qppOePKYRP1-3R59v2HRrDH0eA.ttf",
//       "regular": "https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP1GYTFZt0rNpQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/noticiatext/v15/VuJodNDF2Yv9qppOePKYRP12YztdlU_dpSjt.ttf",
//       "700italic": "https://fonts.gstatic.com/s/noticiatext/v15/VuJrdNDF2Yv9qppOePKYRP12YwPhumvVjjTkeMnz.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/noticiatext/v15/VuJ2dNDF2Yv9qppOePKYRP12YDtd.ttf"
//   },
//   {
//     "family": "Libre Caslon Text",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT578IGsGw1aF1JU10PUbTvNNaDMfID8sdjNR-8ssPt.ttf",
//       "regular": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT878IGsGw1aF1JU10PUbTvNNaDMcq_3eNrHgO1.ttf",
//       "italic": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT678IGsGw1aF1JU10PUbTvNNaDMfq91-dJGxO1q9o.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librecaslontext/v5/DdT878IGsGw1aF1JU10PUbTvNNaDMfq-1-c.ttf"
//   },
//   {
//     "family": "ABeeZee",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf",
//       "italic": "https://fonts.gstatic.com/s/abeezee/v22/esDT31xSG-6AGleN2tCklZUCGpG-GQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN2tOklQ.ttf"
//   },
//   {
//     "family": "Alegreya Sans",
//     "variants": [
//       "100",
//       "100italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2022-12-08",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUt9_-1phKLFgshYDvh6Vwt5TltuGdShm5bsg.ttf",
//       "300": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5fFPmE18imdCqxI.ttf",
//       "500": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5alOmE18imdCqxI.ttf",
//       "700": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5eFImE18imdCqxI.ttf",
//       "800": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5f1LmE18imdCqxI.ttf",
//       "900": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUu9_-1phKLFgshYDvh6Vwt5dlKmE18imdCqxI.ttf",
//       "100italic": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUv9_-1phKLFgshYDvh6Vwt7V9V3G1WpGtLsgu7.ttf",
//       "300italic": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VFE92jkVHuxKiBA.ttf",
//       "regular": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUz9_-1phKLFgshYDvh6Vwt3V1nvEVXlm4.ttf",
//       "italic": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUt9_-1phKLFgshYDvh6Vwt7V9tuGdShm5bsg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VTE52jkVHuxKiBA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VBEh2jkVHuxKiBA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VGEt2jkVHuxKiBA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUo9_-1phKLFgshYDvh6Vwt7V9VPEp2jkVHuxKiBA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alegreyasans/v24/5aUz9_-1phKLFgshYDvh6Vwt7VxtuA.ttf"
//   },
//   {
//     "family": "Cormorant",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/cormorant/v21/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFk9TQ7Rg7A2uwYs.ttf",
//       "500": "https://fonts.gstatic.com/s/cormorant/v21/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFiNTQ7Rg7A2uwYs.ttf",
//       "600": "https://fonts.gstatic.com/s/cormorant/v21/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFs9UQ7Rg7A2uwYs.ttf",
//       "700": "https://fonts.gstatic.com/s/cormorant/v21/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFvZUQ7Rg7A2uwYs.ttf",
//       "regular": "https://fonts.gstatic.com/s/cormorant/v21/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFhFTQ7Rg7A2uwYs.ttf",
//       "300italic": "https://fonts.gstatic.com/s/cormorant/v21/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQ9fdq6C-r0YvxdA.ttf",
//       "italic": "https://fonts.gstatic.com/s/cormorant/v21/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQq_dq6C-r0YvxdA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/cormorant/v21/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQmfdq6C-r0YvxdA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/cormorant/v21/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQdfBq6C-r0YvxdA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cormorant/v21/H4c0BXOCl9bbnla_nHIq6oGzilJm9otsA9kQTPBq6C-r0YvxdA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cormorant/v21/H4c2BXOCl9bbnla_nHIA47NMUjsNbCVrFhFTc7Vq6A.ttf"
//   },
//   {
//     "family": "Oleo Script",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-05-10",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/oleoscript/v14/raxkHieDvtMOe0iICsUccCDmnmrY2zqUKafv.ttf",
//       "regular": "https://fonts.gstatic.com/s/oleoscript/v14/rax5HieDvtMOe0iICsUccBhasU7Q8Cad.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oleoscript/v14/rax5HieDvtMOe0iICsUccChbu0o.ttf"
//   },
//   {
//     "family": "Tinos",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/tinos/v24/buE1poGnedXvwj1AW0Fp2i43-cxL.ttf",
//       "regular": "https://fonts.gstatic.com/s/tinos/v24/buE4poGnedXvwgX8dGVh8TI-.ttf",
//       "italic": "https://fonts.gstatic.com/s/tinos/v24/buE2poGnedXvwjX-fmFD9CI-4NU.ttf",
//       "700italic": "https://fonts.gstatic.com/s/tinos/v24/buEzpoGnedXvwjX-Rt1s0CoV_NxLeiw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tinos/v24/buE4poGnedXvwjX9fmE.ttf"
//   },
//   {
//     "family": "Philosopher",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/philosopher/v19/vEFI2_5QCwIS4_Dhez5jcWjVamgc-NaXXq7H.ttf",
//       "regular": "https://fonts.gstatic.com/s/philosopher/v19/vEFV2_5QCwIS4_Dhez5jcVBpRUwU08qe.ttf",
//       "italic": "https://fonts.gstatic.com/s/philosopher/v19/vEFX2_5QCwIS4_Dhez5jcWBrT0g21tqeR7c.ttf",
//       "700italic": "https://fonts.gstatic.com/s/philosopher/v19/vEFK2_5QCwIS4_Dhez5jcWBrd_QZ8tK1W77HtMo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/philosopher/v19/vEFV2_5QCwIS4_Dhez5jcWBoT0g.ttf"
//   },
//   {
//     "family": "Spectral",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-01-06",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9v2s13GY_etWWIJ.ttf",
//       "300": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uSsF3GY_etWWIJ.ttf",
//       "500": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vKsV3GY_etWWIJ.ttf",
//       "600": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9vmtl3GY_etWWIJ.ttf",
//       "700": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uCt13GY_etWWIJ.ttf",
//       "800": "https://fonts.gstatic.com/s/spectral/v13/rnCs-xNNww_2s0amA9uetF3GY_etWWIJ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qrXHafOPXHIJErY.ttf",
//       "300italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qtHEafOPXHIJErY.ttf",
//       "regular": "https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA-M-mHnOSOuk.ttf",
//       "italic": "https://fonts.gstatic.com/s/spectral/v13/rnCt-xNNww_2s0amA9M8kn3sTfukQHs.ttf",
//       "500italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qonFafOPXHIJErY.ttf",
//       "600italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qqXCafOPXHIJErY.ttf",
//       "700italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qsHDafOPXHIJErY.ttf",
//       "800italic": "https://fonts.gstatic.com/s/spectral/v13/rnCu-xNNww_2s0amA9M8qt3AafOPXHIJErY.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/spectral/v13/rnCr-xNNww_2s0amA9M_kn0.ttf"
//   },
//   {
//     "family": "Zeyada",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zeyada/v19/11hAGpPTxVPUbgZDNGatWKaZ3g.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zeyada/v19/11hAGpPTxVPUbgZzNWyp.ttf"
//   },
//   {
//     "family": "Great Vibes",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/greatvibes/v18/RWmMoKWR9v4ksMfaWd_JN-XCg6UKDXlq.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/greatvibes/v18/RWmMoKWR9v4ksMfaWd_JN9XDiaE.ttf"
//   },
//   {
//     "family": "Noto Serif KR",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTihC8O1ZNH1ahck.otf",
//       "300": "https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTkxB8O1ZNH1ahck.otf",
//       "500": "https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXThRA8O1ZNH1ahck.otf",
//       "600": "https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTjhH8O1ZNH1ahck.otf",
//       "700": "https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTlxG8O1ZNH1ahck.otf",
//       "900": "https://fonts.gstatic.com/s/notoserifkr/v20/3JnmSDn90Gmq2mr3blnHaTZXTmRE8O1ZNH1ahck.otf",
//       "regular": "https://fonts.gstatic.com/s/notoserifkr/v20/3Jn7SDn90Gmq2mr3blnHaTZXduZp1ONyKHQ.otf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifkr/v20/3Jn7SDn90Gmq2mr3blnHaTZXRuFj1g.otf"
//   },
//   {
//     "family": "Alfa Slab One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alfaslabone/v19/6NUQ8FmMKwSEKjnm5-4v-4Jh6dVretWvYmE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alfaslabone/v19/6NUQ8FmMKwSEKjnm5-4v-4Jh2dRhfg.ttf"
//   },
//   {
//     "family": "Lexend Deca",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48MxArBPCqLNflg.ttf",
//       "200": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4cM1ArBPCqLNflg.ttf",
//       "300": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4rs1ArBPCqLNflg.ttf",
//       "500": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4ws1ArBPCqLNflg.ttf",
//       "600": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4LspArBPCqLNflg.ttf",
//       "700": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4F8pArBPCqLNflg.ttf",
//       "800": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4cMpArBPCqLNflg.ttf",
//       "900": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U4WcpArBPCqLNflg.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48M1ArBPCqLNflg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexenddeca/v21/K2FifZFYk-dHSE0UPPuwQ7CrD94i-NCKm-U48M1wrRnG.ttf"
//   },
//   {
//     "family": "Noto Sans Thai",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai"
//     ],
//     "version": "v25",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RspzF-QRvzzXg.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUxRtpzF-QRvzzXg.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU8ptpzF-QRvzzXg.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU6ZtpzF-QRvzzXg.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU0pqpzF-QRvzzXg.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU3NqpzF-QRvzzXg.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUxRqpzF-QRvzzXg.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdUz1qpzF-QRvzzXg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtpzF-QRvzzXg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansthai/v25/iJWnBXeUZi_OHPqn4wq6hQ2_hbJ1xyN9wd43SofNWcd1MKVQt_So_9CdU5RtlzB0RQ.ttf"
//   },
//   {
//     "family": "Encode Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHiZtWP7FJCt2c.ttf",
//       "200": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGOHjZtWP7FJCt2c.ttf",
//       "300": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGD_jZtWP7FJCt2c.ttf",
//       "500": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGFPjZtWP7FJCt2c.ttf",
//       "600": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGL_kZtWP7FJCt2c.ttf",
//       "700": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGIbkZtWP7FJCt2c.ttf",
//       "800": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGOHkZtWP7FJCt2c.ttf",
//       "900": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGMjkZtWP7FJCt2c.ttf",
//       "regular": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHjZtWP7FJCt2c.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/encodesans/v19/LDIcapOFNxEwR-Bd1O9uYNmnUQomAgE25imKSbHhROjLsZBWTSrQGGHjVtSF6A.ttf"
//   },
//   {
//     "family": "Passion One",
//     "variants": [
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/passionone/v18/Pby6FmL8HhTPqbjUzux3JEMq037owpYcuH8y.ttf",
//       "900": "https://fonts.gstatic.com/s/passionone/v18/Pby6FmL8HhTPqbjUzux3JEMS0X7owpYcuH8y.ttf",
//       "regular": "https://fonts.gstatic.com/s/passionone/v18/PbynFmL8HhTPqbjUzux3JHuW_Frg6YoV.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/passionone/v18/PbynFmL8HhTPqbjUzux3JEuX9l4.ttf"
//   },
//   {
//     "family": "Lobster Two",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/lobstertwo/v20/BngRUXZGTXPUvIoyV6yN5-92w4CByxyKeuDp.ttf",
//       "regular": "https://fonts.gstatic.com/s/lobstertwo/v20/BngMUXZGTXPUvIoyV6yN59fK7KSJ4ACD.ttf",
//       "italic": "https://fonts.gstatic.com/s/lobstertwo/v20/BngOUXZGTXPUvIoyV6yN5-fI5qCr5RCDY_k.ttf",
//       "700italic": "https://fonts.gstatic.com/s/lobstertwo/v20/BngTUXZGTXPUvIoyV6yN5-fI3hyEwRiof_DpXMY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lobstertwo/v20/BngMUXZGTXPUvIoyV6yN5-fL5qA.ttf"
//   },
//   {
//     "family": "Courgette",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/courgette/v17/wEO_EBrAnc9BLjLQAUkFUfAL3EsHiA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/courgette/v17/wEO_EBrAnc9BLjLQAUk1UPoP.ttf"
//   },
//   {
//     "family": "Patua One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/patuaone/v20/ZXuke1cDvLCKLDcimxBI5PNvNA9LuA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/patuaone/v20/ZXuke1cDvLCKLDcimxB45flr.ttf"
//   },
//   {
//     "family": "Noto Kufi Arabic",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v21",
//     "lastModified": "2024-02-29",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v3obPnLSmf5yD.ttf",
//       "200": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh7v34bPnLSmf5yD.ttf",
//       "300": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh4x34bPnLSmf5yD.ttf",
//       "500": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5d34bPnLSmf5yD.ttf",
//       "600": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh6x2IbPnLSmf5yD.ttf",
//       "700": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh6I2IbPnLSmf5yD.ttf",
//       "800": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh7v2IbPnLSmf5yD.ttf",
//       "900": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh7G2IbPnLSmf5yD.ttf",
//       "regular": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v34bPnLSmf5yD.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notokufiarabic/v21/CSRp4ydQnPyaDxEXLFF6LZVLKrodhu8t57o1kDc5Wh5v37bOlrA.ttf"
//   },
//   {
//     "family": "Prata",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/prata/v20/6xKhdSpbNNCT-vWIAG_5LWwJ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/prata/v20/6xKhdSpbNNCT-sWJCms.ttf"
//   },
//   {
//     "family": "Khand",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-12-08",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bL5cFE3ZwaH__-C.ttf",
//       "500": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bKhcVE3ZwaH__-C.ttf",
//       "600": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bKNdlE3ZwaH__-C.ttf",
//       "700": "https://fonts.gstatic.com/s/khand/v17/TwMN-IINQlQQ0bLpd1E3ZwaH__-C.ttf",
//       "regular": "https://fonts.gstatic.com/s/khand/v17/TwMA-IINQlQQ0YpVWHU_TBqO.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/khand/v17/TwMA-IINQlQQ0bpUUnE.ttf"
//   },
//   {
//     "family": "Martel",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVqekahRbX9vnDzw.ttf",
//       "300": "https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVzeoahRbX9vnDzw.ttf",
//       "600": "https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVuewahRbX9vnDzw.ttf",
//       "700": "https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XV3e0ahRbX9vnDzw.ttf",
//       "800": "https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XVwe4ahRbX9vnDzw.ttf",
//       "900": "https://fonts.gstatic.com/s/martel/v10/PN_yRfK9oXHga0XV5e8ahRbX9vnDzw.ttf",
//       "regular": "https://fonts.gstatic.com/s/martel/v10/PN_xRfK9oXHga0XtYcI-jT3L_w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/martel/v10/PN_xRfK9oXHga0XdYMg6.ttf"
//   },
//   {
//     "family": "Changa",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZy2xQjDp9htf1ZM.ttf",
//       "300": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZ_OxQjDp9htf1ZM.ttf",
//       "500": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZ5-xQjDp9htf1ZM.ttf",
//       "600": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZ3O2QjDp9htf1ZM.ttf",
//       "700": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZ0q2QjDp9htf1ZM.ttf",
//       "800": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZy22QjDp9htf1ZM.ttf",
//       "regular": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xQjDp9htf1ZM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/changa/v27/2-c79JNi2YuVOUcOarRPgnNGooxCZ62xcjHj8g.ttf"
//   },
//   {
//     "family": "Amiri",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-01-10",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/amiri/v27/J7acnpd8CGxBHp2VkZY4xJ9CGyAa.ttf",
//       "regular": "https://fonts.gstatic.com/s/amiri/v27/J7aRnpd8CGxBHqUpvrIw74NL.ttf",
//       "italic": "https://fonts.gstatic.com/s/amiri/v27/J7afnpd8CGxBHpUrtLYS6pNLAjk.ttf",
//       "700italic": "https://fonts.gstatic.com/s/amiri/v27/J7aanpd8CGxBHpUrjAo9zptgHjAavCA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amiri/v27/J7aRnpd8CGxBHpUotLY.ttf"
//   },
//   {
//     "family": "Titan One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/titanone/v15/mFTzWbsGxbbS_J5cQcjykzIn2Etikg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/titanone/v15/mFTzWbsGxbbS_J5cQcjCkjgj.ttf"
//   },
//   {
//     "family": "Noto Sans Display",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cLVTGQ2iHrvWM.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp__cKVTGQ2iHrvWM.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_ykKVTGQ2iHrvWM.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_0UKVTGQ2iHrvWM.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_6kNVTGQ2iHrvWM.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_5ANVTGQ2iHrvWM.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp__cNVTGQ2iHrvWM.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_94NVTGQ2iHrvWM.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cKVTGQ2iHrvWM.ttf",
//       "100italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JvXOa3gPurWM9uQ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JPXKa3gPurWM9uQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9J43Ka3gPurWM9uQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JvXKa3gPurWM9uQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9Jj3Ka3gPurWM9uQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JY3Wa3gPurWM9uQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JWnWa3gPurWM9uQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JPXWa3gPurWM9uQ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpZK4fy6r6tOBEJg0IAKzqdFZVZxrktbnDB5UzBIup9PwAcHtEsOFNBZqyu6r9JFHWa3gPurWM9uQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansdisplay/v26/RLpbK4fy6r6tOBEJg0IAKzqdFZVZxpMkXJMhnB9XjO1o90LuV-PT4Doq_AKp_3cKZTCa3g.ttf"
//   },
//   {
//     "family": "Silkscreen",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/silkscreen/v4/m8JUjfVPf62XiF7kO-i9aAhATmuo2dudFvc.ttf",
//       "regular": "https://fonts.gstatic.com/s/silkscreen/v4/m8JXjfVPf62XiF7kO-i9ULRvamODxdI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/silkscreen/v4/m8JXjfVPf62XiF7kO-i9YLVlbg.ttf"
//   },
//   {
//     "family": "Noto Serif TC",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "chinese-traditional",
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2022-09-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0Bvr8vbX9GTsoOAX4.otf",
//       "300": "https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvtssbX9GTsoOAX4.otf",
//       "500": "https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvoMtbX9GTsoOAX4.otf",
//       "600": "https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0Bvq8qbX9GTsoOAX4.otf",
//       "700": "https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvssrbX9GTsoOAX4.otf",
//       "900": "https://fonts.gstatic.com/s/notoseriftc/v23/XLY9IZb5bJNDGYxLBibeHZ0BvvMpbX9GTsoOAX4.otf",
//       "regular": "https://fonts.gstatic.com/s/notoseriftc/v23/XLYgIZb5bJNDGYxLBibeHZ0BhnEESXFtUsM.otf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriftc/v23/XLYgIZb5bJNDGYxLBibeHZ0BtnYOSw.otf"
//   },
//   {
//     "family": "Be Vietnam Pro",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-06-07",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVNSTAyLFyeg_IDWvOJmVES_HRUBX8YYbAiah8.ttf",
//       "200": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HT4JF8yT7wrcwap.ttf",
//       "300": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HScJ18yT7wrcwap.ttf",
//       "500": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HTEJl8yT7wrcwap.ttf",
//       "600": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HToIV8yT7wrcwap.ttf",
//       "700": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HSMIF8yT7wrcwap.ttf",
//       "800": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HSQI18yT7wrcwap.ttf",
//       "900": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HS0Il8yT7wrcwap.ttf",
//       "100italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVLSTAyLFyeg_IDWvOJmVES_HwyPRsSZZIneh-waA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPbczRbgJdhapcUU.ttf",
//       "300italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPdMwRbgJdhapcUU.ttf",
//       "regular": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVPSTAyLFyeg_IDWvOJmVES_EwwD3s6ZKAi.ttf",
//       "italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVNSTAyLFyeg_IDWvOJmVES_HwyBX8YYbAiah8.ttf",
//       "500italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPYsxRbgJdhapcUU.ttf",
//       "600italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPac2RbgJdhapcUU.ttf",
//       "700italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPcM3RbgJdhapcUU.ttf",
//       "800italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPd80RbgJdhapcUU.ttf",
//       "900italic": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVKSTAyLFyeg_IDWvOJmVES_HwyPfs1RbgJdhapcUU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bevietnampro/v11/QdVPSTAyLFyeg_IDWvOJmVES_HwxBX8.ttf"
//   },
//   {
//     "family": "Righteous",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/righteous/v17/1cXxaUPXBpj2rGoU7C9mj3uEicG01A.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/righteous/v17/1cXxaUPXBpj2rGoU7C9WjnGA.ttf"
//   },
//   {
//     "family": "Cardo",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/cardo/v19/wlpygwjKBV1pqhND-aQR82JHaTBX.ttf",
//       "regular": "https://fonts.gstatic.com/s/cardo/v19/wlp_gwjKBV1pqiv_1oAZ2H5O.ttf",
//       "italic": "https://fonts.gstatic.com/s/cardo/v19/wlpxgwjKBV1pqhv93IQ73W5OcCk.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cardo/v19/wlp_gwjKBV1pqhv-3IQ.ttf"
//   },
//   {
//     "family": "Space Mono",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-06-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/spacemono/v13/i7dMIFZifjKcF5UAWdDRaPpZYFKQHwyVd3U.ttf",
//       "regular": "https://fonts.gstatic.com/s/spacemono/v13/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf",
//       "italic": "https://fonts.gstatic.com/s/spacemono/v13/i7dNIFZifjKcF5UAWdDRYER8QHi-EwWMbg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/spacemono/v13/i7dSIFZifjKcF5UAWdDRYERE_FeaGy6QZ3WfYg.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/spacemono/v13/i7dPIFZifjKcF5UAWdDRYEd8QA.ttf"
//   },
//   {
//     "family": "Cantarell",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-11-09",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/cantarell/v17/B50IF7ZDq37KMUvlO01xN4dOFISeJY8GgQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/cantarell/v17/B50NF7ZDq37KMUvlO01Ji6hqHK-CLA.ttf",
//       "italic": "https://fonts.gstatic.com/s/cantarell/v17/B50LF7ZDq37KMUvlO015iaJuPqqSLJYf.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cantarell/v17/B50WF7ZDq37KMUvlO015iZrSEY6aB4oWgWHB.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cantarell/v17/B50NF7ZDq37KMUvlO015iqJu.ttf"
//   },
//   {
//     "family": "PT Sans Caption",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/ptsanscaption/v19/0FlJVP6Hrxmt7-fsUFhlFXNIlpcSwSrUSwWuz38Tgg.ttf",
//       "regular": "https://fonts.gstatic.com/s/ptsanscaption/v19/0FlMVP6Hrxmt7-fsUFhlFXNIlpcqfQXwQy6yxg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ptsanscaption/v19/0FlMVP6Hrxmt7-fsUFhlFXNIlpcafA_0.ttf"
//   },
//   {
//     "family": "Merienda",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5JHhoSU78QGBV0A.ttf",
//       "500": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5SHhoSU78QGBV0A.ttf",
//       "600": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5pH9oSU78QGBV0A.ttf",
//       "700": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5nX9oSU78QGBV0A.ttf",
//       "800": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5-n9oSU78QGBV0A.ttf",
//       "900": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5039oSU78QGBV0A.ttf",
//       "regular": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5enhoSU78QGBV0A.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/merienda/v19/gNMaW3x8Qoy5_mf8uUkJGHtiYXjmKFy5enhYSET4.ttf"
//   },
//   {
//     "family": "IBM Plex Sans Arabic",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3MZRtWPQCuHme67tEYUIx3Kh0PHR9N6YNe3PC5eMlAMg0.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPy_dCTVsVJKxTs.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YOW_tCTVsVJKxTs.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPO_9CTVsVJKxTs.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YPi-NCTVsVJKxTs.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3NZRtWPQCuHme67tEYUIx3Kh0PHR9N6YOG-dCTVsVJKxTs.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3CZRtWPQCuHme67tEYUIx3Kh0PHR9N6bs61vSbfdlA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsansarabic/v12/Qw3CZRtWPQCuHme67tEYUIx3Kh0PHR9N6Ys73PA.ttf"
//   },
//   {
//     "family": "Neuton",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "italic",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKAKkfegD5Drog6Q.ttf",
//       "300": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKZKofegD5Drog6Q.ttf",
//       "700": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKdK0fegD5Drog6Q.ttf",
//       "800": "https://fonts.gstatic.com/s/neuton/v22/UMBQrPtMoH62xUZKaK4fegD5Drog6Q.ttf",
//       "regular": "https://fonts.gstatic.com/s/neuton/v22/UMBTrPtMoH62xUZyyII7civlBw.ttf",
//       "italic": "https://fonts.gstatic.com/s/neuton/v22/UMBRrPtMoH62xUZCyog_UC71B6M5.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/neuton/v22/UMBTrPtMoH62xUZCyYg_.ttf"
//   },
//   {
//     "family": "Ubuntu Condensed",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ubuntucondensed/v16/u-4k0rCzjgs5J7oXnJcM_0kACGMtf-fVqvHoJXw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ubuntucondensed/v16/u-4k0rCzjgs5J7oXnJcM_0kACGMtT-bfrg.ttf"
//   },
//   {
//     "family": "Alata",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alata/v9/PbytFmztEwbIofe6xKcRQEOX.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alata/v9/PbytFmztEwbIoce7zqM.ttf"
//   },
//   {
//     "family": "Mate SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/matesc/v22/-nF8OGQ1-uoVr2wKyiXZ95OkJwA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/matesc/v22/-nF8OGQ1-uoVr2wK-iTT8w.ttf"
//   },
//   {
//     "family": "Rubik Bubbles",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v3",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikbubbles/v3/JIA1UVdwbHFJtwA7Us1BPFbRNTENfDxyRXI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikbubbles/v3/JIA1UVdwbHFJtwA7Us1BPFbRBTAHeA.ttf"
//   },
//   {
//     "family": "Yantramanav",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "500",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/yantramanav/v13/flU-Rqu5zY00QEpyWJYWN5-QXeNzDB41rZg.ttf",
//       "300": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN59Yf8NZIhI8tIHh.ttf",
//       "500": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN58AfsNZIhI8tIHh.ttf",
//       "700": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN59IeMNZIhI8tIHh.ttf",
//       "900": "https://fonts.gstatic.com/s/yantramanav/v13/flUhRqu5zY00QEpyWJYWN59wesNZIhI8tIHh.ttf",
//       "regular": "https://fonts.gstatic.com/s/yantramanav/v13/flU8Rqu5zY00QEpyWJYWN6f0V-dRCQ41.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yantramanav/v13/flU8Rqu5zY00QEpyWJYWN5f1XeM.ttf"
//   },
//   {
//     "family": "Sawarabi Mincho",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sawarabimincho/v17/8QIRdiDaitzr7brc8ahpxt6GcIJTLahP46UDUw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sawarabimincho/v17/8QIRdiDaitzr7brc8ahpxt6GcIJjLKJL.ttf"
//   },
//   {
//     "family": "Alice",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6FcJpA_chzJ0.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6GcIrgs.ttf"
//   },
//   {
//     "family": "Paytone One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/paytoneone/v23/0nksC9P7MfYHj2oFtYm2CiTqivr9iBq_.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/paytoneone/v23/0nksC9P7MfYHj2oFtYm2ChTrgP4.ttf"
//   },
//   {
//     "family": "Francois One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/francoisone/v21/_Xmr-H4zszafZw3A-KPSZutNxgKQu_avAg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/francoisone/v21/_Xmr-H4zszafZw3A-KPSZut9xwiU.ttf"
//   },
//   {
//     "family": "Sacramento",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG0CoV_NxLeiw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG4Csf-A.ttf"
//   },
//   {
//     "family": "Rubik Mono One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikmonoone/v18/UqyJK8kPP3hjw6ANTdfRk9YSN-8wRqQrc_j9.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikmonoone/v18/UqyJK8kPP3hjw6ANTdfRk9YSN98xTKA.ttf"
//   },
//   {
//     "family": "Old Standard TT",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQrbh3o1vLImiwAVvYawgcf2eVWEX-dTFxeb80flQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQubh3o1vLImiwAVvYawgcf2eVurVC5RHdCZg.ttf",
//       "italic": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQsbh3o1vLImiwAVvYawgcf2eVer1q9ZnJSZtQG.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oldstandardtt/v20/MwQubh3o1vLImiwAVvYawgcf2eVerFq9.ttf"
//   },
//   {
//     "family": "Crete Round",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/creteround/v14/55xoey1sJNPjPiv1ZZZrxJ1827zAKnxN.ttf",
//       "italic": "https://fonts.gstatic.com/s/creteround/v14/55xqey1sJNPjPiv1ZZZrxK1-0bjiL2xNhKc.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/creteround/v14/55xoey1sJNPjPiv1ZZZrxK190bg.ttf"
//   },
//   {
//     "family": "Yellowtail",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yellowtail/v22/OZpGg_pnoDtINPfRIlLotlzNwED-b4g.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yellowtail/v22/OZpGg_pnoDtINPfRIlLohl3HxA.ttf"
//   },
//   {
//     "family": "Arsenal",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/arsenal/v12/wXKuE3kQtZQ4pF3D7-P5JeQAmX8yrdk.ttf",
//       "regular": "https://fonts.gstatic.com/s/arsenal/v12/wXKrE3kQtZQ4pF3D11_WAewrhXY.ttf",
//       "italic": "https://fonts.gstatic.com/s/arsenal/v12/wXKpE3kQtZQ4pF3D513cBc4ulXYrtA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/arsenal/v12/wXKsE3kQtZQ4pF3D513kueEKnV03vdnKjw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arsenal/v12/wXKrE3kQtZQ4pF3D517cBQ.ttf"
//   },
//   {
//     "family": "Kaushan Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kaushanscript/v16/vm8vdRfvXFLG3OLnsO15WYS5DF7_ytN3M48a.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kaushanscript/v16/vm8vdRfvXFLG3OLnsO15WYS5DG7-wNc.ttf"
//   },
//   {
//     "family": "Black Ops One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3WDzRtjkho4M.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/blackopsone/v20/qWcsB6-ypo7xBdr6Xshe96H3aD3bsg.ttf"
//   },
//   {
//     "family": "Luckiest Guy",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/luckiestguy/v22/_gP_1RrxsjcxVyin9l9n_j2RStR3qDpraA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/luckiestguy/v22/_gP_1RrxsjcxVyin9l9n_j2hS95z.ttf"
//   },
//   {
//     "family": "Eczar",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "devanagari",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-05-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/eczar/v22/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXL96WqTIVKWJKWg.ttf",
//       "600": "https://fonts.gstatic.com/s/eczar/v22/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXw9mWqTIVKWJKWg.ttf",
//       "700": "https://fonts.gstatic.com/s/eczar/v22/BXR2vF3Pi-DLmxcpJB-qbNTyTMDX-tmWqTIVKWJKWg.ttf",
//       "800": "https://fonts.gstatic.com/s/eczar/v22/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXndmWqTIVKWJKWg.ttf",
//       "regular": "https://fonts.gstatic.com/s/eczar/v22/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXHd6WqTIVKWJKWg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/eczar/v22/BXR2vF3Pi-DLmxcpJB-qbNTyTMDXHd6mqDgR.ttf"
//   },
//   {
//     "family": "Bodoni Moda",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v25",
//     "lastModified": "2024-03-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oXzawIBytVjMYwE.ttf",
//       "600": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oZDdwIBytVjMYwE.ttf",
//       "700": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oandwIBytVjMYwE.ttf",
//       "800": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oc7dwIBytVjMYwE.ttf",
//       "900": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oefdwIBytVjMYwE.ttf",
//       "regular": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oU7awIBytVjMYwE.ttf",
//       "italic": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZKMN4sXrJcwHqoQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZGsN4sXrJcwHqoQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZ9sR4sXrJcwHqoQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZz8R4sXrJcwHqoQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZqMR4sXrJcwHqoQ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT07PxzY382XsXX63LUYJSPUqb0pL6OQqxrZLnVbvZedvJtj-V7tIaZgcR4sXrJcwHqoQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bodonimoda/v25/aFT67PxzY382XsXX63LUYL6GYFcan6NJrKp-VPjfJMShrpsGFUt8oU7a8IF4sQ.ttf"
//   },
//   {
//     "family": "Sanchez",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sanchez/v15/Ycm2sZJORluHnXbITm5b_BwE1l0.ttf",
//       "italic": "https://fonts.gstatic.com/s/sanchez/v15/Ycm0sZJORluHnXbIfmxR-D4Bxl3gkw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sanchez/v15/Ycm2sZJORluHnXbIfm9R-A.ttf"
//   },
//   {
//     "family": "Saira",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-12-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rDosg7lwYmUVY.ttf",
//       "200": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rCosg7lwYmUVY.ttf",
//       "300": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA7wTCosg7lwYmUVY.ttf",
//       "500": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA72jCosg7lwYmUVY.ttf",
//       "600": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA74TFosg7lwYmUVY.ttf",
//       "700": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA773Fosg7lwYmUVY.ttf",
//       "800": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA79rFosg7lwYmUVY.ttf",
//       "900": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA7_PFosg7lwYmUVY.ttf",
//       "regular": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCosg7lwYmUVY.ttf",
//       "100italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBSooxkyQjQVYmxA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKByosxkyQjQVYmxA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBFIsxkyQjQVYmxA.ttf",
//       "italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBSosxkyQjQVYmxA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBeIsxkyQjQVYmxA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBlIwxkyQjQVYmxA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKBrYwxkyQjQVYmxA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKByowxkyQjQVYmxA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/saira/v20/memUYa2wxmKQyNkiV50dulWP7s95AqZTzZHcVdxWI9WH-pKB44wxkyQjQVYmxA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/saira/v20/memWYa2wxmKQyPMrZX79wwYZQMhsyuShhKMjjbU9uXuA71rCkskxkw.ttf"
//   },
//   {
//     "family": "Gloria Hallelujah",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gloriahallelujah/v21/LYjYdHv3kUk9BMV96EIswT9DIbW-MLSy3TKEvkCF.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gloriahallelujah/v21/LYjYdHv3kUk9BMV96EIswT9DIbW-MISz1zY.ttf"
//   },
//   {
//     "family": "League Spartan",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvM_oXpBMdcFguczA.ttf",
//       "200": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMfoTpBMdcFguczA.ttf",
//       "300": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMoITpBMdcFguczA.ttf",
//       "500": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMzITpBMdcFguczA.ttf",
//       "600": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMIIPpBMdcFguczA.ttf",
//       "700": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf",
//       "800": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMfoPpBMdcFguczA.ttf",
//       "900": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMV4PpBMdcFguczA.ttf",
//       "regular": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvM_oTpBMdcFguczA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvM_oTZBc1Y.ttf"
//   },
//   {
//     "family": "Readex Pro",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-04-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCYUSmgmsglvjkag.ttf",
//       "300": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCv0Smgmsglvjkag.ttf",
//       "500": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTC00Smgmsglvjkag.ttf",
//       "600": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCP0Omgmsglvjkag.ttf",
//       "700": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTCBkOmgmsglvjkag.ttf",
//       "regular": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTC4USmgmsglvjkag.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/readexpro/v21/SLXnc1bJ7HE5YDoGPuzj_dh8uc7wUy8ZQQyX2KY8TL0kGZN6blTC4USWg2Ek.ttf"
//   },
//   {
//     "family": "Macondo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/macondo/v25/RrQQboN9-iB1IXmOS2XO0LBBd4Y.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/macondo/v25/RrQQboN9-iB1IXmOe2TE1A.ttf"
//   },
//   {
//     "family": "Unbounded",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG65jx043HgP6LR0Y.ttf",
//       "300": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG60bx043HgP6LR0Y.ttf",
//       "500": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6yrx043HgP6LR0Y.ttf",
//       "600": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG68b2043HgP6LR0Y.ttf",
//       "700": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6__2043HgP6LR0Y.ttf",
//       "800": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG65j2043HgP6LR0Y.ttf",
//       "900": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG67H2043HgP6LR0Y.ttf",
//       "regular": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6xjx043HgP6LR0Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/unbounded/v7/Yq6F-LOTXCb04q32xlpat-6uR42XTqtG6xjx44zNhA.ttf"
//   },
//   {
//     "family": "Quattrocento Sans",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/quattrocentosans/v18/va9Z4lja2NVIDdIAAoMR5MfuElaRB0RykmrWN33AiasJ.ttf",
//       "regular": "https://fonts.gstatic.com/s/quattrocentosans/v18/va9c4lja2NVIDdIAAoMR5MfuElaRB3zOvU7eHGHJ.ttf",
//       "italic": "https://fonts.gstatic.com/s/quattrocentosans/v18/va9a4lja2NVIDdIAAoMR5MfuElaRB0zMt0r8GXHJkLI.ttf",
//       "700italic": "https://fonts.gstatic.com/s/quattrocentosans/v18/va9X4lja2NVIDdIAAoMR5MfuElaRB0zMj_bTPXnijLsJV7E.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/quattrocentosans/v18/va9c4lja2NVIDdIAAoMR5MfuElaRB0zPt0o.ttf"
//   },
//   {
//     "family": "Pathway Gothic One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pathwaygothicone/v15/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-dTFxeb80flQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pathwaygothicone/v15/MwQrbgD32-KAvjkYGNUUxAtW7pEBwx-tTVZa.ttf"
//   },
//   {
//     "family": "Gothic A1",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/gothica1/v13/CSR74z5ZnPydRjlCCwlCCMcqYtd2vfwk.ttf",
//       "200": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCpOYKSPl6tOU9Eg.ttf",
//       "300": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCwOUKSPl6tOU9Eg.ttf",
//       "500": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCmOQKSPl6tOU9Eg.ttf",
//       "600": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCtOMKSPl6tOU9Eg.ttf",
//       "700": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlC0OIKSPl6tOU9Eg.ttf",
//       "800": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlCzOEKSPl6tOU9Eg.ttf",
//       "900": "https://fonts.gstatic.com/s/gothica1/v13/CSR44z5ZnPydRjlCCwlC6OAKSPl6tOU9Eg.ttf",
//       "regular": "https://fonts.gstatic.com/s/gothica1/v13/CSR94z5ZnPydRjlCCwl6bM0uQNJmvQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gothica1/v13/CSR94z5ZnPydRjlCCwlKbccq.ttf"
//   },
//   {
//     "family": "DM Serif Text",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dmseriftext/v12/rnCu-xZa_krGokauCeNq1wWyafOPXHIJErY.ttf",
//       "italic": "https://fonts.gstatic.com/s/dmseriftext/v12/rnCw-xZa_krGokauCeNq1wWyWfGFWFAMArZKqQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dmseriftext/v12/rnCu-xZa_krGokauCeNq1wWyWfKFWA.ttf"
//   },
//   {
//     "family": "Albert Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5L_rI32TxAj1g.ttf",
//       "200": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHK5P_rI32TxAj1g.ttf",
//       "300": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSH9ZP_rI32TxAj1g.ttf",
//       "500": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHmZP_rI32TxAj1g.ttf",
//       "600": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHdZT_rI32TxAj1g.ttf",
//       "700": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHTJT_rI32TxAj1g.ttf",
//       "800": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHK5T_rI32TxAj1g.ttf",
//       "900": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHApT_rI32TxAj1g.ttf",
//       "regular": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5P_rI32TxAj1g.ttf",
//       "100italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9AX7ofybRUz1r5t.ttf",
//       "200italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9CX74fybRUz1r5t.ttf",
//       "300italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9BJ74fybRUz1r5t.ttf",
//       "italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9AX74fybRUz1r5t.ttf",
//       "500italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9Al74fybRUz1r5t.ttf",
//       "600italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9DJ6IfybRUz1r5t.ttf",
//       "700italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9Dw6IfybRUz1r5t.ttf",
//       "800italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9CX6IfybRUz1r5t.ttf",
//       "900italic": "https://fonts.gstatic.com/s/albertsans/v1/i7dfIFdwYjGaAMFtZd_QA1Zeelmy79QJ1HOSY9C-6IfybRUz1r5t.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/albertsans/v1/i7dZIFdwYjGaAMFtZd_QA3xXSKZqhr-TenSHq5PPrYfy.ttf"
//   },
//   {
//     "family": "Gruppo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gruppo/v21/WwkfxPmzE06v_ZWFWXDAOIEQUQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gruppo/v21/WwkfxPmzE06v_ZW1WHrE.ttf"
//   },
//   {
//     "family": "Quattrocento",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/quattrocento/v21/OZpbg_xvsDZQL_LKIF7q4jP_eE3fd6PZsXcM9w.ttf",
//       "regular": "https://fonts.gstatic.com/s/quattrocento/v21/OZpEg_xvsDZQL_LKIF7q4jPHxGL7f4jFuA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/quattrocento/v21/OZpEg_xvsDZQL_LKIF7q4jP3xWj_.ttf"
//   },
//   {
//     "family": "Ubuntu Mono",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/ubuntumono/v17/KFO-CneDtsqEr0keqCMhbC-BL-Hyv4xGemO1.ttf",
//       "regular": "https://fonts.gstatic.com/s/ubuntumono/v17/KFOjCneDtsqEr0keqCMhbBc9AMX6lJBP.ttf",
//       "italic": "https://fonts.gstatic.com/s/ubuntumono/v17/KFOhCneDtsqEr0keqCMhbCc_CsHYkYBPY3o.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ubuntumono/v17/KFO8CneDtsqEr0keqCMhbCc_Mn33tYhkf3O1GVg.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ubuntumono/v17/KFOjCneDtsqEr0keqCMhbCc8CsE.ttf"
//   },
//   {
//     "family": "Noto Sans Bengali",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolKudCk8izI0lc.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsglLudCk8izI0lc.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmstdLudCk8izI0lc.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsrtLudCk8izI0lc.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsldMudCk8izI0lc.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6Kmsm5MudCk8izI0lc.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsglMudCk8izI0lc.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsiBMudCk8izI0lc.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolLudCk8izI0lc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbengali/v20/Cn-SJsCGWQxOjaGwMQ6fIiMywrNJIky6nvd8BjzVMvJx2mcSPVFpVEqE-6KmsolLidGu9g.ttf"
//   },
//   {
//     "family": "Noto Sans Mono",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNI49rXVEQQL8Y.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_NNJ49rXVEQQL8Y.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_A1J49rXVEQQL8Y.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_GFJ49rXVEQQL8Y.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_I1O49rXVEQQL8Y.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_LRO49rXVEQQL8Y.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_NNO49rXVEQQL8Y.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_PpO49rXVEQQL8Y.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNJ49rXVEQQL8Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmono/v30/BngrUXNETWXI6LwhGYvaxZikqZqK6fBq6kPvUce2oAZcdthSBUsYck4-_FNJ09vdUA.ttf"
//   },
//   {
//     "family": "Josefin Slab",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71mtd3k3K6CcEyI.ttf",
//       "200": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W79msd3k3K6CcEyI.ttf",
//       "300": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W7wesd3k3K6CcEyI.ttf",
//       "500": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W72usd3k3K6CcEyI.ttf",
//       "600": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W74erd3k3K6CcEyI.ttf",
//       "700": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W776rd3k3K6CcEyI.ttf",
//       "regular": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msd3k3K6CcEyI.ttf",
//       "100italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvnzs9L4KZAyK43w.ttf",
//       "200italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvHzo9L4KZAyK43w.ttf",
//       "300italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvwTo9L4KZAyK43w.ttf",
//       "italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvnzo9L4KZAyK43w.ttf",
//       "500italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvrTo9L4KZAyK43w.ttf",
//       "600italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHvQT09L4KZAyK43w.ttf",
//       "700italic": "https://fonts.gstatic.com/s/josefinslab/v26/lW-qwjwOK3Ps5GSJlNNkMalnrxShJj4wo7AR-pHveD09L4KZAyK43w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/josefinslab/v26/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msR3g9Lw.ttf"
//   },
//   {
//     "family": "Architects Daughter",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvfY5q4szgE-Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/architectsdaughter/v18/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvTY9g5g.ttf"
//   },
//   {
//     "family": "IBM Plex Sans Condensed",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY7KyKvBgYsMDhM.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5m6Yvrr4cFFwq5.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4C6ovrr4cFFwq5.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY5a64vrr4cFFwq5.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY527Ivrr4cFFwq5.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8gN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHY4S7Yvrr4cFFwq5.ttf",
//       "100italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8hN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8M_LhakJHhOgBg.ttf",
//       "200italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8GPqpYMnEhq5H1w.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8AfppYMnEhq5H1w.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHbauwq_jhJsM.ttf",
//       "italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8nN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYasyKvBgYsMDhM.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8F_opYMnEhq5H1w.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8HPvpYMnEhq5H1w.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8iN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYas8BfupYMnEhq5H1w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsanscondensed/v14/Gg8lN4UfRSqiPg7Jn2ZI12V4DCEwkj1E4LVeHYavyKs.ttf"
//   },
//   {
//     "family": "Inter Tight",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw6qXCRToK8EPg.ttf",
//       "200": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjjw-qXCRToK8EPg.ttf",
//       "300": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjUQ-qXCRToK8EPg.ttf",
//       "500": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjPQ-qXCRToK8EPg.ttf",
//       "600": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqXCRToK8EPg.ttf",
//       "700": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj6AiqXCRToK8EPg.ttf",
//       "800": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjjwiqXCRToK8EPg.ttf",
//       "900": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjpgiqXCRToK8EPg.ttf",
//       "regular": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-qXCRToK8EPg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xCHi5XgqoUPvi5.ttf",
//       "200italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0zCHy5XgqoUPvi5.ttf",
//       "300italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0wcHy5XgqoUPvi5.ttf",
//       "italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xCHy5XgqoUPvi5.ttf",
//       "500italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0xwHy5XgqoUPvi5.ttf",
//       "600italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0ycGC5XgqoUPvi5.ttf",
//       "700italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0ylGC5XgqoUPvi5.ttf",
//       "800italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0zCGC5XgqoUPvi5.ttf",
//       "900italic": "https://fonts.gstatic.com/s/intertight/v7/NGShv5HMAFg6IuGlBNMjxLsC66ZMtb8hyW62x0zrGC5XgqoUPvi5.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mjDw-aXS5X.ttf"
//   },
//   {
//     "family": "Sawarabi Gothic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sawarabigothic/v12/x3d4ckfVaqqa-BEj-I9mE65u3k3NBSk3E2YljQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sawarabigothic/v12/x3d4ckfVaqqa-BEj-I9mE65u3k39BCMz.ttf"
//   },
//   {
//     "family": "Libre Barcode 39",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librebarcode39/v21/-nFnOHM08vwC6h8Li1eQnP_AHzI2K_d709jy92k.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebarcode39/v21/-nFnOHM08vwC6h8Li1eQnP_AHzI2G_Zx1w.ttf"
//   },
//   {
//     "family": "Alegreya Sans SC",
//     "variants": [
//       "100",
//       "100italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2022-12-08",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Dipl8g5FPYtmMg.ttf",
//       "300": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DuJH0iRrMYJ_K-4.ttf",
//       "500": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DrpG0iRrMYJ_K-4.ttf",
//       "700": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DvJA0iRrMYJ_K-4.ttf",
//       "800": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1Du5D0iRrMYJ_K-4.ttf",
//       "900": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGm4-RGJqfMvt7P8FUr0Q1j-Hf1DspC0iRrMYJ_K-4.ttf",
//       "100italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGl4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdlgRBH452Mvds.ttf",
//       "300italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdXiZhNaB6O-51OA.ttf",
//       "regular": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Nk5v9ixALYs.ttf",
//       "italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1Bkxl8g5FPYtmMg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdBidhNaB6O-51OA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdTiFhNaB6O-51OA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxdUiJhNaB6O-51OA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGk4-RGJqfMvt7P8FUr0Q1j-Hf1BkxddiNhNaB6O-51OA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alegreyasanssc/v23/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bk9l8g.ttf"
//   },
//   {
//     "family": "Commissioner",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTMNcGPe7Fu0jUdk.ttf",
//       "200": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTENdGPe7Fu0jUdk.ttf",
//       "300": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTJ1dGPe7Fu0jUdk.ttf",
//       "500": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTPFdGPe7Fu0jUdk.ttf",
//       "600": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTB1aGPe7Fu0jUdk.ttf",
//       "700": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTCRaGPe7Fu0jUdk.ttf",
//       "800": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTENaGPe7Fu0jUdk.ttf",
//       "900": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTGpaGPe7Fu0jUdk.ttf",
//       "regular": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTMNdGPe7Fu0jUdk.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/commissioner/v20/tDaH2o2WnlgI0FNDgduEk4jAhwgumbU1SVfU5BD8OuRL8OstC6KOhgvBYWSFJ-Mgdrgiju6fF8meZm0rk4eF-ZugTMNdKPaxEg.ttf"
//   },
//   {
//     "family": "Sen",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-07-24",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/sen/v9/6xK0dSxYI9_dkN18-vZKK2EISBi5H47KlD9q78A.ttf",
//       "600": "https://fonts.gstatic.com/s/sen/v9/6xK0dSxYI9_dkN18-vZKK2EISPS-H47KlD9q78A.ttf",
//       "700": "https://fonts.gstatic.com/s/sen/v9/6xK0dSxYI9_dkN18-vZKK2EISM2-H47KlD9q78A.ttf",
//       "800": "https://fonts.gstatic.com/s/sen/v9/6xK0dSxYI9_dkN18-vZKK2EISKq-H47KlD9q78A.ttf",
//       "regular": "https://fonts.gstatic.com/s/sen/v9/6xK0dSxYI9_dkN18-vZKK2EISCq5H47KlD9q78A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sen/v9/6xK0dSxYI9_dkN18-vZKK2EISCq5L4_AkA.ttf"
//   },
//   {
//     "family": "Roboto Serif",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-04-04",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliosp6d2Af5fR4k.ttf",
//       "200": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElqotp6d2Af5fR4k.ttf",
//       "300": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElnQtp6d2Af5fR4k.ttf",
//       "500": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElhgtp6d2Af5fR4k.ttf",
//       "600": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElvQqp6d2Af5fR4k.ttf",
//       "700": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEls0qp6d2Af5fR4k.ttf",
//       "800": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcElqoqp6d2Af5fR4k.ttf",
//       "900": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6d2Af5fR4k.ttf",
//       "regular": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliotp6d2Af5fR4k.ttf",
//       "100italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuT-V8BdxaV4nUFw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-Juz-R8BdxaV4nUFw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuEeR8BdxaV4nUFw.ttf",
//       "italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuT-R8BdxaV4nUFw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JufeR8BdxaV4nUFw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JukeN8BdxaV4nUFw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-JuqON8BdxaV4nUFw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-Juz-N8BdxaV4nUFw.ttf",
//       "900italic": "https://fonts.gstatic.com/s/robotoserif/v13/R71XjywflP6FLr3gZx7K8UyEVQnyR1E7VN-f51xYuGCQepOvB0KLc2v0wKKB0Q4MSZxyqf2CgAchbDJ69BcVZxkDg-Ju5uN8BdxaV4nUFw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEliotl6Z8BQ.ttf"
//   },
//   {
//     "family": "Zen Kaku Gothic New",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/zenkakugothicnew/v15/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqpdKaWTSTGlMyd8.ttf",
//       "500": "https://fonts.gstatic.com/s/zenkakugothicnew/v15/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqs9LaWTSTGlMyd8.ttf",
//       "700": "https://fonts.gstatic.com/s/zenkakugothicnew/v15/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqodNaWTSTGlMyd8.ttf",
//       "900": "https://fonts.gstatic.com/s/zenkakugothicnew/v15/gNMVW2drQpDw0GjzrVNFf_valaDBcznOqr9PaWTSTGlMyd8.ttf",
//       "regular": "https://fonts.gstatic.com/s/zenkakugothicnew/v15/gNMYW2drQpDw0GjzrVNFf_valaDBcznOkjtiTWz5UGA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenkakugothicnew/v15/gNMYW2drQpDw0GjzrVNFf_valaDBcznOojpoSQ.ttf"
//   },
//   {
//     "family": "Rokkitt",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v36",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rydpDLE76HvN6n.ttf",
//       "200": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pyd5DLE76HvN6n.ttf",
//       "300": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1qsd5DLE76HvN6n.ttf",
//       "500": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1rAd5DLE76HvN6n.ttf",
//       "600": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oscJDLE76HvN6n.ttf",
//       "700": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1oVcJDLE76HvN6n.ttf",
//       "800": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pycJDLE76HvN6n.ttf",
//       "900": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1pbcJDLE76HvN6n.ttf",
//       "regular": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd5DLE76HvN6n.ttf",
//       "100italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NHiJGbqluc6nu9E.ttf",
//       "200italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NPiIGbqluc6nu9E.ttf",
//       "300italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NCaIGbqluc6nu9E.ttf",
//       "italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NHiIGbqluc6nu9E.ttf",
//       "500italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NEqIGbqluc6nu9E.ttf",
//       "600italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NKaPGbqluc6nu9E.ttf",
//       "700italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NJ-PGbqluc6nu9E.ttf",
//       "800italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NPiPGbqluc6nu9E.ttf",
//       "900italic": "https://fonts.gstatic.com/s/rokkitt/v36/qFdV35qfgYFjGy5hkEOYeNY-EoKzjE86NNGPGbqluc6nu9E.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rokkitt/v36/qFdb35qfgYFjGy5hukqqhw5XeRgdi1ryd6DKGbo.ttf"
//   },
//   {
//     "family": "Antic Slab",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/anticslab/v16/bWt97fPFfRzkCa9Jlp6IWcJWXW5p5Qo.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anticslab/v16/bWt97fPFfRzkCa9Jlp6IacNcWQ.ttf"
//   },
//   {
//     "family": "Creepster",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/creepster/v13/AlZy_zVUqJz4yMrniH4hdXf4XB0Tow.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/creepster/v13/AlZy_zVUqJz4yMrniH4RdH38.ttf"
//   },
//   {
//     "family": "Encode Sans Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_76_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-5a-JLQoFI2KR.ttf",
//       "200": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-SY6pByQJKnuIFA.ttf",
//       "300": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-LY2pByQJKnuIFA.ttf",
//       "500": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-dYypByQJKnuIFA.ttf",
//       "600": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-WYupByQJKnuIFA.ttf",
//       "700": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-PYqpByQJKnuIFA.ttf",
//       "800": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-IYmpByQJKnuIFA.ttf",
//       "900": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_46_LD37rqfuwxyIuaZhE6cRXOLtm2gfT-BYipByQJKnuIFA.ttf",
//       "regular": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfTGgaWNDw8VIw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/encodesanscondensed/v10/j8_16_LD37rqfuwxyIuaZhE6cRXOLtm2gfT2gK-J.ttf"
//   },
//   {
//     "family": "Bungee",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bungee/v13/N0bU2SZBIuF2PU_ECn50Kd_PmA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bungee/v13/N0bU2SZBIuF2PU_0C3Rw.ttf"
//   },
//   {
//     "family": "Cookie",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cookie/v21/syky-y18lb0tSbfNlQCT9tPdpw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cookie/v21/syky-y18lb0tSbf9lAqX.ttf"
//   },
//   {
//     "family": "El Messiri",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/elmessiri/v22/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXCe65ghj3OoapG.ttf",
//       "600": "https://fonts.gstatic.com/s/elmessiri/v22/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuUufK5ghj3OoapG.ttf",
//       "700": "https://fonts.gstatic.com/s/elmessiri/v22/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuUXfK5ghj3OoapG.ttf",
//       "regular": "https://fonts.gstatic.com/s/elmessiri/v22/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXwe65ghj3OoapG.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/elmessiri/v22/K2FhfZBRmr9vQ1pHEey6GIGo8_pv3myYjuXwe55hjDk.ttf"
//   },
//   {
//     "family": "Literata",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v35",
//     "lastModified": "2023-06-07",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbJG_F_bcTWCWp8g.ttf",
//       "300": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbE-_F_bcTWCWp8g.ttf",
//       "500": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbCO_F_bcTWCWp8g.ttf",
//       "600": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbM-4F_bcTWCWp8g.ttf",
//       "700": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbPa4F_bcTWCWp8g.ttf",
//       "800": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbJG4F_bcTWCWp8g.ttf",
//       "900": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbLi4F_bcTWCWp8g.ttf",
//       "regular": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_F_bcTWCWp8g.ttf",
//       "200italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8f7XWSUKTt8iVow.ttf",
//       "300italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8obXWSUKTt8iVow.ttf",
//       "italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8_7XWSUKTt8iVow.ttf",
//       "500italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8zbXWSUKTt8iVow.ttf",
//       "600italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8IbLWSUKTt8iVow.ttf",
//       "700italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8GLLWSUKTt8iVow.ttf",
//       "800italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8f7LWSUKTt8iVow.ttf",
//       "900italic": "https://fonts.gstatic.com/s/literata/v35/or3NQ6P12-iJxAIgLYT1PLs1Zd0nfUwAbeGVKoRYzNiCp1OUedn8VrLWSUKTt8iVow.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/literata/v35/or3PQ6P12-iJxAIgLa78DkrbXsDgk0oVDaDPYLanFLHpPf2TbBG_J_fWSQ.ttf"
//   },
//   {
//     "family": "Yeseva One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yesevaone/v22/OpNJno4ck8vc-xYpwWWxpipfWhXD00c.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yesevaone/v22/OpNJno4ck8vc-xYpwWWxlitVXg.ttf"
//   },
//   {
//     "family": "Gelasio",
//     "variants": [
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/gelasio/v10/cIf4MaFfvUQxTTqS_N2CRGEsnIJkWL4.ttf",
//       "600": "https://fonts.gstatic.com/s/gelasio/v10/cIf4MaFfvUQxTTqS_PGFRGEsnIJkWL4.ttf",
//       "700": "https://fonts.gstatic.com/s/gelasio/v10/cIf4MaFfvUQxTTqS_JWERGEsnIJkWL4.ttf",
//       "regular": "https://fonts.gstatic.com/s/gelasio/v10/cIf9MaFfvUQxTTqSxCmrYGkHgIs.ttf",
//       "italic": "https://fonts.gstatic.com/s/gelasio/v10/cIf_MaFfvUQxTTqS9CuhZEsCkIt9QQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/gelasio/v10/cIf6MaFfvUQxTTqS9CuZkGImmKBhSL7Y1Q.ttf",
//       "600italic": "https://fonts.gstatic.com/s/gelasio/v10/cIf6MaFfvUQxTTqS9CuZvGUmmKBhSL7Y1Q.ttf",
//       "700italic": "https://fonts.gstatic.com/s/gelasio/v10/cIf6MaFfvUQxTTqS9CuZ2GQmmKBhSL7Y1Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gelasio/v10/cIf9MaFfvUQxTTqS9CihZA.ttf"
//   },
//   {
//     "family": "Poiret One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/poiretone/v16/UqyVK80NJXN4zfRgbdfbk5lWVscxdKE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/poiretone/v16/UqyVK80NJXN4zfRgbdfbo5hcUg.ttf"
//   },
//   {
//     "family": "Cuprum",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2023-05-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/cuprum/v25/dg45_pLmvrkcOkBnKsOzXyGWTBcmg9f6ZjzSJjQjgnU.ttf",
//       "600": "https://fonts.gstatic.com/s/cuprum/v25/dg45_pLmvrkcOkBnKsOzXyGWTBcmgzv9ZjzSJjQjgnU.ttf",
//       "700": "https://fonts.gstatic.com/s/cuprum/v25/dg45_pLmvrkcOkBnKsOzXyGWTBcmgwL9ZjzSJjQjgnU.ttf",
//       "regular": "https://fonts.gstatic.com/s/cuprum/v25/dg45_pLmvrkcOkBnKsOzXyGWTBcmg-X6ZjzSJjQjgnU.ttf",
//       "italic": "https://fonts.gstatic.com/s/cuprum/v25/dg47_pLmvrkcOkBNI_FMh0j91rkhli25jn_YIhYmknUPEA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/cuprum/v25/dg47_pLmvrkcOkBNI_FMh0j91rkhli25vH_YIhYmknUPEA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/cuprum/v25/dg47_pLmvrkcOkBNI_FMh0j91rkhli25UHjYIhYmknUPEA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cuprum/v25/dg47_pLmvrkcOkBNI_FMh0j91rkhli25aXjYIhYmknUPEA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cuprum/v25/dg45_pLmvrkcOkBnKsOzXyGWTBcmg-X6Vj3YIg.ttf"
//   },
//   {
//     "family": "DM Mono",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/dmmono/v14/aFTR7PB1QTsUX8KYvrGyIYSnbKX9Rlk.ttf",
//       "500": "https://fonts.gstatic.com/s/dmmono/v14/aFTR7PB1QTsUX8KYvumzIYSnbKX9Rlk.ttf",
//       "300italic": "https://fonts.gstatic.com/s/dmmono/v14/aFTT7PB1QTsUX8KYth-orYataIf4VllXuA.ttf",
//       "regular": "https://fonts.gstatic.com/s/dmmono/v14/aFTU7PB1QTsUX8KYhh2aBYyMcKw.ttf",
//       "italic": "https://fonts.gstatic.com/s/dmmono/v14/aFTW7PB1QTsUX8KYth-QAa6JYKzkXw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/dmmono/v14/aFTT7PB1QTsUX8KYth-o9YetaIf4VllXuA.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dmmono/v14/aFTU7PB1QTsUX8KYthyQAQ.ttf"
//   },
//   {
//     "family": "Unna",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/unna/v23/AYCLpXzofN0NMiQusGnpRFpr3vc.ttf",
//       "regular": "https://fonts.gstatic.com/s/unna/v23/AYCEpXzofN0NCpgBlGHCWFM.ttf",
//       "italic": "https://fonts.gstatic.com/s/unna/v23/AYCKpXzofN0NOpoLkEPHSFNyxw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/unna/v23/AYCJpXzofN0NOpozLGzjQHhuzvef5Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/unna/v23/AYCEpXzofN0NOpkLkA.ttf"
//   },
//   {
//     "family": "Allura",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/allura/v21/9oRPNYsQpS4zjuAPjAIXPtrrGA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/allura/v21/9oRPNYsQpS4zjuA_jQgT.ttf"
//   },
//   {
//     "family": "Advent Pro",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2024-02-16",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyJPTJoonw1aBA.ttf",
//       "200": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLwyNPTJoonw1aBA.ttf",
//       "300": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLHSNPTJoonw1aBA.ttf",
//       "500": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLcSNPTJoonw1aBA.ttf",
//       "600": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLnSRPTJoonw1aBA.ttf",
//       "700": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLpCRPTJoonw1aBA.ttf",
//       "800": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLwyRPTJoonw1aBA.ttf",
//       "900": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpL6iRPTJoonw1aBA.ttf",
//       "regular": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyNPTJoonw1aBA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CnDpAsvQhKBH4C.ttf",
//       "200italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AnD5AsvQhKBH4C.ttf",
//       "300italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2D5D5AsvQhKBH4C.ttf",
//       "italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CnD5AsvQhKBH4C.ttf",
//       "500italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2CVD5AsvQhKBH4C.ttf",
//       "600italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2B5CJAsvQhKBH4C.ttf",
//       "700italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2BACJAsvQhKBH4C.ttf",
//       "800italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AnCJAsvQhKBH4C.ttf",
//       "900italic": "https://fonts.gstatic.com/s/adventpro/v28/V8mkoQfxVT4Dvddr_yOwhT-3Jr6w5kKOEbAVEvZiKGAr6BX29i1ei2AOCJAsvQhKBH4C.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/adventpro/v28/V8mqoQfxVT4Dvddr_yOwrzaFxV7JtdQgFqXdUAQrGp_zgX5sWCpLQyN_TZAs.ttf"
//   },
//   {
//     "family": "Special Elite",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/specialelite/v18/XLYgIZbkc4JPUL5CVArUVL0nhncESXFtUsM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/specialelite/v18/XLYgIZbkc4JPUL5CVArUVL0ntnYOTQ.ttf"
//   },
//   {
//     "family": "Bangers",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bangers/v24/FeVQS0BTqb0h60ACL5la2bxii28.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bangers/v24/FeVQS0BTqb0h60ACH5hQ3Q.ttf"
//   },
//   {
//     "family": "Patrick Hand",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/patrickhand/v23/LDI1apSQOAYtSuYWp8ZhfYeMWcjKm7sp8g.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/patrickhand/v23/LDI1apSQOAYtSuYWp8ZhfYe8WMLO.ttf"
//   },
//   {
//     "family": "Fira Sans Extra Condensed",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3Zyuv1WarE9ncg.ttf",
//       "200": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3TCPn3-0oEZ-a2Q.ttf",
//       "300": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3VSMn3-0oEZ-a2Q.ttf",
//       "500": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3QyNn3-0oEZ-a2Q.ttf",
//       "600": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3SCKn3-0oEZ-a2Q.ttf",
//       "700": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3USLn3-0oEZ-a2Q.ttf",
//       "800": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3ViIn3-0oEZ-a2Q.ttf",
//       "900": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPPcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda3XyJn3-0oEZ-a2Q.ttf",
//       "100italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPOcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqW21-ejkp3cn22.ttf",
//       "200italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWd36-pGR7e2SvJQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWE32-pGR7e2SvJQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda5fiku3efvE8.ttf",
//       "italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPMcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fquv1WarE9ncg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWS3y-pGR7e2SvJQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWZ3u-pGR7e2SvJQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWA3q-pGR7e2SvJQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWH3m-pGR7e2SvJQ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPxcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fqWO3i-pGR7e2SvJQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/firasansextracondensed/v10/NaPKcYDaAO5dirw6IaFn7lPJFqXmS-M9Atn3wgda1fmuvw.ttf"
//   },
//   {
//     "family": "Noto Naskh Arabic",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v33",
//     "lastModified": "2023-05-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwj85krK0z9_Mnuw.ttf",
//       "600": "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwY8lkrK0z9_Mnuw.ttf",
//       "700": "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwWslkrK0z9_Mnuw.ttf",
//       "regular": "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwvc5krK0z9_Mnuw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notonaskharabic/v33/RrQ5bpV-9Dd1b1OAGA6M9PkyDuVBePeKNaxcsss0Y7bwvc5Urac3.ttf"
//   },
//   {
//     "family": "Tenor Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tenorsans/v19/bx6ANxqUneKx06UkIXISr3JyC22IyqI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tenorsans/v19/bx6ANxqUneKx06UkIXISn3N4Dw.ttf"
//   },
//   {
//     "family": "Playfair Display SC",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2024-02-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nQIpNcsdL4IUMyE.ttf",
//       "900": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke80OhoaMkR6-hSn7kbHVoFf7ZfgMPr_nTorNcsdL4IUMyE.ttf",
//       "regular": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_pb4GEcM2M4s.ttf",
//       "italic": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke87OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbwMFeEzI4sNKg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0qc4XK6ARIyH5IA.ttf",
//       "900italic": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke82OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lbw0kcwXK6ARIyH5IA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/playfairdisplaysc/v17/ke85OhoaMkR6-hSn7kbHVoFf7ZfgMPr_lb8MFQ.ttf"
//   },
//   {
//     "family": "News Cycle",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/newscycle/v23/CSR54z1Qlv-GDxkbKVQ_dFsvaNNUuOwkC2s.ttf",
//       "regular": "https://fonts.gstatic.com/s/newscycle/v23/CSR64z1Qlv-GDxkbKVQ_TOcATNt_pOU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/newscycle/v23/CSR64z1Qlv-GDxkbKVQ_fOYKSA.ttf"
//   },
//   {
//     "family": "Fugaz One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fugazone/v19/rax_HiWKp9EAITukFslMBBJek0vA8A.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fugazone/v19/rax_HiWKp9EAITukFsl8BRha.ttf"
//   },
//   {
//     "family": "Courier Prime",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/courierprime/v9/u-4k0q2lgwslOqpF_6gQ8kELY7pMf-fVqvHoJXw.ttf",
//       "regular": "https://fonts.gstatic.com/s/courierprime/v9/u-450q2lgwslOqpF_6gQ8kELWwZjW-_-tvg.ttf",
//       "italic": "https://fonts.gstatic.com/s/courierprime/v9/u-4n0q2lgwslOqpF_6gQ8kELawRpX837pvjxPA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/courierprime/v9/u-4i0q2lgwslOqpF_6gQ8kELawRR4-LfrtPtNXyeAg.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/courierprime/v9/u-450q2lgwslOqpF_6gQ8kELawdpXw.ttf"
//   },
//   {
//     "family": "Staatliches",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/staatliches/v13/HI_OiY8KO6hCsQSoAPmtMbectJG9O9PS.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/staatliches/v13/HI_OiY8KO6hCsQSoAPmtMYedvpU.ttf"
//   },
//   {
//     "family": "Comic Neue",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v8",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/comicneue/v8/4UaErEJDsxBrF37olUeD_wHLwpteLwtHJlc.ttf",
//       "700": "https://fonts.gstatic.com/s/comicneue/v8/4UaErEJDsxBrF37olUeD_xHMwpteLwtHJlc.ttf",
//       "300italic": "https://fonts.gstatic.com/s/comicneue/v8/4UaarEJDsxBrF37olUeD96_RTplUKylCNlcw_Q.ttf",
//       "regular": "https://fonts.gstatic.com/s/comicneue/v8/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf",
//       "italic": "https://fonts.gstatic.com/s/comicneue/v8/4UaFrEJDsxBrF37olUeD96_p4rFwIwJePw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/comicneue/v8/4UaarEJDsxBrF37olUeD96_RXp5UKylCNlcw_Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/comicneue/v8/4UaHrEJDsxBrF37olUeD96zp4g.ttf"
//   },
//   {
//     "family": "Taviraj",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/taviraj/v13/ahcbv8Cj3ylylTXzRIorV8N1jU2gog.ttf",
//       "200": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRCYKd-lbgUS5u0s.ttf",
//       "300": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzREIJd-lbgUS5u0s.ttf",
//       "500": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRBoId-lbgUS5u0s.ttf",
//       "600": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRDYPd-lbgUS5u0s.ttf",
//       "700": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRFIOd-lbgUS5u0s.ttf",
//       "800": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRE4Nd-lbgUS5u0s.ttf",
//       "900": "https://fonts.gstatic.com/s/taviraj/v13/ahccv8Cj3ylylTXzRGoMd-lbgUS5u0s.ttf",
//       "100italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcdv8Cj3ylylTXzTOwTM8lxr0iwolLl.ttf",
//       "200italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwTn-hRhWa8q0v8ag.ttf",
//       "300italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT--tRhWa8q0v8ag.ttf",
//       "regular": "https://fonts.gstatic.com/s/taviraj/v13/ahcZv8Cj3ylylTXzfO4hU-FwnU0.ttf",
//       "italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcbv8Cj3ylylTXzTOwrV8N1jU2gog.ttf",
//       "500italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwTo-pRhWa8q0v8ag.ttf",
//       "600italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwTj-1RhWa8q0v8ag.ttf",
//       "700italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT6-xRhWa8q0v8ag.ttf",
//       "800italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT9-9RhWa8q0v8ag.ttf",
//       "900italic": "https://fonts.gstatic.com/s/taviraj/v13/ahcev8Cj3ylylTXzTOwT0-5RhWa8q0v8ag.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/taviraj/v13/ahcZv8Cj3ylylTXzTO8rVw.ttf"
//   },
//   {
//     "family": "Aleo",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KJ3G2P9HI4qCBtJ.ttf",
//       "200": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KL3GmP9HI4qCBtJ.ttf",
//       "300": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KIpGmP9HI4qCBtJ.ttf",
//       "500": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KJFGmP9HI4qCBtJ.ttf",
//       "600": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KKpHWP9HI4qCBtJ.ttf",
//       "700": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KKQHWP9HI4qCBtJ.ttf",
//       "800": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KL3HWP9HI4qCBtJ.ttf",
//       "900": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KLeHWP9HI4qCBtJ.ttf",
//       "regular": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KJ3GmP9HI4qCBtJ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WYu_FooIDQtJbok.ttf",
//       "200italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WQu-FooIDQtJbok.ttf",
//       "300italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WdW-FooIDQtJbok.ttf",
//       "italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WYu-FooIDQtJbok.ttf",
//       "500italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_Wbm-FooIDQtJbok.ttf",
//       "600italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WVW5FooIDQtJbok.ttf",
//       "700italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WWy5FooIDQtJbok.ttf",
//       "800italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WQu5FooIDQtJbok.ttf",
//       "900italic": "https://fonts.gstatic.com/s/aleo/v14/c4m81nF8G8_swAjT3z2dShrG-7e_WSK5FooIDQtJbok.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aleo/v14/c4m61nF8G8_s6gHhIOX0IYBo_KJ3GlP8Foo.ttf"
//   },
//   {
//     "family": "Mitr",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8fMZFJDUc1NECPY.ttf",
//       "300": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8ZcaFJDUc1NECPY.ttf",
//       "500": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8c8bFJDUc1NECPY.ttf",
//       "600": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8eMcFJDUc1NECPY.ttf",
//       "700": "https://fonts.gstatic.com/s/mitr/v11/pxiEypw5ucZF8YcdFJDUc1NECPY.ttf",
//       "regular": "https://fonts.gstatic.com/s/mitr/v11/pxiLypw5ucZFyTsyMJj_b1o.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mitr/v11/pxiLypw5ucZF-To4NA.ttf"
//   },
//   {
//     "family": "Abhaya Libre",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sinhala"
//     ],
//     "version": "v17",
//     "lastModified": "2024-02-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEYj2ryqtxI6oYtBA.ttf",
//       "600": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEYo23yqtxI6oYtBA.ttf",
//       "700": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEYx2zyqtxI6oYtBA.ttf",
//       "800": "https://fonts.gstatic.com/s/abhayalibre/v17/e3t5euGtX-Co5MNzeAOqinEY22_yqtxI6oYtBA.ttf",
//       "regular": "https://fonts.gstatic.com/s/abhayalibre/v17/e3tmeuGtX-Co5MNzeAOqinEge0PWovdU4w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/abhayalibre/v17/e3tmeuGtX-Co5MNzeAOqinEQeknS.ttf"
//   },
//   {
//     "family": "Kosugi Maru",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kosugimaru/v14/0nksC9PgP_wGh21A2KeqGiTqivr9iBq_.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kosugimaru/v14/0nksC9PgP_wGh21A2KeqGhTrgP4.ttf"
//   },
//   {
//     "family": "Tangerine",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/tangerine/v17/Iurd6Y5j_oScZZow4VO5srNpjJtM6G0t9w.ttf",
//       "regular": "https://fonts.gstatic.com/s/tangerine/v17/IurY6Y5j_oScZZow4VOBDpxNhLBQ4Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tangerine/v17/IurY6Y5j_oScZZow4VOxD5ZJ.ttf"
//   },
//   {
//     "family": "PT Mono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ptmono/v13/9oRONYoBnWILk-9ArCg5MtPyAcg.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ptmono/v13/9oRONYoBnWILk-9AnCkzNg.ttf"
//   },
//   {
//     "family": "Homemade Apple",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/homemadeapple/v22/Qw3EZQFXECDrI2q789EKQZJob3x9Vnksi4M7.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/homemadeapple/v22/Qw3EZQFXECDrI2q789EKQZJob0x8XH0.ttf"
//   },
//   {
//     "family": "Kumbh Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v22",
//     "lastModified": "2024-01-26",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQkZcA8bTuUkqaLg.ttf",
//       "200": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQEZYA8bTuUkqaLg.ttf",
//       "300": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQz5YA8bTuUkqaLg.ttf",
//       "500": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQo5YA8bTuUkqaLg.ttf",
//       "600": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQT5EA8bTuUkqaLg.ttf",
//       "700": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQdpEA8bTuUkqaLg.ttf",
//       "800": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQEZEA8bTuUkqaLg.ttf",
//       "900": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQOJEA8bTuUkqaLg.ttf",
//       "regular": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQkZYA8bTuUkqaLg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kumbhsans/v22/c4mP1n92AsfhuCq6tVsaoIx1LQICk0boNoq0SjlDfnzKo-bF3mdQkZYw8L7q.ttf"
//   },
//   {
//     "family": "Handlee",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/handlee/v18/-F6xfjBsISg9aMakDmr6oilJ3ik.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/handlee/v18/-F6xfjBsISg9aMakPmvwpg.ttf"
//   },
//   {
//     "family": "Yatra One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yatraone/v14/C8ch4copsHzj8p7NaF0xw1OBbRDvXw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yatraone/v14/C8ch4copsHzj8p7NaF0BwlmF.ttf"
//   },
//   {
//     "family": "Parisienne",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/parisienne/v13/E21i_d3kivvAkxhLEVZpcy96DuKuavM.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/parisienne/v13/E21i_d3kivvAkxhLEVZpQy5wCg.ttf"
//   },
//   {
//     "family": "Sriracha",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sriracha/v14/0nkrC9D4IuYBgWcI9ObYRQDioeb0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sriracha/v14/0nkrC9D4IuYBgWcI9NbZTwQ.ttf"
//   },
//   {
//     "family": "Adamina",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-04-20",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/adamina/v21/j8_r6-DH1bjoc-dwu-reETl4Bno.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/adamina/v21/j8_r6-DH1bjoc-dwi-vUFQ.ttf"
//   },
//   {
//     "family": "Noto Sans Devanagari",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlXQky-AzoFoW4Ow.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlfQly-AzoFoW4Ow.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlSoly-AzoFoW4Ow.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlUYly-AzoFoW4Ow.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08Alaoiy-AzoFoW4Ow.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlZMiy-AzoFoW4Ow.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlfQiy-AzoFoW4Ow.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08Ald0iy-AzoFoW4Ow.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlXQly-AzoFoW4Ow.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansdevanagari/v25/TuGoUUFzXI5FBtUq5a8bjKYTZjtRU6Sgv3NaV_SNmI0b8QQCQmHn6B2OHjbL_08AlXQl--E5pA.ttf"
//   },
//   {
//     "family": "Cousine",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/cousine/v27/d6lNkaiiRdih4SpP9Z8K6T7G09BlnmQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/cousine/v27/d6lIkaiiRdih4SpPzSMlzTbtz9k.ttf",
//       "italic": "https://fonts.gstatic.com/s/cousine/v27/d6lKkaiiRdih4SpP_SEvyRTo39l8hw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cousine/v27/d6lPkaiiRdih4SpP_SEXdTvM1_JgjmRpOA.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cousine/v27/d6lIkaiiRdih4SpP_SIvyQ.ttf"
//   },
//   {
//     "family": "STIX Two Text",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5YihS2SOYWxFMN1WD.ttf",
//       "600": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yii-3iOYWxFMN1WD.ttf",
//       "700": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5YiiH3iOYWxFMN1WD.ttf",
//       "regular": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yihg2SOYWxFMN1WD.ttf",
//       "italic": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omsvbURVuMkWDmSo.ttf",
//       "500italic": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omvnbURVuMkWDmSo.ttf",
//       "600italic": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omhXcURVuMkWDmSo.ttf",
//       "700italic": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Er02F12Xkf5whdwKf11l0p7uWhf8lJUzXZT2omizcURVuMkWDmSo.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stixtwotext/v12/YA9Gr02F12Xkf5whdwKf11l0jbKkeidMTtZ5Yihg2ROZURU.ttf"
//   },
//   {
//     "family": "JetBrains Mono",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yK1jPVmUsaaDhw.ttf",
//       "200": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8SKxjPVmUsaaDhw.ttf",
//       "300": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8lqxjPVmUsaaDhw.ttf",
//       "500": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8-qxjPVmUsaaDhw.ttf",
//       "600": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8FqtjPVmUsaaDhw.ttf",
//       "700": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8L6tjPVmUsaaDhw.ttf",
//       "800": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8SKtjPVmUsaaDhw.ttf",
//       "regular": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxjPVmUsaaDhw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-Lf1OQk6OThxPA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO8LflOQk6OThxPA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO_VflOQk6OThxPA.ttf",
//       "italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-LflOQk6OThxPA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO-5flOQk6OThxPA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO9VeVOQk6OThxPA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO9seVOQk6OThxPA.ttf",
//       "800italic": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDba2o-flEEny0FZhsfKu5WU4xD-IQ-PuZJJXxfpAO8LeVOQk6OThxPA.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jetbrainsmono/v18/tDbY2o-flEEny0FZhsfKu5WU4zr3E_BX0PnT8RD8yKxTPFOQ.ttf"
//   },
//   {
//     "family": "Amaranth",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/amaranth/v18/KtkpALODe433f0j1zMF-OPWi6WDfFpuc.ttf",
//       "regular": "https://fonts.gstatic.com/s/amaranth/v18/KtkuALODe433f0j1zPnCF9GqwnzW.ttf",
//       "italic": "https://fonts.gstatic.com/s/amaranth/v18/KtkoALODe433f0j1zMnAHdWIx2zWD4I.ttf",
//       "700italic": "https://fonts.gstatic.com/s/amaranth/v18/KtkrALODe433f0j1zMnAJWmn42T9E4ucRY8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amaranth/v18/KtkuALODe433f0j1zMnDHdU.ttf"
//   },
//   {
//     "family": "Noto Serif SC",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mm63SzZBEtERe7U.otf",
//       "300": "https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mgq0SzZBEtERe7U.otf",
//       "500": "https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mlK1SzZBEtERe7U.otf",
//       "600": "https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mn6ySzZBEtERe7U.otf",
//       "700": "https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7mhqzSzZBEtERe7U.otf",
//       "900": "https://fonts.gstatic.com/s/notoserifsc/v22/H4c8BXePl9DZ0Xe7gG9cyOj7miKxSzZBEtERe7U.otf",
//       "regular": "https://fonts.gstatic.com/s/notoserifsc/v22/H4chBXePl9DZ0Xe7gG9cyOj7oqCcbzhqDtg.otf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifsc/v22/H4chBXePl9DZ0Xe7gG9cyOj7kqeWbQ.otf"
//   },
//   {
//     "family": "Red Hat Text",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML-ZwVrbacYVFtIY.ttf",
//       "500": "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML4pwVrbacYVFtIY.ttf",
//       "600": "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML2Z3VrbacYVFtIY.ttf",
//       "700": "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML193VrbacYVFtIY.ttf",
//       "regular": "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML7hwVrbacYVFtIY.ttf",
//       "300italic": "https://fonts.gstatic.com/s/redhattext/v14/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAz4PXQdadApIYv_g.ttf",
//       "italic": "https://fonts.gstatic.com/s/redhattext/v14/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzvvXQdadApIYv_g.ttf",
//       "500italic": "https://fonts.gstatic.com/s/redhattext/v14/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzjPXQdadApIYv_g.ttf",
//       "600italic": "https://fonts.gstatic.com/s/redhattext/v14/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzYPLQdadApIYv_g.ttf",
//       "700italic": "https://fonts.gstatic.com/s/redhattext/v14/RrQEbohi_ic6B3yVSzGBrMxQbb0jEzlRoOOLOnAzWfLQdadApIYv_g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redhattext/v14/RrQCbohi_ic6B3yVSzGBrMx6ZI_cy1A6Ok2ML7hwZrfQdQ.ttf"
//   },
//   {
//     "family": "Baskervville",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/baskervville/v16/YA9Ur0yU4l_XOrogbkun3kQgt5OohvbJ9A.ttf",
//       "italic": "https://fonts.gstatic.com/s/baskervville/v16/YA9Kr0yU4l_XOrogbkun3kQQtZmspPPZ9Mlt.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baskervville/v16/YA9Ur0yU4l_XOrogbkun3kQQtpms.ttf"
//   },
//   {
//     "family": "Antonio",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8BtIY2DwSXlM.ttf",
//       "200": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVzx8RtIY2DwSXlM.ttf",
//       "300": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVwv8RtIY2DwSXlM.ttf",
//       "500": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxD8RtIY2DwSXlM.ttf",
//       "600": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVyv9htIY2DwSXlM.ttf",
//       "700": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVyW9htIY2DwSXlM.ttf",
//       "regular": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8RtIY2DwSXlM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/antonio/v19/gNMbW3NwSYq_9WD34ngK5F8vR8T0PVxx8StJaWQ.ttf"
//   },
//   {
//     "family": "Vidaloka",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vidaloka/v18/7cHrv4c3ipenMKlEass8yn4hnCci.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vidaloka/v18/7cHrv4c3ipenMKlEavs9wHo.ttf"
//   },
//   {
//     "family": "Viga",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/viga/v14/xMQbuFFdSaiX_QIjD4e2OX8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/viga/v14/xMQbuFFdSaiXzQMpCw.ttf"
//   },
//   {
//     "family": "Gudea",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/gudea/v15/neIIzCqgsI0mp9gz26WGHK06UY30.ttf",
//       "regular": "https://fonts.gstatic.com/s/gudea/v15/neIFzCqgsI0mp-CP9IGON7Ez.ttf",
//       "italic": "https://fonts.gstatic.com/s/gudea/v15/neILzCqgsI0mp9CN_oWsMqEzSJQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gudea/v15/neIFzCqgsI0mp9CO_oU.ttf"
//   },
//   {
//     "family": "Mukta Malar",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMwBtAB62ruoAZW.ttf",
//       "300": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINUBdAB62ruoAZW.ttf",
//       "500": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMMBNAB62ruoAZW.ttf",
//       "600": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqIMgA9AB62ruoAZW.ttf",
//       "700": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINEAtAB62ruoAZW.ttf",
//       "800": "https://fonts.gstatic.com/s/muktamalar/v12/MCoKzAXyz8LOE2FpJMxZqINYAdAB62ruoAZW.ttf",
//       "regular": "https://fonts.gstatic.com/s/muktamalar/v12/MCoXzAXyz8LOE2FpJMxZqLv4LfQJwHbn.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/muktamalar/v12/MCoXzAXyz8LOE2FpJMxZqIv5J_A.ttf"
//   },
//   {
//     "family": "Sofia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sofia/v14/8QIHdirahM3j_vu-sowsrqjk.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sofia/v14/8QIHdirahM3j_su_uIg.ttf"
//   },
//   {
//     "family": "Syne",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/syne/v22/8vIS7w4qzmVxsWxjBZRjr0FKM_0KuT6kR47NCV5Z.ttf",
//       "600": "https://fonts.gstatic.com/s/syne/v22/8vIS7w4qzmVxsWxjBZRjr0FKM_3mvj6kR47NCV5Z.ttf",
//       "700": "https://fonts.gstatic.com/s/syne/v22/8vIS7w4qzmVxsWxjBZRjr0FKM_3fvj6kR47NCV5Z.ttf",
//       "800": "https://fonts.gstatic.com/s/syne/v22/8vIS7w4qzmVxsWxjBZRjr0FKM_24vj6kR47NCV5Z.ttf",
//       "regular": "https://fonts.gstatic.com/s/syne/v22/8vIS7w4qzmVxsWxjBZRjr0FKM_04uT6kR47NCV5Z.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/syne/v22/8vIS7w4qzmVxsWxjBZRjr0FKM_04uQ6lTYo.ttf"
//   },
//   {
//     "family": "Ropa Sans",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ropasans/v15/EYqxmaNOzLlWtsZSScyKWjloU5KP2g.ttf",
//       "italic": "https://fonts.gstatic.com/s/ropasans/v15/EYq3maNOzLlWtsZSScy6WDNscZef2mNE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ropasans/v15/EYqxmaNOzLlWtsZSScy6WzNs.ttf"
//   },
//   {
//     "family": "Six Caps",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sixcaps/v20/6ae_4KGrU7VR7bNmabcS9XXaPCop.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sixcaps/v20/6ae_4KGrU7VR7bNmaYcT_3E.ttf"
//   },
//   {
//     "family": "Whisper",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/whisper/v5/q5uHsoqtKftx74K9milCBxxdmYU.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/whisper/v5/q5uHsoqtKftx74K9qihIAw.ttf"
//   },
//   {
//     "family": "Secular One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/secularone/v12/8QINdiTajsj_87rMuMdKypDlMul7LJpK.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/secularone/v12/8QINdiTajsj_87rMuMdKyqDkOO0.ttf"
//   },
//   {
//     "family": "Playball",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/playball/v20/TK3gWksYAxQ7jbsKcj8Dl-tPKo2t.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/playball/v20/TK3gWksYAxQ7jbsKcg8Cne8.ttf"
//   },
//   {
//     "family": "Faustina",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHls3IEvGVWWe8tbEg.ttf",
//       "500": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlssIEvGVWWe8tbEg.ttf",
//       "600": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsXIYvGVWWe8tbEg.ttf",
//       "700": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsZYYvGVWWe8tbEg.ttf",
//       "800": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsAoYvGVWWe8tbEg.ttf",
//       "regular": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEvGVWWe8tbEg.ttf",
//       "300italic": "https://fonts.gstatic.com/s/faustina/v20/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsKZWl-SWc5LEnoF.ttf",
//       "italic": "https://fonts.gstatic.com/s/faustina/v20/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsLHWl-SWc5LEnoF.ttf",
//       "500italic": "https://fonts.gstatic.com/s/faustina/v20/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsL1Wl-SWc5LEnoF.ttf",
//       "600italic": "https://fonts.gstatic.com/s/faustina/v20/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIZXV-SWc5LEnoF.ttf",
//       "700italic": "https://fonts.gstatic.com/s/faustina/v20/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsIgXV-SWc5LEnoF.ttf",
//       "800italic": "https://fonts.gstatic.com/s/faustina/v20/XLY2IZPxYpJfTbZAFV-6B8JKUqez9n55SsJHXV-SWc5LEnoF.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/faustina/v20/XLY4IZPxYpJfTbZAFXWzNT2SO8wpWHlsgoEfGF-S.ttf"
//   },
//   {
//     "family": "Blinker",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/blinker/v13/cIf_MaFatEE-VTaP_E2hZEsCkIt9QQ.ttf",
//       "200": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_OGARGEsnIJkWL4.ttf",
//       "300": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_IWDRGEsnIJkWL4.ttf",
//       "600": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_PGFRGEsnIJkWL4.ttf",
//       "700": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_JWERGEsnIJkWL4.ttf",
//       "800": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_ImHRGEsnIJkWL4.ttf",
//       "900": "https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_K2GRGEsnIJkWL4.ttf",
//       "regular": "https://fonts.gstatic.com/s/blinker/v13/cIf9MaFatEE-VTaPxCmrYGkHgIs.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/blinker/v13/cIf9MaFatEE-VTaP9CihZA.ttf"
//   },
//   {
//     "family": "Pridi",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc1SiE0jRUG0AqUc.ttf",
//       "300": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc02i00jRUG0AqUc.ttf",
//       "500": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc1uik0jRUG0AqUc.ttf",
//       "600": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc1CjU0jRUG0AqUc.ttf",
//       "700": "https://fonts.gstatic.com/s/pridi/v13/2sDdZG5JnZLfkc0mjE0jRUG0AqUc.ttf",
//       "regular": "https://fonts.gstatic.com/s/pridi/v13/2sDQZG5JnZLfkfWao2krbl29.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pridi/v13/2sDQZG5JnZLfkcWbqW0.ttf"
//   },
//   {
//     "family": "Zen Maru Gothic",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/zenmarugothic/v16/o-0XIpIxzW5b-RxT-6A8jWAtCp-cQWpCPJqa_ajlvw.ttf",
//       "500": "https://fonts.gstatic.com/s/zenmarugothic/v16/o-0XIpIxzW5b-RxT-6A8jWAtCp-cGWtCPJqa_ajlvw.ttf",
//       "700": "https://fonts.gstatic.com/s/zenmarugothic/v16/o-0XIpIxzW5b-RxT-6A8jWAtCp-cUW1CPJqa_ajlvw.ttf",
//       "900": "https://fonts.gstatic.com/s/zenmarugothic/v16/o-0XIpIxzW5b-RxT-6A8jWAtCp-caW9CPJqa_ajlvw.ttf",
//       "regular": "https://fonts.gstatic.com/s/zenmarugothic/v16/o-0SIpIxzW5b-RxT-6A8jWAtCp-k7UJmNLGG9A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenmarugothic/v16/o-0SIpIxzW5b-RxT-6A8jWAtCp-U7Ehi.ttf"
//   },
//   {
//     "family": "Volkhov",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-04-27",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/volkhov/v17/SlGVmQieoJcKemNeeY4hoHRYbDQUego.ttf",
//       "regular": "https://fonts.gstatic.com/s/volkhov/v17/SlGQmQieoJcKemNeQTIOhHxzcD0.ttf",
//       "italic": "https://fonts.gstatic.com/s/volkhov/v17/SlGSmQieoJcKemNecTAEgF52YD0NYw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/volkhov/v17/SlGXmQieoJcKemNecTA8PHFSaBYRagrQrA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/volkhov/v17/SlGQmQieoJcKemNecTMEgA.ttf"
//   },
//   {
//     "family": "Jura",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "kayah-li",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/jura/v31/z7NOdRfiaC4Vd8hhoPzfb5vBTP0D7auhTfmrH_rt.ttf",
//       "500": "https://fonts.gstatic.com/s/jura/v31/z7NOdRfiaC4Vd8hhoPzfb5vBTP1v7auhTfmrH_rt.ttf",
//       "600": "https://fonts.gstatic.com/s/jura/v31/z7NOdRfiaC4Vd8hhoPzfb5vBTP2D6quhTfmrH_rt.ttf",
//       "700": "https://fonts.gstatic.com/s/jura/v31/z7NOdRfiaC4Vd8hhoPzfb5vBTP266quhTfmrH_rt.ttf",
//       "regular": "https://fonts.gstatic.com/s/jura/v31/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7auhTfmrH_rt.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jura/v31/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7ZugR_0.ttf"
//   },
//   {
//     "family": "Itim",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/itim/v14/0nknC9ziJOYewARKkc7ZdwU.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/itim/v14/0nknC9ziJOYe8AVAlQ.ttf"
//   },
//   {
//     "family": "Epilogue",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDLiDJXVigHPVA.ttf",
//       "200": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXsDPiDJXVigHPVA.ttf",
//       "300": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXbjPiDJXVigHPVA.ttf",
//       "500": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXAjPiDJXVigHPVA.ttf",
//       "600": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OX7jTiDJXVigHPVA.ttf",
//       "700": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OX1zTiDJXVigHPVA.ttf",
//       "800": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXsDTiDJXVigHPVA.ttf",
//       "900": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXmTTiDJXVigHPVA.ttf",
//       "regular": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDPiDJXVigHPVA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HAKTp_RqATfVHNU.ttf",
//       "200italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCKT5_RqATfVHNU.ttf",
//       "300italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HBUT5_RqATfVHNU.ttf",
//       "italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HAKT5_RqATfVHNU.ttf",
//       "500italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HA4T5_RqATfVHNU.ttf",
//       "600italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HDUSJ_RqATfVHNU.ttf",
//       "700italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HDtSJ_RqATfVHNU.ttf",
//       "800italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCKSJ_RqATfVHNU.ttf",
//       "900italic": "https://fonts.gstatic.com/s/epilogue/v17/O4ZCFGj5hxF0EhjimlIhqAYaY7EBcUSC-HCjSJ_RqATfVHNU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/epilogue/v17/O4ZMFGj5hxF0EhjimngomvnCCtqb30OXMDPSDZ_R.ttf"
//   },
//   {
//     "family": "Alex Brush",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alexbrush/v22/SZc83FzrJKuqFbwMKk6EtUL57DtOmCc.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alexbrush/v22/SZc83FzrJKuqFbwMKk6EhUPz6A.ttf"
//   },
//   {
//     "family": "Audiowide",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/audiowide/v20/l7gdbjpo0cum0ckerWCtkQXPExpQBw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/audiowide/v20/l7gdbjpo0cum0ckerWCdkA_L.ttf"
//   },
//   {
//     "family": "Carter One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/carterone/v17/q5uCsoe5IOB2-pXv9UcNIxR2hYxREMs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/carterone/v17/q5uCsoe5IOB2-pXv9UcNExV8gQ.ttf"
//   },
//   {
//     "family": "Saira Semi Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MN6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXdvaOM8rXT-8V8.ttf",
//       "200": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfDScMWg3j36Ebz.ttf",
//       "300": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXenSsMWg3j36Ebz.ttf",
//       "500": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXf_S8MWg3j36Ebz.ttf",
//       "600": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXfTTMMWg3j36Ebz.ttf",
//       "700": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXe3TcMWg3j36Ebz.ttf",
//       "800": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXerTsMWg3j36Ebz.ttf",
//       "900": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MM6c-2-nnJkHxyCjRcnMHcWVWV1cWRRXePT8MWg3j36Ebz.ttf",
//       "regular": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRU8LYuceqGT-.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sairasemicondensed/v13/U9MD6c-2-nnJkHxyCjRcnMHcWVWV1cWRRX8KaOM.ttf"
//   },
//   {
//     "family": "Baloo 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/baloo2/v21/wXK0E3kTposypRydzVT08TS3JnAmtdgozapv9Fat7WcN.ttf",
//       "600": "https://fonts.gstatic.com/s/baloo2/v21/wXK0E3kTposypRydzVT08TS3JnAmtdjEyqpv9Fat7WcN.ttf",
//       "700": "https://fonts.gstatic.com/s/baloo2/v21/wXK0E3kTposypRydzVT08TS3JnAmtdj9yqpv9Fat7WcN.ttf",
//       "800": "https://fonts.gstatic.com/s/baloo2/v21/wXK0E3kTposypRydzVT08TS3JnAmtdiayqpv9Fat7WcN.ttf",
//       "regular": "https://fonts.gstatic.com/s/baloo2/v21/wXK0E3kTposypRydzVT08TS3JnAmtdgazapv9Fat7WcN.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baloo2/v21/wXK0E3kTposypRydzVT08TS3JnAmtdgazZpu_lI.ttf"
//   },
//   {
//     "family": "Lalezar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lalezar/v14/zrfl0HLVx-HwTP82UaDyIiL0RCg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lalezar/v14/zrfl0HLVx-HwTP82YaH4Jg.ttf"
//   },
//   {
//     "family": "Bad Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-04-20",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/badscript/v16/6NUT8F6PJgbFWQn47_x7lOwuzd1AZtw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/badscript/v16/6NUT8F6PJgbFWQn47_x7pO0kyQ.ttf"
//   },
//   {
//     "family": "Radio Canada",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "canadian-aboriginal",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-05-09",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/radiocanada/v21/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nESkQPIJOdSSfOT.ttf",
//       "500": "https://fonts.gstatic.com/s/radiocanada/v21/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nF-kQPIJOdSSfOT.ttf",
//       "600": "https://fonts.gstatic.com/s/radiocanada/v21/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nGSlgPIJOdSSfOT.ttf",
//       "700": "https://fonts.gstatic.com/s/radiocanada/v21/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nGrlgPIJOdSSfOT.ttf",
//       "regular": "https://fonts.gstatic.com/s/radiocanada/v21/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nFMkQPIJOdSSfOT.ttf",
//       "300italic": "https://fonts.gstatic.com/s/radiocanada/v21/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0rWLLuNwTOOTa9k.ttf",
//       "italic": "https://fonts.gstatic.com/s/radiocanada/v21/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0uuLLuNwTOOTa9k.ttf",
//       "500italic": "https://fonts.gstatic.com/s/radiocanada/v21/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0tmLLuNwTOOTa9k.ttf",
//       "600italic": "https://fonts.gstatic.com/s/radiocanada/v21/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0jWMLuNwTOOTa9k.ttf",
//       "700italic": "https://fonts.gstatic.com/s/radiocanada/v21/XRX33ISXn0dBMcibU6jlAqrdcwAMBJuK9IgQn4bfnSrKcMQM2cGQ1WSE0gyMLuNwTOOTa9k.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/radiocanada/v21/XRX13ISXn0dBMcibU6jlAqr3ejLv5OLZYiYXik6db2P4jxxlsls-0nFMkTPJLuM.ttf"
//   },
//   {
//     "family": "Press Start 2P",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pressstart2p/v15/e3t4euO8T-267oIAQAu6jDQyK0nSgPJE4580.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pressstart2p/v15/e3t4euO8T-267oIAQAu6jDQyK3nTivY.ttf"
//   },
//   {
//     "family": "Hind Vadodara",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-05-02",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSDn3iXM0oSOL2Yw.ttf",
//       "500": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSGH2iXM0oSOL2Yw.ttf",
//       "600": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSE3xiXM0oSOL2Yw.ttf",
//       "700": "https://fonts.gstatic.com/s/hindvadodara/v13/neIQzCKvrIcn5pbuuuriV9tTSCnwiXM0oSOL2Yw.ttf",
//       "regular": "https://fonts.gstatic.com/s/hindvadodara/v13/neINzCKvrIcn5pbuuuriV9tTcJXfrXsfvSo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hindvadodara/v13/neINzCKvrIcn5pbuuuriV9tTQJTVqQ.ttf"
//   },
//   {
//     "family": "Nothing You Could Do",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nothingyoucoulddo/v19/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb0OJl1ol2Ymo.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nothingyoucoulddo/v19/oY1B8fbBpaP5OX3DtrRYf_Q2BPB1SnfZb3OInV4.ttf"
//   },
//   {
//     "family": "Varela",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/varela/v16/DPEtYwqExx0AWHXJBBQFfvzDsQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/varela/v16/DPEtYwqExx0AWHX5BR4B.ttf"
//   },
//   {
//     "family": "Ruda",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJ3si_-2KiSGg-H.ttf",
//       "600": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKbtS_-2KiSGg-H.ttf",
//       "700": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaKitS_-2KiSGg-H.ttf",
//       "800": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLFtS_-2KiSGg-H.ttf",
//       "900": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaLstS_-2KiSGg-H.ttf",
//       "regular": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsi_-2KiSGg-H.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ruda/v28/k3kKo8YQJOpFgHQ1mQ5VkEbUKaJFsh__0qw.ttf"
//   },
//   {
//     "family": "Shippori Mincho",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4L9am5JEO5--2zg.ttf",
//       "600": "https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4A9Gm5JEO5--2zg.ttf",
//       "700": "https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4Z9Cm5JEO5--2zg.ttf",
//       "800": "https://fonts.gstatic.com/s/shipporimincho/v14/VdGDAZweH5EbgHY6YExcZfDoj0B4e9Om5JEO5--2zg.ttf",
//       "regular": "https://fonts.gstatic.com/s/shipporimincho/v14/VdGGAZweH5EbgHY6YExcZfDoj0BA2_-C7LoS7g.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shipporimincho/v14/VdGGAZweH5EbgHY6YExcZfDoj0Bw2vWG.ttf"
//   },
//   {
//     "family": "Fira Mono",
//     "variants": [
//       "regular",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/firamono/v14/N0bS2SlFPv1weGeLZDto1d33mf3VaZBRBQ.ttf",
//       "700": "https://fonts.gstatic.com/s/firamono/v14/N0bS2SlFPv1weGeLZDtondv3mf3VaZBRBQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/firamono/v14/N0bX2SlFPv1weGeLZDtQIfTTkdbJYA.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/firamono/v14/N0bX2SlFPv1weGeLZDtgIP7X.ttf"
//   },
//   {
//     "family": "Anonymous Pro",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2cp2a15UIB7Un-bOeISG3pFuAT0CnW7KOywKo.ttf",
//       "regular": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pLlw89CH98Ko.ttf",
//       "italic": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2fp2a15UIB7Un-bOeISG3pHl428AP44Kqr2Q.ttf",
//       "700italic": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2ap2a15UIB7Un-bOeISG3pHl4OTCzc6IG30KqB9Q.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anonymouspro/v21/rP2Bp2a15UIB7Un-bOeISG3pHl028A.ttf"
//   },
//   {
//     "family": "Mr Dafoe",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mrdafoe/v14/lJwE-pIzkS5NXuMMrGiqg7MCxz_C.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mrdafoe/v14/lJwE-pIzkS5NXuMMrFiribc.ttf"
//   },
//   {
//     "family": "Hammersmith One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hammersmithone/v17/qWcyB624q4L_C4jGQ9IK0O_dFlnbshsks4MRXw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hammersmithone/v17/qWcyB624q4L_C4jGQ9IK0O_dFlnrsxEg.ttf"
//   },
//   {
//     "family": "Geologica",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2023-05-31",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqDx_qQ-MYAXWnqFs.ttf",
//       "200": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqD5_rQ-MYAXWnqFs.ttf",
//       "300": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqD0HrQ-MYAXWnqFs.ttf",
//       "500": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqDy3rQ-MYAXWnqFs.ttf",
//       "600": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqD8HsQ-MYAXWnqFs.ttf",
//       "700": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqD_jsQ-MYAXWnqFs.ttf",
//       "800": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqD5_sQ-MYAXWnqFs.ttf",
//       "900": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqD7bsQ-MYAXWnqFs.ttf",
//       "regular": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqDx_rQ-MYAXWnqFs.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/geologica/v1/oY1o8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckU8H3G11_z-_OZqDx_rc-ISBQ.ttf"
//   },
//   {
//     "family": "Mandali",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v14",
//     "lastModified": "2022-04-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mandali/v14/LhWlMVbYOfASNfNUVFk1ZPdcKtA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mandali/v14/LhWlMVbYOfASNfNUZFg_YA.ttf"
//   },
//   {
//     "family": "Koulen",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2024-02-16",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/koulen/v28/AMOQz46as3KIBPeWgnA9kuYMUg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/koulen/v28/AMOQz46as3KIBPemg3o5.ttf"
//   },
//   {
//     "family": "Quantico",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/quantico/v17/rax5HiSdp9cPL3KIF7TQARhasU7Q8Cad.ttf",
//       "regular": "https://fonts.gstatic.com/s/quantico/v17/rax-HiSdp9cPL3KIF4xsLjxSmlLZ.ttf",
//       "italic": "https://fonts.gstatic.com/s/quantico/v17/rax4HiSdp9cPL3KIF7xuJDhwn0LZ6T8.ttf",
//       "700italic": "https://fonts.gstatic.com/s/quantico/v17/rax7HiSdp9cPL3KIF7xuHIRfu0ry9TadML4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/quantico/v17/rax-HiSdp9cPL3KIF7xtJDg.ttf"
//   },
//   {
//     "family": "Unica One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/unicaone/v18/DPEuYwWHyAYGVTSmalshdtffuEY7FA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/unicaone/v18/DPEuYwWHyAYGVTSmalsRd93b.ttf"
//   },
//   {
//     "family": "Londrina Solid",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "900"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/londrinasolid/v17/flUjRq6sw40kQEJxWNgkLuudGfs9KBYesZHhV64.ttf",
//       "300": "https://fonts.gstatic.com/s/londrinasolid/v17/flUiRq6sw40kQEJxWNgkLuudGfv1CjY0n53oTrcL.ttf",
//       "900": "https://fonts.gstatic.com/s/londrinasolid/v17/flUiRq6sw40kQEJxWNgkLuudGfvdDzY0n53oTrcL.ttf",
//       "regular": "https://fonts.gstatic.com/s/londrinasolid/v17/flUhRq6sw40kQEJxWNgkLuudGcNZIhI8tIHh.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/londrinasolid/v17/flUhRq6sw40kQEJxWNgkLuudGfNYKBY.ttf"
//   },
//   {
//     "family": "Bai Jamjuree",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0kePuk5A1-yiSgA.ttf",
//       "300": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa09eDuk5A1-yiSgA.ttf",
//       "500": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0reHuk5A1-yiSgA.ttf",
//       "600": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa0gebuk5A1-yiSgA.ttf",
//       "700": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIqapSCOBt_aeQQ7ftydoa05efuk5A1-yiSgA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_oGkpox2S2CgOva.ttf",
//       "300italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_pikZox2S2CgOva.ttf",
//       "regular": "https://fonts.gstatic.com/s/baijamjuree/v11/LDI1apSCOBt_aeQQ7ftydoaMWcjKm7sp8g.ttf",
//       "italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIrapSCOBt_aeQQ7ftydoa8W8LOub458jGL.ttf",
//       "500italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_o6kJox2S2CgOva.ttf",
//       "600italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_oWl5ox2S2CgOva.ttf",
//       "700italic": "https://fonts.gstatic.com/s/baijamjuree/v11/LDIoapSCOBt_aeQQ7ftydoa8W_pylpox2S2CgOva.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baijamjuree/v11/LDI1apSCOBt_aeQQ7ftydoa8WMLO.ttf"
//   },
//   {
//     "family": "Fraunces",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v31",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxqjDvTShUtWNg.ttf",
//       "200": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcNxujDvTShUtWNg.ttf",
//       "300": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIc6RujDvTShUtWNg.ttf",
//       "500": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIchRujDvTShUtWNg.ttf",
//       "600": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcaRyjDvTShUtWNg.ttf",
//       "700": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcUByjDvTShUtWNg.ttf",
//       "800": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcNxyjDvTShUtWNg.ttf",
//       "900": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIcHhyjDvTShUtWNg.ttf",
//       "regular": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxujDvTShUtWNg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1hLTP7Wp05GNi3k.ttf",
//       "200italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jLTf7Wp05GNi3k.ttf",
//       "300italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1gVTf7Wp05GNi3k.ttf",
//       "italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1hLTf7Wp05GNi3k.ttf",
//       "500italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1h5Tf7Wp05GNi3k.ttf",
//       "600italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1iVSv7Wp05GNi3k.ttf",
//       "700italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1isSv7Wp05GNi3k.ttf",
//       "800italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jLSv7Wp05GNi3k.ttf",
//       "900italic": "https://fonts.gstatic.com/s/fraunces/v31/6NVf8FyLNQOQZAnv9ZwNjucMHVn85Ni7emAe9lKqZTnbB-gzTK0K1ChJdt9vIVYX9G37lvd9sPEKsxx664UJf1jiSv7Wp05GNi3k.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fraunces/v31/6NUh8FyLNQOQZAnv9bYEvDiIdE9Ea92uemAk_WBq8U_9v0c2Wa0K7iN7hzFUPJH58nib1603gg7S2nfgRYIctxuTD_7W.ttf"
//   },
//   {
//     "family": "Ramabhadra",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v15",
//     "lastModified": "2022-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ramabhadra/v15/EYq2maBOwqRW9P1SQ83LehNGX5uWw3o.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ramabhadra/v15/EYq2maBOwqRW9P1SQ83LShJMWw.ttf"
//   },
//   {
//     "family": "Electrolize",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/electrolize/v18/cIf5Ma1dtE0zSiGSiED7AUEGso5tQafB.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/electrolize/v18/cIf5Ma1dtE0zSiGSiED7AXEHuIo.ttf"
//   },
//   {
//     "family": "Castoro",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/castoro/v19/1q2GY5yMCld3-O4cHYhEzOYenEU.ttf",
//       "italic": "https://fonts.gstatic.com/s/castoro/v19/1q2EY5yMCld3-O4cLYpOyMQbjEX5fw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/castoro/v19/1q2GY5yMCld3-O4cLYlOyA.ttf"
//   },
//   {
//     "family": "Forum",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/forum/v18/6aey4Ky-Vb8Ew_IWMJMa3mnT.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/forum/v18/6aey4Ky-Vb8Ew8IXOpc.ttf"
//   },
//   {
//     "family": "Ultra",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ultra/v23/zOLy4prXmrtY-tT6yLOD6NxF.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ultra/v23/zOLy4prXmrtY-uT7wrc.ttf"
//   },
//   {
//     "family": "Krub",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZo47KLF4R6gWaf8.ttf",
//       "300": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZuo4KLF4R6gWaf8.ttf",
//       "500": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZrI5KLF4R6gWaf8.ttf",
//       "600": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZp4-KLF4R6gWaf8.ttf",
//       "700": "https://fonts.gstatic.com/s/krub/v9/sZlEdRyC6CRYZvo_KLF4R6gWaf8.ttf",
//       "200italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQiwLByQ4oTef_6gQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQipLNyQ4oTef_6gQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/krub/v9/sZlLdRyC6CRYXkYQDLlTW6E.ttf",
//       "italic": "https://fonts.gstatic.com/s/krub/v9/sZlFdRyC6CRYbkQaCJtWS6EPcA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQi_LJyQ4oTef_6gQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQi0LVyQ4oTef_6gQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/krub/v9/sZlGdRyC6CRYbkQitLRyQ4oTef_6gQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/krub/v9/sZlLdRyC6CRYbkcaCA.ttf"
//   },
//   {
//     "family": "Lusitana",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/lusitana/v13/CSR74z9ShvucWzsMKyDmaccqYtd2vfwk.ttf",
//       "regular": "https://fonts.gstatic.com/s/lusitana/v13/CSR84z9ShvucWzsMKxhaRuMiSct_.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lusitana/v13/CSR84z9ShvucWzsMKyhbTOc.ttf"
//   },
//   {
//     "family": "Gilda Display",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gildadisplay/v18/t5tmIRoYMoaYG0WEOh7HwMeR7TnFrpOHYh4.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gildadisplay/v18/t5tmIRoYMoaYG0WEOh7HwMeR3TjPqg.ttf"
//   },
//   {
//     "family": "Cabin Condensed",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpJtK6mNhBK2err_hqkYhHRqmwilMH97F15-K1oqQ.ttf",
//       "600": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpJtK6mNhBK2err_hqkYhHRqmwiuMb97F15-K1oqQ.ttf",
//       "700": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpJtK6mNhBK2err_hqkYhHRqmwi3Mf97F15-K1oqQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpMtK6mNhBK2err_hqkYhHRqmwaYOjZ5HZl8Q.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cabincondensed/v20/nwpMtK6mNhBK2err_hqkYhHRqmwqYeLd.ttf"
//   },
//   {
//     "family": "Neucha",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bvugNsCxVEgA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/neucha/v17/q5uGsou0JOdh94bfuwlo.ttf"
//   },
//   {
//     "family": "Sorts Mill Goudy",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3GZR9MED_6PSuS_50nEaVrfzgEXH0OjpM75PE.ttf",
//       "italic": "https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3AZR9MED_6PSuS_50nEaVrfzgEbH8EirE-9PGLfQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sortsmillgoudy/v15/Qw3GZR9MED_6PSuS_50nEaVrfzgEbHwEig.ttf"
//   },
//   {
//     "family": "Istok Web",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/istokweb/v24/3qTqojGmgSyUukBzKslhvU5a_mkUYBfcMw.ttf",
//       "regular": "https://fonts.gstatic.com/s/istokweb/v24/3qTvojGmgSyUukBzKslZAWF-9kIIaQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/istokweb/v24/3qTpojGmgSyUukBzKslpA2t61EcYaQ7F.ttf",
//       "700italic": "https://fonts.gstatic.com/s/istokweb/v24/3qT0ojGmgSyUukBzKslpA1PG-2MQQhLMMygN.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/istokweb/v24/3qTvojGmgSyUukBzKslpAGt6.ttf"
//   },
//   {
//     "family": "Reenie Beanie",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/reeniebeanie/v20/z7NSdR76eDkaJKZJFkkjuvWxbP2_qoOgf_w.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/reeniebeanie/v20/z7NSdR76eDkaJKZJFkkjuvWxXPy1rg.ttf"
//   },
//   {
//     "family": "Rock Salt",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rocksalt/v22/MwQ0bhv11fWD6QsAVOZbsEk7hbBWrA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rocksalt/v22/MwQ0bhv11fWD6QsAVOZrsUM_.ttf"
//   },
//   {
//     "family": "Monda",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/monda/v16/TK3gWkYFABsmjsLaGz8Dl-tPKo2t.ttf",
//       "regular": "https://fonts.gstatic.com/s/monda/v16/TK3tWkYFABsmjvpmNBsLvPdG.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/monda/v16/TK3tWkYFABsmjspnPh8.ttf"
//   },
//   {
//     "family": "Cutive Mono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cutivemono/v20/m8JWjfRfY7WVjVi2E-K9H5RFRG-K3Mud.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cutivemono/v20/m8JWjfRfY7WVjVi2E-K9H6RETms.ttf"
//   },
//   {
//     "family": "League Gothic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/leaguegothic/v11/qFdR35CBi4tvBz81xy7WG7ep-BQAY7Krj7feObpH_-amidQ6Q9hn.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/leaguegothic/v11/qFdR35CBi4tvBz81xy7WG7ep-BQAY7Krj7feObpH_9ang9A.ttf"
//   },
//   {
//     "family": "Niramit",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVXx7tiiEr5_BdZ8.ttf",
//       "300": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVRh4tiiEr5_BdZ8.ttf",
//       "500": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVUB5tiiEr5_BdZ8.ttf",
//       "600": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVWx-tiiEr5_BdZ8.ttf",
//       "700": "https://fonts.gstatic.com/s/niramit/v10/I_urMpWdvgLdNxVLVQh_tiiEr5_BdZ8.ttf",
//       "200italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiXimOq73EZZ_f6w.ttf",
//       "300italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiOiqOq73EZZ_f6w.ttf",
//       "regular": "https://fonts.gstatic.com/s/niramit/v10/I_uuMpWdvgLdNxVLbbRQkiCvs5Y.ttf",
//       "italic": "https://fonts.gstatic.com/s/niramit/v10/I_usMpWdvgLdNxVLXbZalgKqo5bYbA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiYiuOq73EZZ_f6w.ttf",
//       "600italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiTiyOq73EZZ_f6w.ttf",
//       "700italic": "https://fonts.gstatic.com/s/niramit/v10/I_upMpWdvgLdNxVLXbZiKi2Oq73EZZ_f6w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/niramit/v10/I_uuMpWdvgLdNxVLXbValg.ttf"
//   },
//   {
//     "family": "Noto Sans Tamil",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v27",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGor0RqKDt_EvT.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7tGo70RqKDt_EvT.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7uYo70RqKDt_EvT.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7v0o70RqKDt_EvT.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7sYpL0RqKDt_EvT.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7shpL0RqKDt_EvT.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7tGpL0RqKDt_EvT.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7tvpL0RqKDt_EvT.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo70RqKDt_EvT.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstamil/v27/ieVc2YdFI3GCY6SyQy1KfStzYKZgzN1z4LKDbeZce-0429tBManUktuex7vGo40QoqQ.ttf"
//   },
//   {
//     "family": "Marck Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/marckscript/v20/nwpTtK2oNgBA3Or78gapdwuCzyI-aMPF7Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/marckscript/v20/nwpTtK2oNgBA3Or78gapdwuyzig6.ttf"
//   },
//   {
//     "family": "BenchNine",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/benchnine/v16/ahcev8612zF4jxrwMosT--tRhWa8q0v8ag.ttf",
//       "700": "https://fonts.gstatic.com/s/benchnine/v16/ahcev8612zF4jxrwMosT6-xRhWa8q0v8ag.ttf",
//       "regular": "https://fonts.gstatic.com/s/benchnine/v16/ahcbv8612zF4jxrwMosrV8N1jU2gog.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/benchnine/v16/ahcbv8612zF4jxrwMosbVslx.ttf"
//   },
//   {
//     "family": "Material Symbols Sharp",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v171",
//     "lastModified": "2024-04-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxOLozCOJ1H7-knk.ttf",
//       "200": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxMLojCOJ1H7-knk.ttf",
//       "300": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxPVojCOJ1H7-knk.ttf",
//       "500": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxO5ojCOJ1H7-knk.ttf",
//       "600": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxNVpTCOJ1H7-knk.ttf",
//       "700": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxNspTCOJ1H7-knk.ttf",
//       "regular": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxOLojCOJ1H7-knk.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/materialsymbolssharp/v171/gNNBW2J8Roq16WD5tFNRaeLQk6-SHQ_R00k4c2_whPnoY9ruReaU4bHmz74m0ZkGH-VBYe1x0TV6x4yFH8F-H5OdzEL3sVTgJtfbYxOLogCPLVU.ttf"
//   },
//   {
//     "family": "Monoton",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/monoton/v19/5h1aiZUrOngCibe4fkbBQ2S7FU8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/monoton/v19/5h1aiZUrOngCibe4TkfLRw.ttf"
//   },
//   {
//     "family": "Basic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/basic/v17/xfu_0WLxV2_XKQN34lDVyR7D.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/basic/v17/xfu_0WLxV2_XKTN26FQ.ttf"
//   },
//   {
//     "family": "GFS Didot",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "greek"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gfsdidot/v15/Jqzh5TybZ9vZMWFssvwiF-fGFSCGAA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gfsdidot/v15/Jqzh5TybZ9vZMWFssvwSFu3C.ttf"
//   },
//   {
//     "family": "Share Tech Mono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFc7pAsEIc3Xew.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFcLpQEA.ttf"
//   },
//   {
//     "family": "Gochi Hand",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gochihand/v23/hES06XlsOjtJsgCkx1PkTo71-n0nXWA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gochihand/v23/hES06XlsOjtJsgCkx1Pkfo___g.ttf"
//   },
//   {
//     "family": "Martel Sans",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hAX5suHFUknqMxQ.ttf",
//       "300": "https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hBz5cuHFUknqMxQ.ttf",
//       "600": "https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hAH48uHFUknqMxQ.ttf",
//       "700": "https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hBj4suHFUknqMxQ.ttf",
//       "800": "https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hB_4cuHFUknqMxQ.ttf",
//       "900": "https://fonts.gstatic.com/s/martelsans/v12/h0GxssGi7VdzDgKjM-4d8hBb4MuHFUknqMxQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/martelsans/v12/h0GsssGi7VdzDgKjM-4d8ijfze-PPlUu.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/martelsans/v12/h0GsssGi7VdzDgKjM-4d8hjex-s.ttf"
//   },
//   {
//     "family": "Mada",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-05-23",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFlOkHkw2-m9x2iC.ttf",
//       "300": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFmQkHkw2-m9x2iC.ttf",
//       "500": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFn8kHkw2-m9x2iC.ttf",
//       "600": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFkQl3kw2-m9x2iC.ttf",
//       "700": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFkpl3kw2-m9x2iC.ttf",
//       "800": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFlOl3kw2-m9x2iC.ttf",
//       "900": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFlnl3kw2-m9x2iC.ttf",
//       "regular": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFnOkHkw2-m9x2iC.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mada/v19/7Aulp_0qnzeSVz7u3PJLcUMYOFnOkEkx0e0.ttf"
//   },
//   {
//     "family": "Julius Sans One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/juliussansone/v18/1Pt2g8TAX_SGgBGUi0tGOYEga5W-xXEW6aGXHw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/juliussansone/v18/1Pt2g8TAX_SGgBGUi0tGOYEga5WOxHsS.ttf"
//   },
//   {
//     "family": "Atkinson Hyperlegible",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt73C1KxNDXMspQ1lPyU89-1h6ONRlW45G8WbcNcy-OZFy-FA.ttf",
//       "regular": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt23C1KxNDXMspQ1lPyU89-1h6ONRlW45GE5ZgpewSSbQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt43C1KxNDXMspQ1lPyU89-1h6ONRlW45G055ItWQGCbUWn.ttf",
//       "700italic": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt93C1KxNDXMspQ1lPyU89-1h6ONRlW45G056qRdiWKRlmuFH24.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/atkinsonhyperlegible/v11/9Bt23C1KxNDXMspQ1lPyU89-1h6ONRlW45G05JIt.ttf"
//   },
//   {
//     "family": "Noto Serif Bengali",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAH3qn4LjQH8yD.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfeAHnqn4LjQH8yD.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfdeHnqn4LjQH8yD.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcyHnqn4LjQH8yD.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JffeGXqn4LjQH8yD.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JffnGXqn4LjQH8yD.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfeAGXqn4LjQH8yD.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfepGXqn4LjQH8yD.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAHnqn4LjQH8yD.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifbengali/v19/hYkuPvggTvnzO14VSXltirUdnnkt1pwmWrprmO7RjE0a5BtdATYU1crFaM_5JfcAHkqm6rw.ttf"
//   },
//   {
//     "family": "Short Stack",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shortstack/v15/bMrzmS2X6p0jZC6EcmPFX-SScX8D0nq6.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shortstack/v15/bMrzmS2X6p0jZC6EcmPFX9STe3s.ttf"
//   },
//   {
//     "family": "Covered By Your Grace",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/coveredbyyourgrace/v15/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOS0FgItq6bFIg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/coveredbyyourgrace/v15/QGYwz-AZahWOJJI9kykWW9mD6opopoqXSOSEFwgp.ttf"
//   },
//   {
//     "family": "Fira Code",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/firacode/v22/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_GNsFVfxN87gsj0.ttf",
//       "500": "https://fonts.gstatic.com/s/firacode/v22/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_A9sFVfxN87gsj0.ttf",
//       "600": "https://fonts.gstatic.com/s/firacode/v22/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_ONrFVfxN87gsj0.ttf",
//       "700": "https://fonts.gstatic.com/s/firacode/v22/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_NprFVfxN87gsj0.ttf",
//       "regular": "https://fonts.gstatic.com/s/firacode/v22/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sFVfxN87gsj0.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/firacode/v22/uU9eCBsR6Z2vfE9aq3bL0fxyUs4tcw4W_D1sJVb7Mw.ttf"
//   },
//   {
//     "family": "Nanum Pen Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nanumpenscript/v19/daaDSSYiLGqEal3MvdA_FOL_3FkN2z7-aMFCcTU.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nanumpenscript/v19/daaDSSYiLGqEal3MvdA_FOL_3FkN6z_0bA.ttf"
//   },
//   {
//     "family": "BIZ UDPGothic",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek-ext",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-09-27",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/bizudpgothic/v10/hESq6X5pHAIBjmS84VL0Bue85skjZWEnTABCSQo.ttf",
//       "regular": "https://fonts.gstatic.com/s/bizudpgothic/v10/hES36X5pHAIBjmS84VL0Bue83nUMQWkMUAk.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bizudpgothic/v10/hES36X5pHAIBjmS84VL0Bue87nQGRQ.ttf"
//   },
//   {
//     "family": "Newsreader",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-09-14",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438w-I_ADOxEPjCggA.ttf",
//       "300": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wJo_ADOxEPjCggA.ttf",
//       "500": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wSo_ADOxEPjCggA.ttf",
//       "600": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wpojADOxEPjCggA.ttf",
//       "700": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438wn4jADOxEPjCggA.ttf",
//       "800": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438w-IjADOxEPjCggA.ttf",
//       "regular": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438weI_ADOxEPjCggA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMyoT-ZAHDWwgECi.ttf",
//       "300italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMx2T-ZAHDWwgECi.ttf",
//       "italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMwoT-ZAHDWwgECi.ttf",
//       "500italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMwaT-ZAHDWwgECi.ttf",
//       "600italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMz2SOZAHDWwgECi.ttf",
//       "700italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMzPSOZAHDWwgECi.ttf",
//       "800italic": "https://fonts.gstatic.com/s/newsreader/v20/cY9kfjOCX1hbuyalUrK439vogqC9yFZCYg7oRZaLP4obnf7fTXglsMyoSOZAHDWwgECi.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/newsreader/v20/cY9qfjOCX1hbuyalUrK49dLac06G1ZGsZBtoBCzBDXXD9JVF438weI_wDeZA.ttf"
//   },
//   {
//     "family": "Playfair",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2023-06-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPlKetgdoSMw5ifm.ttf",
//       "500": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPkmetgdoSMw5ifm.ttf",
//       "600": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPnKfdgdoSMw5ifm.ttf",
//       "700": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPnzfdgdoSMw5ifm.ttf",
//       "800": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPmUfdgdoSMw5ifm.ttf",
//       "900": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPm9fdgdoSMw5ifm.ttf",
//       "regular": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPkUetgdoSMw5ifm.ttf",
//       "300italic": "https://fonts.gstatic.com/s/playfair/v2/0nkSC9D7PO4KhmUJ59baVQ_iWhg0cgSrLQZDFpFUsLCFf_1ubkfQeG9KkBAQcOsAs-zcOW5eqycS4zfmNrE.ttf",
//       "italic": "https://fonts.gstatic.com/s/playfair/v2/0nkSC9D7PO4KhmUJ59baVQ_iWhg0cgSrLQZDFpFUsLCFf_1ubkfQeG9KkBAQcOsAs-zcOTBeqycS4zfmNrE.ttf",
//       "500italic": "https://fonts.gstatic.com/s/playfair/v2/0nkSC9D7PO4KhmUJ59baVQ_iWhg0cgSrLQZDFpFUsLCFf_1ubkfQeG9KkBAQcOsAs-zcOQJeqycS4zfmNrE.ttf",
//       "600italic": "https://fonts.gstatic.com/s/playfair/v2/0nkSC9D7PO4KhmUJ59baVQ_iWhg0cgSrLQZDFpFUsLCFf_1ubkfQeG9KkBAQcOsAs-zcOe5ZqycS4zfmNrE.ttf",
//       "700italic": "https://fonts.gstatic.com/s/playfair/v2/0nkSC9D7PO4KhmUJ59baVQ_iWhg0cgSrLQZDFpFUsLCFf_1ubkfQeG9KkBAQcOsAs-zcOddZqycS4zfmNrE.ttf",
//       "800italic": "https://fonts.gstatic.com/s/playfair/v2/0nkSC9D7PO4KhmUJ59baVQ_iWhg0cgSrLQZDFpFUsLCFf_1ubkfQeG9KkBAQcOsAs-zcObBZqycS4zfmNrE.ttf",
//       "900italic": "https://fonts.gstatic.com/s/playfair/v2/0nkSC9D7PO4KhmUJ59baVQ_iWhg0cgSrLQZDFpFUsLCFf_1ubkfQeG9KkBAQcOsAs-zcOZlZqycS4zfmNrE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/playfair/v2/0nkQC9D7PO4KhmUJ5_zTZ_4MYQXznAK-TUcZXKO3UMnW6VNpe4-SiiZ4b8h5G3GutPkUeugcqyc.ttf"
//   },
//   {
//     "family": "Sarala",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/sarala/v12/uK_x4riEZv4o1w9ptjI3OtWYVkMpXA.ttf",
//       "regular": "https://fonts.gstatic.com/s/sarala/v12/uK_y4riEZv4o1w9RCh0TMv6EXw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sarala/v12/uK_y4riEZv4o1w9hCxcX.ttf"
//   },
//   {
//     "family": "Black Han Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/blackhansans/v17/ea8Aad44WunzF9a-dL6toA8r8nqVIXSkH-Hc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/blackhansans/v17/ea8Aad44WunzF9a-dL6toA8r8kqUK3A.ttf"
//   },
//   {
//     "family": "Rufina",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/rufina/v15/Yq6W-LyURyLy-aKKHztAvMxenxE0SA.ttf",
//       "regular": "https://fonts.gstatic.com/s/rufina/v15/Yq6V-LyURyLy-aKyoxRktOdClg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rufina/v15/Yq6V-LyURyLy-aKCoh5g.ttf"
//   },
//   {
//     "family": "Actor",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/actor/v17/wEOzEBbCkc5cO3ekXygtUMIO.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/actor/v17/wEOzEBbCkc5cO0elVSw.ttf"
//   },
//   {
//     "family": "Pontano Sans",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-09-27",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/pontanosans/v17/qFdW35GdgYR8EzR6oBLDHa3wyRf8W8eBM6XLOSzMncaMp9gzWsE.ttf",
//       "500": "https://fonts.gstatic.com/s/pontanosans/v17/qFdW35GdgYR8EzR6oBLDHa3wyRf8W8eBM6XLOUDMncaMp9gzWsE.ttf",
//       "600": "https://fonts.gstatic.com/s/pontanosans/v17/qFdW35GdgYR8EzR6oBLDHa3wyRf8W8eBM6XLOazLncaMp9gzWsE.ttf",
//       "700": "https://fonts.gstatic.com/s/pontanosans/v17/qFdW35GdgYR8EzR6oBLDHa3wyRf8W8eBM6XLOZXLncaMp9gzWsE.ttf",
//       "regular": "https://fonts.gstatic.com/s/pontanosans/v17/qFdW35GdgYR8EzR6oBLDHa3wyRf8W8eBM6XLOXLMncaMp9gzWsE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pontanosans/v17/qFdW35GdgYR8EzR6oBLDHa3wyRf8W8eBM6XLOXLMrceGow.ttf"
//   },
//   {
//     "family": "Coda",
//     "variants": [
//       "regular",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "800": "https://fonts.gstatic.com/s/coda/v21/SLXIc1jY5nQ8HeIgTp6mw9t1cX8.ttf",
//       "regular": "https://fonts.gstatic.com/s/coda/v21/SLXHc1jY5nQ8JUIMapaN39I.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/coda/v21/SLXHc1jY5nQ8FUMGbg.ttf"
//   },
//   {
//     "family": "Damion",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/damion/v14/hv-XlzJ3KEUe_YZUbWY3MTFgVg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/damion/v14/hv-XlzJ3KEUe_YZkbGwz.ttf"
//   },
//   {
//     "family": "Rammetto One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rammettoone/v18/LhWiMV3HOfMbMetJG3lQDpp9Mvuciu-_SQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rammettoone/v18/LhWiMV3HOfMbMetJG3lQDppNM_GY.ttf"
//   },
//   {
//     "family": "Pangolin",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pangolin/v11/cY9GfjGcW0FPpi-tWPfK5d3aiLBG.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pangolin/v11/cY9GfjGcW0FPpi-tWMfL79k.ttf"
//   },
//   {
//     "family": "Khula",
//     "variants": [
//       "300",
//       "regular",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-ljCvUrC59XwXD.ttf",
//       "600": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G_RiivUrC59XwXD.ttf",
//       "700": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-1iyvUrC59XwXD.ttf",
//       "800": "https://fonts.gstatic.com/s/khula/v12/OpNPnoEOns3V7G-piCvUrC59XwXD.ttf",
//       "regular": "https://fonts.gstatic.com/s/khula/v12/OpNCnoEOns3V7FcJpA_chzJ0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/khula/v12/OpNCnoEOns3V7GcIrgs.ttf"
//   },
//   {
//     "family": "Quintessential",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/quintessential/v22/fdNn9sOGq31Yjnh3qWU14DdtjY5wS7kmAyxM.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/quintessential/v22/fdNn9sOGq31Yjnh3qWU14Ddtjb5xQb0.ttf"
//   },
//   {
//     "family": "Trocchi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/trocchi/v17/qWcqB6WkuIDxDZLcDrtUvMeTYD0.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/trocchi/v17/qWcqB6WkuIDxDZLcPrpeuA.ttf"
//   },
//   {
//     "family": "Libre Bodoni",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6L9fwWzZcOb3U3s.ttf",
//       "600": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6FNYwWzZcOb3U3s.ttf",
//       "700": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6GpYwWzZcOb3U3s.ttf",
//       "regular": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6I1fwWzZcOb3U3s.ttf",
//       "italic": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUcKS_TdMTyQ3syLg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUcGy_TdMTyQ3syLg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUc9yjTdMTyQ3syLg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm4-H45qDWDYULr5OfyZud9xBKfuwNnnsVZ_UUczijTdMTyQ3syLg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebodoni/v5/_Xm--H45qDWDYULr5OfyZudXzSBgY2oMBGte6I1f8W3TdA.ttf"
//   },
//   {
//     "family": "Hanken Grotesk",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Ncs2da4fpNzXhRKA.ttf",
//       "200": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcM2Za4fpNzXhRKA.ttf",
//       "300": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Nc7WZa4fpNzXhRKA.ttf",
//       "500": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcgWZa4fpNzXhRKA.ttf",
//       "600": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcbWFa4fpNzXhRKA.ttf",
//       "700": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcVGFa4fpNzXhRKA.ttf",
//       "800": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcM2Fa4fpNzXhRKA.ttf",
//       "900": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_NcGmFa4fpNzXhRKA.ttf",
//       "regular": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Ncs2Za4fpNzXhRKA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyWyo_BJ731BKMSK.ttf",
//       "200italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyUyovBJ731BKMSK.ttf",
//       "300italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyXsovBJ731BKMSK.ttf",
//       "italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyWyovBJ731BKMSK.ttf",
//       "500italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyWAovBJ731BKMSK.ttf",
//       "600italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyVspfBJ731BKMSK.ttf",
//       "700italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyVVpfBJ731BKMSK.ttf",
//       "800italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyUypfBJ731BKMSK.ttf",
//       "900italic": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVo2YZDLWuGJpnzaiwFXS9tYtpY_d6T_POl0fRJeyUbpfBJ731BKMSK.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hankengrotesk/v8/ieVq2YZDLWuGJpnzaiwFXS9tYvBRzyFLlZg_f_Ncs2Zq4PBJ.ttf"
//   },
//   {
//     "family": "Italianno",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/italianno/v17/dg4n_p3sv6gCJkwzT6Rnj5YpQwM-gg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/italianno/v17/dg4n_p3sv6gCJkwzT6RXjpwt.ttf"
//   },
//   {
//     "family": "VT323",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2hsYHpT2dkNE.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vt323/v17/pxiKyp0ihIEF2isZFJA.ttf"
//   },
//   {
//     "family": "Alef",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/alef/v21/FeVQS0NQpLYglo50L5la2bxii28.ttf",
//       "regular": "https://fonts.gstatic.com/s/alef/v21/FeVfS0NQpLYgrjJbC5FxxbU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alef/v21/FeVfS0NQpLYgnjNRDw.ttf"
//   },
//   {
//     "family": "Pragati Narrow",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/pragatinarrow/v13/vm8sdRf0T0bS1ffgsPB7WZ-mD2ZD5fd_GJMTlo_4.ttf",
//       "regular": "https://fonts.gstatic.com/s/pragatinarrow/v13/vm8vdRf0T0bS1ffgsPB7WZ-mD17_ytN3M48a.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pragatinarrow/v13/vm8vdRf0T0bS1ffgsPB7WZ-mD27-wNc.ttf"
//   },
//   {
//     "family": "Pinyon Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pinyonscript/v21/6xKpdSJbL9-e9LuoeQiDRQR8aOLQO4bhiDY.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pinyonscript/v21/6xKpdSJbL9-e9LuoeQiDRQR8WOPaPw.ttf"
//   },
//   {
//     "family": "Andika",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2023-05-31",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/andika/v25/mem8Ya6iyW-Lwqg40ZM1UpcaXcl0Aw.ttf",
//       "regular": "https://fonts.gstatic.com/s/andika/v25/mem_Ya6iyW-LwqgAbbwRWrwGVA.ttf",
//       "italic": "https://fonts.gstatic.com/s/andika/v25/mem9Ya6iyW-Lwqgwb7YVeLkWVNBt.ttf",
//       "700italic": "https://fonts.gstatic.com/s/andika/v25/mem6Ya6iyW-Lwqgwb46pV50ef8xkA76a.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/andika/v25/mem_Ya6iyW-LwqgwbLYV.ttf"
//   },
//   {
//     "family": "Noto Sans Malayalam",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "malayalam"
//     ],
//     "version": "v26",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuH9BFzEr6HxEA.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_xuD9BFzEr6HxEA.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_GOD9BFzEr6HxEA.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_dOD9BFzEr6HxEA.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_mOf9BFzEr6HxEA.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_oef9BFzEr6HxEA.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_xuf9BFzEr6HxEA.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_7-f9BFzEr6HxEA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuD9BFzEr6HxEA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmalayalam/v26/sJoi3K5XjsSdcnzn071rL37lpAOsUThnDZIfPdbeSNzVakglNM-Qw8EaeB8Nss-_RuDNBVbA.ttf"
//   },
//   {
//     "family": "Big Shoulders Display",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY86JF46SRP4yZQ.ttf",
//       "200": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdQ87JF46SRP4yZQ.ttf",
//       "300": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YddE7JF46SRP4yZQ.ttf",
//       "500": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0Ydb07JF46SRP4yZQ.ttf",
//       "600": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdVE8JF46SRP4yZQ.ttf",
//       "700": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdWg8JF46SRP4yZQ.ttf",
//       "800": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdQ88JF46SRP4yZQ.ttf",
//       "900": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdSY8JF46SRP4yZQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY87JF46SRP4yZQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigshouldersdisplay/v21/fC1MPZJEZG-e9gHhdI4-NBbfd2ys3SjJCx12wPgf9g-_3F0YdY87FF8wTQ.ttf"
//   },
//   {
//     "family": "Shrikhand",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shrikhand/v15/a8IbNovtLWfR7T7bMJwbBIiQ0zhMtA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shrikhand/v15/a8IbNovtLWfR7T7bMJwrBYKU.ttf"
//   },
//   {
//     "family": "Fredoka",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-04-04",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/fredoka/v14/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OryLMFuOLlNldbw.ttf",
//       "500": "https://fonts.gstatic.com/s/fredoka/v14/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OwyLMFuOLlNldbw.ttf",
//       "600": "https://fonts.gstatic.com/s/fredoka/v14/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OLyXMFuOLlNldbw.ttf",
//       "700": "https://fonts.gstatic.com/s/fredoka/v14/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3OFiXMFuOLlNldbw.ttf",
//       "regular": "https://fonts.gstatic.com/s/fredoka/v14/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3O8SLMFuOLlNldbw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fredoka/v14/X7nP4b87HvSqjb_WIi2yDCRwoQ_k7367_B-i2yQag0-mac3O8SL8F-mP.ttf"
//   },
//   {
//     "family": "Jaldi",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/jaldi/v12/or3hQ67z0_CI33voSbT3LLQ1niPn.ttf",
//       "regular": "https://fonts.gstatic.com/s/jaldi/v12/or3sQ67z0_CI30NUZpD_B6g8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jaldi/v12/or3sQ67z0_CI33NVbJQ.ttf"
//   },
//   {
//     "family": "Amita",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/amita/v18/HhyXU5si9Om7PTHTLtCCOopCTKkI.ttf",
//       "regular": "https://fonts.gstatic.com/s/amita/v18/HhyaU5si9Om7PQlvAfSKEZZL.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amita/v18/HhyaU5si9Om7PTluC_A.ttf"
//   },
//   {
//     "family": "Noto Sans Hebrew",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v43",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4utoiJltutR2g.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiX94qtoiJltutR2g.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXKYqtoiJltutR2g.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXRYqtoiJltutR2g.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXqY2toiJltutR2g.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXkI2toiJltutR2g.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiX942toiJltutR2g.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiX3o2toiJltutR2g.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4qtoiJltutR2g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanshebrew/v43/or3HQ7v33eiDljA1IufXTtVf7V6RvEEdhQlk0LlGxCyaeNKYZC0sqk3xXGiXd4qdoyhh.ttf"
//   },
//   {
//     "family": "Averia Serif Libre",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGCSmqwacqdrKvbQ.ttf",
//       "700": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIVzD2ms4wxr6GvjeD0X88SHPyX2xYGGS6qwacqdrKvbQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzMmw60uVLe_bXHq.ttf",
//       "regular": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIWzD2ms4wxr6GvjeD0X88SHPyX2xY-pQGOyYw2fw.ttf",
//       "italic": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIUzD2ms4wxr6GvjeD0X88SHPyX2xYOpwuK64kmf6u2.ttf",
//       "700italic": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIbzD2ms4wxr6GvjeD0X88SHPyX2xYOpzM2xK0uVLe_bXHq.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/averiaseriflibre/v18/neIWzD2ms4wxr6GvjeD0X88SHPyX2xYOpAuK.ttf"
//   },
//   {
//     "family": "Leckerli One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/leckerlione/v20/V8mCoQH8VCsNttEnxnGQ-1itLZxcBtItFw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/leckerlione/v20/V8mCoQH8VCsNttEnxnGQ-1idLJZY.ttf"
//   },
//   {
//     "family": "Kreon",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v37",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/kreon/v37/t5t9IRIUKY-TFF_LW5lnMR3v2DnvPNimejUfp2dWNg.ttf",
//       "500": "https://fonts.gstatic.com/s/kreon/v37/t5t9IRIUKY-TFF_LW5lnMR3v2DnvUNimejUfp2dWNg.ttf",
//       "600": "https://fonts.gstatic.com/s/kreon/v37/t5t9IRIUKY-TFF_LW5lnMR3v2DnvvN-mejUfp2dWNg.ttf",
//       "700": "https://fonts.gstatic.com/s/kreon/v37/t5t9IRIUKY-TFF_LW5lnMR3v2Dnvhd-mejUfp2dWNg.ttf",
//       "regular": "https://fonts.gstatic.com/s/kreon/v37/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtimejUfp2dWNg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kreon/v37/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtiWez8b.ttf"
//   },
//   {
//     "family": "Telex",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/telex/v17/ieVw2Y1fKWmIO9fTB1piKFIf.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/telex/v17/ieVw2Y1fKWmIO-fSDV4.ttf"
//   },
//   {
//     "family": "Changa One",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/changaone/v20/xfu00W3wXn3QLUJXhzq46AbouLfbK64.ttf",
//       "italic": "https://fonts.gstatic.com/s/changaone/v20/xfu20W3wXn3QLUJXhzq42ATivJXeO67ISw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/changaone/v20/xfu00W3wXn3QLUJXhzq42AfivA.ttf"
//   },
//   {
//     "family": "Sansita",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/sansita/v11/QldLNTRRphEb_-V7JKWUaXl0wqVv3_g.ttf",
//       "800": "https://fonts.gstatic.com/s/sansita/v11/QldLNTRRphEb_-V7JLmXaXl0wqVv3_g.ttf",
//       "900": "https://fonts.gstatic.com/s/sansita/v11/QldLNTRRphEb_-V7JJ2WaXl0wqVv3_g.ttf",
//       "regular": "https://fonts.gstatic.com/s/sansita/v11/QldONTRRphEb_-V7HBm7TXFf3qw.ttf",
//       "italic": "https://fonts.gstatic.com/s/sansita/v11/QldMNTRRphEb_-V7LBuxSVNazqx2xg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sansita/v11/QldJNTRRphEb_-V7LBuJ9Xx-xodqz_joDQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sansita/v11/QldJNTRRphEb_-V7LBuJ6X9-xodqz_joDQ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sansita/v11/QldJNTRRphEb_-V7LBuJzX5-xodqz_joDQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sansita/v11/QldONTRRphEb_-V7LBixSQ.ttf"
//   },
//   {
//     "family": "Economica",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/economica/v15/Qw3aZQZaHCLgIWa29ZBTjeckCnZ5dHw8iw.ttf",
//       "regular": "https://fonts.gstatic.com/s/economica/v15/Qw3fZQZaHCLgIWa29ZBrMcgAAl1lfQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/economica/v15/Qw3ZZQZaHCLgIWa29ZBbM8IEIFh1fWUl.ttf",
//       "700italic": "https://fonts.gstatic.com/s/economica/v15/Qw3EZQZaHCLgIWa29ZBbM_q4D3x9Vnksi4M7.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/economica/v15/Qw3fZQZaHCLgIWa29ZBbMMIE.ttf"
//   },
//   {
//     "family": "Holtwood One SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/holtwoodonesc/v20/yYLx0hLR0P-3vMFSk1TCq3Txg5B3cbb6LZttyg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/holtwoodonesc/v20/yYLx0hLR0P-3vMFSk1TCq3Txg5BHcLz-.ttf"
//   },
//   {
//     "family": "Racing Sans One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/racingsansone/v15/sykr-yRtm7EvTrXNxkv5jfKKyDCwL3rmWpIBtA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/racingsansone/v15/sykr-yRtm7EvTrXNxkv5jfKKyDCALnDi.ttf"
//   },
//   {
//     "family": "Allerta Stencil",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/allertastencil/v22/HTx0L209KT-LmIE9N7OR6eiycOeF-zz313DuvQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/allertastencil/v22/HTx0L209KT-LmIE9N7OR6eiycOe1-jbz.ttf"
//   },
//   {
//     "family": "Days One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/daysone/v18/mem9YaCnxnKRiYZOCLYVeLkWVNBt.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/daysone/v18/mem9YaCnxnKRiYZOCIYUcr0.ttf"
//   },
//   {
//     "family": "Caveat Brush",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/caveatbrush/v11/EYq0maZfwr9S9-ETZc3fKXtMW7mT03pdQw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/caveatbrush/v11/EYq0maZfwr9S9-ETZc3fKXt8WrOX.ttf"
//   },
//   {
//     "family": "Saira Extra Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFsOHYr-vcC7h8MklGBkrvmUG9rbpkisrTri0jx9i5ss3a3.ttf",
//       "200": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrJ2nR3ABgum-uoQ.ttf",
//       "300": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrQ2rR3ABgum-uoQ.ttf",
//       "500": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrG2vR3ABgum-uoQ.ttf",
//       "600": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrN2zR3ABgum-uoQ.ttf",
//       "700": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrU23R3ABgum-uoQ.ttf",
//       "800": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTrT27R3ABgum-uoQ.ttf",
//       "900": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFvOHYr-vcC7h8MklGBkrvmUG9rbpkisrTra2_R3ABgum-uoQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTT70L11Ct8sw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sairaextracondensed/v13/-nFiOHYr-vcC7h8MklGBkrvmUG9rbpkisrTj7kjx.ttf"
//   },
//   {
//     "family": "Reem Kufi",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/reemkufi/v21/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQttRnEGGf3qGuvM4.ttf",
//       "600": "https://fonts.gstatic.com/s/reemkufi/v21/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtjhgEGGf3qGuvM4.ttf",
//       "700": "https://fonts.gstatic.com/s/reemkufi/v21/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtgFgEGGf3qGuvM4.ttf",
//       "regular": "https://fonts.gstatic.com/s/reemkufi/v21/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtuZnEGGf3qGuvM4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/reemkufi/v21/2sDPZGJLip7W2J7v7wQZZE1I0yCmYzzQtuZnIGCV2g.ttf"
//   },
//   {
//     "family": "Rubik Moonrocks",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikmoonrocks/v5/845ANMAmAI2VUZMLu_W0M7HqlDHnXcD7JGy1Sw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikmoonrocks/v5/845ANMAmAI2VUZMLu_W0M7HqlDHXXMr_.ttf"
//   },
//   {
//     "family": "Petrona",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v32",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsARBH452Mvds.ttf",
//       "200": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4NsQRBH452Mvds.ttf",
//       "300": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk7TsQRBH452Mvds.ttf",
//       "500": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6_sQRBH452Mvds.ttf",
//       "600": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk5TtgRBH452Mvds.ttf",
//       "700": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk5qtgRBH452Mvds.ttf",
//       "800": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4NtgRBH452Mvds.ttf",
//       "900": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk4ktgRBH452Mvds.ttf",
//       "regular": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsQRBH452Mvds.ttf",
//       "100italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8uwDFYpUN-dsIWs.ttf",
//       "200italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8mwCFYpUN-dsIWs.ttf",
//       "300italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8rICFYpUN-dsIWs.ttf",
//       "italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8uwCFYpUN-dsIWs.ttf",
//       "500italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8t4CFYpUN-dsIWs.ttf",
//       "600italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8jIFFYpUN-dsIWs.ttf",
//       "700italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8gsFFYpUN-dsIWs.ttf",
//       "800italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8mwFFYpUN-dsIWs.ttf",
//       "900italic": "https://fonts.gstatic.com/s/petrona/v32/mtGr4_NXL7bZo9XXgXdCu2vkCLkNEVtF8kUFFYpUN-dsIWs.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/petrona/v32/mtGl4_NXL7bZo9XXq35wRLONYyOjFk6NsTRAFYo.ttf"
//   },
//   {
//     "family": "Sintony",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/sintony/v15/XoHj2YDqR7-98cVUGYgIn9cDkjLp6C8.ttf",
//       "regular": "https://fonts.gstatic.com/s/sintony/v15/XoHm2YDqR7-98cVUITQnu98ojjs.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sintony/v15/XoHm2YDqR7-98cVUETUtvw.ttf"
//   },
//   {
//     "family": "Oxanium",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G83JfniMBXQ7d67x.ttf",
//       "300": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G80XfniMBXQ7d67x.ttf",
//       "500": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G817fniMBXQ7d67x.ttf",
//       "600": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G82XeXiMBXQ7d67x.ttf",
//       "700": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G82ueXiMBXQ7d67x.ttf",
//       "800": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G83JeXiMBXQ7d67x.ttf",
//       "regular": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G81JfniMBXQ7d67x.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oxanium/v19/RrQPboN_4yJ0JmiMUW7sIGjd1IA9G81JfkiND3A.ttf"
//   },
//   {
//     "family": "K2D",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE6UpvrIw74NL.ttf",
//       "200": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Erv4QJlJw85ppSGw.ttf",
//       "300": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Er24cJlJw85ppSGw.ttf",
//       "500": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Erg4YJlJw85ppSGw.ttf",
//       "600": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Err4EJlJw85ppSGw.ttf",
//       "700": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Ery4AJlJw85ppSGw.ttf",
//       "800": "https://fonts.gstatic.com/s/k2d/v11/J7aenpF2V0Er14MJlJw85ppSGw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/k2d/v11/J7afnpF2V0EjdZ1NtLYS6pNLAjk.ttf",
//       "200italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ3hlZY4xJ9CGyAa.ttf",
//       "300italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ2FlpY4xJ9CGyAa.ttf",
//       "regular": "https://fonts.gstatic.com/s/k2d/v11/J7aTnpF2V0ETd68tnLcg7w.ttf",
//       "italic": "https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0EjdaUpvrIw74NL.ttf",
//       "500italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ3dl5Y4xJ9CGyAa.ttf",
//       "600italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ3xkJY4xJ9CGyAa.ttf",
//       "700italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ2VkZY4xJ9CGyAa.ttf",
//       "800italic": "https://fonts.gstatic.com/s/k2d/v11/J7acnpF2V0EjdZ2JkpY4xJ9CGyAa.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/k2d/v11/J7aTnpF2V0EjdqUp.ttf"
//   },
//   {
//     "family": "Palanquin",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/palanquin/v13/9XUhlJ90n1fBFg7ceXwUEltI7rWmZzTH.ttf",
//       "200": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUvnpoxJuqbi3ezg.ttf",
//       "300": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwU2nloxJuqbi3ezg.ttf",
//       "500": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUgnhoxJuqbi3ezg.ttf",
//       "600": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUrn9oxJuqbi3ezg.ttf",
//       "700": "https://fonts.gstatic.com/s/palanquin/v13/9XUilJ90n1fBFg7ceXwUyn5oxJuqbi3ezg.ttf",
//       "regular": "https://fonts.gstatic.com/s/palanquin/v13/9XUnlJ90n1fBFg7ceXwsdlFMzLC2Zw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/palanquin/v13/9XUnlJ90n1fBFg7ceXwcd1tI.ttf"
//   },
//   {
//     "family": "Calistoga",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/calistoga/v15/6NUU8F2OJg6MeR7l4e0vtMYAwdRZfw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/calistoga/v15/6NUU8F2OJg6MeR7l4e0ftcwE.ttf"
//   },
//   {
//     "family": "Charm",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/charm/v11/7cHrv4oii5K0Md6TDss8yn4hnCci.ttf",
//       "regular": "https://fonts.gstatic.com/s/charm/v11/7cHmv4oii5K0MeYvIe804WIo.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/charm/v11/7cHmv4oii5K0MdYuK-s.ttf"
//   },
//   {
//     "family": "Laila",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLzxogNAh14nVcfe.ttf",
//       "500": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLypowNAh14nVcfe.ttf",
//       "600": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLyFpANAh14nVcfe.ttf",
//       "700": "https://fonts.gstatic.com/s/laila/v15/LYjBdG_8nE8jDLzhpQNAh14nVcfe.ttf",
//       "regular": "https://fonts.gstatic.com/s/laila/v15/LYjMdG_8nE8jDIRdiidIrEIu.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/laila/v15/LYjMdG_8nE8jDLRcgCM.ttf"
//   },
//   {
//     "family": "IBM Plex Sans Thai",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "thai"
//     ],
//     "version": "v10",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JNje1VVIzcq1HzJq2AEdo2Tj_qvLqEatYlR8ZKUqcX.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqExvcFbehGW74OXw.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqEovQFbehGW74OXw.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqE-vUFbehGW74OXw.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqE1vIFbehGW74OXw.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JMje1VVIzcq1HzJq2AEdo2Tj_qvLqEsvMFbehGW74OXw.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JPje1VVIzcq1HzJq2AEdo2Tj_qvLq8DtwhZcNaUg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsansthai/v10/m8JPje1VVIzcq1HzJq2AEdo2Tj_qvLqMD9Yl.ttf"
//   },
//   {
//     "family": "Hind Guntur",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "telugu"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_yGn1czn9zaj5Ju.ttf",
//       "500": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_zenlczn9zaj5Ju.ttf",
//       "600": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_zymVczn9zaj5Ju.ttf",
//       "700": "https://fonts.gstatic.com/s/hindguntur/v12/wXKyE3UZrok56nvamSuJd_yWmFczn9zaj5Ju.ttf",
//       "regular": "https://fonts.gstatic.com/s/hindguntur/v12/wXKvE3UZrok56nvamSuJd8Qqt3M7tMDT.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hindguntur/v12/wXKvE3UZrok56nvamSuJd_QrvXc.ttf"
//   },
//   {
//     "family": "Mrs Saint Delafield",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mrssaintdelafield/v13/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62cK4tLsubB2w.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mrssaintdelafield/v13/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62QK8nKg.ttf"
//   },
//   {
//     "family": "Rye",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rye/v15/r05XGLJT86YDFpTsXOqx4w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rye/v15/r05XGLJT86YzF57o.ttf"
//   },
//   {
//     "family": "Aclonica",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/aclonica/v22/K2FyfZJVlfNNSEBXGb7TCI6oBjLz.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aclonica/v22/K2FyfZJVlfNNSEBXGY7SAoo.ttf"
//   },
//   {
//     "family": "Cabin Sketch",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/cabinsketch/v21/QGY2z_kZZAGCONcK2A4bGOj0I_1o4dLyI4CMFw.ttf",
//       "regular": "https://fonts.gstatic.com/s/cabinsketch/v21/QGYpz_kZZAGCONcK2A4bGOjMn9JM6fnuKg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cabinsketch/v21/QGYpz_kZZAGCONcK2A4bGOj8nthI.ttf"
//   },
//   {
//     "family": "Syncopate",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/syncopate/v21/pe0pMIuPIYBCpEV5eFdKvtKaA_Rue1UwVg.ttf",
//       "regular": "https://fonts.gstatic.com/s/syncopate/v21/pe0sMIuPIYBCpEV5eFdyAv2-C99ycg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/syncopate/v21/pe0sMIuPIYBCpEV5eFdCA_e6.ttf"
//   },
//   {
//     "family": "Karma",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLjDY8Z_uqzGQC_-.ttf",
//       "500": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLibYsZ_uqzGQC_-.ttf",
//       "600": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLi3ZcZ_uqzGQC_-.ttf",
//       "700": "https://fonts.gstatic.com/s/karma/v16/va9F4kzAzMZRGLjTZMZ_uqzGQC_-.ttf",
//       "regular": "https://fonts.gstatic.com/s/karma/v16/va9I4kzAzMZRGIBvS-J3kbDP.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/karma/v16/va9I4kzAzMZRGLBuQeY.ttf"
//   },
//   {
//     "family": "Marcellus SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLEPgdydGKikhA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/marcellussc/v13/ke8iOgUHP1dg-Rmi6RWjbLE_gNaZ.ttf"
//   },
//   {
//     "family": "Voltaire",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/voltaire/v20/1Pttg8PcRfSblAvGvQooYKVnBOif.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/voltaire/v20/1Pttg8PcRfSblAvGvTopaqE.ttf"
//   },
//   {
//     "family": "Mali",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2023-05-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QOLlKlRaJdbWgdY.ttf",
//       "300": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QIbmKlRaJdbWgdY.ttf",
//       "500": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QN7nKlRaJdbWgdY.ttf",
//       "600": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QPLgKlRaJdbWgdY.ttf",
//       "700": "https://fonts.gstatic.com/s/mali/v10/N0bV2SRONuN4QJbhKlRaJdbWgdY.ttf",
//       "200italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8wlVQIfTTkdbJYA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8plZQIfTTkdbJYA.ttf",
//       "regular": "https://fonts.gstatic.com/s/mali/v10/N0ba2SRONuN4eCrODlxxOd8.ttf",
//       "italic": "https://fonts.gstatic.com/s/mali/v10/N0bU2SRONuN4SCjECn50Kd_PmA.ttf",
//       "500italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8_ldQIfTTkdbJYA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj80lBQIfTTkdbJYA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/mali/v10/N0bX2SRONuN4SCj8tlFQIfTTkdbJYA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mali/v10/N0ba2SRONuN4SCvECg.ttf"
//   },
//   {
//     "family": "Berkshire Swash",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/berkshireswash/v20/ptRRTi-cavZOGqCvnNJDl5m5XmNPrcQybX4pQA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/berkshireswash/v20/ptRRTi-cavZOGqCvnNJDl5m5XmN_rM42.ttf"
//   },
//   {
//     "family": "Alatsi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alatsi/v11/TK3iWkUJAxQ2nLNGHjUHte5fKg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alatsi/v11/TK3iWkUJAxQ2nLN2Hz8D.ttf"
//   },
//   {
//     "family": "Krona One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kronaone/v14/jAnEgHdjHcjgfIb1ZcUCMY-h3cWkWg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kronaone/v14/jAnEgHdjHcjgfIb1ZcUyMIWl.ttf"
//   },
//   {
//     "family": "Cormorant Infant",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN9951w3_DMrQqcdJrk.ttf",
//       "500": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN995wQ2_DMrQqcdJrk.ttf",
//       "600": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN995ygx_DMrQqcdJrk.ttf",
//       "700": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyIU44g9vKiM1sORYSiWeAsLN9950ww_DMrQqcdJrk.ttf",
//       "300italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItcDEhRoUYNrn_Ig.ttf",
//       "regular": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyPU44g9vKiM1sORYSiWeAsLN993_Af2DsAXq4.ttf",
//       "italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyJU44g9vKiM1sORYSiWeAsLN997_IV3BkFTq4EPw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItKDAhRoUYNrn_Ig.ttf",
//       "600italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItBDchRoUYNrn_Ig.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyKU44g9vKiM1sORYSiWeAsLN997_ItYDYhRoUYNrn_Ig.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cormorantinfant/v17/HhyPU44g9vKiM1sORYSiWeAsLN997_EV3A.ttf"
//   },
//   {
//     "family": "Glegoo",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/glegoo/v16/_Xmu-HQyrTKWaw2xN4a9CKRpzimMsg.ttf",
//       "regular": "https://fonts.gstatic.com/s/glegoo/v16/_Xmt-HQyrTKWaw2Ji6mZAI91xw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/glegoo/v16/_Xmt-HQyrTKWaw25iqOd.ttf"
//   },
//   {
//     "family": "Anuphan",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCkY9A4kGmW927Gu.ttf",
//       "200": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCmY9Q4kGmW927Gu.ttf",
//       "300": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZClG9Q4kGmW927Gu.ttf",
//       "500": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCkq9Q4kGmW927Gu.ttf",
//       "600": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCnG8g4kGmW927Gu.ttf",
//       "700": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCn_8g4kGmW927Gu.ttf",
//       "regular": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCkY9Q4kGmW927Gu.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anuphan/v3/2sDBZGxYgY7LkLT0s2Yrm5UhuLoIZCkY9T4lEGE.ttf"
//   },
//   {
//     "family": "Yrsa",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/yrsa/v20/wlprgwnQFlxs_wD3CFSMYmFaaCjASNNV9rRPfrKu.ttf",
//       "500": "https://fonts.gstatic.com/s/yrsa/v20/wlprgwnQFlxs_wD3CFSMYmFaaCisSNNV9rRPfrKu.ttf",
//       "600": "https://fonts.gstatic.com/s/yrsa/v20/wlprgwnQFlxs_wD3CFSMYmFaaChAT9NV9rRPfrKu.ttf",
//       "700": "https://fonts.gstatic.com/s/yrsa/v20/wlprgwnQFlxs_wD3CFSMYmFaaCh5T9NV9rRPfrKu.ttf",
//       "regular": "https://fonts.gstatic.com/s/yrsa/v20/wlprgwnQFlxs_wD3CFSMYmFaaCieSNNV9rRPfrKu.ttf",
//       "300italic": "https://fonts.gstatic.com/s/yrsa/v20/wlptgwnQFlxs1QnF94zlCfv0bz1WC2UW_LBte6KuGEo.ttf",
//       "italic": "https://fonts.gstatic.com/s/yrsa/v20/wlptgwnQFlxs1QnF94zlCfv0bz1WCzsW_LBte6KuGEo.ttf",
//       "500italic": "https://fonts.gstatic.com/s/yrsa/v20/wlptgwnQFlxs1QnF94zlCfv0bz1WCwkW_LBte6KuGEo.ttf",
//       "600italic": "https://fonts.gstatic.com/s/yrsa/v20/wlptgwnQFlxs1QnF94zlCfv0bz1WC-UR_LBte6KuGEo.ttf",
//       "700italic": "https://fonts.gstatic.com/s/yrsa/v20/wlptgwnQFlxs1QnF94zlCfv0bz1WC9wR_LBte6KuGEo.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yrsa/v20/wlprgwnQFlxs_wD3CFSMYmFaaCieSONU_LA.ttf"
//   },
//   {
//     "family": "Cinzel Decorative",
//     "variants": [
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaHSScvJGqLYhG8nNt8KPPswUAPniZoaelDQzCLlQXE.ttf",
//       "900": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaHSScvJGqLYhG8nNt8KPPswUAPniZQa-lDQzCLlQXE.ttf",
//       "regular": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaCSScvJGqLYhG8nNt8KPPswUAPnh7URs1LaCyC.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cinzeldecorative/v16/daaCSScvJGqLYhG8nNt8KPPswUAPni7VTMk.ttf"
//   },
//   {
//     "family": "Lemonada",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/lemonada/v28/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGJOt2mfWc3Z2pTg.ttf",
//       "500": "https://fonts.gstatic.com/s/lemonada/v28/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGSOt2mfWc3Z2pTg.ttf",
//       "600": "https://fonts.gstatic.com/s/lemonada/v28/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGpOx2mfWc3Z2pTg.ttf",
//       "700": "https://fonts.gstatic.com/s/lemonada/v28/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGnex2mfWc3Z2pTg.ttf",
//       "regular": "https://fonts.gstatic.com/s/lemonada/v28/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeut2mfWc3Z2pTg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lemonada/v28/0QI-MXFD9oygTWy_R-FFlwV-bgfR7QJGeutGmP-Y.ttf"
//   },
//   {
//     "family": "Armata",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/armata/v20/gokvH63_HV5jQ-E9lD53Q2u_mQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/armata/v20/gokvH63_HV5jQ-ENlTRz.ttf"
//   },
//   {
//     "family": "Chewy",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wk5xIDMfO-ed.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wn5wKjc.ttf"
//   },
//   {
//     "family": "Michroma",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/michroma/v19/PN_zRfy9qWD8fEagAMg6rzjb_-Da.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/michroma/v19/PN_zRfy9qWD8fEagAPg7pTw.ttf"
//   },
//   {
//     "family": "Sofia Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69B6sa3trvKCXl8k.ttf",
//       "200": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69D6sK3trvKCXl8k.ttf",
//       "300": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69AksK3trvKCXl8k.ttf",
//       "500": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69BIsK3trvKCXl8k.ttf",
//       "600": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69Ckt63trvKCXl8k.ttf",
//       "700": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69Cdt63trvKCXl8k.ttf",
//       "800": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69D6t63trvKCXl8k.ttf",
//       "900": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69DTt63trvKCXl8k.ttf",
//       "regular": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69B6sK3trvKCXl8k.ttf",
//       "100italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy80WvpPagW08kdLY.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy88WupPagW08kdLY.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy8xuupPagW08kdLY.ttf",
//       "italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy80WupPagW08kdLY.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy83eupPagW08kdLY.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy85uppPagW08kdLY.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy86KppPagW08kdLY.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy88WppPagW08kdLY.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6G-LCVXSLy9uPBwlATrORgnBjYmSP97MWy8-yppPagW08kdLY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sofiasans/v16/Yq6E-LCVXSLy9uPBwlAThu1SY8Cx8rlT69B6sJ3spPY.ttf"
//   },
//   {
//     "family": "Arapey",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/arapey/v16/-W__XJn-UDDA2RC6Z9AcZkIzeg.ttf",
//       "italic": "https://fonts.gstatic.com/s/arapey/v16/-W_9XJn-UDDA2RCKZdoYREcjeo0k.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arapey/v16/-W__XJn-UDDA2RCKZtoY.ttf"
//   },
//   {
//     "family": "Zen Old Mincho",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-01-06",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb4Dqlla8dMgPgBu.ttf",
//       "600": "https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb4vrVla8dMgPgBu.ttf",
//       "700": "https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb5LrFla8dMgPgBu.ttf",
//       "900": "https://fonts.gstatic.com/s/zenoldmincho/v11/tss3ApVaYytLwxTqcxfMyBveyb5zrlla8dMgPgBu.ttf",
//       "regular": "https://fonts.gstatic.com/s/zenoldmincho/v11/tss0ApVaYytLwxTqcxfMyBveyYb3g31S2s8p.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenoldmincho/v11/tss0ApVaYytLwxTqcxfMyBveybb2iXk.ttf"
//   },
//   {
//     "family": "Shadows Into Light Two",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shadowsintolighttwo/v17/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmlAvNGLNnIF0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shadowsintolighttwo/v17/4iC86LVlZsRSjQhpWGedwyOoW-0A6_kpsyNmpArHHA.ttf"
//   },
//   {
//     "family": "Candal",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8cNAR4Jt9Yxlw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/candal/v15/XoHn2YH6T7-t_8c9ABQN.ttf"
//   },
//   {
//     "family": "PT Serif Caption",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ptserifcaption/v17/ieVl2ZhbGCW-JoW6S34pSDpqYKU059WxDCs5cvI.ttf",
//       "italic": "https://fonts.gstatic.com/s/ptserifcaption/v17/ieVj2ZhbGCW-JoW6S34pSDpqYKU019e7CAk8YvJEeg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ptserifcaption/v17/ieVl2ZhbGCW-JoW6S34pSDpqYKU019S7CA.ttf"
//   },
//   {
//     "family": "Athiti",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAxDNyAv2-C99ycg.ttf",
//       "300": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAoDByAv2-C99ycg.ttf",
//       "500": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wA-DFyAv2-C99ycg.ttf",
//       "600": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wA1DZyAv2-C99ycg.ttf",
//       "700": "https://fonts.gstatic.com/s/athiti/v12/pe0sMISdLIZIv1wAsDdyAv2-C99ycg.ttf",
//       "regular": "https://fonts.gstatic.com/s/athiti/v12/pe0vMISdLIZIv1w4DBhWCtaiAg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/athiti/v12/pe0vMISdLIZIv1wIDRJS.ttf"
//   },
//   {
//     "family": "Sofia Sans Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-02-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqh-Csl8QO3OfwQQ.ttf",
//       "200": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqB-Gsl8QO3OfwQQ.ttf",
//       "300": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUq2eGsl8QO3OfwQQ.ttf",
//       "500": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqteGsl8QO3OfwQQ.ttf",
//       "600": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqWeasl8QO3OfwQQ.ttf",
//       "700": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqYOasl8QO3OfwQQ.ttf",
//       "800": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqB-asl8QO3OfwQQ.ttf",
//       "900": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqLuasl8QO3OfwQQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqh-Gsl8QO3OfwQQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6JE1c4K_uLgQZ_3.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6LE1M4K_uLgQZ_3.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Ia1M4K_uLgQZ_3.ttf",
//       "italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6JE1M4K_uLgQZ_3.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6J21M4K_uLgQZ_3.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Ka084K_uLgQZ_3.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Kj084K_uLgQZ_3.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6LE084K_uLgQZ_3.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r053GKVS5aVKd567NYXawnFKJaTtoAuLnIcNvN_Vj6TYyQI_T6Lt084K_uLgQZ_3.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sofiasanscondensed/v2/r05xGKVS5aVKd567NYXawnFKJaTtoAuLnK0EjiAN5s9CZwUqh-Gcls4K.ttf"
//   },
//   {
//     "family": "Julee",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/julee/v25/TuGfUVB3RpZPQ6ZLodgzydtk.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/julee/v25/TuGfUVB3RpZPQ5ZKq9w.ttf"
//   },
//   {
//     "family": "Aldrich",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/aldrich/v21/MCoTzAn-1s3IGyJMZaAS3pP5H_E.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aldrich/v21/MCoTzAn-1s3IGyJMVaEY2g.ttf"
//   },
//   {
//     "family": "Pathway Extreme",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2023-04-20",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xak2Nx1Kyw3igP5eg.ttf",
//       "200": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xakWN11Kyw3igP5eg.ttf",
//       "300": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xakht11Kyw3igP5eg.ttf",
//       "500": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xak6t11Kyw3igP5eg.ttf",
//       "600": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xakBtp1Kyw3igP5eg.ttf",
//       "700": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xakP9p1Kyw3igP5eg.ttf",
//       "800": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xakWNp1Kyw3igP5eg.ttf",
//       "900": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xakcdp1Kyw3igP5eg.ttf",
//       "regular": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xak2N11Kyw3igP5eg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ6daSYzqAbpepnF.ttf",
//       "200italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ4daCYzqAbpepnF.ttf",
//       "300italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ7DaCYzqAbpepnF.ttf",
//       "italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ6daCYzqAbpepnF.ttf",
//       "500italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ6vaCYzqAbpepnF.ttf",
//       "600italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ5DbyYzqAbpepnF.ttf",
//       "700italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ56byYzqAbpepnF.ttf",
//       "800italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ4dbyYzqAbpepnF.ttf",
//       "900italic": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI4zCC3pJ0rsaH2_sD-QttXPfDlq0kVrdFsAHYoa7O3LCjRa7zISmmvKDxFz3m_CdF3-dIqTRGxEJ40byYzqAbpepnF.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pathwayextreme/v3/neI6zCC3pJ0rsaH2_sD-QttXPfDPonvkQ-pxx5gufvP2VmLjiFyxGf8BLymNjYv2Oy6vkLmw4xak2N1FKiYz.ttf"
//   },
//   {
//     "family": "Dela Gothic One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek",
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/delagothicone/v15/hESp6XxvMDRA-2eD0lXpDa6QkBAGRUsJQAlbUA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/delagothicone/v15/hESp6XxvMDRA-2eD0lXpDa6QkBA2REEN.ttf"
//   },
//   {
//     "family": "La Belle Aurore",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/labelleaurore/v20/RrQIbot8-mNYKnGNDkWlocovHeIIG-eFNVmULg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/labelleaurore/v20/RrQIbot8-mNYKnGNDkWlocovHeI4Gu2B.ttf"
//   },
//   {
//     "family": "Cedarville Cursive",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_neJbBxw8BeTg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_nuJLp1.ttf"
//   },
//   {
//     "family": "Antic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/antic/v19/TuGfUVB8XY5DRaZLodgzydtk.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/antic/v19/TuGfUVB8XY5DRZZKq9w.ttf"
//   },
//   {
//     "family": "Just Another Hand",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/justanotherhand/v19/845CNN4-AJyIGvIou-6yJKyptyOpOcr_BmmlS5aw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/justanotherhand/v19/845CNN4-AJyIGvIou-6yJKyptyOpOfr-DG0.ttf"
//   },
//   {
//     "family": "Alexandria",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7T6bHHJ8BRq0b.ttf",
//       "200": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9p7TqbHHJ8BRq0b.ttf",
//       "300": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9qlTqbHHJ8BRq0b.ttf",
//       "500": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9rJTqbHHJ8BRq0b.ttf",
//       "600": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9olSabHHJ8BRq0b.ttf",
//       "700": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9ocSabHHJ8BRq0b.ttf",
//       "800": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9p7SabHHJ8BRq0b.ttf",
//       "900": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9pSSabHHJ8BRq0b.ttf",
//       "regular": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7TqbHHJ8BRq0b.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alexandria/v3/UMBCrPdDqW66y0Y2usFeQCH18mulUxBvI9r7TpbGFps.ttf"
//   },
//   {
//     "family": "Squada One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/squadaone/v18/BCasqZ8XsOrx4mcOk6MtWaA8WDBkHgs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/squadaone/v18/BCasqZ8XsOrx4mcOk6MtaaE2XA.ttf"
//   },
//   {
//     "family": "Balsamiq Sans",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-10-19",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sZzZiAbNrN8SB3lQQX7PncyWUyBY9mAzLFRQI.ttf",
//       "regular": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sEzZiAbNrN8SB3lQQX7Pnc8dkdIYdNHzs.ttf",
//       "italic": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sazZiAbNrN8SB3lQQX7PncwdsXJaVIDzvcXA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sfzZiAbNrN8SB3lQQX7PncwdsvmYpsBxDAVQI4aA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/balsamiqsans/v14/P5sEzZiAbNrN8SB3lQQX7PncwdgXJQ.ttf"
//   },
//   {
//     "family": "Cantata One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cantataone/v15/PlI5Fl60Nb5obNzNe2jslVxEt8CwfGaD.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cantataone/v15/PlI5Fl60Nb5obNzNe2jslWxFvcQ.ttf"
//   },
//   {
//     "family": "Noto Serif Display",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd49gKaDU9hvzC.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVrd4tgKaDU9hvzC.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVoD4tgKaDU9hvzC.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpv4tgKaDU9hvzC.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVqD5dgKaDU9hvzC.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVq65dgKaDU9hvzC.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVrd5dgKaDU9hvzC.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVr05dgKaDU9hvzC.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4tgKaDU9hvzC.ttf",
//       "100italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoTBIYjEfg-zCmf4.ttf",
//       "200italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VobBJYjEfg-zCmf4.ttf",
//       "300italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoW5JYjEfg-zCmf4.ttf",
//       "italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoTBJYjEfg-zCmf4.ttf",
//       "500italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoQJJYjEfg-zCmf4.ttf",
//       "600italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-Voe5OYjEfg-zCmf4.ttf",
//       "700italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoddOYjEfg-zCmf4.ttf",
//       "800italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VobBOYjEfg-zCmf4.ttf",
//       "900italic": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buEPppa9f8_vkXaZLAgP0G5Wi6QmA1QwcLRCOrN8uo7t6FBJOJTQit-N33sQOk-VoZlOYjEfg-zCmf4.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifdisplay/v24/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugLYjE.ttf"
//   },
//   {
//     "family": "Kiwi Maru",
//     "variants": [
//       "300",
//       "regular",
//       "500"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-27",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/kiwimaru/v14/R70djykGkuuDep-hRg6gNCi0Vxn9R5ShnA.ttf",
//       "500": "https://fonts.gstatic.com/s/kiwimaru/v14/R70djykGkuuDep-hRg6gbCm0Vxn9R5ShnA.ttf",
//       "regular": "https://fonts.gstatic.com/s/kiwimaru/v14/R70YjykGkuuDep-hRg6YmACQXzLhTg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kiwimaru/v14/R70YjykGkuuDep-hRg6omQqU.ttf"
//   },
//   {
//     "family": "Belleza",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/belleza/v17/0nkoC9_pNeMfhX4BtcbyawzruP8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/belleza/v17/0nkoC9_pNeMfhX4Bhcf4bw.ttf"
//   },
//   {
//     "family": "Wix Madefor Text",
//     "variants": [
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-10-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cKNNOeFgpRt9rN5.ttf",
//       "600": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cJhM-eFgpRt9rN5.ttf",
//       "700": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cJYM-eFgpRt9rN5.ttf",
//       "800": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cI_M-eFgpRt9rN5.ttf",
//       "regular": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cK_NOeFgpRt9rN5.ttf",
//       "italic": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_WXI_oSymQ8Qj-Apx3HGN_Hu1RZiAL6QzqeqKx1td3dw_GiJBP86N53IY.ttf",
//       "500italic": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_WXI_oSymQ8Qj-Apx3HGN_Hu1RZiAL6QzqeqKx1td3dz3GiJBP86N53IY.ttf",
//       "600italic": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_WXI_oSymQ8Qj-Apx3HGN_Hu1RZiAL6QzqeqKx1td3d9HBiJBP86N53IY.ttf",
//       "700italic": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_WXI_oSymQ8Qj-Apx3HGN_Hu1RZiAL6QzqeqKx1td3d-jBiJBP86N53IY.ttf",
//       "800italic": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_WXI_oSymQ8Qj-Apx3HGN_Hu1RZiAL6QzqeqKx1td3d4_BiJBP86N53IY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cK_NNeEiJA.ttf"
//   },
//   {
//     "family": "Lustria",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lustria/v13/9oRONYodvDEyjuhOrCg5MtPyAcg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lustria/v13/9oRONYodvDEyjuhOnCkzNg.ttf"
//   },
//   {
//     "family": "Golos Text",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plVjRQ5cEr8zXcyx.ttf",
//       "600": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plWPQg5cEr8zXcyx.ttf",
//       "700": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plW2Qg5cEr8zXcyx.ttf",
//       "800": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plXRQg5cEr8zXcyx.ttf",
//       "900": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plX4Qg5cEr8zXcyx.ttf",
//       "regular": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plVRRQ5cEr8zXcyx.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/golostext/v4/q5uXsoe9Lv5t7Meb31EcOR9UdVTNs822plVRRT5dGLs.ttf"
//   },
//   {
//     "family": "Grandstander",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1-_D3jWttFGmQk.ttf",
//       "200": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD9--D3jWttFGmQk.ttf",
//       "300": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQDwG-D3jWttFGmQk.ttf",
//       "500": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD22-D3jWttFGmQk.ttf",
//       "600": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD4G5D3jWttFGmQk.ttf",
//       "700": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD7i5D3jWttFGmQk.ttf",
//       "800": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD9-5D3jWttFGmQk.ttf",
//       "900": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD_a5D3jWttFGmQk.ttf",
//       "regular": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1--D3jWttFGmQk.ttf",
//       "100italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf95zrcsvNDiQlBYQ.ttf",
//       "200italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ZzvcsvNDiQlBYQ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9uTvcsvNDiQlBYQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf95zvcsvNDiQlBYQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf91TvcsvNDiQlBYQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9OTzcsvNDiQlBYQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ADzcsvNDiQlBYQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9ZzzcsvNDiQlBYQ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/grandstander/v17/ga6ZawtA-GpSsTWrnNHPCSImbyq1fDGZrzwXGpf9TjzcsvNDiQlBYQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/grandstander/v17/ga6fawtA-GpSsTWrnNHPCSIMZhhKpFjyNZIQD1--P3ncsg.ttf"
//   },
//   {
//     "family": "Seaweed Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/seaweedscript/v15/bx6cNx6Tne2pxOATYE8C_Rsoe0WJ-KcGVbLW.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/seaweedscript/v15/bx6cNx6Tne2pxOATYE8C_Rsoe3WI8qM.ttf"
//   },
//   {
//     "family": "Georama",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvktmQsL5_tgbg.ttf",
//       "200": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5mvgtmQsL5_tgbg.ttf",
//       "300": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5RPgtmQsL5_tgbg.ttf",
//       "500": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5KPgtmQsL5_tgbg.ttf",
//       "600": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5xP8tmQsL5_tgbg.ttf",
//       "700": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5_f8tmQsL5_tgbg.ttf",
//       "800": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5mv8tmQsL5_tgbg.ttf",
//       "900": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5s_8tmQsL5_tgbg.ttf",
//       "regular": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvgtmQsL5_tgbg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rvF2wEPxf5wbh3T.ttf",
//       "200italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rtF2gEPxf5wbh3T.ttf",
//       "300italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rub2gEPxf5wbh3T.ttf",
//       "italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rvF2gEPxf5wbh3T.ttf",
//       "500italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rv32gEPxf5wbh3T.ttf",
//       "600italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rsb3QEPxf5wbh3T.ttf",
//       "700italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rsi3QEPxf5wbh3T.ttf",
//       "800italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rtF3QEPxf5wbh3T.ttf",
//       "900italic": "https://fonts.gstatic.com/s/georama/v13/MCo_zAn438bIEyxFVaIC0ZMQ72G6xnvmodYVPOBB5nuzMdWs0rts3QEPxf5wbh3T.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/georama/v13/MCo5zAn438bIEyxFf6swMnNpvPcUwW4u4yRcDh-ZjxApn9K5GvgdmAEP.ttf"
//   },
//   {
//     "family": "Herr Von Muellerhoff",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/herrvonmuellerhoff/v21/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft3c6_qJY3QPQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/herrvonmuellerhoff/v21/WBL6rFjRZkREW8WqmCWYLgCkQKXb4CAft0c79KY.ttf"
//   },
//   {
//     "family": "Fredericka the Great",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/frederickathegreat/v21/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV-9Skz7Ylch2L.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/frederickathegreat/v21/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV--SlxbI.ttf"
//   },
//   {
//     "family": "Rancho",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rancho/v21/46kulbzmXjLaqZRlbWXgd0RY1g.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rancho/v21/46kulbzmXjLaqZRVbG_k.ttf"
//   },
//   {
//     "family": "Graduate",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/graduate/v17/C8cg4cs3o2n15t_2YxgR6X2NZAn2.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/graduate/v17/C8cg4cs3o2n15t_2YygQ43k.ttf"
//   },
//   {
//     "family": "Calligraffitti",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/calligraffitti/v19/46k2lbT3XjDVqJw3DCmCFjE0vnFZM5ZBpYN-.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/calligraffitti/v19/46k2lbT3XjDVqJw3DCmCFjE0vkFYOZI.ttf"
//   },
//   {
//     "family": "Bungee Shade",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bungeeshade/v13/DtVkJxarWL0t2KdzK3oI_jks7iLSrwFUlw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bungeeshade/v13/DtVkJxarWL0t2KdzK3oI_jkc7yjW.ttf"
//   },
//   {
//     "family": "Delius",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/delius/v19/PN_xRfK0pW_9e1rtYcI-jT3L_w.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/delius/v19/PN_xRfK0pW_9e1rdYMg6.ttf"
//   },
//   {
//     "family": "Bowlby One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bowlbyone/v23/taiPGmVuC4y96PFeqp8smo6C_Z0wcK4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bowlbyone/v23/taiPGmVuC4y96PFeqp8sqo-I-Q.ttf"
//   },
//   {
//     "family": "Pattaya",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pattaya/v16/ea8ZadcqV_zkHY-XNdCn92ZEmVs.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pattaya/v16/ea8ZadcqV_zkHY-XBdGt8w.ttf"
//   },
//   {
//     "family": "Coming Soon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/comingsoon/v19/qWcuB6mzpYL7AJ2VfdQR1u-SUjjzsykh.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/comingsoon/v19/qWcuB6mzpYL7AJ2VfdQR1t-TWDw.ttf"
//   },
//   {
//     "family": "Nanum Brush Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nanumbrushscript/v22/wXK2E2wfpokopxzthSqPbcR5_gVaxazyjqBr1lO97Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nanumbrushscript/v22/wXK2E2wfpokopxzthSqPbcR5_gVaxazCj6pv.ttf"
//   },
//   {
//     "family": "Bevan",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bevan/v24/4iCj6KZ0a9NXjF8aUir7tlSJ.ttf",
//       "italic": "https://fonts.gstatic.com/s/bevan/v24/4iCt6KZ0a9NXjG8YWC7Zs0SJD4U.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bevan/v24/4iCj6KZ0a9NXjG8bWC4.ttf"
//   },
//   {
//     "family": "Brygada 1918",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/brygada1918/v22/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y12f-V8Wu5O3gbo.ttf",
//       "600": "https://fonts.gstatic.com/s/brygada1918/v22/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y7GY-V8Wu5O3gbo.ttf",
//       "700": "https://fonts.gstatic.com/s/brygada1918/v22/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y4iY-V8Wu5O3gbo.ttf",
//       "regular": "https://fonts.gstatic.com/s/brygada1918/v22/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y2-f-V8Wu5O3gbo.ttf",
//       "italic": "https://fonts.gstatic.com/s/brygada1918/v22/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfcERwcv7GykboaLg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/brygada1918/v22/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfcIxwcv7GykboaLg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/brygada1918/v22/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfczxscv7GykboaLg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/brygada1918/v22/pe06MI6eKpdGqlF5LANrM--qAeRhe6D4yip43qfc9hscv7GykboaLg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/brygada1918/v22/pe08MI6eKpdGqlF5LANrM--ACNaeo8mTUIR_y2-fyV4cvw.ttf"
//   },
//   {
//     "family": "Alumni Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO5QqFsJ3C8qng.ttf",
//       "200": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9uO9QqFsJ3C8qng.ttf",
//       "300": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9Zu9QqFsJ3C8qng.ttf",
//       "500": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9Cu9QqFsJ3C8qng.ttf",
//       "600": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd95uhQqFsJ3C8qng.ttf",
//       "700": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd93-hQqFsJ3C8qng.ttf",
//       "800": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9uOhQqFsJ3C8qng.ttf",
//       "900": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9kehQqFsJ3C8qng.ttf",
//       "regular": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO9QqFsJ3C8qng.ttf",
//       "100italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Ky46lEN_io6npfB.ttf",
//       "200italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kw461EN_io6npfB.ttf",
//       "300italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kzm61EN_io6npfB.ttf",
//       "italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Ky461EN_io6npfB.ttf",
//       "500italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8KyK61EN_io6npfB.ttf",
//       "600italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kxm7FEN_io6npfB.ttf",
//       "700italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kxf7FEN_io6npfB.ttf",
//       "800italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8Kw47FEN_io6npfB.ttf",
//       "900italic": "https://fonts.gstatic.com/s/alumnisans/v18/nwpBtKqkOwdO2aOIwhWudG-g9QMylBJAV3Bo8KwR7FEN_io6npfB.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alumnisans/v18/nwpHtKqkOwdO2aOIwhWudEWpx_zq_Xna-Xd9OO9gqVEN.ttf"
//   },
//   {
//     "family": "Kurale",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kurale/v11/4iCs6KV9e9dXjho6eAT3v02QFg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kurale/v11/4iCs6KV9e9dXjhoKeQ7z.ttf"
//   },
//   {
//     "family": "Sofia Sans Extra Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-02-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLmmmEfzmM356GxA.ttf",
//       "200": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLGmiEfzmM356GxA.ttf",
//       "300": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLxGiEfzmM356GxA.ttf",
//       "500": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLqGiEfzmM356GxA.ttf",
//       "600": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLRG-EfzmM356GxA.ttf",
//       "700": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLfW-EfzmM356GxA.ttf",
//       "800": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLGm-EfzmM356GxA.ttf",
//       "900": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLM2-EfzmM356GxA.ttf",
//       "regular": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLmmiEfzmM356GxA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUitsPTOI_ZuWxFXe.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUivsPDOI_ZuWxFXe.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUisyPDOI_ZuWxFXe.ttf",
//       "italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUitsPDOI_ZuWxFXe.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUitePDOI_ZuWxFXe.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUiuyOzOI_ZuWxFXe.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUiuLOzOI_ZuWxFXe.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUivsOzOI_ZuWxFXe.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxfHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0fSrPEBUZv84WtaeUivFOzOI_ZuWxFXe.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sofiasansextracondensed/v2/raxdHjafvdAIOju4GcIfJH0i7zi50X3zRtuLNiMS0d6iDr-MD5Si9NGLmmi0fjOI.ttf"
//   },
//   {
//     "family": "Bowlby One SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bowlbyonesc/v25/DtVlJxerQqQm37tzN3wMug9Pzgj8owhNjuE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bowlbyonesc/v25/DtVlJxerQqQm37tzN3wMug9P_gn2pw.ttf"
//   },
//   {
//     "family": "Jua",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jua/v15/co3KmW9ljjAjc-DZCsKgsg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jua/v15/co3KmW9ljjATcurd.ttf"
//   },
//   {
//     "family": "Rozha One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rozhaone/v15/AlZy_zVFtYP12Zncg2khdXf4XB0Tow.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rozhaone/v15/AlZy_zVFtYP12Zncg2kRdH38.ttf"
//   },
//   {
//     "family": "Nobile",
//     "variants": [
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/nobile/v17/m8JQjflSeaOVl1iOqo7zcJ5BZmqa3A.ttf",
//       "700": "https://fonts.gstatic.com/s/nobile/v17/m8JQjflSeaOVl1iO4ojzcJ5BZmqa3A.ttf",
//       "regular": "https://fonts.gstatic.com/s/nobile/v17/m8JTjflSeaOVl1i2XqfXeLVdbw.ttf",
//       "italic": "https://fonts.gstatic.com/s/nobile/v17/m8JRjflSeaOVl1iGXK3TWrBNb3OD.ttf",
//       "500italic": "https://fonts.gstatic.com/s/nobile/v17/m8JWjflSeaOVl1iGXJUnc5RFRG-K3Mud.ttf",
//       "700italic": "https://fonts.gstatic.com/s/nobile/v17/m8JWjflSeaOVl1iGXJVvdZRFRG-K3Mud.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nobile/v17/m8JTjflSeaOVl1iGX63T.ttf"
//   },
//   {
//     "family": "Boogaloo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/boogaloo/v23/kmK-Zq45GAvOdnaW6x1F_SrQo_1K.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/boogaloo/v23/kmK-Zq45GAvOdnaW6y1E9y4.ttf"
//   },
//   {
//     "family": "Overpass Mono",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/overpassmono/v16/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EWKokzzXur-SmIr.ttf",
//       "500": "https://fonts.gstatic.com/s/overpassmono/v16/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXmokzzXur-SmIr.ttf",
//       "600": "https://fonts.gstatic.com/s/overpassmono/v16/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EUKpUzzXur-SmIr.ttf",
//       "700": "https://fonts.gstatic.com/s/overpassmono/v16/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EUzpUzzXur-SmIr.ttf",
//       "regular": "https://fonts.gstatic.com/s/overpassmono/v16/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXUokzzXur-SmIr.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/overpassmono/v16/_Xm5-H86tzKDdAPa-KPQZ-AC_COcRycquHlL6EXUonzyVO4.ttf"
//   },
//   {
//     "family": "Podkova",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/podkova/v31/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWt3zcU4EoporSHH.ttf",
//       "600": "https://fonts.gstatic.com/s/podkova/v31/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWubysU4EoporSHH.ttf",
//       "700": "https://fonts.gstatic.com/s/podkova/v31/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWuiysU4EoporSHH.ttf",
//       "800": "https://fonts.gstatic.com/s/podkova/v31/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWvFysU4EoporSHH.ttf",
//       "regular": "https://fonts.gstatic.com/s/podkova/v31/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzcU4EoporSHH.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/podkova/v31/K2FufZ1EmftJSV9VQpXb1lo9vC3nZWtFzfU5GI4.ttf"
//   },
//   {
//     "family": "Maitree",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklhGNWJGovLdh6OE.ttf",
//       "300": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklnWOWJGovLdh6OE.ttf",
//       "500": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrkli2PWJGovLdh6OE.ttf",
//       "600": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklgGIWJGovLdh6OE.ttf",
//       "700": "https://fonts.gstatic.com/s/maitree/v10/MjQDmil5tffhpBrklmWJWJGovLdh6OE.ttf",
//       "regular": "https://fonts.gstatic.com/s/maitree/v10/MjQGmil5tffhpBrkrtmmfJmDoL4.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/maitree/v10/MjQGmil5tffhpBrkntiseA.ttf"
//   },
//   {
//     "family": "Judson",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/judson/v19/FeVSS0Fbvbc14Vxps5xQ3Z5nm29Gww.ttf",
//       "regular": "https://fonts.gstatic.com/s/judson/v19/FeVRS0Fbvbc14VxRD7N01bV7kg.ttf",
//       "italic": "https://fonts.gstatic.com/s/judson/v19/FeVTS0Fbvbc14VxhDblw97BrknZf.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/judson/v19/FeVRS0Fbvbc14VxhDrlw.ttf"
//   },
//   {
//     "family": "Kristi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kristi/v21/uK_y4ricdeU6zwdRCh0TMv6EXw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kristi/v21/uK_y4ricdeU6zwdhCxcX.ttf"
//   },
//   {
//     "family": "Livvic",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/livvic/v14/rnCr-x1S2hzjrlffC-M-mHnOSOuk.ttf",
//       "200": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlffp8IeslfCQfK9WQ.ttf",
//       "300": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlffw8EeslfCQfK9WQ.ttf",
//       "500": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlffm8AeslfCQfK9WQ.ttf",
//       "600": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlfft8ceslfCQfK9WQ.ttf",
//       "700": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlff08YeslfCQfK9WQ.ttf",
//       "900": "https://fonts.gstatic.com/s/livvic/v14/rnCq-x1S2hzjrlff68QeslfCQfK9WQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/livvic/v14/rnCt-x1S2hzjrlfXbdtakn3sTfukQHs.ttf",
//       "200italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdv2s13GY_etWWIJ.ttf",
//       "300italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbduSsF3GY_etWWIJ.ttf",
//       "regular": "https://fonts.gstatic.com/s/livvic/v14/rnCp-x1S2hzjrlfnb-k6unzeSA.ttf",
//       "italic": "https://fonts.gstatic.com/s/livvic/v14/rnCr-x1S2hzjrlfXbeM-mHnOSOuk.ttf",
//       "500italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdvKsV3GY_etWWIJ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdvmtl3GY_etWWIJ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbduCt13GY_etWWIJ.ttf",
//       "900italic": "https://fonts.gstatic.com/s/livvic/v14/rnCs-x1S2hzjrlfXbdu6tV3GY_etWWIJ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/livvic/v14/rnCp-x1S2hzjrlfXbuM-.ttf"
//   },
//   {
//     "family": "Wallpoet",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/wallpoet/v20/f0X10em2_8RnXVVdUNbu7cXP8L8G.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/wallpoet/v20/f0X10em2_8RnXVVdUObv58E.ttf"
//   },
//   {
//     "family": "Klee One",
//     "variants": [
//       "regular",
//       "600"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek-ext",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2022-09-27",
//     "files": {
//       "600": "https://fonts.gstatic.com/s/kleeone/v7/LDI2apCLNRc6A8oT4pbYF8Osc-bGkqIw.ttf",
//       "regular": "https://fonts.gstatic.com/s/kleeone/v7/LDIxapCLNRc6A8oT4q4AOeekWPrP.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kleeone/v7/LDIxapCLNRc6A8oT4p4BM-M.ttf"
//   },
//   {
//     "family": "Manjari",
//     "variants": [
//       "100",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "malayalam"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/manjari/v11/k3kSo8UPMOBO2w1UdbroK2vFIaOV8A.ttf",
//       "700": "https://fonts.gstatic.com/s/manjari/v11/k3kVo8UPMOBO2w1UdWLNC0HrLaqM6Q4.ttf",
//       "regular": "https://fonts.gstatic.com/s/manjari/v11/k3kQo8UPMOBO2w1UTd7iL0nAMaM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/manjari/v11/k3kQo8UPMOBO2w1Ufd_oKw.ttf"
//   },
//   {
//     "family": "Corben",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/corben/v21/LYjAdGzzklQtCMpFHCZgrXArXN7HWQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/corben/v21/LYjDdGzzklQtCMp9oAlEpVs3VQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/corben/v21/LYjDdGzzklQtCMpNoQNA.ttf"
//   },
//   {
//     "family": "Do Hyeon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dohyeon/v18/TwMN-I8CRRU2zM86HFE3ZwaH__-C.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dohyeon/v18/TwMN-I8CRRU2zM86HGE2bQI.ttf"
//   },
//   {
//     "family": "Vazirmatn",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklWgyOReZ72DF_QY.ttf",
//       "200": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklegzOReZ72DF_QY.ttf",
//       "300": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklTYzOReZ72DF_QY.ttf",
//       "500": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklVozOReZ72DF_QY.ttf",
//       "600": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklbY0OReZ72DF_QY.ttf",
//       "700": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklY80OReZ72DF_QY.ttf",
//       "800": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRkleg0OReZ72DF_QY.ttf",
//       "900": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklcE0OReZ72DF_QY.ttf",
//       "regular": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklWgzOReZ72DF_QY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vazirmatn/v13/Dxx78j6PP2D_kU2muijPEe1n2vVbfJRklWgzCRaT6w.ttf"
//   },
//   {
//     "family": "Arizonia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/arizonia/v21/neIIzCemt4A5qa7mv6WGHK06UY30.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arizonia/v21/neIIzCemt4A5qa7mv5WHFqk.ttf"
//   },
//   {
//     "family": "Caudex",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/caudex/v17/esDT311QOP6BJUrwdteklZUCGpG-GQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/caudex/v17/esDQ311QOP6BJUrIyviAnb4eEw.ttf",
//       "italic": "https://fonts.gstatic.com/s/caudex/v17/esDS311QOP6BJUr4yPKEv7sOE4in.ttf",
//       "700italic": "https://fonts.gstatic.com/s/caudex/v17/esDV311QOP6BJUr4yMo4kJ8GOJSuGdLB.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/caudex/v17/esDQ311QOP6BJUr4y_KE.ttf"
//   },
//   {
//     "family": "Suez One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/suezone/v13/taiJGmd_EZ6rqscQgNFJkIqg-I0w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/suezone/v13/taiJGmd_EZ6rqscQgOFImo4.ttf"
//   },
//   {
//     "family": "Proza Libre",
//     "variants": [
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2022-09-22",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyELbV__fcpC69i6N.ttf",
//       "600": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEL3UP_fcpC69i6N.ttf",
//       "700": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEKTUf_fcpC69i6N.ttf",
//       "800": "https://fonts.gstatic.com/s/prozalibre/v9/LYjbdGHgj0k1DIQRyUEyyEKPUv_fcpC69i6N.ttf",
//       "regular": "https://fonts.gstatic.com/s/prozalibre/v9/LYjGdGHgj0k1DIQRyUEyyHovftvXWYyz.ttf",
//       "italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjEdGHgj0k1DIQRyUEyyEotdN_1XJyz7zc.ttf",
//       "500italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTCvceJSY8z6Np1k.ttf",
//       "600italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTAfbeJSY8z6Np1k.ttf",
//       "700italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTGPaeJSY8z6Np1k.ttf",
//       "800italic": "https://fonts.gstatic.com/s/prozalibre/v9/LYjZdGHgj0k1DIQRyUEyyEotTH_ZeJSY8z6Np1k.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/prozalibre/v9/LYjGdGHgj0k1DIQRyUEyyEoudN8.ttf"
//   },
//   {
//     "family": "Darker Grotesque",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-05-31",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXxpqn7y-XFyZFUB.ttf",
//       "500": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXwFqn7y-XFyZFUB.ttf",
//       "600": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXzprX7y-XFyZFUB.ttf",
//       "700": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXzQrX7y-XFyZFUB.ttf",
//       "800": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXy3rX7y-XFyZFUB.ttf",
//       "900": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXyerX7y-XFyZFUB.ttf",
//       "regular": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXw3qn7y-XFyZFUB.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/darkergrotesque/v8/U9MK6cuh-mLQlC4BKCtayOfARkSVgb381b-W8-QDqXw3qk7z83U.ttf"
//   },
//   {
//     "family": "BioRhyme",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-10-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4OrIimiaki-gkRDE.ttf",
//       "300": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4Omwimiaki-gkRDE.ttf",
//       "500": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4OgAimiaki-gkRDE.ttf",
//       "600": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4Ouwlmiaki-gkRDE.ttf",
//       "700": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4OtUlmiaki-gkRDE.ttf",
//       "800": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4OrIlmiaki-gkRDE.ttf",
//       "regular": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4OjIimiaki-gkRDE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/biorhyme/v17/1cXeaULHBpDMsHYW_GZNh7loEHurwOIGadI205trrbeBgQs4OjIiqieujw.ttf"
//   },
//   {
//     "family": "Goudy Bookletter 1911",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/goudybookletter1911/v19/sykt-z54laciWfKv-kX8krex0jDiD2HbY6I5tRbXZ4IXAA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/goudybookletter1911/v19/sykt-z54laciWfKv-kX8krex0jDiD2HbY6IJtBzT.ttf"
//   },
//   {
//     "family": "Palanquin Dark",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/palanquindark/v14/xn76YHgl1nqmANMB-26xC7yuF8Z6ZW41fcvN2KT4.ttf",
//       "600": "https://fonts.gstatic.com/s/palanquindark/v14/xn76YHgl1nqmANMB-26xC7yuF8ZWYm41fcvN2KT4.ttf",
//       "700": "https://fonts.gstatic.com/s/palanquindark/v14/xn76YHgl1nqmANMB-26xC7yuF8YyY241fcvN2KT4.ttf",
//       "regular": "https://fonts.gstatic.com/s/palanquindark/v14/xn75YHgl1nqmANMB-26xC7yuF_6OTEo9VtfE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/palanquindark/v14/xn75YHgl1nqmANMB-26xC7yuF86PRk4.ttf"
//   },
//   {
//     "family": "Allerta",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/allerta/v18/TwMO-IAHRlkbx940UnEdSQqO5uY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/allerta/v18/TwMO-IAHRlkbx940YnAXTQ.ttf"
//   },
//   {
//     "family": "Annie Use Your Telescope",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/annieuseyourtelescope/v18/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGjlDfB3UUVZA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/annieuseyourtelescope/v18/daaLSS4tI2qYYl3Jq9s_Hu74xwktnlKxH6osGVGTlT3F.ttf"
//   },
//   {
//     "family": "Pirata One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtoftiiEr5_BdZ8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtofhimOqw.ttf"
//   },
//   {
//     "family": "Allison",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/allison/v11/X7nl4b88AP2nkbvZOCaQ4MTgAgk.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/allison/v11/X7nl4b88AP2nkbvZCCea5A.ttf"
//   },
//   {
//     "family": "Averia Libre",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/averialibre/v16/2V0FKIcMGZEnV6xygz7eNjEarovtb07t-pQgTw.ttf",
//       "700": "https://fonts.gstatic.com/s/averialibre/v16/2V0FKIcMGZEnV6xygz7eNjEavoztb07t-pQgTw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/averialibre/v16/2V0HKIcMGZEnV6xygz7eNjESAJFhbUTp2JEwT4Sk.ttf",
//       "regular": "https://fonts.gstatic.com/s/averialibre/v16/2V0aKIcMGZEnV6xygz7eNjEiAqPJZ2Xx8w.ttf",
//       "italic": "https://fonts.gstatic.com/s/averialibre/v16/2V0EKIcMGZEnV6xygz7eNjESAKnNRWDh8405.ttf",
//       "700italic": "https://fonts.gstatic.com/s/averialibre/v16/2V0HKIcMGZEnV6xygz7eNjESAJFxakTp2JEwT4Sk.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/averialibre/v16/2V0aKIcMGZEnV6xygz7eNjESA6nN.ttf"
//   },
//   {
//     "family": "Oranienbaum",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/oranienbaum/v15/OZpHg_txtzZKMuXLIVrx-3zn7kz3dpHc.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oranienbaum/v15/OZpHg_txtzZKMuXLIVrx-0zm5Eg.ttf"
//   },
//   {
//     "family": "M PLUS 1",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5VSe78nZcsGGycA.ttf",
//       "200": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW51Sa78nZcsGGycA.ttf",
//       "300": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5Cya78nZcsGGycA.ttf",
//       "500": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5Zya78nZcsGGycA.ttf",
//       "600": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5iyG78nZcsGGycA.ttf",
//       "700": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5siG78nZcsGGycA.ttf",
//       "800": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW51SG78nZcsGGycA.ttf",
//       "900": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5_CG78nZcsGGycA.ttf",
//       "regular": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5VSa78nZcsGGycA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mplus1/v7/R70EjygA28ymD4HgBUGzkN5Eyoj-WpW5VSaL83xY.ttf"
//   },
//   {
//     "family": "Orelega One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/orelegaone/v12/3qTpojOggD2XtAdFb-QXZGt61EcYaQ7F.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/orelegaone/v12/3qTpojOggD2XtAdFb-QXZFt73kM.ttf"
//   },
//   {
//     "family": "UnifrakturMaguntia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/unifrakturmaguntia/v20/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVun2xNNgNa1A.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/unifrakturmaguntia/v20/WWXPlieVYwiGNomYU-ciRLRvEmK7oaVenmZJ.ttf"
//   },
//   {
//     "family": "Shippori Mincho B1",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/shipporiminchob1/v21/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKeolP3nih5H4Uug.ttf",
//       "600": "https://fonts.gstatic.com/s/shipporiminchob1/v21/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKVo5P3nih5H4Uug.ttf",
//       "700": "https://fonts.gstatic.com/s/shipporiminchob1/v21/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKMo9P3nih5H4Uug.ttf",
//       "800": "https://fonts.gstatic.com/s/shipporiminchob1/v21/wXK1E2wCr44tulPdnn-xbIpJ9RgT9-nKLoxP3nih5H4Uug.ttf",
//       "regular": "https://fonts.gstatic.com/s/shipporiminchob1/v21/wXK2E2wCr44tulPdnn-xbIpJ9RgT9-nyjqBr1lO97Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shipporiminchob1/v21/wXK2E2wCr44tulPdnn-xbIpJ9RgT9-nCj6pv.ttf"
//   },
//   {
//     "family": "Kameron",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kameron/v16/vm8pdR7vXErQxuznqrUS3z1Uw3nq4NeFcbcbvZPxCDLR.ttf",
//       "600": "https://fonts.gstatic.com/s/kameron/v16/vm8pdR7vXErQxuznqrUS3z1Uw3nq4NdpdrcbvZPxCDLR.ttf",
//       "700": "https://fonts.gstatic.com/s/kameron/v16/vm8pdR7vXErQxuznqrUS3z1Uw3nq4NdQdrcbvZPxCDLR.ttf",
//       "regular": "https://fonts.gstatic.com/s/kameron/v16/vm8pdR7vXErQxuznqrUS3z1Uw3nq4Ne3cbcbvZPxCDLR.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kameron/v16/vm8pdR7vXErQxuznqrUS3z1Uw3nq4Ne3cYcat5c.ttf"
//   },
//   {
//     "family": "Schoolbell",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/schoolbell/v18/92zQtBZWOrcgoe-fgnJIVxIQ6mRqfiQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/schoolbell/v18/92zQtBZWOrcgoe-fgnJIZxMa7g.ttf"
//   },
//   {
//     "family": "Bellefair",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bellefair/v14/kJExBuYY6AAuhiXUxG19__A2pOdvDA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bellefair/v14/kJExBuYY6AAuhiXUxG1N_voy.ttf"
//   },
//   {
//     "family": "Scada",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/scada/v15/RLp8K5Pv5qumeVrU6BEgRVfmZOE5.ttf",
//       "regular": "https://fonts.gstatic.com/s/scada/v15/RLpxK5Pv5qumeWJoxzUobkvv.ttf",
//       "italic": "https://fonts.gstatic.com/s/scada/v15/RLp_K5Pv5qumeVJqzTEKa1vvffg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/scada/v15/RLp6K5Pv5qumeVJq9Y0lT1PEYfE5p6g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/scada/v15/RLpxK5Pv5qumeVJpzTE.ttf"
//   },
//   {
//     "family": "Marmelad",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/marmelad/v18/Qw3eZQdSHj_jK2e-8tFLG-YMC0R8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/marmelad/v18/Qw3eZQdSHj_jK2e-8uFKEeI.ttf"
//   },
//   {
//     "family": "Contrail One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/contrailone/v19/eLGbP-j_JA-kG0_Zo51noafdZUvt_c092w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/contrailone/v19/eLGbP-j_JA-kG0_Zo51noaftZEHp.ttf"
//   },
//   {
//     "family": "Aboreto",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/aboreto/v2/5DCXAKLhwDDQ4N8blKTeA2yuxSY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aboreto/v2/5DCXAKLhwDDQ4N8bpKXUBw.ttf"
//   },
//   {
//     "family": "Knewave",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/knewave/v14/sykz-yx0lLcxQaSItSq9-trEvlQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/knewave/v14/sykz-yx0lLcxQaSIhSu3_g.ttf"
//   },
//   {
//     "family": "Akshar",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/akshar/v9/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSSgFy9CY94XsnPc.ttf",
//       "500": "https://fonts.gstatic.com/s/akshar/v9/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSUQFy9CY94XsnPc.ttf",
//       "600": "https://fonts.gstatic.com/s/akshar/v9/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSagCy9CY94XsnPc.ttf",
//       "700": "https://fonts.gstatic.com/s/akshar/v9/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSZECy9CY94XsnPc.ttf",
//       "regular": "https://fonts.gstatic.com/s/akshar/v9/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSXYFy9CY94XsnPc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/akshar/v9/Yq6I-LyHWTfz9rGoqDaUbHvhkAUsSXYF-9GS8w.ttf"
//   },
//   {
//     "family": "Arbutus Slab",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/arbutusslab/v16/oY1Z8e7OuLXkJGbXtr5ba7ZVa68dJlaFAQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arbutusslab/v16/oY1Z8e7OuLXkJGbXtr5ba7ZlaqUZ.ttf"
//   },
//   {
//     "family": "Noto Sans Kannada",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvMzSIMLsPKrkY.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrLvNzSIMLsPKrkY.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrGXNzSIMLsPKrkY.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrAnNzSIMLsPKrkY.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrOXKzSIMLsPKrkY.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrNzKzSIMLsPKrkY.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrLvKzSIMLsPKrkY.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrJLKzSIMLsPKrkY.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvNzSIMLsPKrkY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskannada/v27/8vIs7xs32H97qzQKnzfeXycxXZyUmySvZWItmf1fe6TVmgop9ndpS-BqHEyGrDvN_SMGKg.ttf"
//   },
//   {
//     "family": "Ibarra Real Nova",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdS5MDtVT9TWIvS.ttf",
//       "600": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXe-48DtVT9TWIvS.ttf",
//       "700": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXeH48DtVT9TWIvS.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdg5MDtVT9TWIvS.ttf",
//       "italic": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKopyiuXztxXZvSkTo.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKopxquXztxXZvSkTo.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKop_apXztxXZvSkTo.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlsdQiA-DBIDCcaWtQzL4BZHoiDkH5CH9yb5n3ZFmKop8-pXztxXZvSkTo.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibarrarealnova/v27/sZlSdQiA-DBIDCcaWtQzL4BZHoiDundw4ATyjed3EXdg5PDsXzs.ttf"
//   },
//   {
//     "family": "Encode Sans Expanded",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mx1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpJGKQNicoAbJlw.ttf",
//       "200": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLqCCNIXIwSP0XD.ttf",
//       "300": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKOCyNIXIwSP0XD.ttf",
//       "500": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpLWCiNIXIwSP0XD.ttf",
//       "600": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpL6DSNIXIwSP0XD.ttf",
//       "700": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKeDCNIXIwSP0XD.ttf",
//       "800": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKCDyNIXIwSP0XD.ttf",
//       "900": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4mw1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpKmDiNIXIwSP0XD.ttf",
//       "regular": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUqoiIwdAd5Ab.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/encodesansexpanded/v11/c4m_1mF4GcnstG_Jh1QH6ac4hNLeNyeYUpojKQM.ttf"
//   },
//   {
//     "family": "Gurajada",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gurajada/v19/FwZY7-Qx308m-l-0Kd6A4sijpFu_.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gurajada/v19/FwZY7-Qx308m-l-0Ke6B6Mw.ttf"
//   },
//   {
//     "family": "Big Shoulders Text",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-r3TIPNl6P2pc.ttf",
//       "200": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Q-q3TIPNl6P2pc.ttf",
//       "300": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3dGq3TIPNl6P2pc.ttf",
//       "500": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3b2q3TIPNl6P2pc.ttf",
//       "600": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3VGt3TIPNl6P2pc.ttf",
//       "700": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Wit3TIPNl6P2pc.ttf",
//       "800": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Q-t3TIPNl6P2pc.ttf",
//       "900": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Sat3TIPNl6P2pc.ttf",
//       "regular": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q3TIPNl6P2pc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigshoulderstext/v24/55xEezRtP9G3CGPIf49hxc8P0eytUxB2l66LmF6xc3kA3Y-q7TMFMg.ttf"
//   },
//   {
//     "family": "Grand Hotel",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/grandhotel/v19/7Au7p_IgjDKdCRWuR1azpmQNEl0O0kEx.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/grandhotel/v19/7Au7p_IgjDKdCRWuR1azplQMGFk.ttf"
//   },
//   {
//     "family": "Halant",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-05-02",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2Pbsvc_pCmwZqcwdRXg.ttf",
//       "500": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2PbsvK_tCmwZqcwdRXg.ttf",
//       "600": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2PbsvB_xCmwZqcwdRXg.ttf",
//       "700": "https://fonts.gstatic.com/s/halant/v14/u-490qaujRI2PbsvY_1CmwZqcwdRXg.ttf",
//       "regular": "https://fonts.gstatic.com/s/halant/v14/u-4-0qaujRI2PbsX39Jmky12eg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/halant/v14/u-4-0qaujRI2Pbsn3thi.ttf"
//   },
//   {
//     "family": "Limelight",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/limelight/v19/XLYkIZL7aopJVbZJHDuYPeNGrnY2TA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/limelight/v19/XLYkIZL7aopJVbZJHDuoPOlC.ttf"
//   },
//   {
//     "family": "Yesteryear",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yesteryear/v18/dg4g_p78rroaKl8kRKo1r7wHTwonmyw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yesteryear/v18/dg4g_p78rroaKl8kRKo1n70NSw.ttf"
//   },
//   {
//     "family": "M PLUS 1 Code",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7iN0XHpapwmdZhY.ttf",
//       "200": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7gN0HHpapwmdZhY.ttf",
//       "300": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7jT0HHpapwmdZhY.ttf",
//       "500": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7i_0HHpapwmdZhY.ttf",
//       "600": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7hT13HpapwmdZhY.ttf",
//       "700": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7hq13HpapwmdZhY.ttf",
//       "regular": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7iN0HHpapwmdZhY.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mplus1code/v12/ypvMbXOOx2xFpzmYJS3N2_J2hBN6RZ5oIp8m_7iN0EHoYJg.ttf"
//   },
//   {
//     "family": "Stardos Stencil",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/stardosstencil/v15/X7n44bcuGPC8hrvEOHXOgaKCc2TpU3tTvg-t29HSHw.ttf",
//       "regular": "https://fonts.gstatic.com/s/stardosstencil/v15/X7n94bcuGPC8hrvEOHXOgaKCc2TR71R3tiSx0g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stardosstencil/v15/X7n94bcuGPC8hrvEOHXOgaKCc2Th7l5z.ttf"
//   },
//   {
//     "family": "Enriqueta",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/enriqueta/v17/gokpH6L7AUFrRvV44HVrv2mHmNZEq6TTFw.ttf",
//       "600": "https://fonts.gstatic.com/s/enriqueta/v17/gokpH6L7AUFrRvV44HVrk26HmNZEq6TTFw.ttf",
//       "700": "https://fonts.gstatic.com/s/enriqueta/v17/gokpH6L7AUFrRvV44HVr92-HmNZEq6TTFw.ttf",
//       "regular": "https://fonts.gstatic.com/s/enriqueta/v17/goksH6L7AUFrRvV44HVTS0CjkP1Yog.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/enriqueta/v17/goksH6L7AUFrRvV44HVjSkqn.ttf"
//   },
//   {
//     "family": "Overlock",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/overlock/v17/Z9XSDmdMWRiN1_T9Z7xizcmMvL2L9TLT.ttf",
//       "900": "https://fonts.gstatic.com/s/overlock/v17/Z9XSDmdMWRiN1_T9Z7xaz8mMvL2L9TLT.ttf",
//       "regular": "https://fonts.gstatic.com/s/overlock/v17/Z9XVDmdMWRiN1_T9Z4Te4u2El6GC.ttf",
//       "italic": "https://fonts.gstatic.com/s/overlock/v17/Z9XTDmdMWRiN1_T9Z7Tc6OmmkrGC7Cs.ttf",
//       "700italic": "https://fonts.gstatic.com/s/overlock/v17/Z9XQDmdMWRiN1_T9Z7Tc0FWJtrmp8CLTlNs.ttf",
//       "900italic": "https://fonts.gstatic.com/s/overlock/v17/Z9XQDmdMWRiN1_T9Z7Tc0G2Ltrmp8CLTlNs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/overlock/v17/Z9XVDmdMWRiN1_T9Z7Tf6Ok.ttf"
//   },
//   {
//     "family": "Spinnaker",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/spinnaker/v19/w8gYH2oyX-I0_rvR6Hmn3HwLqOqSBg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/spinnaker/v19/w8gYH2oyX-I0_rvR6HmX3XYP.ttf"
//   },
//   {
//     "family": "Chonburi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chonburi/v12/8AtqGs-wOpGRTBq66IWaFr3biAfZ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chonburi/v12/8AtqGs-wOpGRTBq66LWbHLk.ttf"
//   },
//   {
//     "family": "Dawning of a New Day",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dawningofanewday/v20/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAz8bhWJGNoBE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dawningofanewday/v20/t5t_IQMbOp2SEwuncwLRjMfIg1yYit_nAw8aj2Y.ttf"
//   },
//   {
//     "family": "Ovo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ovo/v17/yYLl0h7Wyfzjy4Q5_3WVxA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ovo/v17/yYLl0h7WyfzTyo49.ttf"
//   },
//   {
//     "family": "Average Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/averagesans/v16/1Ptpg8fLXP2dlAXR-HlJJNJPBdqazVoK4A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/averagesans/v16/1Ptpg8fLXP2dlAXR-HlJJNJ_BNCe.ttf"
//   },
//   {
//     "family": "IBM Plex Sans KR",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFM2-VJISZe3O_rc3ZVYh4aTwNOyra_X5zCpMrMfA.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyhqef7bsqMPVZb4.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyn6df7bsqMPVZb4.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOyiacf7bsqMPVZb4.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOygqbf7bsqMPVZb4.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFN2-VJISZe3O_rc3ZVYh4aTwNOym6af7bsqMPVZb4.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFK2-VJISZe3O_rc3ZVYh4aTwNO8tK1W77HtMo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsanskr/v10/vEFK2-VJISZe3O_rc3ZVYh4aTwNOwtO_Xw.ttf"
//   },
//   {
//     "family": "Schibsted Grotesk",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v3",
//     "lastModified": "2023-05-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMNEMEAT8JuXFGVOQ.ttf",
//       "600": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMN_MYAT8JuXFGVOQ.ttf",
//       "700": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMNxcYAT8JuXFGVOQ.ttf",
//       "800": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMNosYAT8JuXFGVOQ.ttf",
//       "900": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMNi8YAT8JuXFGVOQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMNIsEAT8JuXFGVOQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzI5SSPQuCQF3t8uOwiUL-taUTtap9DcSQBg_nT9FQY6oLoDMhqflSFOTXs.ttf",
//       "500italic": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzI5SSPQuCQF3t8uOwiUL-taUTtap9DcSQBg_nT9FQY6oLaDMhqflSFOTXs.ttf",
//       "600italic": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzI5SSPQuCQF3t8uOwiUL-taUTtap9DcSQBg_nT9FQY6oI2C8hqflSFOTXs.ttf",
//       "700italic": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzI5SSPQuCQF3t8uOwiUL-taUTtap9DcSQBg_nT9FQY6oIPC8hqflSFOTXs.ttf",
//       "800italic": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzI5SSPQuCQF3t8uOwiUL-taUTtap9DcSQBg_nT9FQY6oJoC8hqflSFOTXs.ttf",
//       "900italic": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzI5SSPQuCQF3t8uOwiUL-taUTtap9DcSQBg_nT9FQY6oJBC8hqflSFOTXs.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/schibstedgrotesk/v3/JqzK5SSPQuCQF3t8uOwiUL-taUTtarVKQ9vZ6pJJWlMNIsEwTshq.ttf"
//   },
//   {
//     "family": "Noto Sans Gujarati",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v25",
//     "lastModified": "2023-11-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ypFgPM_OdiEH0s.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_wpFwPM_OdiEH0s.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_z3FwPM_OdiEH0s.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ybFwPM_OdiEH0s.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_x3EAPM_OdiEH0s.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_xOEAPM_OdiEH0s.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_wpEAPM_OdiEH0s.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_wAEAPM_OdiEH0s.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ypFwPM_OdiEH0s.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansgujarati/v25/wlpWgx_HC1ti5ViekvcxnhMlCVo3f5pv17ivlzsUB14gg1TMR2Gw4VceEl7MA_ypFzPN9uM.ttf"
//   },
//   {
//     "family": "Rochester",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rochester/v22/6ae-4KCqVa4Zy6Fif-Uy31vWNTMwoQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rochester/v22/6ae-4KCqVa4Zy6Fif-UC3lHS.ttf"
//   },
//   {
//     "family": "Niconne",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/niconne/v15/w8gaH2QvRug1_rTfrQut2F4OuOo.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/niconne/v15/w8gaH2QvRug1_rTfnQqn3A.ttf"
//   },
//   {
//     "family": "Hanuman",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-04-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/hanuman/v22/VuJzdNvD15HhpJJBQMLdPKNiaRpFvg.ttf",
//       "300": "https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQAr_HIlMZRNcp0o.ttf",
//       "700": "https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQBr4HIlMZRNcp0o.ttf",
//       "900": "https://fonts.gstatic.com/s/hanuman/v22/VuJ0dNvD15HhpJJBQCL6HIlMZRNcp0o.ttf",
//       "regular": "https://fonts.gstatic.com/s/hanuman/v22/VuJxdNvD15HhpJJBeKbXOIFneRo.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hanuman/v22/VuJxdNvD15HhpJJBSKfdPA.ttf"
//   },
//   {
//     "family": "Biryani",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddYQyGTBSU-J-RxQ.ttf",
//       "300": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddeAxGTBSU-J-RxQ.ttf",
//       "600": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddZQ3GTBSU-J-RxQ.ttf",
//       "700": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84YddfA2GTBSU-J-RxQ.ttf",
//       "800": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84Yddew1GTBSU-J-RxQ.ttf",
//       "900": "https://fonts.gstatic.com/s/biryani/v13/hv-TlzNxIFoO84Yddcg0GTBSU-J-RxQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/biryani/v13/hv-WlzNxIFoO84YdTUwZPTh5T-s.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/biryani/v13/hv-WlzNxIFoO84YdfU0TOQ.ttf"
//   },
//   {
//     "family": "Fjord One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fjordone/v21/zOL-4pbEnKBY_9S1jNKr6e5As-FeiQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fjordone/v21/zOL-4pbEnKBY_9S1jNKb6ORE.ttf"
//   },
//   {
//     "family": "Bubblegum Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bubblegumsans/v20/AYCSpXb_Z9EORv1M5QTjEzMEtdaHzoPPb7R4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bubblegumsans/v20/AYCSpXb_Z9EORv1M5QTjEzMEteaGxIc.ttf"
//   },
//   {
//     "family": "Instrument Sans",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-05-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npST3-Qf1mS0v3_7Y.ttf",
//       "600": "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSQb_gf1mS0v3_7Y.ttf",
//       "700": "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSQi_gf1mS0v3_7Y.ttf",
//       "regular": "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSTF-Qf1mS0v3_7Y.ttf",
//       "italic": "https://fonts.gstatic.com/s/instrumentsans/v1/pxigypc9vsFDm051Uf6KVwgkfoSbSnNPooZAN0lInHGpCWNE27lgU-XJojENuu-2kykN2u7YUwU.ttf",
//       "500italic": "https://fonts.gstatic.com/s/instrumentsans/v1/pxigypc9vsFDm051Uf6KVwgkfoSbSnNPooZAN0lInHGpCWNE27lgU-XJojENut22kykN2u7YUwU.ttf",
//       "600italic": "https://fonts.gstatic.com/s/instrumentsans/v1/pxigypc9vsFDm051Uf6KVwgkfoSbSnNPooZAN0lInHGpCWNE27lgU-XJojENujGxkykN2u7YUwU.ttf",
//       "700italic": "https://fonts.gstatic.com/s/instrumentsans/v1/pxigypc9vsFDm051Uf6KVwgkfoSbSnNPooZAN0lInHGpCWNE27lgU-XJojENugixkykN2u7YUwU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSTF-Tf0kyk.ttf"
//   },
//   {
//     "family": "Vollkorn SC",
//     "variants": [
//       "regular",
//       "600",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "600": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVimhGluqYbPN5Yjn.ttf",
//       "700": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVinFG1uqYbPN5Yjn.ttf",
//       "900": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_y6-zQ3rXpceZj9cqnVin9GVuqYbPN5Yjn.ttf",
//       "regular": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_v6-zQ3rXpceZj9cqnVhF5NH-iSq_E.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vollkornsc/v11/j8_v6-zQ3rXpceZj9cqnViF4Pns.ttf"
//   },
//   {
//     "family": "Alike",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v21",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alike/v21/HI_EiYEYI6BIoEjBSZXAQ4-d.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alike/v21/HI_EiYEYI6BIoHjAQ5E.ttf"
//   },
//   {
//     "family": "Encode Sans Semi Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT6oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1T19MFtQ9jpVUA.ttf",
//       "200": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RZ1eFHbdTgTFmr.ttf",
//       "300": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Q91uFHbdTgTFmr.ttf",
//       "500": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Rl1-FHbdTgTFmr.ttf",
//       "600": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1RJ0OFHbdTgTFmr.ttf",
//       "700": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qt0eFHbdTgTFmr.ttf",
//       "800": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1Qx0uFHbdTgTFmr.ttf",
//       "900": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT7oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1QV0-FHbdTgTFmr.ttf",
//       "regular": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG2yR_sVPRsjp.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/encodesanssemicondensed/v10/3qT4oiKqnDuUtQUEHMoXcmspmy55SFWrXFRp9FTOG1yQ9ME.ttf"
//   },
//   {
//     "family": "David Libre",
//     "variants": [
//       "regular",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2024-03-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/davidlibre/v16/snfzs0W_99N64iuYSvp4W8GIw7qbSjORSo9W.ttf",
//       "700": "https://fonts.gstatic.com/s/davidlibre/v16/snfzs0W_99N64iuYSvp4W8HAxbqbSjORSo9W.ttf",
//       "regular": "https://fonts.gstatic.com/s/davidlibre/v16/snfus0W_99N64iuYSvp4W_l86p6TYS-Y.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/davidlibre/v16/snfus0W_99N64iuYSvp4W8l94Jo.ttf"
//   },
//   {
//     "family": "McLaren",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mclaren/v17/2EbnL-ZuAXFqZFXISYYf8z2Yt_c.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mclaren/v17/2EbnL-ZuAXFqZFXIeYcV9w.ttf"
//   },
//   {
//     "family": "Miriam Libre",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/miriamlibre/v14/DdT-798HsHwubBAqfkcBTL_X3LbbRcC7_-Z7Hg.ttf",
//       "regular": "https://fonts.gstatic.com/s/miriamlibre/v14/DdTh798HsHwubBAqfkcBTL_vYJn_Teun9g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/miriamlibre/v14/DdTh798HsHwubBAqfkcBTL_fYZP7.ttf"
//   },
//   {
//     "family": "Amiko",
//     "variants": [
//       "regular",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2024-02-02",
//     "files": {
//       "600": "https://fonts.gstatic.com/s/amiko/v14/WwkdxPq1DFK04uJ9XXrEGoQAUco5.ttf",
//       "700": "https://fonts.gstatic.com/s/amiko/v14/WwkdxPq1DFK04uIZXHrEGoQAUco5.ttf",
//       "regular": "https://fonts.gstatic.com/s/amiko/v14/WwkQxPq1DFK04tqlc17MMZgJ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amiko/v14/WwkQxPq1DFK04uqkeVo.ttf"
//   },
//   {
//     "family": "Alegreya SC",
//     "variants": [
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2022-12-08",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZZc-rUxQqu2FXKD.ttf",
//       "700": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYU_LUxQqu2FXKD.ttf",
//       "800": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYI_7UxQqu2FXKD.ttf",
//       "900": "https://fonts.gstatic.com/s/alegreyasc/v25/taiTGmRtCJ62-O0HhNEa-ZYs_rUxQqu2FXKD.ttf",
//       "regular": "https://fonts.gstatic.com/s/alegreyasc/v25/taiOGmRtCJ62-O0HhNEa-a6o05E5abe_.ttf",
//       "italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiMGmRtCJ62-O0HhNEa-Z6q2ZUbbKe_DGs.ttf",
//       "500italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4WEySK-UEGKDBz4.ttf",
//       "700italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4Sk0SK-UEGKDBz4.ttf",
//       "800italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4TU3SK-UEGKDBz4.ttf",
//       "900italic": "https://fonts.gstatic.com/s/alegreyasc/v25/taiRGmRtCJ62-O0HhNEa-Z6q4RE2SK-UEGKDBz4.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alegreyasc/v25/taiOGmRtCJ62-O0HhNEa-Z6p2ZU.ttf"
//   },
//   {
//     "family": "Baloo Da 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/balooda2/v24/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjA5sTNe55aRa7UE.ttf",
//       "600": "https://fonts.gstatic.com/s/balooda2/v24/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjDVtjNe55aRa7UE.ttf",
//       "700": "https://fonts.gstatic.com/s/balooda2/v24/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjDstjNe55aRa7UE.ttf",
//       "800": "https://fonts.gstatic.com/s/balooda2/v24/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjCLtjNe55aRa7UE.ttf",
//       "regular": "https://fonts.gstatic.com/s/balooda2/v24/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjALsTNe55aRa7UE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/balooda2/v24/2-c39J9j0IaUMQZwAJyJaOX1UUnf3GLnYjALsQNf7ZI.ttf"
//   },
//   {
//     "family": "Ephesis",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ephesis/v9/uU9PCBUS8IerL2VG7xPb3vyHmlI.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ephesis/v9/uU9PCBUS8IerL2VG3xLR2g.ttf"
//   },
//   {
//     "family": "Magra",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/magra/v14/uK_w4ruaZus72nbNDxcXEPuUX1ow.ttf",
//       "regular": "https://fonts.gstatic.com/s/magra/v14/uK_94ruaZus72k5xIDMfO-ed.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/magra/v14/uK_94ruaZus72n5wKjc.ttf"
//   },
//   {
//     "family": "Metrophobic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/metrophobic/v23/sJoA3LZUhMSAPV_u0qwiAT-J737FPEEL.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/metrophobic/v23/sJoA3LZUhMSAPV_u0qwiAQ-I5Xo.ttf"
//   },
//   {
//     "family": "Noto Serif Thai",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai"
//     ],
//     "version": "v24",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiFuRRCmsdu0Qx.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0qiF-RRCmsdu0Qx.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0p8F-RRCmsdu0Qx.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oQF-RRCmsdu0Qx.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0r8EORRCmsdu0Qx.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0rFEORRCmsdu0Qx.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0qiEORRCmsdu0Qx.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0qLEORRCmsdu0Qx.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiF-RRCmsdu0Qx.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifthai/v24/k3kyo80MPvpLmixYH7euCxWpSMu3-gcWGj0hHAKGvUQlUv_bCKDUSzB5L0oiF9RQAG8.ttf"
//   },
//   {
//     "family": "Kosugi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kosugi/v15/pxiFyp4_v8FCjlI4NLr6f1pdEQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kosugi/v15/pxiFyp4_v8FCjlIINbD-.ttf"
//   },
//   {
//     "family": "Coustard",
//     "variants": [
//       "regular",
//       "900"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "900": "https://fonts.gstatic.com/s/coustard/v16/3XFuErgg3YsZ5fqUU-2LkEHmb_jU3eRL.ttf",
//       "regular": "https://fonts.gstatic.com/s/coustard/v16/3XFpErgg3YsZ5fqUU9UPvWXuROTd.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/coustard/v16/3XFpErgg3YsZ5fqUU-UOt2E.ttf"
//   },
//   {
//     "family": "NTR",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ntr/v15/RLpzK5Xy0ZjiGGhs5TA4bg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ntr/v15/RLpzK5Xy0ZjSGWJo.ttf"
//   },
//   {
//     "family": "Cormorant Upright",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1N5phDsU9X6RPzQ.ttf",
//       "500": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MhpxDsU9X6RPzQ.ttf",
//       "600": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1MNoBDsU9X6RPzQ.ttf",
//       "700": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJudM3I2Y35poFONtLdafkUCHw1y1NpoRDsU9X6RPzQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJrdM3I2Y35poFONtLdafkUCHw1y2vVjjTkeMnz.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cormorantupright/v18/VuJrdM3I2Y35poFONtLdafkUCHw1y1vUhDA.ttf"
//   },
//   {
//     "family": "Noto Sans Telugu",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "telugu"
//     ],
//     "version": "v26",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezfqQUbf-3v37w.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEnt-zbqQUbf-3v37w.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntJTbqQUbf-3v37w.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntSTbqQUbf-3v37w.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntpTHqQUbf-3v37w.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntnDHqQUbf-3v37w.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEnt-zHqQUbf-3v37w.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEnt0jHqQUbf-3v37w.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezbqQUbf-3v37w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstelugu/v26/0FlxVOGZlE2Rrtr-HmgkMWJNjJ5_RyT8o8c7fHkeg-esVC5dzHkHIJQqrEntezbaQEzb.ttf"
//   },
//   {
//     "family": "Baloo Bhaijaan 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TyjSqP4L4ppfcyC.ttf",
//       "600": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TxPTaP4L4ppfcyC.ttf",
//       "700": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8Tx2TaP4L4ppfcyC.ttf",
//       "800": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TwRTaP4L4ppfcyC.ttf",
//       "regular": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TyRSqP4L4ppfcyC.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baloobhaijaan2/v19/zYXwKUwuEqdVGqM8tPDdAA_Y-_bMKo1EhQd2tWxo8TyRSpP5JY4.ttf"
//   },
//   {
//     "family": "Arya",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/arya/v19/ga6NawNG-HJdzfra3b-BaFg3dRE.ttf",
//       "regular": "https://fonts.gstatic.com/s/arya/v19/ga6CawNG-HJd9Ub1-beqdFE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arya/v19/ga6CawNG-HJdxUf__Q.ttf"
//   },
//   {
//     "family": "Aladin",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/aladin/v24/ZgNSjPJFPrvJV5f16Sf4pGT2Ng.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aladin/v24/ZgNSjPJFPrvJV5fF6C38.ttf"
//   },
//   {
//     "family": "B612",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/b612/v12/3Jn9SDDxiSz34oWXPDCLTXUETuE.ttf",
//       "regular": "https://fonts.gstatic.com/s/b612/v12/3JnySDDxiSz32jm4GDigUXw.ttf",
//       "italic": "https://fonts.gstatic.com/s/b612/v12/3Jn8SDDxiSz36juyHBqlQXwdVw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/b612/v12/3Jn_SDDxiSz36juKoDWBSVcBXuFb0Q.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/b612/v12/3JnySDDxiSz36jiyHA.ttf"
//   },
//   {
//     "family": "Fanwood Text",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fanwoodtext/v15/3XFtErwl05Ad_vSCF6Fq7xXGRdbY1P1Sbg.ttf",
//       "italic": "https://fonts.gstatic.com/s/fanwoodtext/v15/3XFzErwl05Ad_vSCF6Fq7xX2R9zc9vhCblye.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fanwoodtext/v15/3XFtErwl05Ad_vSCF6Fq7xX2RNzc.ttf"
//   },
//   {
//     "family": "Zen Antique",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zenantique/v12/AYCPpXPnd91Ma_Zf-Ri2JXJq7PKP5Z_G.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenantique/v12/AYCPpXPnd91Ma_Zf-Ri2JUJr5vY.ttf"
//   },
//   {
//     "family": "B612 Mono",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/b612mono/v14/kmK6Zq85QVWbN1eW6lJdayv4os9Pv7JGSg.ttf",
//       "regular": "https://fonts.gstatic.com/s/b612mono/v14/kmK_Zq85QVWbN1eW6lJl1wTcquRTtg.ttf",
//       "italic": "https://fonts.gstatic.com/s/b612mono/v14/kmK5Zq85QVWbN1eW6lJV1Q7YiOFDtqtf.ttf",
//       "700italic": "https://fonts.gstatic.com/s/b612mono/v14/kmKkZq85QVWbN1eW6lJV1TZkp8VLnbdWSg4x.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/b612mono/v14/kmK_Zq85QVWbN1eW6lJV1g7Y.ttf"
//   },
//   {
//     "family": "Petit Formal Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/petitformalscript/v17/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qoHnqP4gZSiE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/petitformalscript/v17/B50TF6xQr2TXJBnGOFME6u5OR83oRP5qkHjgOw.ttf"
//   },
//   {
//     "family": "Noto Sans Oriya",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "oriya"
//     ],
//     "version": "v27",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivj0fq_c6LhHBRe-.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivh0f6_c6LhHBRe-.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Iviqf6_c6LhHBRe-.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvjGf6_c6LhHBRe-.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvgqeK_c6LhHBRe-.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvgTeK_c6LhHBRe-.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivh0eK_c6LhHBRe-.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5IvhdeK_c6LhHBRe-.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivj0f6_c6LhHBRe-.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoriya/v27/AYCppXfzfccDCstK_hrjDyADv5e9748vhj3CJBLHIARtgD6TJQS0dJT5Ivj0f5_d4rw.ttf"
//   },
//   {
//     "family": "Irish Grover",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/irishgrover/v23/buExpoi6YtLz2QW7LA4flVgf-P5Oaiw4cw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/irishgrover/v23/buExpoi6YtLz2QW7LA4flVgv-fRK.ttf"
//   },
//   {
//     "family": "Sigmar One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sigmarone/v18/co3DmWZ8kjZuErj9Ta3dk6Pjp3Di8U0.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sigmarone/v18/co3DmWZ8kjZuErj9Ta3do6Lpow.ttf"
//   },
//   {
//     "family": "Nixie One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nixieone/v16/lW-8wjkKLXjg5y2o2uUoUOFzpS-yLw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nixieone/v16/lW-8wjkKLXjg5y2o2uUYUet3.ttf"
//   },
//   {
//     "family": "Gravitas One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gravitasone/v19/5h1diZ4hJ3cblKy3LWakKQmaDWRNr3DzbQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gravitasone/v19/5h1diZ4hJ3cblKy3LWakKQmqDG5J.ttf"
//   },
//   {
//     "family": "Markazi Text",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/markazitext/v23/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtcaQT4MlBekmJLo.ttf",
//       "600": "https://fonts.gstatic.com/s/markazitext/v23/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtSqXT4MlBekmJLo.ttf",
//       "700": "https://fonts.gstatic.com/s/markazitext/v23/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtROXT4MlBekmJLo.ttf",
//       "regular": "https://fonts.gstatic.com/s/markazitext/v23/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQT4MlBekmJLo.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/markazitext/v23/sykh-ydym6AtQaiEtX7yhqb_rV1k_81ZVYYZtfSQf4IvAQ.ttf"
//   },
//   {
//     "family": "Mallanna",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v13",
//     "lastModified": "2022-04-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDGwzEzRwVvJ-.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDFwyGTA.ttf"
//   },
//   {
//     "family": "Trirong",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/trirong/v15/7r3EqXNgp8wxdOdOl-go3YRl6ujngw.ttf",
//       "200": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOl0QJ_a5L5uH-mts.ttf",
//       "300": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlyAK_a5L5uH-mts.ttf",
//       "500": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOl3gL_a5L5uH-mts.ttf",
//       "600": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOl1QM_a5L5uH-mts.ttf",
//       "700": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlzAN_a5L5uH-mts.ttf",
//       "800": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlywO_a5L5uH-mts.ttf",
//       "900": "https://fonts.gstatic.com/s/trirong/v15/7r3DqXNgp8wxdOdOlwgP_a5L5uH-mts.ttf",
//       "100italic": "https://fonts.gstatic.com/s/trirong/v15/7r3CqXNgp8wxdOdOn44QuY5hyO33g8IY.ttf",
//       "200italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QFa9B4sP7itsB5g.ttf",
//       "300italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QcaxB4sP7itsB5g.ttf",
//       "regular": "https://fonts.gstatic.com/s/trirong/v15/7r3GqXNgp8wxdOdOr4wi2aZg-ug.ttf",
//       "italic": "https://fonts.gstatic.com/s/trirong/v15/7r3EqXNgp8wxdOdOn44o3YRl6ujngw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QKa1B4sP7itsB5g.ttf",
//       "600italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QBapB4sP7itsB5g.ttf",
//       "700italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QYatB4sP7itsB5g.ttf",
//       "800italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QfahB4sP7itsB5g.ttf",
//       "900italic": "https://fonts.gstatic.com/s/trirong/v15/7r3BqXNgp8wxdOdOn44QWalB4sP7itsB5g.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/trirong/v15/7r3GqXNgp8wxdOdOn40o3Q.ttf"
//   },
//   {
//     "family": "M PLUS 2",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwOa-VxlqHrzNgAw.ttf",
//       "200": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwua6VxlqHrzNgAw.ttf",
//       "300": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwZ66VxlqHrzNgAw.ttf",
//       "500": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwC66VxlqHrzNgAw.ttf",
//       "600": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkw56mVxlqHrzNgAw.ttf",
//       "700": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkw3qmVxlqHrzNgAw.ttf",
//       "800": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwuamVxlqHrzNgAw.ttf",
//       "900": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwkKmVxlqHrzNgAw.ttf",
//       "regular": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwOa6VxlqHrzNgAw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mplus2/v7/7Auhp_Eq3gO_OGbGGhjdwrDdpeIBxlkwOa6lx1CD.ttf"
//   },
//   {
//     "family": "Grenze Gotisch",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz5UcICdYPSd_w.ttf",
//       "200": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5rz9UcICdYPSd_w.ttf",
//       "300": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5cT9UcICdYPSd_w.ttf",
//       "500": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5HT9UcICdYPSd_w.ttf",
//       "600": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i58ThUcICdYPSd_w.ttf",
//       "700": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5yDhUcICdYPSd_w.ttf",
//       "800": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5rzhUcICdYPSd_w.ttf",
//       "900": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5hjhUcICdYPSd_w.ttf",
//       "regular": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9UcICdYPSd_w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/grenzegotisch/v18/Fh4hPjjqNDz1osh_jX9YfjudpBJBNV5y5wf_k1i5Lz9kcYqZ.ttf"
//   },
//   {
//     "family": "Chivo Mono",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7hrqfVKphL03l4.ttf",
//       "200": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D5hr6fVKphL03l4.ttf",
//       "300": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D6_r6fVKphL03l4.ttf",
//       "500": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7Tr6fVKphL03l4.ttf",
//       "600": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D4_qKfVKphL03l4.ttf",
//       "700": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D4GqKfVKphL03l4.ttf",
//       "800": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D5hqKfVKphL03l4.ttf",
//       "900": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D5IqKfVKphL03l4.ttf",
//       "regular": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7hr6fVKphL03l4.ttf",
//       "100italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7E-XIJxp1ml4imo.ttf",
//       "200italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7M-WIJxp1ml4imo.ttf",
//       "300italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7BGWIJxp1ml4imo.ttf",
//       "italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7E-WIJxp1ml4imo.ttf",
//       "500italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7H2WIJxp1ml4imo.ttf",
//       "600italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7JGRIJxp1ml4imo.ttf",
//       "700italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7KiRIJxp1ml4imo.ttf",
//       "800italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7M-RIJxp1ml4imo.ttf",
//       "900italic": "https://fonts.gstatic.com/s/chivomono/v9/mFTjWbgRxKvF_Z5eQMO9gxoZ20KOQ0Hs2ysp7OaRIJxp1ml4imo.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chivomono/v9/mFThWbgRxKvF_Z5eQMO9qRMrJJrnKNtC3D7hr5fUIJw.ttf"
//   },
//   {
//     "family": "Carrois Gothic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/carroisgothic/v16/Z9XPDmFATg-N1PLtLOOxvIHl9ZmD3i7ajcJ-.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/carroisgothic/v16/Z9XPDmFATg-N1PLtLOOxvIHl9amC1Co.ttf"
//   },
//   {
//     "family": "Average",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/average/v18/fC1hPYBHe23MxA7rIeJwVWytTyk.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/average/v18/fC1hPYBHe23MxA7rEeN6UQ.ttf"
//   },
//   {
//     "family": "Tilt Neon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tiltneon/v10/E21L_d7gguXdwD9LEFY2WCeElCNtd-eBqpHp1TzrkJSmwpj5ndxquXK9WualJ9DS.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tiltneon/v10/E21L_d7gguXdwD9LEFY2WCeElCNtd-eBqpHp1TzrkJSmwpj5ndxquUK8UOI.ttf"
//   },
//   {
//     "family": "Besley",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/besley/v19/PlIhFlO1MaNwaNGWUC92IOH_mtG4fYTBSdRoFPOl8-E.ttf",
//       "600": "https://fonts.gstatic.com/s/besley/v19/PlIhFlO1MaNwaNGWUC92IOH_mtG4fWjGSdRoFPOl8-E.ttf",
//       "700": "https://fonts.gstatic.com/s/besley/v19/PlIhFlO1MaNwaNGWUC92IOH_mtG4fVHGSdRoFPOl8-E.ttf",
//       "800": "https://fonts.gstatic.com/s/besley/v19/PlIhFlO1MaNwaNGWUC92IOH_mtG4fTbGSdRoFPOl8-E.ttf",
//       "900": "https://fonts.gstatic.com/s/besley/v19/PlIhFlO1MaNwaNGWUC92IOH_mtG4fR_GSdRoFPOl8-E.ttf",
//       "regular": "https://fonts.gstatic.com/s/besley/v19/PlIhFlO1MaNwaNGWUC92IOH_mtG4fbbBSdRoFPOl8-E.ttf",
//       "italic": "https://fonts.gstatic.com/s/besley/v19/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CoZdiENGg4-E04A.ttf",
//       "500italic": "https://fonts.gstatic.com/s/besley/v19/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6Ck5diENGg4-E04A.ttf",
//       "600italic": "https://fonts.gstatic.com/s/besley/v19/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6Cf5BiENGg4-E04A.ttf",
//       "700italic": "https://fonts.gstatic.com/s/besley/v19/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CRpBiENGg4-E04A.ttf",
//       "800italic": "https://fonts.gstatic.com/s/besley/v19/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CIZBiENGg4-E04A.ttf",
//       "900italic": "https://fonts.gstatic.com/s/besley/v19/PlIjFlO1MaNwaNG8WR2J-IiUAH-_aH6CCJBiENGg4-E04A.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/besley/v19/PlIhFlO1MaNwaNGWUC92IOH_mtG4fbbBedViEA.ttf"
//   },
//   {
//     "family": "Rambla",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/rambla/v13/snfos0ip98hx6mrMn50qPvN4yJuDYQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/rambla/v13/snfrs0ip98hx6mr0I7IONthkwQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/rambla/v13/snfps0ip98hx6mrEIbgKFN10wYKa.ttf",
//       "700italic": "https://fonts.gstatic.com/s/rambla/v13/snfus0ip98hx6mrEIYC2O_l86p6TYS-Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rambla/v13/snfrs0ip98hx6mrEIrgK.ttf"
//   },
//   {
//     "family": "Fahkwang",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJHmZlRFipxkwjx.ttf",
//       "300": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOIjmplRFipxkwjx.ttf",
//       "500": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJ7m5lRFipxkwjx.ttf",
//       "600": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOJXnJlRFipxkwjx.ttf",
//       "700": "https://fonts.gstatic.com/s/fahkwang/v16/Noa26Uj3zpmBOgbNpOIznZlRFipxkwjx.ttf",
//       "200italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgHFQHC5Tlhjxdw4.ttf",
//       "300italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgBVTHC5Tlhjxdw4.ttf",
//       "regular": "https://fonts.gstatic.com/s/fahkwang/v16/Noax6Uj3zpmBOgbNpNqPsr1ZPTZ4.ttf",
//       "italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa36Uj3zpmBOgbNpOqNuLl7OCZ4ihE.ttf",
//       "500italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgE1SHC5Tlhjxdw4.ttf",
//       "600italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgGFVHC5Tlhjxdw4.ttf",
//       "700italic": "https://fonts.gstatic.com/s/fahkwang/v16/Noa06Uj3zpmBOgbNpOqNgAVUHC5Tlhjxdw4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fahkwang/v16/Noax6Uj3zpmBOgbNpOqOuLk.ttf"
//   },
//   {
//     "family": "Kdam Thmor Pro",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kdamthmorpro/v4/EJRPQgAzVdcI-Qdvt34jzurnGA7_j89I8ZWb.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kdamthmorpro/v4/EJRPQgAzVdcI-Qdvt34jzurnGD7-hcs.ttf"
//   },
//   {
//     "family": "Galada",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "bengali",
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/galada/v18/H4cmBXyGmcjXlUX-8iw-4Lqggw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/galada/v18/H4cmBXyGmcjXlUXO8yY6.ttf"
//   },
//   {
//     "family": "Jockey One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jockeyone/v21/HTxpL2g2KjCFj4x8WI6ArIb7HYOk4xc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jockeyone/v21/HTxpL2g2KjCFj4x8WI6AnIfxGQ.ttf"
//   },
//   {
//     "family": "Anek Malayalam",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "malayalam"
//     ],
//     "version": "v6",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUZu_HMr5PDO71Qs.ttf",
//       "200": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTURu-HMr5PDO71Qs.ttf",
//       "300": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUcW-HMr5PDO71Qs.ttf",
//       "500": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUam-HMr5PDO71Qs.ttf",
//       "600": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUUW5HMr5PDO71Qs.ttf",
//       "700": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUXy5HMr5PDO71Qs.ttf",
//       "800": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTURu5HMr5PDO71Qs.ttf",
//       "regular": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUZu-HMr5PDO71Qs.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anekmalayalam/v6/6qLjKZActRTs_mZAJUZWWkhke0nYa_vC8_Azq3-gP1SReZeOtqQuDVUTUZu-LMvzOA.ttf"
//   },
//   {
//     "family": "Kelly Slab",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kellyslab/v17/-W_7XJX0Rz3cxUnJC5t6TkMBf50kbiM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kellyslab/v17/-W_7XJX0Rz3cxUnJC5t6fkILew.ttf"
//   },
//   {
//     "family": "Odibee Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/odibeesans/v18/neIPzCSooYAho6WvjeToRYkyepH9qGsf.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/odibeesans/v18/neIPzCSooYAho6WvjeToRbkzcJU.ttf"
//   },
//   {
//     "family": "Qwigley",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/qwigley/v18/1cXzaU3UGJb5tGoCuVxsi1mBmcE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/qwigley/v18/1cXzaU3UGJb5tGoCiV1mjw.ttf"
//   },
//   {
//     "family": "Andada Pro",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/andadapro/v20/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DP7BY8cFLzvIt2S.ttf",
//       "600": "https://fonts.gstatic.com/s/andadapro/v20/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DMXAo8cFLzvIt2S.ttf",
//       "700": "https://fonts.gstatic.com/s/andadapro/v20/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DMuAo8cFLzvIt2S.ttf",
//       "800": "https://fonts.gstatic.com/s/andadapro/v20/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DNJAo8cFLzvIt2S.ttf",
//       "regular": "https://fonts.gstatic.com/s/andadapro/v20/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DPJBY8cFLzvIt2S.ttf",
//       "italic": "https://fonts.gstatic.com/s/andadapro/v20/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRmdfHrjNJ82Stjw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/andadapro/v20/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRlVfHrjNJ82Stjw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/andadapro/v20/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRrlYHrjNJ82Stjw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/andadapro/v20/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRoBYHrjNJ82Stjw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/andadapro/v20/HhyGU5Qi9-SuOEhPe4LtAIxwRrn9L22O2yYBRudYHrjNJ82Stjw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/andadapro/v20/HhyEU5Qi9-SuOEhPe4LtKoVCuWGURPcg3DPJBb8dHrg.ttf"
//   },
//   {
//     "family": "Zen Kaku Gothic Antique",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/zenkakugothicantique/v15/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22cM9TarWJtyZyGU.ttf",
//       "500": "https://fonts.gstatic.com/s/zenkakugothicantique/v15/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22dU9DarWJtyZyGU.ttf",
//       "700": "https://fonts.gstatic.com/s/zenkakugothicantique/v15/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22cc8jarWJtyZyGU.ttf",
//       "900": "https://fonts.gstatic.com/s/zenkakugothicantique/v15/6qLVKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22ck8DarWJtyZyGU.ttf",
//       "regular": "https://fonts.gstatic.com/s/zenkakugothicantique/v15/6qLQKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB21-g3RKjc4d7.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenkakugothicantique/v15/6qLQKYkHvh-nlUpKPAdoVFBtfxDzIn1eCzpB22-h1xY.ttf"
//   },
//   {
//     "family": "Waiting for the Sunrise",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/waitingforthesunrise/v20/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsYZQ9h_ZYk5J.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/waitingforthesunrise/v20/WBL1rFvOYl9CEv2i1mO6KUW8RKWJ2zoXoz5JsbZR_Bs.ttf"
//   },
//   {
//     "family": "Thasadith",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/thasadith/v11/mtG94_1TIqPYrd_f5R1gDGYw2A6yHk9d8w.ttf",
//       "regular": "https://fonts.gstatic.com/s/thasadith/v11/mtG44_1TIqPYrd_f5R1YsEkU0CWuFw.ttf",
//       "italic": "https://fonts.gstatic.com/s/thasadith/v11/mtG-4_1TIqPYrd_f5R1oskMQ8iC-F1ZE.ttf",
//       "700italic": "https://fonts.gstatic.com/s/thasadith/v11/mtGj4_1TIqPYrd_f5R1osnus3QS2PEpN8zxA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/thasadith/v11/mtG44_1TIqPYrd_f5R1osUMQ.ttf"
//   },
//   {
//     "family": "Love Ya Like A Sister",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/loveyalikeasister/v20/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-Be78nZcsGGycA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/loveyalikeasister/v20/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-BeL83xY.ttf"
//   },
//   {
//     "family": "Hahmlet",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKOdjobsO-aVxn.ttf",
//       "200": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RjKONjobsO-aVxn.ttf",
//       "300": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RgUONjobsO-aVxn.ttf",
//       "500": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4Rh4ONjobsO-aVxn.ttf",
//       "600": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RiUP9jobsO-aVxn.ttf",
//       "700": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RitP9jobsO-aVxn.ttf",
//       "800": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RjKP9jobsO-aVxn.ttf",
//       "900": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RjjP9jobsO-aVxn.ttf",
//       "regular": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKONjobsO-aVxn.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hahmlet/v13/BngXUXpCQ3nKpIo0TfPyfCdXfaeU4RhKOOjpZMc.ttf"
//   },
//   {
//     "family": "Jomhuria",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jomhuria/v20/Dxxp8j-TMXf-llKur2b1MOGbC3Dh.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jomhuria/v20/Dxxp8j-TMXf-llKur1b0OuU.ttf"
//   },
//   {
//     "family": "Copse",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-04-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/copse/v15/11hPGpDKz1rGb0djHkihUb-A.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/copse/v15/11hPGpDKz1rGb3diFEw.ttf"
//   },
//   {
//     "family": "Gabarito",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-11-28",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/gabarito/v4/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj20cK0FgItq6bFIg.ttf",
//       "600": "https://fonts.gstatic.com/s/gabarito/v4/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj2PcW0FgItq6bFIg.ttf",
//       "700": "https://fonts.gstatic.com/s/gabarito/v4/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj2BMW0FgItq6bFIg.ttf",
//       "800": "https://fonts.gstatic.com/s/gabarito/v4/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj2Y8W0FgItq6bFIg.ttf",
//       "900": "https://fonts.gstatic.com/s/gabarito/v4/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj2SsW0FgItq6bFIg.ttf",
//       "regular": "https://fonts.gstatic.com/s/gabarito/v4/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj248K0FgItq6bFIg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gabarito/v4/QGYwz_0dZAGKJJ4t3FFkc3Q8AkNP9Pj248KEFwgp.ttf"
//   },
//   {
//     "family": "Mansalva",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mansalva/v14/aWB4m0aacbtDfvq5NJllI47vdyBg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mansalva/v14/aWB4m0aacbtDfvq5NKlkKYo.ttf"
//   },
//   {
//     "family": "Bentham",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bentham/v18/VdGeAZQPEpYfmHglKWw7CJaK_y4.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bentham/v18/VdGeAZQPEpYfmHglGW0xDA.ttf"
//   },
//   {
//     "family": "Montserrat Subrayada",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/montserratsubrayada/v19/U9MM6c-o9H7PgjlTHThBnNHGVUORwteQQHe3TcMWg3j36Ebz.ttf",
//       "regular": "https://fonts.gstatic.com/s/montserratsubrayada/v19/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQE8LYuceqGT-.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/montserratsubrayada/v19/U9MD6c-o9H7PgjlTHThBnNHGVUORwteQQH8KaOM.ttf"
//   },
//   {
//     "family": "IM Fell DW Pica",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfelldwpica/v16/2sDGZGRQotv9nbn2qSl0TxXVYNw9ZAPUvi88MQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/imfelldwpica/v16/2sDEZGRQotv9nbn2qSl0TxXVYNwNZgnQnCosMXm0.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfelldwpica/v16/2sDGZGRQotv9nbn2qSl0TxXVYNwNZQnQ.ttf"
//   },
//   {
//     "family": "Oooh Baby",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v4",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ooohbaby/v4/2sDcZGJWgJTT2Jf76xQDb2-4C7wFZQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ooohbaby/v4/2sDcZGJWgJTT2Jf76xQzbmW8.ttf"
//   },
//   {
//     "family": "Poller One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pollerone/v23/ahccv82n0TN3gia5E4Bud-lbgUS5u0s.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pollerone/v23/ahccv82n0TN3gia5E4BuR-hRhQ.ttf"
//   },
//   {
//     "family": "Italiana",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/italiana/v20/QldNNTtLsx4E__B0XTmRY31Wx7Vv.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/italiana/v20/QldNNTtLsx4E__B0XQmQaXk.ttf"
//   },
//   {
//     "family": "Monsieur La Doulaise",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/monsieurladoulaise/v18/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZl5ewkEU4HTy.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/monsieurladoulaise/v18/_Xmz-GY4rjmCbQfc-aPRaa4pqV340p7EZm5fyEU.ttf"
//   },
//   {
//     "family": "Rasa",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/rasa/v22/xn76YHIn1mWmVKl8ZtAM9NrJfN4YJW41fcvN2KT4.ttf",
//       "500": "https://fonts.gstatic.com/s/rasa/v22/xn76YHIn1mWmVKl8ZtAM9NrJfN50JW41fcvN2KT4.ttf",
//       "600": "https://fonts.gstatic.com/s/rasa/v22/xn76YHIn1mWmVKl8ZtAM9NrJfN6YIm41fcvN2KT4.ttf",
//       "700": "https://fonts.gstatic.com/s/rasa/v22/xn76YHIn1mWmVKl8ZtAM9NrJfN6hIm41fcvN2KT4.ttf",
//       "regular": "https://fonts.gstatic.com/s/rasa/v22/xn76YHIn1mWmVKl8ZtAM9NrJfN5GJW41fcvN2KT4.ttf",
//       "300italic": "https://fonts.gstatic.com/s/rasa/v22/xn78YHIn1mWmfqBOmQhln0Bne8uOZth2d8_v3bT4Ycc.ttf",
//       "italic": "https://fonts.gstatic.com/s/rasa/v22/xn78YHIn1mWmfqBOmQhln0Bne8uOZoZ2d8_v3bT4Ycc.ttf",
//       "500italic": "https://fonts.gstatic.com/s/rasa/v22/xn78YHIn1mWmfqBOmQhln0Bne8uOZrR2d8_v3bT4Ycc.ttf",
//       "600italic": "https://fonts.gstatic.com/s/rasa/v22/xn78YHIn1mWmfqBOmQhln0Bne8uOZlhxd8_v3bT4Ycc.ttf",
//       "700italic": "https://fonts.gstatic.com/s/rasa/v22/xn78YHIn1mWmfqBOmQhln0Bne8uOZmFxd8_v3bT4Ycc.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rasa/v22/xn76YHIn1mWmVKl8ZtAM9NrJfN5GJV40d88.ttf"
//   },
//   {
//     "family": "Chelsea Market",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chelseamarket/v13/BCawqZsHqfr89WNP_IApC8tzKBhlLA4uKkWk.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chelseamarket/v13/BCawqZsHqfr89WNP_IApC8tzKChkJgo.ttf"
//   },
//   {
//     "family": "Sniglet",
//     "variants": [
//       "regular",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "800": "https://fonts.gstatic.com/s/sniglet/v17/cIf4MaFLtkE3UjaJ_ImHRGEsnIJkWL4.ttf",
//       "regular": "https://fonts.gstatic.com/s/sniglet/v17/cIf9MaFLtkE3UjaJxCmrYGkHgIs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sniglet/v17/cIf9MaFLtkE3UjaJ9CihZA.ttf"
//   },
//   {
//     "family": "Noto Sans Gurmukhi",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1Oe3bxZ_trdp7h.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG3OenbxZ_trdp7h.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG0QenbxZ_trdp7h.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG18enbxZ_trdp7h.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG2QfXbxZ_trdp7h.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG2pfXbxZ_trdp7h.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG3OfXbxZ_trdp7h.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG3nfXbxZ_trdp7h.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1OenbxZ_trdp7h.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansgurmukhi/v26/w8g9H3EvQP81sInb43inmyN9zZ7hb7ATbSWo4q8dJ74a3cVrYFQ_bogT0-gPeG1Oekbwbf8.ttf"
//   },
//   {
//     "family": "Norican",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/norican/v15/MwQ2bhXp1eSBqjkPGJJRtGs-lbA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/norican/v15/MwQ2bhXp1eSBqjkPKJNbsA.ttf"
//   },
//   {
//     "family": "Turret Road",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0ONEdeLYk1Mq3ap.ttf",
//       "300": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0PpEteLYk1Mq3ap.ttf",
//       "500": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0OxE9eLYk1Mq3ap.ttf",
//       "700": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0P5FdeLYk1Mq3ap.ttf",
//       "800": "https://fonts.gstatic.com/s/turretroad/v9/pxidypMgpcBFjE84Zv-fE0PlFteLYk1Mq3ap.ttf",
//       "regular": "https://fonts.gstatic.com/s/turretroad/v9/pxiAypMgpcBFjE84Zv-fE3tFOvODSVFF.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/turretroad/v9/pxiAypMgpcBFjE84Zv-fE0tEMPc.ttf"
//   },
//   {
//     "family": "Spectral SC",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-05-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs1qwkTXPYeVXJZB.ttf",
//       "300": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs0OwUTXPYeVXJZB.ttf",
//       "500": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs1WwETXPYeVXJZB.ttf",
//       "600": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs16x0TXPYeVXJZB.ttf",
//       "700": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs0exkTXPYeVXJZB.ttf",
//       "800": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk0ALCRZonmalTgyPmRfs0CxUTXPYeVXJZB.ttf",
//       "200italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg26zWN4O3WYZB_sU.ttf",
//       "300italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg28jVN4O3WYZB_sU.ttf",
//       "regular": "https://fonts.gstatic.com/s/spectralsc/v12/KtkpALCRZonmalTgyPmRfvWi6WDfFpuc.ttf",
//       "italic": "https://fonts.gstatic.com/s/spectralsc/v12/KtkrALCRZonmalTgyPmRfsWg42T9E4ucRY8.ttf",
//       "500italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg25DUN4O3WYZB_sU.ttf",
//       "600italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg27zTN4O3WYZB_sU.ttf",
//       "700italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg29jSN4O3WYZB_sU.ttf",
//       "800italic": "https://fonts.gstatic.com/s/spectralsc/v12/Ktk2ALCRZonmalTgyPmRfsWg28TRN4O3WYZB_sU.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/spectralsc/v12/KtkpALCRZonmalTgyPmRfsWj42Q.ttf"
//   },
//   {
//     "family": "Fondamento",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fondamento/v20/4UaHrEJGsxNmFTPDnkaJx63j5pN1MwI.ttf",
//       "italic": "https://fonts.gstatic.com/s/fondamento/v20/4UaFrEJGsxNmFTPDnkaJ96_p4rFwIwJePw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fondamento/v20/4UaHrEJGsxNmFTPDnkaJ96zp4g.ttf"
//   },
//   {
//     "family": "Lateef",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v30",
//     "lastModified": "2023-09-13",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0bjygbqTb9nQ-RA.ttf",
//       "300": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0Cj-gbqTb9nQ-RA.ttf",
//       "500": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0Uj6gbqTb9nQ-RA.ttf",
//       "600": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0fjmgbqTb9nQ-RA.ttf",
//       "700": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0GjigbqTb9nQ-RA.ttf",
//       "800": "https://fonts.gstatic.com/s/lateef/v30/hESz6XVnNCxEvkb0BjugbqTb9nQ-RA.ttf",
//       "regular": "https://fonts.gstatic.com/s/lateef/v30/hESw6XVnNCxEvkbMpheEZo_H_w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lateef/v30/hESw6XVnNCxEvkb8px2A.ttf"
//   },
//   {
//     "family": "Rakkas",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rakkas/v19/Qw3cZQlNHiblL3j_lttPOeMcCw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rakkas/v19/Qw3cZQlNHiblL3jPl9FL.ttf"
//   },
//   {
//     "family": "IM Fell English SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfellenglishsc/v16/a8IENpD3CDX-4zrWfr1VY879qFF05pZLO4gOg0shzA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfellenglishsc/v16/a8IENpD3CDX-4zrWfr1VY879qFF05pZ7OoIK.ttf"
//   },
//   {
//     "family": "Bungee Inline",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bungeeinline/v15/Gg8zN58UcgnlCweMrih332VuDGJ1-FEglsc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bungeeinline/v15/Gg8zN58UcgnlCweMrih332VuPGN__A.ttf"
//   },
//   {
//     "family": "Hepta Slab",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5jfbY5B0NBkz.ttf",
//       "200": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV5zfbY5B0NBkz.ttf",
//       "300": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvlL5zfbY5B0NBkz.ttf",
//       "500": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvkn5zfbY5B0NBkz.ttf",
//       "600": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvnL4DfbY5B0NBkz.ttf",
//       "700": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvny4DfbY5B0NBkz.ttf",
//       "800": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvmV4DfbY5B0NBkz.ttf",
//       "900": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5Hvm84DfbY5B0NBkz.ttf",
//       "regular": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5zfbY5B0NBkz.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/heptaslab/v23/ea8JadoyU_jkHdalebHvyWVNdYoIsHe5HvkV5wfaaZQ.ttf"
//   },
//   {
//     "family": "Gloock",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gloock/v6/Iurb6YFw84WUY4N5jxylBrdRjQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gloock/v6/Iurb6YFw84WUY4NJjhah.ttf"
//   },
//   {
//     "family": "Molengo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/molengo/v16/I_uuMpWeuBzZNBtQbbRQkiCvs5Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/molengo/v16/I_uuMpWeuBzZNBtQXbValg.ttf"
//   },
//   {
//     "family": "Fauna One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-03-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/faunaone/v15/wlpzgwTPBVpjpCuwkuEx2UxLYClOCg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/faunaone/v15/wlpzgwTPBVpjpCuwkuEB2EZP.ttf"
//   },
//   {
//     "family": "Rosario",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v31",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/rosario/v31/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM69GCWczd-YnOzUD.ttf",
//       "500": "https://fonts.gstatic.com/s/rosario/v31/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68qCWczd-YnOzUD.ttf",
//       "600": "https://fonts.gstatic.com/s/rosario/v31/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6_GDmczd-YnOzUD.ttf",
//       "700": "https://fonts.gstatic.com/s/rosario/v31/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM6__Dmczd-YnOzUD.ttf",
//       "regular": "https://fonts.gstatic.com/s/rosario/v31/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCWczd-YnOzUD.ttf",
//       "300italic": "https://fonts.gstatic.com/s/rosario/v31/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQStFwfeIFPiUDn08.ttf",
//       "italic": "https://fonts.gstatic.com/s/rosario/v31/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSo9wfeIFPiUDn08.ttf",
//       "500italic": "https://fonts.gstatic.com/s/rosario/v31/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSr1wfeIFPiUDn08.ttf",
//       "600italic": "https://fonts.gstatic.com/s/rosario/v31/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSlF3feIFPiUDn08.ttf",
//       "700italic": "https://fonts.gstatic.com/s/rosario/v31/xfug0WDhWW_fOEoY2Fbnww42bCJhNLrQSmh3feIFPiUDn08.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rosario/v31/xfuu0WDhWW_fOEoY8l_VPNZfB7jPM68YCVcyfeI.ttf"
//   },
//   {
//     "family": "Radley",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/radley/v22/LYjDdGzinEIjCN19oAlEpVs3VQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/radley/v22/LYjBdGzinEIjCN1NogNAh14nVcfe.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/radley/v22/LYjDdGzinEIjCN1NoQNA.ttf"
//   },
//   {
//     "family": "IM Fell English",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfellenglish/v14/Ktk1ALSLW8zDe0rthJysWrnLsAz3F6mZVY9Y5w.ttf",
//       "italic": "https://fonts.gstatic.com/s/imfellenglish/v14/Ktk3ALSLW8zDe0rthJysWrnLsAzHFaOdd4pI59zg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfellenglish/v14/Ktk1ALSLW8zDe0rthJysWrnLsAzHFqOd.ttf"
//   },
//   {
//     "family": "Megrim",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/megrim/v16/46kulbz5WjvLqJZlbWXgd0RY1g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/megrim/v16/46kulbz5WjvLqJZVbG_k.ttf"
//   },
//   {
//     "family": "Cormorant SC",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmABIU_R3y8DOWGA.ttf",
//       "500": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmWBMU_R3y8DOWGA.ttf",
//       "600": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmdBQU_R3y8DOWGA.ttf",
//       "700": "https://fonts.gstatic.com/s/cormorantsc/v18/0ybmGD4kxqXBmOVLG30OGwsmEBUU_R3y8DOWGA.ttf",
//       "regular": "https://fonts.gstatic.com/s/cormorantsc/v18/0yb5GD4kxqXBmOVLG30OGwserDow9Tbu-Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cormorantsc/v18/0yb5GD4kxqXBmOVLG30OGwsurTA0.ttf"
//   },
//   {
//     "family": "Style Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/stylescript/v11/vm8xdRX3SV7Z0aPa88xzW5npeFT76NZnMw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stylescript/v11/vm8xdRX3SV7Z0aPa88xzW5nZeV7_.ttf"
//   },
//   {
//     "family": "Ma Shan Zheng",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mashanzheng/v10/NaPecZTRCLxvwo41b4gvzkXaRMTsDIRSfr0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mashanzheng/v10/NaPecZTRCLxvwo41b4gvzkXadMXmCA.ttf"
//   },
//   {
//     "family": "Skranji",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/skranji/v13/OZpGg_dtriVFNerMW4eBtlzNwED-b4g.ttf",
//       "regular": "https://fonts.gstatic.com/s/skranji/v13/OZpDg_dtriVFNerMYzuuklTm3Ek.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/skranji/v13/OZpDg_dtriVFNerMUzqklg.ttf"
//   },
//   {
//     "family": "Salsa",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/salsa/v21/gNMKW3FiRpKj-imY8ncKEZez.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/salsa/v21/gNMKW3FiRpKj-hmZ-HM.ttf"
//   },
//   {
//     "family": "Tomorrow",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/tomorrow/v17/WBLgrETNbFtZCeGqgR2xe2XiKMiokE4.ttf",
//       "200": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR0dWkXIBsShiVd4.ttf",
//       "300": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR15WUXIBsShiVd4.ttf",
//       "500": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR0hWEXIBsShiVd4.ttf",
//       "600": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR0NX0XIBsShiVd4.ttf",
//       "700": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR1pXkXIBsShiVd4.ttf",
//       "800": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR11XUXIBsShiVd4.ttf",
//       "900": "https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR1RXEXIBsShiVd4.ttf",
//       "100italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLirETNbFtZCeGqgRXXQwHoLOqtgE5h0A.ttf",
//       "200italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ63JDMCDjEd4yVY.ttf",
//       "300italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ8nKDMCDjEd4yVY.ttf",
//       "regular": "https://fonts.gstatic.com/s/tomorrow/v17/WBLmrETNbFtZCeGqgSXVcWHALdio.ttf",
//       "italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLgrETNbFtZCeGqgRXXe2XiKMiokE4.ttf",
//       "500italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ5HLDMCDjEd4yVY.ttf",
//       "600italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ73MDMCDjEd4yVY.ttf",
//       "700italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ9nNDMCDjEd4yVY.ttf",
//       "800italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ8XODMCDjEd4yVY.ttf",
//       "900italic": "https://fonts.gstatic.com/s/tomorrow/v17/WBLjrETNbFtZCeGqgRXXQ-HPDMCDjEd4yVY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tomorrow/v17/WBLmrETNbFtZCeGqgRXUe2U.ttf"
//   },
//   {
//     "family": "Oxygen Mono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/oxygenmono/v14/h0GsssGg9FxgDgCjLeAd7ijfze-PPlUu.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oxygenmono/v14/h0GsssGg9FxgDgCjLeAd7hjex-s.ttf"
//   },
//   {
//     "family": "Angkor",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v32",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/angkor/v32/H4cmBXyAlsPdnlb-8iw-4Lqggw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/angkor/v32/H4cmBXyAlsPdnlbO8yY6.ttf"
//   },
//   {
//     "family": "Onest",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v6",
//     "lastModified": "2023-11-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPh9R6ZshFMQWXgSQ.ttf",
//       "200": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPhdR-ZshFMQWXgSQ.ttf",
//       "300": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPhqx-ZshFMQWXgSQ.ttf",
//       "500": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPhxx-ZshFMQWXgSQ.ttf",
//       "600": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPhKxiZshFMQWXgSQ.ttf",
//       "700": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPhEhiZshFMQWXgSQ.ttf",
//       "800": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPhdRiZshFMQWXgSQ.ttf",
//       "900": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPhXBiZshFMQWXgSQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPh9R-ZshFMQWXgSQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/onest/v6/gNMZW3F-SZuj7zOT0IfSjTS16cPh9R-psxtI.ttf"
//   },
//   {
//     "family": "Gabriela",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gabriela/v20/qkBWXvsO6sreR8E-b_m-zrpHmRzC.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gabriela/v20/qkBWXvsO6sreR8E-b8m_xL4.ttf"
//   },
//   {
//     "family": "Noto Serif Devanagari",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-og-HMUe1u_dv.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTC-ow-HMUe1u_dv.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTBgow-HMUe1u_dv.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTAMow-HMUe1u_dv.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTDgpA-HMUe1u_dv.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTDZpA-HMUe1u_dv.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTC-pA-HMUe1u_dv.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTCXpA-HMUe1u_dv.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-ow-HMUe1u_dv.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifdevanagari/v28/x3dYcl3IZKmUqiMk48ZHXJ5jwU-DZGRSaQ4Hh2dGyFzPLcQPVbnRNeFsw0xRWb6uxTA-oz-GO0M.ttf"
//   },
//   {
//     "family": "Cambay",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/cambay/v12/SLXKc1rY6H0_ZDs-0pusx_lwYX99kA.ttf",
//       "regular": "https://fonts.gstatic.com/s/cambay/v12/SLXJc1rY6H0_ZDsGbrSIz9JsaA.ttf",
//       "italic": "https://fonts.gstatic.com/s/cambay/v12/SLXLc1rY6H0_ZDs2bL6M7dd8aGZk.ttf",
//       "700italic": "https://fonts.gstatic.com/s/cambay/v12/SLXMc1rY6H0_ZDs2bIYwwvN0Q3ptkDMN.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cambay/v12/SLXJc1rY6H0_ZDs2b76M.ttf"
//   },
//   {
//     "family": "Meddon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/meddon/v24/kmK8ZqA2EgDNeHTZhBdB3y_Aow.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/meddon/v24/kmK8ZqA2EgDNeHTphR1F.ttf"
//   },
//   {
//     "family": "KoHo",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPuE1WJ75JoKhHys.ttf",
//       "300": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPoU2WJ75JoKhHys.ttf",
//       "500": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPt03WJ75JoKhHys.ttf",
//       "600": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPvEwWJ75JoKhHys.ttf",
//       "700": "https://fonts.gstatic.com/s/koho/v16/K2FxfZ5fmddNPpUxWJ75JoKhHys.ttf",
//       "200italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNisssJ_zIqCkDyvqZA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNiss1JzzIqCkDyvqZA.ttf",
//       "regular": "https://fonts.gstatic.com/s/koho/v16/K2F-fZ5fmddNBikefJbSOos.ttf",
//       "italic": "https://fonts.gstatic.com/s/koho/v16/K2FwfZ5fmddNNisUeLTXKou4Bg.ttf",
//       "500italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissjJ3zIqCkDyvqZA.ttf",
//       "600italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissoJrzIqCkDyvqZA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/koho/v16/K2FzfZ5fmddNNissxJvzIqCkDyvqZA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/koho/v16/K2F-fZ5fmddNNigUeA.ttf"
//   },
//   {
//     "family": "Libre Barcode 39 Text",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librebarcode39text/v28/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-031RRL-9rYaw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebarcode39text/v28/sJoa3KhViNKANw_E3LwoDXvs5Un0HQ1vT-0H1B5P.ttf"
//   },
//   {
//     "family": "Baloo Tamma 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/balootamma2/v16/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMsuPp-0IF71SGC5.ttf",
//       "600": "https://fonts.gstatic.com/s/balootamma2/v16/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMvCOZ-0IF71SGC5.ttf",
//       "700": "https://fonts.gstatic.com/s/balootamma2/v16/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMv7OZ-0IF71SGC5.ttf",
//       "800": "https://fonts.gstatic.com/s/balootamma2/v16/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMucOZ-0IF71SGC5.ttf",
//       "regular": "https://fonts.gstatic.com/s/balootamma2/v16/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMscPp-0IF71SGC5.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/balootamma2/v16/vEFE2_hCAgcR46PaajtrYlBbVUMUJgIC5LHTrMscPq-1Klo.ttf"
//   },
//   {
//     "family": "Noto Nastaliq Urdu",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notonastaliqurdu/v20/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qu3-DK2f2-_8mEw.ttf",
//       "600": "https://fonts.gstatic.com/s/notonastaliqurdu/v20/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3QgH5DK2f2-_8mEw.ttf",
//       "700": "https://fonts.gstatic.com/s/notonastaliqurdu/v20/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qjj5DK2f2-_8mEw.ttf",
//       "regular": "https://fonts.gstatic.com/s/notonastaliqurdu/v20/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qt_-DK2f2-_8mEw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notonastaliqurdu/v20/LhWNMUPbN-oZdNFcBy1-DJYsEoTq5pudQ9L940pGPkB3Qt_-PKyV3w.ttf"
//   },
//   {
//     "family": "Cambo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cambo/v17/IFSqHeNEk8FJk416ok7xkPm8.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cambo/v17/IFSqHeNEk8FJk717qEo.ttf"
//   },
//   {
//     "family": "Sunflower",
//     "variants": [
//       "300",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-06MfiqYsGBGBzCw.ttf",
//       "500": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-0sMbiqYsGBGBzCw.ttf",
//       "700": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-0-MDiqYsGBGBzCw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sunflower/v16/RWmPoKeF8fUjqIj7Vc-06MfSqIEC.ttf"
//   },
//   {
//     "family": "Ms Madi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/msmadi/v2/HTxsL2UxNnOji5E1N-DPiI7QAYo.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/msmadi/v2/HTxsL2UxNnOji5E1B-HFjA.ttf"
//   },
//   {
//     "family": "Marvel",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/marvel/v16/nwpWtKeoNgBV0qawLXHgB1WmxwkiYQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/marvel/v16/nwpVtKeoNgBV0qaIkV7ED366zg.ttf",
//       "italic": "https://fonts.gstatic.com/s/marvel/v16/nwpXtKeoNgBV0qa4k1TALXuqzhA7.ttf",
//       "700italic": "https://fonts.gstatic.com/s/marvel/v16/nwpQtKeoNgBV0qa4k2x8Al-i5QwyYdrc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/marvel/v16/nwpVtKeoNgBV0qa4kFTA.ttf"
//   },
//   {
//     "family": "Patrick Hand SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/patrickhandsc/v15/0nkwC9f7MfsBiWcLtY65AWDK873ViSi6JQc7Vg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/patrickhandsc/v15/0nkwC9f7MfsBiWcLtY65AWDK873liCK-.ttf"
//   },
//   {
//     "family": "Nova Mono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "greek",
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novamono/v20/Cn-0JtiGWQ5Ajb--MRKfYGxYrdM9Sg.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novamono/v20/Cn-0JtiGWQ5Ajb--MRKvYWZc.ttf"
//   },
//   {
//     "family": "Della Respira",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dellarespira/v22/RLp5K5v44KaueWI6iEJQBiGPRfkSu6EuTHo.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dellarespira/v22/RLp5K5v44KaueWI6iEJQBiGPdfgYvw.ttf"
//   },
//   {
//     "family": "Padauk",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "myanmar"
//     ],
//     "version": "v16",
//     "lastModified": "2022-11-09",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/padauk/v16/RrQSboJg-id7Onb512DE1JJEZ4YwGg.ttf",
//       "regular": "https://fonts.gstatic.com/s/padauk/v16/RrQRboJg-id7OnbBa0_g3LlYbg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/padauk/v16/RrQRboJg-id7OnbxakXk.ttf"
//   },
//   {
//     "family": "Baloo Paaji 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9Al74fybRUz1r5t.ttf",
//       "600": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9DJ6IfybRUz1r5t.ttf",
//       "700": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9Dw6IfybRUz1r5t.ttf",
//       "800": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9CX6IfybRUz1r5t.ttf",
//       "regular": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9AX74fybRUz1r5t.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baloopaaji2/v27/i7dfIFFzbz-QHZUdV9_UGWZuelmy79QJ1HOSY9AX77fzZxE.ttf"
//   },
//   {
//     "family": "Inknut Antiqua",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2vwrj5bBoIYJNf.ttf",
//       "500": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU33w7j5bBoIYJNf.ttf",
//       "600": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU3bxLj5bBoIYJNf.ttf",
//       "700": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2_xbj5bBoIYJNf.ttf",
//       "800": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2jxrj5bBoIYJNf.ttf",
//       "900": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GRYax7VC4ot_qNB4nYpBdaKU2Hx7j5bBoIYJNf.ttf",
//       "regular": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GSYax7VC4ot_qNB4nYpBdaKXUD6pzxRwYB.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inknutantiqua/v14/Y4GSYax7VC4ot_qNB4nYpBdaKUUC4Jg.ttf"
//   },
//   {
//     "family": "Geo",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/geo/v21/CSRz4zRZlufVL3BmQjlCbQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/geo/v21/CSRx4zRZluflLXpiYDxSbf8r.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/geo/v21/CSRz4zRZluflLnpi.ttf"
//   },
//   {
//     "family": "Caladea",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/caladea/v7/kJE2BugZ7AAjhybUtaNY39oYqO52FZ0.ttf",
//       "regular": "https://fonts.gstatic.com/s/caladea/v7/kJEzBugZ7AAjhybUjR93-9IztOc.ttf",
//       "italic": "https://fonts.gstatic.com/s/caladea/v7/kJExBugZ7AAjhybUvR19__A2pOdvDA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/caladea/v7/kJE0BugZ7AAjhybUvR1FQ98SrMxzBZ2lDA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/caladea/v7/kJEzBugZ7AAjhybUvR59_w.ttf"
//   },
//   {
//     "family": "Homenaje",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/homenaje/v16/FwZY7-Q-xVAi_l-6Ld6A4sijpFu_.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/homenaje/v16/FwZY7-Q-xVAi_l-6Le6B6Mw.ttf"
//   },
//   {
//     "family": "Antic Didone",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/anticdidone/v16/RWmPoKKX6u8sp8fIWdnDKqDiqYsGBGBzCw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anticdidone/v16/RWmPoKKX6u8sp8fIWdnDKqDSqIEC.ttf"
//   },
//   {
//     "family": "Over the Rainbow",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/overtherainbow/v20/11haGoXG1k_HKhMLUWz7Mc7vvW5upvOm9NA2XG0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/overtherainbow/v20/11haGoXG1k_HKhMLUWz7Mc7vvW5ulvKs8A.ttf"
//   },
//   {
//     "family": "Almendra",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/almendra/v26/H4cjBXKAlMnTn0Cskx6G7Zu4qKK-aihq.ttf",
//       "regular": "https://fonts.gstatic.com/s/almendra/v26/H4ckBXKAlMnTn0CskyY6wr-wg763.ttf",
//       "italic": "https://fonts.gstatic.com/s/almendra/v26/H4ciBXKAlMnTn0CskxY4yLuShq63czE.ttf",
//       "700italic": "https://fonts.gstatic.com/s/almendra/v26/H4chBXKAlMnTn0CskxY48Ae9oqacbzhqDtg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/almendra/v26/H4ckBXKAlMnTn0CskxY7yLs.ttf"
//   },
//   {
//     "family": "Amethysta",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-04-20",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/amethysta/v16/rP2Fp2K15kgb_F3ibfWIGDWCBl0O8Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amethysta/v16/rP2Fp2K15kgb_F3ibfW4GT-G.ttf"
//   },
//   {
//     "family": "Quando",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/quando/v16/xMQVuFNaVa6YuW0pC6WzKX_QmA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/quando/v16/xMQVuFNaVa6YuW0ZCq-3.ttf"
//   },
//   {
//     "family": "Capriola",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/capriola/v14/wXKoE3YSppcvo1PDln_8L-AinG8y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/capriola/v14/wXKoE3YSppcvo1PDlk_9JeQ.ttf"
//   },
//   {
//     "family": "Noto Sans Lao Looped",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "lao",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-09-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomPr2M-Zw5V_T71k.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomHr3M-Zw5V_T71k.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomKT3M-Zw5V_T71k.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomMj3M-Zw5V_T71k.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomCTwM-Zw5V_T71k.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomB3wM-Zw5V_T71k.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomHrwM-Zw5V_T71k.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomFPwM-Zw5V_T71k.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomPr3M-Zw5V_T71k.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslaolooped/v7/a8IgNpbwKmHXpgXbMIsbSMP7-3U72qUOX5gBg6LRXExhqHIX9YPTpvqkW4UthhjomPr3A-d64Q.ttf"
//   },
//   {
//     "family": "Hurricane",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hurricane/v7/pe0sMIuULZxTolZ5YldyAv2-C99ycg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hurricane/v7/pe0sMIuULZxTolZ5YldCA_e6.ttf"
//   },
//   {
//     "family": "Noto Sans Armenian",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "armenian",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v43",
//     "lastModified": "2023-11-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxbq0iYy6zF3Eg.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLopxb60iYy6zF3Eg.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLoqvb60iYy6zF3Eg.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorDb60iYy6zF3Eg.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLoovaK0iYy6zF3Eg.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLooWaK0iYy6zF3Eg.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLopxaK0iYy6zF3Eg.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLopYaK0iYy6zF3Eg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxb60iYy6zF3Eg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansarmenian/v43/ZgN0jOZKPa7CHqq0h37c7ReDUubm2SEdFXp7ig73qtTY5idb74R9UdM3y2nZLorxb50jaSo.ttf"
//   },
//   {
//     "family": "Montez",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/montez/v22/845ZNMk5GoGIX8lm1LDeSd-R_g.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/montez/v22/845ZNMk5GoGIX8lW1bra.ttf"
//   },
//   {
//     "family": "Pompiere",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pompiere/v19/VEMyRoxis5Dwuyeov6Wt5jDtreOL.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pompiere/v19/VEMyRoxis5Dwuyeov5Ws7DQ.ttf"
//   },
//   {
//     "family": "Baloo Thambi 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbK_8IzaQRG_n4osQ.ttf",
//       "600": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKE8UzaQRG_n4osQ.ttf",
//       "700": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKKsUzaQRG_n4osQ.ttf",
//       "800": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKTcUzaQRG_n4osQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKzcIzaQRG_n4osQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baloothambi2/v17/cY9RfjeOW0NHpmOQXranrbDyu5JMJmNp-aDvUBbKzcIDaA5C.ttf"
//   },
//   {
//     "family": "Bakbak One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bakbakone/v8/zOL54pXAl6RI-p_ardnuycRuv-hHkOs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bakbakone/v8/zOL54pXAl6RI-p_ardnu-cVkuw.ttf"
//   },
//   {
//     "family": "Germania One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/germaniaone/v20/Fh4yPjrqIyv2ucM2qzBjeS3ezAJONau6ew.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/germaniaone/v20/Fh4yPjrqIyv2ucM2qzBjeS3uzQhK.ttf"
//   },
//   {
//     "family": "Zen Kurenaido",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zenkurenaido/v16/3XFsEr0515BK2u6UUptu_gWJZfz22PRLd0U.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenkurenaido/v16/3XFsEr0515BK2u6UUptu_gWJVf383A.ttf"
//   },
//   {
//     "family": "Federo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-04-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/federo/v19/iJWFBX-cbD_ETsbmjVOe2WTG7Q.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/federo/v19/iJWFBX-cbD_ETsbWjFma.ttf"
//   },
//   {
//     "family": "Lekton",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/lekton/v17/SZc73FDmLaWmWpBm4zjMlWjX4DJXgQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/lekton/v17/SZc43FDmLaWmWpBeXxfonUPL6Q.ttf",
//       "italic": "https://fonts.gstatic.com/s/lekton/v17/SZc63FDmLaWmWpBuXR3sv0bb6StO.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lekton/v17/SZc43FDmLaWmWpBuXh3s.ttf"
//   },
//   {
//     "family": "Cutive",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cutive/v21/NaPZcZ_fHOhV3Ip7T_hDoyqlZQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cutive/v21/NaPZcZ_fHOhV3IpLTvJH.ttf"
//   },
//   {
//     "family": "Familjen Grotesk",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMG7aSztc1jcEYq2.ttf",
//       "600": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMFXbiztc1jcEYq2.ttf",
//       "700": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMFubiztc1jcEYq2.ttf",
//       "regular": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMGJaSztc1jcEYq2.ttf",
//       "italic": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKsSueVz-FJq2Rv4.ttf",
//       "500italic": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKvaueVz-FJq2Rv4.ttf",
//       "600italic": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKhqpeVz-FJq2Rv4.ttf",
//       "700italic": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw31ZR9ZHiDnImG6-NEMQ41wby8WbH8egZPOLG0oe9RBKiOpeVz-FJq2Rv4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/familjengrotesk/v8/Qw3LZR9ZHiDnImG6-NEMQ41wby8WRnYsfkunR_eGfMGJaRzseVw.ttf"
//   },
//   {
//     "family": "Amarante",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/amarante/v28/xMQXuF1KTa6EvGx9bq-3C3rAmD-b.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amarante/v28/xMQXuF1KTa6EvGx9bp-2AX4.ttf"
//   },
//   {
//     "family": "Hi Melody",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/himelody/v15/46ktlbP8Vnz0pJcqCTbEf29E31BBGA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/himelody/v15/46ktlbP8Vnz0pJcqCTb0fmVA.ttf"
//   },
//   {
//     "family": "Kadwa",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/kadwa/v10/rnCr-x5V0g7ipix7auM-mHnOSOuk.ttf",
//       "regular": "https://fonts.gstatic.com/s/kadwa/v10/rnCm-x5V0g7iphTHRcc2s2XH.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kadwa/v10/rnCm-x5V0g7ipiTGT8M.ttf"
//   },
//   {
//     "family": "Headland One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/headlandone/v16/yYLu0hHR2vKnp89Tk1TCq3Tx0PlTeZ3mJA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/headlandone/v16/yYLu0hHR2vKnp89Tk1TCq3TB0fNX.ttf"
//   },
//   {
//     "family": "Encode Sans Semi Expanded",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8xOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM-41KwrlKXeOEA.ttf",
//       "200": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM0IUCyDLJX6XCWU.ttf",
//       "300": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyYXCyDLJX6XCWU.ttf",
//       "500": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM34WCyDLJX6XCWU.ttf",
//       "600": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TM1IRCyDLJX6XCWU.ttf",
//       "700": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMzYQCyDLJX6XCWU.ttf",
//       "800": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMyoTCyDLJX6XCWU.ttf",
//       "900": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke8yOhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TMw4SCyDLJX6XCWU.ttf",
//       "regular": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TC4o_LyjgOXc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/encodesanssemiexpanded/v19/ke83OhAPMEZs-BDuzwftTNJ85JvwMOzE9d9Cca5TO4s1Kw.ttf"
//   },
//   {
//     "family": "Seymour One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/seymourone/v23/4iCp6Khla9xbjQpoWGGd0myIPYBvgpUI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/seymourone/v23/4iCp6Khla9xbjQpoWGGd0lyJN4Q.ttf"
//   },
//   {
//     "family": "Kaisei Decol",
//     "variants": [
//       "regular",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2022-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kaiseidecol/v8/bMrvmSqP45sidWf3QmfFW6iKr3gr00i_qb57kA.ttf",
//       "700": "https://fonts.gstatic.com/s/kaiseidecol/v8/bMrvmSqP45sidWf3QmfFW6iK534r00i_qb57kA.ttf",
//       "regular": "https://fonts.gstatic.com/s/kaiseidecol/v8/bMrwmSqP45sidWf3QmfFW6iyW1EP22OjoA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kaiseidecol/v8/bMrwmSqP45sidWf3QmfFW6iCWlsL.ttf"
//   },
//   {
//     "family": "Inder",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/inder/v14/w8gUH2YoQe8_4vq6pw-P3U4O.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inder/v14/w8gUH2YoQe8_4sq7rQs.ttf"
//   },
//   {
//     "family": "Lexend Zetta",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bH0z5jbs8qbts.ttf",
//       "200": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy1bG0z5jbs8qbts.ttf",
//       "300": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy4jG0z5jbs8qbts.ttf",
//       "500": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy-TG0z5jbs8qbts.ttf",
//       "600": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCywjB0z5jbs8qbts.ttf",
//       "700": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCyzHB0z5jbs8qbts.ttf",
//       "800": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy1bB0z5jbs8qbts.ttf",
//       "900": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy3_B0z5jbs8qbts.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bG0z5jbs8qbts.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexendzetta/v28/ll8uK2KYXje7CdOFnEWcU8synQbuVYjYB3BCy9bG4z9pag.ttf"
//   },
//   {
//     "family": "Brawler",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/brawler/v19/xn7lYHE3xXewAscGiryUb932eNaPfk8.ttf",
//       "regular": "https://fonts.gstatic.com/s/brawler/v19/xn7gYHE3xXewAscGsgC7S9XdZN8.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/brawler/v19/xn7gYHE3xXewAscGggGxTw.ttf"
//   },
//   {
//     "family": "Mouse Memoirs",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mousememoirs/v17/t5tmIRoSNJ-PH0WNNgDYxdSb7TnFrpOHYh4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mousememoirs/v17/t5tmIRoSNJ-PH0WNNgDYxdSb3TjPqg.ttf"
//   },
//   {
//     "family": "Asul",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/asul/v21/VuJxdNjKxYr40U8qeKbXOIFneRo.ttf",
//       "regular": "https://fonts.gstatic.com/s/asul/v21/VuJ-dNjKxYr46fMFXK78JIg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/asul/v21/VuJ-dNjKxYr42fIPWA.ttf"
//   },
//   {
//     "family": "Farro",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa3hNJ6-WkJUQUq7.ttf",
//       "500": "https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa25NZ6-WkJUQUq7.ttf",
//       "700": "https://fonts.gstatic.com/s/farro/v14/i7dJIFl3byGNHa3xM56-WkJUQUq7.ttf",
//       "regular": "https://fonts.gstatic.com/s/farro/v14/i7dEIFl3byGNHZVNHLq2cV5d.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/farro/v14/i7dEIFl3byGNHaVMFr4.ttf"
//   },
//   {
//     "family": "Anaheim",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/anaheim/v14/8vII7w042Wp87g4G0UTUEE5eK_w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anaheim/v14/8vII7w042Wp87g4G4UXeFA.ttf"
//   },
//   {
//     "family": "Recursive",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v38",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadDck018vwxjDJCL.ttf",
//       "500": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCwk018vwxjDJCL.ttf",
//       "600": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadBclE18vwxjDJCL.ttf",
//       "700": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadBllE18vwxjDJCL.ttf",
//       "800": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadAClE18vwxjDJCL.ttf",
//       "900": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadArlE18vwxjDJCL.ttf",
//       "regular": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCCk018vwxjDJCL.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/recursive/v38/8vJN7wMr0mhh-RQChyHEH06TlXhq_gukbYrFMk1QuAIcyEwG_X-dpEfaE5YaERmK-CImKsvxvU-MXGX2fSqasNfUvz2xbXfn1uEQadCCk319tQg.ttf"
//   },
//   {
//     "family": "ZCOOL XiaoWei",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1YFKQHwyVd3U.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1UFOaGw.ttf"
//   },
//   {
//     "family": "Bellota Text",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/bellotatext/v18/0FlMVP2VnlWS4f3-UE9hHXM5VfsqfQXwQy6yxg.ttf",
//       "700": "https://fonts.gstatic.com/s/bellotatext/v18/0FlMVP2VnlWS4f3-UE9hHXM5RfwqfQXwQy6yxg.ttf",
//       "300italic": "https://fonts.gstatic.com/s/bellotatext/v18/0FlOVP2VnlWS4f3-UE9hHXMx--Gmfw_0YSuixmYK.ttf",
//       "regular": "https://fonts.gstatic.com/s/bellotatext/v18/0FlTVP2VnlWS4f3-UE9hHXMB-dMOdS7sSg.ttf",
//       "italic": "https://fonts.gstatic.com/s/bellotatext/v18/0FlNVP2VnlWS4f3-UE9hHXMx-9kKVyv8Sjer.ttf",
//       "700italic": "https://fonts.gstatic.com/s/bellotatext/v18/0FlOVP2VnlWS4f3-UE9hHXMx--G2eA_0YSuixmYK.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bellotatext/v18/0FlTVP2VnlWS4f3-UE9hHXMx-NkK.ttf"
//   },
//   {
//     "family": "DotGothic16",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dotgothic16/v17/v6-QGYjBJFKgyw5nSoDAGE7L435YPFrT.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dotgothic16/v17/v6-QGYjBJFKgyw5nSoDAGH7K6Xo.ttf"
//   },
//   {
//     "family": "Share",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/share/v18/i7dJIFliZjKNF63xM56-WkJUQUq7.ttf",
//       "regular": "https://fonts.gstatic.com/s/share/v18/i7dEIFliZjKNF5VNHLq2cV5d.ttf",
//       "italic": "https://fonts.gstatic.com/s/share/v18/i7dKIFliZjKNF6VPFr6UdE5dWFM.ttf",
//       "700italic": "https://fonts.gstatic.com/s/share/v18/i7dPIFliZjKNF6VPLgK7UEZ2RFq7AwU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/share/v18/i7dEIFliZjKNF6VMFr4.ttf"
//   },
//   {
//     "family": "Aref Ruqaa",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2022-12-08",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/arefruqaa/v25/WwkYxPW1E165rajQKDulKDwNcNIS2N_7Bdk.ttf",
//       "regular": "https://fonts.gstatic.com/s/arefruqaa/v25/WwkbxPW1E165rajQKDulEIAiVNo5xNY.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arefruqaa/v25/WwkbxPW1E165rajQKDulIIEoUA.ttf"
//   },
//   {
//     "family": "Glory",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQwIiDpn-dDi9EOQ.ttf",
//       "200": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQQImDpn-dDi9EOQ.ttf",
//       "300": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQnomDpn-dDi9EOQ.ttf",
//       "500": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQ8omDpn-dDi9EOQ.ttf",
//       "600": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQHo6Dpn-dDi9EOQ.ttf",
//       "700": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQJ46Dpn-dDi9EOQ.ttf",
//       "800": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQQI6Dpn-dDi9EOQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQwImDpn-dDi9EOQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMpr5HWZLCpUOaM6.ttf",
//       "200italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMrr5XWZLCpUOaM6.ttf",
//       "300italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMo15XWZLCpUOaM6.ttf",
//       "italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMpr5XWZLCpUOaM6.ttf",
//       "500italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMpZ5XWZLCpUOaM6.ttf",
//       "600italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMq14nWZLCpUOaM6.ttf",
//       "700italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMqM4nWZLCpUOaM6.ttf",
//       "800italic": "https://fonts.gstatic.com/s/glory/v15/q5uYsoi9Lf1w5vfNgCJg98TBOoNFCMrr4nWZLCpUOaM6.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/glory/v15/q5uasoi9Lf1w5t3Est24nq9blIRQwImzp3WZ.ttf"
//   },
//   {
//     "family": "Sansita Swashed",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW-ppbToVehmEa4Q.ttf",
//       "500": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW4ZpbToVehmEa4Q.ttf",
//       "600": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW2pubToVehmEa4Q.ttf",
//       "700": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW1NubToVehmEa4Q.ttf",
//       "800": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSWzRubToVehmEa4Q.ttf",
//       "900": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSWx1ubToVehmEa4Q.ttf",
//       "regular": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW7RpbToVehmEa4Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sansitaswashed/v18/BXR8vFfZifTZgFlDDLgNkBydPKTt3pVCeYWqJnZSW7RpXTsffg.ttf"
//   },
//   {
//     "family": "BIZ UDGothic",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek-ext",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/bizudgothic/v9/daaASTouBF7RUjnbt8p3LuKVCSxZ-xTQZMhbaA.ttf",
//       "regular": "https://fonts.gstatic.com/s/bizudgothic/v9/daafSTouBF7RUjnbt8p3LuKttQN98z_MbQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bizudgothic/v9/daafSTouBF7RUjnbt8p3LuKdtAl5.ttf"
//   },
//   {
//     "family": "Sedgwick Ave",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sedgwickave/v12/uK_04rKEYuguzAcSYRdWTJq8Xmg1Vcf5JA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sedgwickave/v12/uK_04rKEYuguzAcSYRdWTJqMX2Ix.ttf"
//   },
//   {
//     "family": "RocknRoll One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rocknrollone/v10/kmK7ZqspGAfCeUiW6FFlmEC9guVhs7tfUxc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rocknrollone/v10/kmK7ZqspGAfCeUiW6FFlmEC9suRrtw.ttf"
//   },
//   {
//     "family": "Lexend Exa",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v30",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7T6bHHJ8BRq0b.ttf",
//       "200": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9p7TqbHHJ8BRq0b.ttf",
//       "300": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9qlTqbHHJ8BRq0b.ttf",
//       "500": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9rJTqbHHJ8BRq0b.ttf",
//       "600": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9olSabHHJ8BRq0b.ttf",
//       "700": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9ocSabHHJ8BRq0b.ttf",
//       "800": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9p7SabHHJ8BRq0b.ttf",
//       "900": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9pSSabHHJ8BRq0b.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7TqbHHJ8BRq0b.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexendexa/v30/UMBCrPdOoHOnxExyjdBeQCH18mulUxBvI9r7TpbGFps.ttf"
//   },
//   {
//     "family": "Gluten",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vb7B1Luni7ciJh.ttf",
//       "200": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Xb7R1Luni7ciJh.ttf",
//       "300": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8UF7R1Luni7ciJh.ttf",
//       "500": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vp7R1Luni7ciJh.ttf",
//       "600": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8WF6h1Luni7ciJh.ttf",
//       "700": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8W86h1Luni7ciJh.ttf",
//       "800": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Xb6h1Luni7ciJh.ttf",
//       "900": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Xy6h1Luni7ciJh.ttf",
//       "regular": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vb7R1Luni7ciJh.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gluten/v16/Hhy_U5gk9fW7OUdVIPh2zD_RSqQJ__A15jgJsn-Bhb_yI8Vb7S1KsHw.ttf"
//   },
//   {
//     "family": "Mr De Haviland",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mrdehaviland/v14/OpNVnooIhJj96FdB73296ksbOj3C4ULVNTlB.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mrdehaviland/v14/OpNVnooIhJj96FdB73296ksbOg3D60Y.ttf"
//   },
//   {
//     "family": "Expletus Sans",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/expletussans/v29/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaSq2s1oFQTcXfMm.ttf",
//       "600": "https://fonts.gstatic.com/s/expletussans/v29/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaRG3c1oFQTcXfMm.ttf",
//       "700": "https://fonts.gstatic.com/s/expletussans/v29/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaR_3c1oFQTcXfMm.ttf",
//       "regular": "https://fonts.gstatic.com/s/expletussans/v29/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaSY2s1oFQTcXfMm.ttf",
//       "italic": "https://fonts.gstatic.com/s/expletussans/v29/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmSUrHwD-WOMmKKY.ttf",
//       "500italic": "https://fonts.gstatic.com/s/expletussans/v29/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmRcrHwD-WOMmKKY.ttf",
//       "600italic": "https://fonts.gstatic.com/s/expletussans/v29/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmfssHwD-WOMmKKY.ttf",
//       "700italic": "https://fonts.gstatic.com/s/expletussans/v29/RLpoK5v5_bqufTYdnhFzDj2ddfsCtKHbhOZyCrFQmcIsHwD-WOMmKKY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/expletussans/v29/RLpqK5v5_bqufTYdnhFzDj2dX_IwS3my73zcDaSY2v1pHwA.ttf"
//   },
//   {
//     "family": "Sue Ellen Francisco",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sueellenfrancisco/v20/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9ropF2lqk9H4.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sueellenfrancisco/v20/wXK3E20CsoJ9j1DDkjHcQ5ZL8xRaxru9notP3g.ttf"
//   },
//   {
//     "family": "Lemon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lemon/v17/HI_EiYEVKqRMq0jBSZXAQ4-d.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lemon/v17/HI_EiYEVKqRMq3jAQ5E.ttf"
//   },
//   {
//     "family": "Yusei Magic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yuseimagic/v12/yYLt0hbAyuCmoo5wlhPkpjHR-tdfcIT_.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yuseimagic/v12/yYLt0hbAyuCmoo5wlhPkpgHQ8NM.ttf"
//   },
//   {
//     "family": "Wendy One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/wendyone/v18/2sDcZGJOipXfgfXV5wgDb2-4C7wFZQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/wendyone/v18/2sDcZGJOipXfgfXV5wgzbmW8.ttf"
//   },
//   {
//     "family": "Rouge Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rougescript/v18/LYjFdGbiklMoCIQOw1Ep3S4PVPXbUJWq9g.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rougescript/v18/LYjFdGbiklMoCIQOw1Ep3S4_Vf_f.ttf"
//   },
//   {
//     "family": "Inria Serif",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/inriaserif/v16/fC14PYxPY3rXxEndZJAzN3wAVQjFhFyta3xN.ttf",
//       "700": "https://fonts.gstatic.com/s/inriaserif/v16/fC14PYxPY3rXxEndZJAzN3wQUgjFhFyta3xN.ttf",
//       "300italic": "https://fonts.gstatic.com/s/inriaserif/v16/fC16PYxPY3rXxEndZJAzN3SuT4THjliPbmxN0_E.ttf",
//       "regular": "https://fonts.gstatic.com/s/inriaserif/v16/fC1lPYxPY3rXxEndZJAzN0SsfSzNr0Ck.ttf",
//       "italic": "https://fonts.gstatic.com/s/inriaserif/v16/fC1nPYxPY3rXxEndZJAzN3SudyjvqlCkcmU.ttf",
//       "700italic": "https://fonts.gstatic.com/s/inriaserif/v16/fC16PYxPY3rXxEndZJAzN3SuT5TAjliPbmxN0_E.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inriaserif/v16/fC1lPYxPY3rXxEndZJAzN3Stdyg.ttf"
//   },
//   {
//     "family": "Kufam",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3pKk7qQCJHvIwYg.ttf",
//       "600": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3SK47qQCJHvIwYg.ttf",
//       "700": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3ca47qQCJHvIwYg.ttf",
//       "800": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3Fq47qQCJHvIwYg.ttf",
//       "900": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3P647qQCJHvIwYg.ttf",
//       "regular": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqk7qQCJHvIwYg.ttf",
//       "italic": "https://fonts.gstatic.com/s/kufam/v21/C8c84cY7pG7w_q6APDMZN6kY3hbiXurT6gqNPPcgYp0i.ttf",
//       "500italic": "https://fonts.gstatic.com/s/kufam/v21/C8c84cY7pG7w_q6APDMZN6kY3hbiXurh6gqNPPcgYp0i.ttf",
//       "600italic": "https://fonts.gstatic.com/s/kufam/v21/C8c84cY7pG7w_q6APDMZN6kY3hbiXuoN7QqNPPcgYp0i.ttf",
//       "700italic": "https://fonts.gstatic.com/s/kufam/v21/C8c84cY7pG7w_q6APDMZN6kY3hbiXuo07QqNPPcgYp0i.ttf",
//       "800italic": "https://fonts.gstatic.com/s/kufam/v21/C8c84cY7pG7w_q6APDMZN6kY3hbiXupT7QqNPPcgYp0i.ttf",
//       "900italic": "https://fonts.gstatic.com/s/kufam/v21/C8c84cY7pG7w_q6APDMZN6kY3hbiXup67QqNPPcgYp0i.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kufam/v21/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqkLqAqN.ttf"
//   },
//   {
//     "family": "Give You Glory",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/giveyouglory/v15/8QIQdiHOgt3vv4LR7ahjw9-XYc1zB4ZD6rwa.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/giveyouglory/v15/8QIQdiHOgt3vv4LR7ahjw9-XYf1yDYI.ttf"
//   },
//   {
//     "family": "Azeret Mono",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPRh0raa-5s3AA.ttf",
//       "200": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfHPVh0raa-5s3AA.ttf",
//       "300": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfwvVh0raa-5s3AA.ttf",
//       "500": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfrvVh0raa-5s3AA.ttf",
//       "600": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfQvJh0raa-5s3AA.ttf",
//       "700": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfe_Jh0raa-5s3AA.ttf",
//       "800": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfHPJh0raa-5s3AA.ttf",
//       "900": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfNfJh0raa-5s3AA.ttf",
//       "regular": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPVh0raa-5s3AA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLaJkLye2Z4nAN7J.ttf",
//       "200italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLYJkbye2Z4nAN7J.ttf",
//       "300italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLbXkbye2Z4nAN7J.ttf",
//       "italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLaJkbye2Z4nAN7J.ttf",
//       "500italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLa7kbye2Z4nAN7J.ttf",
//       "600italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLZXlrye2Z4nAN7J.ttf",
//       "700italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLZulrye2Z4nAN7J.ttf",
//       "800italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLYJlrye2Z4nAN7J.ttf",
//       "900italic": "https://fonts.gstatic.com/s/azeretmono/v17/3XF_ErsiyJsY9O_Gepph-HHkf_fUKCzX1EOKVLYglrye2Z4nAN7J.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/azeretmono/v17/3XF5ErsiyJsY9O_Gepph-FvtTQgMQUdNekSfnPVR07ye.ttf"
//   },
//   {
//     "family": "Inria Sans",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/inriasans/v14/ptRPTiqXYfZMCOiVj9kQ3ELaDQtFqeY3fX4.ttf",
//       "700": "https://fonts.gstatic.com/s/inriasans/v14/ptRPTiqXYfZMCOiVj9kQ3FLdDQtFqeY3fX4.ttf",
//       "300italic": "https://fonts.gstatic.com/s/inriasans/v14/ptRRTiqXYfZMCOiVj9kQ1OzAgQlPrcQybX4pQA.ttf",
//       "regular": "https://fonts.gstatic.com/s/inriasans/v14/ptRMTiqXYfZMCOiVj9kQ5O7yKQNute8.ttf",
//       "italic": "https://fonts.gstatic.com/s/inriasans/v14/ptROTiqXYfZMCOiVj9kQ1Oz4LSFrpe8uZA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/inriasans/v14/ptRRTiqXYfZMCOiVj9kQ1OzAkQ5PrcQybX4pQA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inriasans/v14/ptRMTiqXYfZMCOiVj9kQ1O_4LQ.ttf"
//   },
//   {
//     "family": "Young Serif",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/youngserif/v2/3qTpojO2nS2VtkB3KtkQZ2t61EcYaQ7F.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/youngserif/v2/3qTpojO2nS2VtkB3KtkQZ1t73kM.ttf"
//   },
//   {
//     "family": "Allan",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/allan/v24/ea8aadU7WuTxEu5KEPCN2WpNgEKU.ttf",
//       "regular": "https://fonts.gstatic.com/s/allan/v24/ea8XadU7WuTxEtb2P9SF8nZE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/allan/v24/ea8XadU7WuTxEub3NdA.ttf"
//   },
//   {
//     "family": "Mountains of Christmas",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/mountainsofchristmas/v22/3y9z6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eBGqJFPtCOp6IaEA.ttf",
//       "regular": "https://fonts.gstatic.com/s/mountainsofchristmas/v22/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7ePNamMPNpJpc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mountainsofchristmas/v22/3y9w6a4zcCnn5X0FDyrKi2ZRUBIy8uxoUo7eDNesNA.ttf"
//   },
//   {
//     "family": "Poly",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/poly/v16/MQpb-W6wKNitRLCAq2Lpris.ttf",
//       "italic": "https://fonts.gstatic.com/s/poly/v16/MQpV-W6wKNitdLKKr0DsviuGWA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/poly/v16/MQpb-W6wKNitdLGKrw.ttf"
//   },
//   {
//     "family": "Vesper Libre",
//     "variants": [
//       "regular",
//       "500",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdA-2ap0okKXKvPlw.ttf",
//       "700": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdAs2Cp0okKXKvPlw.ttf",
//       "900": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6dNxyWnf-uxPdXDHUD_RdAi2Kp0okKXKvPlw.ttf",
//       "regular": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6CNxyWnf-uxPdXDHUD_Rd4D0-N2qIWVQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vesperlibre/v19/bx6CNxyWnf-uxPdXDHUD_RdIDkWJ.ttf"
//   },
//   {
//     "family": "Baloo Chettan 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "malayalam",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CdCTO1oeH9xI2gc.ttf",
//       "600": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CTyUO1oeH9xI2gc.ttf",
//       "700": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CQWUO1oeH9xI2gc.ttf",
//       "800": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CWKUO1oeH9xI2gc.ttf",
//       "regular": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CeKTO1oeH9xI2gc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baloochettan2/v21/vm8hdRbmXEva26PK-NtuX4ynWEzF69-L4gqgkIL5CeKTC1sUGw.ttf"
//   },
//   {
//     "family": "Coiny",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/coiny/v16/gyByhwU1K989PXwbElSvO5Tc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/coiny/v16/gyByhwU1K989PUwaGFA.ttf"
//   },
//   {
//     "family": "Oregano",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/oregano/v15/If2IXTPxciS3H4S2kZffPznO3yM.ttf",
//       "italic": "https://fonts.gstatic.com/s/oregano/v15/If2KXTPxciS3H4S2oZXVOxvLzyP_qw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oregano/v15/If2IXTPxciS3H4S2oZbVOw.ttf"
//   },
//   {
//     "family": "Vina Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vinasans/v6/m8JQjfZKf6-d2273MP7zcJ5BZmqa3A.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vinasans/v6/m8JQjfZKf6-d2273MP7DcZRF.ttf"
//   },
//   {
//     "family": "Gotu",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gotu/v16/o-0FIpksx3QOlH0Lioh6-hU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gotu/v16/o-0FIpksx3QOpHwBjg.ttf"
//   },
//   {
//     "family": "MuseoModerno",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZFuewajeKlCdo.ttf",
//       "200": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMtZEuewajeKlCdo.ttf",
//       "300": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMghEuewajeKlCdo.ttf",
//       "500": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMmREuewajeKlCdo.ttf",
//       "600": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMohDuewajeKlCdo.ttf",
//       "700": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMrFDuewajeKlCdo.ttf",
//       "800": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMtZDuewajeKlCdo.ttf",
//       "900": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMv9DuewajeKlCdo.ttf",
//       "regular": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZEuewajeKlCdo.ttf",
//       "100italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HUa4QicCgGdrS3g.ttf",
//       "200italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54H0a8QicCgGdrS3g.ttf",
//       "300italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HD68QicCgGdrS3g.ttf",
//       "italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HUa8QicCgGdrS3g.ttf",
//       "500italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HY68QicCgGdrS3g.ttf",
//       "600italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54Hj6gQicCgGdrS3g.ttf",
//       "700italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54HtqgQicCgGdrS3g.ttf",
//       "800italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54H0agQicCgGdrS3g.ttf",
//       "900italic": "https://fonts.gstatic.com/s/museomoderno/v27/zrfx0HnU0_7wWdMrFcWqSEXlXhPlgPcSP5dZJ54H-KgQicCgGdrS3g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/museomoderno/v27/zrf30HnU0_7wWdMrFcWqSEXPVyEaWJ55pTleMlZEie0QiQ.ttf"
//   },
//   {
//     "family": "Mukta Mahee",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9MFcBoHJndqZCsW.ttf",
//       "300": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9NhcxoHJndqZCsW.ttf",
//       "500": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9M5choHJndqZCsW.ttf",
//       "600": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9MVdRoHJndqZCsW.ttf",
//       "700": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9NxdBoHJndqZCsW.ttf",
//       "800": "https://fonts.gstatic.com/s/muktamahee/v16/XRXN3IOIi0hcP8iVU67hA9NtdxoHJndqZCsW.ttf",
//       "regular": "https://fonts.gstatic.com/s/muktamahee/v16/XRXQ3IOIi0hcP8iVU67hA-vNWz4PDWtj.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/muktamahee/v16/XRXQ3IOIi0hcP8iVU67hA9vMUTo.ttf"
//   },
//   {
//     "family": "Fresca",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fresca/v22/6ae94K--SKgCzbM2Gr0W13DKPA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fresca/v22/6ae94K--SKgCzbMGG7cS.ttf"
//   },
//   {
//     "family": "Mukta Vaani",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXNV8BD-u97MW1a.ttf",
//       "300": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGWpVMBD-u97MW1a.ttf",
//       "500": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXxVcBD-u97MW1a.ttf",
//       "600": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGXdUsBD-u97MW1a.ttf",
//       "700": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGW5U8BD-u97MW1a.ttf",
//       "800": "https://fonts.gstatic.com/s/muktavaani/v13/3JnkSD_-ynaxmxnEfVHPIGWlUMBD-u97MW1a.ttf",
//       "regular": "https://fonts.gstatic.com/s/muktavaani/v13/3Jn5SD_-ynaxmxnEfVHPIF0FfORL0fNy.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/muktavaani/v13/3Jn5SD_-ynaxmxnEfVHPIG0EduA.ttf"
//   },
//   {
//     "family": "Goblin One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/goblinone/v26/CSR64z1ZnOqZRjRCBVY_TOcATNt_pOU.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/goblinone/v26/CSR64z1ZnOqZRjRCBVY_fOYKSA.ttf"
//   },
//   {
//     "family": "Solway",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-02-16",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuLlgZms0QW3mqyg.ttf",
//       "500": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCudlkZms0QW3mqyg.ttf",
//       "700": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuPl8Zms0QW3mqyg.ttf",
//       "800": "https://fonts.gstatic.com/s/solway/v18/AMOTz46Cs2uTAOCuIlwZms0QW3mqyg.ttf",
//       "regular": "https://fonts.gstatic.com/s/solway/v18/AMOQz46Cs2uTAOCWgnA9kuYMUg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/solway/v18/AMOQz46Cs2uTAOCmg3o5.ttf"
//   },
//   {
//     "family": "Noto Sans Symbols",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "symbols"
//     ],
//     "version": "v42",
//     "lastModified": "2024-03-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ4gavVFRkzrbQ.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3g_Q8gavVFRkzrbQ.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gIw8gavVFRkzrbQ.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gTw8gavVFRkzrbQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gowggavVFRkzrbQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gmgggavVFRkzrbQ.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3g_QggavVFRkzrbQ.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3g1AggavVFRkzrbQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ8gavVFRkzrbQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssymbols/v42/rP2up3q65FkAtHfwd-eIS2brbDN6gxP34F9jRRCe4W3gfQ8Qa_9B.ttf"
//   },
//   {
//     "family": "Bungee Spice",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bungeespice/v11/nwpTtK2nIhxE0q-IwgSpZBqCzyI-aMPF7Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bungeespice/v11/nwpTtK2nIhxE0q-IwgSpZBqyzig6.ttf"
//   },
//   {
//     "family": "Happy Monkey",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/happymonkey/v14/K2F2fZZcl-9SXwl5F_C4R_OABwD2bWqVjw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/happymonkey/v14/K2F2fZZcl-9SXwl5F_C4R_OwBgry.ttf"
//   },
//   {
//     "family": "Croissant One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/croissantone/v26/3y9n6bU9bTPg4m8NDy3Kq24UM3pqn5cdJ-4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/croissantone/v26/3y9n6bU9bTPg4m8NDy3Kq24UA3tgmw.ttf"
//   },
//   {
//     "family": "Gantari",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-09-22",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOz3wa5GD2qnm.ttf",
//       "200": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g2gOj3wa5GD2qnm.ttf",
//       "300": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g1-Oj3wa5GD2qnm.ttf",
//       "500": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0SOj3wa5GD2qnm.ttf",
//       "600": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g3-PT3wa5GD2qnm.ttf",
//       "700": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g3HPT3wa5GD2qnm.ttf",
//       "800": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g2gPT3wa5GD2qnm.ttf",
//       "900": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g2JPT3wa5GD2qnm.ttf",
//       "regular": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOj3wa5GD2qnm.ttf",
//       "100italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoedWyYZWh37nmpWc.ttf",
//       "200italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeVWzYZWh37nmpWc.ttf",
//       "300italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeYuzYZWh37nmpWc.ttf",
//       "italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoedWzYZWh37nmpWc.ttf",
//       "500italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeeezYZWh37nmpWc.ttf",
//       "600italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeQu0YZWh37nmpWc.ttf",
//       "700italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeTK0YZWh37nmpWc.ttf",
//       "800italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeVW0YZWh37nmpWc.ttf",
//       "900italic": "https://fonts.gstatic.com/s/gantari/v1/jVyb7nvyB2HL8iZyJEc0qSzwj1Hs8RjoeXy0YZWh37nmpWc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gantari/v1/jVyV7nvyB2HL8iZyDk4GVvSZ5MtC9g0gOg3xYZU.ttf"
//   },
//   {
//     "family": "Esteban",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/esteban/v15/r05bGLZE-bdGdN-GdOuD5jokU8E.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/esteban/v15/r05bGLZE-bdGdN-GROqJ4g.ttf"
//   },
//   {
//     "family": "Finger Paint",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fingerpaint/v19/0QInMXVJ-o-oRn_7dron8YWO85bS8ANesw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fingerpaint/v19/0QInMXVJ-o-oRn_7dron8YW-8pzW.ttf"
//   },
//   {
//     "family": "Spline Sans",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/splinesans/v10/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpZlnYEtvlUfE2kw.ttf",
//       "500": "https://fonts.gstatic.com/s/splinesans/v10/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpClnYEtvlUfE2kw.ttf",
//       "600": "https://fonts.gstatic.com/s/splinesans/v10/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRp5l7YEtvlUfE2kw.ttf",
//       "700": "https://fonts.gstatic.com/s/splinesans/v10/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRp317YEtvlUfE2kw.ttf",
//       "regular": "https://fonts.gstatic.com/s/splinesans/v10/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpOFnYEtvlUfE2kw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/splinesans/v10/_6_sED73Uf-2WfU2LzycEZousNzn1a1lKWRpOFnoE9Hh.ttf"
//   },
//   {
//     "family": "Noto Sans Sinhala",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sinhala"
//     ],
//     "version": "v26",
//     "lastModified": "2022-09-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2b5lgLpJwbQRM.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwo2a5lgLpJwbQRM.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwlOa5lgLpJwbQRM.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwj-a5lgLpJwbQRM.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwtOd5lgLpJwbQRM.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwuqd5lgLpJwbQRM.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwo2d5lgLpJwbQRM.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwqSd5lgLpJwbQRM.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2a5lgLpJwbQRM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssinhala/v26/yMJ2MJBya43H0SUF_WmcBEEf4rQVO2P524V5N_MxQzQtb-tf5dJbC30Fu9zUwg2a1lkBoA.ttf"
//   },
//   {
//     "family": "Mochiy Pop One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mochiypopone/v9/QdVPSTA9Jh-gg-5XZP2UmU4O9kwwD3s6ZKAi.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mochiypopone/v9/QdVPSTA9Jh-gg-5XZP2UmU4O9nwxBX8.ttf"
//   },
//   {
//     "family": "Alike Angular",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v25",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alikeangular/v25/3qTrojWunjGQtEBlIcwMbSoI3kM6bB7FKjE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alikeangular/v25/3qTrojWunjGQtEBlIcwMbSoI7kIwaA.ttf"
//   },
//   {
//     "family": "Noto Sans Meetei Mayek",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "meetei-mayek"
//     ],
//     "version": "v15",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1TJ__TW5PgeFYVa.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1RJ_vTW5PgeFYVa.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1SX_vTW5PgeFYVa.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1T7_vTW5PgeFYVa.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1QX-fTW5PgeFYVa.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1Qu-fTW5PgeFYVa.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1RJ-fTW5PgeFYVa.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1Rg-fTW5PgeFYVa.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1TJ_vTW5PgeFYVa.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmeeteimayek/v15/HTxAL3QyKieByqY9eZPFweO0be7M21uSphSdhqILnmrRfJ8t_1TJ_sTX7vw.ttf"
//   },
//   {
//     "family": "Freehand",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/freehand/v31/cIf-Ma5eqk01VjKTgAmBTmUOmZJk.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/freehand/v31/cIf-Ma5eqk01VjKTgDmARGE.ttf"
//   },
//   {
//     "family": "Flamenco",
//     "variants": [
//       "300",
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/flamenco/v18/neIPzCehqYguo67ssZ0qNIkyepH9qGsf.ttf",
//       "regular": "https://fonts.gstatic.com/s/flamenco/v18/neIIzCehqYguo67ssaWGHK06UY30.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/flamenco/v18/neIIzCehqYguo67ssZWHFqk.ttf"
//   },
//   {
//     "family": "Noto Sans Georgian",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "georgian",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v44",
//     "lastModified": "2024-02-29",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzVj-f5WK0OQV.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdptnzFj-f5WK0OQV.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpu5zFj-f5WK0OQV.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvVzFj-f5WK0OQV.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdps5y1j-f5WK0OQV.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpsAy1j-f5WK0OQV.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdptny1j-f5WK0OQV.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdptOy1j-f5WK0OQV.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzFj-f5WK0OQV.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansgeorgian/v44/PlIaFke5O6RzLfvNNVSitxkr76PRHBC4Ytyq-Gof7PUs4S7zWn-8YDB09HFNdpvnzGj_dZE.ttf"
//   },
//   {
//     "family": "Aguafina Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/aguafinascript/v22/If2QXTv_ZzSxGIO30LemWEOmt1bHqs4pgicOrg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aguafinascript/v22/If2QXTv_ZzSxGIO30LemWEOmt1b3q8Qt.ttf"
//   },
//   {
//     "family": "Codystar",
//     "variants": [
//       "300",
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/codystar/v17/FwZf7-Q1xVk-40qxOuYsyuyrj0e29bfC.ttf",
//       "regular": "https://fonts.gstatic.com/s/codystar/v17/FwZY7-Q1xVk-40qxOt6A4sijpFu_.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/codystar/v17/FwZY7-Q1xVk-40qxOu6B6Mw.ttf"
//   },
//   {
//     "family": "Euphoria Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/euphoriascript/v20/mFTpWb0X2bLb_cx6To2B8GpKoD5ak_ZT1D8x7Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/euphoriascript/v20/mFTpWb0X2bLb_cx6To2B8GpKoD5qkvxX.ttf"
//   },
//   {
//     "family": "Carme",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/carme/v16/ptRHTiWdbvZIDOjGxLNrxfbZ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/carme/v16/ptRHTiWdbvZIDNjHzrc.ttf"
//   },
//   {
//     "family": "Notable",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notable/v18/gNMEW3N_SIqx-WX9-HMoFIez5MI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notable/v18/gNMEW3N_SIqx-WX9yHIiEA.ttf"
//   },
//   {
//     "family": "Bayon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v34",
//     "lastModified": "2024-02-16",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bayon/v34/9XUrlJNmn0LPFl-pOhYEd2NJ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bayon/v34/9XUrlJNmn0LPFm-oMBI.ttf"
//   },
//   {
//     "family": "Oleo Script Swash Caps",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-05-10",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noag6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HCcaBbYUsn9T5dt0.ttf",
//       "regular": "https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HMXquSY0Hg90.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oleoscriptswashcaps/v13/Noaj6Vb-w5SFbTTAsZP_7JkCS08K-jCzDn_HAXukTQ.ttf"
//   },
//   {
//     "family": "Supermercado One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/supermercadoone/v26/OpNXnpQWg8jc_xps_Gi14kVVEXOn60b3MClBRTs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/supermercadoone/v26/OpNXnpQWg8jc_xps_Gi14kVVEXOn20f9NA.ttf"
//   },
//   {
//     "family": "Rampart One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rampartone/v9/K2F1fZFGl_JSR1tAWNG9R6qgLS76ZHOM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rampartone/v9/K2F1fZFGl_JSR1tAWNG9R5qhJyo.ttf"
//   },
//   {
//     "family": "Walter Turncoat",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/walterturncoat/v23/snfys0Gs98ln43n0d-14ULoToe67YB2dQ5ZPqQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/walterturncoat/v23/snfys0Gs98ln43n0d-14ULoToe6LYReZ.ttf"
//   },
//   {
//     "family": "Fuggles",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fuggles/v12/k3kQo8UEJOlD1hpOTd7iL0nAMaM.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fuggles/v12/k3kQo8UEJOlD1hpOfd_oKw.ttf"
//   },
//   {
//     "family": "Averia Sans Libre",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd3lMKcQJZP1LmD9.ttf",
//       "700": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6SaxZG_G5OvCf_rt7FH3B6BHLMEd31N6cQJZP1LmD9.ttf",
//       "300italic": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKisSL5fXK3D9qtg.ttf",
//       "regular": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEeVJGIMYDo_8.ttf",
//       "italic": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6RaxZG_G5OvCf_rt7FH3B6BHLMEdVLEoc6C5_8N3k.ttf",
//       "700italic": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6caxZG_G5OvCf_rt7FH3B6BHLMEdVLKjsVL5fXK3D9qtg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/averiasanslibre/v19/ga6XaxZG_G5OvCf_rt7FH3B6BHLMEdVIEoc.ttf"
//   },
//   {
//     "family": "Kodchasan",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2023-05-02",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeR1Cggeqo3eMeoA.ttf",
//       "300": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeI1Oggeqo3eMeoA.ttf",
//       "500": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJee1Kggeqo3eMeoA.ttf",
//       "600": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeV1Wggeqo3eMeoA.ttf",
//       "700": "https://fonts.gstatic.com/s/kodchasan/v17/1cX0aUPOAJv9sG4I-DJeM1Sggeqo3eMeoA.ttf",
//       "200italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUlIgOCs_-YOoIgN.ttf",
//       "300italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUksg-Cs_-YOoIgN.ttf",
//       "regular": "https://fonts.gstatic.com/s/kodchasan/v17/1cXxaUPOAJv9sG4I-DJmj3uEicG01A.ttf",
//       "italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cX3aUPOAJv9sG4I-DJWjXGAq8Sk1PoH.ttf",
//       "500italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUl0guCs_-YOoIgN.ttf",
//       "600italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUlYheCs_-YOoIgN.ttf",
//       "700italic": "https://fonts.gstatic.com/s/kodchasan/v17/1cXqaUPOAJv9sG4I-DJWjUk8hOCs_-YOoIgN.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kodchasan/v17/1cXxaUPOAJv9sG4I-DJWjnGA.ttf"
//   },
//   {
//     "family": "IBM Plex Sans JP",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XLDn9KbTDf6_f7dISNqYf_tvPT7E7yjPB7twdmHQ.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7OLTrNpVuw5_BAM.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7IbQrNpVuw5_BAM.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7N7RrNpVuw5_BAM.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7PLWrNpVuw5_BAM.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XKDn9KbTDf6_f7dISNqYf_tvPT7JbXrNpVuw5_BAM.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XNDn9KbTDf6_f7dISNqYf_tvPT1Cr4iNJ-pwc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsansjp/v5/Z9XNDn9KbTDf6_f7dISNqYf_tvPT5CvyjA.ttf"
//   },
//   {
//     "family": "Shojumaru",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shojumaru/v15/rax_HiWfutkLLnaKCtlMBBJek0vA8A.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shojumaru/v15/rax_HiWfutkLLnaKCtl8BRha.ttf"
//   },
//   {
//     "family": "Suranna",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v13",
//     "lastModified": "2022-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/suranna/v13/gokuH6ztGkFjWe58tBRZT2KmgP0.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/suranna/v13/gokuH6ztGkFjWe58hBVTSw.ttf"
//   },
//   {
//     "family": "Abyssinica SIL",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "ethiopic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/abyssinicasil/v5/oY1H8ezOqK7iI3rK_45WKoc8J6UZBFOVAXuI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/abyssinicasil/v5/oY1H8ezOqK7iI3rK_45WKoc8J5UYDlc.ttf"
//   },
//   {
//     "family": "Tenali Ramakrishna",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tenaliramakrishna/v12/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-8KJXqUFFvtA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tenaliramakrishna/v12/raxgHj6Yt9gAN3LLKs0BZVMo8jmwn1-MKZ_u.ttf"
//   },
//   {
//     "family": "Duru Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/durusans/v20/xn7iYH8xwmSyTvEV_HOxT_fYdN-WZw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/durusans/v20/xn7iYH8xwmSyTvEV_HOBTv3c.ttf"
//   },
//   {
//     "family": "Bricolage Grotesque",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2023-09-13",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvZviyM0vs-wJDtw.ttf",
//       "300": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvUXiyM0vs-wJDtw.ttf",
//       "500": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvSniyM0vs-wJDtw.ttf",
//       "600": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvcXlyM0vs-wJDtw.ttf",
//       "700": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvfzlyM0vs-wJDtw.ttf",
//       "800": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvZvlyM0vs-wJDtw.ttf",
//       "regular": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvRviyM0vs-wJDtw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bricolagegrotesque/v2/3y9U6as8bTXq_nANBjzKo3IeZx8z6up5BeSl5jBNz_19PpbpMXuECpwUxJBOm_OJWiaaD30YfKfjZZoLvRvi-Mwltw.ttf"
//   },
//   {
//     "family": "Nerko One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nerkoone/v16/m8JQjfZSc7OXlB3ZMOjzcJ5BZmqa3A.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nerkoone/v16/m8JQjfZSc7OXlB3ZMOjDcZRF.ttf"
//   },
//   {
//     "family": "Elsie",
//     "variants": [
//       "regular",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "900": "https://fonts.gstatic.com/s/elsie/v24/BCaqqZABrez54x6q2-1IU6QeXSBk.ttf",
//       "regular": "https://fonts.gstatic.com/s/elsie/v24/BCanqZABrez54yYu9slAeLgX.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/elsie/v24/BCanqZABrez54xYv_M0.ttf"
//   },
//   {
//     "family": "Sofia Sans Semi Condensed",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoobEO9TGahllIhN.ttf",
//       "200": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoqbEe9TGahllIhN.ttf",
//       "300": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvopFEe9TGahllIhN.ttf",
//       "500": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoopEe9TGahllIhN.ttf",
//       "600": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvorFFu9TGahllIhN.ttf",
//       "700": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvor8Fu9TGahllIhN.ttf",
//       "800": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoqbFu9TGahllIhN.ttf",
//       "900": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoqyFu9TGahllIhN.ttf",
//       "regular": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoobEe9TGahllIhN.ttf",
//       "100italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUgcRE6xHkZhNeas.ttf",
//       "200italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUocQE6xHkZhNeas.ttf",
//       "300italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUlkQE6xHkZhNeas.ttf",
//       "italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUgcQE6xHkZhNeas.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUjUQE6xHkZhNeas.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUtkXE6xHkZhNeas.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUuAXE6xHkZhNeas.ttf",
//       "800italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUocXE6xHkZhNeas.ttf",
//       "900italic": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kMlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURsWYu_G5idVi7uZ_TUq4XE6xHkZhNeas.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sofiasanssemicondensed/v4/46kOlaPnUDrQoNsWDCGXXxYlujh5Wv0nwP4RwxURm28cA7YLHsIVvoobEd9SE6w.ttf"
//   },
//   {
//     "family": "Bigshot One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bigshotone/v29/u-470qukhRkkO6BD_7cM_gxuUQJBXv_-.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigshotone/v29/u-470qukhRkkO6BD_7cM_jxvWwY.ttf"
//   },
//   {
//     "family": "Just Me Again Down Here",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/justmeagaindownhere/v24/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAuwHvqDwc_fg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/justmeagaindownhere/v24/MwQmbgXtz-Wc6RUEGNMc0QpRrfUh2hSdBBMoAtwGtKQ.ttf"
//   },
//   {
//     "family": "Convergence",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-04-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/convergence/v15/rax5HiePvdgXPmmMHcIPYRhasU7Q8Cad.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/convergence/v15/rax5HiePvdgXPmmMHcIPYShbu0o.ttf"
//   },
//   {
//     "family": "Mogra",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mogra/v19/f0X40eSs8c95TBo4DvLmxtnG.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mogra/v19/f0X40eSs8c95TCo5BPY.ttf"
//   },
//   {
//     "family": "Wix Madefor Display",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2023-09-13",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcS3EX9IbbyeJ8aOluD52KXgUA_7Ed1I13G853Cp9duUYFTYltkv_3HQKgh.ttf",
//       "600": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcS3EX9IbbyeJ8aOluD52KXgUA_7Ed1I13G853Cp9duUYG_ZVtkv_3HQKgh.ttf",
//       "700": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcS3EX9IbbyeJ8aOluD52KXgUA_7Ed1I13G853Cp9duUYGGZVtkv_3HQKgh.ttf",
//       "800": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcS3EX9IbbyeJ8aOluD52KXgUA_7Ed1I13G853Cp9duUYHhZVtkv_3HQKgh.ttf",
//       "regular": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcS3EX9IbbyeJ8aOluD52KXgUA_7Ed1I13G853Cp9duUYFhYltkv_3HQKgh.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/wixmadefordisplay/v10/SZcS3EX9IbbyeJ8aOluD52KXgUA_7Ed1I13G853Cp9duUYFhYmtltfk.ttf"
//   },
//   {
//     "family": "Zen Antique Soft",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zenantiquesoft/v12/DtV4JwqzSL1q_KwnEWMc_3xfgW6ihwBmkui5HNg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenantiquesoft/v12/DtV4JwqzSL1q_KwnEWMc_3xfgW6itwFslg.ttf"
//   },
//   {
//     "family": "WindSong",
//     "variants": [
//       "regular",
//       "500"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/windsong/v11/KR1RBsyu-P-GFEW57oeNNPWylS3-jVXm.ttf",
//       "regular": "https://fonts.gstatic.com/s/windsong/v11/KR1WBsyu-P-GFEW57r95HdG6vjH3.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/windsong/v11/KR1WBsyu-P-GFEW57o94F9U.ttf"
//   },
//   {
//     "family": "Crafty Girls",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/craftygirls/v16/va9B4kXI39VaDdlPJo8N_NvuQR37fF3Wlg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/craftygirls/v16/va9B4kXI39VaDdlPJo8N_NveQBf_.ttf"
//   },
//   {
//     "family": "Numans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/numans/v15/SlGRmQmGupYAfH8IYRggiHVqaQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/numans/v15/SlGRmQmGupYAfH84YBIk.ttf"
//   },
//   {
//     "family": "Sumana",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/sumana/v10/4UaArE5TqRBjGj--TDfG54fN6ppsKg.ttf",
//       "regular": "https://fonts.gstatic.com/s/sumana/v10/4UaDrE5TqRBjGj-G8Bji76zR4w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sumana/v10/4UaDrE5TqRBjGj-28RLm.ttf"
//   },
//   {
//     "family": "Clicker Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/clickerscript/v13/raxkHiKPvt8CMH6ZWP8PdlEq72rY2zqUKafv.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/clickerscript/v13/raxkHiKPvt8CMH6ZWP8PdlEq71rZ0T4.ttf"
//   },
//   {
//     "family": "Sarpanch",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziV0ba7f1HEuRHkM.ttf",
//       "600": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziVYaq7f1HEuRHkM.ttf",
//       "700": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziU8a67f1HEuRHkM.ttf",
//       "800": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziUgaK7f1HEuRHkM.ttf",
//       "900": "https://fonts.gstatic.com/s/sarpanch/v13/hES16Xt4NCpRuk6PziUEaa7f1HEuRHkM.ttf",
//       "regular": "https://fonts.gstatic.com/s/sarpanch/v13/hESy6Xt4NCpRuk6Pzh2ARIrX_20n.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sarpanch/v13/hESy6Xt4NCpRuk6Pzi2BTo4.ttf"
//   },
//   {
//     "family": "Libre Caslon Display",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librecaslondisplay/v16/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr2lRdRhtCC4d.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librecaslondisplay/v16/TuGOUUFxWphYQ6YI6q9Xp61FQzxDRKmzr1lQfxw.ttf"
//   },
//   {
//     "family": "Martian Mono",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v3",
//     "lastModified": "2023-02-23",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1qus6WD75kdpF2.ttf",
//       "200": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY3qu86WD75kdpF2.ttf",
//       "300": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY00u86WD75kdpF2.ttf",
//       "500": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1Yu86WD75kdpF2.ttf",
//       "600": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY20vM6WD75kdpF2.ttf",
//       "700": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY2NvM6WD75kdpF2.ttf",
//       "800": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY3qvM6WD75kdpF2.ttf",
//       "regular": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1qu86WD75kdpF2.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/martianmono/v3/2V08KIcADoYhV6w87xrTKjs4CYElh_VS9YA4TlTnQzaVMIE6j15dYY1qu_6XBbo.ttf"
//   },
//   {
//     "family": "BIZ UDPMincho",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek-ext",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-01-18",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/bizudpmincho/v9/ypvCbXOBrmYppy7oWWTg1_58pqR3kU7fnZAy57k.ttf",
//       "regular": "https://fonts.gstatic.com/s/bizudpmincho/v9/ypvfbXOBrmYppy7oWWTg1_58nhhYtUb0gZk.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bizudpmincho/v9/ypvfbXOBrmYppy7oWWTg1_58rhlSsQ.ttf"
//   },
//   {
//     "family": "Xanh Mono",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-06-07",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/xanhmono/v18/R70YjykVmvKCep-vWhSYmACQXzLhTg.ttf",
//       "italic": "https://fonts.gstatic.com/s/xanhmono/v18/R70ejykVmvKCep-vWhSomgqUfTfxTo24.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/xanhmono/v18/R70YjykVmvKCep-vWhSomQqU.ttf"
//   },
//   {
//     "family": "Metamorphous",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/metamorphous/v20/Wnz8HA03aAXcC39ZEX5y1330PCCthTsmaQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/metamorphous/v20/Wnz8HA03aAXcC39ZEX5y133EPSqp.ttf"
//   },
//   {
//     "family": "Noto Sans Myanmar",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "myanmar"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZs_y1ZtY3ymOryg38hOCSdOnFq0HGS1uEapkAC3AY.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HE-98EwiEwLxR-r.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFa9MEwiEwLxR-r.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HEC9cEwiEwLxR-r.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HEu8sEwiEwLxR-r.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFK88EwiEwLxR-r.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFW8MEwiEwLxR-r.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZv_y1ZtY3ymOryg38hOCSdOnFq0HFy8cEwiEwLxR-r.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZq_y1ZtY3ymOryg38hOCSdOnFq0En23OU4o1AC.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmyanmar/v20/AlZq_y1ZtY3ymOryg38hOCSdOnFq0Hn31uE.ttf"
//   },
//   {
//     "family": "Potta One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pottaone/v16/FeVSS05Bp6cy7xI-YfxQ3Z5nm29Gww.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pottaone/v16/FeVSS05Bp6cy7xI-Yfxg3JRj.ttf"
//   },
//   {
//     "family": "League Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/leaguescript/v28/CSR54zpSlumSWj9CGVsoBZdeaNNUuOwkC2s.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/leaguescript/v28/CSR54zpSlumSWj9CGVsoBZdeWNJevA.ttf"
//   },
//   {
//     "family": "Goldman",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/goldman/v19/pe0rMIWbN4JFplR2FI5XIteQB9Zra1U.ttf",
//       "regular": "https://fonts.gstatic.com/s/goldman/v19/pe0uMIWbN4JFplR2LDJ4Bt-7G98.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/goldman/v19/pe0uMIWbN4JFplR2HDNyAg.ttf"
//   },
//   {
//     "family": "Vibur",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vibur/v23/DPEiYwmEzw0QRjTpLjoJd-Xa.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vibur/v23/DPEiYwmEzw0QRgToJD4.ttf"
//   },
//   {
//     "family": "Gaegu",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/gaegu/v17/TuGSUVB6Up9NU57nifw74sdtBk0x.ttf",
//       "700": "https://fonts.gstatic.com/s/gaegu/v17/TuGSUVB6Up9NU573jvw74sdtBk0x.ttf",
//       "regular": "https://fonts.gstatic.com/s/gaegu/v17/TuGfUVB6Up9NU6ZLodgzydtk.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gaegu/v17/TuGfUVB6Up9NU5ZKq9w.ttf"
//   },
//   {
//     "family": "Licorice",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/licorice/v6/t5tjIR8TMomTCAyjNk23hqLgzCHu.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/licorice/v6/t5tjIR8TMomTCAyjNn22jKY.ttf"
//   },
//   {
//     "family": "Ruslan Display",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v26",
//     "lastModified": "2024-01-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ruslandisplay/v26/Gw6jwczl81XcIZuckK_e3UpfdzxrldyFvm1n.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ruslandisplay/v26/Gw6jwczl81XcIZuckK_e3Upfdwxqn9g.ttf"
//   },
//   {
//     "family": "Manuale",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeG6e7wD1TB_JHHY.ttf",
//       "500": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHWe7wD1TB_JHHY.ttf",
//       "600": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeE6fLwD1TB_JHHY.ttf",
//       "700": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeEDfLwD1TB_JHHY.ttf",
//       "800": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeFkfLwD1TB_JHHY.ttf",
//       "regular": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke7wD1TB_JHHY.ttf",
//       "300italic": "https://fonts.gstatic.com/s/manuale/v28/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOApA3zRdIWHYr8M.ttf",
//       "italic": "https://fonts.gstatic.com/s/manuale/v28/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOFRA3zRdIWHYr8M.ttf",
//       "500italic": "https://fonts.gstatic.com/s/manuale/v28/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOGZA3zRdIWHYr8M.ttf",
//       "600italic": "https://fonts.gstatic.com/s/manuale/v28/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOIpH3zRdIWHYr8M.ttf",
//       "700italic": "https://fonts.gstatic.com/s/manuale/v28/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsOLNH3zRdIWHYr8M.ttf",
//       "800italic": "https://fonts.gstatic.com/s/manuale/v28/f0Xn0eas_8Z-TFZdNPTUzMkzITq8fvQsONRH3zRdIWHYr8M.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/manuale/v28/f0Xp0eas_8Z-TFZdHv3mMxFaSqASeeHke4wC3zQ.ttf"
//   },
//   {
//     "family": "Birthstone",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/birthstone/v14/8AtsGs2xO4yLRhy87sv_HLn5jRfZHzM.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/birthstone/v14/8AtsGs2xO4yLRhy87sv_LLjziQ.ttf"
//   },
//   {
//     "family": "ZCOOL QingKe HuangYou",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zcoolqingkehuangyou/v15/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mRIi_28c8bHWA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zcoolqingkehuangyou/v15/2Eb5L_R5IXJEWhD3AOhSvFC554MOOahI4mR4ive4.ttf"
//   },
//   {
//     "family": "Gugi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gugi/v15/A2BVn5dXywshVA6A9DEfgqM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gugi/v15/A2BVn5dXywshZA-K8A.ttf"
//   },
//   {
//     "family": "Vast Shadow",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vastshadow/v19/pe0qMImKOZ1V62ZwbVY9dfe6Kdpickwp.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vastshadow/v19/pe0qMImKOZ1V62ZwbVY9dce7I94.ttf"
//   },
//   {
//     "family": "Mako",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mako/v19/H4coBX6Mmc_Z0ST09g478Lo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mako/v19/H4coBX6Mmc_Z4SX-8g.ttf"
//   },
//   {
//     "family": "Artifika",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-01-10",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/artifika/v21/VEMyRoxzronptCuxu6Wt5jDtreOL.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/artifika/v21/VEMyRoxzronptCuxu5Ws7DQ.ttf"
//   },
//   {
//     "family": "Syne Mono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/synemono/v15/K2FzfZNHj_FHBmRbFvHzIqCkDyvqZA.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/synemono/v15/K2FzfZNHj_FHBmRbFvHDI6qg.ttf"
//   },
//   {
//     "family": "Slabo 13px",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/slabo13px/v15/11hEGp_azEvXZUdSBzzRcKer2wkYnvI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/slabo13px/v15/11hEGp_azEvXZUdSBzzRQKah3w.ttf"
//   },
//   {
//     "family": "Monomaniac One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/monomaniacone/v11/4iC06K17YctZjx50EU-QlwPmcqRnqYkB5kwI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/monomaniacone/v11/4iC06K17YctZjx50EU-QlwPmcpRmo40.ttf"
//   },
//   {
//     "family": "Prosto One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/prostoone/v19/OpNJno4VhNfK-RgpwWWxpipfWhXD00c.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/prostoone/v19/OpNJno4VhNfK-RgpwWWxlitVXg.ttf"
//   },
//   {
//     "family": "Noto Serif Malayalam",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "malayalam"
//     ],
//     "version": "v28",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-1fnVwHpQVySg.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1N-xfnVwHpQVySg.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL16exfnVwHpQVySg.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1hexfnVwHpQVySg.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1aetfnVwHpQVySg.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1UOtfnVwHpQVySg.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1N-tfnVwHpQVySg.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1HutfnVwHpQVySg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-xfnVwHpQVySg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifmalayalam/v28/JIAZUU5sdmdP_HMcVcZFcH7DeVBeGVgSMEk2cmVDq1ihUXL1t-xvnFYD.ttf"
//   },
//   {
//     "family": "Saira Stencil One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sairastencilone/v16/SLXSc03I6HkvZGJ1GvvipLoYSTEL9AsMawif2YQ2.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sairastencilone/v16/SLXSc03I6HkvZGJ1GvvipLoYSTEL9DsNYQw.ttf"
//   },
//   {
//     "family": "Loved by the King",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lovedbytheking/v21/Gw6gwdP76VDVJNXerebZxUMeRXUF2PiNlXFu2R64.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lovedbytheking/v21/Gw6gwdP76VDVJNXerebZxUMeRXUF2MiMn3U.ttf"
//   },
//   {
//     "family": "The Girl Next Door",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/thegirlnextdoor/v22/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCYIV7t7w6bE2A.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/thegirlnextdoor/v22/pe0zMJCIMIsBjFxqYBIcZ6_OI5oFHCY4VrF_.ttf"
//   },
//   {
//     "family": "Spicy Rice",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/spicyrice/v25/uK_24rSEd-Uqwk4jY1RyGv-2WkowRcc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/spicyrice/v25/uK_24rSEd-Uqwk4jY1RyKv68Xg.ttf"
//   },
//   {
//     "family": "Noto Sans NKo Unjoined",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "nko"
//     ],
//     "version": "v2",
//     "lastModified": "2023-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansnkounjoined/v2/MCo_zBjx1d3VUhJFK9MYlNCXJ6VvqwGPz3szPOBB5nuzMdWs0rv32gEPxf5wbh3T.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansnkounjoined/v2/MCo_zBjx1d3VUhJFK9MYlNCXJ6VvqwGPz3szPOBB5nuzMdWs0rsb3QEPxf5wbh3T.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansnkounjoined/v2/MCo_zBjx1d3VUhJFK9MYlNCXJ6VvqwGPz3szPOBB5nuzMdWs0rsi3QEPxf5wbh3T.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansnkounjoined/v2/MCo_zBjx1d3VUhJFK9MYlNCXJ6VvqwGPz3szPOBB5nuzMdWs0rvF2gEPxf5wbh3T.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnkounjoined/v2/MCo_zBjx1d3VUhJFK9MYlNCXJ6VvqwGPz3szPOBB5nuzMdWs0rvF2jEOz_o.ttf"
//   },
//   {
//     "family": "Imbue",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-12-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iWfOsNNK-Q4xY.ttf",
//       "200": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP0iXfOsNNK-Q4xY.ttf",
//       "300": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP5aXfOsNNK-Q4xY.ttf",
//       "500": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP_qXfOsNNK-Q4xY.ttf",
//       "600": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoPxaQfOsNNK-Q4xY.ttf",
//       "700": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoPy-QfOsNNK-Q4xY.ttf",
//       "800": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP0iQfOsNNK-Q4xY.ttf",
//       "900": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP2GQfOsNNK-Q4xY.ttf",
//       "regular": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iXfOsNNK-Q4xY.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imbue/v27/RLpXK5P16Ki3fXhj5cvGrqjocPk4n-gVX3M93TnrnvhoP8iXTOoHMA.ttf"
//   },
//   {
//     "family": "Major Mono Display",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/majormonodisplay/v16/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRhLCn2QIndPww.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/majormonodisplay/v16/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRh7C3eU.ttf"
//   },
//   {
//     "family": "Charmonman",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/charmonman/v18/MjQAmiR3vP_nuxDv47jiYC2HmL9K9OhmGnY.ttf",
//       "regular": "https://fonts.gstatic.com/s/charmonman/v18/MjQDmiR3vP_nuxDv47jiWJGovLdh6OE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/charmonman/v18/MjQDmiR3vP_nuxDv47jiaJCiuA.ttf"
//   },
//   {
//     "family": "Bilbo Swash Caps",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bilboswashcaps/v22/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdqAPopiRfKp8.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bilboswashcaps/v22/zrf-0GXbz-H3Wb4XBsGrTgq2PVmdmALiog.ttf"
//   },
//   {
//     "family": "Battambang",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2022-04-20",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/battambang/v24/uk-kEGe7raEw-HjkzZabNhGp5w50_o9T7Q.ttf",
//       "300": "https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNtmLxyRa8oZK9I0.ttf",
//       "700": "https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNsmMxyRa8oZK9I0.ttf",
//       "900": "https://fonts.gstatic.com/s/battambang/v24/uk-lEGe7raEw-HjkzZabNvGOxyRa8oZK9I0.ttf",
//       "regular": "https://fonts.gstatic.com/s/battambang/v24/uk-mEGe7raEw-HjkzZabDnWj4yxx7o8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/battambang/v24/uk-mEGe7raEw-HjkzZabPnSp5w.ttf"
//   },
//   {
//     "family": "Eater",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/eater/v25/mtG04_FCK7bOvpu2u3FwsXsR.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/eater/v25/mtG04_FCK7bOvqu3sXU.ttf"
//   },
//   {
//     "family": "Libre Barcode 128",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librebarcode128/v28/cIfnMbdUsUoiW3O_hVviCwVjuLtXeJ_A_gMk0izH.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebarcode128/v28/cIfnMbdUsUoiW3O_hVviCwVjuLtXeK_B9Ac.ttf"
//   },
//   {
//     "family": "Original Surfer",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/originalsurfer/v22/RWmQoKGZ9vIirYntXJ3_MbekzNMiDEtvAlaMKw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/originalsurfer/v22/RWmQoKGZ9vIirYntXJ3_MbekzNMSDUFr.ttf"
//   },
//   {
//     "family": "Montaga",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/montaga/v13/H4cnBX2Ml8rCkEO_0gYQ7LO5mqc.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/montaga/v13/H4cnBX2Ml8rCkEO_4gca6A.ttf"
//   },
//   {
//     "family": "Dongle",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/dongle/v12/sJoG3Ltdjt6VPkqeEcxrYjWNzXvVPA.ttf",
//       "700": "https://fonts.gstatic.com/s/dongle/v12/sJoG3Ltdjt6VPkqeActrYjWNzXvVPA.ttf",
//       "regular": "https://fonts.gstatic.com/s/dongle/v12/sJoF3Ltdjt6VPkqmveRPah6RxA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dongle/v12/sJoF3Ltdjt6VPkqWvO5L.ttf"
//   },
//   {
//     "family": "Sail",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sail/v16/DPEjYwiBxwYJFBTDADYAbvw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sail/v16/DPEjYwiBxwYJJBXJBA.ttf"
//   },
//   {
//     "family": "Noto Serif HK",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "chinese-hongkong",
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMf-K2RmV9Su1M6i.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMcgK2RmV9Su1M6i.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMdMK2RmV9Su1M6i.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMegLGRmV9Su1M6i.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMeZLGRmV9Su1M6i.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMf-LGRmV9Su1M6i.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMfXLGRmV9Su1M6i.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMd-K2RmV9Su1M6i.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifhk/v2/BngdUXBETWXI6LwlBZGcqL-B_KuJFcgfwP_9RMd-K1RnXdA.ttf"
//   },
//   {
//     "family": "Puritan",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/puritan/v24/845dNMgkAJ2VTtIozCbfYd6j-0rGRes.ttf",
//       "regular": "https://fonts.gstatic.com/s/puritan/v24/845YNMgkAJ2VTtIo9JrwRdaI50M.ttf",
//       "italic": "https://fonts.gstatic.com/s/puritan/v24/845aNMgkAJ2VTtIoxJj6QfSN90PfXA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/puritan/v24/845fNMgkAJ2VTtIoxJjC_dup_2jDVevnLQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/puritan/v24/845YNMgkAJ2VTtIoxJv6QQ.ttf"
//   },
//   {
//     "family": "Buenard",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/buenard/v17/OD5GuM6Cyma8FnnsB4vSjGCWALepwss.ttf",
//       "regular": "https://fonts.gstatic.com/s/buenard/v17/OD5DuM6Cyma8FnnsPzf9qGi9HL4.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/buenard/v17/OD5DuM6Cyma8FnnsDzb3rA.ttf"
//   },
//   {
//     "family": "Shalimar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shalimar/v7/uU9MCBoE6I6iNWFUvTPx8PCOg0uX.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shalimar/v7/uU9MCBoE6I6iNWFUvQPw-vQ.ttf"
//   },
//   {
//     "family": "Dokdo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dokdo/v17/esDf315XNuCBLxLo4NaMlKcH.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dokdo/v17/esDf315XNuCBLyLp6tI.ttf"
//   },
//   {
//     "family": "Atma",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-02",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo8JzKjc9PvedRkM.ttf",
//       "500": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo5pyKjc9PvedRkM.ttf",
//       "600": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo7Z1Kjc9PvedRkM.ttf",
//       "700": "https://fonts.gstatic.com/s/atma/v16/uK_z4rqWc-Eoo9J0Kjc9PvedRkM.ttf",
//       "regular": "https://fonts.gstatic.com/s/atma/v16/uK_84rqWc-Eom25bDj8WIv4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/atma/v16/uK_84rqWc-Eoq29RCg.ttf"
//   },
//   {
//     "family": "Imprima",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-03-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imprima/v18/VEMxRoN7sY3yuy-7-oWHyDzktPo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imprima/v18/VEMxRoN7sY3yuy-7yoSNzA.ttf"
//   },
//   {
//     "family": "Bellota",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAID55kGMViblPtXs.ttf",
//       "700": "https://fonts.gstatic.com/s/bellota/v16/MwQzbhXl3_qEpiwAIC5-kGMViblPtXs.ttf",
//       "300italic": "https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjHGEfjZtKpXulTQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAGJJRtGs-lbA.ttf",
//       "italic": "https://fonts.gstatic.com/s/bellota/v16/MwQ0bhXl3_qEpiwAKJBbsEk7hbBWrA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/bellota/v16/MwQxbhXl3_qEpiwAKJBjDGYfjZtKpXulTQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bellota/v16/MwQ2bhXl3_qEpiwAKJNbsA.ttf"
//   },
//   {
//     "family": "Kranky",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kranky/v28/hESw6XVgJzlPsFnMpheEZo_H_w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kranky/v28/hESw6XVgJzlPsFn8px2A.ttf"
//   },
//   {
//     "family": "Red Rose",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-10-19",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/redrose/v20/QdVISTYiLBjouPgEUajvsfWwDtc3MH8y8_sDcjSsYUVUjg.ttf",
//       "500": "https://fonts.gstatic.com/s/redrose/v20/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yn_sDcjSsYUVUjg.ttf",
//       "600": "https://fonts.gstatic.com/s/redrose/v20/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yc_wDcjSsYUVUjg.ttf",
//       "700": "https://fonts.gstatic.com/s/redrose/v20/QdVISTYiLBjouPgEUajvsfWwDtc3MH8ySvwDcjSsYUVUjg.ttf",
//       "regular": "https://fonts.gstatic.com/s/redrose/v20/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yrfsDcjSsYUVUjg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redrose/v20/QdVISTYiLBjouPgEUajvsfWwDtc3MH8yrfszcz6o.ttf"
//   },
//   {
//     "family": "Harmattan",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-05-23",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/harmattan/v19/gokpH6L2DkFvVvRp9Xprv2mHmNZEq6TTFw.ttf",
//       "600": "https://fonts.gstatic.com/s/harmattan/v19/gokpH6L2DkFvVvRp9Xprk26HmNZEq6TTFw.ttf",
//       "700": "https://fonts.gstatic.com/s/harmattan/v19/gokpH6L2DkFvVvRp9Xpr92-HmNZEq6TTFw.ttf",
//       "regular": "https://fonts.gstatic.com/s/harmattan/v19/goksH6L2DkFvVvRp9XpTS0CjkP1Yog.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/harmattan/v19/goksH6L2DkFvVvRp9XpjSkqn.ttf"
//   },
//   {
//     "family": "Sulphur Point",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLpkK5vv8KaycDcazWFPBj2afVU6n6kFUHPIFaU.ttf",
//       "700": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLpkK5vv8KaycDcazWFPBj2afUU9n6kFUHPIFaU.ttf",
//       "regular": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLp5K5vv8KaycDcazWFPBj2aRfkSu6EuTHo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sulphurpoint/v15/RLp5K5vv8KaycDcazWFPBj2adfgYvw.ttf"
//   },
//   {
//     "family": "Tienne",
//     "variants": [
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/tienne/v20/AYCJpX7pe9YCRP0zLGzjQHhuzvef5Q.ttf",
//       "900": "https://fonts.gstatic.com/s/tienne/v20/AYCJpX7pe9YCRP0zFG7jQHhuzvef5Q.ttf",
//       "regular": "https://fonts.gstatic.com/s/tienne/v20/AYCKpX7pe9YCRP0LkEPHSFNyxw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tienne/v20/AYCKpX7pe9YCRP07kUnD.ttf"
//   },
//   {
//     "family": "Slackey",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/slackey/v28/N0bV2SdQO-5yM0-dKlRaJdbWgdY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/slackey/v28/N0bV2SdQO-5yM0-dGlVQIQ.ttf"
//   },
//   {
//     "family": "Balthazar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/balthazar/v17/d6lKkaajS8Gm4CVQjFEvyRTo39l8hw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/balthazar/v17/d6lKkaajS8Gm4CVQjFEfyB7s.ttf"
//   },
//   {
//     "family": "Life Savers",
//     "variants": [
//       "regular",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/lifesavers/v20/ZXu_e1UftKKabUQMgxAal8HXOS5Tk8fIpPRW.ttf",
//       "800": "https://fonts.gstatic.com/s/lifesavers/v20/ZXu_e1UftKKabUQMgxAal8HLOi5Tk8fIpPRW.ttf",
//       "regular": "https://fonts.gstatic.com/s/lifesavers/v20/ZXuie1UftKKabUQMgxAal_lrFgpbuNvB.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lifesavers/v20/ZXuie1UftKKabUQMgxAal8lqHA4.ttf"
//   },
//   {
//     "family": "BhuTuka Expanded One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bhutukaexpandedone/v7/SLXXc0jZ4WUJcClHTtv0t7IaDRsBsWRiJCyX8pg_RVH1.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bhutukaexpandedone/v7/SLXXc0jZ4WUJcClHTtv0t7IaDRsBsWRiJByW-Jw.ttf"
//   },
//   {
//     "family": "Stick",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/stick/v17/Qw3TZQpMCyTtJSvfvPVDMPoF.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stick/v17/Qw3TZQpMCyTtJRvetvE.ttf"
//   },
//   {
//     "family": "Unkempt",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/unkempt/v21/2EbiL-Z2DFZue0DScTow1zWzq_5uT84.ttf",
//       "regular": "https://fonts.gstatic.com/s/unkempt/v21/2EbnL-Z2DFZue0DSSYYf8z2Yt_c.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/unkempt/v21/2EbnL-Z2DFZue0DSeYcV9w.ttf"
//   },
//   {
//     "family": "Delius Unicase",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/deliusunicase/v28/845CNMEwEIOVT8BmgfSzIr_6mlp7WMr_BmmlS5aw.ttf",
//       "regular": "https://fonts.gstatic.com/s/deliusunicase/v28/845BNMEwEIOVT8BmgfSzIr_6mmLHd-73LXWs.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/deliusunicase/v28/845BNMEwEIOVT8BmgfSzIr_6mlLGfeo.ttf"
//   },
//   {
//     "family": "Ceviche One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cevicheone/v16/gyB4hws1IcA6JzR-GB_JX6zdZ4vZVbgZ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cevicheone/v16/gyB4hws1IcA6JzR-GB_JX5zcbY8.ttf"
//   },
//   {
//     "family": "Trykker",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/trykker/v21/KtktALyWZJXudUPzhNnoOd2j22U.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/trykker/v21/KtktALyWZJXudUPztNjiPQ.ttf"
//   },
//   {
//     "family": "Mirza",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/mirza/v17/co3FmWlikiN5EtIpAeO4mafBomDi.ttf",
//       "600": "https://fonts.gstatic.com/s/mirza/v17/co3FmWlikiN5EtIFBuO4mafBomDi.ttf",
//       "700": "https://fonts.gstatic.com/s/mirza/v17/co3FmWlikiN5EtJhB-O4mafBomDi.ttf",
//       "regular": "https://fonts.gstatic.com/s/mirza/v17/co3ImWlikiN5EurdKMewsrvI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mirza/v17/co3ImWlikiN5EtrcIsM.ttf"
//   },
//   {
//     "family": "Lily Script One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lilyscriptone/v15/LhW9MV7ZMfIPdMxeBjBvFN8SXLS4gsSjQNsRMg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lilyscriptone/v15/LhW9MV7ZMfIPdMxeBjBvFN8SXLSIg86n.ttf"
//   },
//   {
//     "family": "Share Tech",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sharetech/v21/7cHtv4Uyi5K0OeZ7bohUwHoDmTcibrA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sharetech/v21/7cHtv4Uyi5K0OeZ7bohU8HsJnQ.ttf"
//   },
//   {
//     "family": "Road Rage",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/roadrage/v7/6NUU8F2fKAOBKjjr4ekvtMYAwdRZfw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/roadrage/v7/6NUU8F2fKAOBKjjr4ekftcwE.ttf"
//   },
//   {
//     "family": "Scope One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/scopeone/v14/WBLnrEXKYFlGHrOKmGD1W0_MJMGxiQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/scopeone/v14/WBLnrEXKYFlGHrOKmGDFWkXI.ttf"
//   },
//   {
//     "family": "Cherry Cream Soda",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cherrycreamsoda/v21/UMBIrOxBrW6w2FFyi9paG0fdVdRciTd6Cd47DJ7G.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cherrycreamsoda/v21/UMBIrOxBrW6w2FFyi9paG0fdVdRciQd7A9o.ttf"
//   },
//   {
//     "family": "Trade Winds",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tradewinds/v17/AYCPpXPpYNIIT7h8-QenM3Jq7PKP5Z_G.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tradewinds/v17/AYCPpXPpYNIIT7h8-QenM0Jr5vY.ttf"
//   },
//   {
//     "family": "Kaisei Opti",
//     "variants": [
//       "regular",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2022-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kaiseiopti/v8/QldXNThJphYb8_g6c2nlIGGqxY1u7f34DYwn.ttf",
//       "700": "https://fonts.gstatic.com/s/kaiseiopti/v8/QldXNThJphYb8_g6c2nlIGHiw41u7f34DYwn.ttf",
//       "regular": "https://fonts.gstatic.com/s/kaiseiopti/v8/QldKNThJphYb8_g6c2nlIFle7KlmxuHx.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kaiseiopti/v8/QldKNThJphYb8_g6c2nlIGlf5q0.ttf"
//   },
//   {
//     "family": "Viaoda Libre",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/viaodalibre/v18/vEFW2_lWCgoR6OKuRz9kcRVJb2IY2tOHXg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/viaodalibre/v18/vEFW2_lWCgoR6OKuRz9kcRV5bmgc.ttf"
//   },
//   {
//     "family": "Anek Latin",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuR7EZKdClWL3kgw.ttf",
//       "200": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3Pux7AZKdClWL3kgw.ttf",
//       "300": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuGbAZKdClWL3kgw.ttf",
//       "500": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PudbAZKdClWL3kgw.ttf",
//       "600": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PumbcZKdClWL3kgw.ttf",
//       "700": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuoLcZKdClWL3kgw.ttf",
//       "800": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3Pux7cZKdClWL3kgw.ttf",
//       "regular": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuR7AZKdClWL3kgw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aneklatin/v5/co3pmWZulTRoU4a8dqrWiajBS5ByUkvdrluH-xWG5uJTY4x-L3PuR7ApKNqh.ttf"
//   },
//   {
//     "family": "Barriecito",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/barriecito/v17/WWXXlj-CbBOSLY2QTuY_KdUiYwTO0MU.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/barriecito/v17/WWXXlj-CbBOSLY2QTuY_GdQoZw.ttf"
//   },
//   {
//     "family": "Anybody",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J12HPrsXD_nBPpQ.ttf",
//       "200": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JV2DPrsXD_nBPpQ.ttf",
//       "300": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JiWDPrsXD_nBPpQ.ttf",
//       "500": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J5WDPrsXD_nBPpQ.ttf",
//       "600": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JCWfPrsXD_nBPpQ.ttf",
//       "700": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JMGfPrsXD_nBPpQ.ttf",
//       "800": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JV2fPrsXD_nBPpQ.ttf",
//       "900": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4JfmfPrsXD_nBPpQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J12DPrsXD_nBPpQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyMn7M_H3HVfpcHY.ttf",
//       "200italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyOn7c_H3HVfpcHY.ttf",
//       "300italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyN57c_H3HVfpcHY.ttf",
//       "italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyMn7c_H3HVfpcHY.ttf",
//       "500italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyMV7c_H3HVfpcHY.ttf",
//       "600italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyP56s_H3HVfpcHY.ttf",
//       "700italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyPA6s_H3HVfpcHY.ttf",
//       "800italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyOn6s_H3HVfpcHY.ttf",
//       "900italic": "https://fonts.gstatic.com/s/anybody/v11/VuJddNvK2Ib2ppdWSKTHN4GOiYrmuF7VpPiuQ9r6sTRMJGkcHyOO6s_H3HVfpcHY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anybody/v11/VuJbdNvK2Ib2ppdWYq311GH32hxIv0sd5grncSUi2F_Wim4J12D_r8_H.ttf"
//   },
//   {
//     "family": "Noto Serif Telugu",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "telugu"
//     ],
//     "version": "v26",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TGwuY2fjgrZYA.ttf",
//       "200": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DdTCwuY2fjgrZYA.ttf",
//       "300": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DqzCwuY2fjgrZYA.ttf",
//       "500": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DxzCwuY2fjgrZYA.ttf",
//       "600": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DKzewuY2fjgrZYA.ttf",
//       "700": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DEjewuY2fjgrZYA.ttf",
//       "800": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DdTewuY2fjgrZYA.ttf",
//       "900": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9DXDewuY2fjgrZYA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TCwuY2fjgrZYA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriftelugu/v26/tDbl2pCbnkEKmXNVmt2M1q6f4HWbbj6MRbYEeav7Fe9D9TCAuIeb.ttf"
//   },
//   {
//     "family": "Noto Emoji",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "emoji"
//     ],
//     "version": "v47",
//     "lastModified": "2023-09-27",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/notoemoji/v47/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob_10jwvS-FGJCMY.ttf",
//       "500": "https://fonts.gstatic.com/s/notoemoji/v47/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob-Z0jwvS-FGJCMY.ttf",
//       "600": "https://fonts.gstatic.com/s/notoemoji/v47/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob911TwvS-FGJCMY.ttf",
//       "700": "https://fonts.gstatic.com/s/notoemoji/v47/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob9M1TwvS-FGJCMY.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoemoji/v47/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob-r0jwvS-FGJCMY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoemoji/v47/bMrnmSyK7YY-MEu6aWjPDs-ar6uWaGWuob-r0gwuQeU.ttf"
//   },
//   {
//     "family": "Nova Round",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novaround/v21/flU9Rqquw5UhEnlwTJYTYYfeeetYEBc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novaround/v21/flU9Rqquw5UhEnlwTJYTUYbUfQ.ttf"
//   },
//   {
//     "family": "Shanti",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shanti/v25/t5thIREMM4uSDgzgU0ezpKfwzA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shanti/v25/t5thIREMM4uSDgzQUk23.ttf"
//   },
//   {
//     "family": "Caprasimo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/caprasimo/v5/esDT31JQOPuXIUGBp72klZUCGpG-GQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/caprasimo/v5/esDT31JQOPuXIUGBp72UlJ8G.ttf"
//   },
//   {
//     "family": "Cormorant Unicase",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9N_tucv7Gy0DRzS.ttf",
//       "500": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Mnt-cv7Gy0DRzS.ttf",
//       "600": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9MLsOcv7Gy0DRzS.ttf",
//       "700": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_ViZUaILtOqhqgDeXoF_n1_fTGX9Nvsecv7Gy0DRzS.ttf",
//       "regular": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_QiZUaILtOqhqgDeXoF_n1_fTGX-vTnsMnx3C9.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cormorantunicase/v24/HI_QiZUaILtOqhqgDeXoF_n1_fTGX9vSlMc.ttf"
//   },
//   {
//     "family": "Dynalight",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dynalight/v22/1Ptsg8LOU_aOmQvTsF4ISotrDfGGxA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dynalight/v22/1Ptsg8LOU_aOmQvTsF44S4Fv.ttf"
//   },
//   {
//     "family": "IBM Plex Sans Hebrew",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa4qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEXB-l0VqDaM7C4.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVt230_hjqF9Tc2.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUJ2H0_hjqF9Tc2.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEVR2X0_hjqF9Tc2.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEV93n0_hjqF9Tc2.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa5qYENg9Kw1mpLpO0bGM5lfHAAZHhDXEUZ330_hjqF9Tc2.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXH2l8Fk3rSaM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsanshebrew/v11/BCa2qYENg9Kw1mpLpO0bGM5lfHAAZHhDXE2k-l0.ttf"
//   },
//   {
//     "family": "Baumans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-04-20",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/baumans/v17/-W_-XJj9QyTd3QfpR_oyaksqY5Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baumans/v17/-W_-XJj9QyTd3Qfpd_s4bg.ttf"
//   },
//   {
//     "family": "Doppio One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/doppioone/v13/Gg8wN5gSaBfyBw2MqCh-lgshKGpe5Fg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/doppioone/v13/Gg8wN5gSaBfyBw2MqCh-pgorLA.ttf"
//   },
//   {
//     "family": "IM Fell Double Pica",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rT0S1UL5Ayp0.ttf",
//       "italic": "https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF0EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0a_VJxF2p2G8g.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfelldoublepica/v14/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0W_VA.ttf"
//   },
//   {
//     "family": "Timmana",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v12",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/timmana/v12/6xKvdShfL9yK-rvpCmvbKHwJUOM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/timmana/v12/6xKvdShfL9yK-rvpOmrRLA.ttf"
//   },
//   {
//     "family": "Nokora",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v31",
//     "lastModified": "2023-09-06",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/nokora/v31/hYkKPuwgTubzaWxoXzALgPNw8QZN.ttf",
//       "300": "https://fonts.gstatic.com/s/nokora/v31/hYkLPuwgTubzaWxolxIrqt18-B9Uuw.ttf",
//       "700": "https://fonts.gstatic.com/s/nokora/v31/hYkLPuwgTubzaWxohxUrqt18-B9Uuw.ttf",
//       "900": "https://fonts.gstatic.com/s/nokora/v31/hYkLPuwgTubzaWxovxcrqt18-B9Uuw.ttf",
//       "regular": "https://fonts.gstatic.com/s/nokora/v31/hYkIPuwgTubzaWxQOzoPovZg8Q.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nokora/v31/hYkIPuwgTubzaWxgOjAL.ttf"
//   },
//   {
//     "family": "Kaisei Tokumin",
//     "variants": [
//       "regular",
//       "500",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2022-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8vN5wdZg7xCwuMsylww2ZiQnqr_3khpMIzeI6v.ttf",
//       "700": "https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8vN5wdZg7xCwuMsylww2ZiQnrj-XkhpMIzeI6v.ttf",
//       "800": "https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8vN5wdZg7xCwuMsylww2ZiQnr_-nkhpMIzeI6v.ttf",
//       "regular": "https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8sN5wdZg7xCwuMsylww2ZiQkJf1l0pj946.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kaiseitokumin/v8/Gg8sN5wdZg7xCwuMsylww2ZiQnJe3Fk.ttf"
//   },
//   {
//     "family": "Wire One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/wireone/v28/qFdH35Wah5htUhV75WGiWdrCwwcJ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/wireone/v28/qFdH35Wah5htUhV75VGjU94.ttf"
//   },
//   {
//     "family": "Scheherazade New",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-23",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaerFhTvxVnHDvUkUiHg8jprP4DM_dFHlYC-IKnoSE.ttf",
//       "600": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaerFhTvxVnHDvUkUiHg8jprP4DM9tCHlYC-IKnoSE.ttf",
//       "700": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaerFhTvxVnHDvUkUiHg8jprP4DM79DHlYC-IKnoSE.ttf",
//       "regular": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaZrFhTvxVnHDvUkUiHg8jprP4DCwNsOl4p5Is.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/scheherazadenew/v15/4UaZrFhTvxVnHDvUkUiHg8jprP4DOwJmPg.ttf"
//   },
//   {
//     "family": "Sura",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/sura/v19/SZc53FL5PbyzLUJ7fz3GkUrS8DI.ttf",
//       "regular": "https://fonts.gstatic.com/s/sura/v19/SZc23FL5PbyzFf5UWzXtjUM.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sura/v19/SZc23FL5PbyzJf9eXw.ttf"
//   },
//   {
//     "family": "Corinthia",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/corinthia/v11/wEO6EBrAnchaJyPMHE097d8v1GAbgbLXQA.ttf",
//       "regular": "https://fonts.gstatic.com/s/corinthia/v11/wEO_EBrAnchaJyPMHE0FUfAL3EsHiA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/corinthia/v11/wEO_EBrAnchaJyPMHE01UPoP.ttf"
//   },
//   {
//     "family": "Pixelify Sans",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/pixelifysans/v1/CHy2V-3HFUT7aC4iv1TxGDR9DHEserHN25py2TTb0H1Yb5JagkmX.ttf",
//       "600": "https://fonts.gstatic.com/s/pixelifysans/v1/CHy2V-3HFUT7aC4iv1TxGDR9DHEserHN25py2TQ3131Yb5JagkmX.ttf",
//       "700": "https://fonts.gstatic.com/s/pixelifysans/v1/CHy2V-3HFUT7aC4iv1TxGDR9DHEserHN25py2TQO131Yb5JagkmX.ttf",
//       "regular": "https://fonts.gstatic.com/s/pixelifysans/v1/CHy2V-3HFUT7aC4iv1TxGDR9DHEserHN25py2TTp0H1Yb5JagkmX.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pixelifysans/v1/CHy2V-3HFUT7aC4iv1TxGDR9DHEserHN25py2TTp0E1ZZZY.ttf"
//   },
//   {
//     "family": "Protest Revolution",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-02-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/protestrevolution/v2/11hcGofZ0kXBbxQXFB7MJsjtqnVw6Z2s8PIzTG1nQw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/protestrevolution/v2/11hcGofZ0kXBbxQXFB7MJsjtqnVw6Z2c8fg3.ttf"
//   },
//   {
//     "family": "Fontdiner Swanky",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fontdinerswanky/v23/ijwOs4XgRNsiaI5-hcVb4hQgMvCD4uEfKiGvxts.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fontdinerswanky/v23/ijwOs4XgRNsiaI5-hcVb4hQgMvCD0uAVLg.ttf"
//   },
//   {
//     "family": "Ledger",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ledger/v16/j8_q6-HK1L3if_sxm8DwHTBhHw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ledger/v16/j8_q6-HK1L3if_sBmsr0.ttf"
//   },
//   {
//     "family": "Hina Mincho",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hinamincho/v12/2sDaZGBRhpXa2Jjz5w5LAGW8KbkVZTHR.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hinamincho/v12/2sDaZGBRhpXa2Jjz5w5LAFW9I70.ttf"
//   },
//   {
//     "family": "Chau Philomene One",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chauphilomeneone/v15/55xxezRsPtfie1vPY49qzdgSlJiHRQFsnIx7QMISdQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/chauphilomeneone/v15/55xzezRsPtfie1vPY49qzdgSlJiHRQFcnoZ_YscCdXQB.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chauphilomeneone/v15/55xxezRsPtfie1vPY49qzdgSlJiHRQFcnYZ_.ttf"
//   },
//   {
//     "family": "Bubbler One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bubblerone/v20/f0Xy0eqj68ppQV9KBLmAouHH26MPePkt.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bubblerone/v20/f0Xy0eqj68ppQV9KBLmAotHG0ac.ttf"
//   },
//   {
//     "family": "Instrument Serif",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/instrumentserif/v4/jizBRFtNs2ka5fXjeivQ4LroWlx-2zIZj1bIkNo.ttf",
//       "italic": "https://fonts.gstatic.com/s/instrumentserif/v4/jizHRFtNs2ka5fXjeivQ4LroWlx-6zATi3TNgNq55w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/instrumentserif/v4/jizBRFtNs2ka5fXjeivQ4LroWlx-6zMTiw.ttf"
//   },
//   {
//     "family": "Peralta",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/peralta/v19/hYkJPu0-RP_9d3kRGxAhrv956B8.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/peralta/v19/hYkJPu0-RP_9d3kRKxErqg.ttf"
//   },
//   {
//     "family": "Shantell Sans",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/shantellsans/v9/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYQiS2i2yPwxjyRN.ttf",
//       "500": "https://fonts.gstatic.com/s/shantellsans/v9/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYROS2i2yPwxjyRN.ttf",
//       "600": "https://fonts.gstatic.com/s/shantellsans/v9/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYSiTGi2yPwxjyRN.ttf",
//       "700": "https://fonts.gstatic.com/s/shantellsans/v9/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYSbTGi2yPwxjyRN.ttf",
//       "800": "https://fonts.gstatic.com/s/shantellsans/v9/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYT8TGi2yPwxjyRN.ttf",
//       "regular": "https://fonts.gstatic.com/s/shantellsans/v9/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYR8S2i2yPwxjyRN.ttf",
//       "300italic": "https://fonts.gstatic.com/s/shantellsans/v9/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CN71wvgTijRNYgQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/shantellsans/v9/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CID1wvgTijRNYgQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/shantellsans/v9/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CLL1wvgTijRNYgQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/shantellsans/v9/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CF7ywvgTijRNYgQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/shantellsans/v9/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CGfywvgTijRNYgQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/shantellsans/v9/FeUcS0pCoLIo-lcdY7kjvNoQg2xkycTqsuA6bi9pTt8YiT-NXidjb_ee-maigL6R8nKVh8BbE1mv4wwmM0WUkSqmTpG0CADywvgTijRNYgQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shantellsans/v9/FeUaS0pCoLIo-lcdY7kjvNoQqWVWB0qWpl29ajppTuUTu_kJKmHesPOL-maYi4xZeHCNQ09eBlmv2QcUzJ39-rAISYR8S1i3wvg.ttf"
//   },
//   {
//     "family": "Faster One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-03-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fasterone/v19/H4ciBXCHmdfClFb-vWhfyLuShq63czE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fasterone/v19/H4ciBXCHmdfClFb-vWhf-LqYgg.ttf"
//   },
//   {
//     "family": "Voces",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/voces/v22/-F6_fjJyLyU8d4PBBG7YpzlJ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/voces/v22/-F6_fjJyLyU8d7PADmo.ttf"
//   },
//   {
//     "family": "Libre Barcode 39 Extended Text",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librebarcode39extendedtext/v27/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP68fLIiPopNNA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebarcode39extendedtext/v27/eLG1P_rwIgOiDA7yrs9LoKaYRVLQ1YldrrOnnL7xPO4jNP6Mfbgm.ttf"
//   },
//   {
//     "family": "Mochiy Pop P One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mochiypoppone/v9/Ktk2AKuPeY_td1-h9LayHYWCjAqyN4O3WYZB_sU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mochiypoppone/v9/Ktk2AKuPeY_td1-h9LayHYWCjAqyB4K9XQ.ttf"
//   },
//   {
//     "family": "Noto Sans Old Persian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-persian"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansoldpersian/v16/wEOjEAbNnc5caQTFG18FHrZr9Bp6-8CmIJ_tqOlQfx9CjA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoldpersian/v16/wEOjEAbNnc5caQTFG18FHrZr9Bp6-8CmIJ_dqeNU.ttf"
//   },
//   {
//     "family": "Modak",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/modak/v18/EJRYQgs1XtIEsnMH8BVZ76KU.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/modak/v18/EJRYQgs1XtIEskMG-hE.ttf"
//   },
//   {
//     "family": "Pavanam",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/pavanam/v11/BXRrvF_aiezLh0xPDOtQ9Wf0QcE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/pavanam/v11/BXRrvF_aiezLh0xPPOpa8Q.ttf"
//   },
//   {
//     "family": "Nova Square",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novasquare/v24/RrQUbo9-9DV7b06QHgSWsZhARYMgGtWA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novasquare/v24/RrQUbo9-9DV7b06QHgSWsahBT4c.ttf"
//   },
//   {
//     "family": "Belgrano",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v18",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/belgrano/v18/55xvey5tM9rwKWrJZcMFirl08KDJ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/belgrano/v18/55xvey5tM9rwKWrJZfMEgL0.ttf"
//   },
//   {
//     "family": "Overlock SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/overlocksc/v23/1cX3aUHKGZrstGAY8nwVzHGAq8Sk1PoH.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/overlocksc/v23/1cX3aUHKGZrstGAY8nwVzEGBocA.ttf"
//   },
//   {
//     "family": "ADLaM Display",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "adlam",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-08-17",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/adlamdisplay/v1/KFOhCnGXkPOLlhx6jD8_b1ZECsHYkYBPY3o.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/adlamdisplay/v1/KFOhCnGXkPOLlhx6jD8_b1ZEOsDSlQ.ttf"
//   },
//   {
//     "family": "Freckle Face",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/freckleface/v15/AMOWz4SXrmKHCvXTohxY-YI0U1K2w9lb4g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/freckleface/v15/AMOWz4SXrmKHCvXTohxY-YIEUliy.ttf"
//   },
//   {
//     "family": "Ranchers",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ranchers/v17/zrfm0H3Lx-P2Xvs2AoDYDC79XTHv.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ranchers/v17/zrfm0H3Lx-P2Xvs2ArDZBio.ttf"
//   },
//   {
//     "family": "Emilys Candy",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/emilyscandy/v19/2EbgL-1mD1Rnb0OGKudbk0y5r9xrX84JjA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/emilyscandy/v19/2EbgL-1mD1Rnb0OGKudbk0yJrtZv.ttf"
//   },
//   {
//     "family": "Grape Nuts",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/grapenuts/v5/syk2-yF4iLM2RfKj4F7k3tLvol2RN1E.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/grapenuts/v5/syk2-yF4iLM2RfKj4F7k7tPlpg.ttf"
//   },
//   {
//     "family": "Qwitcher Grypen",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/qwitchergrypen/v6/pxiZypclp9tDilN9RrC5BSI1dZmT9ExkqkSsrvNXiA.ttf",
//       "regular": "https://fonts.gstatic.com/s/qwitchergrypen/v6/pxicypclp9tDilN9RrC5BSI1dZmrSGNAom-wpw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/qwitchergrypen/v6/pxicypclp9tDilN9RrC5BSI1dZmbSWlE.ttf"
//   },
//   {
//     "family": "Henny Penny",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-04-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfMQqt3M7tMDT.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfPQrvXc.ttf"
//   },
//   {
//     "family": "Fragment Mono",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fragmentmono/v4/4iCr6K5wfMRRjxp0DA6-2CLnN4RNh4UI_1U.ttf",
//       "italic": "https://fonts.gstatic.com/s/fragmentmono/v4/4iC16K5wfMRRjxp0DA6-2CLnB4ZHg6cN71URtQ.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fragmentmono/v4/4iCr6K5wfMRRjxp0DA6-2CLnB4VHgw.ttf"
//   },
//   {
//     "family": "Noto Sans Khmer",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "khmer",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAZz4kAbrddiA.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYsNAJz4kAbrddiA.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYvTAJz4kAbrddiA.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYu_AJz4kAbrddiA.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYtTB5z4kAbrddiA.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYtqB5z4kAbrddiA.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYsNB5z4kAbrddiA.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYskB5z4kAbrddiA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAJz4kAbrddiA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskhmer/v24/ijw3s5roRME5LLRxjsRb-gssOenAyendxrgV2c-Zw-9vbVUti_Z_dWgtWYuNAKz5mgI.ttf"
//   },
//   {
//     "family": "Rubik Dirt",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikdirt/v2/DtVmJxC7WLEj1uIXEWAdulwm6gDXvwE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikdirt/v2/DtVmJxC7WLEj1uIXEWAdil0s7g.ttf"
//   },
//   {
//     "family": "Ruthie",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ruthie/v26/gokvH63sGkdqXuU9lD53Q2u_mQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ruthie/v26/gokvH63sGkdqXuUNlTRz.ttf"
//   },
//   {
//     "family": "Medula One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/medulaone/v19/YA9Wr0qb5kjJM6l2V0yukiEqs7GtlvY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/medulaone/v19/YA9Wr0qb5kjJM6l2V0yuoiAgtw.ttf"
//   },
//   {
//     "family": "Gemunu Libre",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sinhala"
//     ],
//     "version": "v14",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp05iJPvSLeMXPIWA.ttf",
//       "300": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp00aJPvSLeMXPIWA.ttf",
//       "500": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0yqJPvSLeMXPIWA.ttf",
//       "600": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp08aOPvSLeMXPIWA.ttf",
//       "700": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0_-OPvSLeMXPIWA.ttf",
//       "800": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp05iOPvSLeMXPIWA.ttf",
//       "regular": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0xiJPvSLeMXPIWA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gemunulibre/v14/X7n34bQ6Cfy7jKGXVE_YlqnbEQAFP-PIuTCp0xiJDvWBfA.ttf"
//   },
//   {
//     "family": "Song Myung",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/songmyung/v20/1cX2aUDWAJH5-EIC7DIhr1GqhcitzeM.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/songmyung/v20/1cX2aUDWAJH5-EIC7DIhn1CggQ.ttf"
//   },
//   {
//     "family": "Kalnia",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math"
//     ],
//     "version": "v2",
//     "lastModified": "2023-12-07",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW9il2xRGNcykIZc.ttf",
//       "200": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW_ilmxRGNcykIZc.ttf",
//       "300": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW88lmxRGNcykIZc.ttf",
//       "500": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW9QlmxRGNcykIZc.ttf",
//       "600": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW-8kWxRGNcykIZc.ttf",
//       "700": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW-FkWxRGNcykIZc.ttf",
//       "regular": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW9ilmxRGNcykIZc.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kalnia/v2/11hqGpPCwUbbYwZZP0RBuDY62BQZhjvOytM_sLzi-TFWAW9illxQEtM.ttf"
//   },
//   {
//     "family": "MedievalSharp",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/medievalsharp/v26/EvOJzAlL3oU5AQl2mP5KdgptAq96MwvXLDk.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/medievalsharp/v26/EvOJzAlL3oU5AQl2mP5KdgptMq5wNw.ttf"
//   },
//   {
//     "family": "Baloo Bhai 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNhohMXeCo-jsZzo.ttf",
//       "600": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNvYmMXeCo-jsZzo.ttf",
//       "700": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNs8mMXeCo-jsZzo.ttf",
//       "800": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNqgmMXeCo-jsZzo.ttf",
//       "regular": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNighMXeCo-jsZzo.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baloobhai2/v28/sZlWdRSL-z1VEWZ4YNA7Y5ItevYWUOHDE8FvNighAXaIpw.ttf"
//   },
//   {
//     "family": "Mina",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/mina/v11/-nF8OGc18vARl4NMyiXZ95OkJwA.ttf",
//       "regular": "https://fonts.gstatic.com/s/mina/v11/-nFzOGc18vARrz9j7i3y65o.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mina/v11/-nFzOGc18vARnz5p6g.ttf"
//   },
//   {
//     "family": "Chango",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chango/v27/2V0cKI0OB5U7WaJyz324TFUaAw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chango/v27/2V0cKI0OB5U7WaJCzne8.ttf"
//   },
//   {
//     "family": "Rhodium Libre",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rhodiumlibre/v19/1q2AY5adA0tn_ukeHcQHqpx6pETLeo2gm2U.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rhodiumlibre/v19/1q2AY5adA0tn_ukeHcQHqpx6lEXBfg.ttf"
//   },
//   {
//     "family": "Noto Serif Hebrew",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwTAG8_vlQxz24.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVIwSAG8_vlQxz24.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVFISAG8_vlQxz24.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVD4SAG8_vlQxz24.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVNIVAG8_vlQxz24.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVOsVAG8_vlQxz24.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVIwVAG8_vlQxz24.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVKUVAG8_vlQxz24.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwSAG8_vlQxz24.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifhebrew/v25/k3k0o9MMPvpLmixYH7euCwmkS9DohjX1-kRyiqyBqIxnoLbp93i9IKrXKF_qVAwSMG41ug.ttf"
//   },
//   {
//     "family": "Orienta",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2023-03-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/orienta/v15/PlI9FlK4Jrl5Y9zNeyeo9HRFhcU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/orienta/v15/PlI9FlK4Jrl5Y9zNSyai8A.ttf"
//   },
//   {
//     "family": "Gayathri",
//     "variants": [
//       "100",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "malayalam"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/gayathri/v17/MCoWzAb429DbBilWLLhc-pvSA_gA2W8.ttf",
//       "700": "https://fonts.gstatic.com/s/gayathri/v17/MCoXzAb429DbBilWLLiE37v4LfQJwHbn.ttf",
//       "regular": "https://fonts.gstatic.com/s/gayathri/v17/MCoQzAb429DbBilWLIA48J_wBugA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gayathri/v17/MCoQzAb429DbBilWLLA5-ps.ttf"
//   },
//   {
//     "family": "Redressed",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/redressed/v29/x3dickHUbrmJ7wMy9MsBfPACvy_1BA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redressed/v29/x3dickHUbrmJ7wMy9MsxffoG.ttf"
//   },
//   {
//     "family": "Montagu Slab",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-09-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbE3P9Fs7bOSO7.ttf",
//       "200": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkBbEnP9Fs7bOSO7.ttf",
//       "300": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkCFEnP9Fs7bOSO7.ttf",
//       "500": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDpEnP9Fs7bOSO7.ttf",
//       "600": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkAFFXP9Fs7bOSO7.ttf",
//       "700": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkA8FXP9Fs7bOSO7.ttf",
//       "regular": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbEnP9Fs7bOSO7.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/montaguslab/v12/6qLhKZIQtB_zv0xUaXRDWkY_HXsphdLRZF40vm_jzR2jhk_n3T6ACkDbEkP8HMo.ttf"
//   },
//   {
//     "family": "Edu SA Beginner",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v3",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/edusabeginner/v3/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9-09fo1yBydUEDs.ttf",
//       "600": "https://fonts.gstatic.com/s/edusabeginner/v3/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9wE6fo1yBydUEDs.ttf",
//       "700": "https://fonts.gstatic.com/s/edusabeginner/v3/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9zg6fo1yBydUEDs.ttf",
//       "regular": "https://fonts.gstatic.com/s/edusabeginner/v3/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9989fo1yBydUEDs.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/edusabeginner/v3/rnC_-xRb1x-1lHXnLaZZ2xOoLIGfU3L82irpr_3C9989Tox4Aw.ttf"
//   },
//   {
//     "family": "Lexend Peta",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-03-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SFyW1YuRTsnfw.ttf",
//       "200": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRaSByW1YuRTsnfw.ttf",
//       "300": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRtyByW1YuRTsnfw.ttf",
//       "500": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR2yByW1YuRTsnfw.ttf",
//       "600": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRNydyW1YuRTsnfw.ttf",
//       "700": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRDidyW1YuRTsnfw.ttf",
//       "800": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRaSdyW1YuRTsnfw.ttf",
//       "900": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgRQCdyW1YuRTsnfw.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SByW1YuRTsnfw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexendpeta/v27/BXR4vFPGjeLPh0kCfI4OkFX-UTQHSCaxvBgR6SBCWlwq.ttf"
//   },
//   {
//     "family": "Iceland",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/iceland/v20/rax9HiuFsdMNOnWPWKxGADBbg0s.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/iceland/v20/rax9HiuFsdMNOnWPaK1MBA.ttf"
//   },
//   {
//     "family": "Reggae One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-09-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/reggaeone/v16/7r3DqX5msMIkeuwJwOJt_a5L5uH-mts.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/reggaeone/v16/7r3DqX5msMIkeuwJwOJtza9B4g.ttf"
//   },
//   {
//     "family": "Cherry Swash",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/cherryswash/v20/i7dSIFByZjaNAMxtZcnfAy5E_FeaGy6QZ3WfYg.ttf",
//       "regular": "https://fonts.gstatic.com/s/cherryswash/v20/i7dNIFByZjaNAMxtZcnfAy58QHi-EwWMbg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cherryswash/v20/i7dNIFByZjaNAMxtZcnfAy5MQXK6.ttf"
//   },
//   {
//     "family": "Comforter Brush",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/comforterbrush/v7/Y4GTYa1xVSggrfzZI5WMjxRaOz0jwLL9Th8YYA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/comforterbrush/v7/Y4GTYa1xVSggrfzZI5WMjxRaOz0Twbj5.ttf"
//   },
//   {
//     "family": "Tilt Warp",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tiltwarp/v12/AlZc_zVDs5XpmO7yn3w7flUoytXJp3z29uEwmEMLEJljLXvT8UJSZTBxAVfMGOPb.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tiltwarp/v12/AlZc_zVDs5XpmO7yn3w7flUoytXJp3z29uEwmEMLEJljLXvT8UJSZQBwC1M.ttf"
//   },
//   {
//     "family": "Red Hat Mono",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/redhatmono/v11/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQQPI-7HNuW4QuKI.ttf",
//       "500": "https://fonts.gstatic.com/s/redhatmono/v11/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQW_I-7HNuW4QuKI.ttf",
//       "600": "https://fonts.gstatic.com/s/redhatmono/v11/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQYPP-7HNuW4QuKI.ttf",
//       "700": "https://fonts.gstatic.com/s/redhatmono/v11/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQbrP-7HNuW4QuKI.ttf",
//       "regular": "https://fonts.gstatic.com/s/redhatmono/v11/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQV3I-7HNuW4QuKI.ttf",
//       "300italic": "https://fonts.gstatic.com/s/redhatmono/v11/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLTfLHvUwVqKIJuw.ttf",
//       "italic": "https://fonts.gstatic.com/s/redhatmono/v11/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLE_LHvUwVqKIJuw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/redhatmono/v11/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLIfLHvUwVqKIJuw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/redhatmono/v11/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWLzfXHvUwVqKIJuw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/redhatmono/v11/jVye7nDnA2uf2zVvFAhhzEsUXfZc_vk45Kb3VJWL9PXHvUwVqKIJuw.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redhatmono/v11/jVyY7nDnA2uf2zVvFAhhzEs-VMSjJpBTfgjwQV3Iy7DHvQ.ttf"
//   },
//   {
//     "family": "Nova Flat",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novaflat/v24/QdVUSTc-JgqpytEbVebEuStkm20oJA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novaflat/v24/QdVUSTc-JgqpytEbVeb0uCFg.ttf"
//   },
//   {
//     "family": "Jomolhari",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "tibetan"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jomolhari/v18/EvONzA1M1Iw_CBd2hsQCF1IZKq5INg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jomolhari/v18/EvONzA1M1Iw_CBd2hsQyFlgd.ttf"
//   },
//   {
//     "family": "Rubik Doodle Shadow",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikdoodleshadow/v1/rP2bp3im_k8G_wTVdvvMdHqmXTR3lEaLyKuZ3KOY7Gw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikdoodleshadow/v1/rP2bp3im_k8G_wTVdvvMdHqmXTR3lEaL-KqT2A.ttf"
//   },
//   {
//     "family": "Flow Circular",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/flowcircular/v11/lJwB-pc4j2F-H8YKuyvfxdZ45ifpWdr2rIg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/flowcircular/v11/lJwB-pc4j2F-H8YKuyvfxdZ41ibjXQ.ttf"
//   },
//   {
//     "family": "Zilla Slab Highlight",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/zillaslabhighlight/v19/gNMUW2BrTpK8-inLtBJgMMfbm6uNVDvRxiP0TET4YmVF0Mb6.ttf",
//       "regular": "https://fonts.gstatic.com/s/zillaslabhighlight/v19/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxhtIY2DwSXlM.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zillaslabhighlight/v19/gNMbW2BrTpK8-inLtBJgMMfbm6uNVDvRxitJaWQ.ttf"
//   },
//   {
//     "family": "Tauri",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tauri/v18/TwMA-IISS0AM3IpVWHU_TBqO.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tauri/v18/TwMA-IISS0AM3LpUUnE.ttf"
//   },
//   {
//     "family": "Monofett",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/monofett/v23/mFTyWbofw6zc9NtnW43SuRwr0VJ7.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/monofett/v23/mFTyWbofw6zc9NtnW73Tsxg.ttf"
//   },
//   {
//     "family": "Stint Ultra Condensed",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/stintultracondensed/v23/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2A-qhUO2cNvdg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stintultracondensed/v23/-W_gXIrsVjjeyEnPC45qD2NoFPtBE0xCh2AOqx8K.ttf"
//   },
//   {
//     "family": "Prociono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/prociono/v26/r05YGLlR-KxAf9GGO8upyDYtStiJ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/prociono/v26/r05YGLlR-KxAf9GGO_uowjI.ttf"
//   },
//   {
//     "family": "Delius Swash Caps",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/deliusswashcaps/v23/oY1E8fPLr7v4JWCExZpWebxVKORpXXedKmeBvEYs.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/deliusswashcaps/v23/oY1E8fPLr7v4JWCExZpWebxVKORpXUecIGM.ttf"
//   },
//   {
//     "family": "Asar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/asar/v22/sZlLdRyI6TBIXkYQDLlTW6E.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/asar/v22/sZlLdRyI6TBIbkcaCA.ttf"
//   },
//   {
//     "family": "Anek Tamil",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v9",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNQiZ6q4v4oegjOQ.ttf",
//       "200": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNwid6q4v4oegjOQ.ttf",
//       "300": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNHCd6q4v4oegjOQ.ttf",
//       "500": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNcCd6q4v4oegjOQ.ttf",
//       "600": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNnCB6q4v4oegjOQ.ttf",
//       "700": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNpSB6q4v4oegjOQ.ttf",
//       "800": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNwiB6q4v4oegjOQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNQid6q4v4oegjOQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anektamil/v9/XLYJIZH2bYJHGYtPGSbUB8JKTp-_9n55SsLHW0WZez6TjtkDu3uNQidKqoH8.ttf"
//   },
//   {
//     "family": "Frijole",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/frijole/v14/uU9PCBUR8oakM2BQ7xPb3vyHmlI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/frijole/v14/uU9PCBUR8oakM2BQ3xLR2g.ttf"
//   },
//   {
//     "family": "East Sea Dokdo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/eastseadokdo/v22/xfuo0Wn2V2_KanASqXSZp22m05_aGavYS18y.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/eastseadokdo/v22/xfuo0Wn2V2_KanASqXSZp22m06_bE68.ttf"
//   },
//   {
//     "family": "Miniver",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/miniver/v25/eLGcP-PxIg-5H0vC770Cy8r8fWA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/miniver/v25/eLGcP-PxIg-5H0vC37wIzw.ttf"
//   },
//   {
//     "family": "Square Peg",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/squarepeg/v5/y83eW48Nzw6ZlUHc-phrBDHrHHfrFPE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/squarepeg/v5/y83eW48Nzw6ZlUHc-phrNDDhGA.ttf"
//   },
//   {
//     "family": "Londrina Outline",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/londrinaoutline/v27/C8c44dM8vmb14dfsZxhetg3pDH-SfuoxrSKMDvI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/londrinaoutline/v27/C8c44dM8vmb14dfsZxhetg3pDH-STus7qQ.ttf"
//   },
//   {
//     "family": "Gamja Flower",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gamjaflower/v22/6NUR8FiKJg-Pa0rM6uN40Z4kyf9Fdty2ew.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gamjaflower/v22/6NUR8FiKJg-Pa0rM6uN40Z4UyPVB.ttf"
//   },
//   {
//     "family": "Murecho",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-06-30",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpr5HWZLCpUOaM6.ttf",
//       "200": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMrr5XWZLCpUOaM6.ttf",
//       "300": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMo15XWZLCpUOaM6.ttf",
//       "500": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpZ5XWZLCpUOaM6.ttf",
//       "600": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMq14nWZLCpUOaM6.ttf",
//       "700": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMqM4nWZLCpUOaM6.ttf",
//       "800": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMrr4nWZLCpUOaM6.ttf",
//       "900": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMrC4nWZLCpUOaM6.ttf",
//       "regular": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpr5XWZLCpUOaM6.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/murecho/v11/q5uYsoq3NOBn_I-ggCJg98TBOoNFCMpr5UWYJi4.ttf"
//   },
//   {
//     "family": "Tillana",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQFL-HIlMZRNcp0o.ttf",
//       "600": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQH75HIlMZRNcp0o.ttf",
//       "700": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQBr4HIlMZRNcp0o.ttf",
//       "800": "https://fonts.gstatic.com/s/tillana/v13/VuJ0dNvf35P4qJ1OQAb7HIlMZRNcp0o.ttf",
//       "regular": "https://fonts.gstatic.com/s/tillana/v13/VuJxdNvf35P4qJ1OeKbXOIFneRo.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tillana/v13/VuJxdNvf35P4qJ1OSKfdPA.ttf"
//   },
//   {
//     "family": "IM Fell French Canon",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6coVPt1ozoPz.ttf",
//       "italic": "https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6gfiNtDWYfYc-tDiyiw08rrghJszkK6foXNNlKy5PzzrU.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfellfrenchcanon/v21/-F6ufiNtDWYfYc-tDiyiw08rrghJszkK6foUNNk.ttf"
//   },
//   {
//     "family": "Varta",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-03-21",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/varta/v21/Qw3AZQpJHj_6LzHUngWbrFkDH1x96j4EirE-9PGLfQ.ttf",
//       "500": "https://fonts.gstatic.com/s/varta/v21/Qw3AZQpJHj_6LzHUngWbrFkDH1x9hj4EirE-9PGLfQ.ttf",
//       "600": "https://fonts.gstatic.com/s/varta/v21/Qw3AZQpJHj_6LzHUngWbrFkDH1x9ajkEirE-9PGLfQ.ttf",
//       "700": "https://fonts.gstatic.com/s/varta/v21/Qw3AZQpJHj_6LzHUngWbrFkDH1x9UzkEirE-9PGLfQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/varta/v21/Qw3AZQpJHj_6LzHUngWbrFkDH1x9tD4EirE-9PGLfQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/varta/v21/Qw3AZQpJHj_6LzHUngWbrFkDH1x9tD40i7s6.ttf"
//   },
//   {
//     "family": "Crushed",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/crushed/v29/U9Mc6dym6WXImTlFT1kfuIqyLzA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/crushed/v29/U9Mc6dym6WXImTlFf1gVvA.ttf"
//   },
//   {
//     "family": "UnifrakturCook",
//     "variants": [
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/unifrakturcook/v23/IurA6Yli8YOdcoky-0PTTdkm56n05Uw13ILXs-h6.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/unifrakturcook/v23/IurA6Yli8YOdcoky-0PTTdkm56n05Xw01oY.ttf"
//   },
//   {
//     "family": "Tiro Bangla",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v6",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirobangla/v6/IFSgHe1Tm95E3O8b5i2V8MG9-UPeuz4i.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirobangla/v6/IFSiHe1Tm95E3O8b5i2V8PG_80f8vi4imBM.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirobangla/v6/IFSgHe1Tm95E3O8b5i2V8PG880c.ttf"
//   },
//   {
//     "family": "Gowun Batang",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2022-09-27",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/gowunbatang/v7/ijwNs5nhRMIjYsdSgcMa3wRZ4J7awssxJii23w.ttf",
//       "regular": "https://fonts.gstatic.com/s/gowunbatang/v7/ijwSs5nhRMIjYsdSgcMa3wRhXLH-yuAtLw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gowunbatang/v7/ijwSs5nhRMIjYsdSgcMa3wRRXbv6.ttf"
//   },
//   {
//     "family": "Fasthand",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v30",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fasthand/v30/0yb9GDohyKTYn_ZEESkuYkw2rQg1.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fasthand/v30/0yb9GDohyKTYn_ZEERkvaEg.ttf"
//   },
//   {
//     "family": "Kite One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-03-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kiteone/v22/70lQu7shLnA_E02vyq1b6HnGO4uA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kiteone/v22/70lQu7shLnA_E02vyp1a4n0.ttf"
//   },
//   {
//     "family": "Galdeano",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/galdeano/v22/uU9MCBoQ4YOqOW1boDPx8PCOg0uX.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/galdeano/v22/uU9MCBoQ4YOqOW1boAPw-vQ.ttf"
//   },
//   {
//     "family": "Modern Antiqua",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/modernantiqua/v24/NGStv5TIAUg6Iq_RLNo_2dp1sI1Ea2u0c3Gi.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/modernantiqua/v24/NGStv5TIAUg6Iq_RLNo_2dp1sL1FYW8.ttf"
//   },
//   {
//     "family": "Rosarivo",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rosarivo/v22/PlI-Fl2lO6N9f8HaNAeC2nhMnNy5.ttf",
//       "italic": "https://fonts.gstatic.com/s/rosarivo/v22/PlI4Fl2lO6N9f8HaNDeA0Hxumcy5ZX8.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rosarivo/v22/PlI-Fl2lO6N9f8HaNDeD0Hw.ttf"
//   },
//   {
//     "family": "Ribeye",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ribeye/v25/L0x8DFMxk1MP9R3RvPCmRSlUig.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ribeye/v25/L0x8DFMxk1MP9R3hvfqi.ttf"
//   },
//   {
//     "family": "Katibeh",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/katibeh/v19/ZGjXol5MQJog4bxDaC1RVDNdGDs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/katibeh/v19/ZGjXol5MQJog4bxDWCxbUA.ttf"
//   },
//   {
//     "family": "Bona Nova",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/bonanova/v10/B50IF7ZCpX7fcHfvIUBxN4dOFISeJY8GgQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/bonanova/v10/B50NF7ZCpX7fcHfvIUBJi6hqHK-CLA.ttf",
//       "italic": "https://fonts.gstatic.com/s/bonanova/v10/B50LF7ZCpX7fcHfvIUB5iaJuPqqSLJYf.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bonanova/v10/B50NF7ZCpX7fcHfvIUB5iqJu.ttf"
//   },
//   {
//     "family": "Metal Mania",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/metalmania/v22/RWmMoKWb4e8kqMfBUdPFJeXCg6UKDXlq.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/metalmania/v22/RWmMoKWb4e8kqMfBUdPFJdXDiaE.ttf"
//   },
//   {
//     "family": "Lovers Quarrel",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/loversquarrel/v23/Yq6N-LSKXTL-5bCy8ksBzpQ_-zAsY7pO6siz.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/loversquarrel/v23/Yq6N-LSKXTL-5bCy8ksBzpQ_-wAtab4.ttf"
//   },
//   {
//     "family": "Swanky and Moo Moo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/swankyandmoomoo/v22/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kUtbPkR64SYQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/swankyandmoomoo/v22/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kktLng.ttf"
//   },
//   {
//     "family": "Beau Rivage",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/beaurivage/v2/UcCi3FIgIG2bH4mMNWJUlmg3NZp8K2sL.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/beaurivage/v2/UcCi3FIgIG2bH4mMNWJUllg2P54.ttf"
//   },
//   {
//     "family": "Meera Inimai",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "tamil"
//     ],
//     "version": "v12",
//     "lastModified": "2022-04-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/meerainimai/v12/845fNMM5EIqOW5MPuvO3ILep_2jDVevnLQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/meerainimai/v12/845fNMM5EIqOW5MPuvO3ILeZ_mLH.ttf"
//   },
//   {
//     "family": "Gafata",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gafata/v20/XRXV3I6Cn0VJKon4MuyAbsrVcA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gafata/v20/XRXV3I6Cn0VJKonIM-aE.ttf"
//   },
//   {
//     "family": "Hachi Maru Pop",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hachimarupop/v19/HI_TiYoRLqpLrEiMAuO9Ysfz7rW1EM_btd8u.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hachimarupop/v19/HI_TiYoRLqpLrEiMAuO9Ysfz7oW0Gss.ttf"
//   },
//   {
//     "family": "Port Lligat Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/portlligatsans/v22/kmKmZrYrGBbdN1aV7Vokow6Lw4s4l7N0Tx4xEcQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/portlligatsans/v22/kmKmZrYrGBbdN1aV7Vokow6Lw4s4p7J-Sw.ttf"
//   },
//   {
//     "family": "ZCOOL KuaiLe",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v19",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zcoolkuaile/v19/tssqApdaRQokwFjFJjvM6h2WpozzoXhC2g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zcoolkuaile/v19/tssqApdaRQokwFjFJjvM6h2mp4b3.ttf"
//   },
//   {
//     "family": "Raleway Dots",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ralewaydots/v16/6NUR8FifJg6AfQvzpshgwJ8kyf9Fdty2ew.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ralewaydots/v16/6NUR8FifJg6AfQvzpshgwJ8UyPVB.ttf"
//   },
//   {
//     "family": "Fenix",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fenix/v20/XoHo2YL_S7-g5ostKzAFvs8o.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fenix/v20/XoHo2YL_S7-g5rssITQ.ttf"
//   },
//   {
//     "family": "Macondo Swash Caps",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/macondoswashcaps/v24/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6Oekoa_mm5bA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/macondoswashcaps/v24/6NUL8EaAJgGKZA7lpt941Z9s6ZYgDq6-e0Ae.ttf"
//   },
//   {
//     "family": "Yarndings 12",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "math",
//       "symbols"
//     ],
//     "version": "v2",
//     "lastModified": "2024-03-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yarndings12/v2/55xreyp2N8T5P2LJbZAlkY9c8ZLMI2VUnQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yarndings12/v2/55xreyp2N8T5P2LJbZAlkY9s8JjI.ttf"
//   },
//   {
//     "family": "Meow Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/meowscript/v5/0FlQVPqanlaJrtr8AnJ0ESch0_0CfDf1.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/meowscript/v5/0FlQVPqanlaJrtr8AnJ0ERcg2fk.ttf"
//   },
//   {
//     "family": "Fuzzy Bubbles",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/fuzzybubbles/v7/6qLbKZMbrgv9pwtjPEVNV0F2Ds_WQxMAZkM1pn4.ttf",
//       "regular": "https://fonts.gstatic.com/s/fuzzybubbles/v7/6qLGKZMbrgv9pwtjPEVNV0F2NnP5Zxsreko.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fuzzybubbles/v7/6qLGKZMbrgv9pwtjPEVNV0F2BnLzYw.ttf"
//   },
//   {
//     "family": "Baloo Bhaina 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "oriya",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEgMPvRfRLYWmZSA.ttf",
//       "600": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEbMTvRfRLYWmZSA.ttf",
//       "700": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEVcTvRfRLYWmZSA.ttf",
//       "800": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEMsTvRfRLYWmZSA.ttf",
//       "regular": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEssPvRfRLYWmZSA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/baloobhaina2/v27/qWc-B6yyq4P9Adr3RtoX1q6ySgbwusXwJjkOS-XEssPfRP5P.ttf"
//   },
//   {
//     "family": "Akronim",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/akronim/v23/fdN-9sqWtWZZlHRp-gBxkFYN-a8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/akronim/v23/fdN-9sqWtWZZlHRpygF7lA.ttf"
//   },
//   {
//     "family": "Karantina",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2022-09-22",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/karantina/v11/buExpo24ccnh31GVMABxXCgf-P5Oaiw4cw.ttf",
//       "700": "https://fonts.gstatic.com/s/karantina/v11/buExpo24ccnh31GVMABxTC8f-P5Oaiw4cw.ttf",
//       "regular": "https://fonts.gstatic.com/s/karantina/v11/buE0po24ccnh31GVMABJ8AA78NVSYw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/karantina/v11/buE0po24ccnh31GVMAB58Qo_.ttf"
//   },
//   {
//     "family": "Kantumruy Pro",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "khmer",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1urUs0M34dR6dW.ttf",
//       "200": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg3urEs0M34dR6dW.ttf",
//       "300": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg0wrEs0M34dR6dW.ttf",
//       "500": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1crEs0M34dR6dW.ttf",
//       "600": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg2wq0s0M34dR6dW.ttf",
//       "700": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg2Jq0s0M34dR6dW.ttf",
//       "regular": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1urEs0M34dR6dW.ttf",
//       "100italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim76N2OXo_QrdWlcU.ttf",
//       "200italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim7yN3OXo_QrdWlcU.ttf",
//       "300italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim7_13OXo_QrdWlcU.ttf",
//       "italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim76N3OXo_QrdWlcU.ttf",
//       "500italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim75F3OXo_QrdWlcU.ttf",
//       "600italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim731wOXo_QrdWlcU.ttf",
//       "700italic": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2RY5aECkp34vEBSPFOmJxwlEbbdY1VU_nxzRim70RwOXo_QrdWlcU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kantumruypro/v8/1q2TY5aECkp34vEBSPFOmJxwvk_pilU8OGNfyg1urHs1OXo.ttf"
//   },
//   {
//     "family": "Sonsie One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sonsieone/v21/PbymFmP_EAnPqbKaoc18YVu80lbp8JM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sonsieone/v21/PbymFmP_EAnPqbKaoc18UVq21g.ttf"
//   },
//   {
//     "family": "Alkatra",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "bengali",
//       "devanagari",
//       "latin",
//       "latin-ext",
//       "oriya"
//     ],
//     "version": "v3",
//     "lastModified": "2023-03-21",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/alkatra/v3/r05EGLZA5qhCYsyJbuChFuK48MedzngUu7cPrNDVemxE.ttf",
//       "600": "https://fonts.gstatic.com/s/alkatra/v3/r05EGLZA5qhCYsyJbuChFuK48Medznj4vLcPrNDVemxE.ttf",
//       "700": "https://fonts.gstatic.com/s/alkatra/v3/r05EGLZA5qhCYsyJbuChFuK48MedznjBvLcPrNDVemxE.ttf",
//       "regular": "https://fonts.gstatic.com/s/alkatra/v3/r05EGLZA5qhCYsyJbuChFuK48Medzngmu7cPrNDVemxE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alkatra/v3/r05EGLZA5qhCYsyJbuChFuK48Medzngmu4cOptQ.ttf"
//   },
//   {
//     "family": "Lexend Mega",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fivveyiq9EqQw.ttf",
//       "200": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLcfmvveyiq9EqQw.ttf",
//       "300": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLr_mvveyiq9EqQw.ttf",
//       "500": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLw_mvveyiq9EqQw.ttf",
//       "600": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLL_6vveyiq9EqQw.ttf",
//       "700": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLFv6vveyiq9EqQw.ttf",
//       "800": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLcf6vveyiq9EqQw.ttf",
//       "900": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDLWP6vveyiq9EqQw.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fmvveyiq9EqQw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexendmega/v25/qFdX35aBi5JtHD41zSTFEuTByuvYFuE9IbDL8fmfvOam.ttf"
//   },
//   {
//     "family": "Yuji Syuku",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yujisyuku/v5/BngNUXdTV3vO6Lw5ApOPqPfgwqiA-Rk.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yujisyuku/v5/BngNUXdTV3vO6Lw5ApOPmPbqxg.ttf"
//   },
//   {
//     "family": "Strait",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/strait/v17/DtViJxy6WaEr1LZzeDhtkl0U7w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/strait/v17/DtViJxy6WaEr1LZDeTJp.ttf"
//   },
//   {
//     "family": "Carlito",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2023-05-02",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/carlito/v3/3Jn4SDPw3m-pk039BIykaX0vUuhCyOo.ttf",
//       "regular": "https://fonts.gstatic.com/s/carlito/v3/3Jn9SDPw3m-pk039PDCLTXUETuE.ttf",
//       "italic": "https://fonts.gstatic.com/s/carlito/v3/3Jn_SDPw3m-pk039DDKBSVcBXuFb0Q.ttf",
//       "700italic": "https://fonts.gstatic.com/s/carlito/v3/3Jn6SDPw3m-pk039DDK59XglVspH2OprMQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/carlito/v3/3Jn9SDPw3m-pk039DDGBSQ.ttf"
//   },
//   {
//     "family": "Kulim Park",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjJYNwa5aZbUvGjU.ttf",
//       "300": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjPIOwa5aZbUvGjU.ttf",
//       "600": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjIYIwa5aZbUvGjU.ttf",
//       "700": "https://fonts.gstatic.com/s/kulimpark/v14/fdN49secq3hflz1Uu3IwjOIJwa5aZbUvGjU.ttf",
//       "200italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUKa9QYZcqCjVVUA.ttf",
//       "300italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUTaxQYZcqCjVVUA.ttf",
//       "regular": "https://fonts.gstatic.com/s/kulimpark/v14/fdN79secq3hflz1Uu3IwtF4m5aZxebw.ttf",
//       "italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdN59secq3hflz1Uu3IwhFws4YR0abw2Aw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUOapQYZcqCjVVUA.ttf",
//       "700italic": "https://fonts.gstatic.com/s/kulimpark/v14/fdNm9secq3hflz1Uu3IwhFwUXatQYZcqCjVVUA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kulimpark/v14/fdN79secq3hflz1Uu3IwhF8s4Q.ttf"
//   },
//   {
//     "family": "Nosifer",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nosifer/v22/ZGjXol5JTp0g5bxZaC1RVDNdGDs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nosifer/v22/ZGjXol5JTp0g5bxZWCxbUA.ttf"
//   },
//   {
//     "family": "Alkalami",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alkalami/v7/zOL_4pfDmqRL95WXi5eLw8BMuvhH.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alkalami/v7/zOL_4pfDmqRL95WXi6eKycQ.ttf"
//   },
//   {
//     "family": "Moul",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/moul/v27/nuF2D__FSo_3E-RYiJCy-00.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/moul/v27/nuF2D__FSo_3I-VSjA.ttf"
//   },
//   {
//     "family": "Carrois Gothic SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/carroisgothicsc/v15/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-28nNHabY4dN.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/carroisgothicsc/v15/ZgNJjOVHM6jfUZCmyUqT2A2HVKjc-18mPnI.ttf"
//   },
//   {
//     "family": "Rum Raisin",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rumraisin/v22/nwpRtKu3Ih8D5avB4h2uJ3-IywA7eMM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rumraisin/v22/nwpRtKu3Ih8D5avB4h2uF36Czw.ttf"
//   },
//   {
//     "family": "Akaya Telivigala",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "telugu"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/akayatelivigala/v22/lJwc-oo_iG9wXqU3rCTD395tp0uifdLdsIH0YH8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/akayatelivigala/v22/lJwc-oo_iG9wXqU3rCTD395tp0uiTdPXtA.ttf"
//   },
//   {
//     "family": "MonteCarlo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/montecarlo/v11/buEzpo6-f9X01GadLA0G0CoV_NxLeiw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/montecarlo/v11/buEzpo6-f9X01GadLA0G4Csf-A.ttf"
//   },
//   {
//     "family": "Tiro Kannada",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v6",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirokannada/v6/CSR44ztKmvqaDxEDJFY7CIYKSPl6tOU9Eg.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirokannada/v6/CSRm4ztKmvqaDxEDJFY7CIY6SvN-luAtEnKp.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirokannada/v6/CSR44ztKmvqaDxEDJFY7CIY6SfN-.ttf"
//   },
//   {
//     "family": "Gowun Dodum",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gowundodum/v7/3Jn5SD_00GqwlBnWc1TUJF0FfORL0fNy.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gowundodum/v7/3Jn5SD_00GqwlBnWc1TUJG0EduA.ttf"
//   },
//   {
//     "family": "Zen Dots",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zendots/v12/XRXX3ICfm00IGoesQeaETM_FcCIG.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zendots/v12/XRXX3ICfm00IGoesQdaFRss.ttf"
//   },
//   {
//     "family": "Dekko",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dekko/v21/46khlb_wWjfSrttFR0vsfl1B.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dekko/v21/46khlb_wWjfSrutETU8.ttf"
//   },
//   {
//     "family": "Arima",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "malayalam",
//       "tamil",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-04-04",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTE-pQGOyYw2fw.ttf",
//       "200": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX14TA-pQGOyYw2fw.ttf",
//       "300": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1PzA-pQGOyYw2fw.ttf",
//       "500": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1UzA-pQGOyYw2fw.ttf",
//       "600": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1vzc-pQGOyYw2fw.ttf",
//       "700": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1hjc-pQGOyYw2fw.ttf",
//       "regular": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTA-pQGOyYw2fw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arima/v5/neIWzCqmt4Aup_qE1nFWqxI1RZX1YTAOpAuK.ttf"
//   },
//   {
//     "family": "Nova Cut",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novacut/v24/KFOkCnSYu8mL-39LkWxPKTM1K9nz.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novacut/v24/KFOkCnSYu8mL-39LkVxOIzc.ttf"
//   },
//   {
//     "family": "Barrio",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/barrio/v19/wEO8EBXBk8hBIDiEdQYhWdsX1Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/barrio/v19/wEO8EBXBk8hBIDi0dAwl.ttf"
//   },
//   {
//     "family": "Gorditas",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/gorditas/v22/ll84K2aTVD26DsPEtThUIooIvAoShA1i.ttf",
//       "regular": "https://fonts.gstatic.com/s/gorditas/v22/ll8_K2aTVD26DsPEtQDoDa4AlxYb.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gorditas/v22/ll8_K2aTVD26DsPEtTDpB6o.ttf"
//   },
//   {
//     "family": "Ramaraja",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ramaraja/v15/SlGTmQearpYAYG1CABIkqnB6aSQU.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ramaraja/v15/SlGTmQearpYAYG1CACIloHQ.ttf"
//   },
//   {
//     "family": "Rubik Scribble",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikscribble/v1/snfzs0Cp48d67SuHQOpjXLsQpbqbSjORSo9W.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikscribble/v1/snfzs0Cp48d67SuHQOpjXLsQpYqaQDc.ttf"
//   },
//   {
//     "family": "Cantora One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cantoraone/v19/gyB4hws1JdgnKy56GB_JX6zdZ4vZVbgZ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cantoraone/v19/gyB4hws1JdgnKy56GB_JX5zcbY8.ttf"
//   },
//   {
//     "family": "Agbalumo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2024-02-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/agbalumo/v5/55xvey5uMdT2N37KZcMFirl08KDJ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/agbalumo/v5/55xvey5uMdT2N37KZfMEgL0.ttf"
//   },
//   {
//     "family": "Uncial Antiqua",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/uncialantiqua/v20/N0bM2S5WOex4OUbESzoESK-i-PfRS5VBBSSF.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/uncialantiqua/v20/N0bM2S5WOex4OUbESzoESK-i-MfQQZE.ttf"
//   },
//   {
//     "family": "Cute Font",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cutefont/v22/Noaw6Uny2oWPbSHMrY6vmJNVNC9hkw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cutefont/v22/Noaw6Uny2oWPbSHMrY6fmZlR.ttf"
//   },
//   {
//     "family": "IM Fell DW Pica SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfelldwpicasc/v21/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTW-BiKEUiBGA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfelldwpicasc/v21/0ybjGCAu5PfqkvtGVU15aBhXz3EUrnTm-RKO.ttf"
//   },
//   {
//     "family": "Stalemate",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/stalemate/v22/taiIGmZ_EJq97-UfkZRpuqSs8ZQpaQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stalemate/v22/taiIGmZ_EJq97-UfkZRZu66o.ttf"
//   },
//   {
//     "family": "Yomogi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yomogi/v11/VuJwdNrS2ZL7rpoPWIz5NIh-YA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yomogi/v11/VuJwdNrS2ZL7rpo_WYb9.ttf"
//   },
//   {
//     "family": "Iceberg",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/iceberg/v24/8QIJdijAiM7o-qnZuIgOq7jkAOw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/iceberg/v24/8QIJdijAiM7o-qnZiIkErw.ttf"
//   },
//   {
//     "family": "Habibi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/habibi/v21/CSR-4zFWkuqcTTNCShJeZOYySQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/habibi/v21/CSR-4zFWkuqcTTNySxha.ttf"
//   },
//   {
//     "family": "IM Fell Great Primer",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJorXvDo3SQY1.ttf",
//       "italic": "https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6UNwSJtayYxOkbYFsT6hMsLzX7u85rJrrVtj4VTBY1N6U.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfellgreatprimer/v21/bx6aNwSJtayYxOkbYFsT6hMsLzX7u85rJrrWtj4.ttf"
//   },
//   {
//     "family": "Akaya Kanadaka",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/akayakanadaka/v16/N0bM2S5CPO5oOQqvazoRRb-8-PfRS5VBBSSF.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/akayakanadaka/v16/N0bM2S5CPO5oOQqvazoRRb-8-MfQQZE.ttf"
//   },
//   {
//     "family": "Anek Telugu",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "telugu"
//     ],
//     "version": "v8",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y-_oE2G2ep10_8.ttf",
//       "200": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i136--oE2G2ep10_8.ttf",
//       "300": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i133G-oE2G2ep10_8.ttf",
//       "500": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13x2-oE2G2ep10_8.ttf",
//       "600": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13_G5oE2G2ep10_8.ttf",
//       "700": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i138i5oE2G2ep10_8.ttf",
//       "800": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i136-5oE2G2ep10_8.ttf",
//       "regular": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y--oE2G2ep10_8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anektelugu/v8/LhWLMVrUNvsddMtYGCx4FcVWOjlwE1WgXdoJ-5XHMl2DkooGK7i13y--kEyM3Q.ttf"
//   },
//   {
//     "family": "Braah One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/braahone/v6/KFOlCnWUpt6LsxxxiylvAx05IsDqlA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/braahone/v6/KFOlCnWUpt6LsxxxiylfAhc9.ttf"
//   },
//   {
//     "family": "Denk One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/denkone/v19/dg4m_pzhrqcFb2IzROtHpbglShon.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/denkone/v19/dg4m_pzhrqcFb2IzRNtGr7w.ttf"
//   },
//   {
//     "family": "Maiden Orange",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/maidenorange/v29/kJE1BuIX7AUmhi2V4m08kb1XjOZdCZS8FY8.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/maidenorange/v29/kJE1BuIX7AUmhi2V4m08kb1XvOdXDQ.ttf"
//   },
//   {
//     "family": "Sunshiney",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sunshiney/v24/LDIwapGTLBwsS-wT4vcgE8moUePWkg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sunshiney/v24/LDIwapGTLBwsS-wT4vcQEsOs.ttf"
//   },
//   {
//     "family": "Poltawski Nowy",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2023-05-31",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUsRq6ww480U1xsUpFXD-iDBNlSAOLkKCLnWq8KmiWnDS6V5CzCoQ.ttf",
//       "600": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUsRq6ww480U1xsUpFXD-iDBNlSAOLkKCLnWq8KdiKnDS6V5CzCoQ.ttf",
//       "700": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUsRq6ww480U1xsUpFXD-iDBNlSAOLkKCLnWq8KTyKnDS6V5CzCoQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUsRq6ww480U1xsUpFXD-iDBNlSAOLkKCLnWq8KqCWnDS6V5CzCoQ.ttf",
//       "italic": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUuRq6ww480U1xsUpFXD-iDBPNbMh08QUl99KgfYGZPTiSRxinSoROp.ttf",
//       "500italic": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUuRq6ww480U1xsUpFXD-iDBPNbMh08QUl99KgfYGZ9TiSRxinSoROp.ttf",
//       "600italic": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUuRq6ww480U1xsUpFXD-iDBPNbMh08QUl99KgfYGaRSSSRxinSoROp.ttf",
//       "700italic": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUuRq6ww480U1xsUpFXD-iDBPNbMh08QUl99KgfYGaoSSSRxinSoROp.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/poltawskinowy/v2/flUsRq6ww480U1xsUpFXD-iDBNlSAOLkKCLnWq8KqCWXDCSR.ttf"
//   },
//   {
//     "family": "Kotta One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-04-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kottaone/v20/S6u_w41LXzPc_jlfNWqPHA3s5dwt7w.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kottaone/v20/S6u_w41LXzPc_jlfNWq_HQfo.ttf"
//   },
//   {
//     "family": "Ysabeau Office",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-01-26",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbh6IfYSfpQj7IGQ.ttf",
//       "200": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbB6MfYSfpQj7IGQ.ttf",
//       "300": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPb2aMfYSfpQj7IGQ.ttf",
//       "500": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbtaMfYSfpQj7IGQ.ttf",
//       "600": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbWaQfYSfpQj7IGQ.ttf",
//       "700": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbYKQfYSfpQj7IGQ.ttf",
//       "800": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbB6QfYSfpQj7IGQ.ttf",
//       "900": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbLqQfYSfpQj7IGQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbh6MfYSfpQj7IGQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-D3Iy3tYDvYGbGh.ttf",
//       "200italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-B3Ii3tYDvYGbGh.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-CpIi3tYDvYGbGh.ttf",
//       "italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-D3Ii3tYDvYGbGh.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-DFIi3tYDvYGbGh.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-ApJS3tYDvYGbGh.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-AQJS3tYDvYGbGh.ttf",
//       "800italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-B3JS3tYDvYGbGh.ttf",
//       "900italic": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDIkapaZKhM9RuQIp8FmdYrPPPLMqbE5B_kXOvTOT-BeJS3tYDvYGbGh.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ysabeauoffice/v2/LDImapaZKhM9RuQIp8FmdYrPPNjFm07hbpKNlPPbh6MvYC3t.ttf"
//   },
//   {
//     "family": "Sancreek",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sancreek/v25/pxiHypAnsdxUm159X7D-XV9NEe-K.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sancreek/v25/pxiHypAnsdxUm159X4D_V1s.ttf"
//   },
//   {
//     "family": "Bruno Ace SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/brunoacesc/v5/ptROTiycffFLBuiHjdJDl634LSFrpe8uZA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/brunoacesc/v5/ptROTiycffFLBuiHjdJDl63ILCtv.ttf"
//   },
//   {
//     "family": "Reem Kufi Fun",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYR3nCgrvqZzZXq.ttf",
//       "600": "https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChob92XCgrvqZzZXq.ttf",
//       "700": "https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChobE2XCgrvqZzZXq.ttf",
//       "regular": "https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYj3nCgrvqZzZXq.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/reemkufifun/v7/uK_m4rOFYukkmyUEbF43fIryZEk5qRZ8nrKChoYj3kChpP4.ttf"
//   },
//   {
//     "family": "Anta",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2024-02-16",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/anta/v1/gyBzhwQ3KsIyZFwxPFimIo0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anta/v1/gyBzhwQ3KsIyVF07OA.ttf"
//   },
//   {
//     "family": "Sree Krushnadevaraya",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sreekrushnadevaraya/v21/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_EWb2FhQuXir.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sreekrushnadevaraya/v21/R70FjzQeifmPepmyQQjQ9kvwMkWYPfTA_HWa0lw.ttf"
//   },
//   {
//     "family": "Madimi One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2024-02-29",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/madimione/v1/2V0YKIEADpA8U6RygDnZZFQoBoHMd2U.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/madimione/v1/2V0YKIEADpA8U6RygDnZVFUiAg.ttf"
//   },
//   {
//     "family": "Stylish",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/stylish/v22/m8JSjfhPYriQkk7-fo35dLxEdmo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stylish/v22/m8JSjfhPYriQkk7-TozzcA.ttf"
//   },
//   {
//     "family": "Spline Sans Mono",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/splinesansmono/v10/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGA8MrtVy4d4dGb1.ttf",
//       "500": "https://fonts.gstatic.com/s/splinesansmono/v10/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGBQMrtVy4d4dGb1.ttf",
//       "600": "https://fonts.gstatic.com/s/splinesansmono/v10/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGC8NbtVy4d4dGb1.ttf",
//       "700": "https://fonts.gstatic.com/s/splinesansmono/v10/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGCFNbtVy4d4dGb1.ttf",
//       "regular": "https://fonts.gstatic.com/s/splinesansmono/v10/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGBiMrtVy4d4dGb1.ttf",
//       "300italic": "https://fonts.gstatic.com/s/splinesansmono/v10/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcQ0WwYNacXb12MM.ttf",
//       "italic": "https://fonts.gstatic.com/s/splinesansmono/v10/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcVMWwYNacXb12MM.ttf",
//       "500italic": "https://fonts.gstatic.com/s/splinesansmono/v10/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcWEWwYNacXb12MM.ttf",
//       "600italic": "https://fonts.gstatic.com/s/splinesansmono/v10/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcY0RwYNacXb12MM.ttf",
//       "700italic": "https://fonts.gstatic.com/s/splinesansmono/v10/R70yjzAei_CDNLfgZxrW6wrZOF2WX5eDlm1vIsHjv3WqcbQRwYNacXb12MM.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/splinesansmono/v10/R70MjzAei_CDNLfgZxrW6wrZOF2WdZ6xabUGSVtNuGBiMotUwYM.ttf"
//   },
//   {
//     "family": "Libre Barcode 128 Text",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librebarcode128text/v28/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5cV3Y6M90w4k.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebarcode128text/v28/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5QVzS7A.ttf"
//   },
//   {
//     "family": "Moon Dance",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/moondance/v6/WBLgrEbUbFlYW9ekmGawe2XiKMiokE4.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/moondance/v6/WBLgrEbUbFlYW9ekmGawS2ToLA.ttf"
//   },
//   {
//     "family": "Shippori Antique",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shipporiantique/v8/-F6qfid3KC8pdMyzR0qRyFUht11v8ldPg-IUDNg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shipporiantique/v8/-F6qfid3KC8pdMyzR0qRyFUht11vwlZFhw.ttf"
//   },
//   {
//     "family": "Milonga",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/milonga/v22/SZc53FHnIaK9W5kffz3GkUrS8DI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/milonga/v22/SZc53FHnIaK9W5kfTzzMlQ.ttf"
//   },
//   {
//     "family": "Single Day",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean"
//     ],
//     "version": "v17",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/singleday/v17/LYjHdGDjlEgoAcF95EI5jVoFUNfeQJU.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/singleday/v17/LYjHdGDjlEgoAcF95EI5vVsPVA.ttf"
//   },
//   {
//     "family": "Inika",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/inika/v21/rnCr-x5X3QP-pix7auM-mHnOSOuk.ttf",
//       "regular": "https://fonts.gstatic.com/s/inika/v21/rnCm-x5X3QP-phTHRcc2s2XH.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inika/v21/rnCm-x5X3QP-piTGT8M.ttf"
//   },
//   {
//     "family": "Port Lligat Slab",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/portlligatslab/v25/LDIpaoiQNgArA8kR7ulhZ8P_NYOss7ob9yGLmfI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/portlligatslab/v25/LDIpaoiQNgArA8kR7ulhZ8P_NYOsg7sR8w.ttf"
//   },
//   {
//     "family": "Offside",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/offside/v24/HI_KiYMWKa9QrAykQ5HiRp-dhpQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/offside/v24/HI_KiYMWKa9QrAykc5DoQg.ttf"
//   },
//   {
//     "family": "Paprika",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/paprika/v21/8QIJdijZitv49rDfuIgOq7jkAOw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/paprika/v21/8QIJdijZitv49rDfiIkErw.ttf"
//   },
//   {
//     "family": "Baloo Tammudu 2",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "telugu",
//       "vietnamese"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_Jc0e4c6PZSlGmAA.ttf",
//       "600": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_JSEZ4c6PZSlGmAA.ttf",
//       "700": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_JRgZ4c6PZSlGmAA.ttf",
//       "800": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_JX8Z4c6PZSlGmAA.ttf",
//       "regular": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_Jf8e4c6PZSlGmAA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/balootammudu2/v23/1Pt5g8TIS_SAmkLguUdFP8UaJcKkzlPmMT00GaE_Jf8e0c-FYQ.ttf"
//   },
//   {
//     "family": "Konkhmer Sleokchher",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/konkhmersleokchher/v2/_Xmw-GE-rjmabA_M-aPOZOsCrUv825LFI3507E0d-W0.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/konkhmersleokchher/v2/_Xmw-GE-rjmabA_M-aPOZOsCrUv825LFE39-6A.ttf"
//   },
//   {
//     "family": "Genos",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cherokee",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVqknorUK6K7ZsAg.ttf",
//       "200": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVKkjorUK6K7ZsAg.ttf",
//       "300": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwV9EjorUK6K7ZsAg.ttf",
//       "500": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVmEjorUK6K7ZsAg.ttf",
//       "600": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVdE_orUK6K7ZsAg.ttf",
//       "700": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVTU_orUK6K7ZsAg.ttf",
//       "800": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVKk_orUK6K7ZsAg.ttf",
//       "900": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVA0_orUK6K7ZsAg.ttf",
//       "regular": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVqkjorUK6K7ZsAg.ttf",
//       "100italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgsA70i-CbN8Ard7.ttf",
//       "200italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYguA7ki-CbN8Ard7.ttf",
//       "300italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgte7ki-CbN8Ard7.ttf",
//       "italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgsA7ki-CbN8Ard7.ttf",
//       "500italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgsy7ki-CbN8Ard7.ttf",
//       "600italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgve6Ui-CbN8Ard7.ttf",
//       "700italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgvn6Ui-CbN8Ard7.ttf",
//       "800italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYguA6Ui-CbN8Ard7.ttf",
//       "900italic": "https://fonts.gstatic.com/s/genos/v12/SlGPmQqPqpUOYRwqWzksdKTv0zsAYgup6Ui-CbN8Ard7.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/genos/v12/SlGNmQqPqpUOYTYjacb0Hc91fTwVqkjYrEi-.ttf"
//   },
//   {
//     "family": "Stick No Bills",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sinhala"
//     ],
//     "version": "v15",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVP8Q7KriwKhcTKA.ttf",
//       "300": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcV4cQ7KriwKhcTKA.ttf",
//       "500": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVjcQ7KriwKhcTKA.ttf",
//       "600": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVYcM7KriwKhcTKA.ttf",
//       "700": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVWMM7KriwKhcTKA.ttf",
//       "800": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVP8M7KriwKhcTKA.ttf",
//       "regular": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVv8Q7KriwKhcTKA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sticknobills/v15/bWts7ffXZwHuAa9Uld-oEK4QKlxj9f9t_7uEmjcVv8QLK7K0.ttf"
//   },
//   {
//     "family": "Lexend Giga",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v25",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2LmE68oo6eepYQ.ttf",
//       "200": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCWLiE68oo6eepYQ.ttf",
//       "300": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRChriE68oo6eepYQ.ttf",
//       "500": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC6riE68oo6eepYQ.ttf",
//       "600": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCBr-E68oo6eepYQ.ttf",
//       "700": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCP7-E68oo6eepYQ.ttf",
//       "800": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCWL-E68oo6eepYQ.ttf",
//       "900": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRCcb-E68oo6eepYQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2LiE68oo6eepYQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2Li06sAs.ttf"
//   },
//   {
//     "family": "Smythe",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v23",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/smythe/v23/MwQ3bhT01--coT1BOLh_uGInjA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/smythe/v23/MwQ3bhT01--coT1xObJ7.ttf"
//   },
//   {
//     "family": "Engagement",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/engagement/v26/x3dlckLDZbqa7RUs9MFVXNossybsHQI.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/engagement/v26/x3dlckLDZbqa7RUs9MFVbNsmtw.ttf"
//   },
//   {
//     "family": "Asset",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v29",
//     "lastModified": "2024-02-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/asset/v29/SLXGc1na-mM4cWImRJqExst1.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/asset/v29/SLXGc1na-mM4cVInTp4.ttf"
//   },
//   {
//     "family": "Jolly Lodger",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jollylodger/v20/BXRsvFTAh_bGkA1uQ48dlB3VWerT3ZyuqA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jollylodger/v20/BXRsvFTAh_bGkA1uQ48dlB3lWODX.ttf"
//   },
//   {
//     "family": "Mystery Quest",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mysteryquest/v20/-nF6OG414u0E6k0wynSGlujRHwElD_9Qz9E.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mysteryquest/v20/-nF6OG414u0E6k0wynSGlujRLwAvCw.ttf"
//   },
//   {
//     "family": "Caesar Dressing",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/caesardressing/v21/yYLx0hLa3vawqtwdswbotmK4vrR3cbb6LZttyg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/caesardressing/v21/yYLx0hLa3vawqtwdswbotmK4vrRHcLz-.ttf"
//   },
//   {
//     "family": "Girassol",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/girassol/v22/JTUUjIo_-DK48laaNC9Nz2pJzxbi.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/girassol/v22/JTUUjIo_-DK48laaNB9MxW4.ttf"
//   },
//   {
//     "family": "Butterfly Kids",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/butterflykids/v25/ll8lK2CWTjuqAsXDqlnIbMNs5S4arxFrAX1D.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/butterflykids/v25/ll8lK2CWTjuqAsXDqlnIbMNs5R4bpRU.ttf"
//   },
//   {
//     "family": "Underdog",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/underdog/v23/CHygV-jCElj7diMroVSiU14GN2Il.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/underdog/v23/CHygV-jCElj7diMroWSjWVo.ttf"
//   },
//   {
//     "family": "Vujahday Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vujahdayscript/v8/RWmQoKGA8fEkrIPtSZ3_J7er2dUiDEtvAlaMKw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vujahdayscript/v8/RWmQoKGA8fEkrIPtSZ3_J7er2dUSDUFr.ttf"
//   },
//   {
//     "family": "AR One Sans",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v4",
//     "lastModified": "2023-11-28",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/aronesans/v4/TUZezwhrmbFp0Srr_tH6fv6RcUejHO_u7GF5aXfv-U2QzBLF6gslWk39DW03no5mBF4.ttf",
//       "600": "https://fonts.gstatic.com/s/aronesans/v4/TUZezwhrmbFp0Srr_tH6fv6RcUejHO_u7GF5aXfv-U2QzBLF6gslWqH6DW03no5mBF4.ttf",
//       "700": "https://fonts.gstatic.com/s/aronesans/v4/TUZezwhrmbFp0Srr_tH6fv6RcUejHO_u7GF5aXfv-U2QzBLF6gslWpj6DW03no5mBF4.ttf",
//       "regular": "https://fonts.gstatic.com/s/aronesans/v4/TUZezwhrmbFp0Srr_tH6fv6RcUejHO_u7GF5aXfv-U2QzBLF6gslWn_9DW03no5mBF4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aronesans/v4/TUZezwhrmbFp0Srr_tH6fv6RcUejHO_u7GF5aXfv-U2QzBLF6gslWn_9PWw9mg.ttf"
//   },
//   {
//     "family": "Delicious Handrawn",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/delicioushandrawn/v8/wlpsgx_NAUNkpmKQifcxkQchDFo3fJ113JpDd6u3AQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/delicioushandrawn/v8/wlpsgx_NAUNkpmKQifcxkQchDFo3fJ1F3ZBH.ttf"
//   },
//   {
//     "family": "Farsan",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/farsan/v22/VEMwRoJ0vY_zsyz62q-pxDX9rQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/farsan/v22/VEMwRoJ0vY_zsyzK26Wt.ttf"
//   },
//   {
//     "family": "Sedgwick Ave Display",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sedgwickavedisplay/v21/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM2czd-YnOzUD.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sedgwickavedisplay/v21/xfuu0XPgU3jZPUoUo3ScvmPi-NapQ8OxM1cyfeI.ttf"
//   },
//   {
//     "family": "Tiro Devanagari Hindi",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55xyezN7P8T4e0_CfIJrwdodg9HoYw0i-M9fSOkOfG0Y3A.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55x8ezN7P8T4e0_CfIJrwdodg9HoYw0i-M9vSuMKXmgI3F_o.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirodevanagarihindi/v5/55xyezN7P8T4e0_CfIJrwdodg9HoYw0i-M9vSeMK.ttf"
//   },
//   {
//     "family": "Anek Devanagari",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-03-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDtk-9nFk0LjZ7E.ttf",
//       "200": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLBtku9nFk0LjZ7E.ttf",
//       "300": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLCzku9nFk0LjZ7E.ttf",
//       "500": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDfku9nFk0LjZ7E.ttf",
//       "600": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLAzle9nFk0LjZ7E.ttf",
//       "700": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLAKle9nFk0LjZ7E.ttf",
//       "800": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLBtle9nFk0LjZ7E.ttf",
//       "regular": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDtku9nFk0LjZ7E.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anekdevanagari/v8/jVyo7nP0CGrUsxB-QiRgw0NlLaVt_QUAkYxLRoCL23mlh20ZVHOMAWbgHLDtkt9mHEk.ttf"
//   },
//   {
//     "family": "The Nautigal",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/thenautigal/v6/VdGGAZ8ZH51Lvng9fQV2bfKTWypA2_-C7LoS7g.ttf",
//       "regular": "https://fonts.gstatic.com/s/thenautigal/v6/VdGZAZ8ZH51Lvng9fQV2bfKr5wVk09Se5Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/thenautigal/v6/VdGZAZ8ZH51Lvng9fQV2bfKb5g9g.ttf"
//   },
//   {
//     "family": "IM Fell French Canon SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfellfrenchcanonsc/v22/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H3c0UBCVIVqH.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfellfrenchcanonsc/v22/FBVmdCru5-ifcor2bgq9V89khWcmQghEURY7H0c1WhQ.ttf"
//   },
//   {
//     "family": "Stint Ultra Expanded",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/stintultraexpanded/v22/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd0qoATQkWwam.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stintultraexpanded/v22/CSRg4yNNh-GbW3o3JkwoDcdvMKMf0oBAd3qpCzA.ttf"
//   },
//   {
//     "family": "Beth Ellen",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-04-20",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bethellen/v17/WwkbxPW2BE-3rb_JNT-qEIAiVNo5xNY.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bethellen/v17/WwkbxPW2BE-3rb_JNT-qIIEoUA.ttf"
//   },
//   {
//     "family": "Meie Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMjxn5IXg0WDz.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMgxm7oE.ttf"
//   },
//   {
//     "family": "Rationale",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rationale/v28/9XUnlJ92n0_JFxHIfHcsdlFMzLC2Zw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rationale/v28/9XUnlJ92n0_JFxHIfHccd1tI.ttf"
//   },
//   {
//     "family": "Nova Slim",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novaslim/v24/Z9XUDmZNQAuem8jyZcn-yMOInrib9Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novaslim/v24/Z9XUDmZNQAuem8jyZcnOycmM.ttf"
//   },
//   {
//     "family": "Piazzolla",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v35",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYx3Ly1AHfAAy5.ttf",
//       "200": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JYxnLy1AHfAAy5.ttf",
//       "300": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7KGxnLy1AHfAAy5.ttf",
//       "500": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LqxnLy1AHfAAy5.ttf",
//       "600": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7IGwXLy1AHfAAy5.ttf",
//       "700": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7I_wXLy1AHfAAy5.ttf",
//       "800": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JYwXLy1AHfAAy5.ttf",
//       "900": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7JxwXLy1AHfAAy5.ttf",
//       "regular": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYxnLy1AHfAAy5.ttf",
//       "100italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhZqw3gX9BRy5m5M.ttf",
//       "200italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhRqx3gX9BRy5m5M.ttf",
//       "300italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhcSx3gX9BRy5m5M.ttf",
//       "italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhZqx3gX9BRy5m5M.ttf",
//       "500italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhaix3gX9BRy5m5M.ttf",
//       "600italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhUS23gX9BRy5m5M.ttf",
//       "700italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhX223gX9BRy5m5M.ttf",
//       "800italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhRq23gX9BRy5m5M.ttf",
//       "900italic": "https://fonts.gstatic.com/s/piazzolla/v35/N0b72SlTPu5rIkWIZjVgI-TckS03oGpPETyEJ88Rbvi0_TzOzKcQhTO23gX9BRy5m5M.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/piazzolla/v35/N0b52SlTPu5rIkWIZjVKKtYtfxYqZ4RJBFzFfYUjkSDdlqZgy7LYxkLz3gU.ttf"
//   },
//   {
//     "family": "Stoke",
//     "variants": [
//       "300",
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/stoke/v24/z7NXdRb7aTMfKNvFVgxC_pjcTeWU.ttf",
//       "regular": "https://fonts.gstatic.com/s/stoke/v24/z7NadRb7aTMfKONpfihK1YTV.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stoke/v24/z7NadRb7aTMfKNNodCw.ttf"
//   },
//   {
//     "family": "Smooch",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/smooch/v7/o-0LIps4xW8U1xUBjqp_6hVdYg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/smooch/v7/o-0LIps4xW8U1xUxj6B7.ttf"
//   },
//   {
//     "family": "Bungee Hairline",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bungeehairline/v22/snfys0G548t04270a_ljTLUVrv-7YB2dQ5ZPqQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bungeehairline/v22/snfys0G548t04270a_ljTLUVrv-LYReZ.ttf"
//   },
//   {
//     "family": "Khmer",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/khmer/v29/MjQImit_vPPwpF-BpN2EeYmD.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/khmer/v29/MjQImit_vPPwpG-Artk.ttf"
//   },
//   {
//     "family": "Waterfall",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/waterfall/v6/MCoRzAfo293fACdFKcwY2rH8D_EZwA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/waterfall/v6/MCoRzAfo293fACdFKcwo27v4.ttf"
//   },
//   {
//     "family": "Anek Bangla",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "bengali",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofm9YIocg56yyvt0.ttf",
//       "200": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofu9ZIocg56yyvt0.ttf",
//       "300": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3OfjFZIocg56yyvt0.ttf",
//       "500": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofl1ZIocg56yyvt0.ttf",
//       "600": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3OfrFeIocg56yyvt0.ttf",
//       "700": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3OfoheIocg56yyvt0.ttf",
//       "800": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofu9eIocg56yyvt0.ttf",
//       "regular": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofm9ZIocg56yyvt0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anekbangla/v5/_gPW1R38qTExHg-17BhM6n66QhabMYB0fBKONtHhRSIUIre5mq3Ofm9ZEoYq4w.ttf"
//   },
//   {
//     "family": "Suwannaphum",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnAgHV7GtDvc8jbe8hXXL3B9cSWXx2VZmk.ttf",
//       "300": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnfgHV7GtDvc8jbe8hXXL0J1-S8cRGcf3Ai.ttf",
//       "700": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnfgHV7GtDvc8jbe8hXXL0Z0OS8cRGcf3Ai.ttf",
//       "900": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnfgHV7GtDvc8jbe8hXXL0h0uS8cRGcf3Ai.ttf",
//       "regular": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnCgHV7GtDvc8jbe8hXXIWl_8C0Wg2V.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/suwannaphum/v31/jAnCgHV7GtDvc8jbe8hXXLWk9cQ.ttf"
//   },
//   {
//     "family": "Text Me One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/textmeone/v24/i7dOIFdlayuLUvgoFvHQFWZcalayGhyV.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/textmeone/v24/i7dOIFdlayuLUvgoFvHQFVZdYFI.ttf"
//   },
//   {
//     "family": "Kenia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kenia/v28/jizURE5PuHQH9qCONUGswfGM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kenia/v28/jizURE5PuHQH9pCPP0U.ttf"
//   },
//   {
//     "family": "Noto Sans Adlam",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "adlam",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansadlam/v22/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufkn0TGnBZLwhuvk.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansadlam/v22/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufqXzTGnBZLwhuvk.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansadlam/v22/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufpzzTGnBZLwhuvk.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansadlam/v22/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufnv0TGnBZLwhuvk.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansadlam/v22/neIczCCpqp0s5pPusPamd81eMfjPonvqdbYxxpgufnv0fGjLYA.ttf"
//   },
//   {
//     "family": "Carattere",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/carattere/v7/4iCv6Kp1b9dXlgt_CkvTt2aMH4V_gg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/carattere/v7/4iCv6Kp1b9dXlgt_CkvjtmyI.ttf"
//   },
//   {
//     "family": "Black And White Picture",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/blackandwhitepicture/v24/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqI5HbcMi-yWY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/blackandwhitepicture/v24/TwMe-JAERlQd3ooUHBUXGmrmioKjjnRSFO-NqL5GZ8c.ttf"
//   },
//   {
//     "family": "Tektur",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TrwcVYtOY8P7TWd0.ttf",
//       "600": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TrzwUotOY8P7TWd0.ttf",
//       "700": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TrzJUotOY8P7TWd0.ttf",
//       "800": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TryuUotOY8P7TWd0.ttf",
//       "900": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TryHUotOY8P7TWd0.ttf",
//       "regular": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TrwuVYtOY8P7TWd0.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tektur/v3/XoHN2YHtS7q969kXCjzlV0aSkS_o8OacmTe0TYlYFot8TrwuVbtPacc.ttf"
//   },
//   {
//     "family": "Donegal One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/donegalone/v21/m8JWjfRYea-ZnFz6fsK9FZRFRG-K3Mud.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/donegalone/v21/m8JWjfRYea-ZnFz6fsK9FaRETms.ttf"
//   },
//   {
//     "family": "Margarine",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/margarine/v25/qkBXXvoE6trLT9Y7YLye5JRLkAXbMQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/margarine/v25/qkBXXvoE6trLT9Y7YLyu5Z5P.ttf"
//   },
//   {
//     "family": "Astloch",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2022-04-20",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/astloch/v26/TuGUUVJ8QI5GSeUjk2A-6MNPA10xLMQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/astloch/v26/TuGRUVJ8QI5GSeUjq9wRzMtkH1Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/astloch/v26/TuGRUVJ8QI5GSeUjm90byA.ttf"
//   },
//   {
//     "family": "IM Fell Great Primer SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfellgreatprimersc/v21/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTLf9BXz0s8FG.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfellgreatprimersc/v21/ga6daxBOxyt6sCqz3fjZCTFCTUDMHagsQKdDTIf8D3g.ttf"
//   },
//   {
//     "family": "Passions Conflict",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/passionsconflict/v7/kmKnZrcrFhfafnWX9x0GuEC-zowow5NeYRI4CN2V.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/passionsconflict/v7/kmKnZrcrFhfafnWX9x0GuEC-zowow6NfaxY.ttf"
//   },
//   {
//     "family": "Chilanka",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "malayalam"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chilanka/v20/WWXRlj2DZQiMJYaYRrJQI9EAZhTO.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chilanka/v20/WWXRlj2DZQiMJYaYRoJRKdU.ttf"
//   },
//   {
//     "family": "Sarina",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sarina/v23/-F6wfjF3ITQwasLhLkDUriBQxw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sarina/v23/-F6wfjF3ITQwasLRL0rQ.ttf"
//   },
//   {
//     "family": "Srisakdi",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai",
//       "vietnamese"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/srisakdi/v16/yMJWMIlvdpDbkB0A-gIAUghxoNFxW0Hz.ttf",
//       "regular": "https://fonts.gstatic.com/s/srisakdi/v16/yMJRMIlvdpDbkB0A-jq8fSx5i814.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/srisakdi/v16/yMJRMIlvdpDbkB0A-gq9dyg.ttf"
//   },
//   {
//     "family": "Charis SIL",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2023-04-27",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/charissil/v2/oPWJ_kV3l-s-Q8govXvKlEbJRj5dQnSX1ko.ttf",
//       "regular": "https://fonts.gstatic.com/s/charissil/v2/oPWK_kV3l-s-Q8govXvKrPrmYjZ2Xn0.ttf",
//       "italic": "https://fonts.gstatic.com/s/charissil/v2/oPWI_kV3l-s-Q8govXvKnPjsZhRzTn2Ozw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/charissil/v2/oPWX_kV3l-s-Q8govXvKnPjU2jtXRlaSxkrMCQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/charissil/v2/oPWK_kV3l-s-Q8govXvKnPvsZg.ttf"
//   },
//   {
//     "family": "Afacad",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2023-12-07",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/afacad/v1/6NUK8FKMIQOGaw6wjYT7ZHG_zsBBfiftWmA08mCgdfM.ttf",
//       "600": "https://fonts.gstatic.com/s/afacad/v1/6NUK8FKMIQOGaw6wjYT7ZHG_zsBBfsvqWmA08mCgdfM.ttf",
//       "700": "https://fonts.gstatic.com/s/afacad/v1/6NUK8FKMIQOGaw6wjYT7ZHG_zsBBfvLqWmA08mCgdfM.ttf",
//       "regular": "https://fonts.gstatic.com/s/afacad/v1/6NUK8FKMIQOGaw6wjYT7ZHG_zsBBfhXtWmA08mCgdfM.ttf",
//       "italic": "https://fonts.gstatic.com/s/afacad/v1/6NUI8FKMIQOGaw6ahLYEvBjUVG5Ga92usiM-9kKlZfNfuw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/afacad/v1/6NUI8FKMIQOGaw6ahLYEvBjUVG5Ga92ugCM-9kKlZfNfuw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/afacad/v1/6NUI8FKMIQOGaw6ahLYEvBjUVG5Ga92ubCQ-9kKlZfNfuw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/afacad/v1/6NUI8FKMIQOGaw6ahLYEvBjUVG5Ga92uVSQ-9kKlZfNfuw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/afacad/v1/6NUK8FKMIQOGaw6wjYT7ZHG_zsBBfhXtamE-9g.ttf"
//   },
//   {
//     "family": "Mohave",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-09-14",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/mohave/v9/7cH0v4ksjJunKqMVAOPIMOeSmiojdif_HvCQopLSvBk.ttf",
//       "500": "https://fonts.gstatic.com/s/mohave/v9/7cH0v4ksjJunKqMVAOPIMOeSmiojdkv_HvCQopLSvBk.ttf",
//       "600": "https://fonts.gstatic.com/s/mohave/v9/7cH0v4ksjJunKqMVAOPIMOeSmiojdqf4HvCQopLSvBk.ttf",
//       "700": "https://fonts.gstatic.com/s/mohave/v9/7cH0v4ksjJunKqMVAOPIMOeSmiojdp74HvCQopLSvBk.ttf",
//       "regular": "https://fonts.gstatic.com/s/mohave/v9/7cH0v4ksjJunKqMVAOPIMOeSmiojdnn_HvCQopLSvBk.ttf",
//       "300italic": "https://fonts.gstatic.com/s/mohave/v9/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8qLOaprDXrBlSVw.ttf",
//       "italic": "https://fonts.gstatic.com/s/mohave/v9/7cH2v4ksjJunKqM_CdE36I75AIQkY7G89rOaprDXrBlSVw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/mohave/v9/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8xLOaprDXrBlSVw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/mohave/v9/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8KLSaprDXrBlSVw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/mohave/v9/7cH2v4ksjJunKqM_CdE36I75AIQkY7G8EbSaprDXrBlSVw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mohave/v9/7cH0v4ksjJunKqMVAOPIMOeSmiojdnn_LvGapg.ttf"
//   },
//   {
//     "family": "Kaisei HarunoUmi",
//     "variants": [
//       "regular",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2022-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_WiZQSLqBQoAHhK_C6N_nzy_jcIj_QlMcFwmC9FAU.ttf",
//       "700": "https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_WiZQSLqBQoAHhK_C6N_nzy_jcInfWlMcFwmC9FAU.ttf",
//       "regular": "https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_RiZQSLqBQoAHhK_C6N_nzy_jcGsv5sM8u3mk.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kaiseiharunoumi/v8/HI_RiZQSLqBQoAHhK_C6N_nzy_jcKsrztA.ttf"
//   },
//   {
//     "family": "Eagle Lake",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/eaglelake/v24/ptRMTiqbbuNJDOiKj9wG5O7yKQNute8.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/eaglelake/v24/ptRMTiqbbuNJDOiKj9wG1O_4LQ.ttf"
//   },
//   {
//     "family": "Anek Kannada",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFDEAukVReA1oef.ttf",
//       "200": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dHDEQukVReA1oef.ttf",
//       "300": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dEdEQukVReA1oef.ttf",
//       "500": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFxEQukVReA1oef.ttf",
//       "600": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dGdFgukVReA1oef.ttf",
//       "700": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dGkFgukVReA1oef.ttf",
//       "800": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dHDFgukVReA1oef.ttf",
//       "regular": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFDEQukVReA1oef.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anekkannada/v5/raxcHiCNvNMKe1CKFsINYFlgkEIwGa8nL6ruWJg1j--h8pvBKSiw4dFDETulXxM.ttf"
//   },
//   {
//     "family": "Workbench",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2024-01-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/workbench/v1/FeV8S05Gp6Et7FcfbPFK1rynGd_MxtkvNFmoUDFhgF2VKTGQk6vapdOL0GKqgZyb.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/workbench/v1/FeV8S05Gp6Et7FcfbPFK1rynGd_MxtkvNFmoUDFhgF2VKTGQk6vapeOK2mY.ttf"
//   },
//   {
//     "family": "IM Fell Double Pica SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imfelldoublepicasc/v21/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ4Y0Eeru2dGg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imfelldoublepicasc/v21/neIazDmuiMkFo6zj_sHpQ8teNbWlwBB_hXjJ0YwOfg.ttf"
//   },
//   {
//     "family": "Nova Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novascript/v25/7Au7p_IpkSWSTWaFWkumvmQNEl0O0kEx.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novascript/v25/7Au7p_IpkSWSTWaFWkumvlQMGFk.ttf"
//   },
//   {
//     "family": "Birthstone Bounce",
//     "variants": [
//       "regular",
//       "500"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/birthstonebounce/v11/ga6SaxZF43lIvTWrktHOTBJZGH7dEd29MacQJZP1LmD9.ttf",
//       "regular": "https://fonts.gstatic.com/s/birthstonebounce/v11/ga6XaxZF43lIvTWrktHOTBJZGH7dEeVJGIMYDo_8.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/birthstonebounce/v11/ga6XaxZF43lIvTWrktHOTBJZGH7dEdVIEoc.ttf"
//   },
//   {
//     "family": "Molle",
//     "variants": [
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "italic": "https://fonts.gstatic.com/s/molle/v23/E21n_dL5hOXFhWEsXzgmVydREus.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/molle/v23/E21n_dL5hOXFhWEsbzksUw.ttf"
//   },
//   {
//     "family": "New Rocker",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/newrocker/v16/MwQzbhjp3-HImzcCU_cJkGMViblPtXs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/newrocker/v16/MwQzbhjp3-HImzcCU_cJoGIfjQ.ttf"
//   },
//   {
//     "family": "Miltonian Tattoo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v32",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/miltoniantattoo/v32/EvOUzBRL0o0kCxF-lcMCQxlpVsA_FwP8MDBku-s.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/miltoniantattoo/v32/EvOUzBRL0o0kCxF-lcMCQxlpVsA_JwL2NA.ttf"
//   },
//   {
//     "family": "Dorsa",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dorsa/v27/yYLn0hjd0OGwqo493XCFxAnQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dorsa/v27/yYLn0hjd0OGwqr4813Q.ttf"
//   },
//   {
//     "family": "Chicle",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chicle/v25/lJwG-pw9i2dqU-BDyWKuobYSxw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chicle/v25/lJwG-pw9i2dqU-BzyGiq.ttf"
//   },
//   {
//     "family": "Noto Serif Georgian",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "georgian",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-11-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvsfdzTw-FgZxQ.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSzvofdzTw-FgZxQ.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSEPofdzTw-FgZxQ.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSfPofdzTw-FgZxQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSkP0fdzTw-FgZxQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSqf0fdzTw-FgZxQ.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSzv0fdzTw-FgZxQ.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aS5_0fdzTw-FgZxQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvofdzTw-FgZxQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifgeorgian/v26/VEMXRpd8s4nv8hG_qOzL7HOAw4nt0Sl_XxyaEduNMvi7T6Y4etRnmGhyLop-R3aSTvovdj70.ttf"
//   },
//   {
//     "family": "Noto Sans Lao",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "lao",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v30",
//     "lastModified": "2023-09-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccfdf5MK3riB2w.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt48cbdf5MK3riB2w.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4L8bdf5MK3riB2w.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4Q8bdf5MK3riB2w.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4r8Hdf5MK3riB2w.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4lsHdf5MK3riB2w.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt48cHdf5MK3riB2w.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt42MHdf5MK3riB2w.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccbdf5MK3riB2w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslao/v30/bx6lNx2Ol_ixgdYWLm9BwxM3NW6BOkuf763Clj73CiQ_J1Djx9pidOt4ccbtfpkO.ttf"
//   },
//   {
//     "family": "Tilt Prism",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tiltprism/v11/5h11iZgyPHoZ3YikNzWGfWey2dCAZXT-bH9V4VGn-FJ7tLI25oc_rIbwoTSrn86NKw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tiltprism/v11/5h11iZgyPHoZ3YikNzWGfWey2dCAZXT-bH9V4VGn-FJ7tLI25oc_rIbAoD6v.ttf"
//   },
//   {
//     "family": "Junge",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/junge/v24/gokgH670Gl1lUqAdvhB7SnKm.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/junge/v24/gokgH670Gl1lUpActBQ.ttf"
//   },
//   {
//     "family": "Felipa",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/felipa/v25/FwZa7-owz1Eu4F_wSNSEwM2zpA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/felipa/v25/FwZa7-owz1Eu4F_ASd6A.ttf"
//   },
//   {
//     "family": "Bilbo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bilbo/v20/o-0EIpgpwWwZ210hpIRz4wxE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bilbo/v20/o-0EIpgpwWwZ220groA.ttf"
//   },
//   {
//     "family": "Arbutus",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/arbutus/v28/NaPYcZ7dG_5J3poob9JtryO8fMU.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arbutus/v28/NaPYcZ7dG_5J3pooX9Nnqw.ttf"
//   },
//   {
//     "family": "Condiment",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/condiment/v24/pONk1hggFNmwvXALyH6Sq4n4o1vyCQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/condiment/v24/pONk1hggFNmwvXALyH6iqoP8.ttf"
//   },
//   {
//     "family": "Comforter",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/comforter/v7/H4clBXOCl8nQnlaql3Qa6JG8iqeuag.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/comforter/v7/H4clBXOCl8nQnlaql3Qq6Zu4.ttf"
//   },
//   {
//     "family": "Nova Oval",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/novaoval/v24/jAnEgHdmANHvPenMaswCMY-h3cWkWg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/novaoval/v24/jAnEgHdmANHvPenMaswyMIWl.ttf"
//   },
//   {
//     "family": "Unlock",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/unlock/v26/7Au-p_8ykD-cDl7GKAjSwkUVOQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/unlock/v26/7Au-p_8ykD-cDl72KQLW.ttf"
//   },
//   {
//     "family": "Miltonian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v30",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/miltonian/v30/zOL-4pbPn6Ne9JqTg9mr6e5As-FeiQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/miltonian/v30/zOL-4pbPn6Ne9JqTg9mb6ORE.ttf"
//   },
//   {
//     "family": "Shippori Antique B1",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shipporiantiqueb1/v8/2Eb7L_JwClR7Zl_UAKZ0mUHw3oMKd40grRFCj9-5Y8Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shipporiantiqueb1/v8/2Eb7L_JwClR7Zl_UAKZ0mUHw3oMKd40gnRBIiw.ttf"
//   },
//   {
//     "family": "Jacques Francois",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jacquesfrancois/v24/ZXu9e04ZvKeOOHIe1TMahbcIU2cgmcPqoeRWfbs.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jacquesfrancois/v24/ZXu9e04ZvKeOOHIe1TMahbcIU2cgqcLgpQ.ttf"
//   },
//   {
//     "family": "Galindo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/galindo/v24/HI_KiYMeLqVKqwyuQ5HiRp-dhpQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/galindo/v24/HI_KiYMeLqVKqwyuc5DoQg.ttf"
//   },
//   {
//     "family": "Averia Gruesa Libre",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/averiagruesalibre/v22/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZUmmJw0SLRA8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/averiagruesalibre/v22/NGSov4nEGEktOaDRKsY-1dhh8eEtIx3ZYmiDxw.ttf"
//   },
//   {
//     "family": "Noto Sans Thai Looped",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thai"
//     ],
//     "version": "v14",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50fF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3YX6AYeCT_Wfd1.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Y84E4UgrzUO5sKA.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yl4I4UgrzUO5sKA.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yz4M4UgrzUO5sKA.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Y44Q4UgrzUO5sKA.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yh4U4UgrzUO5sKA.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Ym4Y4UgrzUO5sKA.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50cF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3Yv4c4UgrzUO5sKA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50RF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3gO6ocWiHvWQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansthailooped/v14/B50RF6pOpWTRcGrhOVJJ3-oPfY7WQuFu5R3QOqAY.ttf"
//   },
//   {
//     "family": "Trispace",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9qoQl0zHugpt0.ttf",
//       "200": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbP9roQl0zHugpt0.ttf",
//       "300": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbCFroQl0zHugpt0.ttf",
//       "500": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbE1roQl0zHugpt0.ttf",
//       "600": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbKFsoQl0zHugpt0.ttf",
//       "700": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbJhsoQl0zHugpt0.ttf",
//       "800": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbP9soQl0zHugpt0.ttf",
//       "regular": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9roQl0zHugpt0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/trispace/v24/Yq65-LKSQC3o56LxxgRrtA6yBqsrXL5GI5KI-IUZVGsxWFIlbH9rkQh-yA.ttf"
//   },
//   {
//     "family": "Alumni Sans Inline One",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alumnisansinlineone/v5/RrQBbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpD3AZcr7xbYw.ttf",
//       "italic": "https://fonts.gstatic.com/s/alumnisansinlineone/v5/RrQDbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpP3ITdpz0fYxcrQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alumnisansinlineone/v5/RrQBbpJx9zZ3IXTBOASKp5gJAetBdaihcjbpP3ETdg.ttf"
//   },
//   {
//     "family": "Aoboshi One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/aoboshione/v10/Gg8xN5kXaAXtHQrFxwl10ysLBmZX_UEg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aoboshione/v10/Gg8xN5kXaAXtHQrFxwl10xsKDGI.ttf"
//   },
//   {
//     "family": "Kavivanar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kavivanar/v20/o-0IIpQgyXYSwhxP7_Jb4j5Ba_2c7A.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kavivanar/v20/o-0IIpQgyXYSwhxP7_Jr4zRF.ttf"
//   },
//   {
//     "family": "Texturina",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYG_Ug25riW1OD.ttf",
//       "200": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cYGvUg25riW1OD.ttf",
//       "300": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2fGGvUg25riW1OD.ttf",
//       "500": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eqGvUg25riW1OD.ttf",
//       "600": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2dGHfUg25riW1OD.ttf",
//       "700": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2d_HfUg25riW1OD.ttf",
//       "800": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cYHfUg25riW1OD.ttf",
//       "900": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2cxHfUg25riW1OD.ttf",
//       "regular": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYGvUg25riW1OD.ttf",
//       "100italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWR1i0Z7AXkODN94.ttf",
//       "200italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWZ1j0Z7AXkODN94.ttf",
//       "300italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWUNj0Z7AXkODN94.ttf",
//       "italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWR1j0Z7AXkODN94.ttf",
//       "500italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWS9j0Z7AXkODN94.ttf",
//       "600italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWcNk0Z7AXkODN94.ttf",
//       "700italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWfpk0Z7AXkODN94.ttf",
//       "800italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWZ1k0Z7AXkODN94.ttf",
//       "900italic": "https://fonts.gstatic.com/s/texturina/v28/c4mO1nxpEtL3pXiAulR5mL129FhZmLj7I4oiSUJyfYDu7sB5zHJQWbRk0Z7AXkODN94.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/texturina/v28/c4mM1nxpEtL3pXiAulRTkY-HGmNEX1b9NspjMwhAgliHhVrXy2eYGsUh0Z4.ttf"
//   },
//   {
//     "family": "Finlandica",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/finlandica/v8/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19AJrEjx9i5ss3a3.ttf",
//       "600": "https://fonts.gstatic.com/s/finlandica/v8/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19Dlq0jx9i5ss3a3.ttf",
//       "700": "https://fonts.gstatic.com/s/finlandica/v8/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19Dcq0jx9i5ss3a3.ttf",
//       "regular": "https://fonts.gstatic.com/s/finlandica/v8/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19A7rEjx9i5ss3a3.ttf",
//       "italic": "https://fonts.gstatic.com/s/finlandica/v8/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz76Cy_CpOtma3uNQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/finlandica/v8/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz75Ky_CpOtma3uNQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/finlandica/v8/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz7361_CpOtma3uNQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/finlandica/v8/-nFuOGk-8vAc7lEtg0aS45mfNAn722rq0MXz70e1_CpOtma3uNQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/finlandica/v8/-nFsOGk-8vAc7lEtg0aSyZCty9GSsPBE19A7rHjw_Co.ttf"
//   },
//   {
//     "family": "REM",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2023-07-24",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPMLrrToUbIqIfBU.ttf",
//       "200": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPELqrToUbIqIfBU.ttf",
//       "300": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPJzqrToUbIqIfBU.ttf",
//       "500": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPPDqrToUbIqIfBU.ttf",
//       "600": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPBztrToUbIqIfBU.ttf",
//       "700": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPCXtrToUbIqIfBU.ttf",
//       "800": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPELtrToUbIqIfBU.ttf",
//       "900": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPGvtrToUbIqIfBU.ttf",
//       "regular": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPMLqrToUbIqIfBU.ttf",
//       "100italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpRXgeaKiNbBVWkw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpxXkeaKiNbBVWkw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpG3keaKiNbBVWkw.ttf",
//       "italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpRXkeaKiNbBVWkw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpd3keaKiNbBVWkw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpm34eaKiNbBVWkw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpon4eaKiNbBVWkw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqpxX4eaKiNbBVWkw.ttf",
//       "900italic": "https://fonts.gstatic.com/s/rem/v2/WnzmHAIoSDytZCogaeLNP7XTKQqp7H4eaKiNbBVWkw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rem/v2/WnzgHAIoSDyHbRjfsYumpRvUPMLqnTseaA.ttf"
//   },
//   {
//     "family": "Cagliostro",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cagliostro/v21/ZgNWjP5HM73BV5amnX-TjGXEM4COoE4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cagliostro/v21/ZgNWjP5HM73BV5amnX-TvGTONw.ttf"
//   },
//   {
//     "family": "Zhi Mang Xing",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zhimangxing/v17/f0Xw0ey79sErYFtWQ9a2rq-g0actfektIJ0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zhimangxing/v17/f0Xw0ey79sErYFtWQ9a2rq-g4aYneQ.ttf"
//   },
//   {
//     "family": "Akatab",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tifinagh"
//     ],
//     "version": "v7",
//     "lastModified": "2023-07-24",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE3rKXdPKNiaRpFvg.ttf",
//       "600": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE3gKLdPKNiaRpFvg.ttf",
//       "700": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE35KPdPKNiaRpFvg.ttf",
//       "800": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE3-KDdPKNiaRpFvg.ttf",
//       "900": "https://fonts.gstatic.com/s/akatab/v7/VuJzdNrK3Z7gqJE33KHdPKNiaRpFvg.ttf",
//       "regular": "https://fonts.gstatic.com/s/akatab/v7/VuJwdNrK3Z7gqJEPWIz5NIh-YA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/akatab/v7/VuJwdNrK3Z7gqJE_WYb9.ttf"
//   },
//   {
//     "family": "Praise",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/praise/v7/qkBUXvUZ-cnFXcFyDvO67L9XmQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/praise/v7/qkBUXvUZ-cnFXcFCD_m-.ttf"
//   },
//   {
//     "family": "Reddit Mono",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2024-03-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUQCYacnnYz7yQYA.ttf",
//       "300": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUniYacnnYz7yQYA.ttf",
//       "500": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vU8iYacnnYz7yQYA.ttf",
//       "600": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUHiEacnnYz7yQYA.ttf",
//       "700": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUJyEacnnYz7yQYA.ttf",
//       "800": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUQCEacnnYz7yQYA.ttf",
//       "900": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUaSEacnnYz7yQYA.ttf",
//       "regular": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUwCYacnnYz7yQYA.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redditmono/v1/oPWc_kRmmu4oQ88oo13o48DHbsqn28eR20vUwCYqc3Pc.ttf"
//   },
//   {
//     "family": "Simonetta",
//     "variants": [
//       "regular",
//       "italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "900": "https://fonts.gstatic.com/s/simonetta/v27/x3dnckHVYrCU5BU15c45-N0mtwTpDQIrGg.ttf",
//       "regular": "https://fonts.gstatic.com/s/simonetta/v27/x3dickHVYrCU5BU15c4BfPACvy_1BA.ttf",
//       "italic": "https://fonts.gstatic.com/s/simonetta/v27/x3dkckHVYrCU5BU15c4xfvoGnSrlBBsy.ttf",
//       "900italic": "https://fonts.gstatic.com/s/simonetta/v27/x3d5ckHVYrCU5BU15c4xfsKCsA7tLwc7Gn88.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/simonetta/v27/x3dickHVYrCU5BU15c4xffoG.ttf"
//   },
//   {
//     "family": "Train One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/trainone/v13/gyB-hwkiNtc6KnxUVjWHOqbZRY7JVQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/trainone/v13/gyB-hwkiNtc6KnxUVjW3O6zd.ttf"
//   },
//   {
//     "family": "Smooch Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiwUFodqIeNlzayg.ttf",
//       "200": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiQUBodqIeNlzayg.ttf",
//       "300": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oin0BodqIeNlzayg.ttf",
//       "500": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oi80BodqIeNlzayg.ttf",
//       "600": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiH0dodqIeNlzayg.ttf",
//       "700": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiJkdodqIeNlzayg.ttf",
//       "800": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiQUdodqIeNlzayg.ttf",
//       "900": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiaEdodqIeNlzayg.ttf",
//       "regular": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiwUBodqIeNlzayg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/smoochsans/v13/c4mz1n5uGsXss2LJh1QH6b129FZvxPj6I4oiwUBYd6ga.ttf"
//   },
//   {
//     "family": "New Tegomin",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/newtegomin/v10/SLXMc1fV7Gd9USdBAfPlqfN0Q3ptkDMN.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/newtegomin/v10/SLXMc1fV7Gd9USdBAfPlqcN1SX4.ttf"
//   },
//   {
//     "family": "Yaldevi",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sinhala"
//     ],
//     "version": "v12",
//     "lastModified": "2023-03-21",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpfxJzvobxLCBJkS.ttf",
//       "300": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpcvJzvobxLCBJkS.ttf",
//       "500": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdDJzvobxLCBJkS.ttf",
//       "600": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpevIDvobxLCBJkS.ttf",
//       "700": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpeWIDvobxLCBJkS.ttf",
//       "regular": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdxJzvobxLCBJkS.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yaldevi/v12/cY9afj6VW0NMrDWtDNzCOwlPMq9SLpdxJwvpZRY.ttf"
//   },
//   {
//     "family": "Tulpen One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tulpenone/v25/dFa6ZfeC474skLgesc0CWj0w_HyIRlE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tulpenone/v25/dFa6ZfeC474skLgesc0Cajw6-A.ttf"
//   },
//   {
//     "family": "Vampiro One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2022-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vampiroone/v18/gokqH6DoDl5yXvJytFsdLkqnsvhIor3K.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vampiroone/v18/gokqH6DoDl5yXvJytFsdLnqmuPw.ttf"
//   },
//   {
//     "family": "Chathura",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v20",
//     "lastModified": "2022-04-21",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/chathura/v20/_gP91R7-rzUuVjim42dEq0SbTvZyuDo.ttf",
//       "300": "https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42eMiWSxYPp7oSNy.ttf",
//       "700": "https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42ecjmSxYPp7oSNy.ttf",
//       "800": "https://fonts.gstatic.com/s/chathura/v20/_gP81R7-rzUuVjim42eAjWSxYPp7oSNy.ttf",
//       "regular": "https://fonts.gstatic.com/s/chathura/v20/_gP71R7-rzUuVjim418goUC5S-Zy.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chathura/v20/_gP71R7-rzUuVjim428hq0Q.ttf"
//   },
//   {
//     "family": "Long Cang",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/longcang/v17/LYjAdGP8kkgoTec8zkRgrXArXN7HWQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/longcang/v17/LYjAdGP8kkgoTec8zkRQrHov.ttf"
//   },
//   {
//     "family": "Redacted",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/redacted/v8/Z9XVDmdRShme2O_7aITe4u2El6GC.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redacted/v8/Z9XVDmdRShme2O_7aLTf6Ok.ttf"
//   },
//   {
//     "family": "Spirax",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/spirax/v21/buE3poKgYNLy0F3cXktt-Csn-Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/spirax/v21/buE3poKgYNLy0F3sX0Fp.ttf"
//   },
//   {
//     "family": "Noto Sans Anatolian Hieroglyphs",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "anatolian-hieroglyphs",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansanatolianhieroglyphs/v16/ijw9s4roRME5LLRxjsRb8A0gKPSWq4BbDmHHu6j2pEtUJzZWXybIymc5QYo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansanatolianhieroglyphs/v16/ijw9s4roRME5LLRxjsRb8A0gKPSWq4BbDmHHu6j2pEtUJzZWbyfCzg.ttf"
//   },
//   {
//     "family": "Buda",
//     "variants": [
//       "300"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/buda/v29/GFDqWAN8mnyIJSSrG7UBr7pZKA0.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/buda/v29/GFDqWAN8mnyIJSSrK7QLqw.ttf"
//   },
//   {
//     "family": "Linden Hill",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lindenhill/v24/-F61fjxoKSg9Yc3hZgO8ygFI7CwC009k.ttf",
//       "italic": "https://fonts.gstatic.com/s/lindenhill/v24/-F63fjxoKSg9Yc3hZgO8yjFK5igg1l9kn-s.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lindenhill/v24/-F61fjxoKSg9Yc3hZgO8yjFJ5ig.ttf"
//   },
//   {
//     "family": "Autour One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/autourone/v24/UqyVK80cP25l3fJgbdfbk5lWVscxdKE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/autourone/v24/UqyVK80cP25l3fJgbdfbo5hcUg.ttf"
//   },
//   {
//     "family": "Wellfleet",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/wellfleet/v22/nuF7D_LfQJb3VYgX6eyT42aLDhO2HA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/wellfleet/v22/nuF7D_LfQJb3VYgX6eyj4myP.ttf"
//   },
//   {
//     "family": "BIZ UDMincho",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "greek-ext",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-01-18",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/bizudmincho/v9/EJROQgI6eOxFjBdKs38yhtWNyyvfpeFE-IyCrw.ttf",
//       "regular": "https://fonts.gstatic.com/s/bizudmincho/v9/EJRRQgI6eOxFjBdKs38yhtW1dwT7rcpY8Q.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bizudmincho/v9/EJRRQgI6eOxFjBdKs38yhtWFdg7_.ttf"
//   },
//   {
//     "family": "Ruluko",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ruluko/v21/xMQVuFNZVaODtm0pC6WzKX_QmA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ruluko/v21/xMQVuFNZVaODtm0ZCq-3.ttf"
//   },
//   {
//     "family": "Fascinate",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fascinate/v21/z7NWdRrufC8XJK0IIEli1LbQRPyNrw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fascinate/v21/z7NWdRrufC8XJK0IIElS1bzU.ttf"
//   },
//   {
//     "family": "Dr Sugiyama",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/drsugiyama/v28/HTxoL2k4N3O9n5I1boGI7abRM4-t-g7y.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/drsugiyama/v28/HTxoL2k4N3O9n5I1boGI7ZbQOYs.ttf"
//   },
//   {
//     "family": "Gentium Book Plus",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFO2-RHBgUK5fbjKxRpbBtJPyRpocojWpbGhs_cfKe1.ttf",
//       "regular": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFL2-RHBgUK5fbjKxRpbBtJPyRpofKfdbLOrdPV.ttf",
//       "italic": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFN2-RHBgUK5fbjKxRpbBtJPyRpocKdf7bsqMPVZb4.ttf",
//       "700italic": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFA2-RHBgUK5fbjKxRpbBtJPyRpocKdRwrDjMv-ebe1Els.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gentiumbookplus/v1/vEFL2-RHBgUK5fbjKxRpbBtJPyRpocKef7Y.ttf"
//   },
//   {
//     "family": "IBM Plex Sans Thai Looped",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "thai"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss5AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_HaKpHOtFCQ76Q.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_NqrhFmDGC0i8Cc.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_L6ohFmDGC0i8Cc.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_OaphFmDGC0i8Cc.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_MquhFmDGC0i8Cc.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss6AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L_K6vhFmDGC0i8Cc.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30LxBKAoFGoBCQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsansthailooped/v11/tss_AoJJRAhL3BTrK3r2xxbFhvKfyBB6l7hHT30L9BOKpA.ttf"
//   },
//   {
//     "family": "Noto Sans Symbols 2",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "braille",
//       "latin",
//       "latin-ext",
//       "math",
//       "mayan-numerals",
//       "symbols"
//     ],
//     "version": "v23",
//     "lastModified": "2023-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssymbols2/v23/I_uyMoGduATTei9eI8daxVHDyfisHr71ypPqfX71-AI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssymbols2/v23/I_uyMoGduATTei9eI8daxVHDyfisHr71-pLgeQ.ttf"
//   },
//   {
//     "family": "Benne",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/benne/v22/L0xzDFAhn18E6Vjxlt6qTDBN.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/benne/v22/L0xzDFAhn18E6WjwnNo.ttf"
//   },
//   {
//     "family": "Kirang Haerang",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kiranghaerang/v20/E21-_dn_gvvIjhYON1lpIU4-bcqvWPaJq4no.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kiranghaerang/v20/E21-_dn_gvvIjhYON1lpIU4-bfquUvI.ttf"
//   },
//   {
//     "family": "Kumar One Outline",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kumaroneoutline/v17/Noao6VH62pyLP0fsrZ-v18wlUEcX9zDwRQu8EGKF.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kumaroneoutline/v17/Noao6VH62pyLP0fsrZ-v18wlUEcX9wDxTw8.ttf"
//   },
//   {
//     "family": "Liu Jian Mao Cao",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/liujianmaocao/v20/845DNN84HJrccNonurqXILGpvCOoferVKGWsUo8.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/liujianmaocao/v20/845DNN84HJrccNonurqXILGpvCOoTevfLA.ttf"
//   },
//   {
//     "family": "Kavoon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kavoon/v23/pxiFyp4_scRYhlU4NLr6f1pdEQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kavoon/v23/pxiFyp4_scRYhlUINbD-.ttf"
//   },
//   {
//     "family": "Marko One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-04-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/markoone/v22/9Btq3DFG0cnVM5lw1haaKpUfrHPzUw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/markoone/v22/9Btq3DFG0cnVM5lw1haqK58b.ttf"
//   },
//   {
//     "family": "Poor Story",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/poorstory/v20/jizfREFUsnUct9P6cDfd4OmnLD0Z4zM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/poorstory/v20/jizfREFUsnUct9P6cDfd0OitKA.ttf"
//   },
//   {
//     "family": "Ravi Prakash",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/raviprakash/v19/gokpH6fsDkVrF9Bv9X8SOAKHmNZEq6TTFw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/raviprakash/v19/gokpH6fsDkVrF9Bv9X8SOAK3mdxA.ttf"
//   },
//   {
//     "family": "Gentium Plus",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2023-04-27",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/gentiumplus/v2/IurC6Ytw-oSPaZ00r2bNe8VRMLRo4EYx_ofHsw.ttf",
//       "regular": "https://fonts.gstatic.com/s/gentiumplus/v2/Iurd6Ytw-oSPaZ00r2bNe8VpjJtM6G0t9w.ttf",
//       "italic": "https://fonts.gstatic.com/s/gentiumplus/v2/IurD6Ytw-oSPaZ00r2bNe8VZjpFIymg9957e.ttf",
//       "700italic": "https://fonts.gstatic.com/s/gentiumplus/v2/IurA6Ytw-oSPaZ00r2bNe8VZjqn05Uw13ILXs-h6.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gentiumplus/v2/Iurd6Ytw-oSPaZ00r2bNe8VZjZFI.ttf"
//   },
//   {
//     "family": "Keania One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/keaniaone/v24/zOL54pXJk65E8pXardnuycRuv-hHkOs.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/keaniaone/v24/zOL54pXJk65E8pXardnu-cVkuw.ttf"
//   },
//   {
//     "family": "Imperial Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/imperialscript/v6/5DCPAKrpzy_H98IV2ISnZBbGrVNvPenlvttWNg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/imperialscript/v6/5DCPAKrpzy_H98IV2ISnZBbGrVNfPOPh.ttf"
//   },
//   {
//     "family": "Atomic Age",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/atomicage/v27/f0Xz0eug6sdmRFkYZZGL58Ht9a8GYeA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/atomicage/v27/f0Xz0eug6sdmRFkYZZGL18Dn8Q.ttf"
//   },
//   {
//     "family": "Sono",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVNkWdEnR4qYeB4Q.ttf",
//       "300": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxV6EWdEnR4qYeB4Q.ttf",
//       "500": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVhEWdEnR4qYeB4Q.ttf",
//       "600": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVaEKdEnR4qYeB4Q.ttf",
//       "700": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVUUKdEnR4qYeB4Q.ttf",
//       "800": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVNkKdEnR4qYeB4Q.ttf",
//       "regular": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVtkWdEnR4qYeB4Q.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sono/v6/aFT97PNiY3U2Cqf_aYEN64CYaK18YWJEsV6u-QLiOsxVtkWtE358.ttf"
//   },
//   {
//     "family": "Englebert",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/englebert/v21/xn7iYH8w2XGrC8AR4HSxT_fYdN-WZw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/englebert/v21/xn7iYH8w2XGrC8AR4HSBTv3c.ttf"
//   },
//   {
//     "family": "Noto Sans Math",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "math"
//     ],
//     "version": "v15",
//     "lastModified": "2022-05-10",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmath/v15/7Aump_cpkSecTWaHRlH2hyV5UHkG-V048PW0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmath/v15/7Aump_cpkSecTWaHRlH2hyV5UEkH81k.ttf"
//   },
//   {
//     "family": "Mrs Sheppards",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mrssheppards/v23/PN_2Rfm9snC0XUGoEZhb91ig3vjxynMix4Y.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mrssheppards/v23/PN_2Rfm9snC0XUGoEZhb91ig7vn7zg.ttf"
//   },
//   {
//     "family": "Protest Riot",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-02-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/protestriot/v2/d6lPkaOxWMKm7TdezXFmpkrM1_JgjmRpOA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/protestriot/v2/d6lPkaOxWMKm7TdezXFmpkr81vhk.ttf"
//   },
//   {
//     "family": "Climate Crisis",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2024-02-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/climatecrisis/v8/wEOpEB3AntNeKCPBVW9XOKlmp3AUgWFN1DvIvcM0gFp6jaUrGb7PsQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/climatecrisis/v8/wEOpEB3AntNeKCPBVW9XOKlmp3AUgWFN1DvIvcM0gFpKjK8v.ttf"
//   },
//   {
//     "family": "Plaster",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/plaster/v24/DdTm79QatW80eRh4Ei5JOtLOeLI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/plaster/v24/DdTm79QatW80eRh4Ii9DPg.ttf"
//   },
//   {
//     "family": "Marhey",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/marhey/v6/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBZVwO2cXiGevOMw.ttf",
//       "500": "https://fonts.gstatic.com/s/marhey/v6/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBflwO2cXiGevOMw.ttf",
//       "600": "https://fonts.gstatic.com/s/marhey/v6/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBRV3O2cXiGevOMw.ttf",
//       "700": "https://fonts.gstatic.com/s/marhey/v6/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBSx3O2cXiGevOMw.ttf",
//       "regular": "https://fonts.gstatic.com/s/marhey/v6/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBctwO2cXiGevOMw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/marhey/v6/x3d8ck7Laq-T7wl7mqfVrEe9sDvtBctwC2YdjA.ttf"
//   },
//   {
//     "family": "Playpen Sans",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "emoji",
//       "latin",
//       "latin-ext",
//       "math",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2024-04-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IvRgunQ9Ffmme0w.ttf",
//       "200": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4InRhunQ9Ffmme0w.ttf",
//       "300": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IqphunQ9Ffmme0w.ttf",
//       "500": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IsZhunQ9Ffmme0w.ttf",
//       "600": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IipmunQ9Ffmme0w.ttf",
//       "700": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IhNmunQ9Ffmme0w.ttf",
//       "800": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4InRmunQ9Ffmme0w.ttf",
//       "regular": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IvRhunQ9Ffmme0w.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/playpensans/v6/dg43_pj1p6gXP0gzAZgm4c8XQArSU7ACQSn4IvRhinU3EQ.ttf"
//   },
//   {
//     "family": "Kumar One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kumarone/v21/bMr1mS-P958wYi6YaGeGNO6WU3oT0g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kumarone/v21/bMr1mS-P958wYi6YaGe2NeSS.ttf"
//   },
//   {
//     "family": "Noto Serif Khojki",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "khojki",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2024-02-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notoserifkhojki/v11/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMY4AhvyZ0Qtc5HAQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifkhojki/v11/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMYDA9vyZ0Qtc5HAQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifkhojki/v11/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMYNQ9vyZ0Qtc5HAQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifkhojki/v11/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMY0ghvyZ0Qtc5HAQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifkhojki/v11/I_uHMoOduATTei9aP90ctmPGxP2rBKTM4mcQ5M3z9QMY0ghfyJcU.ttf"
//   },
//   {
//     "family": "Gupter",
//     "variants": [
//       "regular",
//       "500",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/gupter/v14/2-cl9JNmxJqPO1Qslb-bUsT5rZhaZg.ttf",
//       "700": "https://fonts.gstatic.com/s/gupter/v14/2-cl9JNmxJqPO1Qs3bmbUsT5rZhaZg.ttf",
//       "regular": "https://fonts.gstatic.com/s/gupter/v14/2-cm9JNmxJqPO1QUYZa_Wu_lpA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gupter/v14/2-cm9JNmxJqPO1QkYJy7.ttf"
//   },
//   {
//     "family": "Sahitya",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/sahitya/v17/6qLFKZkOuhnuqlJAUZsqGyQvEnvSexI.ttf",
//       "regular": "https://fonts.gstatic.com/s/sahitya/v17/6qLAKZkOuhnuqlJAaScFPywEDnI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sahitya/v17/6qLAKZkOuhnuqlJAWSYPOw.ttf"
//   },
//   {
//     "family": "Koh Santepheap",
//     "variants": [
//       "100",
//       "300",
//       "regular",
//       "700",
//       "900"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMfW3p6SJbwyGj2rBZyeOrTjNuFHVyTtjNJUWU.ttf",
//       "300": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMeW3p6SJbwyGj2rBZyeOrTjNtNP3y5mD9ASHz5.ttf",
//       "700": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMeW3p6SJbwyGj2rBZyeOrTjNtdOHy5mD9ASHz5.ttf",
//       "900": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMeW3p6SJbwyGj2rBZyeOrTjNtlOny5mD9ASHz5.ttf",
//       "regular": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMdW3p6SJbwyGj2rBZyeOrTjOPhF1ixsyNJ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kohsantepheap/v11/gNMdW3p6SJbwyGj2rBZyeOrTjNPgHVw.ttf"
//   },
//   {
//     "family": "Ysabeau",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-01-26",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7OWCTYwI8Gcw6Oi.ttf",
//       "200": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7MWCDYwI8Gcw6Oi.ttf",
//       "300": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7PICDYwI8Gcw6Oi.ttf",
//       "500": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7OkCDYwI8Gcw6Oi.ttf",
//       "600": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7NIDzYwI8Gcw6Oi.ttf",
//       "700": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7NxDzYwI8Gcw6Oi.ttf",
//       "800": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7MWDzYwI8Gcw6Oi.ttf",
//       "900": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7M_DzYwI8Gcw6Oi.ttf",
//       "regular": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7OWCDYwI8Gcw6Oi.ttf",
//       "100italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeS95yKcW-xrOiIUw.ttf",
//       "200italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeS15zKcW-xrOiIUw.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeS4BzKcW-xrOiIUw.ttf",
//       "italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeS95zKcW-xrOiIUw.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeS-xzKcW-xrOiIUw.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeSwB0KcW-xrOiIUw.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeSzl0KcW-xrOiIUw.ttf",
//       "800italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeS150KcW-xrOiIUw.ttf",
//       "900italic": "https://fonts.gstatic.com/s/ysabeau/v2/kmKsZqEiBAXLcnuMlD9_3CYscnjwsKZeS3d0KcW-xrOiIUw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ysabeau/v2/kmKiZqEiBAXLcnuMvjZNI_5FGeJet7OWCAYxKcU.ttf"
//   },
//   {
//     "family": "Joti One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jotione/v26/Z9XVDmdJQAmWm9TwaYTe4u2El6GC.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jotione/v26/Z9XVDmdJQAmWm9TwabTf6Ok.ttf"
//   },
//   {
//     "family": "Fascinate Inline",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fascinateinline/v22/jVyR7mzzB3zc-jp6QCAu60poNqIy1g3CfRXxWZQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fascinateinline/v22/jVyR7mzzB3zc-jp6QCAu60poNqIy5gzIeQ.ttf"
//   },
//   {
//     "family": "Rethink Sans",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2024-03-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/rethinksans/v4/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45DmqklNRCE7mma-aua4.ttf",
//       "600": "https://fonts.gstatic.com/s/rethinksans/v4/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45Dmqkr9WCE7mma-aua4.ttf",
//       "700": "https://fonts.gstatic.com/s/rethinksans/v4/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45DmqkoZWCE7mma-aua4.ttf",
//       "800": "https://fonts.gstatic.com/s/rethinksans/v4/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45DmqkuFWCE7mma-aua4.ttf",
//       "regular": "https://fonts.gstatic.com/s/rethinksans/v4/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45DmqkmFRCE7mma-aua4.ttf",
//       "italic": "https://fonts.gstatic.com/s/rethinksans/v4/AMOFz4SDuXOMCPfdoglY9JQEUUK5wyyTfpeth6kS4A3snY2fqa7iVQ.ttf",
//       "500italic": "https://fonts.gstatic.com/s/rethinksans/v4/AMOFz4SDuXOMCPfdoglY9JQEUUK5wyyTfpeth6kS0g3snY2fqa7iVQ.ttf",
//       "600italic": "https://fonts.gstatic.com/s/rethinksans/v4/AMOFz4SDuXOMCPfdoglY9JQEUUK5wyyTfpeth6kSPgrsnY2fqa7iVQ.ttf",
//       "700italic": "https://fonts.gstatic.com/s/rethinksans/v4/AMOFz4SDuXOMCPfdoglY9JQEUUK5wyyTfpeth6kSBwrsnY2fqa7iVQ.ttf",
//       "800italic": "https://fonts.gstatic.com/s/rethinksans/v4/AMOFz4SDuXOMCPfdoglY9JQEUUK5wyyTfpeth6kSYArsnY2fqa7iVQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rethinksans/v4/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45DmqkmFROE_snQ.ttf"
//   },
//   {
//     "family": "Content",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "khmer"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/content/v24/zrfg0HLayePhU_AwaRzdBirfWCHvkAI.ttf",
//       "regular": "https://fonts.gstatic.com/s/content/v24/zrfl0HLayePhU_AwUaDyIiL0RCg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/content/v24/zrfl0HLayePhU_AwYaH4Jg.ttf"
//   },
//   {
//     "family": "Londrina Shadow",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/londrinashadow/v26/oPWX_kB4kOQoWNJmjxLV5JuoCUlXRlaSxkrMCQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/londrinashadow/v26/oPWX_kB4kOQoWNJmjxLV5JuoCUlnR1yW.ttf"
//   },
//   {
//     "family": "Ranga",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/ranga/v21/C8cg4cYisGb28qY-AxgR6X2NZAn2.ttf",
//       "regular": "https://fonts.gstatic.com/s/ranga/v21/C8ct4cYisGb28p6CLDwZwmGE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ranga/v21/C8ct4cYisGb28q6DJjg.ttf"
//   },
//   {
//     "family": "Glass Antiqua",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/glassantiqua/v24/xfu30Wr0Wn3NOQM2piC0uXOjnL_wN6fRUkY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/glassantiqua/v24/xfu30Wr0Wn3NOQM2piC0uXOjrL76Mw.ttf"
//   },
//   {
//     "family": "Princess Sofia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/princesssofia/v25/qWczB6yguIb8DZ_GXZst16n7GRz7mDUoupoI.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/princesssofia/v25/qWczB6yguIb8DZ_GXZst16n7GSz6kjE.ttf"
//   },
//   {
//     "family": "Gwendolyn",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/gwendolyn/v7/qkBSXvoO_M3CSss-d7emWLtvmC7HONiSFQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/gwendolyn/v7/qkBXXvoO_M3CSss-d7ee5JRLkAXbMQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gwendolyn/v7/qkBXXvoO_M3CSss-d7eu5Z5P.ttf"
//   },
//   {
//     "family": "Romanesco",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/romanesco/v21/w8gYH2ozQOY7_r_J7mSn3HwLqOqSBg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/romanesco/v21/w8gYH2ozQOY7_r_J7mSX3XYP.ttf"
//   },
//   {
//     "family": "Risque",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/risque/v22/VdGfAZUfHosahXxoCUYVBJ-T5g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/risque/v22/VdGfAZUfHosahXxYCEwR.ttf"
//   },
//   {
//     "family": "Luxurious Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/luxuriousscript/v7/ahcCv9e7yydulT32KZ0rBIoD7DzMg0rOby1JtYk.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/luxuriousscript/v7/ahcCv9e7yydulT32KZ0rBIoD7DzMs0vEaw.ttf"
//   },
//   {
//     "family": "Bahiana",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bahiana/v23/uU9PCBUV4YenPWJU7xPb3vyHmlI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bahiana/v23/uU9PCBUV4YenPWJU3xLR2g.ttf"
//   },
//   {
//     "family": "Sirin Stencil",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sirinstencil/v25/mem4YaWwznmLx-lzGfN7MdRydchGBq6al6o.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sirinstencil/v25/mem4YaWwznmLx-lzGfN7MdRyRclMAg.ttf"
//   },
//   {
//     "family": "Odor Mean Chey",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2022-05-10",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/odormeanchey/v27/raxkHiKDttkTe1aOGcJMR1A_4mrY2zqUKafv.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/odormeanchey/v27/raxkHiKDttkTe1aOGcJMR1A_4lrZ0T4.ttf"
//   },
//   {
//     "family": "DynaPuff",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-05-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/dynapuff/v4/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RSyQu6YjrSRs4wn8.ttf",
//       "600": "https://fonts.gstatic.com/s/dynapuff/v4/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RS8gp6YjrSRs4wn8.ttf",
//       "700": "https://fonts.gstatic.com/s/dynapuff/v4/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RS_Ep6YjrSRs4wn8.ttf",
//       "regular": "https://fonts.gstatic.com/s/dynapuff/v4/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RSxYu6YjrSRs4wn8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dynapuff/v4/z7N5dRvsZDIVHbYPMhZJ3HQ83UaSu4uhr7-ZFeoYkgAr1x8RSxYu2YnhTQ.ttf"
//   },
//   {
//     "family": "Smokum",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/smokum/v28/TK3iWkUbAhopmrdGHjUHte5fKg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/smokum/v28/TK3iWkUbAhopmrd2Hz8D.ttf"
//   },
//   {
//     "family": "Elsie Swash Caps",
//     "variants": [
//       "regular",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "900": "https://fonts.gstatic.com/s/elsieswashcaps/v23/845ENN8xGZyVX5MVo_upKf7KnjK0RW74DG2HToawrdU.ttf",
//       "regular": "https://fonts.gstatic.com/s/elsieswashcaps/v23/845DNN8xGZyVX5MVo_upKf7KnjK0ferVKGWsUo8.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/elsieswashcaps/v23/845DNN8xGZyVX5MVo_upKf7KnjK0TevfLA.ttf"
//   },
//   {
//     "family": "Dangrek",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v30",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dangrek/v30/LYjCdG30nEgoH8E2gCNqqVIuTN4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dangrek/v30/LYjCdG30nEgoH8E2sCJgrQ.ttf"
//   },
//   {
//     "family": "Libre Barcode 39 Extended",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librebarcode39extended/v27/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgfNRCOZ1GOBw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebarcode39extended/v27/8At7Gt6_O5yNS0-K4Nf5U922qSzhJ3dUdfJpwNUgTNVIPQ.ttf"
//   },
//   {
//     "family": "Belanosima",
//     "variants": [
//       "regular",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v3",
//     "lastModified": "2023-08-25",
//     "files": {
//       "600": "https://fonts.gstatic.com/s/belanosima/v3/3y9n6bI8ejDo_3MfCDSL_Nh1M3pqn5cdJ-4.ttf",
//       "700": "https://fonts.gstatic.com/s/belanosima/v3/3y9n6bI8ejDo_3MfCDSL_Lx0M3pqn5cdJ-4.ttf",
//       "regular": "https://fonts.gstatic.com/s/belanosima/v3/3y9k6bI8ejDo_3MfCDSLxABbF3JBg54.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/belanosima/v3/3y9k6bI8ejDo_3MfCDSL9AFREw.ttf"
//   },
//   {
//     "family": "Water Brush",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/waterbrush/v4/AYCPpXPqc8cJWLhp4hywKHJq7PKP5Z_G.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/waterbrush/v4/AYCPpXPqc8cJWLhp4hywKEJr5vY.ttf"
//   },
//   {
//     "family": "Piedra",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/piedra/v25/ke8kOg8aN0Bn7hTunEyHN_M3gA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/piedra/v25/ke8kOg8aN0Bn7hTenUaD.ttf"
//   },
//   {
//     "family": "Victor Mono",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCyO8_NhNyOV0Y9bQ.ttf",
//       "200": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCyu87NhNyOV0Y9bQ.ttf",
//       "300": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCyZc7NhNyOV0Y9bQ.ttf",
//       "500": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCyCc7NhNyOV0Y9bQ.ttf",
//       "600": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCy5cnNhNyOV0Y9bQ.ttf",
//       "700": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCy3MnNhNyOV0Y9bQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCyO87NhNyOV0Y9bQ.ttf",
//       "100italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6B-LGQWyfv-LGy7lEO0_ZYrRskvW7bUNen840lxtaKdUMtba8p.ttf",
//       "200italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6B-LGQWyfv-LGy7lEO0_ZYrRskvW7bUNen842lx9aKdUMtba8p.ttf",
//       "300italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6B-LGQWyfv-LGy7lEO0_ZYrRskvW7bUNen8417x9aKdUMtba8p.ttf",
//       "italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6B-LGQWyfv-LGy7lEO0_ZYrRskvW7bUNen840lx9aKdUMtba8p.ttf",
//       "500italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6B-LGQWyfv-LGy7lEO0_ZYrRskvW7bUNen840Xx9aKdUMtba8p.ttf",
//       "600italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6B-LGQWyfv-LGy7lEO0_ZYrRskvW7bUNen8437wNaKdUMtba8p.ttf",
//       "700italic": "https://fonts.gstatic.com/s/victormono/v3/Yq6B-LGQWyfv-LGy7lEO0_ZYrRskvW7bUNen843CwNaKdUMtba8p.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/victormono/v3/Yq6H-LGQWyfv-LGy7lEO09xRn-T81AVB_tCyO879hdaK.ttf"
//   },
//   {
//     "family": "Lancelot",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lancelot/v26/J7acnppxBGtQEulG4JY4xJ9CGyAa.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lancelot/v26/J7acnppxBGtQEulG4KY5zps.ttf"
//   },
//   {
//     "family": "Yeon Sung",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yeonsung/v20/QldMNTpbohAGtsJvUn6xSVNazqx2xg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yeonsung/v20/QldMNTpbohAGtsJvUn6BSFle.ttf"
//   },
//   {
//     "family": "Amiri Quran",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/amiriquran/v14/_Xmo-Hk0rD6DbUL4_vH8Zq5t7Cycsu-2.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/amiriquran/v14/_Xmo-Hk0rD6DbUL4_vH8Zp5s5ig.ttf"
//   },
//   {
//     "family": "Ribeye Marrow",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ribeyemarrow/v24/GFDsWApshnqMRO2JdtRZ2d0vEAwTVWgKdtw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ribeyemarrow/v24/GFDsWApshnqMRO2JdtRZ2d0vIA0ZUQ.ttf"
//   },
//   {
//     "family": "Labrada",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2023-03-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VTSgM4QPdUej17.ttf",
//       "200": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9XTSwM4QPdUej17.ttf",
//       "300": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9UNSwM4QPdUej17.ttf",
//       "500": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VhSwM4QPdUej17.ttf",
//       "600": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9WNTAM4QPdUej17.ttf",
//       "700": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9W0TAM4QPdUej17.ttf",
//       "800": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9XTTAM4QPdUej17.ttf",
//       "900": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9X6TAM4QPdUej17.ttf",
//       "regular": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VTSwM4QPdUej17.ttf",
//       "100italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCOt6SvN2fy17-dE.ttf",
//       "200italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCGt7SvN2fy17-dE.ttf",
//       "300italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCLV7SvN2fy17-dE.ttf",
//       "italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCOt7SvN2fy17-dE.ttf",
//       "500italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCNl7SvN2fy17-dE.ttf",
//       "600italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCDV8SvN2fy17-dE.ttf",
//       "700italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCAx8SvN2fy17-dE.ttf",
//       "800italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCGt8SvN2fy17-dE.ttf",
//       "900italic": "https://fonts.gstatic.com/s/labrada/v2/ieVv2Y9HLWefIpOyPVxQIkLq2VfhwMCbCEJ8SvN2fy17-dE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/labrada/v2/ieVh2Y9HLWefIpOyF1Vi3ZqDss1Px9VTSzM5SvM.ttf"
//   },
//   {
//     "family": "Almendra SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/almendrasc/v29/Iure6Yx284eebowr7hbyTZZJprVA4XQ0.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/almendrasc/v29/Iure6Yx284eebowr7hbyTaZIrLE.ttf"
//   },
//   {
//     "family": "Preahvihear",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/preahvihear/v29/6NUS8F-dNQeEYhzj7uluxswE49FJf8Wv.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/preahvihear/v29/6NUS8F-dNQeEYhzj7uluxvwF6dU.ttf"
//   },
//   {
//     "family": "Zen Tokyo Zoo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zentokyozoo/v7/NGSyv5ffC0J_BK6aFNtr6sRv8a1uRWe9amg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zentokyozoo/v7/NGSyv5ffC0J_BK6aFNtr6sRvwaxkQQ.ttf"
//   },
//   {
//     "family": "Hubballi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hubballi/v7/o-0JIpUj3WIZ1RFN56B7yBBNYuSF.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hubballi/v7/o-0JIpUj3WIZ1RFN55B6whQ.ttf"
//   },
//   {
//     "family": "Ewert",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ewert/v25/va9I4kzO2tFODYBvS-J3kbDP.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ewert/v25/va9I4kzO2tFODbBuQeY.ttf"
//   },
//   {
//     "family": "Noto Serif Armenian",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "armenian",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-11-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8ObxvXagGdkbg.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZC8KbxvXagGdkbg.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZ1cKbxvXagGdkbg.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZucKbxvXagGdkbg.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZVcWbxvXagGdkbg.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZbMWbxvXagGdkbg.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZC8WbxvXagGdkbg.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZIsWbxvXagGdkbg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8KbxvXagGdkbg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifarmenian/v27/3XFMEqMt3YoFsciDRZxptyCUKJmytZ0kVU-XvF7QaZuL85rnQ_zDNzDe5xNnKxyZi8Krx__e.ttf"
//   },
//   {
//     "family": "Festive",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/festive/v9/cY9Ffj6KX1xcoDWhFtfgy9HTkak.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/festive/v9/cY9Ffj6KX1xcoDWhJtbqzw.ttf"
//   },
//   {
//     "family": "Jacques Francois Shadow",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jacquesfrancoisshadow/v25/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_q8VsHuV5ULS.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jacquesfrancoisshadow/v25/KR1FBtOz8PKTMk-kqdkLVrvR0ECFrB6Pin-2_p8Uun8.ttf"
//   },
//   {
//     "family": "Darumadrop One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/darumadropone/v10/cY9cfjeIW11dpCKgRLi675a87IhHBJOxZQPp.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/darumadropone/v10/cY9cfjeIW11dpCKgRLi675a87LhGDpc.ttf"
//   },
//   {
//     "family": "Snippet",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/snippet/v21/bWt47f7XfQH9Gupu2v_Afcp9QWc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/snippet/v21/bWt47f7XfQH9Gupu6v7KeQ.ttf"
//   },
//   {
//     "family": "Grenze",
//     "variants": [
//       "100",
//       "100italic",
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v15",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/grenze/v15/O4ZRFGb7hR12BxqPm2IjuAkalnmd.ttf",
//       "200": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPN0MDkicWn2CEyw.ttf",
//       "300": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPU0ADkicWn2CEyw.ttf",
//       "500": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPC0EDkicWn2CEyw.ttf",
//       "600": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPJ0YDkicWn2CEyw.ttf",
//       "700": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPQ0cDkicWn2CEyw.ttf",
//       "800": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPX0QDkicWn2CEyw.ttf",
//       "900": "https://fonts.gstatic.com/s/grenze/v15/O4ZQFGb7hR12BxqPe0UDkicWn2CEyw.ttf",
//       "100italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZXFGb7hR12BxqH_VpHsg04k2md0kI.ttf",
//       "200italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_Vrrky0SvWWUy1uW.ttf",
//       "300italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VqPkC0SvWWUy1uW.ttf",
//       "regular": "https://fonts.gstatic.com/s/grenze/v15/O4ZTFGb7hR12Bxq3_2gnmgwKlg.ttf",
//       "italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZRFGb7hR12BxqH_WIjuAkalnmd.ttf",
//       "500italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VrXkS0SvWWUy1uW.ttf",
//       "600italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_Vr7li0SvWWUy1uW.ttf",
//       "700italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_Vqfly0SvWWUy1uW.ttf",
//       "800italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VqDlC0SvWWUy1uW.ttf",
//       "900italic": "https://fonts.gstatic.com/s/grenze/v15/O4ZWFGb7hR12BxqH_VqnlS0SvWWUy1uW.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/grenze/v15/O4ZTFGb7hR12BxqH_mIj.ttf"
//   },
//   {
//     "family": "Hanalei Fill",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hanaleifill/v22/fC1mPYtObGbfyQznIaQzPQiMVwLBplm9aw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hanaleifill/v22/fC1mPYtObGbfyQznIaQzPQi8VgjF.ttf"
//   },
//   {
//     "family": "Noto Serif Kannada",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYCceRJ71svgcI.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgUYDceRJ71svgcI.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgZgDceRJ71svgcI.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgfQDceRJ71svgcI.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgRgEceRJ71svgcI.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgSEEceRJ71svgcI.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgUYEceRJ71svgcI.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgW8EceRJ71svgcI.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYDceRJ71svgcI.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifkannada/v27/v6-8GZHLJFKIhClqUYqXDiWqpxQxWSPoW6bz-l4hGHiNgcYDQeVD6w.ttf"
//   },
//   {
//     "family": "Anek Gujarati",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0F5G7w0KgB7Lm7g.ttf",
//       "200": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0l5C7w0KgB7Lm7g.ttf",
//       "300": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0SZC7w0KgB7Lm7g.ttf",
//       "500": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0JZC7w0KgB7Lm7g.ttf",
//       "600": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0yZe7w0KgB7Lm7g.ttf",
//       "700": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-08Je7w0KgB7Lm7g.ttf",
//       "800": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0l5e7w0KgB7Lm7g.ttf",
//       "regular": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0F5C7w0KgB7Lm7g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anekgujarati/v9/l7g_bj5oysqknvkCo2T_8FuiIRBA7lncQUmbIBEtPKiYYQhRwyBxCD-0F5CLwkik.ttf"
//   },
//   {
//     "family": "Anek Gurmukhi",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-03-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbd5ppXK41H6DjbA.ttf",
//       "200": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkb95tpXK41H6DjbA.ttf",
//       "300": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbKZtpXK41H6DjbA.ttf",
//       "500": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbRZtpXK41H6DjbA.ttf",
//       "600": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbqZxpXK41H6DjbA.ttf",
//       "700": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbkJxpXK41H6DjbA.ttf",
//       "800": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkb95xpXK41H6DjbA.ttf",
//       "regular": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbd5tpXK41H6DjbA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anekgurmukhi/v8/0QIAMXRO_YSkA0quVLY79JnHybfeEOrXCa9Dmd9Ql6a6R_vEMc5TaLkbd5tZXaQx.ttf"
//   },
//   {
//     "family": "Noto Sans Gothic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gothic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansgothic/v16/TuGKUUVzXI5FBtUq5a8bj6wRbzxTFMX40kFQRx0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansgothic/v16/TuGKUUVzXI5FBtUq5a8bj6wRbzxTJMTy1g.ttf"
//   },
//   {
//     "family": "Griffy",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/griffy/v22/FwZa7-ox2FQh9kfwSNSEwM2zpA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/griffy/v22/FwZa7-ox2FQh9kfASd6A.ttf"
//   },
//   {
//     "family": "Oldenburg",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/oldenburg/v22/fC1jPY5JYWzbywv7c4V6UU6oXyndrw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oldenburg/v22/fC1jPY5JYWzbywv7c4VKUESs.ttf"
//   },
//   {
//     "family": "Noto Sans Samaritan",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "samaritan"
//     ],
//     "version": "v16",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssamaritan/v16/buEqppe9f8_vkXadMBJJo0tSmaYjFkxOUo5jNlOVMzQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssamaritan/v16/buEqppe9f8_vkXadMBJJo0tSmaYjFkxOYo9pMg.ttf"
//   },
//   {
//     "family": "Diplomata SC",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/diplomatasc/v28/buExpoi3ecvs3kidKgBJo2kf-P5Oaiw4cw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/diplomatasc/v28/buExpoi3ecvs3kidKgBJo2kv-fRK.ttf"
//   },
//   {
//     "family": "Gulzar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gulzar/v12/Wnz6HAc9eB3HB2ILYTwZqg_MPQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gulzar/v12/Wnz6HAc9eB3HB2I7YDYd.ttf"
//   },
//   {
//     "family": "Bonheur Royale",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bonheurroyale/v13/c4m51nt_GMTrtX-b9GcG4-YRmYK_c0f1N5Ij.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bonheurroyale/v13/c4m51nt_GMTrtX-b9GcG4-YRmbK-eUM.ttf"
//   },
//   {
//     "family": "Kode Mono",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2024-02-16",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kodemono/v1/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq6uy9DqTaOW2A3k.ttf",
//       "600": "https://fonts.gstatic.com/s/kodemono/v1/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq0e19DqTaOW2A3k.ttf",
//       "700": "https://fonts.gstatic.com/s/kodemono/v1/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq3619DqTaOW2A3k.ttf",
//       "regular": "https://fonts.gstatic.com/s/kodemono/v1/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq5my9DqTaOW2A3k.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kodemono/v1/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq5myxDuZbA.ttf"
//   },
//   {
//     "family": "Ysabeau Infant",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-01-26",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYFo4HK5ChLwKH6A.ttf",
//       "200": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYlo8HK5ChLwKH6A.ttf",
//       "300": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYSI8HK5ChLwKH6A.ttf",
//       "500": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYJI8HK5ChLwKH6A.ttf",
//       "600": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYyIgHK5ChLwKH6A.ttf",
//       "700": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQY8YgHK5ChLwKH6A.ttf",
//       "800": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYlogHK5ChLwKH6A.ttf",
//       "900": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYv4gHK5ChLwKH6A.ttf",
//       "regular": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYFo8HK5ChLwKH6A.ttf",
//       "100italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3szvaZqlDQeX6Dc5.ttf",
//       "200italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3sxvaJqlDQeX6Dc5.ttf",
//       "300italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3syxaJqlDQeX6Dc5.ttf",
//       "italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3szvaJqlDQeX6Dc5.ttf",
//       "500italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3szdaJqlDQeX6Dc5.ttf",
//       "600italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3swxb5qlDQeX6Dc5.ttf",
//       "700italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3swIb5qlDQeX6Dc5.ttf",
//       "800italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3sxvb5qlDQeX6Dc5.ttf",
//       "900italic": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-AlzpqOkkV94kBTQVdX1EWI_p9ZR4c8LTTNzMN3sxGb5qlDQeX6Dc5.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ysabeauinfant/v2/hv-ClzpqOkkV94kBTQVdX1EWI9B0V-HEmd9JmTQYFo83Kpql.ttf"
//   },
//   {
//     "family": "Encode Sans SC",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhn8c9NOEEClIc.ttf",
//       "200": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HPhm8c9NOEEClIc.ttf",
//       "300": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HCZm8c9NOEEClIc.ttf",
//       "500": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HEpm8c9NOEEClIc.ttf",
//       "600": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HKZh8c9NOEEClIc.ttf",
//       "700": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HJ9h8c9NOEEClIc.ttf",
//       "800": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HPhh8c9NOEEClIc.ttf",
//       "900": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HNFh8c9NOEEClIc.ttf",
//       "regular": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhm8c9NOEEClIc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/encodesanssc/v9/jVyp7nLwCGzQ9zE7ZyRg0QRXHPZc_uUA6Kb3VJWLE_Pdtm7lcD6qvXT1HHhmwc5HPA.ttf"
//   },
//   {
//     "family": "Dhurjati",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v24",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/dhurjati/v24/_6_8ED3gSeatXfFiFX3ySKQtuTA2.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/dhurjati/v24/_6_8ED3gSeatXfFiFU3zQqA.ttf"
//   },
//   {
//     "family": "Big Shoulders Stencil Text",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04XIGS_Py_AWbQ.ttf",
//       "200": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRU4TIGS_Py_AWbQ.ttf",
//       "300": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRjYTIGS_Py_AWbQ.ttf",
//       "500": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR4YTIGS_Py_AWbQ.ttf",
//       "600": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRDYPIGS_Py_AWbQ.ttf",
//       "700": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRNIPIGS_Py_AWbQ.ttf",
//       "800": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGRU4PIGS_Py_AWbQ.ttf",
//       "900": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGReoPIGS_Py_AWbQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04TIGS_Py_AWbQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigshouldersstenciltext/v26/5aUV9-i2oxDMNwY3dHfW7UAt3Q453SM15wNj53bCcab2SJYLLUtk1OGR04T4GCXL.ttf"
//   },
//   {
//     "family": "Truculenta",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v22",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAjswcFHnJMMhg.ttf",
//       "200": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMtAiswcFHnJMMhg.ttf",
//       "300": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMg4iswcFHnJMMhg.ttf",
//       "500": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMmIiswcFHnJMMhg.ttf",
//       "600": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMo4lswcFHnJMMhg.ttf",
//       "700": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMrclswcFHnJMMhg.ttf",
//       "800": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMtAlswcFHnJMMhg.ttf",
//       "900": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMvklswcFHnJMMhg.ttf",
//       "regular": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAiswcFHnJMMhg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/truculenta/v22/LhWfMVvBKusVIfNYGi1-WvRVyDdZeeiySNppcu32Mb2f06y6Oa21F6XHi0VYDX_PzOupMlAigwYPGg.ttf"
//   },
//   {
//     "family": "Blaka",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/blaka/v7/8vIG7w8722p_6kdr20D2FV5e.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/blaka/v7/8vIG7w8722p_6ndq0UQ.ttf"
//   },
//   {
//     "family": "Emblema One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/emblemaone/v21/nKKT-GQ0F5dSY8vzG0rOEIRBHl57G_f_.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/emblemaone/v21/nKKT-GQ0F5dSY8vzG0rOELRAFFo.ttf"
//   },
//   {
//     "family": "Bungee Outline",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v20",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bungeeoutline/v20/_6_mEDvmVP24UvU2MyiGDslL3Qg3YhJqPXxo.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bungeeoutline/v20/_6_mEDvmVP24UvU2MyiGDslL3Tg2aBY.ttf"
//   },
//   {
//     "family": "Noto Sans Batak",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "batak",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2024-02-29",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansbatak/v20/gok2H6TwAEdtF9N8-mdTCQvT-Zdgo4_PHuk74A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbatak/v20/gok2H6TwAEdtF9N8-mdTCQvT-ZdQooXL.ttf"
//   },
//   {
//     "family": "Flavors",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/flavors/v26/FBV2dDrhxqmveJTpbkzlNqkG9UY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/flavors/v26/FBV2dDrhxqmveJTpXk3vMg.ttf"
//   },
//   {
//     "family": "Revalia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/revalia/v22/WwkexPimBE2-4ZPEeVruNIgJSNM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/revalia/v22/WwkexPimBE2-4ZPESVvkMA.ttf"
//   },
//   {
//     "family": "Solitreo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/solitreo/v2/r05YGLlS5a9KYsyNO8upyDYtStiJ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/solitreo/v2/r05YGLlS5a9KYsyNO_uowjI.ttf"
//   },
//   {
//     "family": "Lacquer",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lacquer/v15/EYqzma1QwqpG4_BBB7-AXhttQ5I.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lacquer/v15/EYqzma1QwqpG4_BBN76KWg.ttf"
//   },
//   {
//     "family": "Bahianita",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bahianita/v21/yYLr0hTb3vuqqsBUgxWtxTvV2NJPcA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bahianita/v21/yYLr0hTb3vuqqsBUgxWdxDHR.ttf"
//   },
//   {
//     "family": "Lakki Reddy",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lakkireddy/v21/S6u5w49MUSzD9jlCPmvLZQfox9k97-xZ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lakkireddy/v21/S6u5w49MUSzD9jlCPmvLZTfpzd0.ttf"
//   },
//   {
//     "family": "Borel",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2023-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/borel/v3/6qLOKZsftAPisgshYyMnOjwE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/borel/v3/6qLOKZsftAPisjsgaSc.ttf"
//   },
//   {
//     "family": "Tiro Devanagari Marathi",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1xPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkotDrDJYM2lAZ.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1zPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkouDpBpIu30AZbUY.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirodevanagarimarathi/v5/fC1xPZBSZHrRhS3rd4M0MAPNJUHl4znXCxAkouDqBpI.ttf"
//   },
//   {
//     "family": "Qahiri",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin"
//     ],
//     "version": "v9",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/qahiri/v9/tsssAp1RZy0C_hGuU3Chrnmupw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/qahiri/v9/tsssAp1RZy0C_hGeUnql.ttf"
//   },
//   {
//     "family": "Tiro Devanagari Sanskrit",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoAzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59pEoEqgtOh0w.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoGzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59ZEIsuoNax06MM.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirodevanagarisanskrit/v5/MCoAzBbr09vVUgVBM8FWu_yZdZkhkg-I0nUlb59ZE4su.ttf"
//   },
//   {
//     "family": "IBM Plex Sans Devanagari",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXB3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HMUjwUcjwCEQq.ttf",
//       "200": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HnWnQe-b8AV0z0w.ttf",
//       "300": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_H-WrQe-b8AV0z0w.ttf",
//       "500": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HoWvQe-b8AV0z0w.ttf",
//       "600": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_HjWzQe-b8AV0z0w.ttf",
//       "700": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXA3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_H6W3Qe-b8AV0z0w.ttf",
//       "regular": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXH3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O__VUL0c83gCA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ibmplexsansdevanagari/v11/XRXH3JCMvG4IDoS9SubXB6W-UX5iehIMBFR2-O_PVEjw.ttf"
//   },
//   {
//     "family": "Sometype Mono",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-10-19",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/sometypemono/v1/70lGu745KGk_R3uxyq0WrROhAJiJsJ_eTWllpTA-GE9agQBbs7uG.ttf",
//       "600": "https://fonts.gstatic.com/s/sometypemono/v1/70lGu745KGk_R3uxyq0WrROhAJiJsJ_eTWllpTDSH09agQBbs7uG.ttf",
//       "700": "https://fonts.gstatic.com/s/sometypemono/v1/70lGu745KGk_R3uxyq0WrROhAJiJsJ_eTWllpTDrH09agQBbs7uG.ttf",
//       "regular": "https://fonts.gstatic.com/s/sometypemono/v1/70lGu745KGk_R3uxyq0WrROhAJiJsJ_eTWllpTAMGE9agQBbs7uG.ttf",
//       "italic": "https://fonts.gstatic.com/s/sometypemono/v1/70lEu745KGk_R3uxyq0WrROhKpG7T0e3JvPLoiXEW6cZiwR5tquGrxk.ttf",
//       "500italic": "https://fonts.gstatic.com/s/sometypemono/v1/70lEu745KGk_R3uxyq0WrROhKpG7T0e3JvPLoiXEW5UZiwR5tquGrxk.ttf",
//       "600italic": "https://fonts.gstatic.com/s/sometypemono/v1/70lEu745KGk_R3uxyq0WrROhKpG7T0e3JvPLoiXEW3keiwR5tquGrxk.ttf",
//       "700italic": "https://fonts.gstatic.com/s/sometypemono/v1/70lEu745KGk_R3uxyq0WrROhKpG7T0e3JvPLoiXEW0AeiwR5tquGrxk.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sometypemono/v1/70lGu745KGk_R3uxyq0WrROhAJiJsJ_eTWllpTAMGH9biwQ.ttf"
//   },
//   {
//     "family": "Erica One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ericaone/v27/WBLnrEXccV9VGrOKmGD1W0_MJMGxiQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ericaone/v27/WBLnrEXccV9VGrOKmGDFWkXI.ttf"
//   },
//   {
//     "family": "Chela One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chelaone/v21/6ae-4KC7Uqgdz_JZdPIy31vWNTMwoQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chelaone/v21/6ae-4KC7Uqgdz_JZdPIC3lHS.ttf"
//   },
//   {
//     "family": "Big Shoulders Inline Text",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0yqGN7Y6Jsc8c.ttf",
//       "200": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgY0y6GN7Y6Jsc8c.ttf",
//       "300": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgbqy6GN7Y6Jsc8c.ttf",
//       "500": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgaGy6GN7Y6Jsc8c.ttf",
//       "600": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgZqzKGN7Y6Jsc8c.ttf",
//       "700": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgZTzKGN7Y6Jsc8c.ttf",
//       "800": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgY0zKGN7Y6Jsc8c.ttf",
//       "900": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwgYdzKGN7Y6Jsc8c.ttf",
//       "regular": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0y6GN7Y6Jsc8c.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigshouldersinlinetext/v26/vm8XdQDmVECV5-vm5dJ-Tp-6WDeRjL4RV7dP8u-NMyHY74qpoNNcwga0y5GM54o.ttf"
//   },
//   {
//     "family": "Rubik Glitch",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikglitch/v2/qkBSXv8b_srFRYQVYrDKh9ZvmC7HONiSFQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikglitch/v2/qkBSXv8b_srFRYQVYrDKh9ZfmSTD.ttf"
//   },
//   {
//     "family": "Noto Sans Ethiopic",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "ethiopic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v47",
//     "lastModified": "2023-09-13",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T35OKqDjwmfeaY9u.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T37OK6DjwmfeaY9u.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T34QK6DjwmfeaY9u.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T358K6DjwmfeaY9u.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T36QLKDjwmfeaY9u.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T36pLKDjwmfeaY9u.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T37OLKDjwmfeaY9u.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T37nLKDjwmfeaY9u.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T35OK6DjwmfeaY9u.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansethiopic/v47/7cHPv50vjIepfJVOZZgcpQ5B9FBTH9KGNfhSTgtoow1KVnIvyBoMSzUMacb-T35OK5DiyGM.ttf"
//   },
//   {
//     "family": "Almendra Display",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/almendradisplay/v31/0FlPVOGWl1Sb4O3tETtADHRRlZhzXS_eTyer338.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/almendradisplay/v31/0FlPVOGWl1Sb4O3tETtADHRRlZhzbS7USw.ttf"
//   },
//   {
//     "family": "Foldit",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XpANmapUYLHkN80.ttf",
//       "200": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XhAMmapUYLHkN80.ttf",
//       "300": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8Xs4MmapUYLHkN80.ttf",
//       "500": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XqIMmapUYLHkN80.ttf",
//       "600": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8Xk4LmapUYLHkN80.ttf",
//       "700": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XncLmapUYLHkN80.ttf",
//       "800": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XhALmapUYLHkN80.ttf",
//       "900": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XjkLmapUYLHkN80.ttf",
//       "regular": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XpAMmapUYLHkN80.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/foldit/v5/aFTI7PF3Y3c9WdjNrRVE0Rk2b7j8XpAMqateZA.ttf"
//   },
//   {
//     "family": "Edu TAS Beginner",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v3",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/edutasbeginner/v3/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_HCemkrBWRhvk02.ttf",
//       "600": "https://fonts.gstatic.com/s/edutasbeginner/v3/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_EufWkrBWRhvk02.ttf",
//       "700": "https://fonts.gstatic.com/s/edutasbeginner/v3/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_EXfWkrBWRhvk02.ttf",
//       "regular": "https://fonts.gstatic.com/s/edutasbeginner/v3/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_HwemkrBWRhvk02.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/edutasbeginner/v3/ZXuwe04WubHfGVY-1TcNg7AFUmshg8jIUTzK3r34f_HwelkqD2A.ttf"
//   },
//   {
//     "family": "Caramel",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/caramel/v7/P5sCzZKBbMTf_ShyxCRuiZ-uydg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/caramel/v7/P5sCzZKBbMTf_Shy9CVkjQ.ttf"
//   },
//   {
//     "family": "Federant",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/federant/v29/2sDdZGNfip_eirT0_U0jRUG0AqUc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/federant/v29/2sDdZGNfip_eirT0_X0iT0U.ttf"
//   },
//   {
//     "family": "Bigelow Rules",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v29",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bigelowrules/v29/RrQWboly8iR_I3KWSzeRuN0zT4cCH8WAJVk.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigelowrules/v29/RrQWboly8iR_I3KWSzeRuN0zf4YIGw.ttf"
//   },
//   {
//     "family": "Lexend Tera",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-03-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zITdpz0fYxcrQ.ttf",
//       "200": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMZzMTdpz0fYxcrQ.ttf",
//       "300": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMuTMTdpz0fYxcrQ.ttf",
//       "500": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM1TMTdpz0fYxcrQ.ttf",
//       "600": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMOTQTdpz0fYxcrQ.ttf",
//       "700": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMADQTdpz0fYxcrQ.ttf",
//       "800": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMZzQTdpz0fYxcrQ.ttf",
//       "900": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiMTjQTdpz0fYxcrQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zMTdpz0fYxcrQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lexendtera/v27/RrQDbo98_jt_IXnBPwCWtYJLZ3P4hnaGKFiM5zMjd5bw.ttf"
//   },
//   {
//     "family": "Big Shoulders Stencil Display",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0nPKHznJucP9w.ttf",
//       "200": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_u0jPKHznJucP9w.ttf",
//       "300": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_ZUjPKHznJucP9w.ttf",
//       "500": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_CUjPKHznJucP9w.ttf",
//       "600": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_5U_PKHznJucP9w.ttf",
//       "700": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_3E_PKHznJucP9w.ttf",
//       "800": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_u0_PKHznJucP9w.ttf",
//       "900": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_kk_PKHznJucP9w.ttf",
//       "regular": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0jPKHznJucP9w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigshouldersstencildisplay/v28/6aeZ4LS6U6pR_bp5b_t2ugOhHWFcxSGP9ttD96KCb8xPytKb-oPRU-vkuLm_O0j_KXbj.ttf"
//   },
//   {
//     "family": "GFS Neohellenic",
//     "variants": [
//       "regular",
//       "italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "greek"
//     ],
//     "version": "v25",
//     "lastModified": "2022-09-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIUdiDOrfiq0b7R8O1Iw9WLcY5rkYdr644fWsRO9w.ttf",
//       "regular": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIRdiDOrfiq0b7R8O1Iw9WLcY5TLahP46UDUw.ttf",
//       "italic": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QITdiDOrfiq0b7R8O1Iw9WLcY5jL6JLwaATU91X.ttf",
//       "700italic": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIWdiDOrfiq0b7R8O1Iw9WLcY5jL5r37oQbeMFe985V.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gfsneohellenic/v25/8QIRdiDOrfiq0b7R8O1Iw9WLcY5jLKJL.ttf"
//   },
//   {
//     "family": "Peddana",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/peddana/v20/aFTU7PBhaX89UcKWhh2aBYyMcKw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/peddana/v20/aFTU7PBhaX89UcKWthyQAQ.ttf"
//   },
//   {
//     "family": "Agdasima",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/agdasima/v4/PN_0Rfyxp2f1fUCgAPCGgBzT1PzTz2Mi.ttf",
//       "regular": "https://fonts.gstatic.com/s/agdasima/v4/PN_zRfyxp2f1fUCgAMg6rzjb_-Da.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/agdasima/v4/PN_zRfyxp2f1fUCgAPg7pTw.ttf"
//   },
//   {
//     "family": "Mea Culpa",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/meaculpa/v6/AMOTz4GcuWbEIuza8jsZms0QW3mqyg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/meaculpa/v6/AMOTz4GcuWbEIuza8jspm8cU.ttf"
//   },
//   {
//     "family": "Stalinist One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v56",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/stalinistone/v56/MQpS-WezM9W4Dd7D3B7I-UT7eZ-UPyacPbo.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/stalinistone/v56/MQpS-WezM9W4Dd7D3B7I-UT7SZ6eOw.ttf"
//   },
//   {
//     "family": "Bagel Fat One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-06-07",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bagelfatone/v1/hYkPPucsQOr5dy02WmQr5Zkd0B5mvv0dSbM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bagelfatone/v1/hYkPPucsQOr5dy02WmQr5Zkd4B9sug.ttf"
//   },
//   {
//     "family": "Siemreap",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/siemreap/v28/Gg82N5oFbgLvHAfNl2YbnA8DLXpe.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/siemreap/v28/Gg82N5oFbgLvHAfNl1Yalgs.ttf"
//   },
//   {
//     "family": "Devonshire",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/devonshire/v27/46kqlbDwWirWr4gtBD2BX0Vq01lYAZM.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/devonshire/v27/46kqlbDwWirWr4gtBD2Bb0Rg1w.ttf"
//   },
//   {
//     "family": "Bokor",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v30",
//     "lastModified": "2022-04-21",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiR2WdInbcaxE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bokor/v30/m8JcjfpeeaqTiS2Xfo0.ttf"
//   },
//   {
//     "family": "Ysabeau SC",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-01-26",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw98U1qEZ4EOmsT5.ttf",
//       "200": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw_8UlqEZ4EOmsT5.ttf",
//       "300": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw8iUlqEZ4EOmsT5.ttf",
//       "500": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw9OUlqEZ4EOmsT5.ttf",
//       "600": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw-iVVqEZ4EOmsT5.ttf",
//       "700": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw-bVVqEZ4EOmsT5.ttf",
//       "800": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw_8VVqEZ4EOmsT5.ttf",
//       "900": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw_VVVqEZ4EOmsT5.ttf",
//       "regular": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw98UlqEZ4EOmsT5.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ysabeausc/v2/Noai6Uro3JCIKAbW46nMorJZyP7kKRflbw98UmqFbYU.ttf"
//   },
//   {
//     "family": "Lavishly Yours",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lavishlyyours/v5/jizDREVIvGwH5OjiZmX9r5z_WxUY0TY7ikbI.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lavishlyyours/v5/jizDREVIvGwH5OjiZmX9r5z_WyUZ2zI.ttf"
//   },
//   {
//     "family": "Butcherman",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/butcherman/v24/2EbiL-thF0loflXUBOdb1zWzq_5uT84.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/butcherman/v24/2EbiL-thF0loflXUBOdb5zS5rw.ttf"
//   },
//   {
//     "family": "Londrina Sketch",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/londrinasketch/v25/c4m41npxGMTnomOHtRU68eIJn8qfWWn5Pos6CA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/londrinasketch/v25/c4m41npxGMTnomOHtRU68eIJn8qvWGP9.ttf"
//   },
//   {
//     "family": "Neonderthaw",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/neonderthaw/v6/Iure6Yx5-oWVZI0r-17AeZZJprVA4XQ0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/neonderthaw/v6/Iure6Yx5-oWVZI0r-17AeaZIrLE.ttf"
//   },
//   {
//     "family": "Miss Fajardose",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/missfajardose/v22/E21-_dn5gvrawDdPFVl-N0Ajb8qvWPaJq4no.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/missfajardose/v22/E21-_dn5gvrawDdPFVl-N0Ajb_quUvI.ttf"
//   },
//   {
//     "family": "BioRhyme Expanded",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9LjbffxxcblSHSdTXrb_z.ttf",
//       "300": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9Ljbffxw4bVSHSdTXrb_z.ttf",
//       "700": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9LjbffxwoalSHSdTXrb_z.ttf",
//       "800": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dVIE1zZzytGswgU577CDY9Ljbffxw0aVSHSdTXrb_z.ttf",
//       "regular": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dQIE1zZzytGswgU577CDY9LjbffySURXCPYsje.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/biorhymeexpanded/v21/i7dQIE1zZzytGswgU577CDY9LjbffxSVT3Q.ttf"
//   },
//   {
//     "family": "Metal",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v30",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/metal/v30/lW-wwjUJIXTo7i3nnoQAUdN2.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/metal/v30/lW-wwjUJIXTo7h3mlIA.ttf"
//   },
//   {
//     "family": "Chenla",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer"
//     ],
//     "version": "v25",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chenla/v25/SZc43FDpIKu8WZ9eXxfonUPL6Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chenla/v25/SZc43FDpIKu8WZ9uXh3s.ttf"
//   },
//   {
//     "family": "Mr Bedfort",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mrbedfort/v22/MQpR-WCtNZSWAdTMwBicliq0XZe_Iy8.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mrbedfort/v22/MQpR-WCtNZSWAdTMwBicpiu-WQ.ttf"
//   },
//   {
//     "family": "Taprom",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v27",
//     "lastModified": "2022-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/taprom/v27/UcCn3F82JHycULbFQyk3-0kvHg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/taprom/v27/UcCn3F82JHycULb1QiMz.ttf"
//   },
//   {
//     "family": "Protest Strike",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-02-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/proteststrike/v2/0QI5MXdf4Y67Rn6vBog67ZjFlpzW0gZOs7BX.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/proteststrike/v2/0QI5MXdf4Y67Rn6vBog67ZjFlqzX2AI.ttf"
//   },
//   {
//     "family": "Passero One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/passeroone/v26/JTUTjIko8DOq5FeaeEAjgE5B5Arr-s50.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/passeroone/v26/JTUTjIko8DOq5FeaeEAjgH5A7g4.ttf"
//   },
//   {
//     "family": "Diplomata",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v31",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/diplomata/v31/Cn-0JtiMXwhNwp-wKxyfYGxYrdM9Sg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/diplomata/v31/Cn-0JtiMXwhNwp-wKxyvYWZc.ttf"
//   },
//   {
//     "family": "Noto Serif Tamil",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v28",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN6R8Pz3v8Etew.ttf",
//       "200": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatNN-R8Pz3v8Etew.ttf",
//       "300": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecat6t-R8Pz3v8Etew.ttf",
//       "500": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatht-R8Pz3v8Etew.ttf",
//       "600": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatatiR8Pz3v8Etew.ttf",
//       "700": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatU9iR8Pz3v8Etew.ttf",
//       "800": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatNNiR8Pz3v8Etew.ttf",
//       "900": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecatHdiR8Pz3v8Etew.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN-R8Pz3v8Etew.ttf",
//       "100italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJx5svbzncQ9e3wx.ttf",
//       "200italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJz5s_bzncQ9e3wx.ttf",
//       "300italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJwns_bzncQ9e3wx.ttf",
//       "italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJx5s_bzncQ9e3wx.ttf",
//       "500italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJxLs_bzncQ9e3wx.ttf",
//       "600italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJyntPbzncQ9e3wx.ttf",
//       "700italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJyetPbzncQ9e3wx.ttf",
//       "800italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJz5tPbzncQ9e3wx.ttf",
//       "900italic": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjldHr-klIgTfc40komjQ5OObazSJaI_D5kV8k_WLwFBmWrypghjeOa18G4fJzQtPbzncQ9e3wx.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriftamil/v28/LYjndHr-klIgTfc40komjQ5OObazYp-6H94dBF-RX6nNRJfi-Gf55IgAecattN-h8fbz.ttf"
//   },
//   {
//     "family": "Gidugu",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v25",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gidugu/v25/L0x8DFMkk1Uf6w3RvPCmRSlUig.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gidugu/v25/L0x8DFMkk1Uf6w3hvfqi.ttf"
//   },
//   {
//     "family": "Alumni Sans Collegiate One",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alumnisanscollegiateone/v5/MQpB-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhjdayDiPw2ta.ttf",
//       "italic": "https://fonts.gstatic.com/s/alumnisanscollegiateone/v5/MQpD-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhgdYwjytxntaDFU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alumnisanscollegiateone/v5/MQpB-XChK8G5CtmK_AuGxQrdNvPSXkn0RM-XqjWWhgdbwjw.ttf"
//   },
//   {
//     "family": "Jim Nightshade",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jimnightshade/v20/PlIkFlu9Pb08Q8HLM1PxmB0g-OS4V3qKaMxD.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jimnightshade/v20/PlIkFlu9Pb08Q8HLM1PxmB0g-NS5XX4.ttf"
//   },
//   {
//     "family": "Trochut",
//     "variants": [
//       "regular",
//       "italic",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v22",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/trochut/v22/CHymV-fDDlP9bDIw3sinWVokMnIllmA.ttf",
//       "regular": "https://fonts.gstatic.com/s/trochut/v22/CHyjV-fDDlP9bDIw5nSIfVIPLns.ttf",
//       "italic": "https://fonts.gstatic.com/s/trochut/v22/CHyhV-fDDlP9bDIw1naCeXAKPns8jw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/trochut/v22/CHyjV-fDDlP9bDIw1nWCeQ.ttf"
//   },
//   {
//     "family": "Lumanosimo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lumanosimo/v4/K2F0fZBYg_JDSEZHEfO8AoqKAyLzfWo.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lumanosimo/v4/K2F0fZBYg_JDSEZHEfO8MouABw.ttf"
//   },
//   {
//     "family": "Noto Serif Sinhala",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sinhala"
//     ],
//     "version": "v26",
//     "lastModified": "2023-05-02",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxRlMsxaLRn3W-.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pExR1MsxaLRn3W-.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pHvR1MsxaLRn3W-.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGDR1MsxaLRn3W-.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pFvQFMsxaLRn3W-.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pFWQFMsxaLRn3W-.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pExQFMsxaLRn3W-.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pEYQFMsxaLRn3W-.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxR1MsxaLRn3W-.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifsinhala/v26/DtVEJwinQqclnZE2CnsPug9lgGC3y2F2nehQ7Eg4EdBKWxPiDxMivFLgRXs_-pGxR2Mtz6Y.ttf"
//   },
//   {
//     "family": "Gasoek One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v3",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gasoekone/v3/EJRTQgQ_UMUKvDgnlX80zrq_cyb-vco.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gasoekone/v3/EJRTQgQ_UMUKvDgnlX80_ru1dw.ttf"
//   },
//   {
//     "family": "Edu NSW ACT Foundation",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v2",
//     "lastModified": "2023-01-06",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki9fovGLeC-sfguJ.ttf",
//       "600": "https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki-zpfGLeC-sfguJ.ttf",
//       "700": "https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki-KpfGLeC-sfguJ.ttf",
//       "regular": "https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki9tovGLeC-sfguJ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/edunswactfoundation/v2/raxRHjqJtsNBFUi8WO0vUBgc9D-2lV_oQdCAYlt_QTQ0vUxJki9tosGKcis.ttf"
//   },
//   {
//     "family": "Rubik Iso",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikiso/v2/x3dickHUfr-S4VAI4sABfPACvy_1BA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikiso/v2/x3dickHUfr-S4VAI4sAxffoG.ttf"
//   },
//   {
//     "family": "Ojuju",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2024-02-29",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_ypk552FRLYeruQ.ttf",
//       "300": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_FJk552FRLYeruQ.ttf",
//       "500": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_eJk552FRLYeruQ.ttf",
//       "600": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_lJ4552FRLYeruQ.ttf",
//       "700": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_rZ4552FRLYeruQ.ttf",
//       "800": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_yp4552FRLYeruQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_Spk552FRLYeruQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ojuju/v1/7r3bqXF7v9ApbrMih3jYQBVm9-n_SpkJ5mtV.ttf"
//   },
//   {
//     "family": "Babylonica",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/babylonica/v5/5aUw9_i2qxWVCAE2aHjTqDJ0-VVMoEw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/babylonica/v5/5aUw9_i2qxWVCAE2aHjTmDN-_Q.ttf"
//   },
//   {
//     "family": "Libre Barcode EAN13 Text",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/librebarcodeean13text/v21/wlpigxXFDU1_oCu9nfZytgIqSG0XRcJm_OQiB96PAGEki52WfA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/librebarcodeean13text/v21/wlpigxXFDU1_oCu9nfZytgIqSG0XRcJm_OQiB96_AWsg.ttf"
//   },
//   {
//     "family": "Uchen",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "tibetan"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/uchen/v9/nKKZ-GokGZ1baIaSEQGodLxA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/uchen/v9/nKKZ-GokGZ1baLaTGwU.ttf"
//   },
//   {
//     "family": "Phudu",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkK62zUSwWuz38Tgg.ttf",
//       "500": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKh2zUSwWuz38Tgg.ttf",
//       "600": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKa2vUSwWuz38Tgg.ttf",
//       "700": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKUmvUSwWuz38Tgg.ttf",
//       "800": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKNWvUSwWuz38Tgg.ttf",
//       "900": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKHGvUSwWuz38Tgg.ttf",
//       "regular": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKtWzUSwWuz38Tgg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/phudu/v4/0FlJVPSHk0ya-7OUeO_U-Lwm7PkKtWzkSg-q.ttf"
//   },
//   {
//     "family": "Ballet",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ballet/v27/QGYyz_MYZA-HM4NjuGOVnUEXme1I4Xi3C4G-EiAou6Y.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ballet/v27/QGYyz_MYZA-HM4NjuGOVnUEXme1I4Xi3O4C0Fg.ttf"
//   },
//   {
//     "family": "Tac One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-03-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tacone/v2/ahcZv8Cj3zw7qDr8fO4hU-FwnU0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tacone/v2/ahcZv8Cj3zw7qDr8TO8rVw.ttf"
//   },
//   {
//     "family": "Cairo Play",
//     "variants": [
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-04-27",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1EnYq9yXa8GvzaA.ttf",
//       "300": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1zHYq9yXa8GvzaA.ttf",
//       "500": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1oHYq9yXa8GvzaA.ttf",
//       "600": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1THEq9yXa8GvzaA.ttf",
//       "700": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1dXEq9yXa8GvzaA.ttf",
//       "800": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1EnEq9yXa8GvzaA.ttf",
//       "900": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1O3Eq9yXa8GvzaA.ttf",
//       "regular": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1knYq9yXa8GvzaA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cairoplay/v7/wXKEE3QSpo4vpRz_mz6FP-8iaauCLt_Hjopv3miu5IvcJo49mOo1knYa9i_e.ttf"
//   },
//   {
//     "family": "Rubik Wet Paint",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikwetpaint/v2/HTx0L20uMDGHgdULcpTF3Oe4d_-F-zz313DuvQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikwetpaint/v2/HTx0L20uMDGHgdULcpTF3Oe4d_-1-jbz.ttf"
//   },
//   {
//     "family": "Aubrey",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v28",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/aubrey/v28/q5uGsou7NPBw-p7vugNsCxVEgA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/aubrey/v28/q5uGsou7NPBw-p7fuwlo.ttf"
//   },
//   {
//     "family": "Tiro Telugu",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "telugu"
//     ],
//     "version": "v7",
//     "lastModified": "2023-02-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirotelugu/v7/aFTQ7PxlZWk2EPiSymjXdKSNQqn0X0BO.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirotelugu/v7/aFTS7PxlZWk2EPiSymjXdJSPSK3WWlBOoas.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirotelugu/v7/aFTQ7PxlZWk2EPiSymjXdJSMSK0.ttf"
//   },
//   {
//     "family": "Inclusive Sans",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/inclusivesans/v1/0nkxC9biPuwflXcJ46P4PGWE0971owa2LB4i.ttf",
//       "italic": "https://fonts.gstatic.com/s/inclusivesans/v1/0nkzC9biPuwflXcJ46P4PGWE0-73qQKUKQ4iT6o.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inclusivesans/v1/0nkxC9biPuwflXcJ46P4PGWE0-70qQI.ttf"
//   },
//   {
//     "family": "Gideon Roman",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gideonroman/v11/e3tmeuGrVOys8sxzZgWlmXoge0PWovdU4w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gideonroman/v11/e3tmeuGrVOys8sxzZgWlmXoQeknS.ttf"
//   },
//   {
//     "family": "Anek Odia",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "oriya"
//     ],
//     "version": "v6",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmZf63mXZAtm_es.ttf",
//       "200": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnkZfq3mXZAtm_es.ttf",
//       "300": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnnHfq3mXZAtm_es.ttf",
//       "500": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmrfq3mXZAtm_es.ttf",
//       "600": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnlHea3mXZAtm_es.ttf",
//       "700": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnl-ea3mXZAtm_es.ttf",
//       "800": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnkZea3mXZAtm_es.ttf",
//       "regular": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmZfq3mXZAtm_es.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/anekodia/v6/TK3PWkoJARApz5UCd345tuevwwQX0CwsoYkAWgWYevAauivBUnmZfp3nV5Q.ttf"
//   },
//   {
//     "family": "Love Light",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lovelight/v6/t5tlIR0TNJyZWimpNAXDjKbCyTHuspo.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lovelight/v6/t5tlIR0TNJyZWimpNAXDvKfIzQ.ttf"
//   },
//   {
//     "family": "Combo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/combo/v21/BXRlvF3Jh_fIhg0iBu9y8Hf0.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/combo/v21/BXRlvF3Jh_fIhj0jDOs.ttf"
//   },
//   {
//     "family": "Joan",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/joan/v9/ZXupe1oZsqWRbRdH8X1p_Ng.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/joan/v9/ZXupe1oZsqWRXRZN9Q.ttf"
//   },
//   {
//     "family": "Inspiration",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/inspiration/v6/x3dkckPPZa6L4wIg5cZOEvoGnSrlBBsy.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/inspiration/v6/x3dkckPPZa6L4wIg5cZOEsoHly4.ttf"
//   },
//   {
//     "family": "Yarndings 12 Charted",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "math",
//       "symbols"
//     ],
//     "version": "v2",
//     "lastModified": "2024-03-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yarndings12charted/v2/eLGDP_DlKhO-DUfeqM4I_vDdJgmIh7hAvvbJ0t-dHaJH.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yarndings12charted/v2/eLGDP_DlKhO-DUfeqM4I_vDdJgmIh7hAvsbI2Ns.ttf"
//   },
//   {
//     "family": "Bacasime Antique",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bacasimeantique/v1/tDbX2pGXkFYEykldjZSrmI6T_XWZOwStSUrV_BE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bacasimeantique/v1/tDbX2pGXkFYEykldjZSrmI6T_XWZCwWnTQ.ttf"
//   },
//   {
//     "family": "Vibes",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin"
//     ],
//     "version": "v14",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/vibes/v14/QdVYSTsmIB6tmbd3HpbsuBlh.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/vibes/v14/QdVYSTsmIB6tmYd2FJI.ttf"
//   },
//   {
//     "family": "Noto Serif Khmer",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "khmer",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-10-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B4wXEZK9Xo4xg.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNaB8wXEZK9Xo4xg.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNth8wXEZK9Xo4xg.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN2h8wXEZK9Xo4xg.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNNhgwXEZK9Xo4xg.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNDxgwXEZK9Xo4xg.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNaBgwXEZK9Xo4xg.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdNQRgwXEZK9Xo4xg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B8wXEZK9Xo4xg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifkhmer/v25/-F6UfidqLzI2JPCkXAO2hmogq0146FxtbwKEr951z5s6lI40sDRH_AVhUKdN6B8AXUxO.ttf"
//   },
//   {
//     "family": "Yarndings 20 Charted",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "math",
//       "symbols"
//     ],
//     "version": "v2",
//     "lastModified": "2024-03-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yarndings20charted/v2/QldRNSdbpg0G8vh0W2qxe0l-hcUPtY2VaLQm4UTqz5V9.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yarndings20charted/v2/QldRNSdbpg0G8vh0W2qxe0l-hcUPtY2VaIQn60A.ttf"
//   },
//   {
//     "family": "Send Flowers",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sendflowers/v5/If2PXTjtZS-0Xqy13uCQSULvxwjjouU1iw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sendflowers/v5/If2PXTjtZS-0Xqy13uCQSULfxgLn.ttf"
//   },
//   {
//     "family": "Island Moments",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/islandmoments/v6/NaPBcZfVGvBdxIt7Ar0qzkXJF-TGIohbZ6SY.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/islandmoments/v6/NaPBcZfVGvBdxIt7Ar0qzkXJF9THKIw.ttf"
//   },
//   {
//     "family": "Ruge Boogie",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v28",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rugeboogie/v28/JIA3UVFwbHRF_GIWSMhKNROiPzUveSxy.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rugeboogie/v28/JIA3UVFwbHRF_GIWSMhKNSOjNTE.ttf"
//   },
//   {
//     "family": "Hedvig Letters Serif",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v2",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hedviglettersserif/v2/OD5puN2I2mekHmyoU1Kj2AXOd5_7v7gIDlX8quj7viQ_N1HixEAZf7fcvSGpZg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hedviglettersserif/v2/OD5puN2I2mekHmyoU1Kj2AXOd5_7v7gIDlX8quj7viQ_N1HixEApfr3Y.ttf"
//   },
//   {
//     "family": "Bruno Ace",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/brunoace/v5/WwkcxPa2E06x4trkOj_kMKoMWNMg3Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/brunoace/v5/WwkcxPa2E06x4trkOj_UMaAI.ttf"
//   },
//   {
//     "family": "Castoro Titling",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/castorotitling/v8/buEupouwccj03leTfjUAhEZWlrNqYgckeo9RMw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/castorotitling/v8/buEupouwccj03leTfjUAhEZWlrNaYw0g.ttf"
//   },
//   {
//     "family": "Luxurious Roman",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/luxuriousroman/v8/buEupou_ZcP1w0yTKxJJokVSmbpqYgckeo9RMw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/luxuriousroman/v8/buEupou_ZcP1w0yTKxJJokVSmbpaYw0g.ttf"
//   },
//   {
//     "family": "Updock",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/updock/v5/nuF4D_3dVZ70UI9SjLK3602XBw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/updock/v5/nuF4D_3dVZ70UI9ijbiz.ttf"
//   },
//   {
//     "family": "Noto Rashi Hebrew",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-03-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-DkRyq6Nf2pfA.ttf",
//       "200": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZh-HkRyq6Nf2pfA.ttf",
//       "300": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZWeHkRyq6Nf2pfA.ttf",
//       "500": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZNeHkRyq6Nf2pfA.ttf",
//       "600": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZ2ebkRyq6Nf2pfA.ttf",
//       "700": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZ4ObkRyq6Nf2pfA.ttf",
//       "800": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZh-bkRyq6Nf2pfA.ttf",
//       "900": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZrubkRyq6Nf2pfA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-HkRyq6Nf2pfA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notorashihebrew/v25/EJR_Qh82XsIK-QFmqXk4zvLwFVya0vFL-HlKM5e6C6HZB-HURiC-.ttf"
//   },
//   {
//     "family": "Sofadi One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sofadione/v21/JIA2UVBxdnVBuElZaMFGcDOIETkmYDU.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sofadione/v21/JIA2UVBxdnVBuElZaMFGQDKCFQ.ttf"
//   },
//   {
//     "family": "Noto Serif Vithkuqi",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vithkuqi"
//     ],
//     "version": "v1",
//     "lastModified": "2023-07-24",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notoserifvithkuqi/v1/YA94r1OY7FjTf5szakutkndpw9HH-4a4z9pklvg1IQSNcRW-dW2Cqy9A4teH.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifvithkuqi/v1/YA94r1OY7FjTf5szakutkndpw9HH-4a4z9pklvg1IQSNcRVScm2Cqy9A4teH.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifvithkuqi/v1/YA94r1OY7FjTf5szakutkndpw9HH-4a4z9pklvg1IQSNcRVrcm2Cqy9A4teH.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifvithkuqi/v1/YA94r1OY7FjTf5szakutkndpw9HH-4a4z9pklvg1IQSNcRWMdW2Cqy9A4teH.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifvithkuqi/v1/YA94r1OY7FjTf5szakutkndpw9HH-4a4z9pklvg1IQSNcRWMdV2DoSs.ttf"
//   },
//   {
//     "family": "Mynerve",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "greek",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mynerve/v6/P5sCzZKPdNjb4jt7xCRuiZ-uydg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mynerve/v6/P5sCzZKPdNjb4jt79CVkjQ.ttf"
//   },
//   {
//     "family": "Noto Sans Sharada",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sharada"
//     ],
//     "version": "v16",
//     "lastModified": "2022-11-18",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssharada/v16/gok0H7rwAEdtF9N8-mdTGALG6p0kwoXLPOwr4H8a.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssharada/v16/gok0H7rwAEdtF9N8-mdTGALG6p0kwrXKNug.ttf"
//   },
//   {
//     "family": "Cherry Bomb One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cherrybombone/v8/y83DW4od1h6KlV3c6JJhRhGOdhrKDNpF41fr-w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cherrybombone/v8/y83DW4od1h6KlV3c6JJhRhGOdhr6DdBB.ttf"
//   },
//   {
//     "family": "Redacted Script",
//     "variants": [
//       "300",
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/redactedscript/v10/ypvEbXGRglhokR7dcC3d1-R6zmxqHUzVmbI397ldkg.ttf",
//       "700": "https://fonts.gstatic.com/s/redactedscript/v10/ypvEbXGRglhokR7dcC3d1-R6zmxqDUvVmbI397ldkg.ttf",
//       "regular": "https://fonts.gstatic.com/s/redactedscript/v10/ypvBbXGRglhokR7dcC3d1-R6zmxSsWTxkZkr_g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/redactedscript/v10/ypvBbXGRglhokR7dcC3d1-R6zmxisG71.ttf"
//   },
//   {
//     "family": "Comme",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1z5cBr644fWsRO9w.ttf",
//       "200": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1zZcFr644fWsRO9w.ttf",
//       "300": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1zu8Fr644fWsRO9w.ttf",
//       "500": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1z18Fr644fWsRO9w.ttf",
//       "600": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1zO8Zr644fWsRO9w.ttf",
//       "700": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1zAsZr644fWsRO9w.ttf",
//       "800": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1zZcZr644fWsRO9w.ttf",
//       "900": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1zTMZr644fWsRO9w.ttf",
//       "regular": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1z5cFr644fWsRO9w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/comme/v2/8QIUdirKhMbn-uG1kHz0MgviDe1z5cFb6oQb.ttf"
//   },
//   {
//     "family": "My Soul",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mysoul/v5/3XFqErcuy945_u6KF_Ulk2nnXf0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mysoul/v5/3XFqErcuy945_u6KJ_Qvlw.ttf"
//   },
//   {
//     "family": "Flow Block",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/flowblock/v11/wlp0gwfPCEB65UmTk-d6-WZlbCBXE_I.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/flowblock/v11/wlp0gwfPCEB65UmTk-d6yWdvaA.ttf"
//   },
//   {
//     "family": "Mooli",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mooli/v1/-F6_fjJpLyk1bYPBBG7YpzlJ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mooli/v1/-F6_fjJpLyk1bbPADmo.ttf"
//   },
//   {
//     "family": "Fruktur",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fruktur/v27/SZc53FHsOru5QYsMfz3GkUrS8DI.ttf",
//       "italic": "https://fonts.gstatic.com/s/fruktur/v27/SZc73FHsOru5QYsMTz_MlWjX4DJXgQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fruktur/v27/SZc53FHsOru5QYsMTzzMlQ.ttf"
//   },
//   {
//     "family": "Langar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/langar/v27/kJEyBukW7AIlgjGVrTVZ99sqrQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/langar/v27/kJEyBukW7AIlgjGlrD9d.ttf"
//   },
//   {
//     "family": "Suravaram",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "telugu"
//     ],
//     "version": "v21",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/suravaram/v21/_gP61R_usiY7SCym4xIAi261Qv9roQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/suravaram/v21/_gP61R_usiY7SCym4xIwimSx.ttf"
//   },
//   {
//     "family": "Tourney",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900",
//       "100italic",
//       "200italic",
//       "300italic",
//       "italic",
//       "500italic",
//       "600italic",
//       "700italic",
//       "800italic",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQByZTp1I1LcGA.ttf",
//       "200": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GuQFyZTp1I1LcGA.ttf",
//       "300": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GZwFyZTp1I1LcGA.ttf",
//       "500": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GCwFyZTp1I1LcGA.ttf",
//       "600": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7G5wZyZTp1I1LcGA.ttf",
//       "700": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7G3gZyZTp1I1LcGA.ttf",
//       "800": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GuQZyZTp1I1LcGA.ttf",
//       "900": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GkAZyZTp1I1LcGA.ttf",
//       "regular": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQFyZTp1I1LcGA.ttf",
//       "100italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UKaJzBxAVfMGOPb.ttf",
//       "200italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UIaJjBxAVfMGOPb.ttf",
//       "300italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8ULEJjBxAVfMGOPb.ttf",
//       "italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UKaJjBxAVfMGOPb.ttf",
//       "500italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UKoJjBxAVfMGOPb.ttf",
//       "600italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UJEITBxAVfMGOPb.ttf",
//       "700italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UJ9ITBxAVfMGOPb.ttf",
//       "800italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UIaITBxAVfMGOPb.ttf",
//       "900italic": "https://fonts.gstatic.com/s/tourney/v13/AlZc_ztDtYzv1tzq_Q47flUUvI2wpXz29ilymEMLMNc3XHnT8UIzITBxAVfMGOPb.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tourney/v13/AlZa_ztDtYzv1tzq1wcJnbVt7xseomk-tNs7qrzTWbyt8n7GOQFCZDBx.ttf"
//   },
//   {
//     "family": "Splash",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/splash/v6/KtksAL2RZoDkbU6hpPPGNdS6wg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/splash/v6/KtksAL2RZoDkbU6RpfnC.ttf"
//   },
//   {
//     "family": "Yuji Boku",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yujiboku/v5/P5sAzZybeNzXsA9xj1Fkjb2r2dgvJA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yujiboku/v5/P5sAzZybeNzXsA9xj1FUjLev.ttf"
//   },
//   {
//     "family": "Aref Ruqaa Ink",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/arefruqaaink/v10/1q2cY5WOGUFlt84GTOkP6Kdx71xde6WhqWBCyxWn.ttf",
//       "regular": "https://fonts.gstatic.com/s/arefruqaaink/v10/1q2fY5WOGUFlt84GTOkP6Kdx72ThVIGpgnxL.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/arefruqaaink/v10/1q2fY5WOGUFlt84GTOkP6Kdx71TgXoU.ttf"
//   },
//   {
//     "family": "Explora",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cherokee",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/explora/v9/tsstApxFfjUH4wrvc1qPonC3vqc.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/explora/v9/tsstApxFfjUH4wrvQ1uFpg.ttf"
//   },
//   {
//     "family": "Sevillana",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sevillana/v23/KFOlCnWFscmDt1Bfiy1vAx05IsDqlA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sevillana/v23/KFOlCnWFscmDt1Bfiy1fAhc9.ttf"
//   },
//   {
//     "family": "Noto Sans Tai Viet",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tai-viet"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstaiviet/v19/8QIUdj3HhN_lv4jf9vsE-9GMOLsaSPZr644fWsRO9w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstaiviet/v19/8QIUdj3HhN_lv4jf9vsE-9GMOLsaSPZb6oQb.ttf"
//   },
//   {
//     "family": "Reem Kufi Ink",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-05-31",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/reemkufiink/v9/oPWJ_kJmmu8hCvB9iFumxZSnRj5dQnSX1ko.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/reemkufiink/v9/oPWJ_kJmmu8hCvB9iFumxZSndj9XRg.ttf"
//   },
//   {
//     "family": "Noto Music",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "music"
//     ],
//     "version": "v20",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notomusic/v20/pe0rMIiSN5pO63htf1sxIteQB9Zra1U.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notomusic/v20/pe0rMIiSN5pO63htf1sxEtaaAw.ttf"
//   },
//   {
//     "family": "Bonbon",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v30",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/bonbon/v30/0FlVVPeVlFec4ee_cDEAbQY5-A.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bonbon/v30/0FlVVPeVlFec4eePcTsE.ttf"
//   },
//   {
//     "family": "Noto Serif Gujarati",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "gujarati",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v27",
//     "lastModified": "2023-11-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycYzuM1Kf-OJu.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuaycIzuM1Kf-OJu.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuZscIzuM1Kf-OJu.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYAcIzuM1Kf-OJu.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2Hubsd4zuM1Kf-OJu.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HubVd4zuM1Kf-OJu.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2Huayd4zuM1Kf-OJu.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2Huabd4zuM1Kf-OJu.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycIzuM1Kf-OJu.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifgujarati/v27/hESa6WBlOixO-3OJ1FTmTsmqlBRUJBVkcgNLpdsspzP2HuYycLzvOVY.ttf"
//   },
//   {
//     "family": "Rubik Beastly",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikbeastly/v10/0QImMXRd5oOmSC2ZQ7o9653X07z8_ApHqqk.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikbeastly/v10/0QImMXRd5oOmSC2ZQ7o9653X4732-A.ttf"
//   },
//   {
//     "family": "Hedvig Letters Sans",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v2",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hedvigletterssans/v2/CHy_V_PfGVjobSBkihHWDT98RVp37w8jQJ1N3Twgi1w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hedvigletterssans/v2/CHy_V_PfGVjobSBkihHWDT98RVp37w8jcJxH2Q.ttf"
//   },
//   {
//     "family": "Fleur De Leah",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/fleurdeleah/v9/AYCNpXX7ftYZWLhv9UmPJTMC5vat4I_Gdq0.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/fleurdeleah/v9/AYCNpXX7ftYZWLhv9UmPJTMC1ven5A.ttf"
//   },
//   {
//     "family": "Yuji Mai",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2022-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yujimai/v5/ZgNQjPxdJ7DEHrS0gC38hmHmNpCO.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yujimai/v5/ZgNQjPxdJ7DEHrS0gB39jGU.ttf"
//   },
//   {
//     "family": "Purple Purse",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/purplepurse/v23/qWctB66gv53iAp-Vfs4My6qyeBb_ujA4ug.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/purplepurse/v23/qWctB66gv53iAp-Vfs4My6qCeRz7.ttf"
//   },
//   {
//     "family": "Honk",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2024-01-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/honk/v1/m8J7jftUea-XwTaemClumrBQbmvynOmXBji9zFhHRr8WFgVLo7tNepQKvg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/honk/v1/m8J7jftUea-XwTaemClumrBQbmvynOmXBji9zFhHRr8WFgV7orFJ.ttf"
//   },
//   {
//     "family": "Gajraj One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/gajrajone/v5/1cX2aUDCDpXsuWVb1jIjr1GqhcitzeM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/gajrajone/v5/1cX2aUDCDpXsuWVb1jIjn1CggQ.ttf"
//   },
//   {
//     "family": "Nuosu SIL",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "yi"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nuosusil/v10/8vIK7wM3wmRn_kc4uAjeFGxbO_zo-w.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nuosusil/v10/8vIK7wM3wmRn_kc4uAjuFWZf.ttf"
//   },
//   {
//     "family": "Handjet",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "arabic",
//       "armenian",
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHTmbUkUsEHFAH2A.ttf",
//       "200": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHbmaUkUsEHFAH2A.ttf",
//       "300": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHWeaUkUsEHFAH2A.ttf",
//       "500": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHQuaUkUsEHFAH2A.ttf",
//       "600": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHeedUkUsEHFAH2A.ttf",
//       "700": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHd6dUkUsEHFAH2A.ttf",
//       "800": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHbmdUkUsEHFAH2A.ttf",
//       "900": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHZCdUkUsEHFAH2A.ttf",
//       "regular": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHTmaUkUsEHFAH2A.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/handjet/v19/oY1n8eXHq7n1OnbQrOY_2FrEwYEMLlcdP1mCtZaLaTutCwcIhGZ0lGU0akFcO3XFHTmaYkQmFA.ttf"
//   },
//   {
//     "family": "Edu QLD Beginner",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v3",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/eduqldbeginner/v3/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE423oebi6vyVWCN.ttf",
//       "600": "https://fonts.gstatic.com/s/eduqldbeginner/v3/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE7a2Yebi6vyVWCN.ttf",
//       "700": "https://fonts.gstatic.com/s/eduqldbeginner/v3/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE7j2Yebi6vyVWCN.ttf",
//       "regular": "https://fonts.gstatic.com/s/eduqldbeginner/v3/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE4E3oebi6vyVWCN.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/eduqldbeginner/v3/AMOHz5iUuHLEMNXyohhc_Y56PR3A8dNLF_w3Ka4HKE4E3reaga8.ttf"
//   },
//   {
//     "family": "Noto Sans Osmanya",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "osmanya"
//     ],
//     "version": "v18",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansosmanya/v18/8vIS7xs32H97qzQKnzfeWzUyUpOJmz6kR47NCV5Z.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansosmanya/v18/8vIS7xs32H97qzQKnzfeWzUyUpOJmw6lTYo.ttf"
//   },
//   {
//     "family": "Snowburst One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/snowburstone/v20/MQpS-WezKdujBsXY3B7I-UT7eZ-UPyacPbo.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/snowburstone/v20/MQpS-WezKdujBsXY3B7I-UT7SZ6eOw.ttf"
//   },
//   {
//     "family": "Moulpali",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khmer",
//       "latin"
//     ],
//     "version": "v30",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/moulpali/v30/H4ckBXKMl9HagUWymyY6wr-wg763.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/moulpali/v30/H4ckBXKMl9HagUWymxY7yLs.ttf"
//   },
//   {
//     "family": "Edu VIC WA NT Beginner",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v4",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/eduvicwantbeginner/v4/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-OllPmFXwnpkeGR.ttf",
//       "600": "https://fonts.gstatic.com/s/eduvicwantbeginner/v4/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-NJk_mFXwnpkeGR.ttf",
//       "700": "https://fonts.gstatic.com/s/eduvicwantbeginner/v4/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-Nwk_mFXwnpkeGR.ttf",
//       "regular": "https://fonts.gstatic.com/s/eduvicwantbeginner/v4/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-OXlPmFXwnpkeGR.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/eduvicwantbeginner/v4/jiz2RF1BuW9OwcnNPxLl4KfZCHd9nFtd5Tu7stCpElYpvPfZZ-OXlMmEVQ0.ttf"
//   },
//   {
//     "family": "Tsukimi Rounded",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/tsukimirounded/v10/sJoZ3LJNksWZO0LvnZwkF3HtoB7VkVsqN7MT3T9X8g.ttf",
//       "500": "https://fonts.gstatic.com/s/tsukimirounded/v10/sJoZ3LJNksWZO0LvnZwkF3HtoB7VyVoqN7MT3T9X8g.ttf",
//       "600": "https://fonts.gstatic.com/s/tsukimirounded/v10/sJoZ3LJNksWZO0LvnZwkF3HtoB7V5V0qN7MT3T9X8g.ttf",
//       "700": "https://fonts.gstatic.com/s/tsukimirounded/v10/sJoZ3LJNksWZO0LvnZwkF3HtoB7VgVwqN7MT3T9X8g.ttf",
//       "regular": "https://fonts.gstatic.com/s/tsukimirounded/v10/sJoc3LJNksWZO0LvnZwkF3HtoB7tPXMOP5gP1A.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tsukimirounded/v10/sJoc3LJNksWZO0LvnZwkF3HtoB7dPHkK.ttf"
//   },
//   {
//     "family": "Noto Sans Javanese",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "javanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansjavanese/v23/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxKvkFFliZYWj4O8.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansjavanese/v23/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxEfjFFliZYWj4O8.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansjavanese/v23/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxH7jFFliZYWj4O8.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansjavanese/v23/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxJnkFFliZYWj4O8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansjavanese/v23/2V01KJkDAIA6Hp4zoSScDjV0Y-eoHAHT-Z3MngEefiidxJnkJFhoYQ.ttf"
//   },
//   {
//     "family": "Nabla",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "math",
//       "vietnamese"
//     ],
//     "version": "v10",
//     "lastModified": "2023-06-07",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/nabla/v10/j8_D6-LI0Lvpe7Makz5UhJt9C3uqg_X_75gyGS4jAxsNIjrRNRBUFFR_198.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nabla/v10/j8_D6-LI0Lvpe7Makz5UhJt9C3uqg_X_75gyGS4jAxsNIjrRBRFeEA.ttf"
//   },
//   {
//     "family": "Rubik Vinyl",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikvinyl/v1/iJWABXKIfDnIV4mQ5BfjvUXexox2ztOU.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikvinyl/v1/iJWABXKIfDnIV4mQ5BfjvXXfzIg.ttf"
//   },
//   {
//     "family": "Tiro Tamil",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil"
//     ],
//     "version": "v10",
//     "lastModified": "2023-05-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirotamil/v10/m8JXjfVIf7OT22n3M-S_ULRvamODxdI.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirotamil/v10/m8JVjfVIf7OT22n3M-S_YLZlbkGG1dKEDw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirotamil/v10/m8JXjfVIf7OT22n3M-S_YLVlbg.ttf"
//   },
//   {
//     "family": "Kolker Brush",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kolkerbrush/v6/iJWDBXWRZjfKWdvmzwvvog3-7KJ6x8qNUQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kolkerbrush/v6/iJWDBXWRZjfKWdvmzwvvog3O7ah-.ttf"
//   },
//   {
//     "family": "Noto Sans Cypro Minoan",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cypro-minoan",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanscyprominoan/v1/2Eb2L_dtDUlkNmPHB_UVtEzp3ZlPGqZ_4nAGq9eSf8_eQSE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscyprominoan/v1/2Eb2L_dtDUlkNmPHB_UVtEzp3ZlPGqZ_4nAGm9aYew.ttf"
//   },
//   {
//     "family": "Geostar Fill",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/geostarfill/v26/AMOWz4SWuWiXFfjEohxQ9os0U1K2w9lb4g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/geostarfill/v26/AMOWz4SWuWiXFfjEohxQ9osEUliy.ttf"
//   },
//   {
//     "family": "Alumni Sans Pinstripe",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/alumnisanspinstripe/v6/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObhezYrVIpcDA5w.ttf",
//       "italic": "https://fonts.gstatic.com/s/alumnisanspinstripe/v6/ZgNDjOFFPq_AUJD1umyS30W-Xub8zD1ObheDYL9Mh8XQ5_cY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/alumnisanspinstripe/v6/ZgNNjOFFPq_AUJD1umyS30W-Xub8zD1ObheDY79M.ttf"
//   },
//   {
//     "family": "Rubik Distressed",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikdistressed/v1/GFDxWBdsmnqAVqjtUsZf2dcrQ2ldcWAhatVBaGM.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikdistressed/v1/GFDxWBdsmnqAVqjtUsZf2dcrQ2ldQWErbg.ttf"
//   },
//   {
//     "family": "Sassy Frass",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sassyfrass/v7/LhWhMVrGOe0FLb97BjhsE99dGNWQg_am.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sassyfrass/v7/LhWhMVrGOe0FLb97BjhsE-9cEtE.ttf"
//   },
//   {
//     "family": "Sigmar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sigmar/v7/hv-XlzJgIE8a85pUbWY3MTFgVg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sigmar/v7/hv-XlzJgIE8a85pkbGwz.ttf"
//   },
//   {
//     "family": "Tai Heritage Pro",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tai-viet",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-05-31",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/taiheritagepro/v6/sZlYdQid-zgaNiNIYcUzJMU3IYyNmMB9NNRFMuhjCXY.ttf",
//       "regular": "https://fonts.gstatic.com/s/taiheritagepro/v6/sZlfdQid-zgaNiNIYcUzJMU3IYyNoHxSENxuLuE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/taiheritagepro/v6/sZlfdQid-zgaNiNIYcUzJMU3IYyNkH1YFA.ttf"
//   },
//   {
//     "family": "Yarndings 20",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "math",
//       "symbols"
//     ],
//     "version": "v2",
//     "lastModified": "2024-03-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yarndings20/v2/TuGWUVlkUohEQu8l7K8b-vNFB380PMTK1w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yarndings20/v2/TuGWUVlkUohEQu8l7K8b-vN1BnUw.ttf"
//   },
//   {
//     "family": "Diphylleia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-06-07",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/diphylleia/v1/DtVmJxCtRKMixK4_HXsIulwm6gDXvwE.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/diphylleia/v1/DtVmJxCtRKMixK4_HXsIil0s7g.ttf"
//   },
//   {
//     "family": "Grechen Fuemen",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/grechenfuemen/v9/vEFI2_tHEQ4d5ObgKxBzZh0MAWgc-NaXXq7H.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/grechenfuemen/v9/vEFI2_tHEQ4d5ObgKxBzZh0MAVgd8tI.ttf"
//   },
//   {
//     "family": "Moirai One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-06-07",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/moiraione/v1/2sDbZGFUgJLJmby6xgNGT0WWB7UcfCg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/moiraione/v1/2sDbZGFUgJLJmby6xgNGf0ScAw.ttf"
//   },
//   {
//     "family": "Oi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "latin",
//       "latin-ext",
//       "tamil",
//       "vietnamese"
//     ],
//     "version": "v19",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/oi/v19/w8gXH2EuRqtaut6yjBOG.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/oi/v19/w8gXH2EuRptbsNo.ttf"
//   },
//   {
//     "family": "Noto Sans Buhid",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "buhid",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansbuhid/v22/Dxxy8jiXMW75w3OmoDXVWJD7YwzAe6tgnaFoGA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbuhid/v22/Dxxy8jiXMW75w3OmoDXVWJD7YwzweqFk.ttf"
//   },
//   {
//     "family": "Slackside One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v10",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/slacksideone/v10/EJRQQgMrXdcGsiBuvnRxodTwVy7VocNB6Iw.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/slacksideone/v10/EJRQQgMrXdcGsiBuvnRxodTwZy_fpQ.ttf"
//   },
//   {
//     "family": "Big Shoulders Inline Display",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBEnR5yPc2Huux.ttf",
//       "200": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0lBE3R5yPc2Huux.ttf",
//       "300": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0mfE3R5yPc2Huux.ttf",
//       "500": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nzE3R5yPc2Huux.ttf",
//       "600": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0kfFHR5yPc2Huux.ttf",
//       "700": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0kmFHR5yPc2Huux.ttf",
//       "800": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0lBFHR5yPc2Huux.ttf",
//       "900": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0loFHR5yPc2Huux.ttf",
//       "regular": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBE3R5yPc2Huux.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/bigshouldersinlinedisplay/v27/_LOumyfF4eSU_SCrJc9OI24U7siGvBGcZqmqV9-ZZ85CGNOFeNLxoYMPJ0nBE0R4wvM.ttf"
//   },
//   {
//     "family": "Rubik Doodle Triangles",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikdoodletriangles/v1/esDA301BLOmMKxKspb3g-domRuLPeaSn2bTzdLi_slZxgWE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikdoodletriangles/v1/esDA301BLOmMKxKspb3g-domRuLPeaSn2bTzRLm1tg.ttf"
//   },
//   {
//     "family": "Noto Sans Coptic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "coptic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanscoptic/v21/iJWfBWmUZi_OHPqn4wq6kgqumOEd78u_VG0xR4Y.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscoptic/v21/iJWfBWmUZi_OHPqn4wq6kgqumOEd38q1UA.ttf"
//   },
//   {
//     "family": "Grey Qo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/greyqo/v9/BXRrvF_Nmv_TyXxNDOtQ9Wf0QcE.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/greyqo/v9/BXRrvF_Nmv_TyXxNPOpa8Q.ttf"
//   },
//   {
//     "family": "Noto Serif NP Hmong",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "nyiakeng-puachue-hmong"
//     ],
//     "version": "v1",
//     "lastModified": "2022-12-08",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbj9hFLp3u0rVO-d.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbgRg1Lp3u0rVO-d.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbgog1Lp3u0rVO-d.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbjPhFLp3u0rVO-d.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifnphmong/v1/pONN1gItFMO79E4L1GPUi-2sixKHZyFj9Jy6_KhXPwzdvbjPhGLo1Ok.ttf"
//   },
//   {
//     "family": "Noto Sans Tagalog",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tagalog"
//     ],
//     "version": "v22",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstagalog/v22/J7aFnoNzCnFcV9ZI-sUYuvote1R0wwEAA8jHexnL.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstagalog/v22/J7aFnoNzCnFcV9ZI-sUYuvote1R0wzEBCcw.ttf"
//   },
//   {
//     "family": "Petemoss",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/petemoss/v7/A2BZn5tA2xgtGWHZgxkesKb9UouQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/petemoss/v7/A2BZn5tA2xgtGWHZgykfuqI.ttf"
//   },
//   {
//     "family": "Noto Sans Lepcha",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "lepcha"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanslepcha/v19/0QI7MWlB_JWgA166SKhu05TekNS32AJstqBXgd4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslepcha/v19/0QI7MWlB_JWgA166SKhu05TekNS36ANmsg.ttf"
//   },
//   {
//     "family": "Annapurna SIL",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "devanagari",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v2",
//     "lastModified": "2024-02-16",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/annapurnasil/v2/yYLy0hDY0f2iu9tPmRWtllidyG9SUZzUIZJ008A.ttf",
//       "regular": "https://fonts.gstatic.com/s/annapurnasil/v2/yYLv0hDY0f2iu9tPmRWtllid8NN9dZT_PZs.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/annapurnasil/v2/yYLv0hDY0f2iu9tPmRWtllidwNJ3cQ.ttf"
//   },
//   {
//     "family": "Dai Banna SIL",
//     "variants": [
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "new-tai-lue"
//     ],
//     "version": "v2",
//     "lastModified": "2023-07-24",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tYKoDsrKOgMX95A.ttf",
//       "500": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tdqpDsrKOgMX95A.ttf",
//       "600": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tfauDsrKOgMX95A.ttf",
//       "700": "https://fonts.gstatic.com/s/daibannasil/v2/lW-lwj0AJWmpwGyJ2uEoA4I7tZKvDsrKOgMX95A.ttf",
//       "300italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyygsjAPiES55D3Vg.ttf",
//       "regular": "https://fonts.gstatic.com/s/daibannasil/v2/lW-4wj0AJWmpwGyJ2uEoA4I7jS6AKsLhJgo.ttf",
//       "italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-mwj0AJWmpwGyJ2uEoA4I7vSyKLuDkNgoO7g.ttf",
//       "500italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyy2snAPiES55D3Vg.ttf",
//       "600italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyy9s7APiES55D3Vg.ttf",
//       "700italic": "https://fonts.gstatic.com/s/daibannasil/v2/lW-jwj0AJWmpwGyJ2uEoA4I7vSyyks_APiES55D3Vg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/daibannasil/v2/lW-4wj0AJWmpwGyJ2uEoA4I7vS-KLg.ttf"
//   },
//   {
//     "family": "Noto Serif Tibetan",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tibetan"
//     ],
//     "version": "v22",
//     "lastModified": "2023-03-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYdPS7rdSy_32c.ttf",
//       "200": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIjYcPS7rdSy_32c.ttf",
//       "300": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIugcPS7rdSy_32c.ttf",
//       "500": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIoQcPS7rdSy_32c.ttf",
//       "600": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmImgbPS7rdSy_32c.ttf",
//       "700": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIlEbPS7rdSy_32c.ttf",
//       "800": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIjYbPS7rdSy_32c.ttf",
//       "900": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIh8bPS7rdSy_32c.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYcPS7rdSy_32c.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriftibetan/v22/gokGH7nwAEdtF9N45n0Vaz7O-pk0wsvxHeDXMfqguoCmIrYcDS_hcQ.ttf"
//   },
//   {
//     "family": "Micro 5",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2024-02-16",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/micro5/v1/H4cnBX2MkcfEngTr0gYQ7LO5mqc.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/micro5/v1/H4cnBX2MkcfEngTr4gca6A.ttf"
//   },
//   {
//     "family": "Tiro Gurmukhi",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v6",
//     "lastModified": "2023-01-06",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tirogurmukhi/v6/x3dmckXSYq-Uqjc048JUF7Jvly7HAQsyA2Y.ttf",
//       "italic": "https://fonts.gstatic.com/s/tirogurmukhi/v6/x3d4ckXSYq-Uqjc048JUF7JvpyzNBSk3E2YljQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tirogurmukhi/v6/x3dmckXSYq-Uqjc048JUF7Jvpy_NBQ.ttf"
//   },
//   {
//     "family": "Syne Tactile",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v15",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/synetactile/v15/11hGGpna2UTQKjMCVzjAPMKh3ysdjvKU8Q.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/synetactile/v15/11hGGpna2UTQKjMCVzjAPMKR3iEZ.ttf"
//   },
//   {
//     "family": "Zen Loop",
//     "variants": [
//       "regular",
//       "italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/zenloop/v9/h0GrssK16UsnJwHsEK9zqwzX5vOG.ttf",
//       "italic": "https://fonts.gstatic.com/s/zenloop/v9/h0GtssK16UsnJwHsEJ9xoQj14-OGJ0w.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/zenloop/v9/h0GrssK16UsnJwHsEJ9yoQg.ttf"
//   },
//   {
//     "family": "Protest Guerrilla",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "vietnamese"
//     ],
//     "version": "v2",
//     "lastModified": "2024-02-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/protestguerrilla/v2/Qw3HZR5PDSL6K3irtrY-VJB2YzARHV0koJ8y_eiS.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/protestguerrilla/v2/Qw3HZR5PDSL6K3irtrY-VJB2YzARHW0lqps.ttf"
//   },
//   {
//     "family": "Are You Serious",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v12",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/areyouserious/v12/ll8kK2GVSSr-PtjQ5nONVcNn4306hT9nCGRayg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/areyouserious/v12/ll8kK2GVSSr-PtjQ5nONVcNn430KhDVj.ttf"
//   },
//   {
//     "family": "Kablammo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2023-06-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kablammo/v1/bWtm7fHPcgrhC-J3lcXhcQTY5Ixs6Au9YgCjjx0Rf4YDKw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kablammo/v1/bWtm7fHPcgrhC-J3lcXhcQTY5Ixs6Au9YgCTjhcV.ttf"
//   },
//   {
//     "family": "Palette Mosaic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin"
//     ],
//     "version": "v10",
//     "lastModified": "2023-05-31",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/palettemosaic/v10/AMOIz4aBvWuBFe3TohdW6YZ9MFiy4dxL4jSr.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/palettemosaic/v10/AMOIz4aBvWuBFe3TohdW6YZ9MGiz69g.ttf"
//   },
//   {
//     "family": "Noto Serif Balinese",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "balinese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifbalinese/v18/QdVKSS0-JginysQSRvuCmUMB_wVeQAxXRbgJdhapcUU.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifbalinese/v18/QdVKSS0-JginysQSRvuCmUMB_wVeQAxXdbkDcg.ttf"
//   },
//   {
//     "family": "Noto Serif Ethiopic",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "ethiopic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v30",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSQjkaO9UVLyiw.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCTSUjkaO9UVLyiw.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCkyUjkaO9UVLyiw.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxC_yUjkaO9UVLyiw.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCEyIjkaO9UVLyiw.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCKiIjkaO9UVLyiw.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCTSIjkaO9UVLyiw.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCZCIjkaO9UVLyiw.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSUjkaO9UVLyiw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifethiopic/v30/V8mjoR7-XjwJ8_Au3Ti5tXj5Rd83frpWLK4d-taxqWw2HMWjDxBAg5S_0QsrggxCzSUTkKm5.ttf"
//   },
//   {
//     "family": "Cherish",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v8",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/cherish/v8/ll88K2mXUyqsDsTN5iDCI6IJjg8.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/cherish/v8/ll88K2mXUyqsDsTN1iHIJw.ttf"
//   },
//   {
//     "family": "Rubik 80s Fade",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubik80sfade/v2/U9MF6dW37nLSmnwZXyoV-uPXUhHwkbL8IHcK.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubik80sfade/v2/U9MF6dW37nLSmnwZXyoV-uPXUiHxm7Y.ttf"
//   },
//   {
//     "family": "Noto Serif Tangut",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tangut"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoseriftangut/v16/xn76YGc72GKoTvER4Gn3b4m9Ern7Em41fcvN2KT4.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriftangut/v16/xn76YGc72GKoTvER4Gn3b4m9Ern7El40d88.ttf"
//   },
//   {
//     "family": "Twinkle Star",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/twinklestar/v6/pe0pMI6IL4dPoFl9LGEmY6WaA_Rue1UwVg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/twinklestar/v6/pe0pMI6IL4dPoFl9LGEmY6WqAv5q.ttf"
//   },
//   {
//     "family": "Noto Sans Glagolitic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "glagolitic",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v18",
//     "lastModified": "2024-01-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansglagolitic/v18/1q2ZY4-BBFBst88SU_tOj4J-4yuNF_HI4ERK4Amu7nM1.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansglagolitic/v18/1q2ZY4-BBFBst88SU_tOj4J-4yuNF_HI4HRL6g0.ttf"
//   },
//   {
//     "family": "Ruwudu",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-08",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/ruwudu/v4/sykx-y1tj6UzRKf1YSm3_vjBrlSILg.ttf",
//       "600": "https://fonts.gstatic.com/s/ruwudu/v4/sykx-y1tj6UzRKf1TS63_vjBrlSILg.ttf",
//       "700": "https://fonts.gstatic.com/s/ruwudu/v4/sykx-y1tj6UzRKf1KS-3_vjBrlSILg.ttf",
//       "regular": "https://fonts.gstatic.com/s/ruwudu/v4/syky-y1tj6UzRKfNlQCT9tPdpw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ruwudu/v4/syky-y1tj6UzRKf9lAqX.ttf"
//   },
//   {
//     "family": "Noto Sans Miao",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "miao"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-28",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmiao/v17/Dxxz8jmXMW75w3OmoDXVV4zyZUjgUYVslLhx.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmiao/v17/Dxxz8jmXMW75w3OmoDXVV4zyZXjhW4E.ttf"
//   },
//   {
//     "family": "Geostar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v26",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/geostar/v26/sykz-yx4n701VLOftSq9-trEvlQ.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/geostar/v26/sykz-yx4n701VLOfhSu3_g.ttf"
//   },
//   {
//     "family": "Noto Sans Canadian Aboriginal",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "canadian-aboriginal",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v22",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLj_yAsg0q0uhQ.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzig2Ln_yAsg0q0uhQ.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigBrn_yAsg0q0uhQ.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigarn_yAsg0q0uhQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzighr7_yAsg0q0uhQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigv77_yAsg0q0uhQ.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzig2L7_yAsg0q0uhQ.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzig8b7_yAsg0q0uhQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLn_yAsg0q0uhQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscanadianaboriginal/v22/4C_TLjTuEqPj-8J01CwaGkiZ9os0iGVkezM1mUT-j_Lmlzda6uH_nnX1bzigWLnPyQEk.ttf"
//   },
//   {
//     "family": "Noto Sans Old Italic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-italic"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansolditalic/v16/TuGOUUFzXI5FBtUq5a8bh68BJxxEVam7tWlRdRhtCC4d.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansolditalic/v16/TuGOUUFzXI5FBtUq5a8bh68BJxxEVam7tVlQfxw.ttf"
//   },
//   {
//     "family": "Noto Sans Mongolian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chinese-simplified",
//       "latin",
//       "latin-ext",
//       "math",
//       "mongolian",
//       "symbols"
//     ],
//     "version": "v19",
//     "lastModified": "2024-02-16",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmongolian/v19/VdGCAYADGIwE0EopZx8xQfHlgEAMsrToxLsg6-av1x0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmongolian/v19/VdGCAYADGIwE0EopZx8xQfHlgEAMsrTo9Loq7w.ttf"
//   },
//   {
//     "family": "Noto Serif Toto",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "toto"
//     ],
//     "version": "v4",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notoseriftoto/v4/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhCx_Il-aj55vdNug.ttf",
//       "600": "https://fonts.gstatic.com/s/notoseriftoto/v4/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhC_PPl-aj55vdNug.ttf",
//       "700": "https://fonts.gstatic.com/s/notoseriftoto/v4/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhC8rPl-aj55vdNug.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoseriftoto/v4/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhCy3Il-aj55vdNug.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriftoto/v4/Ktk6ALSMeZjqPnXk1rCkHYHNtwvtHItpjRP74dHhCy3Ip-ep4w.ttf"
//   },
//   {
//     "family": "Noto Serif Lao",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "lao",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-09-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMLrvOjlmyhHHQ.ttf",
//       "200": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VWMKrvOjlmyhHHQ.ttf",
//       "300": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8Vb0KrvOjlmyhHHQ.ttf",
//       "500": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VdEKrvOjlmyhHHQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VT0NrvOjlmyhHHQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VQQNrvOjlmyhHHQ.ttf",
//       "800": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VWMNrvOjlmyhHHQ.ttf",
//       "900": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VUoNrvOjlmyhHHQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMKrvOjlmyhHHQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriflao/v24/3y9C6bYwcCjmsU8JEzCMxEwQfEBLk3f0rlSqCdaM_LlSNZ59oNw0BWH8VeMKnvKpkg.ttf"
//   },
//   {
//     "family": "Noto Sans Cherokee",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cherokee",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-09-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5ODkm5rAffjl0.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWq5PDkm5rAffjl0.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWnBPDkm5rAffjl0.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWhxPDkm5rAffjl0.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWvBIDkm5rAffjl0.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWslIDkm5rAffjl0.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWq5IDkm5rAffjl0.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWodIDkm5rAffjl0.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5PDkm5rAffjl0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscherokee/v20/KFOPCm6Yu8uF-29fiz9vQF9YWK6Z8O10cHNA0cSkZCHYWi5PPkizqA.ttf"
//   },
//   {
//     "family": "Sixtyfour",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2024-01-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/sixtyfour/v1/OD5vuMCT1numDm3nakXtp2h4jg463t9haG_3mBkVsV20uFT3BAE5f73YnyS5ZuOV.ttf"
//     },
//     "category": "monospace",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/sixtyfour/v1/OD5vuMCT1numDm3nakXtp2h4jg463t9haG_3mBkVsV20uFT3BAE5f43ZlSA.ttf"
//   },
//   {
//     "family": "Noto Sans Sora Sompeng",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sora-sompeng"
//     ],
//     "version": "v24",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanssorasompeng/v24/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHRO18DpZXJQd4Mu.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanssorasompeng/v24/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHSi0MDpZXJQd4Mu.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanssorasompeng/v24/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHSb0MDpZXJQd4Mu.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanssorasompeng/v24/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHR818DpZXJQd4Mu.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssorasompeng/v24/PlIRFkO5O6RzLfvNNVSioxM2_OTrEhPyDLolKvCsHzCxWuGkYHR81_Dob3Y.ttf"
//   },
//   {
//     "family": "Noto Sans Linear A",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "linear-a"
//     ],
//     "version": "v18",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanslineara/v18/oPWS_l16kP4jCuhpgEGmwJOiA18FZj22zmHQAGQicw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslineara/v18/oPWS_l16kP4jCuhpgEGmwJOiA18FZj2Gz2vU.ttf"
//   },
//   {
//     "family": "Lunasima",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "greek",
//       "greek-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v1",
//     "lastModified": "2023-07-13",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/lunasima/v1/wEO5EBvPh9RSOj7JFDSZVPoP_k4XiKvO.ttf",
//       "regular": "https://fonts.gstatic.com/s/lunasima/v1/wEO-EBvPh9RSOj7JFAwle94H1VIe.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lunasima/v1/wEO-EBvPh9RSOj7JFDwkcdo.ttf"
//   },
//   {
//     "family": "Noto Sans Thaana",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "thaana"
//     ],
//     "version": "v24",
//     "lastModified": "2023-11-09",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbxLhnu4-tbNu.ttf",
//       "200": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4VrbhLhnu4-tbNu.ttf",
//       "300": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4W1bhLhnu4-tbNu.ttf",
//       "500": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XZbhLhnu4-tbNu.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4U1aRLhnu4-tbNu.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4UMaRLhnu4-tbNu.ttf",
//       "800": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4VraRLhnu4-tbNu.ttf",
//       "900": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4VCaRLhnu4-tbNu.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbhLhnu4-tbNu.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansthaana/v24/C8c14dM-vnz-s-3jaEsxlxHkBH-WZOETXfoQrfQ9Y4XrbiLglOo.ttf"
//   },
//   {
//     "family": "Namdhinggo",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "limbu"
//     ],
//     "version": "v2",
//     "lastModified": "2024-02-16",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNoGKxyRa8oZK9I0.ttf",
//       "600": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNq2NxyRa8oZK9I0.ttf",
//       "700": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNsmMxyRa8oZK9I0.ttf",
//       "800": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-lEGe3rbgg8Xzoy5-TNtWPxyRa8oZK9I0.ttf",
//       "regular": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-mEGe3rbgg8Xzoy5-TDnWj4yxx7o8.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/namdhinggo/v2/uk-mEGe3rbgg8Xzoy5-TPnSp5w.ttf"
//   },
//   {
//     "family": "Rubik Spray Paint",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikspraypaint/v1/WnzhHBAoeBPUDTB4EWR82y6EXWPH-Ro-QoaBZQxP.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikspraypaint/v1/WnzhHBAoeBPUDTB4EWR82y6EXWPH-So_SII.ttf"
//   },
//   {
//     "family": "Orbit",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2023-06-07",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/orbit/v1/_LOCmz7I-uHd2mjEeqciRwRm.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/orbit/v1/_LOCmz7I-uHd2ljFcKM.ttf"
//   },
//   {
//     "family": "Noto Sans Egyptian Hieroglyphs",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "egyptian-hieroglyphs",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansegyptianhieroglyphs/v28/vEF42-tODB8RrNDvZSUmRhcQHzx1s7y_F9-j3qSzEcbEYindSVK8xRg7iw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansegyptianhieroglyphs/v28/vEF42-tODB8RrNDvZSUmRhcQHzx1s7y_F9-j3qSzEcbEYintSFi4.ttf"
//   },
//   {
//     "family": "Tapestry",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/tapestry/v4/SlGTmQecrosEYXhaGBIkqnB6aSQU.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/tapestry/v4/SlGTmQecrosEYXhaGCIloHQ.ttf"
//   },
//   {
//     "family": "Estonia",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/estonia/v11/7Au_p_4ijSecA1yHCCL8zkwMIFg.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/estonia/v11/7Au_p_4ijSecA1yHOCP2yg.ttf"
//   },
//   {
//     "family": "Grandiflora One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "korean",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v3",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/grandifloraone/v3/0ybmGD0g27bCk_5MGWZcKWhxwnUU_R3y8DOWGA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/grandifloraone/v3/0ybmGD0g27bCk_5MGWZcKWhxwnUk_Bf2.ttf"
//   },
//   {
//     "family": "M PLUS Code Latin",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v13",
//     "lastModified": "2023-08-25",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1EbB6i5MqF9TRwg.ttf",
//       "200": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1MbA6i5MqF9TRwg.ttf",
//       "300": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1BjA6i5MqF9TRwg.ttf",
//       "500": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1HTA6i5MqF9TRwg.ttf",
//       "600": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1JjH6i5MqF9TRwg.ttf",
//       "700": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1KHH6i5MqF9TRwg.ttf",
//       "regular": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1EbA6i5MqF9TRwg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mpluscodelatin/v13/hv-ylyV-aXg7x7tULiNXXBA0Np4WMS8fDIymHY8fy8wn4_ifLAtrObKDO0Xf1EbA2i9GrA.ttf"
//   },
//   {
//     "family": "Warnes",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/warnes/v27/pONn1hc0GsW6sW5OpiC2o6Lkqg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/warnes/v27/pONn1hc0GsW6sW5-pyqy.ttf"
//   },
//   {
//     "family": "Lugrasimo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/lugrasimo/v4/qkBXXvoF_s_eT9c7Y7ae5JRLkAXbMQ.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lugrasimo/v4/qkBXXvoF_s_eT9c7Y7au5Z5P.ttf"
//   },
//   {
//     "family": "Noto Sans Old Hungarian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-hungarian"
//     ],
//     "version": "v18",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansoldhungarian/v18/E213_cD6hP3GwCJPEUssHEM0KqLaHJXg2PiIgRfjbg5nCYXt.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoldhungarian/v18/E213_cD6hP3GwCJPEUssHEM0KqLaHJXg2PiIgSfiZAo.ttf"
//   },
//   {
//     "family": "Hanalei",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/hanalei/v23/E21n_dD8iufIjBRHXzgmVydREus.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/hanalei/v23/E21n_dD8iufIjBRHbzksUw.ttf"
//   },
//   {
//     "family": "Noto Serif Ottoman Siyaq",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "ottoman-siyaq-numbers"
//     ],
//     "version": "v2",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifottomansiyaq/v2/fC1yPZ9IYnzRhTrrc4s8cSvYI0eozzaFOQ01qoHLJrgA00kAdA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifottomansiyaq/v2/fC1yPZ9IYnzRhTrrc4s8cSvYI0eozzaFOQ01qoH7J7IE.ttf"
//   },
//   {
//     "family": "Noto Sans Syloti Nagri",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "syloti-nagri"
//     ],
//     "version": "v20",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssylotinagri/v20/uU9eCAQZ75uhfF9UoWDRiY3q7Sf_VFV3m4dGFVfxN87gsj0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssylotinagri/v20/uU9eCAQZ75uhfF9UoWDRiY3q7Sf_VFV3m4dGJVb7Mw.ttf"
//   },
//   {
//     "family": "Noto Sans Imperial Aramaic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "imperial-aramaic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansimperialaramaic/v16/a8IMNpjwKmHXpgXbMIsbTc_kvks91LlLetBr5itQrtdml3YfPNno.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansimperialaramaic/v16/a8IMNpjwKmHXpgXbMIsbTc_kvks91LlLetBr5itQrudnnXI.ttf"
//   },
//   {
//     "family": "Noto Sans Carian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "carian",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanscarian/v16/LDIpaoiONgYwA9Yc6f0gUILeMIOgs7ob9yGLmfI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscarian/v16/LDIpaoiONgYwA9Yc6f0gUILeMIOgg7sR8w.ttf"
//   },
//   {
//     "family": "Moo Lah Lah",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/moolahlah/v6/dg4h_p_opKZOA0w1AYcm55wtYQYugjW4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/moolahlah/v6/dg4h_p_opKZOA0w1AYcm56wsawI.ttf"
//   },
//   {
//     "family": "Flow Rounded",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v11",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/flowrounded/v11/-zki91mtwsU9qlLiGwD4oQX3oZX-Xup87g.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/flowrounded/v11/-zki91mtwsU9qlLiGwD4oQXHoJ_6.ttf"
//   },
//   {
//     "family": "Rubik Puddles",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikpuddles/v2/1Ptog8bYX_qGnkLkrU5MJsQcJfC0wVMT-aE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikpuddles/v2/1Ptog8bYX_qGnkLkrU5MJsQcFfG-xQ.ttf"
//   },
//   {
//     "family": "Noto Traditional Nushu",
//     "variants": [
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "nushu"
//     ],
//     "version": "v17",
//     "lastModified": "2023-05-23",
//     "files": {
//       "300": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZcV3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67PUZX6ADm6oa8IXvy1tnPa7QoqirI.ttf",
//       "500": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZcV3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67PUZX6ADm6oa8IXue1tnPa7QoqirI.ttf",
//       "600": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZcV3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67PUZX6ADm6oa8IXty0dnPa7QoqirI.ttf",
//       "700": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZcV3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67PUZX6ADm6oa8IXtL0dnPa7QoqirI.ttf",
//       "regular": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZcV3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67PUZX6ADm6oa8IXus1tnPa7QoqirI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/nototraditionalnushu/v17/SZcV3EDkJ7q9FaoMPlmF4Su8hlIjoGh5aj67PUZX6ADm6oa8IXus1unOYbA.ttf"
//   },
//   {
//     "family": "Rubik Maze",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikmaze/v2/xMQRuF9ZVa2ftiJEavXSAX7inS-bxV4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikmaze/v2/xMQRuF9ZVa2ftiJEavXSMX_omQ.ttf"
//   },
//   {
//     "family": "Rubik Gemstones",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikgemstones/v1/zrf90HrL0-_8Xb4DFM2rUkWbOVrOiCnGqi1GMw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikgemstones/v1/zrf90HrL0-_8Xb4DFM2rUkWbOVr-iSPC.ttf"
//   },
//   {
//     "family": "Ole",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v3",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ole/v3/dFazZf6Z-rd89fw69qJ_ew.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ole/v3/dFazZf6Z-rdM9PY-.ttf"
//   },
//   {
//     "family": "Noto Sans Tangsa",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tangsa"
//     ],
//     "version": "v6",
//     "lastModified": "2023-10-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanstangsa/v6/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp1qkyoRYdplyJDA.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanstangsa/v6/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp2GlCoRYdplyJDA.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanstangsa/v6/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp2_lCoRYdplyJDA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanstangsa/v6/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp1YkyoRYdplyJDA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstangsa/v6/z7NCdQPmcigbbZAIOl9igP26K470lICpky0-peX5Qp1YkxoQa94.ttf"
//   },
//   {
//     "family": "Jacquarda Bastarda 9",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v4",
//     "lastModified": "2024-03-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/jacquardabastarda9/v4/f0Xp0fWr_8t6WFtKQJfOhaC0hcZ1HYAMAbwD1TB_JHHY.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/jacquardabastarda9/v4/f0Xp0fWr_8t6WFtKQJfOhaC0hcZ1HYAMAYwC3zQ.ttf"
//   },
//   {
//     "family": "Noto Serif Myanmar",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "myanmar"
//     ],
//     "version": "v13",
//     "lastModified": "2022-09-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJudM7F2Yv76aBKKs-bHMQfAHUw3jnNwBDsU9X6RPzQ.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNbDHMefv2TeXJng.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNCDLMefv2TeXJng.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNUDPMefv2TeXJng.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNfDTMefv2TeXJng.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNGDXMefv2TeXJng.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNBDbMefv2TeXJng.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJvdM7F2Yv76aBKKs-bHMQfAHUw3jnNIDfMefv2TeXJng.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJsdM7F2Yv76aBKKs-bHMQfAHUw3jn1pBrocdDqRA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifmyanmar/v13/VuJsdM7F2Yv76aBKKs-bHMQfAHUw3jnFpRDs.ttf"
//   },
//   {
//     "family": "Noto Sans Modi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "modi"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmodi/v23/pe03MIySN5pO62Z5YkFyT7jeav5qWVAgVol-.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmodi/v23/pe03MIySN5pO62Z5YkFyT7jeas5rU1Q.ttf"
//   },
//   {
//     "family": "Ingrid Darling",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v5",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/ingriddarling/v5/LDIrapaJNxUtSuFdw-9yf4rCPsLOub458jGL.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/ingriddarling/v5/LDIrapaJNxUtSuFdw-9yf4rCPvLPs7o.ttf"
//   },
//   {
//     "family": "Shizuru",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin"
//     ],
//     "version": "v10",
//     "lastModified": "2023-05-31",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/shizuru/v10/O4ZSFGfvnxFiCA3i30IJlgUTj2A.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/shizuru/v10/O4ZSFGfvnxFiCA3i70MDkg.ttf"
//   },
//   {
//     "family": "Rubik Pixels",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-04-04",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikpixels/v2/SlGXmQOaupkIeSx4CEpB7AdSaBYRagrQrA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikpixels/v2/SlGXmQOaupkIeSx4CEpB7AdiaRwV.ttf"
//   },
//   {
//     "family": "Rubik Burned",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqniHQuaCY5ZSg.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikburned/v1/Jqzk5TmOVOqQHihKqPpscqnSHAGe.ttf"
//   },
//   {
//     "family": "Noto Serif Yezidi",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "yezidi"
//     ],
//     "version": "v21",
//     "lastModified": "2023-07-24",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notoserifyezidi/v21/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD6SEkrlGJgmVCqg.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifyezidi/v21/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspDBSYkrlGJgmVCqg.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifyezidi/v21/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspDPCYkrlGJgmVCqg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifyezidi/v21/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD2yEkrlGJgmVCqg.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifyezidi/v21/XLYPIYr5bJNDGYxLBibeHZAn3B5KJENnQjbfhMSVZspD2yEUr1uN.ttf"
//   },
//   {
//     "family": "Rubik Glitch Pop",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2024-01-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikglitchpop/v1/tDbX2pGHhFcM0gB3hN2elZLa3G-MOwStSUrV_BE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikglitchpop/v1/tDbX2pGHhFcM0gB3hN2elZLa3G-MCwWnTQ.ttf"
//   },
//   {
//     "family": "Yuji Hentaigana Akari",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v11",
//     "lastModified": "2023-05-31",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yujihentaiganaakari/v11/cY9bfiyVT0VB6QuhWKOrpr6z58lnb_zYFnLIRTzODYALaA.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yujihentaiganaakari/v11/cY9bfiyVT0VB6QuhWKOrpr6z58lnb_zYFnL4RDbK.ttf"
//   },
//   {
//     "family": "Rubik Microbe",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikmicrobe/v2/UqyWK8oPP3hjw6ANS9rM3PsZcs8aaKgiauE.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikmicrobe/v2/UqyWK8oPP3hjw6ANS9rM3PsZQs4QbA.ttf"
//   },
//   {
//     "family": "Blaka Hollow",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v7",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/blakahollow/v7/MCoUzAL91sjRE2FsKsxUtezYB9oFyW_-oA.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/blakahollow/v7/MCoUzAL91sjRE2FsKsxUtezoBtAB.ttf"
//   },
//   {
//     "family": "Noto Serif Ahom",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "ahom",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifahom/v20/FeVIS0hfp6cprmEUffAW_fUL_AN-wuYrPFiwaw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifahom/v20/FeVIS0hfp6cprmEUffAW_fUL_ANOw-wv.ttf"
//   },
//   {
//     "family": "Noto Znamenny Musical Notation",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols",
//       "znamenny"
//     ],
//     "version": "v3",
//     "lastModified": "2024-04-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoznamennymusicalnotation/v3/CSRW4ylQnPyaDwAMK1U_AolTaJ4Lz41GcgaIZV9YO2rO88jvtpqqdoWa7g.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoznamennymusicalnotation/v3/CSRW4ylQnPyaDwAMK1U_AolTaJ4Lz41GcgaIZV9YO2rO88jft5Cu.ttf"
//   },
//   {
//     "family": "Lisu Bosa",
//     "variants": [
//       "200",
//       "200italic",
//       "300",
//       "300italic",
//       "regular",
//       "italic",
//       "500",
//       "500italic",
//       "600",
//       "600italic",
//       "700",
//       "700italic",
//       "800",
//       "800italic",
//       "900",
//       "900italic"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "lisu"
//     ],
//     "version": "v2",
//     "lastModified": "2023-07-24",
//     "files": {
//       "200": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXX2DGRdbY1P1Sbg.ttf",
//       "300": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXO2PGRdbY1P1Sbg.ttf",
//       "500": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXY2LGRdbY1P1Sbg.ttf",
//       "600": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXT2XGRdbY1P1Sbg.ttf",
//       "700": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXK2TGRdbY1P1Sbg.ttf",
//       "800": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXN2fGRdbY1P1Sbg.ttf",
//       "900": "https://fonts.gstatic.com/s/lisubosa/v2/3XFtErkv240fsdmJRJQXE2bGRdbY1P1Sbg.ttf",
//       "200italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXkuRNzc9vhCblye.ttf",
//       "300italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXlKR9zc9vhCblye.ttf",
//       "regular": "https://fonts.gstatic.com/s/lisubosa/v2/3XFoErkv240fsdmJRJQvl0viTf3E3Q.ttf",
//       "italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFuErkv240fsdmJRJQflUHmb_jU3eRL.ttf",
//       "500italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXkSRtzc9vhCblye.ttf",
//       "600italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXk-Qdzc9vhCblye.ttf",
//       "700italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXlaQNzc9vhCblye.ttf",
//       "800italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXlGQ9zc9vhCblye.ttf",
//       "900italic": "https://fonts.gstatic.com/s/lisubosa/v2/3XFzErkv240fsdmJRJQflXliQtzc9vhCblye.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/lisubosa/v2/3XFoErkv240fsdmJRJQflkHm.ttf"
//   },
//   {
//     "family": "Blaka Ink",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "arabic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v8",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/blakaink/v8/AlZy_zVVtpj22Znag2chdXf4XB0Tow.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/blakaink/v8/AlZy_zVVtpj22Znag2cRdH38.ttf"
//   },
//   {
//     "family": "Rubik Maps",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikmaps/v1/Gw6_wcjl80TZK9XxtbbejSYUChRqp9k.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikmaps/v1/Gw6_wcjl80TZK9XxtbbevSceDg.ttf"
//   },
//   {
//     "family": "Noto Serif Grantha",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "grantha",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2022-09-28",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifgrantha/v19/qkBIXuEH5NzDDvc3fLDYxPk9-Wq3WLiqFENLR7fHGw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifgrantha/v19/qkBIXuEH5NzDDvc3fLDYxPk9-Wq3WLiaFUlP.ttf"
//   },
//   {
//     "family": "Kings",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v7",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/kings/v7/8AtnGsK4O5CYXU_Iq6GSPaHS.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kings/v7/8AtnGsK4O5CYXX_JoaU.ttf"
//   },
//   {
//     "family": "Noto Serif Oriya",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "oriya"
//     ],
//     "version": "v4",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notoseriforiya/v4/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxru_Hy-v039MF1j.ttf",
//       "600": "https://fonts.gstatic.com/s/notoseriforiya/v4/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxoC-3y-v039MF1j.ttf",
//       "700": "https://fonts.gstatic.com/s/notoseriforiya/v4/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxo7-3y-v039MF1j.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoseriforiya/v4/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxrc_Hy-v039MF1j.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoseriforiya/v4/MjQQmj56u-r69izk_LDqWN7w0cYByutv9qeWYrvLaxrc_Ey_tUk.ttf"
//   },
//   {
//     "family": "Rubik Marker Hatch",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-09-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikmarkerhatch/v1/QldTNSFQsh0B_bFXXWv6LAt-jswapJHQDL4iw0H6zw.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikmarkerhatch/v1/QldTNSFQsh0B_bFXXWv6LAt-jswapJHgDbQm.ttf"
//   },
//   {
//     "family": "Rock 3D",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin"
//     ],
//     "version": "v10",
//     "lastModified": "2023-05-31",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rock3d/v10/yYLp0hrL0PCo651513SnwRnQyNI.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rock3d/v10/yYLp0hrL0PCo651553WtxQ.ttf"
//   },
//   {
//     "family": "Rubik Storm",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v1",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikstorm/v1/eLGYP-_uPgO5Ag7ju9JaouL9T2Xh9NQk.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikstorm/v1/eLGYP-_uPgO5Ag7ju9JaotL8RWE.ttf"
//   },
//   {
//     "family": "Noto Sans Avestan",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "avestan",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansavestan/v21/bWti7ejKfBziStx7lIzKOLQZKhIJkyu9SASLji8U.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansavestan/v21/bWti7ejKfBziStx7lIzKOLQZKhIJkxu8QgA.ttf"
//   },
//   {
//     "family": "Noto Sans Lydian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "lydian"
//     ],
//     "version": "v18",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanslydian/v18/c4m71mVzGN7s8FmIukZJ1v4ZlcPReUPXMoIjEQI.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslydian/v18/c4m71mVzGN7s8FmIukZJ1v4ZlcPRSULdNg.ttf"
//   },
//   {
//     "family": "Noto Sans Bamum",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "bamum",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v27",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansbamum/v27/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEeVO-_gLykxEkxA.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansbamum/v27/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEQlJ-_gLykxEkxA.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansbamum/v27/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPETBJ-_gLykxEkxA.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansbamum/v27/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEddO-_gLykxEkxA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbamum/v27/uk-0EGK3o6EruUbnwovcbBTkkklK_Ya_PBHfNGTPEddOy_kBzg.ttf"
//   },
//   {
//     "family": "Noto Sans Tifinagh",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tifinagh"
//     ],
//     "version": "v20",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstifinagh/v20/I_uzMoCduATTei9eI8dawkHIwvmhCvbn6rnEcXfs4Q.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstifinagh/v20/I_uzMoCduATTei9eI8dawkHIwvmhCvbX67PA.ttf"
//   },
//   {
//     "family": "Noto Sans Nag Mundari",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "nag-mundari"
//     ],
//     "version": "v1",
//     "lastModified": "2023-05-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansnagmundari/v1/3qTAoi2hnSyU8TNFIdhZTyod3g5lBnKlQFk2kS9fr9Eq09RHRyqrbUGA0whP19M.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansnagmundari/v1/3qTAoi2hnSyU8TNFIdhZTyod3g5lBnKlQFk2kS9fr9Eq09RHR8asbUGA0whP19M.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansnagmundari/v1/3qTAoi2hnSyU8TNFIdhZTyod3g5lBnKlQFk2kS9fr9Eq09RHR_-sbUGA0whP19M.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansnagmundari/v1/3qTAoi2hnSyU8TNFIdhZTyod3g5lBnKlQFk2kS9fr9Eq09RHRxirbUGA0whP19M.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnagmundari/v1/3qTAoi2hnSyU8TNFIdhZTyod3g5lBnKlQFk2kS9fr9Eq09RHRxirXUCK1w.ttf"
//   },
//   {
//     "family": "Linefont",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v5",
//     "lastModified": "2023-12-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY4UNbu7tmdXux3U.ttf",
//       "200": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnYwUMbu7tmdXux3U.ttf",
//       "300": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY9sMbu7tmdXux3U.ttf",
//       "500": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY7cMbu7tmdXux3U.ttf",
//       "600": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY1sLbu7tmdXux3U.ttf",
//       "700": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY2ILbu7tmdXux3U.ttf",
//       "800": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnYwULbu7tmdXux3U.ttf",
//       "900": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnYywLbu7tmdXux3U.ttf",
//       "regular": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY4UMbu7tmdXux3U.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/linefont/v5/dg4I_pzpoqcLKUIzVfFMh1TF2rkhli25jn7CKTTWSumsFuSnY4UMXu_nnQ.ttf"
//   },
//   {
//     "family": "Noto Sans Medefaidrin",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "medefaidrin"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansmedefaidrin/v23/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmHjWlT318e5A3rw.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansmedefaidrin/v23/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmJTRlT318e5A3rw.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansmedefaidrin/v23/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmK3RlT318e5A3rw.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansmedefaidrin/v23/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmErWlT318e5A3rw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmedefaidrin/v23/WwkzxOq6Dk-wranENynkfeVsNbRZtbOIdLb1exeM4ZeuabBfmErWpTz_9Q.ttf"
//   },
//   {
//     "family": "Puppies Play",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/puppiesplay/v9/wlp2gwHZEV99rG6M3NR9uB9vaAJSA_JN3Q.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/puppiesplay/v9/wlp2gwHZEV99rG6M3NR9uB9faQhW.ttf"
//   },
//   {
//     "family": "Noto Sans Nandinagari",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "nandinagari"
//     ],
//     "version": "v4",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansnandinagari/v4/or38Q7733eiDljA1IufXSNFT-1KI5y10H4jVa5RXzC1KOw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnandinagari/v4/or38Q7733eiDljA1IufXSNFT-1KI5y10H4jlap5T.ttf"
//   },
//   {
//     "family": "Noto Sans Khojki",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khojki",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2024-02-16",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanskhojki/v19/-nFnOHM29Oofr2wohFbTuPPKVWpmK_d709jy92k.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskhojki/v19/-nFnOHM29Oofr2wohFbTuPPKVWpmG_Zx1w.ttf"
//   },
//   {
//     "family": "Noto Sans Ol Chiki",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "ol-chiki"
//     ],
//     "version": "v29",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansolchiki/v29/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALVs267I6gVrz5gQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansolchiki/v29/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALbcx67I6gVrz5gQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansolchiki/v29/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALY4x67I6gVrz5gQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansolchiki/v29/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALWk267I6gVrz5gQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansolchiki/v29/N0b92TJNOPt-eHmFZCdQbrL32r-4CvhzDzRwlxOQYuVALWk227MwhQ.ttf"
//   },
//   {
//     "family": "Noto Sans Marchen",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "marchen"
//     ],
//     "version": "v19",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmarchen/v19/aFTO7OZ_Y282EP-WyG6QTOX_C8WZMHhPk652ZaHk.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmarchen/v19/aFTO7OZ_Y282EP-WyG6QTOX_C8WZMEhOmao.ttf"
//   },
//   {
//     "family": "Noto Sans Deseret",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "deseret",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansdeseret/v17/MwQsbgPp1eKH6QsAVuFb9AZM6MMr2Vq9ZnJSZtQG.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansdeseret/v17/MwQsbgPp1eKH6QsAVuFb9AZM6MMr2Wq8bHY.ttf"
//   },
//   {
//     "family": "Noto Sans Adlam Unjoined",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "adlam",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v25",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansadlamunjoined/v25/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Yd_5PMEe-E3slUg.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansadlamunjoined/v25/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_YTP-PMEe-E3slUg.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansadlamunjoined/v25/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_YQr-PMEe-E3slUg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansadlamunjoined/v25/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Ye35PMEe-E3slUg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansadlamunjoined/v25/P5sszY2MYsLRsB5_ildkzPPDsLQXcOEmaFOqOGcaYrzFTIjsPam_Ye35DMAU_A.ttf"
//   },
//   {
//     "family": "Noto Sans Old South Arabian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-south-arabian"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansoldsoutharabian/v16/3qT5oiOhnSyU8TNFIdhZTice3hB_HWKsEnF--0XCHiKx1OtDT9HwTA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoldsoutharabian/v16/3qT5oiOhnSyU8TNFIdhZTice3hB_HWKsEnF--0XCHiKB1eFH.ttf"
//   },
//   {
//     "family": "Mingzat",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "lepcha"
//     ],
//     "version": "v8",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/mingzat/v8/0QIgMX5C-o-oWWyvBttkm_mv670.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/mingzat/v8/0QIgMX5C-o-oWWyvNtpunw.ttf"
//   },
//   {
//     "family": "Rubik Broken Fax",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubikbrokenfax/v1/NGSvv4rXG042O-GzH9sg1cUgl8w8YW-WdmGi300.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubikbrokenfax/v1/NGSvv4rXG042O-GzH9sg1cUgl8w8UW6ccg.ttf"
//   },
//   {
//     "family": "Noto Sans Hanunoo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "hanunoo",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanshanunoo/v21/f0Xs0fCv8dxkDWlZSoXOj6CphMloFsEsEpgL_ix2.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanshanunoo/v21/f0Xs0fCv8dxkDWlZSoXOj6CphMloFvEtGJw.ttf"
//   },
//   {
//     "family": "Kay Pho Du",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "kayah-li",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v2",
//     "lastModified": "2023-10-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/kayphodu/v2/jizcREFPvGNOx-jhPwHR2B2OCDUy_zoyk18.ttf",
//       "600": "https://fonts.gstatic.com/s/kayphodu/v2/jizcREFPvGNOx-jhPwHR2DGJCDUy_zoyk18.ttf",
//       "700": "https://fonts.gstatic.com/s/kayphodu/v2/jizcREFPvGNOx-jhPwHR2FWICDUy_zoyk18.ttf",
//       "regular": "https://fonts.gstatic.com/s/kayphodu/v2/jizfREFPvGNOx-jhPwHR4OmnLD0Z4zM.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/kayphodu/v2/jizfREFPvGNOx-jhPwHR0OitKA.ttf"
//   },
//   {
//     "family": "Noto Sans Tamil Supplement",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tamil-supplement"
//     ],
//     "version": "v21",
//     "lastModified": "2023-06-30",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstamilsupplement/v21/DdTz78kEtnooLS5rXF1DaruiCd_bFp_Ph4sGcn7ax_vsAeMkeq1x.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstamilsupplement/v21/DdTz78kEtnooLS5rXF1DaruiCd_bFp_Ph4sGcn7ax8vtC-c.ttf"
//   },
//   {
//     "family": "Wavefont",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin"
//     ],
//     "version": "v10",
//     "lastModified": "2023-12-14",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI3V0rDHXKtOXOg4.ttf",
//       "200": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI1V07DHXKtOXOg4.ttf",
//       "300": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI2L07DHXKtOXOg4.ttf",
//       "500": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI3n07DHXKtOXOg4.ttf",
//       "600": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI0L1LDHXKtOXOg4.ttf",
//       "700": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI0y1LDHXKtOXOg4.ttf",
//       "800": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI1V1LDHXKtOXOg4.ttf",
//       "900": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI181LDHXKtOXOg4.ttf",
//       "regular": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI3V07DHXKtOXOg4.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/wavefont/v10/L0xFDF00m0cP6hefyOCpRezQNuizSrqDyx8FHbFu21B3L4m0SEzuQYwq-f_JJ8I1WI3V04DGVq8.ttf"
//   },
//   {
//     "family": "Rubik Lines",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic",
//       "cyrillic-ext",
//       "hebrew",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v1",
//     "lastModified": "2023-12-14",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/rubiklines/v1/_gP81R3vsjYzVW2Y6xFF-GSxYPp7oSNy.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/rubiklines/v1/_gP81R3vsjYzVW2Y6xFF-FSwav4.ttf"
//   },
//   {
//     "family": "Noto Sans Bassa Vah",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "bassa-vah",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-11-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4MoAc6p34gH-GD7.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4PEBs6p34gH-GD7.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4P9Bs6p34gH-GD7.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4MaAc6p34gH-GD7.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbassavah/v17/PN_bRee-r3f7LnqsD5sax12gjZn7mBpL5YwUpA2MBdcFn4MaAf6o1Yw.ttf"
//   },
//   {
//     "family": "Noto Sans Balinese",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "balinese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v24",
//     "lastModified": "2023-05-02",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansbalinese/v24/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov4XdhE5Vd222PPY.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansbalinese/v24/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov2nahE5Vd222PPY.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansbalinese/v24/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov1DahE5Vd222PPY.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansbalinese/v24/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov7fdhE5Vd222PPY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbalinese/v24/NaPwcYvSBuhTirw6IaFn6UrRDaqje-lpbbRtYf-Fwu2Ov7fdtE9fcw.ttf"
//   },
//   {
//     "family": "Noto Sans Vithkuqi",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vithkuqi"
//     ],
//     "version": "v1",
//     "lastModified": "2023-07-24",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansvithkuqi/v1/jVyi7m77CXvQswd6WjYu9E1wN6cih2TSchUEkQgw3KTnvZxSgKM7vmn0BLE.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansvithkuqi/v1/jVyi7m77CXvQswd6WjYu9E1wN6cih2TSchUEkQgw3KTnvXBVgKM7vmn0BLE.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansvithkuqi/v1/jVyi7m77CXvQswd6WjYu9E1wN6cih2TSchUEkQgw3KTnvUlVgKM7vmn0BLE.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansvithkuqi/v1/jVyi7m77CXvQswd6WjYu9E1wN6cih2TSchUEkQgw3KTnva5SgKM7vmn0BLE.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansvithkuqi/v1/jVyi7m77CXvQswd6WjYu9E1wN6cih2TSchUEkQgw3KTnva5SsKIxug.ttf"
//   },
//   {
//     "family": "Noto Sans Yi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "yi"
//     ],
//     "version": "v19",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansyi/v19/sJoD3LFXjsSdcnzn071rO3apxVDJNVgSNg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansyi/v19/sJoD3LFXjsSdcnzn071rO3aZxFrN.ttf"
//   },
//   {
//     "family": "Noto Sans Kayah Li",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "kayah-li",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanskayahli/v21/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WBU3CZH4EXLuKVM.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanskayahli/v21/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WPkwCZH4EXLuKVM.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanskayahli/v21/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WMAwCZH4EXLuKVM.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanskayahli/v21/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WCc3CZH4EXLuKVM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskayahli/v21/B50nF61OpWTRcGrhOVJJwOMXdca6Yecki3E06x2jVTX3WCc3OZDyFQ.ttf"
//   },
//   {
//     "family": "Noto Serif Gurmukhi",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "gurmukhi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v20",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eBTNmqVU7y6l.ttf",
//       "200": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr4-eRTNmqVU7y6l.ttf",
//       "300": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr7geRTNmqVU7y6l.ttf",
//       "500": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6MeRTNmqVU7y6l.ttf",
//       "600": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr5gfhTNmqVU7y6l.ttf",
//       "700": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr5ZfhTNmqVU7y6l.ttf",
//       "800": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr4-fhTNmqVU7y6l.ttf",
//       "900": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr4XfhTNmqVU7y6l.ttf",
//       "regular": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eRTNmqVU7y6l.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifgurmukhi/v20/92z-tA9LNqsg7tCYlXdCV1VPnAEeDU0vLoYMbylXk0xTCr6-eSTMkKE.ttf"
//   },
//   {
//     "family": "Noto Serif Makasar",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "makasar"
//     ],
//     "version": "v1",
//     "lastModified": "2023-06-30",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifmakasar/v1/memjYbqtyH-NiZpFH_9zcvB_PqkfY9S7j4HTVSmevw.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifmakasar/v1/memjYbqtyH-NiZpFH_9zcvB_PqkfY9SLjovX.ttf"
//   },
//   {
//     "family": "Noto Sans Brahmi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "brahmi",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v19",
//     "lastModified": "2024-02-29",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansbrahmi/v19/vEFK2-VODB8RrNDvZSUmQQIIByV18tK1W77HtMo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbrahmi/v19/vEFK2-VODB8RrNDvZSUmQQIIByV1wtO_Xw.ttf"
//   },
//   {
//     "family": "Noto Sans Phags Pa",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "phags-pa"
//     ],
//     "version": "v15",
//     "lastModified": "2022-05-10",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansphagspa/v15/pxiZyoo6v8ZYyWh5WuPeJzMkd4SrGChkqkSsrvNXiA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansphagspa/v15/pxiZyoo6v8ZYyWh5WuPeJzMkd4SrGChUq06o.ttf"
//   },
//   {
//     "family": "Noto Sans Chorasmian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chorasmian",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v3",
//     "lastModified": "2024-01-26",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanschorasmian/v3/MQpL-X6uKMC7ROPLwRnI9ULxK_7NVkf8S5vyoH7w4g9b.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanschorasmian/v3/MQpL-X6uKMC7ROPLwRnI9ULxK_7NVkf8S6vzqno.ttf"
//   },
//   {
//     "family": "Noto Sans Tagbanwa",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tagbanwa"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstagbanwa/v18/Y4GWYbB8VTEp4t3MKJSMmQdIKjRtt_nZRjQEaYpGoQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstagbanwa/v18/Y4GWYbB8VTEp4t3MKJSMmQdIKjRtt_npRz4A.ttf"
//   },
//   {
//     "family": "Noto Sans New Tai Lue",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "new-tai-lue"
//     ],
//     "version": "v22",
//     "lastModified": "2023-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansnewtailue/v22/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pddfAYUbghFPKzeY.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansnewtailue/v22/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdTvHYUbghFPKzeY.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansnewtailue/v22/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdQLHYUbghFPKzeY.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansnewtailue/v22/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdeXAYUbghFPKzeY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnewtailue/v22/H4cKBW-Pl9DZ0Xe_nHUapt7PovLXAhAnY7wqaLy-OJgU3p_pdeXAUUfqgA.ttf"
//   },
//   {
//     "family": "Noto Sans Chakma",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "chakma",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanschakma/v17/Y4GQYbJ8VTEp4t3MKJSMjg5OIzhi4JjTQhYBeYo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanschakma/v17/Y4GQYbJ8VTEp4t3MKJSMjg5OIzhi0JnZRg.ttf"
//   },
//   {
//     "family": "Noto Sans Cham",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "cham",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v30",
//     "lastModified": "2023-11-28",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcER0cv7GykboaLg.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfckRwcv7GykboaLg.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcTxwcv7GykboaLg.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcIxwcv7GykboaLg.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfczxscv7GykboaLg.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfc9hscv7GykboaLg.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfckRscv7GykboaLg.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcuBscv7GykboaLg.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcERwcv7GykboaLg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscham/v30/pe06MIySN5pO62Z5YkFyQb_bbuRhe6D4yip43qfcERwsvru2.ttf"
//   },
//   {
//     "family": "Noto Sans Tai Tham",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tai-tham"
//     ],
//     "version": "v20",
//     "lastModified": "2023-09-14",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanstaitham/v20/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBBcbPgquyaRGKMw.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanstaitham/v20/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBPscPgquyaRGKMw.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanstaitham/v20/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBMIcPgquyaRGKMw.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanstaitham/v20/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBCUbPgquyaRGKMw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstaitham/v20/kJEbBv0U4hgtwxDUw2x9q7tbjLIfbPGHBoaVSAZ3MdLJBCUbDgukzQ.ttf"
//   },
//   {
//     "family": "Noto Serif Old Uyghur",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-uyghur"
//     ],
//     "version": "v3",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifolduyghur/v3/v6-KGZbLJFKIhClqUYqXDiGnrVoFRCW6JdwnKumeF2yVgA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifolduyghur/v3/v6-KGZbLJFKIhClqUYqXDiGnrVoFRCW6JdwXK-Oa.ttf"
//   },
//   {
//     "family": "Noto Sans Lisu",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "lisu"
//     ],
//     "version": "v25",
//     "lastModified": "2023-03-09",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanslisu/v25/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP61wt29IlxkVdig.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanslisu/v25/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHPB1st29IlxkVdig.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanslisu/v25/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHPPlst29IlxkVdig.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanslisu/v25/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP2Vwt29IlxkVdig.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslisu/v25/uk-3EGO3o6EruUbnwovcYhz6kh57_nqbcTdjJnHP2Vwd2tgh.ttf"
//   },
//   {
//     "family": "Noto Sans Indic Siyaq Numbers",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "indic-siyaq-numbers",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-17",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansindicsiyaqnumbers/v16/6xK5dTJFKcWIu4bpRBjRZRpsIYHabOeZ8UZLubTzpXNHKx2WPOpVd5Iu.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansindicsiyaqnumbers/v16/6xK5dTJFKcWIu4bpRBjRZRpsIYHabOeZ8UZLubTzpXNHKy2XNu4.ttf"
//   },
//   {
//     "family": "Yuji Hentaigana Akebono",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v12",
//     "lastModified": "2023-05-31",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/yujihentaiganaakebono/v12/EJRGQhkhRNwM-RtitGUwh930GU_f5KAlkuL0wQy9NKXRzrrF.ttf"
//     },
//     "category": "handwriting",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/yujihentaiganaakebono/v12/EJRGQhkhRNwM-RtitGUwh930GU_f5KAlkuL0wTy8PqE.ttf"
//   },
//   {
//     "family": "Padyakke Expanded One",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "kannada",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v6",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/padyakkeexpandedone/v6/K2FvfY9El_tbR0JfHb6WWvrBaU6XAUvC4IAYOKRkpDjeoQ.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/padyakkeexpandedone/v6/K2FvfY9El_tbR0JfHb6WWvrBaU6XAUvC4IAoOa5g.ttf"
//   },
//   {
//     "family": "Noto Sans Inscriptional Pahlavi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "inscriptional-pahlavi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-17",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansinscriptionalpahlavi/v16/ll8UK3GaVDuxR-TEqFPIbsR79Xxz9WEKbwsjpz7VklYlC7FCVtqVOAYK0QA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansinscriptionalpahlavi/v16/ll8UK3GaVDuxR-TEqFPIbsR79Xxz9WEKbwsjpz7VklYlC7FCZtufPA.ttf"
//   },
//   {
//     "family": "Noto Sans Kawi",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "kawi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v3",
//     "lastModified": "2023-10-25",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanskawi/v3/92zBtBJLNqsg7tCciW0EPHNNh1ZgbtGWiTYDjvnK0ghmCpRyMjXVsQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanskawi/v3/92zBtBJLNqsg7tCciW0EPHNNh1ZgbtGWiTYDjvnKPg9mCpRyMjXVsQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanskawi/v3/92zBtBJLNqsg7tCciW0EPHNNh1ZgbtGWiTYDjvnKBw9mCpRyMjXVsQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanskawi/v3/92zBtBJLNqsg7tCciW0EPHNNh1ZgbtGWiTYDjvnK4AhmCpRyMjXVsQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskawi/v3/92zBtBJLNqsg7tCciW0EPHNNh1ZgbtGWiTYDjvnK4AhWC552.ttf"
//   },
//   {
//     "family": "Noto Sans Vai",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "vai"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-28",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansvai/v17/NaPecZTSBuhTirw6IaFn_UrURMTsDIRSfr0.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansvai/v17/NaPecZTSBuhTirw6IaFn_UrUdMXmCA.ttf"
//   },
//   {
//     "family": "Noto Sans Old North Arabian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-north-arabian"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansoldnortharabian/v16/esDF30BdNv-KYGGJpKGk2tNiMt7Jar6olZDyNdr81zBQmUo_xw4ABw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoldnortharabian/v16/esDF30BdNv-KYGGJpKGk2tNiMt7Jar6olZDyNdr81zBgmEA7.ttf"
//   },
//   {
//     "family": "Noto Sans Tirhuta",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tirhuta"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstirhuta/v16/t5t6IQYRNJ6TWjahPR6X-M-apUyby7uGUBsTrn5P.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstirhuta/v16/t5t6IQYRNJ6TWjahPR6X-M-apUyby4uHWh8.ttf"
//   },
//   {
//     "family": "Noto Sans Zanabazar Square",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "zanabazar-square"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanszanabazarsquare/v19/Cn-jJsuGWQxOjaGwMQ6fOicyxLBEMRfDtkzl4uagQtJxOCEgN0Gc.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanszanabazarsquare/v19/Cn-jJsuGWQxOjaGwMQ6fOicyxLBEMRfDtkzl4uagQuJwMiU.ttf"
//   },
//   {
//     "family": "Noto Sans Multani",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "multani"
//     ],
//     "version": "v20",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmultani/v20/9Bty3ClF38_RfOpe1gCaZ8p30BOFO1A0pfCs5Kos.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmultani/v20/9Bty3ClF38_RfOpe1gCaZ8p30BOFO2A1r_Q.ttf"
//   },
//   {
//     "family": "Noto Sans Saurashtra",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "saurashtra"
//     ],
//     "version": "v23",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssaurashtra/v23/ea8GacQ0Wfz_XKWXe6OtoA8w8zvmYwTef9ndjhPTSIx9.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssaurashtra/v23/ea8GacQ0Wfz_XKWXe6OtoA8w8zvmYwTef-nchBc.ttf"
//   },
//   {
//     "family": "Noto Sans Pahawh Hmong",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "pahawh-hmong"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanspahawhhmong/v18/bWtp7e_KfBziStx7lIzKKaMUOBEA3UPQDW7krzc_c48aMpM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanspahawhhmong/v18/bWtp7e_KfBziStx7lIzKKaMUOBEA3UPQDW7knzY1dw.ttf"
//   },
//   {
//     "family": "Noto Sans Tai Le",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "tai-le"
//     ],
//     "version": "v17",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstaile/v17/vEFK2-VODB8RrNDvZSUmVxEATwR58tK1W77HtMo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstaile/v17/vEFK2-VODB8RrNDvZSUmVxEATwR5wtO_Xw.ttf"
//   },
//   {
//     "family": "Noto Sans Sundanese",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sundanese"
//     ],
//     "version": "v25",
//     "lastModified": "2024-01-26",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanssundanese/v25/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxbNNHCizv7fQES.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanssundanese/v25/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6cty3M9HCizv7fQES.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanssundanese/v25/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctyOM9HCizv7fQES.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanssundanese/v25/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxpNNHCizv7fQES.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssundanese/v25/FwZw7_84xUkosG2xJo2gm7nFwSLQkdymq2mkz3Gz1_b6ctxpNOHDgT8.ttf"
//   },
//   {
//     "family": "Noto Sans Hanifi Rohingya",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "hanifi-rohingya",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v28",
//     "lastModified": "2023-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosanshanifirohingya/v28/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYq4j6vvcudK8rN.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanshanifirohingya/v28/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIbG5T6vvcudK8rN.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanshanifirohingya/v28/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIb_5T6vvcudK8rN.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanshanifirohingya/v28/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYY4j6vvcudK8rN.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanshanifirohingya/v28/5h17iYsoOmIC3Yu3MDXLDw3UZCgghyOEBBY7hhLNyo3tiaiuSIAqrIYY4g6ut88.ttf"
//   },
//   {
//     "family": "Noto Sans Duployan",
//     "variants": [
//       "regular",
//       "700"
//     ],
//     "subsets": [
//       "duployan",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-06-22",
//     "files": {
//       "700": "https://fonts.gstatic.com/s/notosansduployan/v17/gokwH7nwAEdtF9N8-mdTDx_X9JM5wsvTqu0D4U0ftZS-Iw.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansduployan/v17/gokzH7nwAEdtF9N8-mdTDx_X9JM5wsvrFsIn6WYDvA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansduployan/v17/gokzH7nwAEdtF9N8-mdTDx_X9JM5wsvbF8gj.ttf"
//   },
//   {
//     "family": "Noto Sans Old Turkic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-turkic"
//     ],
//     "version": "v17",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansoldturkic/v17/yMJNMJVya43H0SUF_WmcGEQVqoEMKDKbsE2RjEw-Vyws.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoldturkic/v17/yMJNMJVya43H0SUF_WmcGEQVqoEMKDKbsH2Qhkg.ttf"
//   },
//   {
//     "family": "Noto Sans Cuneiform",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cuneiform",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanscuneiform/v17/bMrrmTWK7YY-MF22aHGGd7H8PhJtvBDWgb9JlRQueeQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscuneiform/v17/bMrrmTWK7YY-MF22aHGGd7H8PhJtvBDWsb5DkQ.ttf"
//   },
//   {
//     "family": "Noto Sans Limbu",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "limbu"
//     ],
//     "version": "v22",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanslimbu/v22/3JnlSDv90Gmq2mrzckOBBRRoNJVj0MF3OHRDnA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslimbu/v22/3JnlSDv90Gmq2mrzckOBBRRoNJVT0ctz.ttf"
//   },
//   {
//     "family": "Narnoor",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800"
//     ],
//     "subsets": [
//       "gunjala-gondi",
//       "latin",
//       "latin-ext",
//       "math",
//       "symbols"
//     ],
//     "version": "v7",
//     "lastModified": "2023-11-28",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_N2CRGEsnIJkWL4.ttf",
//       "600": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_PGFRGEsnIJkWL4.ttf",
//       "700": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_JWERGEsnIJkWL4.ttf",
//       "800": "https://fonts.gstatic.com/s/narnoor/v7/cIf4MaFWuVo-UTyP_ImHRGEsnIJkWL4.ttf",
//       "regular": "https://fonts.gstatic.com/s/narnoor/v7/cIf9MaFWuVo-UTyPxCmrYGkHgIs.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/narnoor/v7/cIf9MaFWuVo-UTyP9CihZA.ttf"
//   },
//   {
//     "family": "Noto Sans Kaithi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "kaithi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2024-02-29",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanskaithi/v21/buEtppS9f8_vkXadMBJJu0tWjLwjQi0KdoZIKlo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskaithi/v21/buEtppS9f8_vkXadMBJJu0tWjLwjciwAcg.ttf"
//   },
//   {
//     "family": "Noto Sans Siddham",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "siddham"
//     ],
//     "version": "v20",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssiddham/v20/OZpZg-FwqiNLe9PELUikxTWDoCCeGqndk3Ic92ZH.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssiddham/v20/OZpZg-FwqiNLe9PELUikxTWDoCCeGpncmXY.ttf"
//   },
//   {
//     "family": "Noto Sans Lycian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "lycian"
//     ],
//     "version": "v15",
//     "lastModified": "2022-05-10",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanslycian/v15/QldVNSNMqAsHtsJ7UmqxBQA9r8wA5_naCJwn00E.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslycian/v15/QldVNSNMqAsHtsJ7UmqxBQA9r8wA1_jQDA.ttf"
//   },
//   {
//     "family": "Noto Serif Khitan Small Script",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khitan-small-script",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v4",
//     "lastModified": "2023-08-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifkhitansmallscript/v4/jizzRFVKsm4Bt9PrbSzC4KLlQUF5lRJg5j-l5PvyhfTdd4TsZ8lb39iddA.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifkhitansmallscript/v4/jizzRFVKsm4Bt9PrbSzC4KLlQUF5lRJg5j-l5PvyhfTdd4TcZsNf.ttf"
//   },
//   {
//     "family": "Noto Sans Takri",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "takri"
//     ],
//     "version": "v24",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanstakri/v24/TuGJUVpzXI5FBtUq5a8bnKIOdTwQNO_W3khJXg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanstakri/v24/TuGJUVpzXI5FBtUq5a8bnKIOdTwgNeXS.ttf"
//   },
//   {
//     "family": "Noto Serif Dogra",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "dogra",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v23",
//     "lastModified": "2023-11-28",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notoserifdogra/v23/MQpP-XquKMC7ROPP3QOOlm7xPu3fGy63IbPzkns.ttf"
//     },
//     "category": "serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notoserifdogra/v23/MQpP-XquKMC7ROPP3QOOlm7xPu3fKy-9JQ.ttf"
//   },
//   {
//     "family": "Noto Sans Wancho",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "wancho"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-28",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanswancho/v17/zrf-0GXXyfn6Fs0lH9P4cUubP0GBqAPopiRfKp8.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanswancho/v17/zrf-0GXXyfn6Fs0lH9P4cUubP0GBmALiog.ttf"
//   },
//   {
//     "family": "Noto Sans Grantha",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "grantha",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-28",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansgrantha/v17/3y976akwcCjmsU8NDyrKo3IQfQ4o-r8cFeulHc6N.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansgrantha/v17/3y976akwcCjmsU8NDyrKo3IQfQ4o-o8dH-8.ttf"
//   },
//   {
//     "family": "Noto Sans Runic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "runic"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansrunic/v17/H4c_BXWPl9DZ0Xe_nHUaus7W68WWaxpvHtgIYg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansrunic/v17/H4c_BXWPl9DZ0Xe_nHUaus7W68WmahBr.ttf"
//   },
//   {
//     "family": "Noto Sans Hatran",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "hatran",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanshatran/v16/A2BBn4Ne0RgnVF3Lnko-0sOBIfL_mM83r1nwzDs.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanshatran/v16/A2BBn4Ne0RgnVF3Lnko-0sOBIfL_qM49qw.ttf"
//   },
//   {
//     "family": "Noto Sans Psalter Pahlavi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "psalter-pahlavi"
//     ],
//     "version": "v16",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanspsalterpahlavi/v16/rP2Vp3K65FkAtHfwd-eISGznYihzggmsicPfud3w1G3KsUQBct4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanspsalterpahlavi/v16/rP2Vp3K65FkAtHfwd-eISGznYihzggmsicPfud3w5GzAtQ.ttf"
//   },
//   {
//     "family": "Noto Sans Cypriot",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cypriot",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanscypriot/v19/8AtzGta9PYqQDjyp79a6f8Cj-3a3cxIsK5MPpahF.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscypriot/v19/8AtzGta9PYqQDjyp79a6f8Cj-3a3cyItIZc.ttf"
//   },
//   {
//     "family": "Noto Sans Palmyrene",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "palmyrene"
//     ],
//     "version": "v16",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanspalmyrene/v16/ZgNPjOdKPa7CHqq0h37c_ASCWvH93SFCPnK5ZpdNtcA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanspalmyrene/v16/ZgNPjOdKPa7CHqq0h37c_ASCWvH93SFCDnOzYg.ttf"
//   },
//   {
//     "family": "Noto Sans Osage",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "osage"
//     ],
//     "version": "v18",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansosage/v18/oPWX_kB6kP4jCuhpgEGmw4mtAVtXRlaSxkrMCQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansosage/v18/oPWX_kB6kP4jCuhpgEGmw4mtAVtnR1yW.ttf"
//   },
//   {
//     "family": "Noto Sans Inscriptional Parthian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "inscriptional-parthian",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansinscriptionalparthian/v16/k3k7o-IMPvpLmixcA63oYi-yStDkgXuXncL7dzfW3P4TAJ2yklBJ2jNkLlLr.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansinscriptionalparthian/v16/k3k7o-IMPvpLmixcA63oYi-yStDkgXuXncL7dzfW3P4TAJ2ykmBI0Dc.ttf"
//   },
//   {
//     "family": "Noto Sans Mahajani",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "mahajani"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmahajani/v19/-F6sfiVqLzI2JPCgQBnw60Agp0JrvD5Fh8ARHNh4zg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmahajani/v19/-F6sfiVqLzI2JPCgQBnw60Agp0JrvD51hsoV.ttf"
//   },
//   {
//     "family": "Noto Sans Syriac Eastern",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "syriac"
//     ],
//     "version": "v1",
//     "lastModified": "2023-07-13",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPi-eszCL5ep1QPQ.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPq-fszCL5ep1QPQ.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPnGfszCL5ep1QPQ.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPh2fszCL5ep1QPQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPvGYszCL5ep1QPQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPsiYszCL5ep1QPQ.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPq-YszCL5ep1QPQ.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPoaYszCL5ep1QPQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPi-fszCL5ep1QPQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssyriaceastern/v1/Noac6Vj_wIWFbTTCrYmvy8AjVU8aslWRHHvRYxS-Ro3yS0FDacnHPi-fgzGB4Q.ttf"
//   },
//   {
//     "family": "Noto Sans Caucasian Albanian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "caucasian-albanian",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanscaucasianalbanian/v18/nKKA-HM_FYFRJvXzVXaANsU0VzsAc46QGOkWytlTs-TXrYDmoVmRSZo.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanscaucasianalbanian/v18/nKKA-HM_FYFRJvXzVXaANsU0VzsAc46QGOkWytlTs-TXnYHspQ.ttf"
//   },
//   {
//     "family": "Chokokutai",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "japanese",
//       "latin",
//       "latin-ext",
//       "vietnamese"
//     ],
//     "version": "v9",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/chokokutai/v9/kmK4Zqw4HwvCeHGM8Fws9y7ypu1Kr7I.ttf"
//     },
//     "category": "display",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/chokokutai/v9/kmK4Zqw4HwvCeHGM8Fwsxy_4og.ttf"
//   },
//   {
//     "family": "Noto Sans Linear B",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "linear-b"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanslinearb/v17/HhyJU4wt9vSgfHoORYOiXOckKNB737IV3BkFTq4EPw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanslinearb/v17/HhyJU4wt9vSgfHoORYOiXOckKNB737Il3RMB.ttf"
//   },
//   {
//     "family": "Noto Sans Phoenician",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "phoenician"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansphoenician/v17/jizFRF9Ksm4Bt9PvcTaEkIHiTVtxmFtS5X7Jot-p5561.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansphoenician/v17/jizFRF9Ksm4Bt9PvcTaEkIHiTVtxmFtS5U7IqNs.ttf"
//   },
//   {
//     "family": "Noto Sans Warang Citi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "warang-citi"
//     ],
//     "version": "v17",
//     "lastModified": "2022-09-28",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanswarangciti/v17/EYqtmb9SzL1YtsZSScyKDXIeOv3w-zgsNvKRpeVCCXzdgA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanswarangciti/v17/EYqtmb9SzL1YtsZSScyKDXIeOv3w-zgsNvKhpO9G.ttf"
//   },
//   {
//     "family": "Noto Sans Newa",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "newa"
//     ],
//     "version": "v16",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansnewa/v16/7r3fqXp6utEsO9pI4f8ok8sWg8n_qN4R5lNU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnewa/v16/7r3fqXp6utEsO9pI4f8ok8sWg_n-oto.ttf"
//   },
//   {
//     "family": "Noto Sans Khudawadi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "khudawadi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanskhudawadi/v21/fdNi9t6ZsWBZ2k5ltHN73zZ5hc8HANlHIjRnVVXz9MY.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskhudawadi/v21/fdNi9t6ZsWBZ2k5ltHN73zZ5hc8HANlHEjVtUQ.ttf"
//   },
//   {
//     "family": "Noto Sans Gunjala Gondi",
//     "variants": [
//       "regular",
//       "500",
//       "600",
//       "700"
//     ],
//     "subsets": [
//       "gunjala-gondi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v19",
//     "lastModified": "2023-09-27",
//     "files": {
//       "500": "https://fonts.gstatic.com/s/notosansgunjalagondi/v19/bWtX7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5g0ef3PLtymzNxYL04DE4J4vCTxEJQ.ttf",
//       "600": "https://fonts.gstatic.com/s/notosansgunjalagondi/v19/bWtX7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5g0ef3PLtymzNxYLP4fE4J4vCTxEJQ.ttf",
//       "700": "https://fonts.gstatic.com/s/notosansgunjalagondi/v19/bWtX7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5g0ef3PLtymzNxYLBofE4J4vCTxEJQ.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosansgunjalagondi/v19/bWtX7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5g0ef3PLtymzNxYL4YDE4J4vCTxEJQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansgunjalagondi/v19/bWtX7e7KfBziStx7lIzKPrcSMwcEnCv6DW7n5g0ef3PLtymzNxYL4YD04ZQr.ttf"
//   },
//   {
//     "family": "Noto Sans Mayan Numerals",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "mayan-numerals"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-17",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmayannumerals/v16/PlIuFk25O6RzLfvNNVSivR09_KqYMwvvDKYjfIiE68oo6eepYQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmayannumerals/v16/PlIuFk25O6RzLfvNNVSivR09_KqYMwvvDKYjfIi06sAs.ttf"
//   },
//   {
//     "family": "Noto Sans Old Permic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "cyrillic-ext",
//       "latin",
//       "latin-ext",
//       "old-permic"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-13",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansoldpermic/v17/snf1s1q1-dF8pli1TesqcbUY4Mr-ElrwKLdXgv_dKYB5.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoldpermic/v17/snf1s1q1-dF8pli1TesqcbUY4Mr-ElrwKIdWiPs.ttf"
//   },
//   {
//     "family": "Noto Sans Syriac",
//     "variants": [
//       "100",
//       "200",
//       "300",
//       "regular",
//       "500",
//       "600",
//       "700",
//       "800",
//       "900"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "syriac"
//     ],
//     "version": "v16",
//     "lastModified": "2023-04-27",
//     "files": {
//       "100": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-VD9caJyZfUL_FC.ttf",
//       "200": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-XD9MaJyZfUL_FC.ttf",
//       "300": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-Ud9MaJyZfUL_FC.ttf",
//       "500": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-Vx9MaJyZfUL_FC.ttf",
//       "600": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-Wd88aJyZfUL_FC.ttf",
//       "700": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-Wk88aJyZfUL_FC.ttf",
//       "800": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-XD88aJyZfUL_FC.ttf",
//       "900": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-Xq88aJyZfUL_FC.ttf",
//       "regular": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-VD9MaJyZfUL_FC.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssyriac/v16/Ktk7AKuMeZjqPnXgyqribqzQqgW0LYiVqV7dXcP0C-VD9PaIw5M.ttf"
//   },
//   {
//     "family": "Noto Sans Mandaic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "mandaic"
//     ],
//     "version": "v16",
//     "lastModified": "2023-08-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmandaic/v16/cIfnMbdWt1w_HgCcilqhKQBo_OsMI5_A_gMk0izH.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmandaic/v16/cIfnMbdWt1w_HgCcilqhKQBo_OsMI6_B9Ac.ttf"
//   },
//   {
//     "family": "Noto Sans Bhaiksuki",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "bhaiksuki",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansbhaiksuki/v17/UcC63EosKniBH4iELXATsSBWdvUHXxhj8rLUdU4wh9U.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbhaiksuki/v17/UcC63EosKniBH4iELXATsSBWdvUHXxhjwrPecQ.ttf"
//   },
//   {
//     "family": "Noto Sans Buginese",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "buginese",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v18",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansbuginese/v18/esDM30ldNv-KYGGJpKGk18phe_7Da6_gtfuEXLmNtw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansbuginese/v18/esDM30ldNv-KYGGJpKGk18phe_7Da6_QtPGA.ttf"
//   },
//   {
//     "family": "Noto Sans Nabataean",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "nabataean"
//     ],
//     "version": "v16",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansnabataean/v16/IFS4HfVJndhE3P4b5jnZ34DfsjO330dNoBJ9hK8kMK4.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnabataean/v16/IFS4HfVJndhE3P4b5jnZ34DfsjO330dNkBN3gA.ttf"
//   },
//   {
//     "family": "Noto Sans Rejang",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "rejang"
//     ],
//     "version": "v21",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansrejang/v21/Ktk2AKuMeZjqPnXgyqrib7DIogqwN4O3WYZB_sU.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansrejang/v21/Ktk2AKuMeZjqPnXgyqrib7DIogqwB4K9XQ.ttf"
//   },
//   {
//     "family": "Noto Sans Sogdian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "sogdian"
//     ],
//     "version": "v16",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssogdian/v16/taiQGn5iC4--qtsfi4Jp6eHPnfxQBo--Pm6KHidM.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssogdian/v16/taiQGn5iC4--qtsfi4Jp6eHPnfxQBr-_NGo.ttf"
//   },
//   {
//     "family": "Noto Sans Mro",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "mro"
//     ],
//     "version": "v18",
//     "lastModified": "2022-12-08",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmro/v18/qWcsB6--pZv9TqnUQMhe9b39WDzRtjkho4M.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmro/v18/qWcsB6--pZv9TqnUQMhe9b39aD3bsg.ttf"
//   },
//   {
//     "family": "Noto Sans Shavian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "shavian"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansshavian/v17/CHy5V_HZE0jxJBQlqAeCKjJvQBNF4EFQSplv2Cwg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansshavian/v17/CHy5V_HZE0jxJBQlqAeCKjJvQBNF4HFRQJ0.ttf"
//   },
//   {
//     "family": "Noto Sans Ogham",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "ogham"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansogham/v17/kmKlZqk1GBDGN0mY6k5lmEmww4hrt5laQxcoCA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansogham/v17/kmKlZqk1GBDGN0mY6k5lmEmww4hbtpNe.ttf"
//   },
//   {
//     "family": "Noto Sans Kharoshthi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "kharoshthi",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanskharoshthi/v16/Fh4qPiLjKS30-P4-pGMMXCCfvkc5Vd7KE5z4rFyx5mR1.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanskharoshthi/v16/Fh4qPiLjKS30-P4-pGMMXCCfvkc5Vd7KE6z5plg.ttf"
//   },
//   {
//     "family": "Noto Sans Masaram Gondi",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "masaram-gondi"
//     ],
//     "version": "v17",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmasaramgondi/v17/6xK_dThFKcWIu4bpRBjRYRV7KZCbUq6n_1kPnuGe7RI9WSWX.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmasaramgondi/v17/6xK_dThFKcWIu4bpRBjRYRV7KZCbUq6n_1kPntGf5xY.ttf"
//   },
//   {
//     "family": "Noto Sans Soyombo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "soyombo"
//     ],
//     "version": "v17",
//     "lastModified": "2023-07-24",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssoyombo/v17/RWmSoL-Y6-8q5LTtXs6MF6q7xsxgY0FrIFOcK25W.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssoyombo/v17/RWmSoL-Y6-8q5LTtXs6MF6q7xsxgY3FqKlc.ttf"
//   },
//   {
//     "family": "Noto Sans Mende Kikakui",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "mende-kikakui"
//     ],
//     "version": "v28",
//     "lastModified": "2022-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmendekikakui/v28/11hRGoLHz17aKjQCWj-JHcLvu2Q5zZrnkbNCLUx_aDJLAHer.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmendekikakui/v28/11hRGoLHz17aKjQCWj-JHcLvu2Q5zZrnkbNCLXx-YjY.ttf"
//   },
//   {
//     "family": "Noto Sans Elymaic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "elymaic",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v17",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanselymaic/v17/UqyKK9YTJW5liNMhTMqe9vUFP65ZD4AjWOT0zi2V.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanselymaic/v17/UqyKK9YTJW5liNMhTMqe9vUFP65ZD7AiUuA.ttf"
//   },
//   {
//     "family": "Noto Sans Pau Cin Hau",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "pau-cin-hau"
//     ],
//     "version": "v20",
//     "lastModified": "2023-05-02",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanspaucinhau/v20/x3d-cl3IZKmUqiMg_9wBLLtzl22EayN7ehIdjEWqKMxsKw.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanspaucinhau/v20/x3d-cl3IZKmUqiMg_9wBLLtzl22EayN7ehItjU-u.ttf"
//   },
//   {
//     "family": "Noto Sans Old Sogdian",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "old-sogdian"
//     ],
//     "version": "v16",
//     "lastModified": "2023-06-22",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansoldsogdian/v16/3JnjSCH90Gmq2mrzckOBBhFhdrMst48aURt7neIqM-9uyg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansoldsogdian/v16/3JnjSCH90Gmq2mrzckOBBhFhdrMst48aURtLnOgu.ttf"
//   },
//   {
//     "family": "Noto Sans Meroitic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "meroitic",
//       "meroitic-cursive",
//       "meroitic-hieroglyphs"
//     ],
//     "version": "v18",
//     "lastModified": "2023-11-09",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmeroitic/v18/IFS5HfRJndhE3P4b5jnZ3ITPvC6i00UDgDhTiKY9KQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmeroitic/v18/IFS5HfRJndhE3P4b5jnZ3ITPvC6i00UzgTJX.ttf"
//   },
//   {
//     "family": "Noto Sans Nushu",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "nushu"
//     ],
//     "version": "v19",
//     "lastModified": "2023-04-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansnushu/v19/rnCw-xRQ3B7652emAbAe_Ai1IYaFWFAMArZKqQ.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnushu/v19/rnCw-xRQ3B7652emAbAe_Ai1IYa1WVoI.ttf"
//   },
//   {
//     "family": "Noto Sans Elbasan",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "elbasan",
//       "latin",
//       "latin-ext"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanselbasan/v16/-F6rfiZqLzI2JPCgQBnw400qp1trvHdlre4dFcFh.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanselbasan/v16/-F6rfiZqLzI2JPCgQBnw400qp1trvEdkp-o.ttf"
//   },
//   {
//     "family": "Noto Sans SignWriting",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "signwriting"
//     ],
//     "version": "v4",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosanssignwriting/v4/Noas6VX_wIWFbTTCrYmvy9A2UnkL-2SZAWiUEVCARYQemg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosanssignwriting/v4/Noas6VX_wIWFbTTCrYmvy9A2UnkL-2SZAWikEFqE.ttf"
//   },
//   {
//     "family": "Noto Sans Ugaritic",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "ugaritic"
//     ],
//     "version": "v16",
//     "lastModified": "2023-05-23",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansugaritic/v16/3qTwoiqhnSyU8TNFIdhZVCwbjCpkAXXkMhoIkiazfg.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansugaritic/v16/3qTwoiqhnSyU8TNFIdhZVCwbjCpkAXXUMxAM.ttf"
//   },
//   {
//     "family": "Noto Sans Manichaean",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "manichaean"
//     ],
//     "version": "v18",
//     "lastModified": "2023-10-25",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansmanichaean/v18/taiVGntiC4--qtsfi4Jp9-_GkPZZCcrfekqCNTtFCtdX.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansmanichaean/v18/taiVGntiC4--qtsfi4Jp9-_GkPZZCcrfenqDPz8.ttf"
//   },
//   {
//     "family": "Noto Sans NKo",
//     "variants": [
//       "regular"
//     ],
//     "subsets": [
//       "latin",
//       "latin-ext",
//       "nko"
//     ],
//     "version": "v6",
//     "lastModified": "2023-09-27",
//     "files": {
//       "regular": "https://fonts.gstatic.com/s/notosansnko/v6/esDX31ZdNv-KYGGJpKGk2_RpMpCMHMLBrdA.ttf"
//     },
//     "category": "sans-serif",
//     "kind": "webfonts#webfont",
//     "menu": "https://fonts.gstatic.com/s/notosansnko/v6/esDX31ZdNv-KYGGJpKGk2_RpApGGGA.ttf"
//   }
// ]
export const googleFonts = [
  "Roboto",
  "Open Sans",
  "Noto Sans JP",
  "Montserrat",
  "Poppins",
  "Lato",
  "Inter",
  "Roboto Condensed",
  "Oswald",
  "Roboto Mono",
  "Noto Sans",
  "Raleway",
  "Playfair Display",
  "Nunito Sans",
  "Ubuntu",
  "Rubik",
  "Nunito",
  "Merriweather",
  "Roboto Slab",
  "Noto Sans KR",
  "PT Sans",
  "Kanit",
  "Noto Sans TC",
  "Work Sans",
  "Lora",
  "Fira Sans",
  "DM Sans",
  "Mulish",
  "Barlow",
  "Quicksand",
  "PT Serif",
  "IBM Plex Sans",
  "Manrope",
  "Heebo",
  "Titillium Web",
  "Noto Serif",
  "Libre Franklin",
  "Karla",
  "Noto Sans SC",
  "Inconsolata",
  "Josefin Sans",
  "Noto Color Emoji",
  "Arimo",
  "Bebas Neue",
  "Libre Baskerville",
  "Nanum Gothic",
  "Dosis",
  "Mukta",
  "Material Symbols Outlined",
  "Anton",
  "PT Sans Narrow",
  "Abel",
  "Cabin",
  "Bitter",
  "Dancing Script",
  "Jost",
  "DM Serif Display",
  "Cairo",
  "Hind Siliguri",
  "Barlow Condensed",
  "Oxygen",
  "EB Garamond",
  "Teko",
  "Source Code Pro",
  "Mulish",
  "Exo 2",
  "Noto Serif JP",
  "Pacifico",
  "Hind",
  "Noto Sans HK",
  "Archivo",
  "Space Grotesk",
  "Assistant",
  "Comfortaa",
  "Crimson Text",
  "Lobster",
  "Fjalla One",
  "Sora",
  "Signika Negative",
  "Prompt",
  "M PLUS Rounded 1c",
  "Slabo 27px",
  "Public Sans",
  "Varela Round",
  "IBM Plex Mono",
  "Play",
  "Maven Pro",
  "Rajdhani",
  "Caveat",
  "Arvo",
  "Asap",
  "Red Hat Display",
  "Noto Sans Arabic",
  "Chakra Petch",
  "Overpass",
  "Cormorant Garamond",
  "Fira Sans Condensed",
  "Shadows Into Light",
  "Abril Fatface",
  "Tajawal",
  "Zilla Slab",
  "Figtree",
  "Satisfy",
  "Archivo Black",
  "Plus Jakarta Sans",
  "IBM Plex Serif",
  "IBM Plex Sans Arabic",
  "Signika",
  "Merriweather Sans",
  "Barlow Semi Condensed",
  "Domine",
  "Indie Flower",
  "Questrial",
  "Lilita One",
  "Permanent Marker",
  "Nanum Myeongjo",
  "Yanone Kaffeesatz",
  "Saira Condensed",
  "Lexend",
  "Almarai",
  "M PLUS 1p",
  "Catamaran",
  "Didact Gothic",
  "Black Ops One",
  "Russo One",
  "Rowdies",
  "Montserrat Alternates",
  "Orbitron",
  "Source Sans 3",
  "Hind Madurai",
  "Cinzel",
  "Archivo Narrow",
  "Sarabun",
  "Urbanist",
  "Acme",
  "Asap Condensed",
  "Vollkorn",
  "Roboto Flex",
  "Material Symbols Rounded",
  "Philosopher",
  "Exo",
  "Bree Serif",
  "Amatic SC",
  "Alegreya",
  "Frank Ruhl Libre",
  "Cormorant",
  "Alegreya Sans",
  "Alfa Slab One",
  "Source Serif 4",
  "Kalam",
  "Noto Serif TC",
  "Tinos",
  "Spectral",
  "Chivo",
  "ABeeZee",
  "Amiri",
  "Great Vibes",
  "Righteous",
  "Noto Sans Thai",
  "Noto Kufi Arabic",
  "Martel",
  "Changa",
  "Marcellus",
  "Concert One",
  "Lobster Two",
  "Passion One",
  "Courgette",
  "Patua One",
  "Prata",
  "Yellowtail",
  "Sawarabi Mincho",
  "Be Vietnam Pro",
  "Noticia Text",
  "Cantarell",
  "Cardo",
  "Mate",
  "Alata",
  "Ubuntu Condensed",
  "Alice",
  "El Messiri",
  "Noto Sans Display",
  "Ubuntu Mono",
  "IBM Plex Sans Condensed",
  "Crete Round",
  "Space Mono",
  "Arsenal",
  "Sacramento",
  "Khand",
  "PT Sans Caption",
  "Cookie",
  "Lexend Deca",
  "Bodoni Moda",
  "Noto Serif KR",
  "Bungee",
  "Kaushan Script",
  "Yantramanav",
  "Old Standard TT",
  "Neuton",
  "Saira",
  "Sawarabi Gothic",
  "Oleo Script",
  "Unna",
  "Gothic A1",
  "Inter Tight",
  "Francois One",
  "Merienda",
  "Josefin Slab",
  "Gloria Hallelujah",
  "Press Start 2P",
  "Pathway Gothic One",
  "Gruppo",
  "Encode Sans",
  "Crimson Pro",
  "Alegreya Sans SC",
  "Roboto Serif",
  "Mate SC",
  "Luckiest Guy",
  "Gelasio",
  "Rokkitt",
  "Quattrocento Sans",
  "Quattrocento",
  "Commissioner",
  "Antic Slab",
  "Zeyada",
  "Readex Pro",
  "Architects Daughter",
  "Unbounded",
  "Encode Sans Condensed",
  "Advent Pro",
  "Red Hat Text",
  "Literata",
  "Rubik Mono One",
  "Silkscreen",
  "Cuprum",
  "Noto Sans Bengali",
  "DM Serif Text",
  "Noto Naskh Arabic",
  "Paytone One",
  "Poiret One",
  "Secular One",
  "Special Elite",
  "Tangerine",
  "Fira Sans Extra Condensed",
  "Zen Kaku Gothic New",
  "Yeseva One",
  "Yatra One",
  "Sen",
  "Nanum Gothic Coding",
  "League Spartan",
  "Staatliches",
  "Sanchez",
  "Libre Barcode 39",
  "Tenor Sans",
  "Abhaya Libre",
  "Antonio",
  "Bangers",
  "Playfair Display SC",
  "Mitr",
  "Carter One",
  "Libre Caslon Text",
  "Allura",
  "Vidaloka",
  "Rubik Bubbles",
  "Itim",
  "Kosugi Maru",
  "Dela Gothic One",
  "Faustina",
  "Mukta Malar",
  "Aleo",
  "Zen Maru Gothic",
  "DM Mono",
  "News Cycle",
  "Ultra",
  "Courier Prime",
  "Fraunces",
  "Sorts Mill Goudy",
  "Viga",
  "Parisienne",
  "Gudea",
  "Londrina Solid",
  "Handlee",
  "Mada",
  "Amaranth",
  "Ropa Sans",
  "Patrick Hand",
  "Baloo 2",
  "Epilogue",
  "Titan One",
  "JetBrains Mono",
  "Bad Script",
  "Pragati Narrow",
  "Comic Neue",
  "Hind Vadodara",
  "Hammersmith One",
  "Sofia",
  "Adamina",
  "Saira Semi Condensed",
  "PT Mono",
  "Homemade Apple",
  "STIX Two Text",
  "Blinker",
  "Monoton",
  "Baskervville",
  "Volkhov",
  "Marck Script",
  "Cousine",
  "Unica One",
  "Playball",
  "Taviraj",
  "Lusitana",
  "Neucha",
  "Shippori Mincho",
  "Atkinson Hyperlegible",
  "Nanum Pen Script",
  "Castoro",
  "Voltaire",
  "Lalezar",
  "Pangolin",
  "Kumbh Sans",
  "Share Tech Mono",
  "Noto Serif Bengali",
  "Albert Sans",
  "Bai Jamjuree",
  "Six Caps",
  "BIZ UDPGothic",
  "Koulen",
  "Mandali",
  "Ruda",
  "Armata",
  "Audiowide",
  "Alex Brush",
  "Noto Serif SC",
  "Noto Sans Tamil",
  "Calistoga",
  "Jura",
  "Cabin Condensed",
  "League Gothic",
  "Sriracha",
  "Istok Web",
  "Monda",
  "Krub",
  "Actor",
  "David Libre",
  "Electrolize",
  "Forum",
  "Instrument Serif",
  "Mr Dafoe",
  "Sansita",
  "Rock Salt",
  "Fira Mono",
  "Anonymous Pro",
  "Gilda Display",
  "Cutive Mono",
  "Varela",
  "BenchNine",
  "Fredoka",
  "Nothing You Could Do",
  "Macondo",
  "VT323",
  "Pridi",
  "Geologica",
  "Syne",
  "Italianno",
  "Laila",
  "Leckerli One",
  "Reem Kufi",
  "Pinyon Script",
  "Pontano Sans",
  "Khula",
  "Quantico",
  "Niramit",
  "Material Symbols Sharp",
  "Noto Sans Mono",
  "Reenie Beanie",
  "Sofia Sans Condensed",
  "Jaldi",
  "Shrikhand",
  "Black Han Sans",
  "Palanquin",
  "Damion",
  "Julius Sans One",
  "Eczar",
  "Days One",
  "Fugaz One",
  "Playfair",
  "Amita",
  "Martel Sans",
  "Saira Extra Condensed",
  "Creepster",
  "Noto Sans Malayalam",
  "Charm",
  "Rufina",
  "Sarala",
  "Gochi Hand",
  "Newsreader",
  "Alef",
  "GFS Didot",
  "Julee",
  "Alatsi",
  "Basic",
  "Petrona",
  "Ramabhadra",
  "Covered By Your Grace",
  "Holtwood One SC",
  "Economica",
  "Rammetto One",
  "Yrsa",
  "Lemon",
  "Lemonada",
  "Karma",
  "Cinzel Decorative",
  "Quintessential",
  "Glegoo",
  "Noto Sans Hebrew",
  "Rubik Moonrocks",
  "Antic",
  "Cabin Sketch",
  "Pathway Extreme",
  "Short Stack",
  "Oranienbaum",
  "Berkshire Swash",
  "Salsa",
  "Averia Serif Libre",
  "Sofia Sans",
  "Alumni Sans",
  "Aclonica",
  "Palanquin Dark",
  "Alexandria",
  "Hind Guntur",
  "Scada",
  "Caveat Brush",
  "Chewy",
  "Noto Serif Devanagari",
  "Kurale",
  "Syncopate",
  "Fredericka the Great",
  "Candal",
  "PT Serif Caption",
  "Libre Bodoni",
  "Arapey",
  "Telex",
  "Darker Grotesque",
  "Changa One",
  "Big Shoulders Display",
  "Graduate",
  "Kreon",
  "Lustria",
  "Athiti",
  "Racing Sans One",
  "Squada One",
  "Coda",
  "Mali",
  "Belleza",
  "Noto Sans Devanagari",
  "Michroma",
  "Chonburi",
  "Cormorant Infant",
  "Nanum Brush Script",
  "Sintony",
  "Fira Code",
  "K2D",
  "Vazirmatn",
  "Akshar",
  "Kiwi Maru",
  "Hanken Grotesk",
  "Arizonia",
  "Zen Old Mincho",
  "Rozha One",
  "Trocchi",
  "Boogaloo",
  "Allerta Stencil",
  "BioRhyme",
  "Seaweed Script",
  "Inria Sans",
  "Alike",
  "Ibarra Real Nova",
  "Pirata One",
  "Trirong",
  "Annie Use Your Telescope",
  "Tillana",
  "Bellefair",
  "Do Hyeon",
  "Oxanium",
  "Shadows Into Light Two",
  "Grandstander",
  "Zen Antique",
  "Herr Von Muellerhoff",
  "Yesteryear",
  "Contrail One",
  "Marcellus SC",
  "Bevan",
  "Golos Text",
  "Hanuman",
  "Rye",
  "Jua",
  "Noto Serif Display",
  "Aldrich",
  "Cantata One",
  "Suez One",
  "Pattaya",
  "Marmelad",
  "M PLUS 1",
  "Brygada 1918",
  "Ephesis",
  "Henny Penny",
  "Averia Libre",
  "Markazi Text",
  "Allerta",
  "IBM Plex Sans Thai",
  "Mrs Saint Delafield",
  "Caudex",
  "B612 Mono",
  "Georama",
  "Livvic",
  "Arbutus Slab",
  "Young Serif",
  "Krona One",
  "Wallpoet",
  "Biryani",
  "Niconne",
  "Fanwood Text",
  "Just Another Hand",
  "Limelight",
  "Balsamiq Sans",
  "Knewave",
  "Cedarville Cursive",
  "Overlock",
  "Coming Soon",
  "Rancho",
  "Spinnaker",
  "Odibee Sans",
  "Baloo Bhaijaan 2",
  "Petit Formal Script",
  "Podkova",
  "Bubblegum Sans",
  "Corben",
  "Carrois Gothic",
  "Manjari",
  "Nobile",
  "B612",
  "Vollkorn SC",
  "Aladin",
  "Kristi",
  "Halant",
  "DotGothic16",
  "Ovo",
  "Irish Grover",
  "Grand Hotel",
  "Metrophobic",
  "Miriam Libre",
  "Baloo Da 2",
  "Amiko",
  "Alegreya SC",
  "Magra",
  "Glory",
  "Klee One",
  "Shippori Mincho B1",
  "Arya",
  "NTR",
  "La Belle Aurore",
  "Overpass Mono",
  "Grenze Gotisch",
  "Encode Sans Semi Condensed",
  "Rochester",
  "Delius",
  "Allison",
  "Coustard",
  "Cormorant Upright",
  "Maitree",
  "Gurajada",
  "Fahkwang",
  "Bungee Inline",
  "Average",
  "Fjord One",
  "Kosugi",
  "Kameron",
  "Average Sans",
  "Enriqueta",
  "Mallanna",
  "Mina",
  "Norican",
  "Big Shoulders Text",
  "IBM Plex Sans KR",
  "Bowlby One SC",
  "Sulphur Point",
  "Della Respira",
  "Lateef",
  "Homenaje",
  "Bungee Shade",
  "Jockey One",
  "Judson",
  "Turret Road",
  "Mansalva",
  "Freehand",
  "Rosario",
  "Montserrat Subrayada",
  "Noto Nastaliq Urdu",
  "Rambla",
  "Spectral SC",
  "Nixie One",
  "Gabarito",
  "Monsieur La Doulaise",
  "Oxygen Mono",
  "IM Fell English SC",
  "Stardos Stencil",
  "Rasa",
  "Italiana",
  "Amethysta",
  "Thasadith",
  "Style Script",
  "Gabriela",
  "Trykker",
  "Cambay",
  "IM Fell English",
  "Tomorrow",
  "Kaisei Decol",
  "Sniglet",
  "Schoolbell",
  "Sedgwick Ave",
  "Calligraffitti",
  "Baloo Thambi 2",
  "Quando",
  "Slabo 13px",
  "Zen Kaku Gothic Antique",
  "Bowlby One",
  "Gloock",
  "Baloo Tamma 2",
  "Fondamento",
  "Andika",
  "Alike Angular",
  "Proza Libre",
  "RocknRoll One",
  "Chivo Mono",
  "Jomhuria",
  "Share",
  "Cutive",
  "Aref Ruqaa",
  "Eater",
  "IM Fell DW Pica",
  "Cormorant SC",
  "Bentham",
  "Marvel",
  "Sigmar One",
  "Qwigley",
  "Solway",
  "Aboreto",
  "Dawning of a New Day",
  "Fasthand",
  "Vesper Libre",
  "Hahmlet",
  "Fauna One",
  "Rakkas",
  "Sansita Swashed",
  "Goudy Bookletter 1911",
  "Gugi",
  "Capriola",
  "Sunflower",
  "Andada Pro",
  "Mountains of Christmas",
  "Chelsea Market",
  "Hepta Slab",
  "Headland One",
  "Zen Kurenaido",
  "Brawler",
  "Anuphan",
  "Nova Square",
  "ZCOOL XiaoWei",
  "Antic Didone",
  "Pavanam",
  "KoHo",
  "Molengo",
  "Duru Sans",
  "Zen Antique Soft",
  "UnifrakturMaguntia",
  "Baloo Paaji 2",
  "Original Surfer",
  "Waiting for the Sunrise",
  "M PLUS 2",
  "Oregano",
  "Love Ya Like A Sister",
  "Loved by the King",
  "Radley",
  "Encode Sans Semi Expanded",
  "Oleo Script Swash Caps",
  "Ms Madi",
  "Modak",
  "Goblin One",
  "Fresca",
  "Baloo Chettan 2",
  "Bellota Text",
  "Amarante",
  "Gafata",
  "Kadwa",
  "Poly",
  "Lexend Zetta",
  "Abyssinica SIL",
  "Croissant One",
  "Rouge Script",
  "Instrument Sans",
  "Esteban",
  "Germania One",
  "Kufam",
  "Asul",
  "Copse",
  "Londrina Outline",
  "Gotu",
  "Padauk",
  "Supermercado One",
  "Buenard",
  "Meddon",
  "Hi Melody",
  "Inder",
  "Federo",
  "Anek Malayalam",
  "Cambo",
  "Besley",
  "Inknut Antiqua",
  "Sofia Sans Extra Condensed",
  "Yusei Magic",
  "Notable",
  "Oooh Baby",
  "Kodchasan",
  "Ledger",
  "Bakbak One",
  "Ma Shan Zheng",
  "Fuggles",
  "Mukta Vaani",
  "Radio Canada",
  "Caladea",
  "Tenali Ramakrishna",
  "Varta",
  "Azeret Mono",
  "Goldman",
  "Suranna",
  "Tektur",
  "Syne Mono",
  "Red Rose",
  "Schibsted Grotesk",
  "Sail",
  "Harmattan",
  "Wix Madefor Display",
  "Skranji",
  "Prosto One",
  "Meera Inimai",
  "Gravitas One",
  "Happy Monkey",
  "Anaheim",
  "Noto Sans Georgian",
  "Hurricane",
  "Mouse Memoirs",
  "Recursive",
  "Saira Stencil One",
  "BhuTuka Expanded One",
  "Carme",
  "Overlock SC",
  "Megrim",
  "Lexend Exa",
  "Elsie",
  "Aguafina Script",
  "Libre Barcode 128",
  "BIZ UDGothic",
  "Sue Ellen Francisco",
  "Share Tech",
  "Flamenco",
  "Xanh Mono",
  "Mr De Haviland",
  "Noto Sans Sinhala",
  "Shalimar",
  "Inria Serif",
  "Coiny",
  "Wendy One",
  "Averia Sans Libre",
  "Familjen Grotesk",
  "Pompiere",
  "Noto Sans Myanmar",
  "Galada",
  "Wix Madefor Text",
  "Bayon",
  "McLaren",
  "Mirza",
  "Geo",
  "Euphoria Script",
  "Poller One",
  "Metamorphous",
  "Clicker Script",
  "Cormorant Unicase",
  "Battambang",
  "Nova Mono",
  "Mochiy Pop One",
  "Alkatra",
  "Lily Script One",
  "Give You Glory",
  "Montez",
  "Gaegu",
  "Convergence",
  "Baloo Bhai 2",
  "Balthazar",
  "Tienne",
  "Comforter Brush",
  "ZCOOL QingKe HuangYou",
  "Gantari",
  "Libre Caslon Display",
  "Ceviche One",
  "Mako",
  "Just Me Again Down Here",
  "Delius Unicase",
  "Kaisei Opti",
  "Tiro Devanagari Hindi",
  "Vast Shadow",
  "Walter Turncoat",
  "Fragment Mono",
  "Montaga",
  "Noto Sans Kannada",
  "Codystar",
  "MuseoModerno",
  "Hina Mincho",
  "Chau Philomene One",
  "Timmana",
  "Anek Tamil",
  "Noto Sans NKo Unjoined",
  "Kelly Slab",
  "Libre Barcode 39 Extended Text",
  "Kdam Thmor Pro",
  "Farro",
  "Encode Sans Expanded",
  "Dokdo",
  "Noto Sans Armenian",
  "Lexend Peta",
  "Tiro Kannada",
  "Reggae One",
  "Baumans",
  "Allan",
  "BIZ UDPMincho",
  "Numans",
  "Frijole",
  "Over the Rainbow",
  "Mukta Mahee",
  "Patrick Hand SC",
  "Mogra",
  "Tilt Neon",
  "Orelega One",
  "Gluten",
  "Imprima",
  "Galdeano",
  "Emilys Candy",
  "Scope One",
  "Expletus Sans",
  "Life Savers",
  "Finger Paint",
  "Atma",
  "Ranga",
  "ZCOOL KuaiLe",
  "REM",
  "Metal Mania",
  "Bilbo Swash Caps",
  "Bellota",
  "Crafty Girls",
  "Birthstone",
  "Noto Serif Hebrew",
  "Doppio One",
  "Nokora",
  "Puritan",
  "Lekton",
  "Phudu",
  "Dynalight",
  "Belgrano",
  "Sarpanch",
  "Rampart One",
  "Corinthia",
  "Vibur",
  "Freckle Face",
  "Almendra",
  "Ruslan Display",
  "Ruthie",
  "Delius Swash Caps",
  "Alkalami",
  "Fontdiner Swanky",
  "IM Fell Double Pica",
  "Kaisei Tokumin",
  "Shojumaru",
  "Sumana",
  "Aoboshi One",
  "Charmonman",
  "Orienta",
  "Spline Sans",
  "Kenia",
  "IBM Plex Sans JP",
  "Cherry Swash",
  "Song Myung",
  "Noto Sans Symbols",
  "Chango",
  "Road Rage",
  "Gamja Flower",
  "Major Mono Display",
  "Miniver",
  "Artifika",
  "Edu SA Beginner",
  "Potta One",
  "Dongle",
  "Raleway Dots",
  "Faster One",
  "Monomaniac One",
  "Voces",
  "Sofia Sans Semi Condensed",
  "Poltawski Nowy",
  "Gowun Dodum",
  "Akaya Kanadaka",
  "Shantell Sans",
  "Unkempt",
  "Tilt Warp",
  "Noto Serif Georgian",
  "Onest",
  "Macondo Swash Caps",
  "Libre Barcode 39 Text",
  "Kranky",
  "Ribeye",
  "Katibeh",
  "Gorditas",
  "Jomolhari",
  "Manuale",
  "League Script",
  "Gayathri",
  "Stick",
  "Noto Sans Mongolian",
  "Noto Serif Thai",
  "Bigshot One",
  "Noto Sans Indic Siyaq Numbers",
  "Medula One",
  "Baloo Bhaina 2",
  "Stint Ultra Condensed",
  "Genos",
  "Peralta",
  "Prociono",
  "Slackey",
  "Rum Raisin",
  "Mohave",
  "Square Peg",
  "Gasoek One",
  "Noto Sans Tirhuta",
  "Anek Bangla",
  "New Rocker",
  "Kotta One",
  "Hachi Maru Pop",
  "Qwitcher Grypen",
  "Iceland",
  "Rubik Dirt",
  "Agbalumo",
  "Cherry Cream Soda",
  "Asar",
  "Caprasimo",
  "East Sea Dokdo",
  "Jolly Lodger",
  "Ranchers",
  "Iceberg",
  "Suwannaphum",
  "Trade Winds",
  "Rosarivo",
  "Gowun Batang",
  "Arima",
  "Port Lligat Sans",
  "Tauri",
  "Murecho",
  "Scheherazade New",
  "Zilla Slab Highlight",
  "Marhey",
  "Sura",
  "Nova Round",
  "ADLaM Display",
  "The Girl Next Door",
  "Nerko One",
  "Strait",
  "Akaya Telivigala",
  "Spicy Rice",
  "Nova Flat",
  "Mochiy Pop P One",
  "Zen Dots",
  "Glass Antiqua",
  "Noto Sans Khmer",
  "Barrio",
  "Vujahday Script",
  "Noto Serif Malayalam",
  "Wire One",
  "Kite One",
  "Sonsie One",
  "Noto Sans Telugu",
  "Anek Devanagari",
  "Uncial Antiqua",
  "Shippori Antique",
  "Sevillana",
  "Montagu Slab",
  "Meow Script",
  "Stylish",
  "Gemunu Libre",
  "Whisper",
  "Shanti",
  "WindSong",
  "Cute Font",
  "Bona Nova",
  "Ramaraja",
  "Lovers Quarrel",
  "Pixelify Sans",
  "Arbutus",
  "Fenix",
  "Yuji Syuku",
  "Nosifer",
  "Kulim Park",
  "Kaisei HarunoUmi",
  "Denk One",
  "UnifrakturCook",
  "Moul",
  "Vina Sans",
  "Carrois Gothic SC",
  "IBM Plex Sans Hebrew",
  "Bricolage Grotesque",
  "MonteCarlo",
  "Cantora One",
  "Sancreek",
  "Beth Ellen",
  "Piazzolla",
  "Habibi",
  "Rethink Sans",
  "Akronim",
  "MedievalSharp",
  "Romanesco",
  "Dorsa",
  "AR One Sans",
  "Donegal One",
  "Carattere",
  "Dekko",
  "Sahitya",
  "Tulpen One",
  "Red Hat Mono",
  "Anek Latin",
  "IBM Plex Sans Thai Looped",
  "Englebert",
  "IM Fell French Canon",
  "Viaoda Libre",
  "Milonga",
  "Port Lligat Slab",
  "Paprika",
  "Caesar Dressing",
  "Tiro Bangla",
  "Modern Antiqua",
  "Engagement",
  "Kalnia",
  "Karantina",
  "Lexend Giga",
  "Grape Nuts",
  "Sarina",
  "Mystery Quest",
  "Cagliostro",
  "Noto Emoji",
  "Rationale",
  "Redressed",
  "Libre Barcode 39 Extended",
  "Beau Rivage",
  "Bungee Spice",
  "M PLUS 1 Code",
  "Meie Script",
  "Rhodium Libre",
  "Libre Barcode 128 Text",
  "Sree Krushnadevaraya",
  "Train One",
  "Farsan",
  "Spline Sans Mono",
  "Stalemate",
  "Texturina",
  "Stoke",
  "Yomogi",
  "Baloo Tammudu 2",
  "Anybody",
  "Kantumruy Pro",
  "Redacted",
  "Angkor",
  "Margarine",
  "Vampiro One",
  "Offside",
  "Keania One",
  "Moon Dance",
  "Text Me One",
  "Molle",
  "Ravi Prakash",
  "Bungee Hairline",
  "Zhi Mang Xing",
  "Noto Serif Armenian",
  "Monofett",
  "Unlock",
  "Chicle",
  "Content",
  "Noto Sans Lao",
  "Smooch",
  "Underdog",
  "Comforter",
  "Benne",
  "Eagle Lake",
  "Autour One",
  "Spirax",
  "Oldenburg",
  "Flow Circular",
  "Playpen Sans",
  "Lexend Mega",
  "Stick No Bills",
  "Long Cang",
  "Labrada",
  "Khmer",
  "Sirin Stencil",
  "Averia Gruesa Libre",
  "Srisakdi",
  "Fascinate",
  "Noto Sans Gujarati",
  "Sono",
  "Birthstone Bounce",
  "Simonetta",
  "Inika",
  "Kavivanar",
  "Crushed",
  "Shippori Antique B1",
  "Plaster",
  "Noto Sans Oriya",
  "Belanosima",
  "Marko One",
  "Rubik Doodle Shadow",
  "Liu Jian Mao Cao",
  "Noto Serif Telugu",
  "Bruno Ace SC",
  "Kirang Haerang",
  "Sedgwick Ave Display",
  "Chathura",
  "IM Fell DW Pica SC",
  "Seymour One",
  "Gulzar",
  "Passions Conflict",
  "Girassol",
  "Licorice",
  "Charis SIL",
  "Elsie Swash Caps",
  "Mrs Sheppards",
  "Buda",
  "Waterfall",
  "Stint Ultra Expanded",
  "Finlandica",
  "Poor Story",
  "Tilt Prism",
  "Noto Serif HK",
  "Qahiri",
  "Condiment",
  "Akatab",
  "Bilbo",
  "Wellfleet",
  "The Nautigal",
  "Trispace",
  "Kumar One Outline",
  "Bubbler One",
  "Ruluko",
  "Junge",
  "Encode Sans SC",
  "Atomic Age",
  "Delicious Handrawn",
  "Lancelot",
  "Imbue",
  "Hedvig Letters Serif",
  "Dangrek",
  "Chela One",
  "Odor Mean Chey",
  "Zen Tokyo Zoo",
  "Joti One",
  "Jacques Francois",
  "Galindo",
  "Risque",
  "Swanky and Moo Moo",
  "Kavoon",
  "Princess Sofia",
  "Linden Hill",
  "New Tegomin",
  "Felipa",
  "Piedra",
  "Kumar One",
  "BIZ UDMincho",
  "Joan",
  "Fuzzy Bubbles",
  "Yaldevi",
  "Praise",
  "Smokum",
  "Emblema One",
  "IM Fell Great Primer",
  "Hanalei Fill",
  "Ribeye Marrow",
  "Solitreo",
  "Noto Sans Symbols 2",
  "Black And White Picture",
  "Lumanosimo",
  "Londrina Shadow",
  "Jacques Francois Shadow",
  "BioRhyme Expanded",
  "Bahiana",
  "Noto Sans Gothic",
  "Yeon Sung",
  "Ewert",
  "Snippet",
  "Noto Sans Anatolian Hieroglyphs",
  "Mynerve",
  "Bahianita",
  "Noto Sans Math",
  "Passero One",
  "Carlito",
  "Fascinate Inline",
  "Festive",
  "Gentium Book Plus",
  "Luxurious Script",
  "Maiden Orange",
  "Martian Mono",
  "Barriecito",
  "Anek Gurmukhi",
  "Combo",
  "Smooch Sans",
  "Big Shoulders Stencil Text",
  "Anek Gujarati",
  "Bungee Outline",
  "Nuosu SIL",
  "Smythe",
  "Anek Telugu",
  "Big Shoulders Stencil Display",
  "Chilanka",
  "Noto Sans Ethiopic",
  "Braah One",
  "Almendra SC",
  "Rubik Burned",
  "Alumni Sans Inline One",
  "Tourney",
  "Mea Culpa",
  "Water Brush",
  "Preahvihear",
  "Gupter",
  "Anek Kannada",
  "Revalia",
  "Gidugu",
  "Edu TAS Beginner",
  "Amiri Quran",
  "Griffy",
  "IM Fell French Canon SC",
  "Diplomata SC",
  "Asset",
  "Afacad",
  "Bonheur Royale",
  "Almendra Display",
  "Lacquer",
  "Grenze",
  "Sunshiney",
  "Metal",
  "Comme",
  "Noto Serif Kannada",
  "Nova Cut",
  "Butcherman",
  "DynaPuff",
  "Gentium Plus",
  "Ysabeau",
  "Erica One",
  "Flavors",
  "Dr Sugiyama",
  "Peddana",
  "Rubik Maps",
  "My Soul",
  "Noto Serif Khmer",
  "Caramel",
  "Geostar Fill",
  "Taprom",
  "Lavishly Yours",
  "Federant",
  "Rubik Glitch",
  "Siemreap",
  "Koh Santepheap",
  "Nova Slim",
  "Stalinist One",
  "Mr Bedfort",
  "Ysabeau Office",
  "Cairo Play",
  "Miltonian",
  "Diplomata",
  "Lexend Tera",
  "IM Fell Great Primer SC",
  "Ruge Boogie",
  "Trochut",
  "Bokor",
  "Hubballi",
  "Lakki Reddy",
  "Libre Barcode EAN13 Text",
  "Rubik Wet Paint",
  "Tiro Telugu",
  "Climate Crisis",
  "GFS Neohellenic",
  "Jim Nightshade",
  "Suravaram",
  "Single Day",
  "Gideon Roman",
  "Aubrey",
  "Noto Sans Tai Viet",
  "Agdasima",
  "Noto Sans Samaritan",
  "Dhurjati",
  "Rubik Beastly",
  "Fleur De Leah",
  "Alumni Sans Collegiate One",
  "Neonderthaw",
  "Imperial Script",
  "Fruktur",
  "Bigelow Rules",
  "Edu VIC WA NT Beginner",
  "Gwendolyn",
  "Inspiration",
  "Noto Sans Osmanya",
  "Moulpali",
  "Borel",
  "Tiro Devanagari Marathi",
  "Noto Sans Thai Looped",
  "Castoro Titling",
  "Purple Purse",
  "Foldit",
  "Devonshire",
  "Luxurious Roman",
  "Miss Fajardose",
  "Chenla",
  "Aref Ruqaa Ink",
  "Babylonica",
  "Ysabeau Infant",
  "IM Fell Double Pica SC",
  "Rubik Distressed",
  "IBM Plex Sans Devanagari",
  "Vibes",
  "Yuji Boku",
  "Noto Serif Sinhala",
  "Londrina Sketch",
  "Zen Loop",
  "Truculenta",
  "Ballet",
  "Tai Heritage Pro",
  "Rubik Iso",
  "Butterfly Kids",
  "Anek Odia",
  "Bagel Fat One",
  "Rubik Vinyl",
  "Nova Oval",
  "Noto Sans Javanese",
  "Astloch",
  "Big Shoulders Inline Text",
  "Sometype Mono",
  "Noto Sans Meetei Mayek",
  "Bacasime Antique",
  "Updock",
  "Snowburst One",
  "Noto Sans Adlam",
  "Langar",
  "Tiro Devanagari Sanskrit",
  "Reem Kufi Fun",
  "Noto Sans Buhid",
  "Yuji Mai",
  "Lugrasimo",
  "Noto Serif Gujarati",
  "Cherry Bomb One",
  "Noto Rashi Hebrew",
  "Sofadi One",
  "Nabla",
  "Kolker Brush",
  "Tiro Tamil",
  "Noto Serif Balinese",
  "Bonbon",
  "Sassy Frass",
  "Uchen",
  "Noto Sans Gurmukhi",
  "Darumadrop One",
  "Nova Script",
  "Splash",
  "Hedvig Letters Sans",
  "Miltonian Tattoo",
  "Geostar",
  "Grechen Fuemen",
  "Inclusive Sans",
  "Ruwudu",
  "Bruno Ace",
  "Redacted Script",
  "Moo Lah Lah",
  "Mooli",
  "Blaka",
  "Moirai One",
  "Gajraj One",
  "Victor Mono",
  "Send Flowers",
  "Noto Serif Vithkuqi",
  "Petemoss",
  "Love Light",
  "Edu NSW ACT Foundation",
  "Noto Sans Lao Looped",
  "Oi",
  "Handjet",
  "Konkhmer Sleokchher",
  "Noto Sans Glagolitic",
  "Reem Kufi Ink",
  "Alumni Sans Pinstripe",
  "Big Shoulders Inline Display",
  "Noto Serif Tibetan",
  "Noto Sans Syloti Nagri",
  "Noto Sans Tagalog",
  "Island Moments",
  "Explora",
  "Rubik Scribble",
  "Tsukimi Rounded",
  "Dai Banna SIL",
  "Palette Mosaic",
  "Ysabeau SC",
  "Syne Tactile",
  "Rubik Pixels",
  "Sigmar",
  "Noto Serif Ottoman Siyaq",
  "Diphylleia",
  "Rubik Broken Fax",
  "Edu QLD Beginner",
  "Rubik Lines",
  "Noto Serif Tangut",
  "Rubik Doodle Triangles",
  "Cherish",
  "Grey Qo",
  "Noto Music",
  "Noto Serif Tamil",
  "Rubik 80s Fade",
  "Noto Serif Ethiopic",
  "Tiro Gurmukhi",
  "Rubik Spray Paint",
  "Noto Serif Lao",
  "Noto Sans Coptic",
  "Lisu Bosa",
  "Warnes",
  "Kablammo",
  "Noto Sans Sora Sompeng",
  "Noto Serif NP Hmong",
  "Noto Sans Carian",
  "Noto Sans Tifinagh",
  "Are You Serious",
  "Blaka Hollow",
  "Estonia",
  "Orbit",
  "Twinkle Star",
  "Shizuru",
  "Hanalei",
  "Kings",
  "Noto Serif Myanmar",
  "Yuji Hentaigana Akari",
  "Tapestry",
  "Rubik Microbe",
  "Noto Sans Canadian Aboriginal",
  "Noto Serif Toto",
  "Flow Block",
  "Noto Sans Cypriot",
  "Ole",
  "Noto Sans Imperial Aramaic",
  "M PLUS Code Latin",
  "Noto Sans Miao",
  "Rubik Puddles",
  "Noto Serif Ahom",
  "Noto Sans Pahawh Hmong",
  "Lunasima",
  "Noto Sans Tangsa",
  "Noto Sans Old Italic",
  "Blaka Ink",
  "Kay Pho Du",
  "Noto Sans Cypro Minoan",
  "Noto Sans Lepcha",
  "Rubik Marker Hatch",
  "Rubik Gemstones",
  "Noto Serif Khojki",
  "Slackside One",
  "Noto Traditional Nushu",
  "Rubik Storm",
  "Noto Sans Deseret",
  "Noto Sans Linear A",
  "Grandiflora One",
  "Noto Sans Bamum",
  "Noto Sans Old Hungarian",
  "Noto Sans Thaana",
  "Flow Rounded",
  "Noto Sans Marchen",
  "Noto Sans Vithkuqi",
  "Noto Sans Duployan",
  "Noto Serif Old Uyghur",
  "Ingrid Darling",
  "Noto Sans Cherokee",
  "Linefont",
  "Noto Sans Sundanese",
  "Noto Serif Makasar",
  "Noto Sans Kawi",
  "Noto Sans Egyptian Hieroglyphs",
  "Narnoor",
  "Noto Sans Old Persian",
  "Noto Serif Oriya",
  "Noto Serif Khitan Small Script",
  "Noto Sans Hanunoo",
  "Noto Sans Old Turkic",
  "Noto Sans Khudawadi",
  "Noto Sans Old North Arabian",
  "Puppies Play",
  "Noto Sans Cham",
  "Noto Sans Sharada",
  "Noto Sans Batak",
  "Noto Sans Inscriptional Pahlavi",
  "Noto Sans Avestan",
  "Noto Sans Limbu",
  "Noto Sans Bassa Vah",
  "Noto Serif Grantha",
  "Rubik Maze",
  "Noto Sans Multani",
  "Noto Sans Kayah Li",
  "Noto Sans Tai Tham",
  "Mingzat",
  "Noto Sans Brahmi",
  "Noto Sans Tai Le",
  "Noto Sans Nag Mundari",
  "Noto Sans Takri",
  "Noto Sans Syriac Eastern",
  "Noto Sans Ol Chiki",
  "Noto Sans Nandinagari",
  "Noto Sans Balinese",
  "Noto Sans Medefaidrin",
  "Noto Sans Palmyrene",
  "Noto Sans Cuneiform",
  "Noto Sans Siddham",
  "Noto Sans Mayan Numerals",
  "Noto Sans Yi",
  "Noto Sans Kaithi",
  "Noto Serif Gurmukhi",
  "Noto Sans Newa",
  "Noto Sans Lisu",
  "Noto Sans Tagbanwa",
  "Noto Sans Chorasmian",
  "Rock 3D",
  "Noto Sans Tamil Supplement",
  "Noto Sans Chakma",
  "Noto Sans Bhaiksuki",
  "Noto Sans Lycian",
  "Noto Sans Saurashtra",
  "Padyakke Expanded One",
  "Noto Sans Lydian",
  "Yuji Hentaigana Akebono",
  "Noto Sans Adlam Unjoined",
  "Noto Sans Wancho",
  "Noto Sans Modi",
  "Noto Serif Yezidi",
  "Wavefont",
  "Noto Sans Masaram Gondi",
  "Noto Sans Osage",
  "Noto Sans Inscriptional Parthian",
  "Noto Sans Runic",
  "Noto Sans Vai",
  "Chokokutai",
  "Noto Sans Mro",
  "Noto Sans Old South Arabian",
  "Noto Sans Mende Kikakui",
  "Noto Sans Warang Citi",
  "Noto Sans Nabataean",
  "Noto Sans Zanabazar Square",
  "Noto Sans Grantha",
  "Noto Sans Syriac",
  "Noto Sans Psalter Pahlavi",
  "Noto Sans Mandaic",
  "Noto Sans Buginese",
  "Noto Sans Rejang",
  "Noto Sans Old Permic",
  "Noto Sans Phags Pa",
  "Noto Sans Gunjala Gondi",
  "Noto Sans Hatran",
  "Noto Sans Caucasian Albanian",
  "Noto Serif Dogra",
  "Noto Sans Hanifi Rohingya",
  "Noto Sans Sogdian",
  "Noto Sans Linear B",
  "Noto Sans New Tai Lue",
  "Noto Sans Phoenician",
  "Noto Sans Mahajani",
  "Noto Sans NKo",
  "Noto Sans Kharoshthi",
  "Noto Sans Khojki",
  "Noto Sans Pau Cin Hau",
  "Noto Sans Shavian",
  "Noto Sans Elymaic",
  "Noto Sans SignWriting",
  "Noto Sans Ugaritic",
  "Noto Sans Old Sogdian",
  "Noto Sans Manichaean",
  "Noto Sans Ogham",
  "Noto Sans Elbasan",
  "Noto Sans Nushu",
  "Noto Sans Soyombo",
  "Noto Sans Meroitic"
]
  .filter((value, index, array) =>
    array.indexOf(value) === index
  )