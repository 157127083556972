import { useStore } from '../../../hooks/use-store';
import { ImageElementProps } from '../../../stores/data/space-store/imageElement';
import Moveable, { MoveableManagerInterface, Renderer } from 'react-moveable';
import { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player'
import { Flex } from '@mantine/core';
import { EmblaCarousel } from './Carousel';
import { FloatingButtons } from './FloatingButtons';
import { ParralaxPreview } from './ParralaxPreview';
import { observer } from 'mobx-react';
import { CONTENT_TYPE } from './helper';

const ImageElementComponent = (props: ImageElementProps & { selected: boolean, zoom: number, idsMap: string[], selectedClassName: string }) => {

  const {
    dataStore: { spaceStore },
  } = useStore();

  const {
    id,
    x,
    y,
    width,
    height,
    src,
    set,
    alt,
    position,
    objectFit,
    bgColor,
    zIndex,
    opacity,
    borderRadius,
    rotate,
    type,
    zoom,
    transitionDuration,
    sliderDuration,
    parralax
  } = props

  const moveableRef = useRef<Moveable>(null);

  const Editable = {
    name: "editable",
    props: [],
    events: [],
    render(moveable: MoveableManagerInterface<any, any>, React: Renderer) {
      const rect = moveable.getRect();
      const { pos1 } = moveable.state;
      // Add key (required)
      // Add class prefix moveable-(required)
      const EditableViewer = moveable.useCSS("div", `
        {
            position: absolute;
            left: 0px;
            top: 0px;
            will-change: transform;
            transform-origin: 0px 0px;
        }
            `);

          
      return (
        <EditableViewer key={"editable-viewer"} className={"moveable-editable"} style={{
          transform: `translate(${pos1[0]}px, ${pos1[1]}px) rotate(${rect.rotation}deg) translate(10px)`,
          width: width
        }}>
          <FloatingButtons
                   id={id}
            type={type === 'IMAGE' ? CONTENT_TYPE.IMAGE : undefined}
            drag={true}
            selected={props.selected}
            onClickDelete={() => spaceStore.deleteElement(spaceStore.selected!.id)}
            onClickDuplicate={() => spaceStore.duplicate(spaceStore.selected!.id)}
            zoom={zoom}
          />
        </EditableViewer>
      );
    },
  }


  useEffect(() => {
    try {
      moveableRef.current?.setState({
        target: [`.${id}`]
      }, () => {
        try {
          moveableRef.current?.forceUpdate()
          moveableRef.current?.updateRect();
        } catch (error) {
          console.log('error', error)
        }

      });
    } catch (error) {
      console.log('error', error)
    }

  }, [x, y, width, height, rotate, id])


  const snapHorizontalThreshold = zoom < 1 ? 5 : 1
  return (
    <>
      <div
        onClick={(e) => {
          spaceStore.selectElement(id, e.shiftKey)
        }}
        className={`${id}${props.selectedClassName ? ` ${props.selectedClassName}` : ''}`}
        style={{
          position,
          backgroundColor: bgColor || undefined,
          zIndex,
          opacity,
          borderRadius,
          top: 0,
          left: 0,
          width,
          height,
          transform: `translate(${x}px, ${y}px) rotate(${rotate || 0}deg)`,
        }}>
        {
          parralax ? <ParralaxPreview width={parralax.width} height={height} elementWidth={width} margin={parralax.margin} zIndex={zIndex} /> : null
        }
        <Flex
          align={'center'}
          justify={'center'}
          style={{
            userSelect: 'none',
            pointerEvents: 'none',
            width: '100%',
            height: '100%',
          }}
        >

          {
            type === 'IMAGE' ? (
              src ?
                <img
                  style={{
                    borderRadius,
                    objectFit: objectFit as any,
                    width: '100%',
                    height: '100%',
                  }}
                  src={src}
                  alt={alt || ''}
                /> : (
                  <Flex
                    justify={'center'}
                    align={'center'}
                    bg={
                      bgColor || 'rgba(190, 190, 190, 1)'}
                    style={{
                      borderRadius,
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M71.3763 54.9617L71.3755 54.9598L61.203 31.1698L61.2024 31.1685C59.4007 26.9327 56.8235 24.7775 54.0865 24.6364L54.0861 24.6364C51.3826 24.4956 48.5766 26.3777 46.3309 30.4262L46.3302 30.4274L40.1557 41.5091C38.7787 43.9877 36.7392 45.601 34.3983 45.792C32.0195 46.0163 29.7479 44.7581 27.9903 42.5182L71.3763 54.9617ZM71.3763 54.9617C72.9993 58.7281 72.6306 63.0395 70.3069 66.4637L70.3018 66.4714C68.036 69.9008 64.2089 71.9524 60.1051 71.9524H18.6351C14.378 71.9524 10.4915 69.7799 8.2554 66.1653C6.02176 62.5548 5.83533 58.1184 7.73621 54.2847C7.73646 54.2842 7.73672 54.2837 7.73697 54.2831L13.3571 43.0754C13.3572 43.0751 13.3574 43.0748 13.3576 43.0745C14.9373 39.9465 17.251 38.1533 19.8472 37.8937C22.4552 37.6329 25.0847 38.8633 27.2771 41.6104C27.2776 41.6112 27.2782 41.6119 27.2788 41.6126L27.99 42.5178L71.3763 54.9617Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                      <path d="M33.3875 16.2499C33.3875 21.9025 28.8051 26.4849 23.1525 26.4849C17.4998 26.4849 12.9175 21.9025 12.9175 16.2499C12.9175 10.5973 17.4998 6.01489 23.1525 6.01489C28.8051 6.01489 33.3875 10.5973 33.3875 16.2499Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                    </svg>
                  </Flex>
                )
            ) : null
          }
          {
            type === 'VIDEO' ?
              (
                src ? <ReactPlayer
                  style={{
                    borderRadius,
                    objectFit: objectFit as any,
                  }}
                  controls={true}
                  width={'100%'}
                  height={'100%'}
                  url={src}
                /> : <Flex justify={'center'} align={'center'} bg={
                  bgColor || 'rgba(190, 190, 190, 1)'}
                  style={{
                    borderRadius,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M71.3763 54.9617L71.3755 54.9598L61.203 31.1698L61.2024 31.1685C59.4007 26.9327 56.8235 24.7775 54.0865 24.6364L54.0861 24.6364C51.3826 24.4956 48.5766 26.3777 46.3309 30.4262L46.3302 30.4274L40.1557 41.5091C38.7787 43.9877 36.7392 45.601 34.3983 45.792C32.0195 46.0163 29.7479 44.7581 27.9903 42.5182L71.3763 54.9617ZM71.3763 54.9617C72.9993 58.7281 72.6306 63.0395 70.3069 66.4637L70.3018 66.4714C68.036 69.9008 64.2089 71.9524 60.1051 71.9524H18.6351C14.378 71.9524 10.4915 69.7799 8.2554 66.1653C6.02176 62.5548 5.83533 58.1184 7.73621 54.2847C7.73646 54.2842 7.73672 54.2837 7.73697 54.2831L13.3571 43.0754C13.3572 43.0751 13.3574 43.0748 13.3576 43.0745C14.9373 39.9465 17.251 38.1533 19.8472 37.8937C22.4552 37.6329 25.0847 38.8633 27.2771 41.6104C27.2776 41.6112 27.2782 41.6119 27.2788 41.6126L27.99 42.5178L71.3763 54.9617Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                    <path d="M33.3875 16.2499C33.3875 21.9025 28.8051 26.4849 23.1525 26.4849C17.4998 26.4849 12.9175 21.9025 12.9175 16.2499C12.9175 10.5973 17.4998 6.01489 23.1525 6.01489C28.8051 6.01489 33.3875 10.5973 33.3875 16.2499Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                  </svg>
                </Flex>
              )
              : null
          }
          {
            type === 'SLIDER' ? (set.length > 0 ?
              <EmblaCarousel
                options={{
                  duration: transitionDuration,
                  loop: true,
                }}
                delay={sliderDuration}
                slides={set}
                objectFit={objectFit}
                borderRadius={borderRadius}
              /> : <Flex justify={'center'} align={'center'} bg={
                bgColor || 'rgba(190, 190, 190, 1)'}
                style={{
                  borderRadius,
                  width: '100%',
                  height: '100%',
                }}
              >
                <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M71.3763 54.9617L71.3755 54.9598L61.203 31.1698L61.2024 31.1685C59.4007 26.9327 56.8235 24.7775 54.0865 24.6364L54.0861 24.6364C51.3826 24.4956 48.5766 26.3777 46.3309 30.4262L46.3302 30.4274L40.1557 41.5091C38.7787 43.9877 36.7392 45.601 34.3983 45.792C32.0195 46.0163 29.7479 44.7581 27.9903 42.5182L71.3763 54.9617ZM71.3763 54.9617C72.9993 58.7281 72.6306 63.0395 70.3069 66.4637L70.3018 66.4714C68.036 69.9008 64.2089 71.9524 60.1051 71.9524H18.6351C14.378 71.9524 10.4915 69.7799 8.2554 66.1653C6.02176 62.5548 5.83533 58.1184 7.73621 54.2847C7.73646 54.2842 7.73672 54.2837 7.73697 54.2831L13.3571 43.0754C13.3572 43.0751 13.3574 43.0748 13.3576 43.0745C14.9373 39.9465 17.251 38.1533 19.8472 37.8937C22.4552 37.6329 25.0847 38.8633 27.2771 41.6104C27.2776 41.6112 27.2782 41.6119 27.2788 41.6126L27.99 42.5178L71.3763 54.9617Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                  <path d="M33.3875 16.2499C33.3875 21.9025 28.8051 26.4849 23.1525 26.4849C17.4998 26.4849 12.9175 21.9025 12.9175 16.2499C12.9175 10.5973 17.4998 6.01489 23.1525 6.01489C28.8051 6.01489 33.3875 10.5973 33.3875 16.2499Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                </svg>
              </Flex>)
              : null
          }

        </Flex>
      </div >
      <Moveable
        ref={moveableRef}
        zoom={1 / zoom}
        ables={[Editable]}
        props={{
          editable: true,
        }}
        // dragTarget={'.moveable-drag-target'}
        target={props.selected ? `.${id}` : null}
        dragTarget={'.moveable-drag-target'}
        draggable={true}
        resizable={true}
        keepRatio={spaceStore.lockAspectRatio}
        persistData={{
          left: x,
          top: y,
          width: width,
          height: height,
        }}
        pinchable={false}

        snappable={true}
        isDisplaySnapDigit={true}
        isDisplayInnerSnapDigit={false}
        snapGap={true}
        snapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
        elementSnapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
        snapThreshold={0}
        maxSnapElementGuidelineDistance={300}
        useAccuratePosition={true}
        elementGuidelines={props.idsMap}
        // snapRenderThreshold={1}
        snapHorizontalThreshold={snapHorizontalThreshold}
        snapVerticalThreshold={snapHorizontalThreshold}
        verticalGuidelines={
          [0, 20, 720, 1420, 1440,]
        }
        horizontalGuidelines={
          [0, 20, 720, 1420, 1440,]
        }
        rotatable={true}
        onRenderEnd={e => {
          const cssText = e.target.style.cssText
          let width = undefined
          if (cssText.includes('width: ')) {
            width = +cssText.split('width: ')[1].split('px')[0]
          }
          let height = undefined
          if (cssText.includes('height: ')) {
            height = +cssText.split('height: ')[1].split('px')[0]
          }

          let rotate = undefined
          if (e.transform.includes('rotate(')) {
            rotate = +e.transform.split('rotate(')[1].split('deg')[0]
          }
          spaceStore.updateElementProps({
            id: props.id,
            x: e.transformObject.translate[0],
            y: e.transformObject.translate[1],
            width,
            height,
            rotate,
          })
        }}
        onRender={e => {
          e.target.style.cssText += e.cssText;
        }}
      />
    </>
  );
}

export default observer(ImageElementComponent);