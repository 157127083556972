import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import throttle from "lodash/throttle";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useStore } from "../../../hooks/use-store";
import { ButtonElementProps } from "../../../stores/data/space-store/buttonElement";

interface Props {
    data: ButtonElementProps
}

const Text = ({ data }: Props) => {
    const {
        dataStore: { spaceStore },
    } = useStore();


    const [lastChange, setLastChange] = useState<null | number>(null)
    const debouncedChangeHandler = throttle((...params) => {
        const date = Date.now()
        console.log(params)
        setLastChange(date + 1)

        spaceStore.updateElementProps({
            id: data.id,
            text: params,
        })

    }, 0);

    const editor = useEditor({
        onBlur({ editor }) {
            console.log(JSON.stringify(editor.getJSON()));
            debouncedChangeHandler(JSON.stringify(editor.getJSON()));
        },
        extensions: [
            StarterKit.configure({
                history: false,
            }),
        ],
        content: `<p>Text placeholder</p>
    `,
    });

    useEffect(() => {
        if (data.text) {
            try {
                editor?.commands.setContent(JSON.parse(data.text));
            } catch (e) {
                editor?.commands.setContent(data.text);
            }
        }
    }, [data.text, lastChange, editor]);

    if (!editor) {
        return null
    }
    return (
        <EditorContent
            style={{
                width: '100%',
                textAlign: data.textAlign,
                textDecoration: data.textDecoration,
                fontSize: data.fontSize,
                lineHeight: `${+data.lineHeight ? `${data.lineHeight}px` : 'normal'}`,
                letterSpacing: `${+data.letterSpacing ? `${data.letterSpacing}px` : 'normal'}`,
                fontWeight: data.fontWeight,
                fontFamily: `'${data.typefaceId}'`,
                color: data.showHoverState ? data.hoverColor! : data.color!,
            }} className={'textEditor'}
            placeholder={'Enter your notes'}
            editor={editor}
        />
    );
};

export default observer(Text);
