import { useCallback, useEffect, useState } from "react";
import { Beforeunload } from 'react-beforeunload'
import { useStore } from "../../hooks/use-store";

import CopyToClipboard from "./CopyToClipboard";
import {
  createStyles,

  Group,

  Text,

  Grid,
  rem,
  Avatar,
  Center,
  TextInput,
  Image,
  AppShell,
  MediaQuery,
  Button,
  Header,
  Burger,
  useMantineTheme,
  Popover,
  Flex,
  Modal,
  Tooltip,
  LoadingOverlay,
  Loader,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useRequest } from "ahooks";
import { NOTIFICATION_SERVICE } from "../../components/notifications/NotificationService";
import { useHistory, useParams } from "react-router-dom";
// import IndexPage from "./Iframe";
import isEmpty from 'lodash.isempty'
import EditAttributes from "./EditAttributes";
import { useWindowSize } from "./Site/helper";
import { observer } from "mobx-react";
import Test from "./Site/SitePreview";
import Logo from "../../components/Logo";
import GroupElement from "../../stores/data/space-store/groupElement";
import TextElement from "../../stores/data/space-store/textElement";
import ShapeElement from "../../stores/data/space-store/shapeElement";
import ImageElement from "../../stores/data/space-store/imageElement";
import FormElement from "../../stores/data/space-store/formElement";
import { UIContentEditable } from "../../components/ui-kit/contentEditable/UIContentEditable";
import { UIButton } from "../../components/ui-kit/button/UIButton";
import { SitePropertyHeader } from "./SitePropertyHeader";
import { SitePropertyDivider } from "./SitePropertyDivider";
import { SitePropertyAccordion } from "./SitePropertyAccordion";
import { SiteSettingsModal } from "./SiteSettingsModal";
import { PageSettingsModal } from "./PageSettingsModal";
import { FormsSettingsModal } from "./FormsSettingsModal";
import { BillingModal } from "./BillingModal";
import { usePostHog } from "posthog-js/react";
import ButtonElement from "../../stores/data/space-store/buttonElement";
import useIsMobile from "../../hooks/isMobile";
import { IframePreview } from "../../pages/public/gen/website/IframePreview";
import { modals } from "@mantine/modals";
import { SiteGenImageModal } from "./SiteGenImageModal";
import { SiteStatisticsModal } from "./SiteStatisticsModal";
import { SiteLangModal } from "./SiteLangModal";
import LanguageSwitcherElement from "../../stores/data/space-store/languageSwitcherElement";

const useStyles = createStyles((theme) => ({

  logo: {
    padding: '7.5px',
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #464646',
    height: '100%',
    marginRight: '8px',
    cursor: 'pointer',
  },
  pagesHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  padding: {
    padding: '8px 16px'
  },

  item2: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
      }`,
    padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
    paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
    marginBottom: theme.spacing.sm,
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    fontSize: rem(30),
    fontWeight: 700,
    width: rem(60),
  },

  dragHandle: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  wrapper: {
    height: "100vh",
    overflow: "auto",
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    background: theme.colorScheme === "dark" ? "#25262b" : "white",
    borderLeft: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
      }`,
  },

  title: {
    marginBottom: theme.spacing.sm,
    paddingLeft: theme.spacing.sm,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  iframe: {
    height: "100vh",
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],
  },
  publish: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  copyLink: {
    display: "flex",

    alignItems: "center",
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },

  center: {
    display: "flex",
    justifyContent: "center",
  },
  video: {
    margin: "0 auto",

    "> div": {
      position: "absolute!important" as any,
      transformOrigin: "left top",
      borderRadius: "8px",
      overflow: "hidden",
    },
  },
}));

const SiteDetails = () => {
  // const [opened, { open, close }] = useDisclosure(false);

  const { width } = useWindowSize()

  const history = useHistory();

  const posthog = usePostHog()




  const {
    uiStore: { authStore, currentLanguage },
    dataStore: { appStore, spaceStore },
  } = useStore();

  useEffect(() => {
    posthog?.capture('SITE_DETAILS_PAGE_VIEWED')
  }, [posthog]);

  const { siteId } = useParams<{ siteId: string }>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { classes, cx } = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [handlers] = useListState([]);

  const isMobile = useIsMobile()

  const [published, setPublished] = useState(false);
  const [publishedDomainsOpen, setPublishedDomainsOpen] = useState(false);

  const [openPageSettings, setOpenPageSettings] = useState<null | string>(null);
  const [openSiteSettings, setOpenSiteSettings] = useState(false);
  const [openFormsSettings, setOpenFormsSettings] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [sitePreview, setSitePreview] = useState<string | null>(null);

  // const [website, setWebsite] = useState({
  //   name: "",
  //   id: "",
  //   freeDomain: "",
  //   updated_at: ''
  // });
  const [touched, setTouched] = useState(false);

  const syncBilling = useCallback(async (event: any) => {
    if (event.key === "sync") {
      const res = await appStore.getWebsiteById(spaceStore.siteId)
      spaceStore.setBilling({
        planType: res.planType,
      })
      setTimeout(async () => {
        const res = await appStore.getWebsiteById(spaceStore.siteId)
        spaceStore.setBilling({
          planType: res.planType,
        })
      }, 4000)

    }



  }, [appStore, spaceStore]);

  useEffect(() => {
    window.addEventListener("storage", syncBilling);

    return () => {
      window.removeEventListener("storage", syncBilling);
    };
  }, [syncBilling]);



  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // if the form is NOT unchanged, then set the onbeforeunload
    if (touched) {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => { };
  }, [touched]);
  const [data, setData] = useState<any>({} as any);

  // useEffect(() => {
  //   if (sections) {
  //     if (!isEmpty(data)) {
  //       // setData sections
  //       setData((state) => ({ ...state, sections }));
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sections]);



  // TODO add escape key listener
  // useEffect(() => {
  //   const handler = (model: any) => {
  //     console.log(model)
  //     if (model.key === 'Escape') {
  //       // spaceStore.selectElement(null, false)
  //     }
  //   }
  //   document.addEventListener('keydown', handler);

  //   return () => {
  //     document.removeEventListener('keydown', handler);
  //   };
  // }, []);


  const deleteElementModal = (id: string) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure? This action cannot be undone.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => {
      spaceStore.deleteElement(id)
    },
  });

  const replaceTemplateModal = () => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure? All content will be lost.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: () => {
      history.push(`/templates/${spaceStore.siteId}`)
    },
  });

  useEffect(() => {
    console.log(authStore.currentUser?.status.name)
    if (authStore.currentUser?.status.name === 'Inactive') {
      window.location.href = '/waitlist';
    }
  }, [authStore]);

  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const { loading, refresh } = useRequest(async () => appStore.getWebsiteById(siteId), {
    onSuccess: (model: any) => {
      spaceStore.setWebsiteData(model.draft)
      spaceStore.setDomains({
        freeDomain: model.freeDomain,
        id: model.id,
        domain: model.domain,
        planType: model.planType,
      })
      setData(model.data);
      // handlers.setState(model.data.sections)
      setTouched(false);
      // setWebsite({ id: model.id, name: model.name, updated_at: model.updated_at, freeDomain: model.freeDomain });
    },
  });

  const { loading: publishLoading, runAsync } = useRequest(
    async (model: { id: string; data?: any; draft?: any, name: string }) =>
      appStore.updateFunnel(model),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => {
        if (model.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        }
      },
    }
  );

  const { loading: remixLoading, runAsync: runAsyncRemix } = useRequest(
    async ({ copyId }) =>
      appStore.generateWebsite({
        type: 'startup',
        style: 'Professional',
        prompt: ``,
        lang: currentLanguage,
        copyId: copyId,
        replaceId: spaceStore.siteId,
      }),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => {

        if (model.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {

          refresh()
          // setItrame(model)
          // setStep(6)
          // setLeadModalOpened(true)

        }
      },
    }
  );

  const openRemixConfirmModal = (model: { copyId: string }) => modals.openConfirmModal({
    title: 'Please confirm your action',
    children: (
      <Text size="sm">
        Are you sure? All content will be replaced with the template.
      </Text>
    ),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    onCancel: () => console.log('Cancel'),
    onConfirm: async () => {
      await runAsyncRemix({ copyId: model.copyId })
    },
  });

  const { loading: saveDraftLoading, runAsync: saveDraft } = useRequest(
    async (model: { id: string; data?: any; draft?: any, name: string }) =>
      appStore.updateFunnel(model),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => {
        if (model.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          NOTIFICATION_SERVICE.successNotification(
            "Draft saved"
          );
        }
      },
    }
  );
  const { loading: previewLoading, runAsync: previewDraft } = useRequest(
    async (model: { draft: any }) =>
      appStore.previewSite(model),
    {
      loadingDelay: 20,
      manual: true,
      onSuccess: (model) => {
        if (model.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {

          // NOTIFICATION_SERVICE.successNotification(
          //   "Draft saved"
          // );
        }
      },
    }
  );

  const changePulishState = () => {
    setTimeout(() => {
      setPublished(false);
      setPublishedDomainsOpen(false)
    }, 5000);
  };

  if (loading || isEmpty(data)) {
    return null;
  }


  if (isMobile) {
    return (
      <Flex h="100%" w={'100%'} align={'center'} justify={'center'} p={24}>
        <Text align="center">For an enhanced experience, please enter Klykn on your desktop.</Text>
      </Flex>
    )
  }

  return (
    <AppShell
      styles={{
        main: {
          padding: 0,
          background: theme.other.background
        },
      }}
      fixed
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      footer={
        undefined
      }
      header={
        <Header style={{
          backgroundColor: '#303030',
          borderBottom: '1px solid #464646'
        }}
          height={{ base: 40, md: 40 }}
        >


          <Beforeunload onBeforeunload={() => 'You’ll lose your data!'} />
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={'gray'}
                mr="xl"
              />
            </MediaQuery>

            <div className={classes.logo} onClick={() => {
              setOpenSaveModal(true)
            }}>
              <Logo size={40} />
            </div>

            <Modal opened={openSaveModal}
              onClose={() => {
                setOpenSaveModal(false)
              }}
              title={
                t("editor.generate.changes.lost", {
                })
              }
              overlayProps={{
                // backgroundOpacity: 0.55,
                blur: 3,
              }}
            >
              <Flex gap={12} justify={'center'}>
                <UIButton color={"gray"} loading={false} fluid={false} onClick={() => {
                  history.push(`/sites/`);

                }}>
                  {
                    t("editor.generate.changes.continue", {
                    })
                  }
                </UIButton>
                <UIButton color={"blue"} loading={saveDraftLoading} fluid={false} onClick={async () => {

                  await saveDraft({
                    name: 'website.name',
                    id: spaceStore.siteId,
                    draft: spaceStore.websiteData,
                  });
                  history.push(`/sites/`);
                }}>
                  {
                    t("editor.generate.changes.save.continue", {
                    })
                  }
                </UIButton>
              </Flex>
            </Modal>

            <Flex
              style={{
                minWidth: '514px',
              }}
              gap={8}
              align={'center'}>
              <Flex
                align={'center'}
                style={{
                  borderRadius: '4px',
                  border: '1px solid rgba(70, 70, 70, 1)',
                  background: '#212121',
                  height: '26px',
                  width: 'max-content',
                }}
                pr={6}
                gap={0}>
                {
                  spaceStore.websiteData.favicon ? (
                    <Flex ml={6} style={{ height: '16px', width: '16px' }} justify={'center'} align={'center'}>
                      <Image
                        width={14}
                        height={14}
                        styles={{
                          image: {
                            borderRadius: '50%',
                          }
                        }}
                        src={spaceStore.websiteData.favicon}
                        alt="With default placeholder"
                        withPlaceholder
                        fit="fill"
                      />
                    </Flex>
                  ) : (
                    null
                  )
                }

                <Flex ml={6}>
                  <Text size={12}>{spaceStore.domain ? `${spaceStore.domain}` : `${spaceStore.freeDomain}.klykn.com`}</Text>
                </Flex>
              </Flex>
              <UIButton
                loading={false}
                color={'transparent'}
                fluid={false}
                onClick={async () => {
                  spaceStore.undo()
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.12988 18.3101H15.1299C17.8899 18.3101 20.1299 16.0701 20.1299 13.3101C20.1299 10.5501 17.8899 8.31006 15.1299 8.31006H4.12988"
                    stroke={
                      spaceStore.stack.length === 0 ? '#737373' : 'white'
                    }
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
                  <path
                    d="M6.43012 10.8099L3.87012 8.24994L6.43012 5.68994"
                    stroke={
                      spaceStore.stack.length === 0 ? '#737373' : 'white'
                    }
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

              </UIButton>
              <UIButton
                loading={false}
                color={'transparent'}
                fluid={false}
                onClick={async () => {
                  spaceStore.redo()
                }}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.8701 18.3101H8.87012C6.11012 18.3101 3.87012 16.0701 3.87012 13.3101C3.87012 10.5501 6.11012 8.31006 8.87012 8.31006H19.8701"
                    stroke={
                      spaceStore.redoStack.length === 0 ? '#737373' : 'white'
                    }
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.5701 10.8099L20.1301 8.24994L17.5701 5.68994"
                    stroke={
                      spaceStore.redoStack.length === 0 ? '#737373' : 'white'
                    }
                    strokeLinecap="round"
                    strokeLinejoin="round" />
                </svg>

              </UIButton>
            </Flex>



            <Center mb={6} mt={6} mx="auto">

              <div
                onClick={() => spaceStore.changeViewport(1440)}
                style={{
                  background: spaceStore.currentViewport === 1440 ? 'black' : undefined,
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/monitor">
                    <g id="monitor">
                      <path id="Vector" d="M6.94 2H18.05C21.61 2 22.5 2.89 22.5 6.44V12.77C22.5 16.33 21.61 17.21 18.06 17.21H6.94C3.39 17.22 2.5 16.33 2.5 12.78V6.44C2.5 2.89 3.39 2 6.94 2Z" stroke={spaceStore.currentViewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_2" d="M12.5 17.22V22" stroke={spaceStore.currentViewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_3" d="M2.5 13H22.5" stroke={spaceStore.currentViewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_4" d="M8 22H17" stroke={spaceStore.currentViewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>
              </div>


              <div
                onClick={() => spaceStore.changeViewport(810)}
                style={{
                  background: spaceStore.currentViewport === 810 ? 'black' : undefined,
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/mobile">
                    <g id="mobile">
                      <path id="Vector" d="M0.833338 13.1667L0.833338 4.83337C0.833337 1.50004 1.66667 0.666708 5 0.666707L10 0.666707C13.3333 0.666707 14.1667 1.50004 14.1667 4.83337L14.1667 13.1667C14.1667 16.5 13.3333 17.3334 10 17.3334L5.00001 17.3334C1.66667 17.3334 0.833339 16.5 0.833338 13.1667Z" stroke={spaceStore.currentViewport === 810 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_2" d="M5.83334 14.4167L9.16667 14.4167" stroke={spaceStore.currentViewport === 810 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>

                <Popover width={240} position="bottom" withArrow shadow="md" offset={20}>
                  <Popover.Target>
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                      style={{
                        alignSelf: 'end',
                        marginLeft: '2px',
                        marginBottom: '10px'
                      }}>
                      <g id="vuesax/bold/arrow-square-down">
                        <g id="arrow-square-down">
                          <path id="Vector" d="M8.595 1L4.405 1C2.585 1 1.5 2.085 1.5 3.905L1.5 8.09C1.5 9.915 2.585 11 4.405 11H8.59C10.41 11 11.495 9.915 11.495 8.095V3.905C11.5 2.085 10.415 1 8.595 1ZM8.53 5.585L6.765 7.35C6.69 7.425 6.595 7.46 6.5 7.46C6.405 7.46 6.31 7.425 6.235 7.35L4.47 5.585C4.325 5.44 4.325 5.2 4.47 5.055C4.615 4.91 4.855 4.91 5 5.055L6.5 6.555L8 5.055C8.145 4.91 8.385 4.91 8.53 5.055C8.675 5.2 8.675 5.435 8.53 5.585Z" fill="#74757D" />
                        </g>
                      </g>
                    </svg>

                  </Popover.Target>
                  <Popover.Dropdown>
                    <Flex direction={'column'} gap={8} p={2}>
                      <Text size="xs">
                        {
                          t("editor.generate.tablet", {
                          })
                        }
                      </Text>
                      <Button
                        fullWidth
                        styles={{
                          root: { height: rem(28), background: '#464646' },
                        }}
                        variant="default"
                        onClick={async (e) => {
                          e.stopPropagation()
                          spaceStore.generateFromVeiport(810)

                          posthog?.capture('GENERATE_TABLET_VERSION_CLICKED')

                          NOTIFICATION_SERVICE.successNotification('Tablet version generated')
                        }}
                      >
                        {
                          t("editor.generate.tablet.button", {
                          })
                        }

                      </Button>
                    </Flex>

                  </Popover.Dropdown>
                </Popover>
              </div>
              <div onClick={() => spaceStore.changeViewport(320)}
                style={{
                  background: spaceStore.currentViewport === 320 ? 'black' : undefined,
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >

                <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="vuesax/linear/mobile">
                    <g id="mobile">
                      <path id="Vector" d="M1.23333 11.3333L1.23333 4.66663C1.23333 1.99996 1.76666 1.33329 3.89999 1.33329L7.09999 1.33329C9.23333 1.33329 9.76666 1.99996 9.76666 4.66663L9.76666 11.3333C9.76666 14 9.23333 14.6666 7.1 14.6666L3.89999 14.6666C1.76666 14.6666 1.23333 14 1.23333 11.3333Z" stroke={spaceStore.currentViewport === 320 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_2" d="M4.43333 12.3333L6.56667 12.3333" stroke={spaceStore.currentViewport === 320 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>
                <Popover width={240} position="bottom" withArrow shadow="md" offset={20}>
                  <Popover.Target>
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"
                      style={{
                        alignSelf: 'end',
                        marginLeft: '2px',
                        marginBottom: '10px'
                      }}>
                      <g id="vuesax/bold/arrow-square-down">
                        <g id="arrow-square-down">
                          <path id="Vector" d="M8.595 1L4.405 1C2.585 1 1.5 2.085 1.5 3.905L1.5 8.09C1.5 9.915 2.585 11 4.405 11H8.59C10.41 11 11.495 9.915 11.495 8.095V3.905C11.5 2.085 10.415 1 8.595 1ZM8.53 5.585L6.765 7.35C6.69 7.425 6.595 7.46 6.5 7.46C6.405 7.46 6.31 7.425 6.235 7.35L4.47 5.585C4.325 5.44 4.325 5.2 4.47 5.055C4.615 4.91 4.855 4.91 5 5.055L6.5 6.555L8 5.055C8.145 4.91 8.385 4.91 8.53 5.055C8.675 5.2 8.675 5.435 8.53 5.585Z" fill="#74757D" />
                        </g>
                      </g>
                    </svg>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Flex direction={'column'} gap={8} p={2}>
                      <Text size="xs">
                        {
                          t("editor.generate.mobile", {
                          })
                        }
                      </Text>
                      <Button
                        fullWidth
                        styles={{
                          root: { height: rem(28), background: '#464646' },
                        }}
                        variant="default"
                        onClick={async (e) => {
                          e.stopPropagation()
                          spaceStore.generateFromVeiport(320)
                          posthog?.capture('GENERATE_MOBILE_VERSION_CLICKED')

                          NOTIFICATION_SERVICE.successNotification('Mobile version generated')
                        }}
                      >
                        {
                          t("editor.generate.mobile.button", {
                          })
                        }

                      </Button>
                    </Flex>
                  </Popover.Dropdown>
                </Popover>

              </div>


              <Text size="xs" color="dimmed" style={{
                minWidth: '46px',
                marginLeft: '10px',
              }}>
                {spaceStore.currentViewport} px
              </Text>
            </Center>



            <Flex gap={6} align={'center'} mr={6}>
              <Center mb={6} mt={6} mx={2}>
                <CopyToClipboard
                  text={spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}
                  link={spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}
                />
              </Center>
              <UIButton
                loading={previewLoading}
                color={'gray'}
                fluid={false}
                onClick={async () => {
                  posthog?.capture('PREVIEW_CLICKED')
                  const res = await previewDraft({
                    draft: spaceStore.websiteData,
                  });
                  console.log(res)

                  if (res.preview) {
                    setSitePreview(res.preview)
                  }
                }}
              >
                {
                  t("preview")
                }
              </UIButton>
              <UIButton
                loading={saveDraftLoading}
                color={'gray'}
                fluid={false}
                onClick={async () => {
                  posthog?.capture('SAVE_DRAFT_CLICKED')
                  await saveDraft({
                    name: 'website.name',
                    id: spaceStore.siteId,
                    draft: spaceStore.websiteData,
                  });

                }}
              >
                {
                  t("editor.save.draft", {
                    defaultValue: "Save draft",
                  })
                }
              </UIButton>
              {

              }
              <div style={{ position: 'relative' }}>
                <UIButton
                  color={'gray'}
                  fluid={false}
                  loading={publishLoading}
                  onClick={async () => {

                    // const errors = spaceStore.checkBeforePublish()

                    // if (errors.length > 0) {
                    //   spaceStore.openBillingModal()
                    // } else {
                    await runAsync({
                      name: 'website.name',
                      id: spaceStore.siteId,
                      data: spaceStore.websiteData,
                    });
                    setPublished(true);
                    setPublishedDomainsOpen(true);

                    posthog?.capture('PUBLISH_SITE_CLICKED')

                    // setTouched(false);
                    changePulishState();
                    // }
                  }}
                >
                  {published ?
                    t("editor.published", {
                      defaultValue: "Published",
                    })
                    : t("editor.publish", {
                      defaultValue: "Publish",
                    })
                  }
                  <Avatar size={32}
                    mr={-10}
                    color={undefined}>
                    {published ? "🎉" : "🚀"}
                  </Avatar>
                </UIButton>


                {publishedDomainsOpen ? <Flex style={{
                  zIndex: 111111111,
                  position: 'absolute',
                  right: '6px',
                  top: '40px',
                  background: '#303030',
                  borderRadius: '4px',
                  padding: '12px',
                  width: '320px',
                  boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                }}>
                  <Flex
                    direction={'column'}
                    gap={8}
                    style={{
                      width: '100%',
                    }}>
                    <Flex style={{
                      width: '100%',
                      borderRadius: '4px',
                      background: 'rgba(70, 70, 70, 1)',
                      padding: '16px 46px'
                    }}>
                      <Flex gap={0} direction={'column'} pos={'relative'}>

                        <div style={{ position: 'absolute', left: '-30px', top: '2px' }}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" rx="2" fill="#236CFA" />
                            <path d="M12 5L6.5 10.5L4 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </div>
                        <Text style={{
                          color: 'white',
                          fontSize: '14px',
                          fontWeight: 'bold',
                        }}>
                          Default address
                        </Text>
                        <Flex
                          align={'center'}
                          pos={'relative'}
                          gap={6}>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_268_9369)">
                              <path d="M3.82503 10.455C3.81003 10.455 3.79003 10.465 3.77503 10.465C2.80503 9.98498 2.01503 9.18998 1.53003 8.21998C1.53003 8.20498 1.54003 8.18498 1.54003 8.16998C2.15003 8.34998 2.78003 8.48498 3.40503 8.58998C3.51503 9.21998 3.64503 9.84498 3.82503 10.455Z" fill="#989898" />
                              <path d="M10.47 8.22498C9.97502 9.21998 9.15002 10.025 8.14502 10.51C8.33502 9.87498 8.49502 9.23498 8.60002 8.58998C9.23002 8.48498 9.85002 8.34998 10.46 8.16998C10.455 8.18998 10.47 8.20998 10.47 8.22498Z" fill="#989898" />
                              <path d="M10.51 3.85499C9.88002 3.66499 9.24502 3.50999 8.60002 3.39999C8.49502 2.75499 8.34002 2.11499 8.14502 1.48999C9.18002 1.98499 10.015 2.81999 10.51 3.85499Z" fill="#989898" />
                              <path d="M3.82499 1.54503C3.64499 2.15503 3.51499 2.77503 3.40999 3.40503C2.76499 3.50503 2.12499 3.66503 1.48999 3.85503C1.97499 2.85003 2.77999 2.02503 3.77499 1.53003C3.78999 1.53003 3.80999 1.54503 3.82499 1.54503Z" fill="#989898" />
                              <path d="M7.74488 3.295C6.58488 3.165 5.41488 3.165 4.25488 3.295C4.37988 2.61 4.53988 1.925 4.76488 1.265C4.77488 1.225 4.76988 1.195 4.77488 1.155C5.16988 1.06 5.57488 1 5.99988 1C6.41988 1 6.82988 1.06 7.21988 1.155C7.22488 1.195 7.22488 1.225 7.23488 1.265C7.45988 1.93 7.61988 2.61 7.74488 3.295Z" fill="#989898" />
                              <path d="M3.295 7.745C2.605 7.62 1.925 7.46 1.265 7.235C1.225 7.225 1.195 7.23 1.155 7.225C1.06 6.83 1 6.425 1 6C1 5.58 1.06 5.17 1.155 4.78C1.195 4.775 1.225 4.775 1.265 4.765C1.93 4.545 2.605 4.38 3.295 4.255C3.17 5.415 3.17 6.585 3.295 7.745Z" fill="#989898" />
                              <path d="M11.0001 6C11.0001 6.425 10.9401 6.83 10.8451 7.225C10.8051 7.23 10.7751 7.225 10.7351 7.235C10.0701 7.455 9.39008 7.62 8.70508 7.745C8.83508 6.585 8.83508 5.415 8.70508 4.255C9.39008 4.38 10.0751 4.54 10.7351 4.765C10.7751 4.775 10.8051 4.78 10.8451 4.78C10.9401 5.175 11.0001 5.58 11.0001 6Z" fill="#989898" />
                              <path d="M7.74488 8.70502C7.61988 9.39502 7.45988 10.075 7.23488 10.735C7.22488 10.775 7.22488 10.805 7.21988 10.845C6.82988 10.94 6.41988 11 5.99988 11C5.57488 11 5.16988 10.94 4.77488 10.845C4.76988 10.805 4.77488 10.775 4.76488 10.735C4.54488 10.07 4.37988 9.39502 4.25488 8.70502C4.83488 8.77002 5.41488 8.81502 5.99988 8.81502C6.58488 8.81502 7.16988 8.77002 7.74488 8.70502Z" fill="#989898" />
                              <path d="M7.88167 7.88167C6.63112 8.03944 5.36888 8.03944 4.11833 7.88167C3.96056 6.63112 3.96056 5.36888 4.11833 4.11833C5.36888 3.96056 6.63112 3.96056 7.88167 4.11833C8.03944 5.36888 8.03944 6.63112 7.88167 7.88167Z" fill="#989898" />
                            </g>
                            <defs>
                              <clipPath id="clip0_268_9369">
                                <rect width="12" height="12" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>

                          <Text size={'12px'} color={'rgba(152, 152, 152, 1)'}>
                            {spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}
                          </Text>
                          <div onClick={() => setOpenSiteSettings(true)}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z" stroke="#989898" strokeMiterlimit="10" strokeLinecap="square" strokeLinejoin="round" />
                              <path d="M1 6.44003V5.56003C1 5.04003 1.425 4.61003 1.95 4.61003C2.855 4.61003 3.225 3.97003 2.77 3.18503C2.51 2.73503 2.665 2.15003 3.12 1.89003L3.985 1.39503C4.38 1.16003 4.89 1.30003 5.125 1.69503L5.18 1.79003C5.63 2.57503 6.37 2.57503 6.825 1.79003L6.88 1.69503C7.115 1.30003 7.625 1.16003 8.02 1.39503L8.885 1.89003C9.34 2.15003 9.495 2.73503 9.235 3.18503C8.78 3.97003 9.15 4.61003 10.055 4.61003C10.575 4.61003 11.005 5.03503 11.005 5.56003V6.44003C11.005 6.96003 10.58 7.39003 10.055 7.39003C9.15 7.39003 8.78 8.03003 9.235 8.81503C9.495 9.27003 9.34 9.85003 8.885 10.11L8.02 10.605C7.625 10.84 7.115 10.7 6.88 10.305L6.825 10.21C6.375 9.42503 5.635 9.42503 5.18 10.21L5.125 10.305C4.89 10.7 4.38 10.84 3.985 10.605L3.12 10.11C2.665 9.85003 2.51 9.26503 2.77 8.81503C3.225 8.03003 2.855 7.39003 1.95 7.39003C1.425 7.39003 1 6.96003 1 6.44003Z" stroke="#989898" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          </div>

                          <a href={spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`} target="_blank" rel="noreferrer">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.5 5.50002L10.6 1.40002" stroke="#989898" strokeLinecap="square" strokeLinejoin="round" />
                              <path d="M11.0001 3.4V1H8.6001" stroke="#989898" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11H7.5C10 11 11 10 11 7.5V6.5" stroke="#989898" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          </a>
                        </Flex>
                        <Text size={'12px'} color={'#36E678'}>Published a few second ago</Text>
                      </Flex>
                    </Flex>

                    <Flex gap={6} justify={'flex-end'}>


                      <UIButton color={"blue"} loading={false} fluid={false} onClick={() => {
                        setOpenSiteSettings(true)
                        setPublishedDomainsOpen(false)
                      }}>
                        Settings
                      </UIButton>

                      <UIButton color={"gray"} loading={false} fluid={false} onClick={() => {
                        setPublishedDomainsOpen(false)
                      }}>
                        Close
                      </UIButton>


                    </Flex>


                  </Flex>
                </Flex> : null}
              </div>
            </Flex>

          </div >
        </Header >
      }
    >
      <div>
        <LoadingOverlay visible={remixLoading} loaderProps={{ children: <Flex gap={8}><Loader color="orange" /><Text>Generating...</Text></Flex> }} />

        <Grid justify="space-between" m={0}>
          <div
            style={{
              width: '40px',
              background: '#303030',
              marginTop: '40px',
              height: 'calc(100vh - 40px)',
              borderRight: '1px solid #464646'
            }}
          >
            <Flex direction={'column'}>
              <Tooltip label={t("Home")} bg={'black'} color='white' position="right">
                <Flex justify={'center'} align={'center'} style={{ background: 'rgba(18, 18, 18, 1)', height: '40px', width: '39px', cursor: 'pointer' }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17 13.4H13.8C11.4 13.4 10.6 12.6 10.6 10.2V7L17 13.4Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.6 2H15.6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 8V14.19C22 15.74 20.74 17 19.19 17" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Flex>
              </Tooltip>
              {/* <Flex justify={'center'} align={'center'} style={{ height: '40px', width: '39px', cursor: 'pointer' }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 18.43H13L8.54999 21.39C7.88999 21.83 7 21.36 7 20.56V18.43C4 18.43 2 16.43 2 13.43V7.42993C2 4.42993 4 2.42993 7 2.42993H17C20 2.42993 22 4.42993 22 7.42993V13.43C22 16.43 20 18.43 17 18.43Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12 11.36V11.15C12 10.47 12.42 10.11 12.84 9.82001C13.25 9.54001 13.66 9.18002 13.66 8.52002C13.66 7.60002 12.92 6.85999 12 6.85999C11.08 6.85999 10.34 7.60002 10.34 8.52002" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M11.9955 13.75H12.0045" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </Flex> */}
              <Tooltip label={t("Website settings")} bg={'black'} color='white' position="right">
                <Flex justify={'center'} align={'center'} style={{ height: '40px', width: '39px', cursor: 'pointer' }}
                  onClick={() => {
                    setOpenSiteSettings(true)
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 12.8801V11.1201C2 10.0801 2.85 9.22006 3.9 9.22006C5.71 9.22006 6.45 7.94006 5.54 6.37006C5.02 5.47006 5.33 4.30006 6.24 3.78006L7.97 2.79006C8.76 2.32006 9.78 2.60006 10.25 3.39006L10.36 3.58006C11.26 5.15006 12.74 5.15006 13.65 3.58006L13.76 3.39006C14.23 2.60006 15.25 2.32006 16.04 2.79006L17.77 3.78006C18.68 4.30006 18.99 5.47006 18.47 6.37006C17.56 7.94006 18.3 9.22006 20.11 9.22006C21.15 9.22006 22.01 10.0701 22.01 11.1201V12.8801C22.01 13.9201 21.16 14.7801 20.11 14.7801C18.3 14.7801 17.56 16.0601 18.47 17.6301C18.99 18.5401 18.68 19.7001 17.77 20.2201L16.04 21.2101C15.25 21.6801 14.23 21.4001 13.76 20.6101L13.65 20.4201C12.75 18.8501 11.27 18.8501 10.36 20.4201L10.25 20.6101C9.78 21.4001 8.76 21.6801 7.97 21.2101L6.24 20.2201C5.33 19.7001 5.02 18.5301 5.54 17.6301C6.45 16.0601 5.71 14.7801 3.9 14.7801C2.85 14.7801 2 13.9201 2 12.8801Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                </Flex>
              </Tooltip>


              <Tooltip label={t("Billing")} bg={'black'} color='white' position="right">
                <Flex justify={'center'} align={'center'} style={{ height: '40px', width: '39px', cursor: 'pointer' }}
                  onClick={() => {
                    spaceStore.openBillingModal()
                  }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22 18V15.58C22 14 21 13 19.42 13H16V19.99C16 21.1 16.91 22 18.02 22C19.11 21.99 20.11 21.55 20.83 20.83C21.55 20.1 22 19.1 22 18Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 17V3C2 2.17 2.93998 1.7 3.59998 2.2L5.31 3.48C5.71 3.78 6.27 3.74 6.63 3.38L8.28998 1.71C8.67998 1.32 9.32002 1.32 9.71002 1.71L11.39 3.39C11.74 3.74 12.3 3.78 12.69 3.48L14.4 2.2C15.06 1.71 16 2.18 16 3V20C16 21.1 16.9 22 18 22H7H6C3 22 2 20.21 2 18V17Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.58344 14.2079C6.58344 15.1779 7.33345 15.9579 8.25345 15.9579H10.1334C10.9334 15.9579 11.5834 15.2779 11.5834 14.4279C11.5834 13.5179 11.1834 13.1879 10.5934 12.9779L7.58344 11.9279C6.99344 11.7179 6.59344 11.3979 6.59344 10.4779C6.59344 9.63787 7.24343 8.94788 8.04343 8.94788H9.92343C10.8434 8.94788 11.5934 9.72788 11.5934 10.6979" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.08344 7.95789V16.9579" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Flex>
              </Tooltip>




              <Tooltip label={t("Replace template")} bg={'black'} color='white' position="right">
                <Flex
                  justify={'center'}
                  align={'center'}
                  style={{ height: '40px', width: '39px', cursor: 'pointer' }}
                  onClick={() => replaceTemplateModal()}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.6 12.58V15.31C14.6 17.59 13.69 18.5 11.41 18.5H8.69C6.42 18.5 5.5 17.59 5.5 15.31V12.58C5.5 10.31 6.41 9.39999 8.69 9.39999H11.42C13.69 9.39999 14.6 10.31 14.6 12.58Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.5 8.68V11.41C18.5 13.69 17.59 14.6 15.31 14.6H14.6V12.58C14.6 10.31 13.69 9.4 11.41 9.4H9.40002V8.68C9.40002 6.4 10.31 5.5 12.59 5.5H15.32C17.59 5.5 18.5 6.41 18.5 8.68Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M2 9C2 5.13 5.13 2 9 2L7.95 3.75" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>


                </Flex>
              </Tooltip>

              <Tooltip label={t("Form submissions")} bg={'black'} color='white' position="right">
                <Flex justify={'center'} align={'center'} style={{ height: '40px', width: '39px', cursor: 'pointer' }}
                  onClick={() => {
                    setOpenFormsSettings(true)
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 4.66671V11.3334C14 13.3334 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3334 2 11.3334V4.66671C2 2.66671 3 1.33337 5.33333 1.33337H10.6667C13 1.33337 14 2.66671 14 4.66671Z" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.6665 3V4.33333C9.6665 5.06667 10.2665 5.66667 10.9998 5.66667H12.3332" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.3335 8.66663H8.00016" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.3335 11.3334H10.6668" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                </Flex>
              </Tooltip>

              <Tooltip label={t("Site statistics")} bg={'black'} color='white' position="right">
                <Flex
                  justify={'center'}
                  align={'center'}
                  style={{ height: '40px', width: '39px', cursor: 'pointer' }}
                  onClick={() => spaceStore.toggleStatisticModal()}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15.5 18.5C16.6 18.5 17.5 17.6 17.5 16.5V7.5C17.5 6.4 16.6 5.5 15.5 5.5C14.4 5.5 13.5 6.4 13.5 7.5V16.5C13.5 17.6 14.39 18.5 15.5 18.5Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.5 18.5C9.6 18.5 10.5 17.6 10.5 16.5V13C10.5 11.9 9.6 11 8.5 11C7.4 11 6.5 11.9 6.5 13V16.5C6.5 17.6 7.39 18.5 8.5 18.5Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </Flex>
              </Tooltip>

              <Tooltip label={t("Localization")} bg={'black'} color='white' position="right">
                <Flex
                  justify={'center'}
                  align={'center'}
                  style={{ height: '40px', width: '39px', cursor: 'pointer' }}
                  onClick={() => spaceStore.toggleLangModal()}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.99 8.96002H7.01001" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 7.28003V8.96002" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M14.5 8.94C14.5 13.24 11.14 16.72 7 16.72" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M17 16.72C15.2 16.72 13.6 15.76 12.45 14.25" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </Flex>
              </Tooltip>

              <Tooltip label={t("Remix")} bg={'black'} color='white' position="right">
                <Flex
                  justify={'center'}
                  align={'center'}
                  style={{ height: '40px', width: '39px', cursor: 'pointer' }}
                  onClick={async () => {
                    const getRandomValue = (obj: { [key: string]: string }) => {
                      const values = Object.values(obj);
                      const randomIndex = Math.floor(Math.random() * values.length);
                      return values[randomIndex];
                    }

                    const template = getRandomValue({
                      'onestartupv2-template': 'onestartupv2-template',
                      'bluestartup-template': 'bluestartup-template',
                      'beauty-remix-template': 'beauty-remix-template',
                      'cleanconsult-template': 'cleanconsult-template',
                      'mindfulcoaching-template': 'mindfulcoaching-template',
                    })
                    openRemixConfirmModal({ copyId: template })
                  }}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.49994 20.5C4.32994 21.33 5.66994 21.33 6.49994 20.5L19.4999 7.5C20.3299 6.67 20.3299 5.33 19.4999 4.5C18.6699 3.67 17.3299 3.67 16.4999 4.5L3.49994 17.5C2.66994 18.33 2.66994 19.67 3.49994 20.5Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.01 8.99001L15.01 5.99001" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.5 2.44L10 2L9.56 3.5L10 5L8.5 4.56L7 5L7.44 3.5L7 2L8.5 2.44Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.5 8.44L6 8L5.56 9.5L6 11L4.5 10.56L3 11L3.44 9.5L3 8L4.5 8.44Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M19.5 13.44L21 13L20.56 14.5L21 16L19.5 15.56L18 16L18.44 14.5L18 13L19.5 13.44Z" stroke="#BEBEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </Flex>
              </Tooltip>


            </Flex>



          </div>
          <div
            style={{
              width: '224px',
              background: '#303030',
              marginTop: '40px',
              height: 'calc(100vh - 40px)',
              overflow: 'scroll',

            }}
          >
            <SitePropertyHeader
              label={
                t("editor.pages", {
                  defaultValue: "Pages",
                })
              }
              content={<div
                style={{
                  cursor: 'pointer',
                  display: 'flex'
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    spaceStore.addPage()

                  }}>
                  <g id="vuesax/linear/add">
                    <g id="add">
                      <path id="Vector" d="M4 8H12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Vector_2" d="M8 12V4" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>
              </div>}
            />
            {
              spaceStore.websiteData?.pages ? Object.keys(spaceStore.websiteData?.pages)
                .sort(key => spaceStore.websiteData?.pages[key].homepage ? -1 : 1)
                .map((key) => {

                  const isActive = spaceStore.pageId === key
                  return <>
                    <div
                      key={key}
                      style={{
                        fontSize: 12,
                        color: 'gray',
                        padding: '4px 16px',
                        background: isActive ? '#464646' : 'transparent'
                      }}
                      onClick={() => {
                        spaceStore.setPageId(key)
                      }}

                    >

                      <Flex align={'center'}>
                        {!spaceStore.websiteData.pages[key].homepage ? <Flex
                          align={'center'}
                          style={{
                            marginRight: '6px'
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M10.2101 6.79001H3.29004C2.46004 6.79001 1.79004 6.12001 1.79004 5.29001V1.46423" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8.63007 8.36996L10.2101 6.78998L8.63007 5.20996" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </Flex> : null
                        }
                        <Flex
                          align={'center'}
                          style={{
                            marginRight: '6px'
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M10.25 5.095H8.805C7.62 5.095 6.655 4.13 6.655 2.945V1.5C6.655 1.225 6.43 1 6.155 1H4.035C2.495 1 1.25 2 1.25 3.785V8.215C1.25 10 2.495 11 4.035 11H7.965C9.505 11 10.75 10 10.75 8.215V5.595C10.75 5.32 10.525 5.095 10.25 5.095Z" fill="#BEBEBE" />
                            <path d="M7.90003 1.105C7.69503 0.899997 7.34003 1.04 7.34003 1.325V3.07C7.34003 3.8 7.96003 4.405 8.71503 4.405C9.19003 4.41 9.85003 4.41 10.415 4.41C10.7 4.41 10.85 4.075 10.65 3.875C9.93003 3.15 8.64003 1.845 7.90003 1.105Z" fill="#BEBEBE" />
                          </svg>
                        </Flex>

                        <UIContentEditable
                          disabled={false}
                          label=""
                          value={spaceStore.websiteData.pages[key].name}
                          placeholder=""
                          onChange={(value) => {
                            spaceStore.updatePageAttributes({
                              id: key,
                              name: value,
                            })
                          }}
                        />

                        <Flex
                          align={'center'}
                          style={{
                            cursor: 'pointer',
                            marginLeft: '6px'
                          }}
                          onClick={() => {
                            setOpenPageSettings(key)
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M1.5 4.55505V7.44005C1.5 8.50005 1.5 8.50005 2.5 9.17505L5.25 10.7651C5.665 11.0051 6.34 11.0051 6.75 10.7651L9.5 9.17505C10.5 8.50005 10.5 8.50005 10.5 7.44505V4.55505C10.5 3.50005 10.5 3.50005 9.5 2.82505L6.75 1.23505C6.34 0.995054 5.665 0.995054 5.25 1.23505L2.5 2.82505C1.5 3.50005 1.5 3.50005 1.5 4.55505Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 7.5C6.82843 7.5 7.5 6.82843 7.5 6C7.5 5.17157 6.82843 4.5 6 4.5C5.17157 4.5 4.5 5.17157 4.5 6C4.5 6.82843 5.17157 7.5 6 7.5Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </Flex>

                        {spaceStore.websiteData.pages[key].homepage ? (
                          <Flex align={'center'}
                            style={{
                              marginLeft: '6px'
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                              <path d="M6 9V7.5" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5.03501 1.41005L1.57001 4.18505C1.18001 4.49505 0.93001 5.15005 1.01501 5.64005L1.68001 9.62005C1.80001 10.33 2.48001 10.905 3.20001 10.905H8.80001C9.51501 10.905 10.2 10.325 10.32 9.62005L10.985 5.64005C11.065 5.15005 10.815 4.49505 10.43 4.18505L6.96501 1.41505C6.43001 0.985047 5.56501 0.985047 5.03501 1.41005Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </Flex>
                        ) :
                          null
                        }
                      </Flex>
                    </div>
                  </>
                }) : null
            }



            <PageSettingsModal
              opened={openPageSettings}
              close={() => {
                setOpenPageSettings(null)
              }} />

            <SiteSettingsModal
              opened={openSiteSettings}
              close={() => {
                setOpenSiteSettings(false)
              }} />


            <FormsSettingsModal
              opened={openFormsSettings}
              close={() => {
                setOpenFormsSettings(false)
              }} />

            <BillingModal
              opened={spaceStore.billingModalOpen}
              close={() => {
                spaceStore.closeBillingModal()
              }} />

            <SitePropertyDivider />
            <SitePropertyAccordion
              label={
                t("editor.elements", {
                  defaultValue: "Elements",
                })
              }
              defaultChecked={false}
            >
              {Array.from(spaceStore.elementsMap.values())
                .sort((itm1, itm2) => {
                  // sort by x position
                  return itm1.y - itm2.y
                })
                .map((value) => {
                  if (value.parentId) {
                    return null
                  }
                  const getElementType = (value: ShapeElement | TextElement | ImageElement | LanguageSwitcherElement) => {
                    if (value instanceof TextElement) {
                      return t("editor.text", {
                        defaultValue: "Text",
                      })
                    }
                    if (value instanceof ImageElement) {
                      return t("editor.image", {
                        defaultValue: "Image",
                      })
                    }
                    if (value instanceof ShapeElement) {
                      return t("editor.shape", {
                        defaultValue: "Shape",
                      })
                    }
                    if (value instanceof GroupElement) {
                      return t("editor.group", {
                        defaultValue: "Group",
                      })
                    }
                    if (value instanceof FormElement) {
                      return t("editor.form", {
                        defaultValue: "Form",
                      })
                    }
                    if (value instanceof ButtonElement) {
                      return t("editor.button", {
                        defaultValue: "Button",
                      })
                    }
                    if (value instanceof LanguageSwitcherElement) {
                      return t("Language switcher")
                    }
                  }
                  return (
                    <div
                      style={{
                        fontSize: 10, color: 'gray',
                        background: value.id === spaceStore.selectedId ? 'rgba(35, 108, 250, 0.16)' : undefined,
                        padding: '6px 22px',
                      }}
                      key={value.id}

                    >
                      <div

                        style={{
                          display: 'flex', justifyContent: 'space-between',
                          marginLeft: value instanceof GroupElement ? '-18px' : undefined
                        }}
                      >

                        {
                          value instanceof GroupElement ?

                            <Flex
                              align={'center'}
                              onClick={(e) => spaceStore.selectElement(value.id, e.shiftKey)}
                              style={{
                                marginRight: '6px'
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path d="M9.95998 4.47498L6.69998 7.73498C6.31498 8.11998 5.68498 8.11998 5.29998 7.73498L2.03998 4.47498" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>

                            </Flex> : null
                        }

                        <UIContentEditable
                          label=""
                          value={value.name || getElementType(value)!}
                          placeholder=""
                          disabled={value instanceof GroupElement}
                          onChange={(text) => {
                            spaceStore.updateElementProps({
                              id: value.id,
                              name: text,
                            }, false)
                          }}
                        />
                        <Flex
                          align={'center'}
                          onClick={(e) => {

                            spaceStore.selectElement(value.id, e.shiftKey)
                          }}
                          style={{
                            marginLeft: '6px'
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M7.79002 5.99996C7.79002 6.98996 6.99002 7.78996 6.00002 7.78996C5.01002 7.78996 4.21002 6.98996 4.21002 5.99996C4.21002 5.00996 5.01002 4.20996 6.00002 4.20996C6.99002 4.20996 7.79002 5.00996 7.79002 5.99996Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.99998 10.135C7.76498 10.135 9.40998 9.09499 10.555 7.29499C11.005 6.58999 11.005 5.40499 10.555 4.69999C9.40998 2.89999 7.76498 1.85999 5.99998 1.85999C4.23498 1.85999 2.58998 2.89999 1.44498 4.69999C0.994983 5.40499 0.994983 6.58999 1.44498 7.29499C2.58998 9.09499 4.23498 10.135 5.99998 10.135Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </Flex>


                        <Flex
                          onClick={() => deleteElementModal(value.id)}
                          align={'center'}
                          style={{
                            marginLeft: '6px'
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M10.5 2.98999C8.835 2.82499 7.16 2.73999 5.49 2.73999C4.5 2.73999 3.51 2.78999 2.52 2.88999L1.5 2.98999" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.25 2.485L4.36 1.83C4.44 1.355 4.5 1 5.345 1H6.655C7.5 1 7.565 1.375 7.64 1.835L7.75 2.485" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.42501 4.56995L9.10001 9.60495C9.04501 10.3899 9.00001 10.9999 7.60501 10.9999H4.39501C3.00001 10.9999 2.95501 10.3899 2.90001 9.60495L2.57501 4.56995" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.16498 8.25H6.82998" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.75 6.25H7.25" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </Flex>

                      </div>
                      {
                        value instanceof GroupElement ? <div>
                          {
                            Array.from(spaceStore.elementsMap.values()).filter(itm => itm.parentId === value.id).map(itm => {
                              return (
                                <div
                                  key={itm.id}
                                  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '6px' }}>

                                  <Flex
                                    align={'center'}
                                    style={{
                                      marginRight: '6px'
                                    }}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <path d="M10.2101 6.79001H3.29004C2.46004 6.79001 1.79004 6.12001 1.79004 5.29001V1.46423" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M8.63007 8.36996L10.2101 6.78998L8.63007 5.20996" stroke="#BEBEBE" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </Flex>
                                  <TextInput
                                    label=""
                                    size="xs"
                                    styles={{
                                      input: {
                                        paddingLeft: '4px',
                                        paddingRight: '4px',
                                        background: 'transparent',
                                        minHeight: '1.4rem',
                                        height: '1.4rem',
                                        borderColor: '#464646'
                                      },
                                      root: {
                                        width: '100%',
                                      }
                                    }}
                                    value={itm.name || getElementType(itm)}
                                    placeholder=""
                                    onChange={(e) => {
                                      spaceStore.updateElementProps({
                                        id: itm.id,
                                        name: e.target.value,

                                      }, false)
                                    }}
                                  />
                                  {/* <Flex
                                    align={'center'}
                                    onClick={(e) => {
                                      spaceStore.selectElement(itm.id, e.shiftKey)
                                    }}
                                    style={{
                                      marginLeft: '6px'
                                    }}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <path d="M7.79002 5.99996C7.79002 6.98996 6.99002 7.78996 6.00002 7.78996C5.01002 7.78996 4.21002 6.98996 4.21002 5.99996C4.21002 5.00996 5.01002 4.20996 6.00002 4.20996C6.99002 4.20996 7.79002 5.00996 7.79002 5.99996Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M5.99998 10.135C7.76498 10.135 9.40998 9.09499 10.555 7.29499C11.005 6.58999 11.005 5.40499 10.555 4.69999C9.40998 2.89999 7.76498 1.85999 5.99998 1.85999C4.23498 1.85999 2.58998 2.89999 1.44498 4.69999C0.994983 5.40499 0.994983 6.58999 1.44498 7.29499C2.58998 9.09499 4.23498 10.135 5.99998 10.135Z" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                  </Flex> */}
                                  {/* <Popconfirm
                                    title={"Are you sure?"}
                                    onConfirm={() => spaceStore.deleteElement(itm.id)}
                                    okText="Yes"
                                    cancelText="No"
                                  > */}

                                  <Flex
                                    align={'center'}
                                    // onClick={(e) => spaceStore.selectElement(itm.id, e.shiftKey)}
                                    style={{
                                      marginLeft: '6px'
                                    }}
                                    onClick={() => deleteElementModal(itm.id)}
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                      <path d="M10.5 2.98999C8.835 2.82499 7.16 2.73999 5.49 2.73999C4.5 2.73999 3.51 2.78999 2.52 2.88999L1.5 2.98999" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M4.25 2.485L4.36 1.83C4.44 1.355 4.5 1 5.345 1H6.655C7.5 1 7.565 1.375 7.64 1.835L7.75 2.485" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M9.42501 4.56995L9.10001 9.60495C9.04501 10.3899 9.00001 10.9999 7.60501 10.9999H4.39501C3.00001 10.9999 2.95501 10.3899 2.90001 9.60495L2.57501 4.56995" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M5.16498 8.25H6.82998" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                                      <path d="M4.75 6.25H7.25" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                  </Flex>
                                  {/* </Popconfirm> */}

                                </div>
                              )
                            })}
                        </div> : null
                      }
                    </div>
                  )
                })}
            </SitePropertyAccordion>
          </div>
          <div style={{
            // marginTop: '40px',
            width: `${width as any - 264 - 264}px`,
            background: '#989898'

          }} className={classes.iframe}
          >
            <Group>
              {/* <IndexPage site={data} /> */}
              <div style={{ width: '100%', height: 'calc ( 100vh - 40px ) ', background: 'white', position: 'relative', marginTop: '40px' }}>
                <Test />
              </div>

            </Group>
          </div>

          <div
            style={{
              width: '264px',
              background: '#303030',
              marginTop: '40px',
              height: 'calc(100vh - 40px)',
              overflow: 'scroll',
            }}
          >
            <EditAttributes />
          </div>
        </Grid >
      </div >
      <Modal
        opened={!!sitePreview}
        onClose={() => setSitePreview(null)}
        title={' '}
        fullScreen
        yOffset="0" xOffset={0}
        radius={0}
        zIndex={11111111}
        transitionProps={{ transition: 'fade', duration: 200 }}
        styles={{
          header: {
            position: 'fixed',
            zIndex: 1,
            background: 'transparent !important',
            width: 'max-content',
            right: '0',
          },
          body: {
            height: '100vh',
            padding: 0,
          },
          content: {
            height: '100%',
          },
        }}
      >

        <Flex w={'100%'} mih="calc(100vh - 74px)" justify={'center'}>
          <IframePreview site={sitePreview!} height={1200} />
        </Flex>
      </Modal >

      <SiteGenImageModal />
      <SiteStatisticsModal />
      <SiteLangModal />
    </AppShell >


  );
};

export default observer(SiteDetails);
