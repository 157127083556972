import { useStore } from '../../../hooks/use-store';
import { GroupElementProps } from '../../../stores/data/space-store/groupElement';
import Moveable from 'react-moveable';
import { useEffect, useRef } from 'react';
import { FloatingButtons } from './FloatingButtons';
import { ParralaxPreview } from './ParralaxPreview';

export function GroupElementComponent(props: GroupElementProps & { selected: boolean, zoom: number, children: any, idsMap: string[], selectedClassName: string }) {

  const {
    dataStore: { spaceStore },
  } = useStore();

  const moveableRef = useRef<Moveable>(null);

  const {
    id,
    x,
    y,
    width,
    height,
    position,
    bgColor,
    zIndex,
    opacity,
    borderRadius,
    rotate,

    zoom,
    parralax
  } = props
  useEffect(() => {
    try {
      moveableRef.current?.setState({
        target: [`.${id}`]
      }, () => {
        try {
          moveableRef.current?.forceUpdate()
          moveableRef.current?.updateRect();
        } catch (error) {
          console.log('error', error)
        }

      });
    } catch (error) {
      console.log('error', error)
    }

  }, [x, y, width, height, rotate, id])

  const snapHorizontalThreshold = zoom < 1 ? 5 : 1

  return (
    <>
      <div
        onClick={(e) => {
          spaceStore.selectElement(id, e.shiftKey)
        }}
        className={`${id}${props.selectedClassName ? ` ${props.selectedClassName}` : ''}`}
        style={{
          position,
          backgroundColor: bgColor || undefined,
          zIndex,
          opacity,
          borderRadius,
          top: 0,
          left: 0,
          width,
          height,
          transform: `translate(${x}px, ${y}px) rotate(${rotate || 0}deg)`,
        }}>
        {
          parralax ?
            <ParralaxPreview
              width={parralax.width}
              height={height}
              elementWidth={width}
              margin={parralax.margin}
              zIndex={zIndex}
            /> : null
        }
        <FloatingButtons
          id={id}
          drag={true}
          selected={props.selected}
          onClickDelete={() => spaceStore.deleteElement(spaceStore.selected!.id)}
          onClickDuplicate={() => spaceStore.duplicate(spaceStore.selected!.id)}
          zoom={zoom}
        />
        {props.children}
      </div>
      <Moveable
        ref={moveableRef}
        zoom={1 / zoom}
        target={props.selected ? `.${id}` : null}
        dragTarget={'.moveable-drag-target'}
        draggable={true}
        resizable={false}
        persistData={{
          left: x,
          top: y,
          width: width,
          height: height,
        }}
        pinchable={false}

        snappable={true}
        isDisplaySnapDigit={true}
        isDisplayInnerSnapDigit={false}
        snapGap={true}
        snapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
        elementSnapDirections={{ "top": true, "left": true, "bottom": true, "right": true, "center": true, "middle": true }}
        snapThreshold={0}
        maxSnapElementGuidelineDistance={300}
        useAccuratePosition={true}
        elementGuidelines={props.idsMap}
        snapHorizontalThreshold={snapHorizontalThreshold}
        snapVerticalThreshold={snapHorizontalThreshold}
        verticalGuidelines={
          [0, 20, 720, 1420, 1440,]
        }
        horizontalGuidelines={
          [0, 1440, 600,]
        }
        rotatable={true}
        onRenderEnd={e => {
          const cssText = e.target.style.cssText
          let width = undefined
          if (cssText.includes('width: ')) {
            width = +cssText.split('width: ')[1].split('px')[0]
          }
          let height = undefined
          if (cssText.includes('height: ')) {
            height = +cssText.split('height: ')[1].split('px')[0]
          }

          let rotate = undefined
          if (e.transform.includes('rotate(')) {
            rotate = +e.transform.split('rotate(')[1].split('deg')[0]
          }
          spaceStore.updateElementProps({
            id: props.id,
            x: e.transformObject.translate[0],
            y: e.transformObject.translate[1],
            width,
            height,
            rotate,
          })
        }}
        onRender={e => {
          e.target.style.cssText += e.cssText;
        }}
      />
    </>
  );
}
