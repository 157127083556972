// import { useWindowSize } from './helper';
import { useStore } from '../../../hooks/use-store';
import { observer } from 'mobx-react';
import TextElement from '../../../stores/data/space-store/textElement';
import ShapeElement from '../../../stores/data/space-store/shapeElement';
import ImageElement from '../../../stores/data/space-store/imageElement';
import GroupElement from '../../../stores/data/space-store/groupElement';

import ShapeElementComponent from './ShapeElementComponent';
import TextElementComponent from './TextElementComponent';
import ImageElementComponent from './ImageElementComponent';
import FormElementComponent from './FormElementComponent';
import ButtonElementComponent from './ButtonElementComponent';

import { GroupElementComponent } from './GroupElementComponent';
import TextEditor from './TextEditor';
import ReactPlayer from 'react-player';
import FormElement from '../../../stores/data/space-store/formElement';
import { EmblaCarousel } from './Carousel';
import { Flex } from '@mantine/core';
import ButtonElement from '../../../stores/data/space-store/buttonElement';
import ButtonTextEditor from './ButtonTextEditor';
import { ShiftGroupElementComponent } from './ShiftGroupElementComponent';
import FormElementContent from './FormElementContent';
import LanguageSwitcherElement from '../../../stores/data/space-store/languageSwitcherElement';
import LanguageSwitcherComponent from './LanguageSwitcherComponent';

const SiteContent = () => {
  const {
    dataStore: { spaceStore },
  } = useStore();

  const elements = Array.from(spaceStore.elementsMap.values());
  // const { width, height } = useWindowSize();

  // useEffect(() => {
  //   console.log('spaceStore.selectedId', spaceStore.selectedId)
  //   WebFont.load({
  //     google: {
  //       families: ['Droid Sans', 'Droid Serif']
  //     }
  //   });
  // }, [
  //   spaceStore.selectedId
  // ])

  const idsMap = elements.map(itm => `.${itm.id}`)

  // inside component:

  // const [scrollPosition, setScrollPosition] = useState(0);


  // const zoom = (width / 1440).toFixed(5);
  return (
    <div style={{ position: 'relative', width: spaceStore.currentViewport, height: '100%', overflow: spaceStore.clipView ? 'clip' : undefined }}
      onClick={() => {
        console.log('click here')
        // spaceStore.selectElement(null, false)
      }}
    >
      <div className="cvs" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: spaceStore.getActivePageHeight, zIndex: 1 }}
        onClick={() => {
          spaceStore.selectElement(null, false)
        }}>

      </div>

      <ShiftGroupElementComponent
        selectedWithShift={spaceStore.selectedWithShift}
        zoom={spaceStore.zoom}
        idsMap={idsMap}
      />
      {
        elements.map((value) => {
          if (value.parentId) {
            return null
          }

          if (value instanceof TextElement) {
            const v = value as TextElement;
            return (

              <div
                key={value.id}
                onClick={(e) => {
                  spaceStore.selectElement(value.id, e.shiftKey)
                }}>
                <TextElementComponent
                  selectedClassName={spaceStore.selectedWithShift.includes(value.id) ? `selectedShift${spaceStore.selectedWithShift.length}` : ''}
                  idsMap={idsMap}
                  zoom={spaceStore.zoom}
                  selected={spaceStore.selectedId === value.id}
                  {...v}
                />
              </div>


            );
          }
          else if (value instanceof ShapeElement) {
            const v = value as ShapeElement;
            return (
              <div
                key={value.id}
                onClick={(e) => {
                  spaceStore.selectElement(value.id, e.shiftKey)
                }}>
                <ShapeElementComponent
                  selectedClassName={spaceStore.selectedWithShift.includes(value.id) ? `selectedShift${spaceStore.selectedWithShift.length}` : ''}
                  idsMap={idsMap}
                  zoom={spaceStore.zoom}
                  selected={spaceStore.selectedId === value.id}
                  {...v}
                />
              </div>
            );
          }
          else if (value instanceof ButtonElement) {
            const v = value as ButtonElement;
            return (
              <div
                key={value.id}
                onClick={(e) => {
                  spaceStore.selectElement(value.id, e.shiftKey)
                }}>
                <ButtonElementComponent
                  selectedClassName={spaceStore.selectedWithShift.includes(value.id) ? `selectedShift${spaceStore.selectedWithShift.length}` : ''}
                  idsMap={idsMap}
                  zoom={spaceStore.zoom}
                  selected={spaceStore.selectedId === value.id}
                  {...v}
                />
              </div>
            );
          }
          else if (value instanceof ImageElement) {
            const v = value as ImageElement;
            return (
              <div
                key={value.id}
                onClick={(e) => {
                  spaceStore.selectElement(value.id, e.shiftKey)
                }}>
                <ImageElementComponent
                  selectedClassName={spaceStore.selectedWithShift.includes(value.id) ? `selectedShift${spaceStore.selectedWithShift.length}` : ''}
                  idsMap={idsMap}
                  zoom={spaceStore.zoom}
                  selected={spaceStore.selectedId === value.id}
                  {...v}
                />
              </div>
            );
          } else if (value instanceof FormElement) {
            const v = value as FormElement;
            return (
              <div
                key={value.id}
                onClick={(e) => {
                  spaceStore.selectElement(value.id, e.shiftKey)
                }}>
                <FormElementComponent
                  attributes={v}
                  selectedClassName={spaceStore.selectedWithShift.includes(value.id) ? `selectedShift${spaceStore.selectedWithShift.length}` : ''}
                  idsMap={idsMap}
                  zoom={spaceStore.zoom}
                  selected={spaceStore.selectedId === value.id}
                />
              </div>
            );
          }  else if (value instanceof LanguageSwitcherElement) {
            const v = value as LanguageSwitcherElement;
            return (
              <div
                key={value.id}
                onClick={(e) => {
                  spaceStore.selectElement(value.id, e.shiftKey)
                }}>
                <LanguageSwitcherComponent
                  attributes={v}
                  selectedClassName={spaceStore.selectedWithShift.includes(value.id) ? `selectedShift${spaceStore.selectedWithShift.length}` : ''}
                  idsMap={idsMap}
                  zoom={spaceStore.zoom}
                  selected={spaceStore.selectedId === value.id}
                />
              </div>
            );
          } else if (value instanceof GroupElement) {
            const v = value as GroupElement;

            const parentElements = elements.filter(e => e.parentId === value.id)

            return (
              <div
                key={value.id}
                onClick={(e) => {
                  spaceStore.selectElement(value.id, e.shiftKey)
                }}>
                <GroupElementComponent
                  selectedClassName={spaceStore.selectedWithShift.includes(value.id) ? `selectedShift${spaceStore.selectedWithShift.length}` : ''}
                  idsMap={idsMap}
                  zoom={spaceStore.zoom}
                  selected={spaceStore.selectedId === value.id}
                  {...v}
                >
                  <div>
                    {parentElements.map(itm => {
                      if (itm instanceof TextElement) {
                        const value = itm as TextElement;
                        return (
                          <div
                            className={`${spaceStore.selectedId === itm.id ? 'selected-element' : ''}`}
                            key={itm.id}
                            style={{
                              position: 'absolute',
                              backgroundColor: value.bgColor || undefined,
                              zIndex: value.zIndex,
                              opacity: value.opacity,
                              // borderRadius: newTexxValue.borderRadius,
                              left: value.x,
                              top: value.y,
                              width: value.width,
                              transform: `rotate(${value.rotate}deg)`,
                            }}
                            onClick={(e) => {
                              // spaceStore.selectElement(itm.id, e.shiftKey)
                            }}>
                            <TextEditor
                              data={value}
                              readonly={true}
                            // {...newTexxValue}
                            />
                          </div>
                        );
                      } else if (itm instanceof ShapeElement) {
                        const value = itm as ShapeElement;
                        return (
                          <div
                            className={`${spaceStore.selectedId === itm.id ? 'selected-element' : ''}`}
                            key={itm.id}
                            style={{
                              position: 'absolute',
                              backgroundColor: value.bgColor || undefined,
                              zIndex: value.zIndex,
                              opacity: value.opacity,
                              borderRadius: value.borderRadius,
                              left: value.x,
                              top: value.y,
                              width: value.width,
                              height: value.height,
                              transform: `rotate(${value.rotate}deg)`,
                              border: `${value.borderWidth}px solid ${value.borderColor}`,
                            }}
                          >
                            {value.type === 'CODE' ? (
                              <div style={{
                                background: 'black',
                                height: '100%',
                                width: '100%',
                                color: 'white',
                                padding: '14px',
                                fontSize: '10px',
                                textAlign: 'center',
                                overflow: 'hidden'
                              }}>
                                <span>This script embed will only appear on the published site</span>
                              </div>
                            ) : null
                            }
                          </div>
                        );
                      }
                      else if (itm instanceof ButtonElement) {
                        const value = itm as ButtonElement;
                        return (
                          <div
                            className={`${spaceStore.selectedId === itm.id ? 'selected-element' : ''}`}
                            key={itm.id}
                            style={{
                              position: 'absolute',
                              backgroundColor: value.bgColor || undefined,
                              zIndex: value.zIndex,
                              opacity: value.opacity,
                              borderRadius: value.borderRadius,
                              left: value.x,
                              top: value.y,
                              width: value.width,
                              height: value.height,
                              transform: `rotate(${value.rotate}deg)`,
                              border: `${value.borderWidth}px solid ${value.borderColor}`,
                            }}
                          >
                            <div
                              style={{
                                color: value.color,
                                textAlign: value.textAlign,
                                fontSize: value.fontSize,
                                fontWeight: value.fontWeight,
                                fontFamily: `'${value.typefaceId}'`,
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                              }}
                            >
                              <ButtonTextEditor
                                data={value}
                              />
                            </div>
                          </div>
                        );
                      }
                      else if (itm instanceof FormElement) {
                        const value = itm as FormElement;
                        return (
                          <div
                            className={`${spaceStore.selectedId === itm.id ? 'selected-element' : ''}`}
                            key={itm.id}
                            // style={{
                            //   position: 'absolute',
                            //   backgroundColor: value.bgColor || undefined,
                            //   zIndex: value.zIndex,
                            //   opacity: value.opacity,
                            //   borderRadius: value.borderRadius,
                            //   left: value.x,
                            //   top: value.y,
                            //   width: value.width,
                            //   height: value.height,
                            //   transform: `rotate(${value.rotate}deg)`,
                            // }}
                          >
                            <FormElementContent
                              selectedClassName=''
                              attributes={value}
                              onSelect={() => { }} />
                          </div>
                        );
                      }
                      else if (itm instanceof ButtonElement) {
                        const value = itm as ButtonElement;
                        return (
                          <div
                            className={`${spaceStore.selectedId === itm.id ? 'selected-element' : ''}`}
                            key={itm.id}
                            style={{
                              position: 'absolute',
                              backgroundColor: value.bgColor || undefined,
                              zIndex: value.zIndex,
                              opacity: value.opacity,
                              borderRadius: value.borderRadius,
                              left: value.x,
                              top: value.y,
                              width: value.width,
                              height: value.height,
                              transform: `rotate(${value.rotate}deg)`,
                              border: `${value.borderWidth}px solid ${value.borderColor}`,
                            }}
                          >
                            <div
                              style={{
                                color: value.color,
                                textAlign: value.textAlign,
                                fontSize: value.fontSize,
                                fontWeight: value.fontWeight,
                                fontFamily: `'${value.typefaceId}'`,
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%'
                              }}
                            >
                              <ButtonTextEditor
                                data={value}
                              />
                            </div>
                          </div>
                        );
                      }
                      else if (itm instanceof ImageElement) {
                        const value = itm as ImageElement;
                        return (
                          <div
                            className={`${spaceStore.selectedId === itm.id ? 'selected-element' : ''}`}
                            key={itm.id}
                            style={{
                              position: 'absolute',
                              backgroundColor: value.bgColor || undefined,
                              zIndex: value.zIndex,
                              opacity: value.opacity,
                              borderRadius: value.borderRadius,
                              left: value.x,
                              top: value.y,
                              width: value.width,
                              height: value.height,
                              transform: `rotate(${value.rotate}deg)`,
                            }}
                          >
                            <Flex
                              align={'center'}
                              justify={'center'}
                              style={{
                                userSelect: 'none',
                                pointerEvents: 'none',
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              {
                                value.type === 'IMAGE' ? (
                                  value.src ?
                                    <img
                                      style={{
                                        borderRadius: value.borderRadius,
                                        objectFit: value.objectFit as any,
                                        width: '100%',
                                        height: '100%',
                                      }}
                                      src={value.src}
                                      alt={value.alt || ''}
                                    /> : (
                                      <Flex justify={'center'} align={'center'} bg={
                                        value.bgColor || 'rgba(190, 190, 190, 1)'}
                                        style={{
                                          borderRadius: value.borderRadius,
                                          width: '100%',
                                          height: '100%',
                                        }}
                                      >
                                        <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M71.3763 54.9617L71.3755 54.9598L61.203 31.1698L61.2024 31.1685C59.4007 26.9327 56.8235 24.7775 54.0865 24.6364L54.0861 24.6364C51.3826 24.4956 48.5766 26.3777 46.3309 30.4262L46.3302 30.4274L40.1557 41.5091C38.7787 43.9877 36.7392 45.601 34.3983 45.792C32.0195 46.0163 29.7479 44.7581 27.9903 42.5182L71.3763 54.9617ZM71.3763 54.9617C72.9993 58.7281 72.6306 63.0395 70.3069 66.4637L70.3018 66.4714C68.036 69.9008 64.2089 71.9524 60.1051 71.9524H18.6351C14.378 71.9524 10.4915 69.7799 8.2554 66.1653C6.02176 62.5548 5.83533 58.1184 7.73621 54.2847C7.73646 54.2842 7.73672 54.2837 7.73697 54.2831L13.3571 43.0754C13.3572 43.0751 13.3574 43.0748 13.3576 43.0745C14.9373 39.9465 17.251 38.1533 19.8472 37.8937C22.4552 37.6329 25.0847 38.8633 27.2771 41.6104C27.2776 41.6112 27.2782 41.6119 27.2788 41.6126L27.99 42.5178L71.3763 54.9617Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                                          <path d="M33.3875 16.2499C33.3875 21.9025 28.8051 26.4849 23.1525 26.4849C17.4998 26.4849 12.9175 21.9025 12.9175 16.2499C12.9175 10.5973 17.4998 6.01489 23.1525 6.01489C28.8051 6.01489 33.3875 10.5973 33.3875 16.2499Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                                        </svg>
                                      </Flex>
                                    )
                                ) : null
                              }
                              {
                                value.type === 'VIDEO' ?
                                  (
                                    value.src ? <ReactPlayer
                                      style={{
                                        borderRadius: value.borderRadius,
                                        objectFit: value.objectFit as any,
                                      }}
                                      controls={true}
                                      width={'100%'}
                                      height={'100%'}
                                      url={value.src}
                                    /> : <Flex justify={'center'} align={'center'} bg={
                                      value.bgColor || 'rgba(190, 190, 190, 1)'}
                                      style={{
                                        borderRadius: value.borderRadius,
                                        width: '100%',
                                        height: '100%',
                                      }}
                                    >
                                      <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M71.3763 54.9617L71.3755 54.9598L61.203 31.1698L61.2024 31.1685C59.4007 26.9327 56.8235 24.7775 54.0865 24.6364L54.0861 24.6364C51.3826 24.4956 48.5766 26.3777 46.3309 30.4262L46.3302 30.4274L40.1557 41.5091C38.7787 43.9877 36.7392 45.601 34.3983 45.792C32.0195 46.0163 29.7479 44.7581 27.9903 42.5182L71.3763 54.9617ZM71.3763 54.9617C72.9993 58.7281 72.6306 63.0395 70.3069 66.4637L70.3018 66.4714C68.036 69.9008 64.2089 71.9524 60.1051 71.9524H18.6351C14.378 71.9524 10.4915 69.7799 8.2554 66.1653C6.02176 62.5548 5.83533 58.1184 7.73621 54.2847C7.73646 54.2842 7.73672 54.2837 7.73697 54.2831L13.3571 43.0754C13.3572 43.0751 13.3574 43.0748 13.3576 43.0745C14.9373 39.9465 17.251 38.1533 19.8472 37.8937C22.4552 37.6329 25.0847 38.8633 27.2771 41.6104C27.2776 41.6112 27.2782 41.6119 27.2788 41.6126L27.99 42.5178L71.3763 54.9617Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                                        <path d="M33.3875 16.2499C33.3875 21.9025 28.8051 26.4849 23.1525 26.4849C17.4998 26.4849 12.9175 21.9025 12.9175 16.2499C12.9175 10.5973 17.4998 6.01489 23.1525 6.01489C28.8051 6.01489 33.3875 10.5973 33.3875 16.2499Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                                      </svg>
                                    </Flex>
                                  )
                                  : null
                              }
                              {
                                value.type === 'SLIDER' ? (value.set.length > 0 ?
                                  <EmblaCarousel
                                    options={{
                                      duration: value.transitionDuration,
                                      loop: true,
                                    }}
                                    delay={value.sliderDuration}
                                    slides={value.set}
                                    objectFit={value.objectFit}
                                    borderRadius={value.borderRadius}
                                  /> : <Flex justify={'center'} align={'center'} bg={
                                    value.bgColor || 'rgba(190, 190, 190, 1)'}
                                    style={{
                                      borderRadius: value.borderRadius,
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  >
                                    <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M71.3763 54.9617L71.3755 54.9598L61.203 31.1698L61.2024 31.1685C59.4007 26.9327 56.8235 24.7775 54.0865 24.6364L54.0861 24.6364C51.3826 24.4956 48.5766 26.3777 46.3309 30.4262L46.3302 30.4274L40.1557 41.5091C38.7787 43.9877 36.7392 45.601 34.3983 45.792C32.0195 46.0163 29.7479 44.7581 27.9903 42.5182L71.3763 54.9617ZM71.3763 54.9617C72.9993 58.7281 72.6306 63.0395 70.3069 66.4637L70.3018 66.4714C68.036 69.9008 64.2089 71.9524 60.1051 71.9524H18.6351C14.378 71.9524 10.4915 69.7799 8.2554 66.1653C6.02176 62.5548 5.83533 58.1184 7.73621 54.2847C7.73646 54.2842 7.73672 54.2837 7.73697 54.2831L13.3571 43.0754C13.3572 43.0751 13.3574 43.0748 13.3576 43.0745C14.9373 39.9465 17.251 38.1533 19.8472 37.8937C22.4552 37.6329 25.0847 38.8633 27.2771 41.6104C27.2776 41.6112 27.2782 41.6119 27.2788 41.6126L27.99 42.5178L71.3763 54.9617Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                                      <path d="M33.3875 16.2499C33.3875 21.9025 28.8051 26.4849 23.1525 26.4849C17.4998 26.4849 12.9175 21.9025 12.9175 16.2499C12.9175 10.5973 17.4998 6.01489 23.1525 6.01489C28.8051 6.01489 33.3875 10.5973 33.3875 16.2499Z" fill="#989898" stroke="#989898" strokeWidth="1.5" />
                                    </svg>
                                  </Flex>)
                                  : null
                              }

                            </Flex>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                </GroupElementComponent>
              </div>
            );
          } else {
            return null;
          }
        })
      }
    </div >
  );
}

export default observer(SiteContent);