import { getEnv } from "../../../helpers/mobx-easy-wrapper";

export class AppStore {
  listSpaces = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.spacesService.list();
  };

  getDefaultFunnel = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getDefaultFunnel();
  };

  getWebsiteById = async (siteId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getWebsiteById(siteId);
  };

  getPipelineById = async (jobId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getPipelineById(jobId);
  };

  getPipelines = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getPipelines();
  };

  updateFreeDomain = async (model: { siteId: string, freeDomain: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.updateFreeDomain(model);
  };
  sendSurveyRequest = async (model: any) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.sendSurveyRequest(model);
  };

  createPipeline = async (model: any) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.createPipeline(model);
  };

  updatePremiumDomain = async (model: { siteId: string, domain: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.updatePremiumDomain(model);
  };

  getCheckoutLink = async (model: { siteId: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getCheckoutLink(model);
  };

  getPortalLink = async (model: { siteId: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getPortalLink(model);
  };

  getFormSubmissionByWebsiteId = async (siteId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getFormSubmissionByWebsiteId(siteId);
  };
  getStatisticsByWebsiteId = async (siteId: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getStatisticsByWebsiteId(siteId);
  };
  translate = async (model: {
    fromLang: string;
    toLang: string;
    texts: {
      key: string;
      value: string;
    }[];
  }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.translate(model);
  };

  sendCode = async (model: { email: string }) => {
    const { apiFactory } = getEnv();

    let fbpArr = document.cookie
      .split(";")
      .filter((c) => c.includes("_fbp="))
      .map((c) => c.split("_fbp=")[1]);
    let fbcArr = document.cookie
      .split(";")
      .filter((c) => c.includes("_fbc="))
      .map((c) => c.split("_fbc=")[1]);
    let fbp = (fbpArr.length && fbpArr[0]) || null;
    let fbc = (fbcArr.length && fbcArr[0]) || null;

    if (!fbc && window.location.search.includes("fbclid=")) {
      fbc =
        "fb.1." +
        +new Date() +
        "." +
        window.location.search.split("fbclid=")[1];
    }

    try {
      var callback = function () {
      };
      // @ts-ignore
      window.gtag && window.gtag('event', 'conversion', {
        'send_to': 'AW-16521667272/AT-eCJC23KMZEMjFksY9',
        'value': 1.0,
        'currency': 'EUR',
        'transaction_id': '',
        'event_callback': callback
      });
    } catch (e) {
    }

    return apiFactory.apiService.sendCode({
      ...model,
      fbp,
      fbc,
    });
  };

  getFunnelsListByUser = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getFunnelsListByUser();
  };

  createWebsite = async (model: { name: string, freeDomain?: string, replaceId?: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.createWebsite(model);
  };

  deleteFunnel = async (id: string) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.deleteFunnel(id);
  };

  rewrite = async (model: { text: string; tone: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.rewrite(model);
  };

  convertImage = async (model: { url: string, websiteId: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.convertImage(model);
  };

  generateImage = async (model: { text: string; ratio: string, websiteId: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.generateImage(model);
  };

  getVideoId = async () => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.getVideoId();
  };

  updateFunnel = async (model: { id: string; data?: any; draft?: any, name: string }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.updateFunnel(model);
  };
  previewSite = async (model: { draft: any }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.previewSite(model);
  };

  generateWebsite = async (model: {
    type: string,
    style: string,
    prompt: string,
    lang: string,
    copyId: string,
    replaceId?: string,
  }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.generateWebsite(model);
  };

  genTerms = async (model: {
    type: string,
    prompt: string,
    lang: string,
  }) => {
    const { apiFactory } = getEnv();
    return apiFactory.apiService.genTerms(model);
  };
}
