import { Modal, Image, Text, Flex } from '@mantine/core';

import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { Widget } from '@uploadcare/react-widget';
import { UIButton } from '../../components/ui-kit/button/UIButton';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';
import { UITextInput } from '../../components/ui-kit/textInput/UINumberInput';
// @ts-ignore 
import UrlSafeString from 'url-safe-string'
import { useState } from 'react';
import { SiteGenImageButton } from './SiteGenImageButton';

const tagGenerator = new UrlSafeString({
    maxLen: 150,
    lowercaseOnly: false,
    joinString: '-',
    trimWhitespace: false
});

export const Element = (props: {
    opened: string | null
    close: () => void
}) => {

    const [urlError, setUrlError] = useState('')

    const { t } = useTranslation();

    const {
        dataStore: { spaceStore, appStore },
    } = useStore();

    const { runAsync: runAsyncConvert } = useRequest(
        async (model: { url: string }) => appStore.convertImage({
            url: model.url,
            websiteId: spaceStore.siteId,
        }),
        {
            manual: true,
            onSuccess: (res) => {
                if (res.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    return res.url
                    // spaceStore.updateElementProps({
                    //   id: spaceStore.selected?.id,
                    //   src: res.url,
                    // })
                }
            },
        }
    );

    const key = props.opened
    if (!key || !spaceStore.websiteData.pages[key]) {
        return null
    }
    return (
        <Modal opened={!!props.opened} onClose={props.close} title={t("page.settings")} size={'lg'} padding={32}>
            <div>
                {
                    spaceStore.websiteData.pages[key].homepage ? <Flex gap={16}>


                        <UIButton
                            fluid={false}
                            color='gray'
                            onClick={() => {
                                spaceStore.dublicatePage(key);
                                props.close()
                            }}
                            loading={false}
                        >
                            {t("duplicate.page")}

                        </UIButton>
                    </Flex> : (
                        <>
                            <Flex gap={8} direction={'column'}>
                                <Text style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}>
                                    {t("slug")}
                                </Text>
                                <Text
                                    style={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {spaceStore.domain ? `https://${spaceStore.domain}/` : `https://${spaceStore.freeDomain}.klykn.com/`}
                                    <span style={{
                                        color: '#36E678'
                                    }}>
                                        {spaceStore.websiteData.pages[key].url}
                                    </span>
                                </Text>
                                <UITextInput
                                    value={spaceStore.websiteData.pages[key].url}
                                    placeholder=""
                                    description={urlError}
                                    onChange={(e) => {
                                        const url: string = tagGenerator.generate(e)
                                        console.log(url, e)

                                        if (url === spaceStore.websiteData.pages[key].url) {
                                            setUrlError(`"${e.replace(url, '')}" is not valid symbol for slug`)
                                        } else {
                                            setUrlError('')
                                        }
                                        spaceStore.updatePageAttributes({
                                            id: key,
                                            url,
                                        })
                                    }}
                                />


                            </Flex>
                            <br />
                            <Flex gap={16}>
                                <UIButton
                                    fluid={false}
                                    color='gray'
                                    onClick={() => {
                                        spaceStore.setHomepage(key);
                                    }}
                                    loading={false}
                                >
                                    {t("set.homepage")}

                                </UIButton>

                                <UIButton
                                    fluid={false}
                                    color='gray'
                                    onClick={() => {
                                        spaceStore.dublicatePage(key);
                                        props.close()
                                    }}
                                    loading={false}
                                >
                                    {t("duplicate.page")}

                                </UIButton>

                                <UIButton
                                    fluid={false}
                                    color='gray'
                                    onClick={() => {
                                        spaceStore.deletePage(key)
                                    }}
                                    loading={false}
                                >
                                    {t("delete.page")}
                                </UIButton>
                            </Flex>
                            <br />
                        </>


                    )
                }

                <Flex justify={'space-between'} align={'center'} mt={10} mb={10} w={'100%'}>
                    <Text fw={'500'} size={12} color='rgba(190, 190, 190, 1)'>
                        {t("page.title")}
                    </Text>
                    <div style={{ width: '400px' }}>
                        <UITextInput
                            value={spaceStore.websiteData.pages[key].title}
                            placeholder={t("enter.title.here")}
                            onChange={(e) => {
                                spaceStore.websiteData.pages[key].title = e
                            }}
                        />
                    </div>
                </Flex>
                <Flex justify={'space-between'} align={'center'} mt={10} mb={10} w={'100%'}>
                    <Text fw={'500'} size={12} color='rgba(190, 190, 190, 1)'>
                        {t("page.description")}
                    </Text>
                    <div style={{ width: '400px' }}>
                        <UITextInput
                            value={spaceStore.websiteData.pages[key].description}
                            placeholder={t("enter.description.here")}
                            onChange={(e) => {
                                spaceStore.websiteData.pages[key].description = e
                            }}
                        />
                    </div>
                </Flex>


                <Flex gap={8} direction={'column'}
                    mb={32}
                    mt={32}
                >
                    <Text
                        style={{
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                    >
                        {t("preview")}
                    </Text>

                    <Flex
                        style={{
                            background: 'rgba(18, 18, 18, 0.5)',
                            borderRadius: '4px',
                            padding: '16px',
                        }}
                        gap={8}
                        direction={'column'}

                    >
                        <Text
                            style={{
                                color: '#BEBEBE',
                                fontSize: '10px',
                                fontWeight: '500',
                            }}
                        >
                            {spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}
                        </Text>
                        <Text
                            style={{
                                color: 'rgba(101, 152, 252, 1)',
                                fontSize: '16px',
                                fontWeight: '500',
                            }}
                        >
                            {spaceStore.websiteData.pages[key].title}
                        </Text>
                        <Text
                            style={{
                                color: 'rgba(115, 115, 115, 1)',
                                fontSize: '10px',
                                fontWeight: '500',
                            }}
                        >
                            {spaceStore.websiteData.pages[key].description}
                        </Text>
                    </Flex>
                </Flex>


                <Flex gap={32}
                    mb={32}
                    mt={32}
                >
                    <div style={{ width: '50%' }}>
                        <Text
                            style={{
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                            mb={12}
                        >
                            {t("og.image")}
                        </Text>
                        <Text
                            style={{
                                color: 'rgba(190, 190, 190, 1)',
                                fontSize: '10px',
                                fontWeight: '400',
                            }}
                            mb={12}
                        >
                            {t("og.image.desc")}
                        </Text>

                        <Flex direction={'column'}>
                            <div style={{ marginTop: '10px' }}>
                                <Widget
                                    localeTranslations={{
                                        buttons: {
                                            choose: {
                                                files: {
                                                    one: t("upload")
                                                },
                                            },
                                        },
                                    }}
                                    clearable={false}
                                    // @ts-ignore
                                    onChange={async (info: { cdnUrl: string }) => {
                                        const res = await runAsyncConvert({ url: info.cdnUrl })
                                        spaceStore.websiteData.pages[key].og = res.url
                                    }}
                                    tabs={"file camera url"}
                                    publicKey="f4d9f840695d97e9cbb7"
                                    previewStep={true}
                                    crop="free, 16:9, 4:3, 5:4, 1:1"
                                />
                            </div>
                            {spaceStore.websiteData.pages[key].og ? (
                                <UIButton

                                    loading={false}
                                    fluid
                                    color="gray"
                                    onClick={() => {
                                        spaceStore.websiteData.pages[key].og = ''
                                    }}
                                >
                                    {t("editor.page.image.delete")}
                                </UIButton>
                            ) : null}
                            <Flex mt={10}>
                                <SiteGenImageButton
                                    forceRatio='16:9'
                                    content={undefined}
                                    label={''}
                                    clicked={() => props.close()}
                                    onCompleted={function (image: string): void {
                                        spaceStore.websiteData.pages[key].og = image

                                    }} />
                            </Flex>
                        </Flex>
                    </div>
                    <div style={{ width: '50%' }}>
                        <Text
                            style={{
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                            mb={12}
                        >
                            {t("preview")}
                        </Text>
                        <Flex direction={'column'} style={{
                            borderRadius: '4px',
                            border: '1px solid rgba(33, 33, 33, 1)',
                            background: '#212121',
                        }}>
                            {
                                spaceStore.websiteData.pages[key].og ? (
                                    <Image
                                        width={'100%'}
                                        height={140}
                                        styles={{
                                            image: {
                                                borderRadius: '4px',
                                            }
                                        }}
                                        src={spaceStore.websiteData.pages[key].og}
                                        alt="With default placeholder"
                                        withPlaceholder
                                        fit="contain"
                                    />
                                ) : (
                                    <Flex direction={'column'} bg={'rgba(70, 70, 70, 1)'} style={{ borderRadius: '2px' }} justify={'center'} align={'center'} p={44}>
                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M26 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44H30C40 44 44 40 44 30V20" stroke="#989898" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M31.5 10H42.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                            <path d="M37 15.5V4.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                            <path d="M5.33984 37.9001L15.1998 31.2801C16.7798 30.2201 19.0598 30.3401 20.4798 31.5601L21.1398 32.1401C22.6998 33.4801 25.2198 33.4801 26.7798 32.1401L35.0998 25.0001C36.6598 23.6601 39.1798 23.6601 40.7398 25.0001L43.9998 27.8001" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </Flex>
                                )
                            }
                            <Flex
                                style={{
                                    background: 'rgba(18, 18, 18, 0.5)',
                                    borderRadius: '4px',
                                    padding: '16px',
                                }}
                                gap={6}
                                direction={'column'}

                            >
                                <Text
                                    style={{
                                        color: '#BEBEBE',
                                        fontSize: '8px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}
                                </Text>
                                <Text
                                    style={{
                                        color: 'rgba(101, 152, 252, 1)',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {spaceStore.websiteData.pages[key].title}
                                </Text>
                                <Text
                                    style={{
                                        color: 'rgba(115, 115, 115, 1)',
                                        fontSize: '8px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {spaceStore.websiteData.pages[key].description}
                                </Text>
                            </Flex>
                        </Flex>
                    </div>
                </Flex>
            </div>
        </Modal >
    )
}

export const PageSettingsModal = observer(Element);