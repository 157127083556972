import { Redirect, Route, Switch } from "react-router-dom";
import Login from "../public/login/login";
import ForgotPassword from "../public/forgotPassword/ForgotPassword";
import ResetPassword from "../public/resetPassword/ResetPassword";

const LoggedOut = () => {
  return (
    <Switch>
      <Route path="/login-password">
        <Login />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset/:hash">
        <ResetPassword />
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
};

export default LoggedOut;
