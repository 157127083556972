const Logo = (props: {size: number}) => {
    return (
        <svg width={props.size} viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185; 2">
                <g id="&#208;&#161;&#208;&#187;&#208;&#190;&#208;&#185; 1">
                    <path id="Vector" d="M8.2002 2.90186H10.1954L11.23 5.88647L10.2831 8.35719L8.2002 2.90186Z" fill="white" />
                    <path id="Vector_2" d="M1.67753 0.810791H-0.166443V9.09812H1.67753V0.810791Z" fill="white" />
                    <path id="Vector_3" d="M8.06596 0.810791H6.22198V9.09812H8.06596V0.810791Z" fill="white" />
                    <path id="Vector_4" d="M20.0943 2.90186H18.2504V9.09814H20.0943V2.90186Z" fill="white" />
                    <path id="Vector_5" d="M15.4106 0.810791H13.415L10.2507 9.09812H12.2463L15.4106 0.810791Z" fill="white" />
                    <path id="Vector_6" d="M14.611 2.90186H12.6158L9.45148 11.1892H11.4467L14.611 2.90186Z" fill="white" />
                    <path id="Vector_7" d="M1.83966 5.51708L3.82354 2.90186H6.03799L3.77429 5.62939L5.98874 9.09814H3.89769L1.83966 5.51708Z" fill="white" />
                    <path id="Vector_8" d="M24.0638 5.58551C24.0638 3.12129 22.807 2.64392 21.6653 2.64392C20.7798 2.64392 20.3964 3.04822 20.2998 3.17189C20.2927 3.18109 20.2998 4.79559 20.2998 4.79559C20.3993 4.54094 20.7744 4.36666 21.1795 4.36666C21.7064 4.36666 22.2198 4.60561 22.2198 5.58443V9.09702H24.0638V5.58551Z" fill="white" />
                    <path id="Vector_9" d="M13.8843 5.51708L15.8684 2.90186H18.0829L15.8189 5.62939L18.0336 9.09814H15.9426L13.8843 5.51708Z" fill="white" />
                </g>
            </g>
        </svg>
    )
}

export default Logo