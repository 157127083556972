import { Paper, Title, Container, Button, TextInput } from "@mantine/core";

import React, { useState } from "react";
import { useForm } from "@mantine/form";
import { useStore } from "../../../hooks/use-store";
import styles from "./resetPassword.module.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Logo from "../../../components/Logo";

interface FormProps {
  password: string;
}

const ResetPassword = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();
  const { hash } = useParams<{ hash: string }>();

  const [success, showSuccess] = useState(false);
 
  
  const form = useForm({
    initialValues: {
      password: '',
    },
    validate: {
    },
  });

  const login = async (data: FormProps) => {
    const res = await authStore.resetPassword(data.password, hash);

    if (res.ok) {
      showSuccess(true);
    }
  };

  return (
    <div className={styles.container}>
      <Container size={420} my={40}>
        <div className={styles.logo}>
          <Logo size={40}/>
        </div>
        <Title color="white" align="center" sx={(theme) => ({ fontWeight: 700 })}>
          {t("reset.header", { defaultValue: "Reset your password" })}
        </Title>

        {success ? (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{background: '#303030'}}>
            {t("reset.success", {
              defaultValue: "Success! Now you can login with new password!",
            })}
          </Paper>
        ) : (
          <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{background: '#303030'}}>
           
           <form onSubmit={form.onSubmit(async (values) => {
              await login(values)
            })}>
              <TextInput
                required
                type="password"
                label={t("reset.new.password", {
                  defaultValue: "Enter new password",
                })}
                name={"email"}
                {...form.getInputProps('password')}
              />
              <Button
                fullWidth
                mt="xl"
                loading={false}
                type="submit"
              // onClick={}
              >
                 {t("reset.change", { defaultValue: "Change password" })}
              </Button>
            </form>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default ResetPassword;
