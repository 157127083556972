import { createStyles } from '@mantine/core';
import { FormElementProps } from '../../../stores/data/space-store/formElement';
import { observer } from 'mobx-react';

const useStyles = createStyles((theme, attributes: FormElementProps) => ({
  input: {
    fontFamily: `'${attributes.fieldTypefaceId}'`,
    backgroundColor: attributes.fieldBackgroundColor,
    color: attributes.fieldTextColor,
    padding: `${attributes.fieldPaddingTop}px ${attributes.fieldPaddingLeft}px`,
    textAlign: attributes.fieldTextAlign,
    fontSize: attributes.fieldFontSize,
    fontWeight: attributes.fieldFontWeight,
    borderRadius: attributes.fieldBorderRadius,
    border: `${attributes.fieldBorderWidth}px solid ${attributes.fieldBorderColor}`,
    '::placeholder': {
      color: attributes.fieldTextColor,
      opacity: 0.8,
    },
    '::-webkit-input-placeholder': {
      color: attributes.fieldTextColor,
      opacity: 0.8,
    },

    '::-ms-input-placeholder': {
      color: attributes.fieldTextColor,
      opacity: 0.8,
    },
  },
}));

const FormElementContent = (props: {onSelect: (withShift: boolean) => void, attributes: FormElementProps, selectedClassName: string}) => {

  const { classes } = useStyles(props.attributes);

  const {
    id,
    x,
    y,
    width,
    height,
    // src,
    // alt,
    position,
    // objectFit,
    bgColor,
    zIndex,
    opacity,
    borderRadius,
    rotate,

    fields,
    buttonText,
    formSubmitText,


    // TODO!!!!!
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    backgroundColor,

    labelPaddingLeft,
    labelPaddingTop,
    labelFontSize,
    labelFontWeight,
    // labelLetterSpacing,
    // labelLineHeight,
    // labelTextDecoration,
    labelTextColor,
    labelTextAlign,
    labelTypefaceId,

    buttonPaddingLeft,
    buttonPaddingTop,
    buttonFontSize,
    buttonFontWeight,
    // buttonLetterSpacing,
    // buttonLineHeight,
    // buttonTextDecoration,
    buttonTextColor,
    buttonBackgroundColor,
    buttonBorderRadius,
    buttonBorderWidth,
    buttonBorderColor,
    buttonTypefaceId,
    // buttonTextAlign,
    // buttonTypefaceId,
    // buttonFluid,
    // buttonWidth,

    formSubmitTextFontSize,
    formSubmitTextFontWeight,
    // formSubmitTextLetterSpacing,
    // formSubmitTextLineHeight,
    // formSubmitTextTextDecoration,
    formSubmitTextTextColor,
    formSubmitTextTextAlign,
    formSubmitTextTypefaceId,

    displaySavedState,
  } = props.attributes



  return (
    <>
      <div
        onClick={(e) => {
          props.onSelect(e.shiftKey)
        }}
        className={`${id}${props.selectedClassName ? ` ${props.selectedClassName}` : ''}`}
        style={{
          position,
          backgroundColor: bgColor || undefined,
          zIndex,
          opacity,
          borderRadius,
          top: 0,
          left: 0,
          width,
          height,
          transform: `translate(${x}px, ${y}px) rotate(${rotate || 0}deg)`,
        }}>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: displaySavedState ? 'center' : 'space-between',
            height: '100%',
            width: '100%'
          }}
        >
          {
            displaySavedState ? (
              <div
                style={{
                  color: formSubmitTextTextColor,
                  textAlign: formSubmitTextTextAlign,
                  fontSize: formSubmitTextFontSize,
                  fontWeight: formSubmitTextFontWeight,
                  fontFamily: `'${formSubmitTextTypefaceId}'`,
                }}
              >
                {formSubmitText}
              </div>
            ) :
              fields.map((field, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    {
                      field.label ? <label
                        style={{
                          color: labelTextColor,
                          padding: `${labelPaddingTop}px ${labelPaddingLeft}px`,
                          textAlign: labelTextAlign,
                          fontSize: labelFontSize,
                          fontWeight: labelFontWeight,
                          fontFamily: `'${labelTypefaceId}'`,
                        }}
                      >{field.label}</label> : null
                    }

                    <input
                      placeholder={field.placeholder}
                      required={field.required}
                      className={classes.input}
                      type={field.type}
                    />
                  </div>
                )
              })
          }
          {
            displaySavedState ? null : <button
              style={{
                backgroundColor: buttonBackgroundColor,
                color: buttonTextColor,
                padding: `${buttonPaddingTop}px ${buttonPaddingLeft}px`,
                border: `${buttonBorderWidth}px solid ${buttonBorderColor}`,
                borderRadius: buttonBorderRadius,
                fontFamily: `'${buttonTypefaceId}'`,

                cursor: 'pointer',
                fontSize: buttonFontSize,
                fontWeight: buttonFontWeight,
              }}
            >
              {buttonText}
            </button>
          }
        </form>
      </div>
    </>
  );
}

export default observer(FormElementContent);