import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { Button, Modal, TextInput } from "@mantine/core";

interface Props {
  visible: boolean;
  handleAdd: (url: string) => void;
  handleCancel: () => void;
}

interface FormProps {
  link: string;
}

const PasteLinkModal = ({ visible, handleAdd, handleCancel }: Props) => {
  const submit = (data: FormProps) => {
    handleAdd(data.link);
  };

  const form = useForm({
    initialValues: {
      link: '',
    },
    validate: {},
  });
  
  useEffect(() => {
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);


  return (
    <Modal
      title={"Insert link"}
      opened={visible}
      onClose={handleCancel}
      size={"md"}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          console.log(values)
          submit(values)
        })}>
        <TextInput
          required
          type="text"
          label={"Link"}
          placeholder="https://klykn.com/"
          name={"link"}
          {...form.getInputProps('link')}
        />
        <Button
          fullWidth
          mt="xl"
          // loading={false}
          type="submit"
          onClick={() => {

            handleAdd(form.values.link);
          }}
        >
          Paste
        </Button>
      </form>
    </Modal>
  );
};

export default PasteLinkModal;
