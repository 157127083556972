import React from "react";
import styles from "./menuItem.module.scss";
import remixiconUrl from "remixicon/fonts/remixicon.symbol.svg";

const MenuItem = ({ icon, title, action, isActive = null }: any) => (
  <button
    className={styles.menuItem}
    style={
      isActive && isActive()
        ? { backgroundColor: "#0d0d0d", color: "white" }
        : undefined
    }
    onClick={action}
    title={title}
  >
    <svg className="remix">
      <use xlinkHref={`${remixiconUrl}#ri-${icon}`} />
    </svg>
  </button>
);

export default MenuItem;
