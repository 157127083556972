import { Flex, Text } from '@mantine/core';
import { UIButton } from '../../components/ui-kit/button/UIButton';
import { useStore } from '../../hooks/use-store';
import { ProBadge } from './ProBadge';
import { useTranslation } from 'react-i18next';

export const SiteGenImageButton = (props: {
    forceRatio?: string,
    content: any
    label: string
    onCompleted: (image: string) => void
    clicked?: () => void
}) => {
    const {
        dataStore: { spaceStore },
    } = useStore();

    const { t } = useTranslation();


    return (
        <UIButton
            onClick={() => {
                spaceStore.openGenImageModal(props.onCompleted)
                if (props.clicked) {
                    props.clicked()
                }
            }}
            color={'gray'}
            loading={false}
            fluid={true}
        >
            <Flex align={'center'} gap={8}>
                <Text>
                    {t("generate.with.ai")}
                </Text>
                <ProBadge />
            </Flex>
        </UIButton>
    )
}