import { observer } from "mobx-react";
import { useStore } from "../../../hooks/use-store";
import { useKeycon } from "react-keycon";

const KeyboardController = () => {
    const {
        dataStore: { spaceStore },
    } = useStore();

    const {
        onKeydown,
        onKeyup,
    } = useKeycon({
        keys: ["shift"],
    });

    onKeydown(() => {
        spaceStore.setLockAspectRation(true);
    }, []);

    onKeyup(() => {
        spaceStore.setLockAspectRation(false)
    }, []);

    return null
}

export default observer(KeyboardController);