import { useEffect, useState } from "react";
import { useStore } from "../../hooks/use-store";
import {
  Grid,
  Table,
} from "@mantine/core";
import { useRequest } from "ahooks";
import { useParams } from "react-router-dom";
// import { useWindowSize } from "./Site/helper";
import { observer } from "mobx-react";

// const useStyles = createStyles((theme) => ({

//   item2: {
//     display: 'flex',
//     alignItems: 'center',
//     borderRadius: theme.radius.md,
//     border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
//       }`,
//     padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
//     paddingLeft: `calc(${theme.spacing.xl} - ${theme.spacing.md})`, // to offset drag handle
//     backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
//     marginBottom: theme.spacing.sm,
//   },

//   itemDragging: {
//     boxShadow: theme.shadows.sm,
//   },

//   symbol: {
//     fontSize: rem(30),
//     fontWeight: 700,
//     width: rem(60),
//   },

//   dragHandle: {
//     ...theme.fn.focusStyles(),
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     height: '100%',
//     color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
//     paddingLeft: theme.spacing.md,
//     paddingRight: theme.spacing.md,
//   },

//   wrapper: {
//     height: "100vh",
//     overflow: "auto",
//     paddingTop: theme.spacing.sm,
//     paddingBottom: theme.spacing.sm,
//     background: theme.colorScheme === "dark" ? "#25262b" : "white",
//     borderLeft: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[2]
//       }`,
//   },

//   title: {
//     marginBottom: theme.spacing.sm,
//     paddingLeft: theme.spacing.sm,
//     color: theme.colorScheme === "dark" ? theme.white : theme.black,
//     // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
//   },

//   iframe: {
//     height: "100vh",
//     overflowX: "hidden",
//     overflowY: "hidden",
//     backgroundColor:
//       theme.colorScheme === "dark"
//         ? theme.colors.dark[5]
//         : theme.colors.gray[1],
//   },
//   publish: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//   },

//   copyLink: {
//     display: "flex",

//     alignItems: "center",
//   },

//   item: {
//     fontSize: theme.fontSizes.sm,
//     color:
//       theme.colorScheme === "dark"
//         ? theme.colors.dark[1]
//         : theme.colors.gray[7],
//   },

//   center: {
//     display: "flex",
//     justifyContent: "center",
//   },
//   video: {
//     margin: "0 auto",

//     "> div": {
//       position: "absolute!important" as any,
//       transformOrigin: "left top",
//       borderRadius: "8px",
//       overflow: "hidden",
//     },
//   },
// }));

const FormsScreen = () => {
  // const [opened, { open, close }] = useDisclosure(false);

  // const { width } = useWindowSize()

  // const posthog = usePostHog()

  const {
    dataStore: { appStore },
  } = useStore();

  const { siteId } = useParams<{ siteId: string }>();

  const [data, setData] = useState<[]>([]);



  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading, refresh } = useRequest(async () => appStore.getFormSubmissionByWebsiteId(siteId), {
    onSuccess: (model: any) => {
      console.log(model)
      setData(model);
    },
  });

  useEffect(() => {
    
  }, [loading, refresh])

  console.log(data)
  // reduce form by id
  const forms = data.reduce((acc: any, item: any) => {
    if (!acc[item.formId]) {
      acc[item.formId] = [];
    }
    acc[item.formId].push(item);
    return acc;
  }, {})


  const renderTable = (data: any) => {
    console.log(data[0].data)
    // console.log(data[0])

    const ths = data[0].data.map((itm: any) => {
      return <th>{itm.name}</th>
    })

    const rows = data.map((itm: any) => {
      return (
        <tr key={itm}>
          {
            itm.data.map((item: any) => {
              return <td>{item.value}</td>
            })
          }
        </tr>
      )
    })
    // const elements = [
    //   { position: 6, mass: 12.011, symbol: 'C', name: 'Carbon' },
    //   { position: 7, mass: 14.007, symbol: 'N', name: 'Nitrogen' },
    //   { position: 39, mass: 88.906, symbol: 'Y', name: 'Yttrium' },
    //   { position: 56, mass: 137.33, symbol: 'Ba', name: 'Barium' },
    //   { position: 58, mass: 140.12, symbol: 'Ce', name: 'Cerium' },
    // ];
    // const rows = elements.map((element) => (
    //   <tr key={element.name}>
    //     <td>{element.position}</td>
    //     <td>{element.name}</td>
    //     <td>{element.symbol}</td>
    //     <td>{element.mass}</td>
    //   </tr>
    // ));

    return (
      <Table>
        <thead>
          <tr>
            {ths}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    )


  }
  return (
    <Grid justify="space-between" m={0}>
      {
        Object.keys(forms).map((key: any) => {
          return renderTable(forms[key])
        })
      }
    </Grid >
  );
};

export default observer(FormsScreen);
