import {
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  TextInput,
} from "@mantine/core";
// import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { useStore } from "../../../hooks/use-store";
import styles from "./login.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../../components/Logo";

interface FormProps {
  username: string;
  password: string;
}

const Login = () => {
  const {
    uiStore: { authStore },
  } = useStore();
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },
    validate: {
      username: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const login = async (data: FormProps) => {
    await authStore.login(data.username, data.password);
  };

  return (
    <div className={styles.container}>
      <Container size={420} my={40}>
        <div className={styles.logo}>
          <Logo size={40} />
        </div>
        <Title
          color="white"
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Hello Again!
        </Title>
        <Text color="white" size="sm" align="center" mt={5}>
          No account?{" "}
          <Link to={"/new-account"}>
            <Anchor color="white" size="sm" component="button">
              Create here
            </Anchor>
          </Link>
        </Text>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ background: '#303030' }}>

          <form onSubmit={form.onSubmit(async (values) => {
            await login(values)
          })}>
            <TextInput
              type="text"
              required
              label={t("loginpage.email")}
              name={"username"}
              {...form.getInputProps('username')}
            />
            <TextInput
              type="password"
              required
              label={t("loginpage.password", { defaultValue: "Password" })}
              name={"password"}
              {...form.getInputProps('password')}
            />
            <Group position="apart" mt="lg">
              <Link to={"/forgot-password"}>
                <Anchor component="button" size="sm">
                  Forgot password?
                </Anchor>
              </Link>
            </Group>
            <Button
              fullWidth
              mt="xl"
              type="submit"
            >
              Sign in
            </Button>
          </form>


        </Paper>
      </Container>
    </div>
  );
};

export default Login;
