import { NumberInput, Text } from "@mantine/core"

interface IUINumberInput {
    value: number
    step: number
    min: number
    max: number
    precision: number
    suffix: string
    onChange: (value: number | '') => void
}

const UINumberInput = (props: IUINumberInput) => {
    return (
        <NumberInput
            size="xs"
            prefix={props.suffix}
            value={props.value}
            step={props.step}
            min={props.min}
            max={props.max}
            mt={0}
            mb={0}
            radius={2}
            styles={{
                icon: {
                    right: '19px',
                    left: 'auto',
                    fontSize: '10px'
                },  
                root: {
                    backgroundColor: 'transparent',
                },
                input: {
                    color: 'white',
                    paddingLeft: '10px !important',
                    paddingRight: '40px',
                    backgroundColor: 'transparent',
                    borderColor: '#464646',
                }
            }}
            icon={<Text color="dimmed" size={'12px'}>{props.suffix}</Text>}
            removeTrailingZeros={true}
            precision={props.precision}
            onChange={(e) => {
                props.onChange(e)
            }}
        />
    )
}

export { UINumberInput }