import { Flex, Modal, Text, Tabs } from '@mantine/core';
import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { ButtonContent, CONTENT_TYPE, TextContent } from './Site/helper';
import TextComponent from './text/TextEditor';
import { UISelect } from '../../components/ui-kit/select/UISelect';
import { useState } from 'react';
import { UIButton } from '../../components/ui-kit/button/UIButton';
import { useRequest } from 'ahooks';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';

const countries = {
    "aa": "Afar",
    "ab": "Abkhazian",
    "ae": "Avestan",
    "af": "Afrikaans",
    "ak": "Akan",
    "am": "Amharic",
    "an": "Aragonese",
    "ar": "Arabic",
    "ar-ae": "Arabic (U.A.E.)",
    "ar-bh": "Arabic (Bahrain)",
    "ar-dz": "Arabic (Algeria)",
    "ar-eg": "Arabic (Egypt)",
    "ar-iq": "Arabic (Iraq)",
    "ar-jo": "Arabic (Jordan)",
    "ar-kw": "Arabic (Kuwait)",
    "ar-lb": "Arabic (Lebanon)",
    "ar-ly": "Arabic (Libya)",
    "ar-ma": "Arabic (Morocco)",
    "ar-om": "Arabic (Oman)",
    "ar-qa": "Arabic (Qatar)",
    "ar-sa": "Arabic (Saudi Arabia)",
    "ar-sy": "Arabic (Syria)",
    "ar-tn": "Arabic (Tunisia)",
    "ar-ye": "Arabic (Yemen)",
    "as": "Assamese",
    "av": "Avaric",
    "ay": "Aymara",
    "az": "Azeri",
    "ba": "Bashkir",
    "be": "Belarusian",
    "bg": "Bulgarian",
    "bh": "Bihari",
    "bi": "Bislama",
    "bm": "Bambara",
    "bn": "Bengali",
    "bo": "Tibetan",
    "br": "Breton",
    "bs": "Bosnian",
    "ca": "Catalan",
    "ce": "Chechen",
    "ch": "Chamorro",
    "co": "Corsican",
    "cr": "Cree",
    "cs": "Czech",
    "cu": "Church Slavonic",
    "cv": "Chuvash",
    "cy": "Welsh",
    "da": "Danish",
    "de": "German",
    "de-at": "German (Austria)",
    "de-ch": "German (Switzerland)",
    "de-de": "German (Germany)",
    "de-li": "German (Liechtenstein)",
    "de-lu": "German (Luxembourg)",
    "div": "Divehi",
    "dv": "Divehi",
    "dz": "Bhutani",
    "ee": "Ewe",
    "el": "Greek",
    "en": "English",
    "en-au": "English (Australia)",
    "en-bz": "English (Belize)",
    "en-ca": "English (Canada)",
    "en-cb": "English (Caribbean)",
    "en-gb": "English (United Kingdom)",
    "en-ie": "English (Ireland)",
    "en-jm": "English (Jamaica)",
    "en-nz": "English (New Zealand)",
    "en-ph": "English (Philippines)",
    "en-tt": "English (Trinidad and Tobago)",
    "en-us": "English (United States)",
    "en-za": "English (South Africa)",
    "en-zw": "English (Zimbabwe)",
    "eo": "Esperanto",
    "es": "Spanish",
    "es-ar": "Spanish (Argentina)",
    "es-bo": "Spanish (Bolivia)",
    "es-cl": "Spanish (Chile)",
    "es-co": "Spanish (Colombia)",
    "es-cr": "Spanish (Costa Rica)",
    "es-do": "Spanish (Dominican Republic)",
    "es-ec": "Spanish (Ecuador)",
    "es-es": "Spanish (Spain)",
    "es-gt": "Spanish (Guatemala)",
    "es-hn": "Spanish (Honduras)",
    "es-mx": "Spanish (Mexico)",
    "es-ni": "Spanish (Nicaragua)",
    "es-pa": "Spanish (Panama)",
    "es-pe": "Spanish (Peru)",
    "es-pr": "Spanish (Puerto Rico)",
    "es-py": "Spanish (Paraguay)",
    "es-sv": "Spanish (El Salvador)",
    "es-us": "Spanish (United States)",
    "es-uy": "Spanish (Uruguay)",
    "es-ve": "Spanish (Venezuela)",
    "et": "Estonian",
    "eu": "Basque",
    "fa": "Farsi",
    "ff": "Fulah",
    "fi": "Finnish",
    "fj": "Fiji",
    "fo": "Faroese",
    "fr": "French",
    "fr-be": "French (Belgium)",
    "fr-ca": "French (Canada)",
    "fr-ch": "French (Switzerland)",
    "fr-fr": "French (France)",
    "fr-lu": "French (Luxembourg)",
    "fr-mc": "French (Monaco)",
    "fy": "Frisian",
    "ga": "Irish",
    "gd": "Gaelic",
    "gl": "Galician",
    "gn": "Guarani",
    "gu": "Gujarati",
    "gv": "Manx",
    "ha": "Hausa",
    "he": "Hebrew",
    "hi": "Hindi",
    "ho": "Hiri Motu",
    "hr": "Croatian",
    "hr-ba": "Croatian (Bosnia and Herzegovina)",
    "hr-hr": "Croatian (Croatia)",
    "ht": "Haitian",
    "hu": "Hungarian",
    "hy": "Armenian",
    "hz": "Herero",
    "ia": "Interlingua",
    "id": "Indonesian",
    "ie": "Interlingue",
    "ig": "Igbo",
    "ii": "Sichuan Yi",
    "ik": "Inupiak",
    "in": "Indonesian",
    "io": "Ido",
    "is": "Icelandic",
    "it": "Italian",
    "it-ch": "Italian (Switzerland)",
    "it-it": "Italian (Italy)",
    "iu": "Inuktitut",
    "iw": "Hebrew",
    "ja": "Japanese",
    "ji": "Yiddish",
    "jv": "Javanese",
    "jw": "Javanese",
    "ka": "Georgian",
    "kg": "Kongo",
    "ki": "Kikuyu",
    "kj": "Kuanyama",
    "kk": "Kazakh",
    "kl": "Greenlandic",
    "km": "Cambodian",
    "kn": "Kannada",
    "ko": "Korean",
    "kok": "Konkani",
    "kr": "Kanuri",
    "ks": "Kashmiri",
    "ku": "Kurdish",
    "kv": "Komi",
    "kw": "Cornish",
    "ky": "Kirghiz",
    "kz": "Kyrgyz",
    "la": "Latin",
    "lb": "Luxembourgish",
    "lg": "Ganda",
    "li": "Limburgan",
    "ln": "Lingala",
    "lo": "Laothian",
    "ls": "Slovenian",
    "lt": "Lithuanian",
    "lu": "Luba-Katanga",
    "lv": "Latvian",
    "mg": "Malagasy",
    "mh": "Marshallese",
    "mi": "Maori",
    "mk": "FYRO Macedonian",
    "ml": "Malayalam",
    "mn": "Mongolian",
    "mo": "Moldavian",
    "mr": "Marathi",
    "ms": "Malay",
    "ms-bn": "Malay (Brunei Darussalam)",
    "ms-my": "Malay (Malaysia)",
    "mt": "Maltese",
    "my": "Burmese",
    "na": "Nauru",
    "nb": "Norwegian (Bokmal)",
    "nd": "North Ndebele",
    "ne": "Nepali (India)",
    "ng": "Ndonga",
    "nl": "Dutch",
    "nl-be": "Dutch (Belgium)",
    "nl-nl": "Dutch (Netherlands)",
    "nn": "Norwegian (Nynorsk)",
    "no": "Norwegian",
    "nr": "South Ndebele",
    "ns": "Northern Sotho",
    "nv": "Navajo",
    "ny": "Chichewa",
    "oc": "Occitan",
    "oj": "Ojibwa",
    "om": "(Afan)/Oromoor/Oriya",
    "or": "Oriya",
    "os": "Ossetian",
    "pa": "Punjabi",
    "pi": "Pali",
    "pl": "Polish",
    "ps": "Pashto/Pushto",
    "pt": "Portuguese",
    "pt-br": "Portuguese (Brazil)",
    "pt-pt": "Portuguese (Portugal)",
    "qu": "Quechua",
    "qu-bo": "Quechua (Bolivia)",
    "qu-ec": "Quechua (Ecuador)",
    "qu-pe": "Quechua (Peru)",
    "rm": "Rhaeto-Romanic",
    "rn": "Kirundi",
    "ro": "Romanian",
    "ru": "Russian",
    "rw": "Kinyarwanda",
    "sa": "Sanskrit",
    "sb": "Sorbian",
    "sc": "Sardinian",
    "sd": "Sindhi",
    "se": "Sami",
    "se-fi": "Sami (Finland)",
    "se-no": "Sami (Norway)",
    "se-se": "Sami (Sweden)",
    "sg": "Sangro",
    "sh": "Serbo-Croatian",
    "si": "Singhalese",
    "sk": "Slovak",
    "sl": "Slovenian",
    "sm": "Samoan",
    "sn": "Shona",
    "so": "Somali",
    "sq": "Albanian",
    "sr": "Serbian",
    "sr-ba": "Serbian (Bosnia and Herzegovina)",
    "sr-sp": "Serbian (Serbia and Montenegro)",
    "ss": "Siswati",
    "st": "Sesotho",
    "su": "Sundanese",
    "sv": "Swedish",
    "sv-fi": "Swedish (Finland)",
    "sv-se": "Swedish (Sweden)",
    "sw": "Swahili",
    "sx": "Sutu",
    "syr": "Syriac",
    "ta": "Tamil",
    "te": "Telugu",
    "tg": "Tajik",
    "th": "Thai",
    "ti": "Tigrinya",
    "tk": "Turkmen",
    "tl": "Tagalog",
    "tn": "Tswana",
    "to": "Tonga",
    "tr": "Turkish",
    "ts": "Tsonga",
    "tt": "Tatar",
    "tw": "Twi",
    "ty": "Tahitian",
    "ug": "Uighur",
    "uk": "Ukrainian",
    "ur": "Urdu",
    "us": "English",
    "uz": "Uzbek",
    "ve": "Venda",
    "vi": "Vietnamese",
    "vo": "Volapuk",
    "wa": "Walloon",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "za": "Zhuang",
    "zh": "Chinese",
    "zh-cn": "Chinese (China)",
    "zh-hk": "Chinese (Hong Kong SAR)",
    "zh-mo": "Chinese (Macau SAR)",
    "zh-sg": "Chinese (Singapore)",
    "zh-tw": "Chinese (Taiwan)",
    "zu": "Zulu"
}
const langs = [
    {
        label: 'None',
        value: '-'
    },
    ...Object.keys(countries).map((key) => {
        return {
            label: (countries[key] as string),
            value: key
        }
    })
]
export const Element = () => {

    const {
        dataStore: { appStore, spaceStore },
    } = useStore();

    const { loading, runAsync: runTranslate } = useRequest(
        async (model: {
            fromLang: string;
            toLang: string;
            texts: {
                key: string;
                value: string;
            }[];
        }) => appStore.translate(model),
        {
            loadingDelay: 100,
            manual: true,
            onSuccess: (res) => {
                if (res.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    // console.log(res)
                    // setDaleeImage(res.url)

                }
            },
        }
    );

    const [newLocale, setNewLocale] = useState({
        label: 'None',
        value: '-'
    })
    if (!spaceStore.langModalOpen) {
        return null
    }
    return (
        <Modal padding={'30px 40px'} opened={spaceStore.langModalOpen} onClose={() => spaceStore.toggleLangModal()} title=" " size={'auto'} styles={{
            content: {
                minWidth: '1200px'
            }
        }}>

            <Tabs orientation="vertical" defaultValue="common" mih={900}>
                <Tabs.List style={{ minWidth: '160px' }}>
                    <Tabs.Tab value="common" p={16}>
                        <Flex align={'center'} gap={8}>
                            <Text>
                                {'Settings'}
                            </Text>
                        </Flex>

                    </Tabs.Tab>

                    {
                        spaceStore.websiteData.alternativeLangs ?
                            spaceStore.websiteData.alternativeLangs.map((lang) => {
                                return (
                                    <Tabs.Tab value={lang.value} p={16} key={lang.value} >
                                        <Flex align={'center'} gap={8}>
                                            <Text>
                                                {lang.label}
                                            </Text>
                                        </Flex>
                                    </Tabs.Tab>
                                )
                            }) : null
                    }
                </Tabs.List>

                <Tabs.Panel value="common" pl={26} pr={26}>

                    <Flex wrap={'wrap'} gap={8}>
                        <Flex w={'200px'} direction={'column'}>
                            <Text>Default language</Text>
                            <UISelect
                                searchable={true}
                                data={langs}
                                value={spaceStore.websiteData.defaultLang?.value || '-'}
                                onChange={(e) => {
                                    const lang = langs.find((l) => l.value === e)
                                    spaceStore.websiteData.defaultLang = lang
                                }}
                            />
                        </Flex>
                    </Flex>

                    <Flex wrap={'wrap'} gap={8} align={'flex-end'} mt={20}>
                        <Flex w={'200px'} direction={'column'}>
                            <Text>Add new language</Text>
                            <UISelect
                                searchable={true}
                                data={langs.filter(itm => !spaceStore.websiteData.alternativeLangs?.find((l) => l.value === itm.value) && itm.value !== spaceStore.websiteData.defaultLang?.value)}
                                value={newLocale.value}
                                onChange={(e) => {
                                    const lang = langs.find((l) => l.value === e)
                                    setNewLocale(lang!)
                                }}
                            />
                        </Flex>
                        <UIButton
                            loading={false}
                            onClick={() => {
                                Object.keys(spaceStore.websiteData.pages).forEach((key) => {
                                    if (!spaceStore.websiteData.pages[key].alternativeLangsContent) {
                                        spaceStore.websiteData.pages[key].alternativeLangsContent = {
                                            [newLocale.value]: {}
                                        };
                                    }
                                    if (!spaceStore.websiteData.pages[key].alternativeLangsContent[newLocale.value]) {
                                        spaceStore.websiteData.pages[key].alternativeLangsContent[newLocale.value] = {};
                                    }
                                });
                                spaceStore.websiteData.alternativeLangs ? spaceStore.websiteData.alternativeLangs.push(newLocale) : spaceStore.websiteData.alternativeLangs = [newLocale];
                                setNewLocale({
                                    label: 'None',
                                    value: '-'
                                })
                            }}
                            color={'gray'}
                            fluid={false}>
                            Add new
                        </UIButton>
                    </Flex>




                </Tabs.Panel>

                {
                    spaceStore.websiteData.alternativeLangs ?
                        spaceStore.websiteData.alternativeLangs.map((lang) => {
                            return (
                                <Tabs.Panel value={lang.value} pl={26} pr={26} key={lang.value}>
                                    <Flex>
                                        {

                                            <Flex direction={'column'} gap={8} w="100%">
                                                <Flex justify={'space-between'}>
                                                    <Text>Current language: {lang.label}</Text>

                                                    <Flex direction={'column'}>
                                                        <Text>Fill empty translations with AI</Text>
                                                        <UIButton color={'gray'} loading={loading} fluid={false} onClick={async () => {
                                                            const texts: {
                                                                key: string;
                                                                value: string;
                                                            }[] = []

                                                            Object.keys(spaceStore.websiteData.pages).forEach((pageId) => {
                                                                Object.keys(spaceStore.websiteData.pages[pageId].content).forEach((key) => {
                                                                    if (!spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key]) {

                                                                        if (spaceStore.websiteData.pages[pageId].content[key].type === CONTENT_TYPE.TEXT || spaceStore.websiteData.pages[pageId].content[key].type === CONTENT_TYPE.BUTTON) {
                                                                            texts.push({
                                                                                key,
                                                                                value: (spaceStore.websiteData.pages[pageId].content[key].content as TextContent).text
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                            })
                                                            const res = await runTranslate({
                                                                fromLang: `${spaceStore.websiteData.defaultLang?.label} (${spaceStore.websiteData.defaultLang?.value})`,
                                                                toLang: `${lang.label} (${lang.value})`,
                                                                texts,
                                                            })
                                                            if (res.texts) {
                                                                Object.keys(spaceStore.websiteData.pages).forEach((pageId) => {
                                                                    Object.keys(spaceStore.websiteData.pages[pageId].content).forEach((key) => {
                                                                        if (!spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key]) {
                                                                            if (spaceStore.websiteData.pages[pageId].content[key].type === CONTENT_TYPE.TEXT) {

                                                                                const value = res.texts.find(itm => itm.key === key)

                                                                                if (value) {
                                                                                    spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key] = {
                                                                                        type: CONTENT_TYPE.TEXT,
                                                                                        content: {
                                                                                            text: value.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            } else if (spaceStore.websiteData.pages[pageId].content[key].type === CONTENT_TYPE.BUTTON) {
                                                                                const value = res.texts.find(itm => itm.key === key)

                                                                                if (value) {
                                                                                    spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key] = {
                                                                                        type: CONTENT_TYPE.BUTTON,
                                                                                        content: {
                                                                                            text: res.texts[key] || ''
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    })
                                                                })
                                                            }
                                                            console.log(res)
                                                        }}>✨ Generate</UIButton>
                                                    </Flex>
                                                </Flex>

                                                {
                                                    Object.keys(spaceStore.websiteData.pages).reverse().map((pageId) => {

                                                        return (
                                                            <Flex gap={8} direction={'column'} w="100%">
                                                                <Text>Page: {spaceStore.websiteData.pages[pageId].name}</Text>
                                                                {
                                                                    spaceStore.websiteData.pages[pageId].alternativeLangsContent && spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value]
                                                                        ? (
                                                                            Object.keys(spaceStore.websiteData.pages[pageId].content).map((key) => {
                                                                                if (spaceStore.websiteData.pages[pageId].content[key].type === CONTENT_TYPE.TEXT) {
                                                                                    const alternative = spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key] ? (spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key].content as TextContent).text : ''
                                                                                    return (
                                                                                        <Flex gap={8} w="100%">
                                                                                            <Flex w="50%">
                                                                                                <TextComponent
                                                                                                    disabled
                                                                                                    data={(spaceStore.websiteData.pages[pageId].content[key].content as TextContent).text}
                                                                                                    onChange={(text) => {
                                                                                                        // (spaceStore.websiteData.pages[pageId].content[key].content as TextContent).text = text
                                                                                                    }} />
                                                                                            </Flex>
                                                                                            <Flex w="50%" pos={'relative'}>
                                                                                                <TextComponent
                                                                                                    data={alternative}
                                                                                                    onChange={(text) => {
                                                                                                        spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key] = {
                                                                                                            type: CONTENT_TYPE.TEXT,
                                                                                                            content: {
                                                                                                                text,
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                                {alternative ? null :
                                                                                                    <Flex
                                                                                                        style={{
                                                                                                            position: 'absolute',
                                                                                                            right: '12px',
                                                                                                            top: '12px',
                                                                                                            cursor: 'pointer'
                                                                                                        }}
                                                                                                    >


                                                                                                        <UIButton
                                                                                                            color={'gray'}
                                                                                                            loading={loading}
                                                                                                            fluid={false}

                                                                                                            onClick={async () => {
                                                                                                                const texts: {
                                                                                                                    key: string;
                                                                                                                    value: string;
                                                                                                                }[] = []

                                                                                                                texts.push({
                                                                                                                    key,
                                                                                                                    value: (spaceStore.websiteData.pages[pageId].content[key].content as TextContent).text,
                                                                                                                })
                                                                                                                const res = await runTranslate({
                                                                                                                    fromLang: `${spaceStore.websiteData.defaultLang?.label} (${spaceStore.websiteData.defaultLang?.value})`,
                                                                                                                    toLang: `${lang.label} (${lang.value})`,
                                                                                                                    texts,
                                                                                                                })
                                                                                                                if (res.texts) {

                                                                                                                    console.log(key, res.texts[key], res.texts.find((itm: any) => itm.key === key) || '')
                                                                                                                    spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key] = {
                                                                                                                        type: CONTENT_TYPE.TEXT,
                                                                                                                        content: {
                                                                                                                            text: res.texts.find((itm: any) => itm.key === key).value || ''
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                console.log(res)
                                                                                                            }}>✨ generate</UIButton>
                                                                                                    </Flex>
                                                                                                }
                                                                                            </Flex>
                                                                                        </Flex>
                                                                                    )
                                                                                } else if (spaceStore.websiteData.pages[pageId].content[key].type === CONTENT_TYPE.BUTTON) {
                                                                                    const alternative = spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key] ? (spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key].content as ButtonContent).text : ''
                                                                                    return (
                                                                                        <Flex gap={8} w="100%">
                                                                                            <Flex w="50%">
                                                                                                <TextComponent
                                                                                                    disabled
                                                                                                    data={(spaceStore.websiteData.pages[pageId].content[key].content as ButtonContent).text}
                                                                                                    onChange={() => { }}
                                                                                                />
                                                                                            </Flex>
                                                                                            <Flex w="50%">
                                                                                                <TextComponent data={alternative} onChange={(text) => {
                                                                                                    spaceStore.websiteData.pages[pageId].alternativeLangsContent[lang.value][key] = {
                                                                                                        type: CONTENT_TYPE.BUTTON,
                                                                                                        content: {
                                                                                                            text,
                                                                                                        }
                                                                                                    }
                                                                                                }} />
                                                                                            </Flex>
                                                                                        </Flex>
                                                                                    )
                                                                                } else {
                                                                                    return null
                                                                                }
                                                                            })) : null
                                                                }
                                                            </Flex>
                                                        )
                                                    })
                                                }
                                            </Flex>
                                        }
                                    </Flex>
                                </Tabs.Panel>
                            )
                        }) : null
                }
            </Tabs >


        </Modal >
    )
}

export const SiteLangModal = observer(Element);