import { Flex, Text } from "@mantine/core"
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react";
import { modals } from "@mantine/modals";
import { CONTENT_TYPE } from "./helper";

const FloatingButtonsBase = (props: {
    id: string
    type?: CONTENT_TYPE,
    drag?: boolean,
    selected: boolean,
    onClickDelete: () => void,
    onClickDuplicate: () => void,
    zoom: number
}) => {

    const {
        dataStore: { spaceStore },
    } = useStore();

    if (!props.selected) {
        return null
    }

    const deleteConfirm = () => modals.openConfirmModal({
        title: 'Please confirm your action',
        children: (
            <Text size="sm">
                Are you sure? This action cannot be undone.
            </Text>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => console.log('Cancel'),
        onConfirm: () => {
            props.onClickDelete()
        },
    });


    const zoom = 1 / Math.max(1, spaceStore.currentViewport === 320 ? props.zoom * 1.4 : props.zoom)
    return (
        <>
            {
                props.type === CONTENT_TYPE.IMAGE && spaceStore.currentViewport !== 320 ?
                    <Flex
                        // gap={5}
                        style={{
                            zIndex: 99999999,
                            position: 'absolute',
                            left: '-10px',
                            top: `-${40 * zoom}px`,
                            cursor: 'pointer',
                        }}
                    >
                        <Flex
                            justify={'center'}
                            align={'center'}

                            onClick={() => spaceStore.openGenImageModal(function (image: string): void {
                                spaceStore.updateElementProps({
                                    id: props.id,
                                    src: image,
                                })
                            })}
                            style={{
                                padding: `${4 * zoom}px`,
                                width: `${100 * zoom}px`,
                                height: `${34 * zoom}px`,
                                borderRadius: `${6 * zoom}px`,
                                marginRight: `${4 * zoom}px`,
                                fontSize: `${12 * zoom}px`,
                                background: 'rgb(213 125 29)',
                                color: 'white',
                                fontWeight: '700',
                                textAlign: 'center',
                            }}
                        >
                            <Text>✨ Generate</Text>
                        </Flex>
                    </Flex>
                    : null
            }
            <Flex
                // gap={5}
                style={{
                    zIndex: 99999999,
                    position: 'absolute',
                    right: '10px',
                    top: `-${40 * zoom}px`,
                    cursor: 'pointer',
                }}
            >
                {
                    props.drag ?
                        (
                            <Flex
                                justify={'center'}
                                align={'center'}
                                className="moveable-drag-target"
                                style={{
                                    padding: `${4 * zoom}px`,
                                    width: `${84 * zoom}px`,
                                    height: `${34 * zoom}px`,
                                    borderRadius: `${6 * zoom}px`,
                                    marginRight: `${4 * zoom}px`,
                                    fontSize: `${12 * zoom}px`,
                                    background: 'rgba(35, 108, 250, 1)',
                                    color: 'white',
                                    textAlign: 'center',
                                }}
                            >
                                <Text>Drag me</Text>
                            </Flex>
                        ) : null
                }

                <Flex
                    style={{
                        padding: `${4 * zoom}px`,
                        width: `${34 * zoom}px`,
                        height: `${34 * zoom}px`,
                        marginRight: `${4 * zoom}px`,
                        borderRadius: `${6 * zoom}px`,
                        background: 'rgba(35, 108, 250, 1)',

                    }}
                    onClick={() => props.onClickDuplicate()}
                >
                    <svg width={`${24 * zoom}px`} height={`${24 * zoom}px`} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 6.45V8.55C8 10.3 7.3 11 5.55 11H3.45C1.7 11 1 10.3 1 8.55V6.45C1 4.7 1.7 4 3.45 4H5.55C7.3 4 8 4.7 8 6.45Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11 3.45V5.55C11 7.3 10.3 8 8.55 8H8V6.45C8 4.7 7.3 4 5.55 4H4V3.45C4 1.7 4.7 1 6.45 1H8.55C10.3 1 11 1.7 11 3.45Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </Flex>

                <Flex
                    onClick={deleteConfirm}
                    style={{
                        padding: `${4 * zoom}px`,
                        width: `${34 * zoom}px`,
                        height: `${34 * zoom}px`,
                        borderRadius: `${6 * zoom}px`,
                        background: 'rgba(35, 108, 250, 1)',
                    }}
                >
                    <svg width={`${24 * zoom}px`} height={`${24 * zoom}px`} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 3.59961C9.335 3.43461 7.66 3.34961 5.99 3.34961C5 3.34961 4.01 3.39961 3.02 3.49961L2 3.59961" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.75 3.09462L4.86 2.43962C4.94 1.96462 5 1.60962 5.845 1.60962H7.155C8 1.60962 8.065 1.98462 8.14 2.44462L8.25 3.09462" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.92495 5.17969L9.59995 10.2147C9.54495 10.9997 9.49995 11.6097 8.10495 11.6097H4.89495C3.49995 11.6097 3.45495 10.9997 3.39995 10.2147L3.07495 5.17969" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.66504 8.85962H7.33004" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.25 6.85962H7.75" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                </Flex>
            </Flex>
        </>
    )
}

export const FloatingButtons = observer(FloatingButtonsBase);