import { Button, Flex, Text, TextInput, rem, Textarea, LoadingOverlay, Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { useStore } from "../../../../hooks/use-store";
import OtpLoginGenerator from "../otp/OtpLoginGen";
import { useRequest } from "ahooks";
import { NOTIFICATION_SERVICE } from "../../../../components/notifications/NotificationService";
import Placeholder from "../Placeholder";
import LanguageSwitcher from "../../profile/LanguageSwitcher";
import { Steps } from "../Steps";
import { useTranslation } from "react-i18next";
import { LeadModal } from "../../templates/LeadModal";
import useIsMobile from "../../../../hooks/isMobile";
import { useHistory } from "react-router-dom";
import { IframePreview } from "./IframePreview";

// const mapping = {
//     'ecohub': 'ecoclub-template',
//     'stonespeak': 'stonespeak-template',
//     'synclife': 'synclife-template',
//     'jamesmorgam': 'artist-template',
//     'mindfulcoaching': 'mindfulcoaching-template',
//     'rockstars': 'rockstars-template',
//     'bluestartup': 'bluestartup-template',
//     'spiritfusion': 'spiritfusion-template',
//     'odysseyexpeditions': 'travel-template',
//     'donut': 'donut-template',
//     'enchantmentfest': 'enchantment-template',
//     'paws': 'paws-template',
//     'echoesofeternity': 'echoesofeternity-template',
//     'retrorevive': 'retrovive-template',
//     'horizoncoaching': 'horizoncoaching-template',
//     '3dplayground': '3dplayground-template',
//     'cleanconsult': 'cleanconsult-template',
//     'one-agency': 'oneagency-template',
//     'onestartupv2-template': 'onestartupv2-template',
// }
export const WebsiteGenScreen = () => {

    const isMobile = useIsMobile()

    const history = useHistory();

    const { t } = useTranslation();

    const [remixCount, setRemixCount] = useState(0)

    const {
        uiStore,
        dataStore: { appStore },
    } = useStore();

    // TODO
    const [step, setStep] = useState(1)
    const [productType, setProductType] = useState('Product')

    useEffect(() => {

        if (window?.Intercom) {
            window.Intercom('shutdown')
        }
    }, [])

    const [text, setText] = useState({
        brand: '',
        business: '',
        targetAud: '',
        usp: '',
    })

    const [leadModalOpened, setLeadModalOpened] = useState(false);

    const [iframe, setItrame] = useState({
        // url: 'https://3ogpesqo.klykn.com',
        url: null,
        title: '',
        description: '',
        id: null
    })

    const { loading: publishLoading, runAsync } = useRequest(
        async ({ copyId }) =>
            appStore.generateWebsite({
                type: 'startup',
                style: 'Professional',
                prompt: `
My Brand/Company name: ${text.brand}.
My Business Description: ${text.business}. 
My Unique Selling Proposition: ${text.usp}. 
My Target Audience Description: ${text.targetAud}.`,
                lang: uiStore.currentLanguage,
                //@ts-ignore
                copyId: copyId,
            }),
        {
            loadingDelay: 20,
            manual: true,
            onSuccess: (model) => {
                if (model.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    setItrame(model)
                    setStep(6)
                    // setLeadModalOpened(true)

                }
            },
        }
    );

    const getTemplate = () => {
        const getRandomValue = (obj: { [key: string]: string }) => {
            const values = Object.values(obj);
            const randomIndex = Math.floor(Math.random() * values.length);
            return values[randomIndex];
        }

        return getRandomValue({
            'onestartupv2-template': 'onestartupv2-template',
            'bluestartup-template': 'bluestartup-template',
            'beauty-remix-template': 'beauty-remix-template',
        })
    }

    const { loading: remixLoading, runAsync: runAsyncRemix } = useRequest(
        async ({ copyId }) =>
            appStore.generateWebsite({
                type: 'startup',
                style: 'Professional',
                prompt: ``,
                lang: uiStore.currentLanguage,
                copyId: copyId,
                replaceId: iframe.id!,
            }),
        {
            loadingDelay: 20,
            manual: true,
            onSuccess: (model) => {
                setRemixCount(remixCount + 1)
                if (model.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    setItrame(model)
                    setStep(6)
                }
            },
        }
    );
    return (
        <Flex w={'100%'} h='100%' wrap={'wrap'}>
            <Flex w={(isMobile ? '100%' : '40%')} direction={'column'} bg={'rgba(48, 48, 48, 1)'} style={{ height: '100vh', overflow: 'scroll' }} align={'center'}>
                <Flex maw={'480px'} w="100%" p={30} gap={6} justify={'space-between'} align={'center'}>
                    <Steps active={step} steps={6} />
                    <LanguageSwitcher />
                </Flex>
                <Flex
                    direction="column"
                    maw={'480px'}
                    w="100%"
                    p={30}
                >
                    {
                        step === 1 ? (
                            <>
                                <Text color="white" size={20} weight={'700'} mt={10} mb={10} align="center">
                                    What's your company name?
                                </Text>
                                <TextInput
                                    mt={24}
                                    size="lg"
                                    label=""
                                    value={text.brand}
                                    placeholder="ex. Klykn AI"
                                    onChange={(e) => {
                                        setText({
                                            ...text,
                                            brand: e.currentTarget.value
                                        })
                                    }}
                                />

                                <Flex gap={12} mt={32} justify={'end'}>
                                    <Button
                                        disabled={text.brand.length === 0}
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: rem(42),

                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(2)
                                        }}
                                    >
                                        {t("next.step")}
                                    </Button>
                                </Flex>

                            </>

                        ) : null
                    }

                    {
                        step === 2 ? (
                            <>
                                <Text color="white" size={20} weight={'700'} mt={10} mb={10} align="center">
                                    Is this website for a service or product?
                                </Text>

                                <Flex
                                    mt={22}
                                    mb={12}
                                    gap={12}
                                    p={12}
                                    onClick={() => setProductType('Product')}
                                    align={'center'}
                                    style={{
                                        cursor: 'pointer',
                                        background: productType === 'Product' ? 'rgba(46, 58, 80, 1)' : 'rgba(70, 70, 70, 1)',
                                        border: productType === 'Product' ? '1px solid rgba(35, 108, 250, 1)' : '1px solid rgba(115, 115, 115, 1)',
                                        borderRadius: '4px',
                                    }}>
                                    {productType === 'Product' ? <Flex ml={12} mr={12}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="16" height="16" rx="2" fill="#236CFA" />
                                            <path d="M12 5L6.5 10.5L4 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </Flex>
                                        : <Flex ml={12} mr={12}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#737373" />
                                            </svg>
                                        </Flex>}

                                    <Text color="white" size={'14px'}>
                                        Product
                                    </Text>
                                </Flex>
                                <Flex
                                    mb={12}
                                    gap={12}
                                    p={12}
                                    onClick={() => setProductType('Service')}
                                    align={'center'}
                                    style={{
                                        cursor: 'pointer',
                                        background: productType === 'Service' ? 'rgba(46, 58, 80, 1)' : 'rgba(70, 70, 70, 1)',
                                        border: productType === 'Service' ? '1px solid rgba(35, 108, 250, 1)' : '1px solid rgba(115, 115, 115, 1)',
                                        borderRadius: '4px',
                                    }}>
                                    {productType === 'Service' ? <Flex ml={12} mr={12}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="16" height="16" rx="2" fill="#236CFA" />
                                            <path d="M12 5L6.5 10.5L4 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </Flex>
                                        : <Flex ml={12} mr={12}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#737373" />
                                            </svg>
                                        </Flex>}
                                    <Text color="white" size={'14px'}>
                                        Service
                                    </Text>
                                </Flex>



                                <Flex gap={12} mt={32} justify={'end'}>
                                    <Button
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                background: '#464646',
                                                height: rem(42),
                                                fontSize: '18px',

                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(1)
                                        }}
                                    >
                                        {t("back")}
                                    </Button>
                                    <Button
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: rem(42),

                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(3)
                                        }}
                                    >
                                        {t("next.step")}
                                    </Button>
                                </Flex>

                            </>
                        ) : null
                    }
                    <LoadingOverlay visible={publishLoading || remixLoading} loaderProps={{ children: <Flex gap={8}><Loader color="orange" /><Text>Generating...</Text></Flex> }} />
                    {
                        step === 3 ? (
                            <>
                                <Text color="white" size={20} weight={'700'} mt={10} mb={10} align="center">
                                    Desribe your {productType.toLowerCase()}

                                </Text>
                                <Textarea
                                    mt={24}
                                    minRows={5}
                                    size="lg"
                                    label=""
                                    value={text.business}
                                    placeholder="ex. Tech enthusiasts seeking innovative, AI-driven solutions for everyday challenges."
                                    onChange={(e) => {
                                        setText({
                                            ...text,
                                            business: e.currentTarget.value
                                        })
                                    }}
                                />
                                <Flex gap={12} mt={32} justify={'end'}>
                                    <Button

                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                background: '#464646',
                                                height: rem(42),
                                                fontSize: '18px',

                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(2)
                                        }}
                                    >
                                        {t("back")}
                                    </Button>
                                    <Button
                                        disabled={text.business.length === 0}
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: rem(42),

                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={async () => {
                                            setStep(4)
                                        }}
                                    >
                                        {t("next.step")}
                                    </Button>
                                </Flex>
                            </>
                        ) : null

                    }
                    {
                        step === 4 ? (
                            <>
                                <Text color="white" size={20} weight={'700'} mt={10} mb={10} align="center">
                                    Describe your ideal customer
                                </Text>
                                <Textarea
                                    mt={24}
                                    minRows={5}
                                    size="lg"
                                    label=''
                                    value={text.targetAud}
                                    placeholder="ex. Tech enthusiasts seeking cutting-edge, AI-powered innovations for enhanced daily experiences."
                                    onChange={(e) => {
                                        setText({
                                            ...text,
                                            targetAud: e.currentTarget.value
                                        })
                                    }}
                                />
                                <Flex gap={12} mt={32} justify={'end'}>
                                    <Button

                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                background: '#464646',
                                                height: rem(42),
                                                fontSize: '18px',

                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(3)
                                        }}
                                    >
                                        {t("back")}
                                    </Button>
                                    <Button
                                        disabled={text.targetAud.length === 0}
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: rem(42),

                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={async () => {
                                            setStep(5)
                                        }}
                                    >
                                        {t("next.step")}
                                    </Button>
                                </Flex>
                            </>
                        ) : null
                    }
                    {
                        step === 5 ? (
                            uiStore.authStore.isLoggedIn ?
                                <>
                                    <Text color="white" size={20} weight={'700'} mt={10} mb={10} align="center">
                                        What problem does your product solve
                                    </Text>
                                    <Textarea
                                        mt={24}
                                        minRows={5}
                                        size="lg"
                                        label=''
                                        value={text.usp}
                                        placeholder="ex. Empowering businesses to streamline operations and boost efficiency through advanced AI-driven automation solutions."
                                        onChange={(e) => {
                                            setText({
                                                ...text,
                                                usp: e.currentTarget.value
                                            })
                                        }}
                                    />
                                    <Flex gap={12} mt={32} justify={'end'}>
                                        <Button

                                            styles={{
                                                root: {
                                                    color: 'background: rgba(230, 230, 230, 1)',
                                                    background: '#464646',
                                                    height: rem(42),
                                                    fontSize: '18px',

                                                    border: 'none',
                                                    position: 'relative',
                                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                                }
                                            }}
                                            variant="default"
                                            onClick={() => {
                                                setStep(4)
                                            }}
                                        >
                                            {t("back")}
                                        </Button>
                                        <Button
                                            disabled={text.usp.length === 0}
                                            styles={{
                                                root: {
                                                    color: 'background: rgba(230, 230, 230, 1)',
                                                    height: rem(42),

                                                    fontSize: '18px',
                                                    background: 'rgba(35, 108, 250, 1)',
                                                    border: 'none',
                                                    position: 'relative',
                                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                                }
                                            }}
                                            variant="default"
                                            onClick={async () => {
                                                await runAsync({ copyId: getTemplate() })
                                            }}
                                        >
                                            {t("Generate")}
                                        </Button>
                                    </Flex>
                                </>
                                : (
                                    <>
                                        <OtpLoginGenerator onSuccess={() => { }} />
                                    </>

                                )
                        ) : null
                    }
                    {
                        step === 6 ? (
                            <>
                                <Text color="white" size={28} weight={'700'} mt={10} mb={10} align="center">
                                    Your website draft is ready
                                </Text>
                                <Text color="rgba(230, 230, 230, 1)" size={14} weight={'400'} mt={10} mb={10}>
                                    Your website draft is ready. You can now customize it further or publish it as is. SEO information is already optimized for you.
                                </Text>
                                <Text color="rgba(230, 230, 230, 1)" size={14} weight={'400'} mt={10}>
                                    More features included:
                                </Text>
                                <Flex direction={'column'} mt={2} mb={20}>
                                    {
                                        [
                                            'Custom domain connection',
                                            'AI - Text',
                                            'AI - Image',
                                            'Animation Effects',
                                            'Code embed',
                                            'Lead forms',
                                            'Parallax',
                                            'Sticky effect',
                                            'Custom fonts',
                                            'Scripts',
                                            'Extended SEO',
                                            'Localization',
                                            'Remove klykn badge',
                                        ].map(itm =>
                                            <Flex
                                                gap={8}
                                                align={'center'}>
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.5909 2.79545C12.4697 2.79906 12.3546 2.84973 12.2701 2.93672L5.13334 10.0734L2.19663 7.13672C2.15362 7.09193 2.10212 7.05618 2.04513 7.03154C1.98813 7.00691 1.9268 6.9939 1.86471 6.99327C1.80263 6.99263 1.74104 7.0044 1.68356 7.02786C1.62607 7.05133 1.57385 7.08604 1.52995 7.12994C1.48604 7.17384 1.45134 7.22606 1.42787 7.28355C1.4044 7.34103 1.39264 7.40262 1.39327 7.4647C1.39391 7.52679 1.40692 7.58812 1.43155 7.64512C1.45618 7.70211 1.49194 7.75362 1.53673 7.79662L4.8034 11.0633C4.89092 11.1508 5.0096 11.1999 5.13334 11.1999C5.25709 11.1999 5.37577 11.1508 5.46329 11.0633L12.93 3.59662C12.9974 3.53107 13.0435 3.44668 13.0621 3.3545C13.0807 3.26232 13.0711 3.16666 13.0345 3.08006C12.9978 2.99345 12.9358 2.91994 12.8567 2.86915C12.7775 2.81836 12.6849 2.79267 12.5909 2.79545Z" fill="white" />
                                                </svg>
                                                <Text
                                                    fw={'500'}
                                                    style={{
                                                        fontSize: '14px',
                                                        color: 'white',

                                                    }}
                                                >
                                                    {itm}
                                                </Text>
                                            </Flex>
                                        )
                                    }
                                </Flex>

                                <Flex justify={'center'} gap={20}>
                                    <Button
                                        fullWidth={false}
                                        loading={false}
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: '44px',
                                                width: '160px',
                                                fontSize: '18px',

                                                background: '#7010E9',

                                                border: 'none',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={async () => {
                                            if (remixCount < 3) {
                                                runAsyncRemix({ copyId: getTemplate() })
                                            } else {
                                                if (iframe) {
                                                    // setLeadModalOpened(false)
                                                    history.push(`/sites/${iframe.id}`)
                                                }
                                                // setLeadModalOpened(true)
                                            }
                                        }}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.49994 20.5C4.32994 21.33 5.66994 21.33 6.49994 20.5L19.4999 7.5C20.3299 6.67 20.3299 5.33 19.4999 4.5C18.6699 3.67 17.3299 3.67 16.4999 4.5L3.49994 17.5C2.66994 18.33 2.66994 19.67 3.49994 20.5Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M18.01 8.99001L15.01 5.99001" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.5 2.44L10 2L9.56 3.5L10 5L8.5 4.56L7 5L7.44 3.5L7 2L8.5 2.44Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M4.5 8.44L6 8L5.56 9.5L6 11L4.5 10.56L3 11L3.44 9.5L3 8L4.5 8.44Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M19.5 13.44L21 13L20.56 14.5L21 16L19.5 15.56L18 16L18.44 14.5L18 13L19.5 13.44Z" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                        <Text ml={6}>Remix</Text>

                                    </Button>
                                    <Button
                                        fullWidth={false}
                                        loading={false}
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: '44px',
                                                width: '160px',
                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={async () => {
                                            if (iframe) {
                                                // setLeadModalOpened(false)
                                                history.push(`/sites/${iframe.id}`)
                                            }
                                            // setLeadModalOpened(true)
                                        }}
                                    >
                                        Start editing
                                    </Button>

                                </Flex>


                            </>
                        ) : null
                    }
                </Flex>
            </Flex>
            <Flex w={(isMobile ? '100%' : '60%')} style={{ height: '100vh', overflow: 'scroll' }}>
                {step === 6 ? <Flex justify={'center'} w={'100%'} pos='relative' bg="#464646"
                >
                    <Flex
                        w={'96%'}
                    >
                        {
                            !publishLoading && !remixLoading ?
                                <IframePreview site={iframe.url!} height={1800} /> : null
                        }

                    </Flex>
                    {
                        iframe.title && (
                            <Flex
                                style={{
                                    zIndex: 11,
                                    left: 0,
                                    right: 0,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    position: 'absolute',
                                    bottom: '10px',
                                    background: 'rgba(18, 18, 18, 0.9)',
                                    borderRadius: '4px',
                                    padding: '16px',
                                    width: '480px'
                                }}
                                gap={8}
                                direction={'column'}

                            >
                                <Text
                                    style={{
                                        color: '#BEBEBE',
                                        fontSize: '10px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {iframe.url}
                                </Text>
                                <Text
                                    style={{
                                        color: 'rgba(101, 152, 252, 1)',
                                        fontSize: '16px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {iframe.title}
                                </Text>
                                <Text
                                    style={{
                                        color: 'rgba(115, 115, 115, 1)',
                                        fontSize: '10px',
                                        fontWeight: '500',
                                    }}
                                >
                                    {iframe.description}
                                </Text>
                            </Flex>)
                    }
                </Flex> : null
                }
                {
                    step < 6 ? (
                        <Flex bg="#464646" pos={'relative'}>
                            <Placeholder />
                        </Flex>
                    ) : null
                }
            </Flex>

            <LeadModal
                open={iframe && leadModalOpened}
                siteId={iframe?.id!}
                onClose={() => {
                    setLeadModalOpened(false)
                    // history.push(`/sites/${iframe.id}`)
                }}
                onSkip={() => {
                    if (iframe) {
                        // setLeadModalOpened(false)
                        history.push(`/sites/${iframe.id}`)
                    }
                }}
            />
        </Flex>
    );
}