import { Modal, Tabs, TextInput, Textarea, Image, Text, Flex, Divider, Button, Popover, Paper, Switch } from '@mantine/core';

import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { Widget } from '@uploadcare/react-widget';
import { UIButton } from '../../components/ui-kit/button/UIButton';
import { useRequest } from 'ahooks';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';
import { UISelect } from '../../components/ui-kit/select/UISelect';
import { useState } from 'react';
// import { ProBadge } from './ProBadge';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../pages/public/gen/terms/TextEditor';
import { SiteGenImageButton } from './SiteGenImageButton';

export const Element = (props: {
    opened: boolean
    close: () => void
}) => {

    const {
        dataStore: { spaceStore },
        dataStore: { appStore },
    } = useStore();

    const [fontType, setFontType] = useState('typekit')
    const [defaultSelected, setDefaultSelected] = useState(spaceStore.domain ? 'customDomain' : 'freeDomain')
    const [fontName, setFontName] = useState('')
    const [projectId, setProjectId] = useState('')

    const { t } = useTranslation();

    const { runAsync: runAsyncConvert } = useRequest(
        async (model: { url: string }) => appStore.convertImage({
            url: model.url,
            websiteId: spaceStore.siteId,
        }),
        {
            manual: true,
            onSuccess: (res) => {
                if (res.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    return res.url
                    // spaceStore.updateElementProps({
                    //   id: spaceStore.selected?.id,
                    //   src: res.url,
                    // })
                }
            },
        }
    );


    const { runAsync, loading: updateFreeDomainLoading } = useRequest(
        async () => appStore.updateFreeDomain({
            siteId: spaceStore.siteId,
            freeDomain: spaceStore.freeDomain.toLowerCase(),
        }),
        {
            loadingDelay: 0,
            manual: true,
            onSuccess: (res) => {
                if (res.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        t("this.domain.taken")
                    );
                } else {
                    NOTIFICATION_SERVICE.successNotification(
                        t("free.domain.updated")
                    );
                }
            },
            onError: (error) => {
                NOTIFICATION_SERVICE.errorNotification(
                    t("domain.taken")
                );
            }
        }
    );
    const { runAsync: connectPremiumDomain, loading: updatePremiumDomainLoading } = useRequest(
        async () => appStore.updatePremiumDomain({
            siteId: spaceStore.siteId,
            domain: spaceStore.domain,
        }),
        {
            loadingDelay: 0,
            manual: true,
            onSuccess: (res) => {
                return res
            },
        }
    );

    const key = props.opened
    if (!key) {
        return null
    }

    const fonts = Object.keys(spaceStore.websiteData.typeface).map(key => spaceStore.websiteData.typeface[key])

    const typekitFonts = fonts.filter(itm => itm.provider === 'typekit')
    const fontdeskFonts = fonts.filter(itm => itm.provider === 'fontdesk')
    const fontscomFonts = fonts.filter(itm => itm.provider === 'fontscom')

    return (
        <Modal opened={props.opened} onClose={props.close} title=" " size={900}>
            <Tabs orientation="vertical" defaultValue="seo" mih={900}>
                <Tabs.List style={{ minWidth: '160px' }}>
                    <Tabs.Tab value="seo" p={16}>
                        <Flex align={'center'} gap={8}>
                            <Text>
                                {t("seo")}
                            </Text>
                            {/* <ProBadge /> */}
                        </Flex>

                    </Tabs.Tab>
                    <Tabs.Tab value="scripts" p={16}>
                        <Flex align={'center'} gap={8}>
                            <Text>
                                {t("scripts")}

                            </Text>
                            {/* <ProBadge /> */}
                        </Flex>


                    </Tabs.Tab>
                    <Tabs.Tab value="fonts" p={16}>
                        <Flex align={'center'} gap={8}>
                            <Text>
                                {t("fonts")}
                            </Text>
                            {/* <ProBadge /> */}
                        </Flex>

                    </Tabs.Tab>
                    <Tabs.Tab value="domain" p={16}>
                        {t("domain")}
                    </Tabs.Tab>
                    <Tabs.Tab value="legal" p={16}>
                        {t("legal.pages")}
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="seo" pl={26} pr={26}>


                    <TextInput
                        styles={{
                            label: {
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            },
                            root: {
                                marginTop: '12px',
                            }
                        }}
                        label={t("site.title")}
                        size="sm"
                        description=""
                        value={spaceStore.websiteData.title}
                        placeholder={t("site.title.placeholder")}
                        onChange={(e) => {
                            spaceStore.websiteData.title = e.target.value
                        }}
                    />
                    <TextInput
                        styles={{
                            label: {
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            },
                            root: {
                                marginTop: '12px',
                            }
                        }}
                        label={t("site.description")}
                        size="sm"
                        type='textarea'
                        description=""
                        value={spaceStore.websiteData.description}
                        placeholder=""
                        onChange={(e) => {
                            spaceStore.websiteData.description = e.target.value
                        }}
                    />

                    <Flex gap={8} direction={'column'}
                        mb={32}
                        mt={32}
                    >
                        <Text
                            style={{
                                color: 'white',
                                fontSize: '14px',
                                fontWeight: 'bold',
                            }}
                        >
                            {t("preview")}
                        </Text>

                        <Flex
                            style={{
                                background: 'rgba(18, 18, 18, 0.5)',
                                borderRadius: '4px',
                                padding: '16px',
                            }}
                            gap={8}
                            direction={'column'}

                        >
                            <Text
                                style={{
                                    color: '#BEBEBE',
                                    fontSize: '10px',
                                    fontWeight: '500',
                                }}
                            >
                                {spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}
                            </Text>
                            <Text
                                style={{
                                    color: 'rgba(101, 152, 252, 1)',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                }}
                            >
                                {spaceStore.websiteData.title}
                            </Text>
                            <Text
                                style={{
                                    color: 'rgba(115, 115, 115, 1)',
                                    fontSize: '10px',
                                    fontWeight: '500',
                                }}
                            >
                                {spaceStore.websiteData.description}
                            </Text>
                        </Flex>
                    </Flex>


                    <Flex gap={32}
                        mb={32}
                        mt={32}
                    >
                        <div style={{ width: '50%' }}>
                            <Text
                                style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                mb={12}
                            >
                                {t("og.image")}
                            </Text>
                            <Text
                                style={{
                                    color: 'rgba(190, 190, 190, 1)',
                                    fontSize: '10px',
                                    fontWeight: '400',
                                }}
                                mb={12}
                            >
                                {t("og.image.desc")}
                            </Text>

                            <Flex direction={'column'}>
                                <div style={{ marginTop: '10px' }}>
                                    <Widget
                                        localeTranslations={{
                                            buttons: {
                                                choose: {
                                                    files: {
                                                        one: t("upload")
                                                    },
                                                },
                                            },
                                        }}
                                        clearable={false}
                                        // @ts-ignore
                                        onChange={async (info: { cdnUrl: string }) => {
                                            const res = await runAsyncConvert({ url: info.cdnUrl })
                                            spaceStore.websiteData.og = res.url
                                        }}
                                        tabs={"file camera url"}
                                        publicKey="f4d9f840695d97e9cbb7"
                                        previewStep={true}
                                        crop="free, 16:9, 1:1"
                                    />
                                </div>
                                {spaceStore.websiteData.og ? (
                                    <UIButton

                                        loading={false}
                                        fluid
                                        color="gray"
                                        onClick={() => {
                                            spaceStore.websiteData.og = ''
                                        }}
                                    >
                                        {
                                            t("editor.page.image.delete")
                                        }
                                    </UIButton>
                                ) : null}
                                <Flex mt={10}>
                                    <SiteGenImageButton
                                        clicked={() => props.close()}
                                        forceRatio='16:9'
                                        content={undefined}
                                        label={''}
                                        onCompleted={function (image: string): void {
                                            spaceStore.websiteData.og = image
                                        }} />
                                </Flex>
                            </Flex>
                        </div>
                        <div style={{ width: '50%' }}>
                            <Text
                                style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                mb={12}
                            >
                                {
                                    t("preview")
                                }
                            </Text>
                            <Flex direction={'column'} style={{
                                borderRadius: '4px',
                                border: '1px solid rgba(33, 33, 33, 1)',
                                background: '#212121',
                            }}>
                                {
                                    spaceStore.websiteData.og ? (
                                        <Image
                                            width={'100%'}
                                            height={140}
                                            styles={{
                                                image: {
                                                    borderRadius: '4px',
                                                }
                                            }}
                                            src={spaceStore.websiteData.og}
                                            alt="With default placeholder"
                                            withPlaceholder
                                            fit="contain"
                                        />
                                    ) : (
                                        <Flex direction={'column'} bg={'rgba(70, 70, 70, 1)'} style={{ borderRadius: '2px' }} justify={'center'} align={'center'} p={44}>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M26 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44H30C40 44 44 40 44 30V20" stroke="#989898" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M31.5 10H42.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M37 15.5V4.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M5.33984 37.9001L15.1998 31.2801C16.7798 30.2201 19.0598 30.3401 20.4798 31.5601L21.1398 32.1401C22.6998 33.4801 25.2198 33.4801 26.7798 32.1401L35.0998 25.0001C36.6598 23.6601 39.1798 23.6601 40.7398 25.0001L43.9998 27.8001" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </Flex>
                                    )
                                }
                                <Flex
                                    style={{
                                        background: 'rgba(18, 18, 18, 0.5)',
                                        borderRadius: '4px',
                                        padding: '16px',
                                    }}
                                    gap={6}
                                    direction={'column'}

                                >
                                    <Text
                                        style={{
                                            color: '#BEBEBE',
                                            fontSize: '8px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}
                                    </Text>
                                    <Text
                                        style={{
                                            color: 'rgba(101, 152, 252, 1)',
                                            fontSize: '12px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {spaceStore.websiteData.title}
                                    </Text>
                                    <Text
                                        style={{
                                            color: 'rgba(115, 115, 115, 1)',
                                            fontSize: '8px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        {spaceStore.websiteData.description}
                                    </Text>
                                </Flex>
                            </Flex>
                        </div>

                    </Flex>



                    <Flex gap={32}
                        mb={32}
                        mt={32}
                    >
                        <div style={{ width: '50%' }}>
                            <Text
                                style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                mb={12}
                            >
                                {
                                    t("favicon")
                                }

                            </Text>
                            <Text
                                style={{
                                    color: 'rgba(190, 190, 190, 1)',
                                    fontSize: '10px',
                                    fontWeight: '400',
                                }}
                                mb={12}
                            >
                                {
                                    t("favicon.desc")
                                }
                            </Text>

                            <Flex direction={'column'}>
                                <div style={{ marginTop: '10px' }}>
                                    <Widget
                                        localeTranslations={{
                                            buttons: {
                                                choose: {
                                                    files: {
                                                        one: t("upload")
                                                    },
                                                },
                                            },
                                        }}
                                        clearable={false}
                                        // @ts-ignore
                                        onChange={async (info: { cdnUrl: string }) => {
                                            const res = await runAsyncConvert({ url: info.cdnUrl })
                                            spaceStore.websiteData.favicon = res.url
                                        }}
                                        tabs={"file camera url"}
                                        publicKey="f4d9f840695d97e9cbb7"
                                        previewStep={true}
                                        crop="free, 1:1"
                                    />
                                </div>
                                {spaceStore.websiteData.favicon ? (
                                    <Flex mt={10}>
                                        <UIButton

                                            loading={false}
                                            fluid
                                            color="gray"
                                            onClick={() => {
                                                spaceStore.websiteData.favicon = ''
                                            }}
                                        >
                                            {
                                                t("delete.favicon")
                                            }
                                        </UIButton>
                                    </Flex>
                                ) : null}
                            </Flex>
                        </div>
                        <div style={{ width: '50%' }}>
                            <Text
                                style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',


                                }}
                                mb={12}
                            >
                                {
                                    t("preview")
                                }
                            </Text>
                            <Flex
                                align={'center'}
                                style={{
                                    borderRadius: '4px',
                                    border: '1px solid rgba(70, 70, 70, 1)',
                                    background: '#212121',
                                    height: '26px'
                                }}
                                gap={12}>
                                <Flex ml={12} style={{ height: '16px', width: '16px', background: 'rgba(70, 70, 70, 1)', }} justify={'center'} align={'center'}>
                                    {
                                        spaceStore.websiteData.favicon ? (
                                            <Image
                                                width={11}
                                                height={11}
                                                styles={{
                                                    image: {
                                                        borderRadius: '50%',
                                                    }
                                                }}
                                                src={spaceStore.websiteData.favicon}
                                                alt="With default placeholder"
                                                withPlaceholder
                                                fit="fill"
                                            />
                                        ) : (
                                            null
                                        )
                                    }
                                </Flex>
                                <Flex>
                                    <Text size={12}>{spaceStore.domain ? `https://${spaceStore.domain}` : `https://${spaceStore.freeDomain}.klykn.com`}</Text>
                                </Flex>

                            </Flex>
                        </div>

                    </Flex>



                </Tabs.Panel>

                <Tabs.Panel value="scripts" pl={26} pr={26}>
                    <Textarea
                        label={
                            t("head.script")
                        }
                        value={spaceStore.websiteData.scripts.head}
                        onChange={(e) => {
                            spaceStore.websiteData.scripts.head = e.target.value
                        }}
                    />
                    <br />
                    <Textarea
                        label={
                            t("body.script")
                        }
                        value={spaceStore.websiteData.scripts.body}
                        onChange={(e) => {
                            spaceStore.websiteData.scripts.body = e.target.value
                        }}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="fonts" pl={26} pr={26}>

                    <Flex align={'center'} gap={8}>
                        <Text>
                            {
                                t("add.new.font")
                            }
                        </Text>
                        {/* <ProBadge /> */}
                    </Flex>

                    <Flex mt={20}>
                        <Flex gap={8} align={'flex-end'}>
                            <UISelect
                                data={[
                                    'typekit',
                                    'fontdesk',
                                    'fontscom',
                                ]}
                                label={
                                    t("font.type")
                                }
                                searchable={false}
                                value={fontType}
                                onChange={(e) => {
                                    setFontType(e!)
                                }}
                            />

                            <TextInput
                                size="xs"
                                label={
                                    t("font.name")
                                }
                                description=""
                                value={fontName}
                                placeholder=''
                                onChange={(e) => {
                                    setFontName(e.target.value)
                                }}
                            />
                            <TextInput
                                size="xs"
                                label={
                                    t("project.id")
                                }
                                description=""
                                value={projectId}
                                placeholder=''
                                onChange={(e) => {
                                    setProjectId(e.target.value)
                                }}
                            />

                            <UIButton color={'blue'} loading={false} fluid={false} onClick={
                                () => {
                                    if (fontType === 'typekit') {
                                        spaceStore.websiteData.typeface[fontName] = {
                                            provider: 'typekit',
                                            id: projectId,
                                            name: fontName,
                                        }
                                    } else if (fontType === 'fontdesk') {
                                        spaceStore.websiteData.typeface[fontName] = {
                                            provider: 'fontdesk',
                                            id: projectId,
                                            name: fontName,
                                        }
                                    } else if (fontType === 'fontscom') {
                                        spaceStore.websiteData.typeface[fontName] = {
                                            provider: 'fontscom',
                                            projectId: projectId,
                                            name: fontName,
                                        }
                                    }

                                    setFontName('')
                                    setProjectId('')
                                    setFontType('typekit')
                                }}>
                                {
                                    t("add.new.font")
                                }
                            </UIButton>
                        </Flex>
                    </Flex>

                    <br />
                    <br />
                    <Text>
                        {
                            t("project.fonts")
                        }:
                    </Text>
                    <Flex direction={'column'} gap={12}>
                        {
                            [
                                ...typekitFonts,
                                ...fontdeskFonts,
                                ...fontscomFonts,
                            ].map(itm => (
                                <Flex gap={24} align={'center'}>
                                    <Text>{itm.name} / {itm.provider}</Text>
                                    <UIButton color={'gray'} loading={false} fluid={false} onClick={() => {
                                        delete spaceStore.websiteData.typeface[itm.name]
                                    }
                                    }>
                                        {
                                            t("remove")
                                        }
                                    </UIButton>
                                </Flex>
                            ))

                        }

                    </Flex>


                </Tabs.Panel>

                <Tabs.Panel value="domain" pl={26} pr={26}>
                    <Flex direction={'column'} gap={12}>
                        <Flex style={{
                            borderRadius: '4px',
                            background: defaultSelected === 'freeDomain' ? 'rgba(70, 70, 70, 1)' : undefined,
                            minHeight: '106px',
                            padding: '16px 56px'
                        }}
                            onClick={() => {
                                setDefaultSelected('freeDomain')
                            }}
                        >
                            <Flex gap={8} direction={'column'} pos={'relative'}>
                                {
                                    defaultSelected === 'freeDomain' ?
                                        <div style={{ position: 'absolute', left: '-30px', top: '2px' }}>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8" cy="8.5" r="8" fill="#236CFA" />
                                                <circle cx="8" cy="8.5" r="4" fill="white" />
                                            </svg>
                                        </div>
                                        : <div style={{ position: 'absolute', left: '-30px', top: '2px' }}>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8" cy="8.5" r="7.5" stroke="#737373" />
                                            </svg>
                                        </div>
                                }

                                <Text style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}>
                                    {t("default.domain")}

                                </Text>
                                <Flex
                                    align={'center'}
                                    pos={'relative'}>
                                    <TextInput
                                        style={{ width: '200px' }}
                                        label=""
                                        size="sm"
                                        description=""
                                        value={spaceStore.freeDomain}
                                        placeholder=""
                                        onChange={(e) => {
                                            spaceStore.freeDomain = e.target.value
                                        }}
                                    />
                                    <Button
                                        loading={updateFreeDomainLoading}
                                        variant='transparent'
                                        bg={'rgba(70, 70, 70, 1)'}
                                        styles={{
                                            root: {
                                                position: 'absolute',
                                                left: '170px',
                                                paddingRight: '0',
                                                paddingLeft: '0',
                                                width: '24px',
                                                height: '24px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            },
                                            leftIcon: {
                                                marginRight: 0,
                                            }
                                        }}
                                        onClick={async () => {
                                            await runAsync()
                                        }}
                                        leftIcon={
                                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M11 6.5C11 9.26 8.76 11.5 6 11.5C3.24 11.5 1.555 8.72 1.555 8.72M1.555 8.72H3.815M1.555 8.72V11.22M1 6.5C1 3.74 3.22 1.5 6 1.5C9.335 1.5 11 4.28 11 4.28M11 4.28V1.78M11 4.28H8.78" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>}
                                    >
                                    </Button>



                                    <Text ml={6}>.klykn.com</Text>


                                </Flex>


                                <Text
                                    style={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                    }}
                                >
                                    https://
                                    <span style={{
                                        color: '#36E678'
                                    }}>
                                        {spaceStore.freeDomain}
                                    </span>
                                    .klykn.com
                                </Text>

                            </Flex>
                        </Flex>
                        <Flex style={{
                            borderRadius: '4px',
                            background: defaultSelected === 'customDomain' ? 'rgba(70, 70, 70, 1)' : undefined,
                            minHeight: '106px',
                            padding: '16px 56px'
                        }}
                            onClick={() => {
                                setDefaultSelected('customDomain')
                            }}>
                            <Flex gap={8} direction={'column'} pos={'relative'}>
                                {
                                    defaultSelected === 'customDomain' ?
                                        <div style={{ position: 'absolute', left: '-30px', top: '2px' }}>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8" cy="8.5" r="8" fill="#236CFA" />
                                                <circle cx="8" cy="8.5" r="4" fill="white" />
                                            </svg>
                                        </div>
                                        : <div style={{ position: 'absolute', left: '-30px', top: '2px' }}>
                                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8" cy="8.5" r="7.5" stroke="#737373" />
                                            </svg>
                                        </div>
                                }
                                <Text style={{
                                    color: 'white',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}>
                                    {t("premium.domain")}
                                </Text>
                                <Flex
                                    align={'center'} gap={12}>
                                    <TextInput
                                        style={{ width: '200px' }}
                                        label=""
                                        size="sm"
                                        description=""
                                        value={spaceStore.domain}
                                        placeholder=""
                                        onChange={(e) => {
                                            spaceStore.domain = e.target.value
                                        }}
                                    />

                                    <Popover
                                        width="380"
                                        position="bottom"
                                        offset={24}
                                        withArrow
                                        shadow="md">
                                        <Popover.Target>
                                            <UIButton color={'blue'} loading={updatePremiumDomainLoading} fluid={false} onClick={() => { }}>
                                                {t("connect.domain")}

                                            </UIButton>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            <Text size="xs">Login to your domain provider dashboard. In the DNS settings of your domain add two records.</Text>

                                            <br />
                                            <Text size="xs">1. Record Type: <b>A</b> Host: <b>@</b>   Value: 149.248.217.32</Text>
                                            <Text size="xs">2. Record Type: <b>A</b> Host: <b>www</b> Value: 149.248.217.32</Text>
                                            <br />
                                            <Text size="xs">Make sure no other A records for these hosts exist. Remove them if necessary.</Text>
                                            <br />
                                            <UIButton
                                                color={'blue'}
                                                loading={updatePremiumDomainLoading}
                                                fluid={false}
                                                onClick={async () => {
                                                    const res = spaceStore.checkBillingCapability()
                                                    if (res) {
                                                        const r = await connectPremiumDomain()

                                                        if (r.error) {
                                                            NOTIFICATION_SERVICE.errorNotification(
                                                                "Something went wrong. Please try again later."
                                                            );
                                                        } else {
                                                            NOTIFICATION_SERVICE.successNotification(
                                                                "Your domain has been connected successfully to your website. It may take up to 48 hours for the changes to take effect."
                                                            );
                                                        }
                                                    } else {
                                                        props.close()
                                                    }
                                                }}>
                                                Records updated, connect domain
                                            </UIButton>


                                        </Popover.Dropdown>
                                    </Popover>

                                    {
                                        spaceStore.domain ?
                                            <UIButton
                                                color={'gray'}
                                                loading={false}
                                                fluid={false}
                                                onClick={async () => {
                                                    spaceStore.domain = ''
                                                    await connectPremiumDomain()
                                                }}>
                                                {t("remove")}
                                            </UIButton> : null
                                    }

                                </Flex>
                            </Flex>
                        </Flex>

                        <Divider my="xs" label={t("dont.have.domain")} labelPosition="center" />
                        <Flex justify={'center'}>
                            <a href='namecheap.pxf.io/ZQWmJX' target='_blank'>
                                <UIButton color={'blue'} loading={false} fluid={false} onClick={() => {

                                }} >
                                    {t("buy.domain")}
                                </UIButton>
                            </a>
                        </Flex>
                    </Flex>
                </Tabs.Panel>
                <Tabs.Panel value="legal" pl={26} pr={26}>
                    <Flex direction={'column'} gap={12}>
                        <Flex w={'100%'} direction={'column'}>

                            <Tabs variant="pills" defaultValue="terms">
                                <Tabs.List mb={24}>
                                    <Tabs.Tab value="terms" fz={12}>
                                        {t("terms")}
                                    </Tabs.Tab>
                                    <Tabs.Tab value="imprint" >
                                        {t("imprint")}
                                    </Tabs.Tab>
                                    <Tabs.Tab value="privacy"  >
                                        {t("privacy")}
                                    </Tabs.Tab>
                                    <Tabs.Tab value="cookie"  >
                                        {t("cookie.policy")}
                                    </Tabs.Tab>
                                    <Tabs.Tab value="licenses"  >
                                        {t("licenses")}
                                    </Tabs.Tab>
                                </Tabs.List>
                                <Tabs.Panel value="terms">
                                    <Switch
                                        width={'100%'}
                                        mt={4}
                                        mb={4}
                                        color="dimmed"
                                        // size={'sm'}
                                        labelPosition="left"
                                        styles={{
                                            body: {
                                                width: '100%',
                                                justifyContent: 'space-between',
                                            },
                                            label: {
                                                // fontSize: 12,
                                                // width: '92px'
                                            },
                                            input: {
                                                borderRadius: 2,
                                                borderColor: '#464646',
                                                backgroundColor: 'transparent',
                                            }
                                        }}
                                        label={
                                            <Flex align={'center'} gap={12}>
                                                <Text>
                                                    {t("Enabled")}
                                                </Text>
                                            </Flex>
                                        }
                                        checked={!!spaceStore.websiteData.legal?.terms?.enabled}
                                        onChange={(value) => {
                                            if (spaceStore.websiteData.legal?.terms) {
                                                spaceStore.websiteData.legal.terms.enabled = value.target.checked;
                                            } else {
                                                if (spaceStore.websiteData.legal) {

                                                    spaceStore.websiteData.legal.terms = {
                                                        enabled: value.target.checked,
                                                        content: spaceStore.websiteData.legal.terms.content

                                                    }
                                                } else {
                                                    spaceStore.websiteData.legal = {
                                                        terms: {
                                                            enabled: value.target.checked,
                                                            content: ''
                                                        },
                                                        privacy: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        cookie: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        licenses: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        imprint: {
                                                            enabled: false,
                                                            content: ''
                                                        }
                                                    }

                                                }
                                            }
                                        }}
                                    />
                                    {
                                        spaceStore.websiteData.legal?.terms?.enabled ? (
                                            <>
                                                <Text >
                                                    Pulic link: {spaceStore.domain ? `https://${spaceStore.domain}/terms` : `https://${spaceStore.freeDomain}.klykn.com/terms`}
                                                </Text>
                                                <Paper shadow="xs" radius="sm" p={10} bg='white' color='black' mt={20}>
                                                    <TextEditor
                                                        data={spaceStore.websiteData.legal.terms.content}
                                                        onChange={(value) => {
                                                            console.log(value, 'changed')
                                                            if (spaceStore.websiteData.legal?.terms) {
                                                                spaceStore.websiteData.legal.terms.content = value
                                                            }
                                                        }} />
                                                </Paper>
                                            </>
                                        ) : null
                                    }

                                </Tabs.Panel>

                                <Tabs.Panel value="imprint">
                                    <Switch
                                        width={'100%'}
                                        mt={4}
                                        mb={4}
                                        color="dimmed"
                                        // size={'sm'}
                                        labelPosition="left"
                                        styles={{
                                            body: {
                                                width: '100%',
                                                justifyContent: 'space-between',
                                            },
                                            label: {
                                                // fontSize: 12,
                                                // width: '92px'
                                            },
                                            input: {
                                                borderRadius: 2,
                                                borderColor: '#464646',
                                                backgroundColor: 'transparent',
                                            }
                                        }}
                                        label={
                                            <Flex align={'center'} gap={12}>
                                                <Text>
                                                    {t("Enabled")}
                                                </Text>
                                            </Flex>
                                        }
                                        checked={!!spaceStore.websiteData.legal?.imprint?.enabled}
                                        onChange={(value) => {
                                            if (spaceStore.websiteData.legal?.imprint) {
                                                spaceStore.websiteData.legal.imprint.enabled = value.target.checked;
                                            } else {
                                                if (spaceStore.websiteData.legal) {

                                                    spaceStore.websiteData.legal.imprint = {
                                                        enabled: value.target.checked,
                                                        content: spaceStore.websiteData.legal.imprint.content

                                                    }
                                                } else {
                                                    spaceStore.websiteData.legal = {
                                                        imprint: {
                                                            enabled: value.target.checked,
                                                            content: ''
                                                        },
                                                        privacy: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        cookie: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        licenses: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        terms: {
                                                            enabled: false,
                                                            content: ''
                                                        }
                                                    }

                                                }
                                            }
                                        }}
                                    />
                                    {
                                        spaceStore.websiteData.legal?.imprint?.enabled ? (
                                            <>
                                                <Text >
                                                    Pulic link: {spaceStore.domain ? `https://${spaceStore.domain}/imprint` : `https://${spaceStore.freeDomain}.klykn.com/imprint`}
                                                </Text>
                                                <Paper shadow="xs" radius="sm" p={10} bg='white' color='black' mt={20}>
                                                    <TextEditor
                                                        data={spaceStore.websiteData.legal.imprint.content}
                                                        onChange={(value) => {
                                                            console.log(value, 'changed')
                                                            if (spaceStore.websiteData.legal?.imprint) {
                                                                spaceStore.websiteData.legal.imprint.content = value
                                                            }
                                                        }} />
                                                </Paper>
                                            </>
                                        ) : null
                                    }

                                </Tabs.Panel>

                                <Tabs.Panel value="privacy">
                                    <Switch
                                        width={'100%'}
                                        mt={4}
                                        mb={4}
                                        color="dimmed"
                                        // size={'sm'}
                                        labelPosition="left"
                                        styles={{
                                            body: {
                                                width: '100%',
                                                justifyContent: 'space-between',
                                            },
                                            label: {
                                                // fontSize: 12,
                                                // width: '92px'
                                            },
                                            input: {
                                                borderRadius: 2,
                                                borderColor: '#464646',
                                                backgroundColor: 'transparent',
                                            }
                                        }}
                                        label={
                                            <Flex align={'center'} gap={12}>
                                                <Text>
                                                    {t("Enabled")}
                                                </Text>
                                            </Flex>
                                        }
                                        checked={!!spaceStore.websiteData.legal?.privacy?.enabled}
                                        onChange={(value) => {
                                            if (spaceStore.websiteData.legal?.privacy) {
                                                spaceStore.websiteData.legal.privacy.enabled = value.target.checked;
                                            } else {
                                                if (spaceStore.websiteData.legal) {

                                                    spaceStore.websiteData.legal.privacy = {
                                                        enabled: value.target.checked,
                                                        content: spaceStore.websiteData.legal.privacy.content

                                                    }
                                                } else {
                                                    spaceStore.websiteData.legal = {
                                                        privacy: {
                                                            enabled: value.target.checked,
                                                            content: ''
                                                        },
                                                        imprint: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        cookie: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        licenses: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        terms: {
                                                            enabled: false,
                                                            content: ''
                                                        }
                                                    }

                                                }
                                            }
                                        }}
                                    />
                                    {
                                        spaceStore.websiteData.legal?.privacy?.enabled ? (
                                            <>
                                                <Text >
                                                    Pulic link: {spaceStore.domain ? `https://${spaceStore.domain}/privacy` : `https://${spaceStore.freeDomain}.klykn.com/privacy`}
                                                </Text>
                                                <Paper shadow="xs" radius="sm" p={10} bg='white' color='black' mt={20}>
                                                    <TextEditor
                                                        data={spaceStore.websiteData.legal.privacy.content}
                                                        onChange={(value) => {
                                                            console.log(value, 'changed')
                                                            if (spaceStore.websiteData.legal?.privacy) {
                                                                spaceStore.websiteData.legal.privacy.content = value
                                                            }
                                                        }} />
                                                </Paper>
                                            </>
                                        ) : null
                                    }
                                </Tabs.Panel>
                                <Tabs.Panel value="cookie">
                                    <Switch
                                        width={'100%'}
                                        mt={4}
                                        mb={4}
                                        color="dimmed"
                                        // size={'sm'}
                                        labelPosition="left"
                                        styles={{
                                            body: {
                                                width: '100%',
                                                justifyContent: 'space-between',
                                            },
                                            label: {
                                                // fontSize: 12,
                                                // width: '92px'
                                            },
                                            input: {
                                                borderRadius: 2,
                                                borderColor: '#464646',
                                                backgroundColor: 'transparent',
                                            }
                                        }}
                                        label={
                                            <Flex align={'center'} gap={12}>
                                                <Text>
                                                    {t("Enabled")}
                                                </Text>
                                            </Flex>
                                        }
                                        checked={!!spaceStore.websiteData.legal?.cookie?.enabled}
                                        onChange={(value) => {
                                            if (spaceStore.websiteData.legal?.cookie) {
                                                spaceStore.websiteData.legal.cookie.enabled = value.target.checked;
                                            } else {
                                                if (spaceStore.websiteData.legal) {

                                                    spaceStore.websiteData.legal.cookie = {
                                                        enabled: value.target.checked,
                                                        content: spaceStore.websiteData.legal.cookie.content

                                                    }
                                                } else {
                                                    spaceStore.websiteData.legal = {
                                                        cookie: {
                                                            enabled: value.target.checked,
                                                            content: ''
                                                        },
                                                        privacy: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        imprint: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        licenses: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        terms: {
                                                            enabled: false,
                                                            content: ''
                                                        }
                                                    }

                                                }
                                            }
                                        }}
                                    />
                                    {
                                        spaceStore.websiteData.legal?.cookie?.enabled ? (
                                            <>
                                                <Text >
                                                    Pulic link: {spaceStore.domain ? `https://${spaceStore.domain}/cookie` : `https://${spaceStore.freeDomain}.klykn.com/cookie`}
                                                </Text>
                                                <Paper shadow="xs" radius="sm" p={10} bg='white' color='black' mt={20}>
                                                    <TextEditor
                                                        data={spaceStore.websiteData.legal.cookie.content}
                                                        onChange={(value) => {
                                                            console.log(value, 'changed')
                                                            if (spaceStore.websiteData.legal?.cookie) {
                                                                spaceStore.websiteData.legal.cookie.content = value
                                                            }
                                                        }} />
                                                </Paper>
                                            </>
                                        ) : null
                                    }
                                </Tabs.Panel>
                                <Tabs.Panel value="licenses">
                                    <Switch
                                        width={'100%'}
                                        mt={4}
                                        mb={4}
                                        color="dimmed"
                                        // size={'sm'}
                                        labelPosition="left"
                                        styles={{
                                            body: {
                                                width: '100%',
                                                justifyContent: 'space-between',
                                            },
                                            label: {
                                                // fontSize: 12,
                                                // width: '92px'
                                            },
                                            input: {
                                                borderRadius: 2,
                                                borderColor: '#464646',
                                                backgroundColor: 'transparent',
                                            }
                                        }}
                                        label={
                                            <Flex align={'center'} gap={12}>
                                                <Text>
                                                    {t("Enabled")}
                                                </Text>
                                            </Flex>
                                        }
                                        checked={!!spaceStore.websiteData.legal?.licenses?.enabled}
                                        onChange={(value) => {
                                            if (spaceStore.websiteData.legal?.licenses) {
                                                spaceStore.websiteData.legal.licenses.enabled = value.target.checked;
                                            } else {
                                                if (spaceStore.websiteData.legal) {

                                                    spaceStore.websiteData.legal.licenses = {
                                                        enabled: value.target.checked,
                                                        content: spaceStore.websiteData.legal.licenses.content

                                                    }
                                                } else {
                                                    spaceStore.websiteData.legal = {
                                                        licenses: {
                                                            enabled: value.target.checked,
                                                            content: ''
                                                        },
                                                        privacy: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        cookie: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        terms: {
                                                            enabled: false,
                                                            content: ''
                                                        },
                                                        imprint: {
                                                            enabled: false,
                                                            content: ''
                                                        }
                                                    }

                                                }
                                            }
                                        }}
                                    />
                                    {
                                        spaceStore.websiteData.legal?.licenses?.enabled ? (
                                            <>
                                                <Text >
                                                    Pulic link: {spaceStore.domain ? `https://${spaceStore.domain}/licenses` : `https://${spaceStore.freeDomain}.klykn.com/licenses`}
                                                </Text>
                                                <Paper shadow="xs" radius="sm" p={10} bg='white' color='black' mt={20}>
                                                    <TextEditor
                                                        data={spaceStore.websiteData.legal.licenses.content}
                                                        onChange={(value) => {
                                                            console.log(value, 'changed')
                                                            if (spaceStore.websiteData.legal?.licenses) {
                                                                spaceStore.websiteData.legal.licenses.content = value
                                                            }
                                                        }} />
                                                </Paper>
                                            </>
                                        ) : null
                                    }

                                </Tabs.Panel>
                            </Tabs>

                        </Flex>
                    </Flex>
                </Tabs.Panel>
            </Tabs >
        </Modal >
    )
}

export const SiteSettingsModal = observer(Element);