import { TextInput } from "@mantine/core"

interface IUITextInput {
    value: string | number
    placeholder?: string
    description?: string
    onChange: (value: string | '') => void
}

const UITextInput = (props: IUITextInput) => {
    return (
        <TextInput
            w={'100%'}
            size="xs"
            value={props.value}
            mt={0}
            mb={0}
            placeholder={props.placeholder}
            radius={2}
            description={props.description}
            styles={{
                icon: {
                    right: '19px',
                    left: 'auto',
                    fontSize: '10px'
                },
                root: {
                    backgroundColor: 'transparent',
                },
                input: {
                    color: 'white',
                    paddingLeft: '10px !important',
                    paddingRight: '10px',
                    backgroundColor: 'transparent',
                    borderColor: '#464646',
                }
            }}
            onChange={(e) => {
                props.onChange(e.target.value)
            }}
        />
    )
}

export { UITextInput }