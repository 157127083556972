import { Button, CopyButton, Flex, Tabs, Text, Textarea, rem } from "@mantine/core";
import { useState } from "react";
import { useStore } from "../../../../hooks/use-store";
import OtpLoginGenerator from "../otp/OtpLoginGen";
import { useRequest } from "ahooks";
import { NOTIFICATION_SERVICE } from "../../../../components/notifications/NotificationService";
import Placeholder from "../Placeholder";
import LanguageSwitcher from "../../profile/LanguageSwitcher";
import { Steps } from "../Steps";
import { SelectItem } from "./SelectItem";
import TextEditor from "./TextEditor";
import { useTranslation } from "react-i18next";

export const TermsGenScreen = () => {

    const {
        uiStore,
        dataStore: { appStore },
    } = useStore();

    const { t } = useTranslation();

    const [step, setStep] = useState(1)
    const [type, setType] = useState('')
    const [text, setText] = useState('')

    const [data, setData] = useState({
        terms: null,
        privacy: '',
        imprint: '',
    })

    const { loading: publishLoading, runAsync } = useRequest(
        async () =>
            appStore.genTerms({
                type,
                prompt: text,
                lang: uiStore.currentLanguage
            }),
        {
            loadingDelay: 20,
            manual: true,
            onSuccess: (model) => {
                if (model.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    setData(model)
                }
            },
        }
    );

    const rootStore = useStore();

    const {
        uiStore: { authStore },
    } = rootStore;

    return (
        <Flex w={'100%'} h='100%'>
            <Flex w={'35%'} direction={'column'} bg={'rgba(48, 48, 48, 1)'}>

                <Flex p="30px" gap={6} justify={'space-between'} align={'center'}>
                    <Steps active={step} steps={3} />
                    <LanguageSwitcher />
                </Flex>
                <div style={{
                    padding: '30px 60px'
                }}>
                    {
                        step === 1 ? (
                            <>
                                <Text color="white" size={24} weight={'700'} mt={10} mb={10} align="center">
                                    {t("get.started")}
                                </Text>
                                <Text color="rgba(230, 230, 230, 1)" size={12} weight={'400'} mt={10} mb={30}>
                                    {t("select.type.of.service")}
                                </Text>

                                {
                                    [
                                        t("ecommerce"),
                                        t("freelancer"),
                                        t("photographer"),
                                        t("consultant"),
                                        t("designer"),
                                    ].map((name) => (
                                        <SelectItem selected={name === type} name={name} setItem={() => {
                                            setType(name)
                                        }} />
                                    ))
                                }
                                <Flex gap={12} mt={32} justify={'end'}>
                                    <Button

                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: rem(42),

                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(2)
                                        }}
                                    >
                                        {t("next.step")}
                                    </Button>
                                </Flex>

                            </>

                        ) : null
                    }

                    {
                        step === 2 ? (
                            <>
                                <Text color="white" size={24} weight={'700'} mt={10} mb={10} align="center">
                                    {t("tell.us.moreabout.services")}

                                </Text>
                                <Text color="rgba(230, 230, 230, 1)" size={12} weight={'400'} mt={10} mb={10}>
                                    {t("tell.us.moreabout.services.desc")}
                                </Text>
                                <Textarea
                                    mt={24}
                                    label=""
                                    size="md"
                                    description=""
                                    value={text}
                                    minRows={5}
                                    placeholder=""
                                    onChange={(e) => {
                                        setText(e.currentTarget.value)
                                    }}
                                />

                                <Text color="rgba(230, 230, 230, 1)" size={12} weight={'400'} mt={10} mb={10}>
                                    {t("for.example")}  <CopyButton value={t("terms.example")}>
                                        {({ copied, copy }) => (
                                            <Button color={copied ? 'teal' : 'blue'} onClick={copy} variant={'subtle'} size="xs">
                                                {copied ? t("copy.copied") : t("copy.text")}
                                            </Button>
                                        )}
                                    </CopyButton>
                                    <br />
                                    {t("terms.example")}
                                </Text>


                                <Flex gap={12} mt={32} justify={'end'}>
                                    <Button

                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                background: '#464646',
                                                height: rem(42),
                                                fontSize: '18px',

                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(1)
                                        }}
                                    >
                                        {t("back")}
                                    </Button>
                                    <Button

                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: rem(42),

                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        onClick={() => {
                                            setStep(3)
                                        }}
                                    >
                                        {t("next.step")}
                                    </Button>
                                </Flex>

                            </>
                        ) : null
                    }
                    {
                        step === 3 ? (
                            authStore.isLoggedIn ?

                                <>
                                    <Text color="white" size={24} weight={'700'} mt={10} mb={10} align="center">
                                        {t("cool.generate.texts")}
                                    </Text>

                                    {
                                        data.terms ? (
                                            <Flex gap={12} mt={32} justify={'end'}>
                                                {/* <Button
                                                    styles={{
                                                        root: {
                                                            color: 'background: rgba(230, 230, 230, 1)',
                                                            height: rem(42),

                                                            fontSize: '18px',
                                                            background: 'rgba(35, 108, 250, 1)',
                                                            border: 'none',
                                                            position: 'relative',
                                                            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                                        }
                                                    }}
                                                    variant="default"
                                                    onClick={() => {
                                                        window.location.href = `https://app.klykn.com/sites/${iframe.id}`
                                                    }}
                                                >
                                                    Edit website
                                                </Button> */}
                                            </Flex>
                                        ) : <Flex gap={12} mt={32} justify={'end'}>
                                            <Button

                                                styles={{
                                                    root: {
                                                        color: 'background: rgba(230, 230, 230, 1)',
                                                        background: '#464646',
                                                        height: rem(42),
                                                        fontSize: '18px',

                                                        border: 'none',
                                                        position: 'relative',
                                                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                                    }
                                                }}
                                                variant="default"
                                                onClick={() => {
                                                    setStep(2)
                                                }}
                                            >
                                                {t("back")}
                                            </Button>
                                            <Button

                                                styles={{
                                                    root: {
                                                        color: 'background: rgba(230, 230, 230, 1)',
                                                        height: rem(42),

                                                        fontSize: '18px',
                                                        background: 'rgba(35, 108, 250, 1)',
                                                        border: 'none',
                                                        position: 'relative',
                                                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                                    }
                                                }}
                                                variant="default"
                                                loading={publishLoading}
                                                onClick={async () => {
                                                    await runAsync()
                                                }}
                                            >
                                                {t("generate")}
                                            </Button>
                                        </Flex>
                                    }

                                </>
                                : (
                                    <>
                                        <OtpLoginGenerator onSuccess={() => { }} />
                                    </>

                                )
                        ) : null
                    }
                </div>
            </Flex>
            <Flex w={'65%'} h={'100vh'} style={{ overflow: 'scroll' }} bg="#464646">
                {
                    data.terms ? (
                        <Flex align={'ceter'} justify={'center'} w={'100%'} pos='relative' bg="#464646" p={30}>
                            <Flex w={'600px'} direction={'column'} bg="#464646">

                                <Tabs variant="pills" defaultValue="terms">
                                    <Tabs.List mb={24}>
                                        <Tabs.Tab value="terms" >
                                            {t("terms")}
                                        </Tabs.Tab>
                                        <Tabs.Tab value="imprint" >
                                            {t("imprint")}
                                        </Tabs.Tab>
                                        <Tabs.Tab value="privacy"  >
                                            {t("privacy")}
                                        </Tabs.Tab>
                                    </Tabs.List>

                                    <Tabs.Panel value="terms">
                                        <TextEditor data={data.terms} />
                                    </Tabs.Panel>

                                    <Tabs.Panel value="imprint">
                                        <TextEditor data={data.imprint} />
                                    </Tabs.Panel>

                                    <Tabs.Panel value="privacy">
                                        <TextEditor data={data.privacy} />
                                    </Tabs.Panel>
                                </Tabs>

                            </Flex>

                        </Flex >

                    ) : (
                        <Flex bg="#464646" pos={'relative'}>
                            <Placeholder />
                        </Flex>
                    )
                }
            </Flex >
        </Flex >
    );
}