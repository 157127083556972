import { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import throttle from "lodash/throttle";

interface Props {
    disabled?: boolean
    data: string
    onChange?: (value: any) => void
}

const TextComponent = ({ data, onChange, disabled }: Props) => {
    const debouncedChangeHandler = throttle((...params) => {
        if (onChange) {
            onChange(params)
        }

        // onChangeText(params, date)
    }, 300);

    const editor = useEditor({
        editable: !disabled,
        onUpdate({editor}) {
            console.log(editor.getJSON())
        },
        onBlur({ editor }) {
            debouncedChangeHandler(JSON.stringify(editor.getJSON()));
        },
        extensions: [
            
            StarterKit.configure({
                history: false,
            }),
            Link.configure({
                openOnClick: true,
            }),
            TextAlign.configure({
                types: ["heading", "paragraph"],
            }),
        ],

        content: `<p></p>`,
    });

    useEffect(() => {
        if (data) {
            try {
                editor?.commands.setContent(JSON.parse(data));
            } catch (e) {
                editor?.commands.setContent(data);
            }
        }
    }, [editor, data]);

    if (!editor) {
        return null
    }
    return (
            <EditorContent
                style={{
                    borderRadius: '0.125rem',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    fontSize: '14px',
                    color: 'white',
                    backgroundColor: 'rgb(48, 48, 48)',
                    border: '0.0625rem solid rgb(70, 70, 70)',
                    width: '100%',
                }}
                placeholder={''}
                editor={editor}
                readOnly={disabled}
            />

    );
};

export default TextComponent;
