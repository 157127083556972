import { Text, Flex } from '@mantine/core';

export const SitePropertyLine = (props: {
    input: any,
    width?: string,
    label: any
}) => {

    return (
        <Flex justify={'space-between'} align={'center'} mt={10} mb={10} >
            <Text fw={'500'} size={12} color='rgba(190, 190, 190, 1)'>
                {props.label}
            </Text>
            <div style={{ maxWidth: props.width || '60%' }}>
                {props.input}
            </div>
        </Flex>
    )
}