import { Flex, createStyles } from "@mantine/core"
import { useWindowSize } from "../../../../helpers/helper";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        // height: '1700px',
        marginTop: '20px',
        // overflow: 'hidden',
        // margin: '19px',
        '> iframe': {
            background: 'white',
            border: 'none',
            borderRadius: '4px',
            // transform: 'scale(0.7)',
            position: 'absolute',
            // top: '0',
            // left: '3.3973%',
            transformOrigin: 'left top',
            // height: '938.1px',
            margin: '0px auto',
            width: '100%',

            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 30px',
        }
    },

    wrapper: {
        // padding: '14px',
        // width: '0',
        zIndex: 1,
        margin: '0 auto',
    }
}));

export const IframePreview = (props: { site: string, height?: number }) => {

    const { classes } = useStyles();

    const { width } = useWindowSize()

    const [viewport, setViewport] = useState(1440)
    const currentViewport = viewport
    const transform =
        (width as any * 0.65 - 80) / (currentViewport)

    console.log(transform, viewport)
    return (
        <div style={{ maxHeight: props.height || '1200px', overflow: 'scroll' }}>
            <Flex justify={'center'}>
                <div
                    onClick={() => setViewport(1440)}
                    style={{
                        background: viewport === 1440 ? 'black' : undefined,
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/linear/monitor">
                            <g id="monitor">
                                <path id="Vector" d="M6.94 2H18.05C21.61 2 22.5 2.89 22.5 6.44V12.77C22.5 16.33 21.61 17.21 18.06 17.21H6.94C3.39 17.22 2.5 16.33 2.5 12.78V6.44C2.5 2.89 3.39 2 6.94 2Z" stroke={viewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_2" d="M12.5 17.22V22" stroke={viewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_3" d="M2.5 13H22.5" stroke={viewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_4" d="M8 22H17" stroke={viewport === 1440 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div
                    onClick={() => setViewport(810)}
                    style={{
                        background: viewport === 810 ? 'black' : undefined,
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/linear/mobile">
                            <g id="mobile">
                                <path id="Vector" d="M0.833338 13.1667L0.833338 4.83337C0.833337 1.50004 1.66667 0.666708 5 0.666707L10 0.666707C13.3333 0.666707 14.1667 1.50004 14.1667 4.83337L14.1667 13.1667C14.1667 16.5 13.3333 17.3334 10 17.3334L5.00001 17.3334C1.66667 17.3334 0.833339 16.5 0.833338 13.1667Z" stroke={viewport === 810 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_2" d="M5.83334 14.4167L9.16667 14.4167" stroke={viewport === 810 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </g>
                    </svg>

                </div>
                <div onClick={() => setViewport(320)}
                    style={{
                        background: viewport === 320 ? 'black' : undefined,
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >

                    <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="vuesax/linear/mobile">
                            <g id="mobile">
                                <path id="Vector" d="M1.23333 11.3333L1.23333 4.66663C1.23333 1.99996 1.76666 1.33329 3.89999 1.33329L7.09999 1.33329C9.23333 1.33329 9.76666 1.99996 9.76666 4.66663L9.76666 11.3333C9.76666 14 9.23333 14.6666 7.1 14.6666L3.89999 14.6666C1.76666 14.6666 1.23333 14 1.23333 11.3333Z" stroke={viewport === 320 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                                <path id="Vector_2" d="M4.43333 12.3333L6.56667 12.3333" stroke={viewport === 320 ? 'white' : '#737373'} strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </g>
                    </svg>
                </div>
            </Flex>
            <Flex justify={'center'}>
                <div
                    className={classes.wrapper}
                >
                    <div className={classes.container}
                        style={{
                            width: `${width as any * 0.65 - 80}px`,
                            height: `calc( 100vh - 74px )`,
                        }}>
                        <iframe
                            title="preview"
                            style={{
                                transform: `scale(${Math.min(transform, 1)}) translateX(-50%)`,
                                position: 'absolute',
                                top: `0`,
                                left: `50%`,
                                // height: '200vh',
                                height: `calc(100vh / ${Math.min(transform, 1)} - 114px / ${Math.min(transform, 1)})`,
                                width: currentViewport,
                                backgroundColor: 'white'
                            }}
                            src={props.site}
                        >

                        </iframe>
                    </div>
                </div>
            </Flex>

        </div>

    )
}
