import { Image, Flex } from '@mantine/core';

const icon = () => (
    <Flex style={{ width: '100%', height: '100%' }} align={'center'}>
        <Image
            width={'100%'}
            height={'100%'}
            styles={{
                image: {
                    alignContent: 'center',
                }
            }}
            src={'/static/background.png'}
            alt="With default placeholder"
            withPlaceholder
            fit="contain"
        />
    </Flex>
)

export default icon