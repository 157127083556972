import { Button, Flex, Text, Image, rem } from "@mantine/core";
import { IframePreview } from "../gen/website/IframePreview";
import { useStore } from "../../../hooks/use-store";
import OtpLoginGenerator from "../gen/otp/OtpLoginGen";
import Placeholder from "../gen/Placeholder";
import LanguageSwitcher from "../profile/LanguageSwitcher";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";
import { useRequest } from "ahooks";
import { useTranslation } from "react-i18next";
import { LeadModal } from "./LeadModal";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import useIsMobile from "../../../hooks/isMobile";
import { templates } from "./templates";

export const TemplateScreen = () => {
    const isMobile = useIsMobile()

    const { t } = useTranslation();

    const { name, replaceId } = useParams<{ name: string, replaceId?: string }>();

    const [website, setWebsite] = useState<any>(null);
    const [leadModalOpened, setLeadModalOpened] = useState(false);
    const [showLogin, setShowLogin] = useState(false)
    const content = templates
    const {
        uiStore: { authStore },
        dataStore: { appStore },
    } = useStore();

    const history = useHistory();

    const { loading: createWebsiteLoading, runAsync: createWebsite } = useRequest(
        async (model: { name: string, freeDomain: string, replaceId? :string }) => appStore.createWebsite(model),
        {
            manual: true,
            onSuccess: (model: any) => {
                return model;
            },
        }
    );

    const item = content.find((item) => item.url === name)

    return (
        <Flex w={'100%'} h='100%' wrap={'wrap'}>
            <Flex w={isMobile ? '100%' : '35%'} direction={'column'} bg={'rgba(48, 48, 48, 1)'}>

                <Flex p="30px" gap={6} justify={'space-between'} align={'center'}>
                    <Button 
                    onClick={async () => {
                        if (replaceId && replaceId !== 'default') {
                            history.push(`/templates/${replaceId}/`);
                        } else {
                            history.push(`/templates/`);
                        }
                    }}
                    >
                        {t("back")}
                    </Button>
                    <LanguageSwitcher />
                </Flex>
                <div style={{
                    padding: '30px 60px'
                }}>
                    {
                        authStore.isLoggedIn ?
                            <>
                                <Text color="white" size={24} weight={'700'} mt={10} mb={10} align="left">
                                    {t("find.website")}
                                </Text>
                                <Text color="rgba(230, 230, 230, 1)" size={12} weight={'400'} mt={20} mb={10}>
                                    Crafted to enhance your website's visibility on search engines, our template incorporates advanced SEO features, ensuring that your content ranks prominently and attracts the right audience.
                                    <br />
                                    <br />
                                    With its flexible design, our template empowers you to customize every aspect of your website effortlessly. Tailor your site's layout, color schemes, fonts, and more to reflect your unique brand identity and captivate your visitors.
                                    {/* {t("find.website.desc")} */}
                                </Text>
                                <Flex gap={24} mt={32} justify={'space-between'}>
                                    <Button
                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                background: '#464646',
                                                height: rem(42),
                                                fontSize: '18px',
                                                width: '50%',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        // loading={publishLoading}
                                        component="a"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={item?.iframeUrl}
                                    >
                                        {t("open.preview")}
                                    </Button>
                                    <Button

                                        styles={{
                                            root: {
                                                color: 'background: rgba(230, 230, 230, 1)',
                                                height: rem(42),
                                                width: '50%',
                                                fontSize: '18px',
                                                background: 'rgba(35, 108, 250, 1)',
                                                border: 'none',
                                                position: 'relative',
                                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                            }
                                        }}
                                        variant="default"
                                        loading={createWebsiteLoading}
                                        onClick={async () => {

                                            const website = await createWebsite({
                                                replaceId: replaceId && replaceId !== 'default' ? replaceId : undefined,
                                                name: '',
                                                freeDomain: item?.iframeUrl.replace('https://', '').replace('.klykn.com/', '')!
                                            });


                                            if (website) {
                                                setWebsite(website)
                                                setLeadModalOpened(true)
                                                // history.push(`/sites/${website.id}`);
                                            } else {
                                                NOTIFICATION_SERVICE.errorNotification("Sorry, service is unavailable due to high demand. Please try later");
                                            }
                                        }}
                                    >
                                        {replaceId && replaceId !== 'default' ? 'Apply design': t("open.in.editor")} 
                                    </Button>
                                </Flex>
                            </>
                            : (
                                showLogin ?
                                    <>
                                        <OtpLoginGenerator onSuccess={() => { }} />
                                    </> :
                                    <>
                                        <Text color="white" size={24} weight={'700'} mt={10} mb={10} align="left">
                                            {t("find.website")}
                                        </Text>
                                        <Text color="rgba(230, 230, 230, 1)" size={16} weight={'400'} mt={20} mb={10}>
                                            Crafted to enhance your website's visibility on search engines, our template incorporates advanced SEO features, ensuring that your content ranks prominently and attracts the right audience.
                                            <br />
                                            <br />
                                            With its flexible design, our template empowers you to customize every aspect of your website effortlessly. Tailor your site's layout, color schemes, fonts, and more to reflect your unique brand identity and captivate your visitors.
                                            {/* {t("find.website.desc")} */}
                                        </Text>

                                        <Flex gap={24} mt={32} justify={'space-between'}>
                                            <Button

                                                styles={{
                                                    root: {
                                                        color: 'background: rgba(230, 230, 230, 1)',
                                                        background: '#464646',
                                                        height: rem(42),
                                                        fontSize: '18px',
                                                        width: '50%',
                                                        border: 'none',
                                                        position: 'relative',
                                                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                                    }
                                                }}
                                                variant="default"
                                                component="a"
                                                target="_blank"
                                                rel="noreferrer"
                                                href={item?.iframeUrl}
                                            >
                                                {t("open.preview")}
                                            </Button>
                                            <Button

                                                styles={{
                                                    root: {
                                                        color: 'background: rgba(230, 230, 230, 1)',
                                                        height: rem(42),
                                                        width: '50%',
                                                        fontSize: '18px',
                                                        background: 'rgba(35, 108, 250, 1)',
                                                        border: 'none',
                                                        position: 'relative',
                                                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                                    }
                                                }}
                                                variant="default"
                                                // loading={publishLoading}
                                                onClick={() => setShowLogin(true)}

                                            >
                                                {t("open.in.editor")}
                                            </Button>
                                        </Flex>
                                    </>
                            )
                    }
                </div>
            </Flex>
            <Flex w={isMobile ? '100%' : '65%'}>
                {
                    item?.iframeUrl ? (
                        <Flex align={'ceter'} justify={'center'} w={'100%'} pos='relative' bg="#464646">
                            <Flex
                                style={{
                                    zIndex: 1,
                                }}
                            >
                                <IframePreview site={item?.iframeUrl} height={1200} />
                            </Flex>
                            <Image
                                style={{
                                    background: 'black',
                                    opacity: 0.5,
                                    zIndex: 0,
                                }}

                                pos={'absolute'}
                                width={'100%'}
                                height={'100vh'}
                                styles={{
                                    image: {
                                        filter: 'blur(2px)',
                                        '-webkit-filter': 'blur(2px)',
                                        zIndex: 0,
                                        alignContent: 'center',
                                    }
                                }}
                                src={'/static/background-template.png'}
                                alt="With default placeholder"
                                withPlaceholder
                                fit="cover"
                            />
                        </Flex>

                    ) : (
                        <Flex bg="#464646" pos={'relative'}>
                            <Placeholder />
                        </Flex>
                    )
                }
            </Flex>


            <LeadModal
                open={website && leadModalOpened}
                siteId={website?.id}
                onClose={() => { setLeadModalOpened(false) }}
                onSkip={() => {
                    if (website) {
                        setLeadModalOpened(false)
                        history.push(`/sites/${website?.id}`);
                    }
                }}
            />

        </Flex>
    );
}