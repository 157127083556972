import { Button, rem } from "@mantine/core"

interface IUIButton {
    children: any,
    color: 'gray' | 'blue' | 'transparent'
    loading: boolean
    fluid: boolean
    onClick: () => void
}
const UIButton = (props: IUIButton) => {
    return (
        <Button
            fullWidth={props.fluid}
            loading={props.loading}
            styles={{
                root: props.color === 'blue' ?
                    {
                        color: 'background: rgba(230, 230, 230, 1)',
                        height: rem(28),
                        fontSize: '12px',
                        background: 'rgba(35, 108, 250, 1)',
                        border: 'none',
                        position: 'relative',
                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                    } : {
                        color: 'background: rgba(230, 230, 230, 1)',
                        height: rem(28),
                        background: '#464646',
                        fontSize: '12px',
                        border: 'none',
                        position: 'relative',
                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                    }
            }}
            variant={props.color === 'transparent' ? 'outline': 'default'}
            onClick={props.onClick}
        >
            {props.children}
        </Button>
    )
}

export { UIButton }