import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import TextElement from '../../stores/data/space-store/textElement';
import { Checkbox, Image, Text, Flex, Switch, Tooltip, ActionIcon, Textarea, Divider } from '@mantine/core';
import { FilesUpload, Widget } from '@uploadcare/react-widget';
import ImageElement from '../../stores/data/space-store/imageElement';
import ShapeElement from '../../stores/data/space-store/shapeElement';
import GroupElement from '../../stores/data/space-store/groupElement';
import { useRequest } from 'ahooks';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';
import { useState } from 'react';
import { googleFonts } from './fonts';
import FormElement from '../../stores/data/space-store/formElement';
import { UINumberInput } from '../../components/ui-kit/numberInput/UINumberInput';
import { SitePropertyLine } from './SitePropertyLine';
import { UIColorInput } from '../../components/ui-kit/colorInput/UIColorInput';
import { SitePropertyHeader } from './SitePropertyHeader';
import { SitePropertyPadding } from './SitePropertyPadding';
import { SitePropertyDivider } from './SitePropertyDivider';
import { SitePropertyAccordion } from './SitePropertyAccordion';
import { UISelect } from '../../components/ui-kit/select/UISelect';
import { UIButton } from '../../components/ui-kit/button/UIButton';
// import { SiteLinkModal } from './SiteLinkModal';
import { CarouselPreview } from './Site/CarouselPreview';
import { ProBadge } from './ProBadge';
import { ParralaxEffect, isNumber } from './Site/helper';
import ButtonElement from '../../stores/data/space-store/buttonElement';
import { useTranslation } from 'react-i18next';
import { UITextInput } from '../../components/ui-kit/textInput/UINumberInput';
import { SiteGenImageButton } from './SiteGenImageButton';
import { SiteLinkEditModal } from './SiteLinkEditModal';
import LanguageSwitcherElement from '../../stores/data/space-store/languageSwitcherElement';

const EditAttributes = () => {

  const {
    dataStore: { spaceStore, appStore },

  } = useStore();

  const { t } = useTranslation();

  const [rewrite, setRewrite] = useState<string>('rewrite')
  const { runAsync, loading: rewriteLoading } = useRequest(
    async (model: { tone: string }) => appStore.rewrite({
      tone: model.tone,
      // @ts-ignore
      text: spaceStore.selected!.text,
    }),
    {
      loadingDelay: 100,
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          spaceStore.updateElementProps({
            id: spaceStore.selected?.id,
            text: res.text,
          })
        }
      },
    }
  );

  const { runAsync: runAsyncConvert } = useRequest(
    async (model: { url: string }) => appStore.convertImage({
      url: model.url,
      websiteId: spaceStore.siteId,
    }),
    {
      manual: true,
      onSuccess: (res) => {
        if (res.error) {
          NOTIFICATION_SERVICE.errorNotification(
            "Sorry, service is unavailable due to high demand. Please try later"
          );
        } else {
          return res.url
          // spaceStore.updateElementProps({
          //   id: spaceStore.selected?.id,
          //   src: res.url,
          // })
        }
      },
    }
  );


  //   <div
  //   style={{ fontSize: 16 }}
  //   onClick={() => {
  //     spaceStore.deleteElement(spaceStore.selected!.id)
  //   }}>
  //   delete element
  // </div>
  return (
    <div>
      {
        spaceStore.selected ? (
          <div>
            <SitePropertyHeader
              label={t("editor.page.element.settings")}
              content={null}
            />
            <SitePropertyDivider />
            <SitePropertyAccordion
              label={t("editor.page.size.by.pixel")}
              defaultChecked={false}>
              <SitePropertyPadding>
                <Flex gap={16}>
                  <div style={{ width: '50%' }}>
                    <SitePropertyLine
                      width='87px'
                      label='X'
                      input={
                        <UINumberInput
                          value={spaceStore.selected.x}
                          step={1}
                          min={-999999}
                          max={9999999}
                          precision={0.1}
                          onChange={(value) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              x: value,
                            })
                          }}
                          suffix={'px'}
                        />
                      }
                    />
                  </div>

                  <div style={{ width: '50%' }}>
                    <SitePropertyLine
                      width='87px'
                      label='Y'
                      input={
                        <UINumberInput
                          value={spaceStore.selected.y}
                          step={1}
                          min={-999999}
                          max={9999999}
                          precision={0.1}
                          onChange={(value) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              y: value,
                            })
                          }}
                          suffix={'px'}
                        />
                      }
                    />
                  </div>
                </Flex>
                {
                  (spaceStore.selected instanceof GroupElement) ? null : (
                    <Flex gap={16}>
                      <div style={{ width: '50%' }}>
                        <SitePropertyLine
                          width='87px'
                          label={
                            // <Tooltip
                            //   label={
                            //     isNumber(spaceStore.selected.width) ?
                            //       t("Fixed width, click to change to relative width") :
                            //       t("Relative width, click to change to fixed width")
                            //   }
                            //   bg={'black'}
                            //   color='white'
                            // >
                            <Text
                            // onClick={() => {
                            //   if (isNumber(spaceStore?.selected?.width)) {
                            //     spaceStore.updateElementProps({
                            //       id: spaceStore.selected?.id,
                            //       width: '100vw',
                            //     })
                            //   } else {
                            //     spaceStore.updateElementProps({
                            //       id: spaceStore.selected?.id,
                            //       width: 100,
                            //     })
                            //   }
                            // }}
                            >
                              {
                                isNumber(spaceStore.selected.width) ? 'W' : 'Wr'
                              }
                            </Text>
                            // </Tooltip>
                          }
                          input={
                            isNumber(spaceStore.selected.width) ? (
                              <UINumberInput
                                value={+spaceStore.selected.width}
                                step={1}
                                min={1}
                                max={9999999}
                                precision={0.1}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    width: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            ) : (
                              <UITextInput
                                value={spaceStore.selected.width}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    width: value.toString(),
                                  })
                                }}
                              />
                            )
                          }
                        />
                      </div>
                      <div style={{ width: '50%' }}>
                        <SitePropertyLine
                          width='87px'
                          label={
                            // <Tooltip
                            //   label={
                            //     isNumber(spaceStore.selected.height) ?
                            //       t("Fixed height, click to change to relative height") :
                            //       t("Relative height, click to change to fixed height")
                            //   }
                            //   bg={'black'}
                            //   color='white'
                            // >
                            <Text
                            // onClick={() => {
                            //   if (isNumber(spaceStore?.selected?.height)) {
                            //     spaceStore.updateElementProps({
                            //       id: spaceStore.selected?.id,
                            //       height: '100vh',
                            //     })
                            //   } else {
                            //     spaceStore.updateElementProps({
                            //       id: spaceStore.selected?.id,
                            //       height: 100,
                            //     })
                            //   }
                            // }}
                            >
                              {
                                isNumber(spaceStore.selected.height) ? 'H' : 'Hr'
                              }
                            </Text>
                            // </Tooltip>
                          }
                          input={
                            isNumber(spaceStore.selected.height) ? (
                              <UINumberInput
                                value={+spaceStore.selected.height}
                                step={1}
                                min={1}
                                max={9999999}
                                precision={0.1}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    height: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            ) : (
                              <UITextInput
                                value={spaceStore.selected.height}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    height: value.toString(),
                                  })
                                }}
                              />
                            )
                          }
                        />
                      </div>
                    </Flex>)
                }
                <Flex gap={16}>
                  <div style={{ width: '50%' }}>
                    <SitePropertyLine
                      width='87px'
                      label='D'
                      input={
                        <UINumberInput
                          value={spaceStore.selected.rotate}
                          step={1}
                          min={0}
                          max={365}
                          precision={2}
                          onChange={(value) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              rotate: value,
                            })
                          }}
                          suffix={'°'}
                        />
                      }
                    />
                  </div>
                  <div style={{ width: '50%' }}>

                  </div>
                </Flex>
              </SitePropertyPadding>
            </SitePropertyAccordion>
            <SitePropertyDivider />
            <SitePropertyPadding>
              <Switch
                width={'100%'}
                mt={4}
                mb={4}
                color="dimmed"
                size={'sm'}
                labelPosition="left"
                styles={{
                  body: {
                    width: '100%',
                    justifyContent: 'space-between',
                  },
                  label: {
                    fontSize: 12,
                    width: '92px'
                  },
                  input: {
                    borderRadius: 2,
                    borderColor: '#464646',
                    backgroundColor: 'transparent',
                  }
                }}
                label={
                  <Flex align={'center'} gap={12}>
                    <Text>
                      {t("editor.page.pin")}
                    </Text>
                    {/* <ProBadge /> */}
                  </Flex>
                }
                checked={spaceStore.selected.position === 'fixed'}
                onChange={(value) => {
                  spaceStore.updateElementProps({
                    id: spaceStore.selected?.id,
                    position: value.target.checked ? 'fixed' : 'absolute',
                  })
                }}
              />
              {/* {
                spaceStore.selected.position === 'fixed' ? <Switch
                  width={'100%'}
                  mt={4}
                  mb={4}
                  color="dimmed"
                  size={'sm'}
                  labelPosition="left"
                  styles={{
                    body: {
                      width: '100%',
                      justifyContent: 'space-between',
                    },
                    label: {
                      fontSize: 12,
                      width: '92px'
                    },
                    input: {
                      borderRadius: 2,
                      borderColor: '#464646',
                      backgroundColor: 'transparent',
                    }
                  }}
                  label={
                    <Flex align={'center'} gap={12}>
                      <Text>
                        {t("editor.page.pin")}
                      </Text>
                      <ProBadge />
                    </Flex>
                  }
                  checked={spaceStore.selected.position === 'fixed'}
                  onChange={(value) => {
                    spaceStore.updateElementProps({
                      id: spaceStore.selected?.id,
                      position: value.target.checked ? 'fixed' : 'absolute',
                    })
                  }}
                /> : null
              } */}

            </SitePropertyPadding>
            <SitePropertyDivider />
            <SitePropertyPadding>
              <SiteLinkEditModal />
              {/* <SitePropertyLine
                width='198px'
                label={t("editor.page.link")}
                input={
                  <SiteLinkModal
                    url={spaceStore.selected.link}
                    onCompleted={function (link): void {
                      spaceStore.updateElementProps({
                        id: spaceStore.selected?.id,
                        link,
                      })
                    }}
                  />
                }
              /> */}
            </SitePropertyPadding>
            <SitePropertyDivider />
            {
              spaceStore.selected instanceof ShapeElement || spaceStore.selected instanceof ImageElement || spaceStore.selected instanceof FormElement || spaceStore.selected instanceof ButtonElement || spaceStore.selected instanceof LanguageSwitcherElement ? (
                <>
                  <SitePropertyPadding>
                    <SitePropertyLine
                      width='98px'
                      label={t("editor.page.corner.radius")}
                      input={
                        <UINumberInput
                          value={spaceStore.selected.borderRadius}
                          step={1}
                          min={0}
                          max={99999}
                          precision={2}
                          onChange={(value) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              borderRadius: value,
                            })
                          }}
                          suffix={'px'}
                        />
                      }
                    />
                  </SitePropertyPadding>
                  <SitePropertyDivider />
                </>

              )
                : null
            }

            {
              spaceStore.selected instanceof ImageElement ? (<div>
                {
                  spaceStore.selected.type === 'IMAGE' ? (
                    <>

                      <SitePropertyAccordion
                        label={t("editor.page.content")}
                        defaultChecked={true}>
                        <SitePropertyPadding>
                          <SitePropertyLine
                            width='92px'
                            label={t("editor.page.image.fit")}
                            input={
                              <UISelect
                                searchable={false}
                                data={['cover', 'contain']}
                                value={spaceStore.selected.objectFit}
                                onChange={(e) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    objectFit: e,
                                  })
                                }}
                              />
                            }
                          />
                          {
                            spaceStore.selected.src ? (
                              <Image
                                width={'100%'}
                                height={140}
                                styles={{
                                  image: {
                                    borderRadius: '4px',
                                  }
                                }}
                                src={spaceStore.selected.src}
                                alt="With default placeholder"
                                withPlaceholder
                                fit="contain"
                              />
                            ) : (
                              <Flex direction={'column'} bg={'rgba(70, 70, 70, 1)'} style={{ borderRadius: '2px' }} justify={'center'} align={'center'} p={44}>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M26 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44H30C40 44 44 40 44 30V20" stroke="#989898" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M31.5 10H42.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                  <path d="M37 15.5V4.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                  <path d="M5.33984 37.9001L15.1998 31.2801C16.7798 30.2201 19.0598 30.3401 20.4798 31.5601L21.1398 32.1401C22.6998 33.4801 25.2198 33.4801 26.7798 32.1401L35.0998 25.0001C36.6598 23.6601 39.1798 23.6601 40.7398 25.0001L43.9998 27.8001" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </Flex>
                            )
                          }
                          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Widget
                              localeTranslations={{
                                buttons: {
                                  choose: {
                                    files: {
                                      one: 'Upload'
                                    },
                                  },
                                },
                              }}
                              clearable={false}
                              // @ts-ignore
                              onChange={async (info: { cdnUrl: string }) => {
                                const id = spaceStore.selected?.id
                                const res = await runAsyncConvert({ url: info.cdnUrl })
                                spaceStore.updateElementProps({
                                  id: id,
                                  src: res.url,
                                })
                              }}
                              tabs={"file camera url"}
                              publicKey="f4d9f840695d97e9cbb7"
                              previewStep={true}
                              crop="free, 16:9, 4:3, 5:4, 1:1"
                            />
                          </div>
                          {spaceStore.selected!.src ? (
                            <UIButton

                              loading={false}
                              fluid
                              color="gray"
                              onClick={() => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  src: null,
                                })
                              }}
                            >
                              {t("editor.page.image.delete")}

                            </UIButton>
                          ) : null}
                          <Divider
                            mt="xl"
                            mb={0}
                            label={
                              <Flex align={'center'} gap={8}>
                                <Text>
                                  {t("AI Copilot")}
                                </Text>
                                <ProBadge />
                              </Flex>
                            } labelPosition="center"
                          />
                          <Flex mt={10}>

                            <SiteGenImageButton
                              content={undefined}
                              label={''}
                              onCompleted={function (image: string): void {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  src: image,
                                })
                              }} />
                          </Flex>
                        </SitePropertyPadding>
                        <SitePropertyPadding>
                          <SitePropertyLine
                            width='160px'
                            label='Alt text'
                            input={
                              <UITextInput
                                value={spaceStore.selected.alt!}
                                placeholder="A gray tabby cat lounging on a windowsill"
                                onChange={(e) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    alt: e,
                                  })
                                }}
                              />
                            }
                          />
                        </SitePropertyPadding>
                      </SitePropertyAccordion>
                    </>) : null
                }
                {
                  spaceStore.selected.type === 'SLIDER' ? (
                    <>

                      <SitePropertyAccordion
                        label={t("editor.page.content")}
                        defaultChecked={true}>
                        <SitePropertyPadding>
                          <SitePropertyLine
                            width='92px'
                            label={t("editor.page.image.fit")}
                            input={
                              <UISelect
                                searchable={false}
                                data={['cover', 'contain']}
                                value={spaceStore.selected.objectFit}
                                onChange={(e) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    objectFit: e,
                                  })
                                }}
                              />
                            }
                          />
                          {
                            spaceStore.selected.set ? (
                              <CarouselPreview
                                slides={spaceStore.selected.set}
                              />
                            ) : (
                              <Flex direction={'column'} bg={'rgba(70, 70, 70, 1)'} style={{ borderRadius: '2px' }} justify={'center'} align={'center'} p={44}>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M18 20C20.2091 20 22 18.2091 22 16C22 13.7909 20.2091 12 18 12C15.7909 12 14 13.7909 14 16C14 18.2091 15.7909 20 18 20Z" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M26 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44H30C40 44 44 40 44 30V20" stroke="#989898" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  <path d="M31.5 10H42.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                  <path d="M37 15.5V4.5" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" />
                                  <path d="M5.33984 37.9001L15.1998 31.2801C16.7798 30.2201 19.0598 30.3401 20.4798 31.5601L21.1398 32.1401C22.6998 33.4801 25.2198 33.4801 26.7798 32.1401L35.0998 25.0001C36.6598 23.6601 39.1798 23.6601 40.7398 25.0001L43.9998 27.8001" stroke="#236CFA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                              </Flex>
                            )
                          }
                          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Widget
                              localeTranslations={{
                                buttons: {
                                  choose: {
                                    files: {
                                      one: 'Upload'
                                    },
                                  },
                                },
                              }}
                              multiple={true}
                              multipleMax={20}
                              clearable={false}
                              // @ts-ignore
                              onFileSelect={async (res) => {
                                const imgs: any[] = [];
                                if (res) {
                                  const files = (res as FilesUpload).files();
                                  for (const file of files) {
                                    // @ts-ignore
                                    const img = await file.promise();

                                    const res = await runAsyncConvert({ url: img.cdnUrl })
                                    imgs.push(res.url);
                                  }
                                }

                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  set: imgs,
                                })

                              }}
                              tabs={"file camera url"}
                              publicKey="f4d9f840695d97e9cbb7"
                              previewStep={true}
                              crop="free, 16:9, 4:3, 5:4, 1:1"
                            />
                          </div>
                          {spaceStore.selected!.set ? (
                            <UIButton

                              loading={false}
                              fluid
                              color="gray"
                              onClick={() => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  set: null,
                                })
                              }}
                            >
                              {t("editor.page.set.delete")}
                            </UIButton>
                          ) : null}

                          <SitePropertyLine
                            width='87px'
                            label={t("editor.page.image.time")}
                            input={
                              <UINumberInput
                                value={spaceStore.selected.sliderDuration}
                                step={1}
                                min={1}
                                max={1000000}
                                precision={2}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    sliderDuration: value,
                                  })
                                }}
                                suffix={'ms'}
                              />
                            }
                          />
                          <SitePropertyLine
                            width='87px'
                            label={t("editor.page.image.transition.duration")}
                            input={
                              <UINumberInput
                                value={spaceStore.selected.transitionDuration}
                                step={1}
                                min={1}
                                max={100000000}
                                precision={2}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    transitionDuration: value,
                                  })
                                }}
                                suffix={'ms'}
                              />
                            }
                          />
                        </SitePropertyPadding>
                      </SitePropertyAccordion>
                    </>) : null
                }
                {spaceStore.selected.type === 'VIDEO' ? (
                  <SitePropertyPadding>
                    <SitePropertyLine
                      width='160px'
                      label='Video src'
                      input={
                        <UITextInput
                          value={spaceStore.selected.src}
                          placeholder="https://video.com/video.mp4"
                          onChange={(e) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              src: e,
                            })
                          }}
                        />
                      }
                    />

                  </SitePropertyPadding>
                ) : null}
              </div>
              )
                : null
            }
            {
              spaceStore.selected instanceof ShapeElement && spaceStore.selected.type === 'CODE' ? (
                <SitePropertyPadding>
                  <Textarea
                    label={t("editor.page.code")}
                    size="xs"
                    description=""
                    value={spaceStore.selected.code}
                    placeholder=""
                    onChange={(e) => {
                      spaceStore.updateElementProps({
                        id: spaceStore.selected?.id,
                        code: e.target.value,
                      })
                    }}
                  />
                </SitePropertyPadding>
              ) : null
            }
            {
              spaceStore.selected instanceof TextElement || spaceStore.selected instanceof ButtonElement ? (
                <SitePropertyAccordion
                  label={t("editor.page.text")}
                  defaultChecked={true}>
                  <SitePropertyPadding>
                    <div
                      style={{
                        width: '100%',
                      }}

                    >
                      <SitePropertyLine
                        width='172px'
                        label={t("editor.page.font.family")}
                        input={
                          <UISelect
                            data={[
                              ...spaceStore.getCustomFonts,
                              ...googleFonts,
                            ]}
                            searchable={true}
                            value={spaceStore.selected.typefaceId}
                            onChange={(e) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                typefaceId: e,
                              })
                            }}
                          />
                        }
                      />
                    </div>

                    <Flex gap={16}>
                      <Flex style={{ width: '50%' }} mt={10} mb={10}>
                        <UISelect
                          data={['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000']}
                          searchable={false}
                          value={spaceStore.selected.fontWeight.toString()}
                          onChange={(e) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              fontWeight: e,
                            })
                          }}
                        />
                      </Flex>
                      <div style={{ width: '50%' }}>
                        <SitePropertyLine
                          width='87px'
                          label='T'
                          input={
                            <UINumberInput
                              value={spaceStore.selected.fontSize}
                              step={1}
                              min={1}
                              max={9999999}
                              precision={1}
                              onChange={(value) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  fontSize: value,
                                })
                              }}
                              suffix={'px'}
                            />
                          }
                        />
                      </div>
                    </Flex>
                    <Flex gap={16}>
                      <div style={{ width: '50%' }}>
                        <SitePropertyLine
                          width='87px'
                          label='H'
                          input={
                            <UINumberInput
                              value={spaceStore.selected.lineHeight}
                              max={100}
                              step={1}
                              precision={2}
                              min={0}
                              onChange={(value) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  lineHeight: value,
                                })
                              }}
                              suffix={'px'}
                            />
                          }
                        />
                      </div>
                      <div style={{ width: '50%' }}>
                        <SitePropertyLine
                          width='87px'
                          label='L'
                          input={
                            <UINumberInput
                              value={spaceStore.selected.letterSpacing}
                              min={0}
                              max={100}
                              step={0.1}
                              precision={2}

                              onChange={(value) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  letterSpacing: value,
                                })
                              }}
                              suffix={'px'}
                            />
                          }
                        />
                      </div>
                    </Flex>
                    <Flex gap={16} mt={10} mb={10}>
                      <Flex style={{ width: '50%' }}>
                        <ActionIcon variant="transparent" bg={spaceStore.selected.textAlign === 'left' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                          onClick={() => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              textAlign: 'left',
                            })
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2 6.33325L8.31333 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2 13L8.31333 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </ActionIcon>
                        <ActionIcon variant="transparent" bg={spaceStore.selected.textAlign === 'center' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                          onClick={() => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              textAlign: 'center',
                            })
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.84009 6.33325L11.1601 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.84009 13L11.1601 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </ActionIcon>
                        <ActionIcon variant="transparent" bg={spaceStore.selected.textAlign === 'right' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                          onClick={() => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              textAlign: 'right',
                            })
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.68677 6.33325L14.0001 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.68677 13L14.0001 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </ActionIcon>
                      </Flex>

                      <Flex style={{ width: '50%' }}>
                        <ActionIcon variant="transparent" bg={spaceStore.selected.textDecoration === 'none' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                          onClick={() => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              textDecoration: 'none',
                            })
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 8L12 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </ActionIcon>
                        <ActionIcon variant="transparent" bg={spaceStore.selected.textDecoration === 'underline' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                          onClick={() => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              textDecoration: 'underline',
                            })
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.33325 14L12.6666 14" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.33325 2L3.33325 6.66667C3.33325 9.24667 5.41992 11.3333 7.99992 11.3333C10.5799 11.3333 12.6666 9.24667 12.6666 6.66667V2" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>

                        </ActionIcon>
                        <ActionIcon variant="transparent" bg={spaceStore.selected.textDecoration === 'line-through' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                          onClick={() => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              textDecoration: 'line-through',
                            })
                          }}
                        >
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.00007 7.9998C8.70932 7.9998 9.40512 8.15257 10.0154 8.4407C10.2909 8.57131 10.545 8.72725 10.7714 8.90536C11.0461 9.12067 11.2819 9.36843 11.4688 9.64152C11.8107 10.1378 11.9785 10.6991 11.9564 11.2658C11.9342 11.8326 11.7221 12.3835 11.3421 12.8624C10.9621 13.3413 10.4286 13.73 9.79695 13.9865C9.14825 14.2472 8.4505 14.3634 7.75231 14.3268C7.06129 14.2956 6.38598 14.1104 5.77575 13.7846C5.18998 13.4664 4.72137 13.0271 4.41978 12.5141M8.00007 7.9998L1.66748 7.9998M8.00007 7.9998L14.3327 7.9998M11.5812 3.48545C11.2796 2.97251 10.811 2.53398 10.2244 2.21497C9.61433 1.88965 8.93931 1.70467 8.24863 1.67353C7.55029 1.6364 6.85226 1.75201 6.2032 2.01233C5.57152 2.26959 5.038 2.65825 4.65805 3.13636C4.27809 3.61606 4.06674 4.16779 4.04379 4.73455C4.03745 4.90078 4.04774 5.06543 4.07307 5.22929" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </ActionIcon>
                      </Flex>
                    </Flex>
                    <Divider
                      mt="xl"
                      mb={0}
                      label={
                        <Flex align={'center'} gap={8}>
                          <Text>
                            {t("AI Copilot")}
                          </Text>
                          <ProBadge />
                        </Flex>
                      } labelPosition="center"
                    />
                    <Flex mt={10} mb={10} >
                      <div style={{ width: '100%' }}>
                        <SitePropertyLine
                          width='120px'
                          label={t("Choose option")}
                          input={
                            <UISelect
                              placeholder={t("")}
                              data={[{
                                label: "Rephrase",
                                value: "rewrite",
                              },
                              {
                                label: "Shorten",
                                value: "reduce",
                              },
                              {
                                label: "Extend",
                                value: "extend",
                              },
                              {
                                label: "Neutral",
                                value: "neutral",
                              },
                              {
                                label: "Friendly",
                                value: "friendly",
                              },
                              {
                                label: "Excited",
                                value: "excited",
                              },
                              {
                                label: "Academic",
                                value: "intellectual",
                              },
                              {
                                label: "Corporate",
                                value: "business",
                              }]}
                              searchable={false}
                              value={rewrite}
                              onChange={(e) => {
                                setRewrite(e!)
                              }}
                            />
                          }
                        />
                      </div>
                    </Flex>
                    <Flex>
                      <UIButton
                        fluid={true}
                        loading={rewriteLoading}
                        onClick={() => {
                          setTimeout(() => {
                            runAsync({
                              tone: rewrite
                            })
                          }, 200)
                        }}
                        color='gray'
                      >
                        <Flex align={'center'} gap={8}>
                          <Text>
                            {t("Improve with AI")}
                          </Text>
                          {/* <ProBadge /> */}
                        </Flex>


                      </UIButton>
                    </Flex>
                  </SitePropertyPadding>
                </SitePropertyAccordion>
              )
                : null
            }
            {
              spaceStore.selected instanceof LanguageSwitcherElement ? (
                <>
                  <SitePropertyAccordion
                    label="Text styles"
                    defaultChecked={true}>
                    <SitePropertyPadding>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SitePropertyLine
                          width='120px'
                          label={t("editor.page.font.family")}
                          input={
                            <UISelect
                              data={[
                                ...spaceStore.getCustomFonts,
                                ...googleFonts,
                              ]}
                              searchable={true}
                              value={spaceStore.selected.typefaceId}
                              onChange={(e) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  typefaceId: e,
                                })
                              }}
                            />
                          }
                        />
                      </div>

                      <Flex gap={16}>
                        <Flex style={{ width: '50%' }} mt={10} mb={10}>
                          <UISelect
                            data={['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000']}
                            searchable={false}
                            value={spaceStore.selected.fontWeight.toString()}
                            onChange={(e) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fontWeight: e,
                              })
                            }}
                          />
                        </Flex>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='T'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.fontSize}
                                step={1}
                                min={1}
                                max={9999999}
                                precision={1}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    fontSize: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16}>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='H'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.lineHeight}
                                max={100}
                                step={1}
                                precision={2}
                                min={0}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    lineHeight: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='L'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.letterSpacing}
                                min={0}
                                max={100}
                                step={0.1}
                                precision={2}

                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    letterSpacing: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                    </SitePropertyPadding>
                  </SitePropertyAccordion>
                </>
              ) : null
            }

            <SitePropertyAccordion
              label="Fill"
              defaultChecked={true}>
              <SitePropertyPadding>
                <SitePropertyLine
                  width='124px'
                  label={t("editor.page.background")}
                  input={
                    <UIColorInput
                      value={spaceStore.selected.bgColor || ''}
                      onChange={(value) => {
                        spaceStore.updateElementProps({
                          id: spaceStore.selected?.id,
                          bgColor: value,
                        })
                      }}
                    />
                  }
                />
                {
                  spaceStore.selected instanceof TextElement ? (
                    <SitePropertyLine
                      width='124px'
                      label={t("editor.page.element.text.color")}
                      input={
                        <UIColorInput
                          value={spaceStore.selected.color || ''}
                          onChange={(value) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              color: value,
                            })
                          }}
                        />
                      }
                    />) : null
                }
                {
                  spaceStore.selected instanceof LanguageSwitcherElement ? (
                    <>
                      <SitePropertyLine
                        width='124px'
                        label={t("editor.page.element.text.color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.textColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                textColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("Icon size")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.iconSize}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                iconSize: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='124px'
                        label={t("Icon color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.iconColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                iconColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("Caret size")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.caretSize}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                caretSize: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='124px'
                        label={t("Caret color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.caretColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                caretColor: value,
                              })
                            }}
                          />
                        }
                      />
                    </>
                  ) : null
                }
                {
                  spaceStore.selected instanceof ButtonElement ? (
                    <>
                      <SitePropertyLine
                        width='124px'
                        label={t("editor.page.element.text.color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.color || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                color: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='124px'
                        label={t("editor.page.hover.background")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.hoverBgColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                hoverBgColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='124px'
                        label={t("editor.page.hover.color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.hoverColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                hoverColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        label={t("editor.page.border")}
                        width='124px'
                        input={
                          <UIColorInput
                            value={spaceStore.selected.borderColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                borderColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.border.width")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.borderWidth}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                borderWidth: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <Checkbox
                          mt={10}
                          mb={10}
                          color="dimmed"
                          size={'sm'}
                          labelPosition="left"
                          styles={{
                            label: {
                              fontSize: 12,
                              width: '160px'
                            },
                            input: {
                              borderRadius: 2,
                              borderColor: '#464646',
                              backgroundColor: 'transparent',
                            }
                          }}
                          label={t("Show hover state")}
                          checked={spaceStore.selected.showHoverState}
                          onChange={(e) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              showHoverState: e.currentTarget.checked,
                            })
                          }}
                        />
                      </div>
                    </>
                  ) : null
                }
                <SitePropertyLine
                  width='124px'
                  label={t("editor.page.opacity")}
                  input={
                    <UINumberInput
                      value={spaceStore.selected.opacity * 100}
                      step={0.1}
                      min={0}
                      max={100}
                      precision={2}
                      onChange={(value) => {
                        spaceStore.updateElementProps({
                          id: spaceStore.selected?.id,
                          opacity: +value / 100,
                        })
                      }}
                      suffix={'%'}
                    />
                  }
                />

                {
                  spaceStore.selected instanceof ShapeElement || spaceStore.selected instanceof LanguageSwitcherElement ? (
                    <>
                      <SitePropertyLine
                        label={t("editor.page.border")}
                        width='124px'
                        input={
                          <UIColorInput
                            value={spaceStore.selected.borderColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                borderColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.border.width")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.borderWidth}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                borderWidth: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                    </>
                  ) : null
                }
              </SitePropertyPadding>
            </SitePropertyAccordion>
            <SitePropertyPadding>
              <SitePropertyLine
                width='132px'
                label={t("editor.page.arrange")}
                input={
                  <Flex align={'center'} gap={12}>
                    {/* // To front */}
                    <Tooltip label={t("editor.page.send.to.front")} bg={'black'} color='white'>
                      <div onClick={() => {
                        spaceStore.updateElementProps({
                          id: spaceStore.selected?.id,
                          zIndex: spaceStore.getHightestZIndex() + 1,
                        })
                      }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="8.5" y="8.5" width="15" height="15" rx="1.5" stroke="#737373" />
                          <rect width="16" height="16" rx="2" fill="#BEBEBE" />
                        </svg>

                      </div>
                    </Tooltip>

                    <Tooltip label={t("editor.page.send.to.back")} bg={'black'} color='white'>
                      <div
                        onClick={() => {
                          spaceStore.checkZIndexCollisionAndRepair(2)
                          spaceStore.updateElementProps({
                            id: spaceStore.selected?.id,
                            zIndex: 2,
                          })
                        }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="8" y="8" width="16" height="16" rx="2" fill="#BEBEBE" />
                          <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#303030" stroke="#737373" />
                        </svg>
                      </div>
                    </Tooltip>

                    <Tooltip label={t("editor.page.bring.forward")} bg={'black'} color='white'>
                      <div
                        onClick={() => {
                          const zIndex = spaceStore.selected!.zIndex + 1
                          spaceStore.checkZIndexCollisionAndRepair(zIndex)
                          spaceStore.updateElementProps({
                            id: spaceStore.selected?.id,
                            zIndex,
                          })
                        }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="8.5" y="8.5" width="15" height="15" rx="1.5" fill="#303030" stroke="#737373" />
                          <rect x="4.5" y="4.5" width="15" height="15" rx="1.5" fill="#303030" stroke="#737373" />
                          <rect width="16" height="16" rx="2" fill="#BEBEBE" />
                        </svg>
                      </div>
                    </Tooltip>

                    <Tooltip label={t("editor.page.bring.backwards")} bg={'black'} color='white'>
                      <div
                        onClick={() => {
                          const zIndex = spaceStore.selected!.zIndex - 1
                          spaceStore.checkZIndexCollisionAndRepair(zIndex)
                          spaceStore.updateElementProps({
                            id: spaceStore.selected?.id,
                            zIndex,
                          })
                        }}
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="8" y="8" width="16" height="16" rx="2" fill="#BEBEBE" />
                          <rect x="4.5" y="4.5" width="15" height="15" rx="1.5" fill="#303030" stroke="#737373" />
                          <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#303030" stroke="#737373" />
                        </svg>
                      </div>
                    </Tooltip>

                  </Flex>
                }
              />
            </SitePropertyPadding>
            <SitePropertyAccordion
              label={
                <Flex align={'center'} gap={12}>
                  <Text>
                    {t("editor.page.animation")}
                  </Text>
                  {/* <ProBadge /> */}
                </Flex>
              }
              defaultChecked={true}>
              <SitePropertyPadding>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {/* <SitePropertyLine
                    width='172px'
                    label='Trigger'
                    input={
                      <UISelect
                        data={[{
                          label: 'On appear',
                          value: 'on_appear',
                        }, {
                          label: 'On scroll',
                          value: 'on_scroll',
                        }]}
                        searchable={false}
                        value={spaceStore.selected.animation?.type}
                        onChange={(e) => {
                          // spaceStore.updateElementProps({
                          //   id: spaceStore.selected?.id,
                          //   'animation': {
                          //     type: e as any
                          //   },
                          // })
                        }}
                      />
                    }
                  /> */}
                  <SitePropertyLine
                    width='172px'
                    label={t("editor.page.preset")}
                    input={
                      <UISelect
                        data={[
                          { label: 'None', value: 'none' },
                          { label: 'Fade in', value: 'fadein' },
                          { label: 'Scale in', value: 'scalein' },
                          { label: 'Slide in', value: 'slidein' }
                        ]}
                        searchable={false}
                        value={spaceStore.selected.animation?.type}
                        onChange={(e) => {
                          spaceStore.updateElementProps({
                            id: spaceStore.selected?.id,
                            'animation': {
                              type: e as any
                            },
                          })
                        }}
                      />
                    }
                  />
                </div>
              </SitePropertyPadding>
            </SitePropertyAccordion>
            <SitePropertyAccordion
              label={
                <Flex align={'center'} gap={12}>
                  <Text>
                    {t("editor.page.parralax")}
                  </Text>
                  {/* <ProBadge /> */}
                </Flex>
              }
              defaultChecked={true}
            >
              <SitePropertyPadding>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <SitePropertyLine
                    width='124px'
                    label='Parralax effect'
                    input={
                      <UISelect
                        data={[
                          { label: 'Enabled', value: 'yes' },
                          { label: 'Disabled', value: 'no' },
                        ]}
                        searchable={false}
                        value={spaceStore.selected.parralax ? 'yes' : 'no'}
                        onChange={(e) => {
                          if (e === 'no') {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              'parralax': null
                            })
                          } else {
                            const effect: ParralaxEffect = {
                              width: (spaceStore.selected?.width || 100) * 4,
                              velocity: 0.5,
                              margin: 30
                            }
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              'parralax': effect
                            })
                          }

                        }}
                      />
                    }
                  />

                  {
                    spaceStore.selected.parralax ? (
                      <SitePropertyLine
                        width='124px'
                        label='Width'
                        input={
                          <UINumberInput
                            value={spaceStore.selected.parralax.width}
                            step={0.1}
                            min={0}
                            max={1000000}
                            precision={2}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                parralax: {
                                  ...spaceStore.selected?.parralax,
                                  width: +value,
                                },
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                    ) : null
                  }
                  {
                    spaceStore.selected.parralax ? (
                      <SitePropertyLine
                        width='124px'
                        label='Margin'
                        input={
                          <UINumberInput
                            value={spaceStore.selected.parralax.margin}
                            step={0.1}
                            min={0}
                            max={1000000}
                            precision={2}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                parralax: {
                                  ...spaceStore.selected?.parralax,
                                  margin: +value,
                                },
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                    ) : null
                  }
                  {
                    spaceStore.selected.parralax ? (
                      <SitePropertyLine
                        width='124px'
                        label='Velocity'
                        input={
                          <UINumberInput
                            value={spaceStore.selected.parralax.velocity}
                            step={0.1}
                            min={0}
                            max={1000000}
                            precision={4}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                parralax: {
                                  ...spaceStore.selected?.parralax,
                                  velocity: +value,
                                },
                              })
                            }}
                            suffix={''}
                          />
                        }
                      />
                    ) : null
                  }
                </div>
              </SitePropertyPadding>
            </SitePropertyAccordion>
            {
              spaceStore.selected instanceof FormElement ? (
                <div>
                  <SitePropertyHeader
                    label="Form fields"
                    content={<div
                      style={{
                        cursor: 'pointer',
                        display: 'flex'
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          // @ts-ignore
                          const fields = [...spaceStore.selected!.fields]
                          fields.push({
                            label: 'Label',
                            placeholder: 'Placeholder',
                            type: 'text',
                            required: false,
                          })
                          spaceStore.updateElementProps({
                            id: spaceStore.selected?.id,
                            fields,
                          })

                        }}>
                        <g id="vuesax/linear/add">
                          <g id="add">
                            <path id="Vector" d="M4 8H12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                            <path id="Vector_2" d="M8 12V4" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                          </g>
                        </g>
                      </svg>
                    </div>}
                  />

                  <SitePropertyPadding>
                    <Flex gap={12} direction={'column'}>
                      {
                        spaceStore.selected.fields.map((field, index) => {
                          return (
                            <div style={{ width: '100%', background: '#3f3f3f', borderRadius: '4px', padding: '0 6px', margin: '0 -6px' }}>
                              <SitePropertyLine
                                width='110px'
                                label='Label name'
                                input={
                                  <UITextInput
                                    // size="xs"
                                    // description=""
                                    value={field.label}
                                    placeholder=''
                                    onChange={(e) => {
                                      // @ts-ignore
                                      const fields = [...spaceStore.selected!.fields]
                                      fields[index].label = e
                                      spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        fields,
                                      })
                                    }}
                                  />
                                }
                              />

                              <SitePropertyLine
                                width='110px'
                                label='Placeholder text'
                                input={
                                  <UITextInput
                                    value={field.placeholder}
                                    onChange={(e) => {
                                      // @ts-ignore
                                      const fields = [...spaceStore.selected!.fields]
                                      fields[index].placeholder = e
                                      spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        fields,
                                      })
                                    }}
                                  />
                                }
                              />

                              <SitePropertyLine
                                width='110px'
                                label='Field type'
                                input={
                                  <UISelect
                                    data={[
                                      'text',
                                      'email',
                                      'phone',
                                      'number',
                                      'date',
                                      'time',
                                      'textarea',
                                      'select',
                                      'checkbox',
                                      'radio',
                                      'file',
                                      'button',
                                    ]}
                                    searchable={true}
                                    value={field.type}
                                    onChange={(e) => {
                                      // @ts-ignore
                                      const fields = [...spaceStore.selected!.fields]
                                      fields[index].type = e
                                      spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        fields,
                                      })
                                    }}
                                  />
                                }
                              />

                              <Flex>
                                <div style={{ width: '50%' }}>
                                  <Checkbox
                                    mt={10}
                                    mb={10}
                                    color="dimmed"
                                    size={'xs'}
                                    labelPosition="left"
                                    styles={{
                                      label: {
                                        fontSize: 12,
                                        width: '60px'
                                      },
                                      input: {
                                        borderRadius: 2,
                                        borderColor: '#464646',
                                        backgroundColor: 'transparent',
                                      }
                                    }}
                                    label="Required"
                                    checked={field.required}
                                    onChange={(e) => {
                                      // @ts-ignore
                                      const fields = [...spaceStore.selected!.fields]
                                      fields[index].required = e.currentTarget.checked
                                      spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        fields,
                                      })
                                    }}
                                  />
                                </div>
                                <div style={{ width: '50%', marginTop: '4px' }}>
                                  <UIButton
                                    color={'gray'}
                                    loading={false}
                                    fluid={false}
                                    onClick={function (): void {
                                      // @ts-ignore
                                      const fields = [...spaceStore.selected!.fields]
                                      spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        fields: fields.filter((value, i) => {
                                          return i !== index
                                        })
                                      })

                                    }}
                                  >remove</UIButton>
                                </div>
                              </Flex>
                            </div>
                          )
                        })

                      }
                    </Flex>
                  </SitePropertyPadding>



                  <SitePropertyAccordion
                    label="Label field styles"
                    defaultChecked={true}>
                    <SitePropertyPadding>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SitePropertyLine
                          width='120px'
                          label={t("editor.page.font.family")}
                          input={
                            <UISelect
                              data={[
                                ...spaceStore.getCustomFonts,
                                ...googleFonts,
                              ]}
                              searchable={true}
                              value={spaceStore.selected.labelTypefaceId}
                              onChange={(e) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  labelTypefaceId: e,
                                })
                              }}
                            />
                          }
                        />
                      </div>

                      <Flex gap={16}>
                        <Flex style={{ width: '50%' }} mt={10} mb={10}>
                          <UISelect
                            data={['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000']}
                            searchable={false}
                            value={spaceStore.selected.labelFontWeight.toString()}
                            onChange={(e) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                labelFontWeight: e,
                              })
                            }}
                          />
                        </Flex>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='T'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.labelFontSize}
                                step={1}
                                min={1}
                                max={9999999}
                                precision={1}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    labelFontSize: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16}>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='H'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.labelLineHeight}
                                max={100}
                                step={1}
                                precision={2}
                                min={0}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    labelLineHeight: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='L'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.labelLetterSpacing}
                                min={0}
                                max={100}
                                step={0.1}
                                precision={2}

                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    labelLetterSpacing: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16} mt={10} mb={10}>
                        <Flex style={{ width: '50%' }}>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.labelTextAlign === 'left' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                labelTextAlign: 'left',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 6.33325L8.31333 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 13L8.31333 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.labelTextAlign === 'center' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                labelTextAlign: 'center',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 6.33325L11.1601 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 13L11.1601 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.labelTextAlign === 'right' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                labelTextAlign: 'right',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 6.33325L14.0001 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 13L14.0001 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          </ActionIcon>
                        </Flex>

                      </Flex>
                      <SitePropertyLine
                        label={t("editor.page.text.color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.labelTextColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                labelTextColor: value,
                              })
                            }}
                          />
                        }
                      />

                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.padding.left")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.labelPaddingLeft}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                labelPaddingLeft: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.padding.top")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.labelPaddingTop}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                labelPaddingTop: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                    </SitePropertyPadding>
                  </SitePropertyAccordion>


                  <SitePropertyAccordion
                    label="Form field styles"
                    defaultChecked={true}>
                    <SitePropertyPadding>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SitePropertyLine
                          width='120px'
                          label={t("editor.page.font.family")}
                          input={
                            <UISelect
                              data={[
                                ...spaceStore.getCustomFonts,
                                ...googleFonts,
                              ]}
                              searchable={true}
                              value={spaceStore.selected.fieldTypefaceId}
                              onChange={(e) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  fieldTypefaceId: e,
                                })
                              }}
                            />
                          }
                        />
                      </div>

                      <Flex gap={16}>
                        <Flex style={{ width: '50%' }} mt={10} mb={10}>
                          <UISelect
                            data={['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000']}
                            searchable={false}
                            value={spaceStore.selected.fieldFontWeight.toString()}
                            onChange={(e) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldFontWeight: e,
                              })
                            }}
                          />
                        </Flex>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='T'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.fieldFontSize}
                                step={1}
                                min={1}
                                max={9999999}
                                precision={1}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    fieldFontSize: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16}>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='H'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.fieldLineHeight}
                                max={100}
                                step={1}
                                precision={2}
                                min={0}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    fieldLineHeight: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='L'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.fieldLetterSpacing}
                                min={0}
                                max={100}
                                step={0.1}
                                precision={2}

                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    fieldLetterSpacing: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16} mt={10} mb={10}>
                        <Flex style={{ width: '50%' }}>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.fieldTextAlign === 'left' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldTextAlign: 'left',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 6.33325L8.31333 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 13L8.31333 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.fieldTextAlign === 'center' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldTextAlign: 'center',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 6.33325L11.1601 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 13L11.1601 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.fieldTextAlign === 'right' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldTextAlign: 'right',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 6.33325L14.0001 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 13L14.0001 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          </ActionIcon>
                        </Flex>

                      </Flex>
                      <SitePropertyLine
                        label={t("editor.page.text.color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.fieldTextColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldTextColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        label={t("editor.page.background")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.fieldBackgroundColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldBackgroundColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        label={t("editor.page.border")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.fieldBorderColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldBorderColor: value,
                              })
                            }}
                          />
                        }
                      />

                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.border.radius")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.fieldBorderRadius}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldBorderRadius: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.border.width")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.fieldBorderWidth}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldBorderWidth: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.padding.left")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.fieldPaddingLeft}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldPaddingLeft: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />  <SitePropertyLine
                        width='87px'
                        label={t("editor.page.padding.top")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.fieldPaddingTop}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                fieldPaddingTop: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                    </SitePropertyPadding>
                  </SitePropertyAccordion>


                  <SitePropertyAccordion
                    label="Form submit button text"
                    defaultChecked={true}>
                    <SitePropertyPadding>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SitePropertyLine
                          width='120px'
                          label={t("editor.page.font.family")}
                          input={
                            <UISelect
                              data={[
                                ...spaceStore.getCustomFonts,
                                ...googleFonts,
                              ]}
                              searchable={true}
                              value={spaceStore.selected.buttonTypefaceId}
                              onChange={(e) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  buttonTypefaceId: e,
                                })
                              }}
                            />
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SitePropertyLine
                          width='120px'
                          label='Button text'
                          input={
                            <UITextInput
                              // label="Form submit text"
                              // size="xs"
                              // description=""
                              value={spaceStore.selected.buttonText}
                              placeholder="Click me"
                              onChange={(e) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  buttonText: e,
                                })
                              }}
                            />
                          }
                        />
                      </div>


                      <Flex gap={16}>
                        <Flex style={{ width: '50%' }} mt={10} mb={10}>
                          <UISelect
                            data={['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000']}
                            searchable={false}
                            value={spaceStore.selected.buttonFontWeight.toString()}
                            onChange={(e) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonFontWeight: e,
                              })
                            }}
                          />
                        </Flex>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='T'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.buttonFontSize}
                                step={1}
                                min={1}
                                max={9999999}
                                precision={1}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    buttonFontSize: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16}>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='H'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.buttonLineHeight}
                                max={100}
                                step={1}
                                precision={2}
                                min={0}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    buttonLineHeight: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='L'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.buttonLetterSpacing}
                                min={0}
                                max={100}
                                step={0.1}
                                precision={2}

                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    buttonLetterSpacing: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16} mt={10} mb={10}>
                        <Flex style={{ width: '50%' }}>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.buttonTextAlign === 'left' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonTextAlign: 'left',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 6.33325L8.31333 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 13L8.31333 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.buttonTextAlign === 'center' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonTextAlign: 'center',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 6.33325L11.1601 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 13L11.1601 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.buttonTextAlign === 'right' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonTextAlign: 'right',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 6.33325L14.0001 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 13L14.0001 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          </ActionIcon>
                        </Flex>

                      </Flex>
                      <SitePropertyLine
                        label={t("editor.page.text.color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.buttonTextColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonTextColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        label={t("editor.page.background")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.buttonBackgroundColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonBackgroundColor: value,
                              })
                            }}
                          />
                        }
                      />
                      <SitePropertyLine
                        label={t("editor.page.border")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.buttonBorderColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonBorderColor: value,
                              })
                            }}
                          />
                        }
                      />

                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.border.radius")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.buttonBorderRadius}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonBorderRadius: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.border.width")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.buttonBorderWidth}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonBorderWidth: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                      <SitePropertyLine
                        width='87px'
                        label={t("editor.page.padding.left")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.buttonPaddingLeft}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonPaddingLeft: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />  <SitePropertyLine
                        width='87px'
                        label={t("editor.page.padding.top")}
                        input={
                          <UINumberInput
                            value={spaceStore.selected.buttonPaddingTop}
                            step={1}
                            min={0}
                            max={9999999}
                            precision={1}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                buttonPaddingTop: value,
                              })
                            }}
                            suffix={'px'}
                          />
                        }
                      />
                    </SitePropertyPadding>
                  </SitePropertyAccordion>

                  <SitePropertyAccordion
                    label="Form after submit text"
                    defaultChecked={true}>
                    <SitePropertyPadding>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SitePropertyLine
                          width='172px'
                          label='Button text'
                          input={
                            <UITextInput
                              value={spaceStore.selected.formSubmitText}
                              placeholder=""
                              onChange={(e) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  formSubmitText: e,
                                })
                              }}
                            />
                          }
                        />
                      </div>

                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <Checkbox
                          mt={10}
                          mb={10}
                          color="dimmed"
                          size={'sm'}
                          labelPosition="left"
                          styles={{
                            label: {
                              fontSize: 12,
                              width: '160px'
                            },
                            input: {
                              borderRadius: 2,
                              borderColor: '#464646',
                              backgroundColor: 'transparent',
                            }
                          }}
                          label="Show submitted state"
                          checked={spaceStore.selected.displaySavedState}
                          onChange={(e) => {
                            spaceStore.updateElementProps({
                              id: spaceStore.selected?.id,
                              displaySavedState: e.currentTarget.checked,
                            })
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <SitePropertyLine
                          width='172px'
                          label={t("editor.page.font.family")}
                          input={
                            <UISelect
                              data={[
                                ...spaceStore.getCustomFonts,
                                ...googleFonts,
                              ]}
                              searchable={true}
                              value={spaceStore.selected.formSubmitTextTypefaceId}
                              onChange={(e) => {
                                spaceStore.updateElementProps({
                                  id: spaceStore.selected?.id,
                                  formSubmitTextTypefaceId: e,
                                })
                              }}
                            />
                          }
                        />
                      </div>

                      <Flex gap={16}>
                        <Flex style={{ width: '50%' }} mt={10} mb={10}>
                          <UISelect
                            data={['100', '200', '300', '400', '500', '600', '700', '800', '900', '1000']}
                            searchable={false}
                            value={spaceStore.selected.formSubmitTextFontWeight.toString()}
                            onChange={(e) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                formSubmitTextFontWeight: e,
                              })
                            }}
                          />
                        </Flex>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='T'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.formSubmitTextFontSize}
                                step={1}
                                min={1}
                                max={9999999}
                                precision={1}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    formSubmitTextFontSize: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16}>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='H'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.formSubmitTextLineHeight}
                                max={100}
                                step={1}
                                precision={2}
                                min={0}
                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    formSubmitTextLineHeight: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                        <div style={{ width: '50%' }}>
                          <SitePropertyLine
                            width='87px'
                            label='L'
                            input={
                              <UINumberInput
                                value={spaceStore.selected.formSubmitTextLetterSpacing}
                                min={0}
                                max={100}
                                step={0.1}
                                precision={2}

                                onChange={(value) => {
                                  spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    formSubmitTextLetterSpacing: value,
                                  })
                                }}
                                suffix={'px'}
                              />
                            }
                          />
                        </div>
                      </Flex>
                      <Flex gap={16} mt={10} mb={10}>
                        <Flex style={{ width: '50%' }}>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.formSubmitTextTextAlign === 'left' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                formSubmitTextTextAlign: 'left',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 6.33325L8.31333 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 13L8.31333 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.formSubmitTextTextAlign === 'center' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                formSubmitTextTextAlign: 'center',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 6.33325L11.1601 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.84009 13L11.1601 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </ActionIcon>
                          <ActionIcon variant="transparent" bg={spaceStore.selected.formSubmitTextTextAlign === 'right' ? "rgba(70, 70, 70, 1)" : undefined} aria-label="Settings"
                            onClick={() => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                formSubmitTextTextAlign: 'right',
                              })
                            }}
                          >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2 3L14 3" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 6.33325L14.0001 6.33325" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M2 9.66675L14 9.66675" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M7.68677 13L14.0001 13" stroke="#BEBEBE" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                          </ActionIcon>
                        </Flex>

                      </Flex>
                      <SitePropertyLine
                        label={t("editor.page.text.color")}
                        input={
                          <UIColorInput
                            value={spaceStore.selected.formSubmitTextTextColor || ''}
                            onChange={(value) => {
                              spaceStore.updateElementProps({
                                id: spaceStore.selected?.id,
                                formSubmitTextTextColor: value,
                              })
                            }}
                          />
                        }
                      />
                    </SitePropertyPadding>
                  </SitePropertyAccordion>
                </div>) : null
            }





            <SitePropertyAccordion
              label={t("Achor")}
              defaultChecked={false}>
              <SitePropertyPadding>
                <SitePropertyLine
                  width='120px'
                  label='Anchor attribute'
                  input={
                    <UITextInput
                      value={spaceStore.selected.anchor!}
                      placeholder="#form"
                      onChange={(e) => {
                        spaceStore.updateElementProps({
                          id: spaceStore.selected?.id,
                          anchor: e,
                        })
                      }}
                    />
                  }
                />
              </SitePropertyPadding>
            </SitePropertyAccordion>




          </div>
        ) : null
      }
      {
        !spaceStore.selected ? (
          <div>
            <SitePropertyHeader
              label={t("editor.page.settings")}
              content={null}
            />
            <SitePropertyDivider />
            <SitePropertyPadding>
              <SitePropertyLine
                label={t("editor.page.color")}
                input={
                  <UIColorInput
                    value={spaceStore.page?.backgroundColor || ''}
                    onChange={(value) => {
                      spaceStore.updatePageProps({
                        backgroundColor: value,
                      })
                    }}
                  />
                }
              />
              <SitePropertyLine
                label={t("editor.page.height")}
                input={

                  isNumber(spaceStore.page?.height) ? (
                    <Flex>
                      <UINumberInput
                        value={+spaceStore.page?.height}
                        step={1}
                        min={100}
                        max={9999999}
                        precision={0}
                        onChange={(value) => {
                          spaceStore.updatePageProps({
                            height: +value,
                          });
                        }}
                        suffix={'px'}
                      />
                      <Tooltip label={t("Fixed height")} bg={'black'} color='white'>
                        <Flex
                          align={'center'}
                          justify={'center'}
                          // p={4}
                          style={{
                            border: '0.0625rem solid rgb(70 70 70)',
                            borderRadius: '2px',
                            paddingLeft: '4px',
                            marginLeft: '2px',
                            paddingRight: '4px',
                            cursor: 'pointer',
                          }}
                          onClick={
                            () => spaceStore.updatePageProps({
                              height: '100vh'
                            })
                          }
                        >
                          <Text>F</Text>
                        </Flex>
                      </Tooltip>
                    </Flex>
                  ) : (
                    <Flex>

                      <UITextInput
                        value={spaceStore.page?.height}
                        onChange={(value) => {
                          spaceStore.updatePageProps({
                            height: value.toString(),
                          });
                        }}
                      />
                      <Tooltip label={t("Relative height")} bg={'black'} color='white'>
                        <Flex
                          align={'center'}
                          justify={'center'}
                          style={{
                            border: '0.0625rem solid rgb(70 70 70)',
                            borderRadius: '2px',
                            paddingLeft: '4px',
                            marginLeft: '2px',
                            paddingRight: '4px',
                            cursor: 'pointer',
                          }}
                          onClick={
                            () => spaceStore.updatePageProps({
                              height: 900
                            })
                          }
                        >
                          <Text>R</Text>
                        </Flex>
                      </Tooltip>
                    </Flex>
                  )


                }
              />
              <Checkbox
                mt={10}
                mb={10}
                color="dimmed"
                size={'sm'}
                labelPosition="left"
                styles={{
                  label: {
                    fontSize: 12,
                    width: '92px'
                  },
                  input: {
                    borderRadius: 2,
                    borderColor: '#464646',
                    backgroundColor: 'transparent',
                  }
                }}
                label={t("editor.page.clip.content")}
                checked={spaceStore.clipView}
                onChange={(e) => {
                  spaceStore.changeClip()
                }}
              />
            </SitePropertyPadding>
            <SitePropertyDivider />
            <SitePropertyPadding>
              <Flex direction={'column'} bg={'rgba(70, 70, 70, 1)'} style={{ borderRadius: '2px' }} justify={'center'} align={'center'} p={16}>
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M40 40L34.6667 53.3333L24 24L53.3333 34.6667L40 40ZM40 40L53.3333 53.3333" stroke="#BEBEBE" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M19.168 5.9707L21.24 13.696M13.696 21.24L5.96799 19.168M37.2 10.8L31.5413 16.4587M16.456 31.5414L10.8027 37.2" stroke="#236CFA" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <Text align='center' size={'12px'} fw="bold">
                  {t("editor.page.select")}
                </Text>
                <Text align='center' size={'12px'}>
                  {t("editor.page.select.canvas")}
                </Text>
              </Flex>
            </SitePropertyPadding>
          </div>
        ) : null
      }

      <Flex h="90px">

      </Flex>
    </div>
  )
}

export default observer(EditAttributes);