import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import styles from "./space.module.scss";
import {
  AppShell,
  Navbar,
  createStyles,
  Group,
  Box,
  rem,
  Header,
  Burger,
  useMantineTheme,
} from "@mantine/core";
import { Route, Switch } from "react-router-dom";
// import Funnel from "./SiteDetails";
// import Faq from "./Faq";
import { SitesList } from "./SitesList";
import User from "./User";
import MainLinks from "./SidebarLinks";
import LanguageSwitcher from "../../pages/public/profile/LanguageSwitcher";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import Logo from "../../components/Logo";
import { useStore } from "../../hooks/use-store";

const useStyles = createStyles((theme) => ({
  logo: {
    display: "flex",
    "> svg": {
      height: "24px",
    },
  },
}));

const Element = () => {
  const theme = useMantineTheme();

  const { classes } = useStyles();

  const [opened, setOpened] = useState(false);

  // const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  const isDesktop = useMediaQuery(`(min-width: 500px)`);

  const {
    uiStore: { authStore },
  } = useStore();

  // TODO remove after the wailist is removed
  useEffect(() => {
    if (authStore.currentUser?.status.name === 'Inactive') {
      window.location.href = '/waitlist';
    }
  }, [authStore]);

  if (authStore.currentUser?.status.name === 'Inactive') {
    return null
  }

  return (
    <AppShell
      padding={0}

      header={
        isDesktop ? undefined :
          <Header height={{ base: 50, md: 70 }} p="md" bg={'#303030'}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>

              <div className={classes.logo}>
                <Logo size={40} />
              </div>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
              // mr="xl"
              />
            </div>
          </Header>

      }

      navbar={
        <Navbar hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 270 }} p="xs" bg={'#303030'}
          styles={{
            root: {
              borderRight: '1px solid rgba(70, 70, 70, 1)'
            }
          }}
        >
          <Navbar.Section mt="xs">
            <Box
              sx={(theme) => ({
                paddingLeft: theme.spacing.xs,
                paddingRight: theme.spacing.xs,
                paddingBottom: theme.spacing.lg,
                borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark"
                  ? theme.colors.dark[4]
                  : theme.colors.gray[2]
                  }`,
              })}
            >
              <Group position="apart">
                <div className={classes.logo}>
                  <Logo size={42} />
                </div>
                {/* <ActionIcon
                  variant="default"
                  onClick={() => toggleColorScheme()}
                  size={30}
                >
                  {colorScheme === "dark" ? (
                    <IconSun size="1rem" />
                  ) : (
                    <IconMoonStars size="1rem" />
                  )}
                </ActionIcon> */}
              </Group>
            </Box>
          </Navbar.Section>
          <Navbar.Section grow mt="xs">
            <MainLinks />
          </Navbar.Section>
          <Navbar.Section mt="xs" mb="xs">
            <LanguageSwitcher />
          </Navbar.Section>
          <Navbar.Section>
            <User />
          </Navbar.Section>
        </Navbar>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            '#303030'
        },
      })}
    >
      <Helmet>
        {/* <script type="text/javascript">
          {`
        var eyelet_wdgt = { 'id':'6343dc5b2b521fe3cffcf086'};
        (function(d) {
          var n = d.getElementsByTagName('script')[0],
          s = d.createElement('script');
          s.async = true;
          s.src = '//widgetapp.eyelet.io/code.js';
          n.parentNode.insertBefore(s, n);
        })(document);
        `}
        </script> */}
      </Helmet>

      <Switch>
        <Route path="/">
          <div className={styles.content}>
            <SitesList />

          </div>
        </Route>
        <Route path="/sites">
          <div className={styles.content}>
            <SitesList />

          </div>
        </Route>
      </Switch>
    </AppShell>
  );
};

export const SiteListLayout = observer(Element);
