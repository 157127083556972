import { Text, Flex } from '@mantine/core';

export const SitePropertyHeader = (props: {
    content: any
    label: string
}) => {

    return (
        <Flex justify={'space-between'} align={'center'} pb={8} pt={8} pl={16} pr={16}> 
            <Text 
             color="white" 
             fw={'bold'} 
             size={12} 
             >
                {props.label}
            </Text>
            <div style={{ maxWidth: '50%' }}>
                {props.content}
            </div>
        </Flex>
    )
}