import { Button, Flex, Text, Image, Modal, rem } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../hooks/use-store";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import useIsMobile from "../../../hooks/isMobile";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export const LeadModal = (model: { open: boolean, siteId: string, onSkip: () => void, onClose: () => void}) => {
    const { t } = useTranslation();

    const isMobile = useIsMobile()
    
    const posthog = usePostHog()
    
    useEffect(() => {
        if (model.open) {

            posthog?.capture('LEAD_MODAL_OPENED')
        }
    }, [model.open, posthog])
  
    const {
        dataStore: { appStore },
    } = useStore();

    return (
        <Modal
            opened={model.open}
            onClose={model.onClose}
            title={' '}
            fullScreen
            // tool="outside"
            yOffset="0" xOffset={0}
            radius={0}
            transitionProps={{ transition: 'fade', duration: 200 }}
            styles={{
                header: {
                    position: 'fixed',
                    width: '100%',
                    zIndex: 1,
                    background: 'transparent !important',
                },
                body: {
                    height: '100%',
                },
                content: {
                    height: '100%',
                },
            }}
        >

            <Flex h="100%" wrap={'wrap'}>
                <Flex w={isMobile ? '100%': '40%'} justify={'center'}>
                    <Flex w={'312px'} direction={'column'} gap={20} mt={100} mb={50}>
                        <Text color="#FFFFFF" fz="40px" fw={'900'} lh={'40px'}>Upgrade for more features</Text>
                        <Flex
                            // mt={20}
                            style={{
                                border: '1px solid #515151',
                                borderRadius: '20px',
                                padding: '20px'
                            }}
                            direction={'column'}
                            // gap={0}
                            pos={'relative'}
                        >
                            <Text color="#FFFFFF" fz="42px" fw={'900'} lh={'42px'} mb="2px">PRO</Text>
                            <Text color="#FFFFFF" fz="10px" fw={'500'} lh={'20px'} style={{
                                position: 'absolute', right: '42px', top: '30px',
                                background: '#403E4B',
                                borderRadius: '5px',
                                padding: '2px 6px',

                            }}>⚡️ LIMITED TIME: SAVE 66%</Text>
                            <Text color="#FFFFFF" fz="42px" fw={'900'} lh={'42px'} mb="2px" style={{ textDecoration: 'line-through' }}>$30 USD</Text>
                            <Flex align={'flex-end'} gap={4}>
                                <Text color="#09FF6B" fz="42px" fw={'900'} lh={'42px'} mb="2px">
                                $10 USD
                                </Text>
                                <Text
                                    fw={'500'}
                                    style={{
                                        fontSize: '16pxpx',
                                        color: 'white',

                                    }}>/mo.</Text>
                            </Flex>

                            <Button
                                mt={20}
                                styles={{
                                    root: {
                                        color: 'background: rgba(230, 230, 230, 1)',
                                        height: rem(42),

                                        fontSize: '18px',
                                        background: '#7010E9',
                                        border: 'none',
                                        position: 'relative',
                                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                    }
                                }}
                                variant="default"


                                onClick={async () => {
                                    const res = await appStore.getCheckoutLink({ siteId: model.siteId })

                                    console.log(res)
                                    if (res.url) {

                                        let a = document.createElement('a');
                                        a.target = '_blank';
                                        a.href = res.url;
                                        a.click();

                                        model.onSkip()
                                    } else {
                                        NOTIFICATION_SERVICE.errorNotification('Something went wrong')
                                    }
                                }}
                            >
                                {t("Start 7-Days Free Trial")}
                            </Button>
                            <Flex direction={'column'} mt={20}>
                                {
                                    [
                                        'Custom domain connection',
                                        'AI - Text',
                                        'AI - Image',
                                        'Animation Effects',
                                        'Code embed',
                                        'Lead forms',
                                        'Parallax',
                                        'Sticky effect',
                                        'Custom fonts',
                                        'Scripts',
                                        'Extended SEO',
                                        'Localization',
                                        'Remove klykn badge',
                                    ].map(itm =>
                                        <Flex
                                            gap={8}
                                            align={'center'}>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.5909 2.79545C12.4697 2.79906 12.3546 2.84973 12.2701 2.93672L5.13334 10.0734L2.19663 7.13672C2.15362 7.09193 2.10212 7.05618 2.04513 7.03154C1.98813 7.00691 1.9268 6.9939 1.86471 6.99327C1.80263 6.99263 1.74104 7.0044 1.68356 7.02786C1.62607 7.05133 1.57385 7.08604 1.52995 7.12994C1.48604 7.17384 1.45134 7.22606 1.42787 7.28355C1.4044 7.34103 1.39264 7.40262 1.39327 7.4647C1.39391 7.52679 1.40692 7.58812 1.43155 7.64512C1.45618 7.70211 1.49194 7.75362 1.53673 7.79662L4.8034 11.0633C4.89092 11.1508 5.0096 11.1999 5.13334 11.1999C5.25709 11.1999 5.37577 11.1508 5.46329 11.0633L12.93 3.59662C12.9974 3.53107 13.0435 3.44668 13.0621 3.3545C13.0807 3.26232 13.0711 3.16666 13.0345 3.08006C12.9978 2.99345 12.9358 2.91994 12.8567 2.86915C12.7775 2.81836 12.6849 2.79267 12.5909 2.79545Z" fill="white" />
                                            </svg>
                                            <Text
                                                fw={'500'}
                                                style={{
                                                    fontSize: '14px',
                                                    color: 'white',

                                                }}
                                            >


                                                {itm}
                                            </Text>
                                        </Flex>
                                    )
                                }
                                {/* <Flex
                                    mt={20}
                                    gap={8}
                                    align={'center'}>
                                    <svg style={{ visibility: 'hidden' }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.5909 2.79545C12.4697 2.79906 12.3546 2.84973 12.2701 2.93672L5.13334 10.0734L2.19663 7.13672C2.15362 7.09193 2.10212 7.05618 2.04513 7.03154C1.98813 7.00691 1.9268 6.9939 1.86471 6.99327C1.80263 6.99263 1.74104 7.0044 1.68356 7.02786C1.62607 7.05133 1.57385 7.08604 1.52995 7.12994C1.48604 7.17384 1.45134 7.22606 1.42787 7.28355C1.4044 7.34103 1.39264 7.40262 1.39327 7.4647C1.39391 7.52679 1.40692 7.58812 1.43155 7.64512C1.45618 7.70211 1.49194 7.75362 1.53673 7.79662L4.8034 11.0633C4.89092 11.1508 5.0096 11.1999 5.13334 11.1999C5.25709 11.1999 5.37577 11.1508 5.46329 11.0633L12.93 3.59662C12.9974 3.53107 13.0435 3.44668 13.0621 3.3545C13.0807 3.26232 13.0711 3.16666 13.0345 3.08006C12.9978 2.99345 12.9358 2.91994 12.8567 2.86915C12.7775 2.81836 12.6849 2.79267 12.5909 2.79545Z" fill="white" />
                                    </svg>
                                    <Text
                                        fw={'500'}
                                        style={{
                                            cursor: 'pointer',
                                            fontSize: '14px',
                                            color: 'white',
                                            textDecoration: 'underline'
                                        }}
                                        onClick={model.onSkip}
                                    >
                                        Skip, continue
                                    </Text>
                                </Flex> */}
                            </Flex>
                        </Flex>
                    </Flex>

                </Flex>
                <Flex w={isMobile ? '100%': '60%'} h="100%" pos={'relative'}>
                    <Flex w={'100%'} h="100%"
                    // pos={'absolute'} style={{
                    //     top: 0,
                    //     left: 0,
                    // }}
                    >
                        <Image
                            width={'100%'}
                            height={'100%'}
                            // style={{
                            //     // background: 'black',
                            //     // opacity: 0.5,
                            // }}
                            // styles={{
                            //     image: {
                            //         // filter: 'blur(2px)',
                            //         // '-webkit-filter': 'blur(2px)',
                            //         // zIndex: 0,
                            //         // alignContent: 'center',
                            //     }
                            // }}
                            src={'/static/upgrade.png'}
                            alt="With default placeholder"
                            withPlaceholder
                            fit="cover"
                        />
                    </Flex>

                </Flex>
            </Flex>
        </Modal >
    );
}