import { PLAN_TYPE } from "../../../types";

export default class User {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  email: string | null;
  avatar: string | null;
  id: string;
  identityId: string;
  plan: PLAN_TYPE;
  seats: number;
  status: { name: string };

  constructor(data: any) {
    const user = {
      ...data,
      firstName: data.first_name,
      lastName: data.last_name,
    }
    Object.assign(this, user);
  }
}
