import React, { useEffect, useState } from "react";

import {
  Paper,
  Button,
  Flex,
  TextInput,
  Text,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useStore } from "../../../hooks/use-store";
import { useDisclosure } from "@mantine/hooks";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { useTranslation } from "react-i18next";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
interface FormProps {
  name: string,
  phone: string,
  work: string,
  email: string,
}

const Survey = () => {
  const {
    uiStore,
    dataStore: { appStore },
  } = useStore();

  const [visible, { open, close }] = useDisclosure(false);
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      work: '',
      email: '',
    },
    validate: {
    },
  });

  const register = async (data: FormProps) => {
    open()
    const res = await appStore.sendSurveyRequest({
      ...data,
      lang: uiStore.currentLanguage,
    });
    close()
    if (res.ok) {
      setSubmitted(true)
    } else {
      NOTIFICATION_SERVICE.errorNotification('Please check your phone number and try again.')
      console.log(res)
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (window?.Intercom) {
      window.Intercom('shutdown')
    }
  }, [])

  return (
    <Flex justify={'center'}>

      <Paper p={20} radius="0" style={{ background: '#e7e5ee' }} w={'100%'} h={'100vh'}>
        <form onSubmit={form.onSubmit(async (values) => {
          await register(values)
          // toggle()
        })}>

          {
            submitted ? (
              <Text color="black" fz={32} fw={'bold'} align="center">
                {t("survey.sent")}
              </Text>
            ) : (
              <>
                <Text fz={18} color={'black'} fw={'bold'}>
                  {t("survey.language")}
                </Text>
                <LanguageSwitcher2 />

                <TextInput
                  mt={10}
                  mb={10}
                  required
                  size="md"
                  type="text"
                  placeholder="Sam Altman"
                  label={t("survey.name")}
                  name={"name"}
                  withAsterisk={false}
                  styles={{
                    icon: {
                      right: '19px',
                      left: 'auto',
                      fontSize: '10px'
                    },
                    root: {
                      backgroundColor: 'transparent',
                    },
                    label: {
                      color: 'black',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                    input: {
                      color: 'black',
                      paddingLeft: '10px !important',
                      paddingRight: '10px',
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                      borderColor: '#ffffff',
                    },
                  }}
                  {...form.getInputProps('name')}
                />

                <TextInput
                  mt={10}
                  mb={10}
                  required
                  size="md"
                  type="email"
                  placeholder="a@openai.com"
                  label={t("survey.email")}
                  name={"email"}
                  withAsterisk={false}
                  styles={{
                    icon: {
                      right: '19px',
                      left: 'auto',
                      fontSize: '10px'
                    },
                    root: {
                      backgroundColor: 'transparent',
                    },
                    label: {
                      color: 'black',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                    input: {
                      color: 'black',
                      paddingLeft: '10px !important',
                      paddingRight: '10px',
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                      borderColor: '#ffffff',
                    },
                  }}
                  {...form.getInputProps('email')}
                />

                <Text fz={18} color={'black'} fw={'bold'}>
                  {t("survey.phone")}
                </Text>
                <PhoneInput

                  country={'de'}
                  value={form.values.phone}
                  placeholder="+49172734352"
                  onChange={phone => {
                    form.setFieldValue('phone', phone)
                  }}
                />
                <TextInput
                  mt={10}
                  mb={10}
                  required
                  size="md"
                  type="text"
                  placeholder="OpenAI"
                  label={t("survey.work")}
                  name={"work"}
                  withAsterisk={false}
                  styles={{
                    icon: {
                      right: '19px',
                      left: 'auto',
                      fontSize: '10px'
                    },
                    root: {
                      backgroundColor: 'transparent',
                    },
                    label: {
                      color: 'black',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                    input: {
                      color: 'black',
                      paddingLeft: '10px !important',
                      paddingRight: '10px',
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                      borderColor: '#ffffff',
                    },
                  }}
                  {...form.getInputProps('work')}
                />
                <Button
                  fullWidth
                  mt="xl"
                  loading={visible}
                  styles={{
                    root: {
                      color: 'background: rgba(230, 230, 230, 1)',
                      height: '52px',
                      fontSize: '18px',
                      background: '#4d41fb',
                      border: 'none',
                      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                    }
                  }}
                  variant="default"
                  type="submit"
                >
                  {t("survey.call")}
                </Button>
              </>
            )
          }

        </form>
      </Paper>
    </Flex >
  );
};

export default Survey;
