import { useEffect } from "react";
import { observer } from "mobx-react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Link from "@tiptap/extension-link";
import { Flex } from "@mantine/core";
import MenuBar from "./text/Menubar";
import throttle from "lodash/throttle";

interface Props {
    data: string
    onChange?: (value: any) => void
}

const Text = ({ data, onChange }: Props) => {
    const debouncedChangeHandler = throttle((...params) => {
        if (onChange) {
            onChange(params)
        }

        // onChangeText(params, date)
    }, 200);

    const editor = useEditor({
        onUpdate({editor}) {
            console.log(editor.getJSON())
        },
        onBlur({ editor }) {
            debouncedChangeHandler(JSON.stringify(editor.getJSON()));
        },
        extensions: [
            StarterKit.configure({
                history: false,
            }),
            Link.configure({
                openOnClick: true,
            }),
            TextAlign.configure({
                types: ["heading", "paragraph"],
            }),
        ],

        content: `<p>Text placeholder</p>
    `,
    });

    useEffect(() => {
        if (data) {
            try {
                editor?.commands.setContent(JSON.parse(data));
            } catch (e) {
                editor?.commands.setContent(data);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor]);

    if (!editor) {
        return null
    }
    return (
        <Flex direction={'column'}>
            <MenuBar editor={editor} />
            <br />
            <EditorContent
                style={{
                    color: 'black',
                    width: '100%',
                }}
                placeholder={'Enter your notes'}
                editor={editor}
            />
        </Flex>

    );
};

export default observer(Text);
