import { Image, Flex, Modal, Text, Tabs, Textarea, Select, Button, rem } from '@mantine/core';
import { UIButton } from '../../components/ui-kit/button/UIButton';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useStore } from '../../hooks/use-store';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

const preset = {
    none: '',
    prophotos: 'multiple exposure, leica cl, uhd image, portraitures, golden light',
    vintage: '1950s vintage clipart retro green and azure, realist portraiture, associated press photo, realist: lifelike accuracy',
    dark: 'a black and white drawing, dramatic somber, religious works, lush and detailed',
    retro: '1980s, sci-fi, celestial punk, Angus Mckie, cinestill 50D, oversized objects, movie stills, futuristic contraptions, Ekta chrome',
    cinematic: 'shiges visual aesthetic style, scoutcore, video montages, floral impressionism, uhd image',
    paintings: 'botanical watercolors, 1970–present, figurative precision, utilitarian, kanō school, orange and green, comic art',
}

const SiteDetailsComponent = () => {
    const {
        dataStore: { appStore, spaceStore },
    } = useStore();

    const { t } = useTranslation();

    const [daleeText, setDaleeText] = useState<string>('')
    const [ratio, setRatio] = useState<string | null>('1:1')
    const [daleeImage, setDaleeImage] = useState<string>('')
    const [style, setStyle] = useState<string>('none')

    useEffect(() => {
        if (spaceStore.genImageModalOpen) {
            setDaleeText('')
            setDaleeImage('')
            setStyle('none')
            setRatio('1:1')
        }
    }, [
        spaceStore.genImageModalOpen])
    const { loading, runAsync: runDaleeAsync } = useRequest(
        async () => appStore.generateImage({
            text: daleeText + preset[style as keyof typeof preset],
            ratio: ratio!,
            websiteId: spaceStore.siteId,
        }),
        {
            loadingDelay: 100,
            manual: true,
            onSuccess: (res) => {
                if (res.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    setDaleeImage(res.url)

                }
            },
        }
    );
    return (
        <Modal

            opened={spaceStore.genImageModalOpen}
            onClose={() => spaceStore.closeGenImageModal()}
            title=" "
            size={'lg'}
            overlayProps={{
                blur: 3,
            }}
        >
            <Flex direction={'column'} pb={18} pt={18} pl={36} pr={36}>

                <Text size={24} weight={700} mb={20}>✨ {t("generate.with.ai")}</Text>
                <Flex justify={'space-between'} align={'center'}>
                    {
                        daleeImage ? (
                            <Flex direction={'column'} gap={12} style={{ width: '100%' }}>
                                <Image
                                    width={'100%'}
                                    height={340}
                                    src={daleeImage}
                                    alt="AI image"
                                    withPlaceholder
                                    fit="contain"
                                />
                                <UIButton
                                    loading={loading}
                                    onClick={() => {
                                        runDaleeAsync();
                                    }}
                                    color={'gray'}
                                    fluid={true}>
                                    {t("regenerate")}

                                </UIButton>
                                <UIButton
                                    loading={false}
                                    onClick={() => {
                                        setDaleeText('')
                                        setDaleeImage('')
                                        setStyle('none')
                                        setRatio('1:1')
                                    }}
                                    color={'gray'}
                                    fluid={true}>
                                    {t("Back to prompt")}

                                </UIButton>
                                <br />
                                <UIButton
                                    loading={loading}
                                    onClick={() => {
                                        spaceStore.genImageModalFunc(daleeImage)
                                        spaceStore.closeGenImageModal()
                                    }}
                                    color={'gray'}
                                    fluid={true}>
                                    {t("looks.good")}

                                </UIButton>
                            </Flex>
                        ) : (
                            <Flex direction={'column'} gap={8} style={{ width: '100%' }}>
                                <Textarea
                                    size={'sm'}
                                    label={t("describe.image")}
                                    value={daleeText}
                                    description="Describe the image you want to generate, the more details you provide, the better the result"
                                    placeholder={t("prompt.example")}
                                    onChange={(e) => {
                                        setDaleeText(e.target.value)
                                    }}
                                    mb={10}
                                    minRows={4}
                                    maxLength={300}

                                />
                                <Select
                                    size={'sm'}
                                    description="Optional. Presets help to generate images with a specific style"
                                    label="Style preset"
                                    placeholder="Pick value"
                                    value={style}
                                    onChange={(value) => {
                                        setStyle(value!)
                                    }}
                                    mb={10}
                                    data={[
                                        { value: 'none', label: 'None' },
                                        { value: 'prophotos', label: 'Pro Photos' },
                                        { value: 'dark', label: 'Dark dramatic' },
                                        { value: 'retro', label: 'Retro Sci-Fi' },
                                        { value: 'cinematic', label: 'Cinematic' },
                                        { value: 'paintings', label: 'Paintings' },
                                    ]}
                                />

                                <Tabs color="gray" variant="pills" mb={20} value={ratio} onTabChange={(value) => setRatio(value)}>
                                    <Text mb={20} fz={14}>Aspect Ratio:</Text>
                                    <Flex justify={'center'}>
                                        <Tabs.List>
                                            {['1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'].map((r) => (
                                                <Tabs.Tab key={r} value={r} fz={10}>
                                                    {r}
                                                </Tabs.Tab>
                                            ))}
                                        </Tabs.List>
                                    </Flex>

                                </Tabs>

                                <Button
                                    styles={{
                                        root: {
                                            color: 'background: rgba(230, 230, 230, 1)',
                                            height: rem(42),

                                            fontSize: '18px',
                                            background: 'rgba(35, 108, 250, 1)',
                                            border: 'none',
                                            position: 'relative',
                                            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                        }
                                    }}
                                    variant="default"
                                    loading={loading}
                                    onClick={() => {
                                        runDaleeAsync();
                                    }}
                                >
                                    {t("generate")}
                                </Button>
                            </Flex>
                        )
                    }

                </Flex>
            </Flex>
        </Modal>
    )
}

export const SiteGenImageModal = observer(SiteDetailsComponent);