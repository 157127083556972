import { Modal, Tabs, Text, Flex, Blockquote } from '@mantine/core';

import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';

export const Element = (props: {
    opened: boolean
    close: () => void
}) => {

    const {
        dataStore: { spaceStore },
        dataStore: { appStore },
    } = useStore();

    const [data, setData] = useState<[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { loading, runAsync } = useRequest(async () => appStore.getFormSubmissionByWebsiteId(spaceStore.siteId), {
        manual: true,
        onSuccess: (model: any) => {
            console.log(model)
            setData(model);
        },
    });

    useEffect(() => {
        if (props.opened) {
            runAsync();
        }
    }, [props.opened, runAsync])

   
    const forms = data.reduce((acc: any, item: any) => {
        if (!acc[item.form_id]) {
            acc[item.form_id] = [];
        }
        acc[item.form_id].push(item);
        return acc;
    }, {})


    const renderTable = (data: any) => {
        const rows = data.map((itm: any) => {
            return (

                <Blockquote color="gray" icon={<IconInfoCircle />}>
                    {
                        itm.data.map((item: any) => {
                            return <Text color='white' size={'12px'}><b>{item.name}:</b> {item.value}</Text>
                        })
                    }
                    <Text color='white' size={'12px'}><b>Submitted at:</b> {new Date(itm.created_at).toLocaleString()}</Text>
                    

                </Blockquote>

            )
        })
        return (
            rows
        )


    }

    return (
        <Modal opened={props.opened} onClose={props.close} title="Form submissions" size={'auto'} styles={{
            content: {
                minWidth: '800px'
            }
        }}>
            <Tabs orientation="vertical" mih={900} defaultValue={Object.keys(forms).length > 0 ? Object.keys(forms)[0] : ''}>
                <Tabs.List style={{ minWidth: '160px' }}>
                    {
                        Object.keys(forms).map((key: any) => {
                            return <Tabs.Tab value={key} p={16}>From id#{key}</Tabs.Tab>
                        })
                    }
                </Tabs.List>
                {
                    Object.keys(forms).map((key: any) => {
                        return (
                            <Tabs.Panel value={key} pl={26} pr={26}>

                                <Flex direction="column" gap="sm">
                                    {renderTable(forms[key])}
                                </Flex>

                            </Tabs.Panel>
                        )
                    })
                }
            </Tabs >
        </Modal >
    )
}

export const FormsSettingsModal = observer(Element);