import { Text, Select, Flex } from '@mantine/core';
import { UIButton } from '../../components/ui-kit/button/UIButton';
import { useEffect, useState } from 'react';
import { useStore } from '../../hooks/use-store';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { SitePropertyLine } from './SitePropertyLine';
import { UITextInput } from '../../components/ui-kit/textInput/UINumberInput';

export const SiteLinkEditModalComponent = () => {
    const {
        dataStore: { spaceStore },
    } = useStore();

    const [mode, setMode] = useState<string>('preview')
    const props = spaceStore.selected

    const { t } = useTranslation();

    const [type, setType] = useState<string>('none')

    // const [searchValue, setSearchValue] = useState<string>('')

    const pages = spaceStore.websiteData?.pages ? Object.keys(spaceStore.websiteData?.pages).filter(key => key !== spaceStore.pageId).map((key) => {
        return { value: `klykn://${key}`, label: `${spaceStore.websiteData?.pages[key].name} (/${spaceStore.websiteData?.pages[key].url})` }
    }) : []

    // const value = searchValue ? [{ value: searchValue, label: searchValue }] : []

    useEffect(() => {
        if (props?.link) {
            if (props.link.includes('klykn://')) {
                setType('internal')
            } else if (props.link.startsWith('#')) {
                setType('anchor')
            } else {
                setType('external')
            }
        } else {
            setType('none')
        }
    }, [props?.link])

    const ids = pages.map(({ label }) => label);
    const filtered = pages.filter(({ label }, index) => !ids.includes(label, index + 1));

    const getPublicLink = (link?: string | null) => {
        if (link) {

            if (link.startsWith('klykn://')) {
                const key = link.replace('klykn://', '')
                return spaceStore.websiteData?.pages[key]?.name
            
            } else {
                return link
            }
        } else {
            return ''
        }
    }
    if (mode === 'edit') {
        return (
            <>
                <SitePropertyLine
                    width='140px'
                    label={t("editor.page.link")}
                    input={
                        <Select
                            // searchable
                            size={'xs'}
                            value={type}
                            mt={0}
                            mb={0}
                            styles={{
                                icon: {
                                    // right: '19px',
                                    left: 'auto',
                                    fontSize: '10px'
                                },
                                root: {
                                    backgroundColor: 'transparent',
                                },
                                input: {
                                    color: 'white',
                                    paddingLeft: '10px !important',
                                    paddingRight: '22px',
                                    backgroundColor: 'transparent',
                                    borderColor: '#464646',
                                }
                            }}
                            onChange={(val) => {
                                setType(val!)
                                if (val === 'anchor') {
                                    spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        link: '#'
                                    })
                                } else if (val === 'external') {
                                    spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        link: 'https://'
                                    })
                                } else if (val === 'internal') {
                                    spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        link: ' '
                                    })
                                } else {
                                    spaceStore.updateElementProps({
                                        id: spaceStore.selected?.id,
                                        link: ''
                                    })
                                }

                            }}
                            data={[
                                {
                                    label: '-',
                                    value: 'none'
                                },
                                {
                                    label: 'Go to page',
                                    value: 'internal'
                                },
                                {
                                    label: 'Scroll to anchor',
                                    value: 'anchor'
                                }, {
                                    label: 'Open link',
                                    value: 'external'
                                }
                            ]}
                        />
                    }
                />

                {
                    type === 'internal' ?
                        <Select
                            clearable
                            searchable
                            size={'xs'}
                            value={props?.link}
                            placeholder='Enter a URL or select a page'
                            // searchValue={searchValue}
                            // onSearchChange={setSearchValue}
                            mt={0}
                            mb={0}
                            styles={{
                                icon: {
                                    // right: '19px',
                                    left: 'auto',
                                    fontSize: '10px'
                                },
                                root: {
                                    backgroundColor: 'transparent',
                                },
                                input: {
                                    color: 'white',
                                    paddingLeft: '10px !important',
                                    paddingRight: '22px',
                                    backgroundColor: 'transparent',
                                    borderColor: '#464646',
                                }
                            }}
                            onChange={(val) => {
                                spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    link: val
                                })
                            }}
                            data={filtered}
                        /> : null
                }
                {
                    type === 'anchor' ?
                        <UITextInput
                            placeholder='#anchor'
                            value={props?.link!}
                            onChange={(value) => {
                                spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    link: value.toString(),
                                })
                            }}
                        /> : null
                }
                {
                    type === 'external' ?
                        <UITextInput
                            placeholder='https://klykn.com.com'
                            value={props?.link!}
                            onChange={(value) => {
                                spaceStore.updateElementProps({
                                    id: spaceStore.selected?.id,
                                    link: value.toString(),
                                })
                            }}
                        /> : null
                }
                <Flex mt={10}>
                    <UIButton fluid={true} color={'blue'} loading={false} onClick={() => setMode('preview')}>
                        Save
                    </UIButton>
                </Flex>


            </>
        )
    } else {
        return (
            <>
                <SitePropertyLine
                    width='180px'
                    label={t("editor.page.link")}
                    input={
                        <Text fz={12} color="white" style={{maxWidth: '180px', overflow: 'hidden'}}>
                          {getPublicLink(props?.link)}
                        </Text>
                        
                    }
                />

                <UIButton loading={false} fluid={true} onClick={() => setMode('edit')} color={'gray'}>
                    Edit
                </UIButton>
            </>
        )
    }

}


export const SiteLinkEditModal = observer(SiteLinkEditModalComponent);