import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'


type PropType = {
  slides: string[]
}

export const CarouselPreview: React.FC<PropType> = (props) => {
  const { slides } = props
  const [emblaRef] = useEmblaCarousel({
    align: 'center', containScroll: false
  }, [])

  console.log(slides)
  return (
    <div className="embla" style={{
    }}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((value, index) => (
            <div className="embla__slide" key={index}>
              <img
                className="embla__slide__img"
                src={value}
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '100%',
                }}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

