import React, { useState } from "react";
import { IconChevronRight } from "@tabler/icons-react";
import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  Box,
  useMantineTheme,
  rem,
  Menu,
} from "@mantine/core";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react";
import EditAccount from "../../components/sidebar/editAccount/EditAccount";

const User = () => {
  const {
    uiStore: { authStore },
  } = useStore();

  const theme = useMantineTheme();

  const [editAccountDrawerOpen, setEditAccountDrawerOpen] = useState(false);

  console.log('authStore.currentUser?.avatar', authStore.currentUser?.avatar)

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `${rem(1)} solid ${
          theme.colorScheme === "dark"
            ? theme.colors.dark[4]
            : theme.colors.gray[2]
        }`,
      }}
    >
      <UnstyledButton
        sx={{
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color:
            theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[6]
                : theme.colors.gray[0],
          },
        }}
      >
        <Menu
          width={190}
          position="top-end"
          transitionProps={{ transition: "pop-top-right" }}
          withinPortal
        >
          <Menu.Target>
            <Group>
              <Avatar src={authStore.currentUser?.avatar} radius="xl" />
              <Box sx={{ flex: 1 }}>
                <Text size="sm" weight={500}>
                  {authStore.currentUser?.firstName}{" "}
                  {authStore.currentUser?.lastName}
                </Text>
                <Text color="dimmed" size="xs">
                  {authStore.currentUser?.email}
                </Text>
              </Box>
              <IconChevronRight size={rem(18)} />
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Settings</Menu.Label>
            <Menu.Item
              icon={null}
              onClick={() => {
                setEditAccountDrawerOpen(true);
              }}
            >
              Account settings
            </Menu.Item>
            <Menu.Item
              icon={null}
              onClick={() => {
                authStore.logout();
                window.location.href = "/login";
              }}
            >
              Logout
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </UnstyledButton>

      <EditAccount
        visible={editAccountDrawerOpen}
        onClose={() => setEditAccountDrawerOpen(false)}
      />
    </Box>
  );
};

export default observer(User);
