import { Button, Flex, Text, Image } from "@mantine/core";
import LanguageSwitcher from "../profile/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../hooks/use-store";
import { useRequest } from "ahooks";
import { useHistory, useParams } from "react-router-dom";
import useIsMobile from "../../../hooks/isMobile";
import { templates } from "./templates";

export const TemplatesScreen = () => {
    const isMobile = useIsMobile()

    const { replaceId } = useParams<{ replaceId?: string }>();

    console.log(replaceId)
    const { t } = useTranslation();

    const history = useHistory();

    const {
        dataStore: { appStore },
    } = useStore();

    const { loading: createWebsiteLoading, runAsync: createWebsite } = useRequest(
        async (model: { name: string }) => appStore.createWebsite(model),
        {
            manual: true,
            onSuccess: (model: any) => {
                return model;
            },
        }
    );



    return (
        <Flex w={'100%'} h='100%' wrap={'wrap'}>
            <Flex w={isMobile ? '100%' : '35%'} direction={'column'} bg={'rgba(48, 48, 48, 1)'}>
                <Flex p="30px" gap={6} justify={'space-between'} align={'center'}>
                    <Button
                        onClick={async () => {
                            if (replaceId && replaceId !== 'default') {
                                history.push(`/sites/${replaceId}/`);
                            } else {
                                history.push(`/new`);
                            }
                        }}
                    >
                        {t("back")}
                    </Button>
                    <LanguageSwitcher />
                </Flex>
                <div style={{
                    padding: '30px 60px'
                }}>
                    <Text color="white" size={24} weight={'700'} mt={10} mb={10} align="left">
                        {t("find.website")}
                    </Text>
                    <Text color="rgba(230, 230, 230, 1)" size={12} weight={'400'} mt={20} mb={10}>
                        Crafted to enhance your website's visibility on search engines, our template incorporates advanced SEO features, ensuring that your content ranks prominently and attracts the right audience.
                        <br />
                        <br />
                        With its flexible design, our template empowers you to customize every aspect of your website effortlessly. Tailor your site's layout, color schemes, fonts, and more to reflect your unique brand identity and captivate your visitors.
                        {/* {t("find.website.desc")} */}
                    </Text>
                </div>
            </Flex>
            <Flex w={isMobile ? '100%' : '65%'}>
                <Flex align={'ceter'} justify={'center'} w={'100%'} pos='relative' bg="#464646" >
                    <Flex wrap={'wrap'} style={{ zIndex: '1', width: '100%', overflow: 'scroll' }} p={20} >
                        {
                            [
                                ...templates,
                                {
                                    name: t("start.skratch"),
                                    url: 'skratch',
                                },
                            ].map((item) => {
                                return (
                                    <Flex w={isMobile ? '50%' : '33%'} p={20} direction={'column'}>
                                        <Flex style={{ width: '100%', borderRadius: '4px', paddingTop: '10px', paddingBottom: '10px', }}
                                            justify={'space-between'}
                                        >
                                            {item.preview ? <Image
                                                width={'100%'}
                                                // height={'470px'}
                                                style={{
                                                    // background: 'black',
                                                }}
                                                styles={{
                                                    image: {
                                                        zIndex: 0,
                                                        alignContent: 'center',
                                                    }
                                                }}
                                                src={item.preview}
                                                alt="With default placeholder"
                                                withPlaceholder
                                                fit="contain"
                                                radius={4}
                                            /> : null}
                                        </Flex>
                                        <Flex justify={'space-between'} mt={10} align={'center'}>
                                            <Text color="white">{item.name}</Text>
                                            {
                                                item.url === 'skratch' ? <Button loading={createWebsiteLoading}
                                                    onClick={async () => {
                                                        const website = await createWebsite({
                                                            name: '',
                                                        });
                                                        if (website) {
                                                            history.push(`/sites/${website.id}`);
                                                        }
                                                    }}
                                                >
                                                    {
                                                        t("start.new")
                                                    }
                                                </Button> :
                                                    <Button
                                                        onClick={async () => {
                                                            if (replaceId && replaceId !== 'default') {
                                                                history.push(`/templates/${replaceId}/${item.url}`);
                                                            } else {
                                                                history.push(`/templates/default/${item.url}`);
                                                            }
                                                        }}>
                                                        {t("preview")}
                                                    </Button>
                                            }

                                        </Flex>
                                    </Flex>
                                )
                            })
                        }
                    </Flex>
                    <Image

                        pos={'absolute'}
                        width={'100%'}
                        height={'100vh'}
                        style={{
                            background: 'black',
                            opacity: 0.5,
                        }}
                        styles={{
                            image: {
                                filter: 'blur(2px)',
                                '-webkit-filter': 'blur(2px)',
                                zIndex: 0,
                                alignContent: 'center',
                            }
                        }}
                        src={'/static/background-template.png'}
                        alt="With default placeholder"
                        withPlaceholder
                        fit="cover"
                    />
                </Flex>
            </Flex>
        </Flex >
    );
}