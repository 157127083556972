export const SitePropertyPadding = (props: {
    children: any
}) => {

    return (
        <div
            style={{
                padding: '8px 16px',
            }}
        >
            {props.children}
        </div>
    )
}