import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import throttle from "lodash/throttle";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { TextElementProps } from "../../../stores/data/space-store/textElement";
import { useStore } from "../../../hooks/use-store";
import { ButtonElementProps } from "../../../stores/data/space-store/buttonElement";
import isEqual from "lodash.isequal";
// import TaskList from '@tiptap/extension-task-list'
// import TaskItem from '@tiptap/extension-task-item'
// import Highlight from '@tiptap/extension-highlight'
// import Placeholder from '@tiptap/extension-placeholder'
// import MenuBar from './Menubar'
// import styles from './text.module.scss'

interface Props {
    data: TextElementProps | ButtonElementProps
    readonly?: boolean;
    // content: any;
    // timestamp: number;
    // onChangeText: (json: string, date: number) => void;
}

const Text = ({ data, readonly }: Props) => {
    // content={text}
    // onChangeText={function (json: string, date: number): void {
    //   spaceStore.updateElementProps({
    //     id: props.id,
    //     text: json,
    //   })
    // }}

    const {
        dataStore: { spaceStore },
    } = useStore();


    const [lastChange, setLastChange] = useState<null | number>(null)
    const debouncedChangeHandler = throttle((...params) => {
        const date = Date.now()
        console.log(params)
        setLastChange(date + 1)

        if (!isEqual(data.text, params)) {
            spaceStore.updateElementProps({
                id: data.id,
                text: params,
            })
        }

        // onChangeText(params, date)
    }, 0);

    const editor = useEditor({
        editable: !readonly,
        editorProps: {
            transformPastedText(text) {
                return text.replace(/\xA0/g, " ");
            },
            transformPastedHTML(html) {
                return html.replace(/\xA0/g, " ");
            },
        },
        onBlur({ editor }) {
            console.log(JSON.stringify(editor.getJSON()));
            debouncedChangeHandler(JSON.stringify(editor.getJSON()));
        },
        extensions: [
            StarterKit.configure({
                history: false,
            }),
            // Highlight,
            // TaskList,
            // TaskItem,
        ],
        content: `<p>Text placeholder</p>
    `,
    });

    useEffect(() => {
        // console.log(data.tex t, 'content')
        if (data.text) {
            // if (lastChange) {
            //     if (timestamp < lastChange) {
            //         return
            //     }
            // }
            try {
                editor?.commands.setContent(JSON.parse(data.text));
            } catch (e) {
                editor?.commands.setContent(data.text);
            }
        }
    }, [data.text, lastChange, editor]);

    if (!editor) {
        return null
    }
    return (

        <EditorContent
            style={{
                width: '100%',
                textAlign: data.textAlign,
                textDecoration: data.textDecoration,
                fontSize: data.fontSize,
                lineHeight: `${+data.lineHeight ? `${data.lineHeight}px` : 'normal'}`,
                letterSpacing: `${+data.letterSpacing ? `${data.letterSpacing}px` : 'normal'}`,
                fontWeight: data.fontWeight,
                fontFamily: `'${data.typefaceId}'`,
                color: data.color,
            }} className={'textEditor'}
            placeholder={'Enter your notes'}
            editor={editor}
            readOnly={readonly}
        />
    );
};

export default observer(Text);
