import {
  Group,
  Box,
  ThemeIcon,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import {
  IconBulb,
  IconHome,
  // IconNote,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  control: {
    background: '#303030',
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: 'rgba(230, 230, 230, 1)',
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor: 'rgba(70, 70, 70, 1)',
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(30),
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: "transform 200ms ease",
  },
}));

function QuickLinks() {
  const { classes } = useStyles();

  const { t } = useTranslation();
  // const posthog = usePostHog()
  // const [opened, { open, close }] = useDisclosure(false);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
      })}
    >
      <Helmet>
        {/* <script src="https://js.stripe.com/v3/"></script>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script> */}

        {/* <script>
          {`window.chatbaseConfig = {
            chatbotId: "-pTFmha21MJlw_DIPzv7Z",
         }`}
        </script>
        <script
          src="https://www.chatbase.co/embed.min.js"
          id="-pTFmha21MJlw_DIPzv7Z"
          defer>
        </script> */}

      </Helmet>

      <UnstyledButton
        component={Link}
        to={`/sites/`}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30}>
              <IconHome size="1.1rem" />
            </ThemeIcon>
            <Box ml="md">
              {
                t("home", {
                  defaultValue: "Home",
                })
              }
            </Box>
          </Box>
        </Group>
      </UnstyledButton>

      {/* <UnstyledButton
        onClick={() => {
          posthog?.capture('Click on upgrade')
          open()
        }}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30}>
              <IconReceipt2 size="1.1rem" />
            </ThemeIcon>
            <Box ml="md">Subscription</Box>
          </Box>
        </Group>
      </UnstyledButton> */}

      <UnstyledButton
        component={"a"}
        href={`https://feedback.klykn.com/boards/feature-requests`}
        target={"_blank"}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30}>
              <IconBulb size="1.1rem" />
            </ThemeIcon>
            <Box ml="md">
              {
                t("submit.idea", {
                  defaultValue: "Submit idea",
                })
              }
            </Box>
          </Box>
        </Group>
      </UnstyledButton>
      {/* <UnstyledButton
        component={"a"}
        href={`https://vsble.typeform.com/to/Ti5MBxu8`}
        target={"_blank"}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30}>
              <IconNote size="1.1rem" />
            </ThemeIcon>
            <Box ml="md">
              {
                t("leave.feedback", {
                  defaultValue: "Leave feedback",
                })
              }
            </Box>
          </Box>
        </Group>
      </UnstyledButton> */}
    </Box>
  );
}

export default QuickLinks;
