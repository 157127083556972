import { useEffect } from "react";
import { useStore } from "../../hooks/use-store";
import {
  Grid,
} from "@mantine/core";
import { observer } from "mobx-react";

const WaitlistForm = () => {

  const {
    uiStore: { authStore },
  } = useStore();

  useEffect(() => {
    console.log(authStore.currentUser?.status.name)
    if (authStore.currentUser?.status.name === 'Active') {
      window.location.href = '/sites';
    }
  }, [authStore]);
  // The code below will load the embed
  useEffect(() => {
    const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

    const load = () => {
      // Load Tally embeds
      // @ts-ignore
      if (typeof Tally !== 'undefined') {
        // @ts-ignore
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll('iframe[data-tally-src]:not([src])')
        .forEach((iframeEl: any) => {
          iframeEl.src = iframeEl.dataset.tallySrc;
        });
    };

    // If Tally is already loaded, load the embeds
    // @ts-ignore
    if (typeof Tally !== 'undefined') {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement('script');
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, []);

  return (
    <Grid justify="space-between" m={0}>
      <iframe
        data-tally-src="https://tally.so/embed/w2eNdp?transparentBackground=1&dynamicHeight=1"
        loading="lazy"
        width="100%"
        height="100vh"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title="Newsletter subscribers"
      ></iframe>
    </Grid >
  );
};

export default observer(WaitlistForm);
