import { Select } from "@mantine/core"

interface IUISelect {
    value: string,
    searchable: boolean
    label?: string
    data: string[] | { label: string; value: string; }[],
    onChange: (string: string | null) => void
    placeholder?: string
}

const UISelect = (props: IUISelect) => {
    return (
        <Select
            styles={{
               
                root: {
                    backgroundColor: 'transparent',
                },
                input: {
                    color: 'white',
                    paddingLeft: '10px !important',
                    backgroundColor: 'transparent',
                    borderColor: '#464646',
                }
            }}
            label={props.label}
            size="xs"
            placeholder={props.placeholder || "Pick value"}
            value={props.value}
            searchable={props.searchable}
            data={props.data}
            allowDeselect={false}
            onChange={(e) => {
                props.onChange(e)
            }}
        />
    )
}

export { UISelect }