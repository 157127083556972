import { TextInput } from "@mantine/core"

interface IUIContentEditable {
    value: string
    placeholder: string
    label: string
    disabled: boolean
    onChange: (value: string) => void
}
const UIContentEditable = (props: IUIContentEditable) => {
    return <TextInput
        label={props.label}
        size="xs"
        disabled={props.disabled}
        styles={{
            input: {
                borderRadius: '2px',
                paddingLeft: '4px',
                paddingRight: '4px',
                background: 'transparent',
                minHeight: '1.4rem',
                height: '1.4rem',
                borderColor: '#464646'
            }
        }}
        description=""
        value={props.value}
        placeholder=""
        onChange={(e) => props.onChange(e.target.value)}
    />
}

export { UIContentEditable }